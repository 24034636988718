import * as React from "react";
import PropTypes from "prop-types";

const ListIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={11.406}
    height={9.8}
    {...props}
  >
    <path
      d="M.95 0a.951.951 0 1 0 0 1.9h4.436a.951.951 0 1 0 0-1.9Zm0 4.082a.951.951 0 1 0 0 1.9h6.97a.951.951 0 1 0 0-1.9Zm0 3.817a.951.951 0 1 0 0 1.9h9.5a.951.951 0 1 0 0-1.9Z"
      fill={props.color}
    />
  </svg>
);

export default ListIcon;

ListIcon.defaultProps = {
  color: "#6a7888",
};

ListIcon.propTypes = {
  color: PropTypes.string,
};
