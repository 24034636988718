import Card from "@material-ui/core/Card";
// import Divider from "@mui/material/Divider";
import Icon from "@material-ui/core/Icon";

// import Transaction from "layouts/billing/components/Transaction";

import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Transaction from "./transactions";

import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import TrendingDownOutlinedIcon from "@material-ui/icons/TrendingDownOutlined";
import TrendingUpOutlinedIcon from "@material-ui/icons/TrendingUpOutlined";

import DateRangeIcon from "@material-ui/icons/DateRange";
import Button from "@material-ui/core/Button";
function Transactions() {
  return (
    <>
      {/* <CardContent> */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        style={{
          listStyle: "none",
          padding: "8px",
          margin: "0",
          marginBottom: "16px",
        }}
      >
        <Typography
          variant="body1"
          fontWeight="medium"
          textTransform="capitalize"
        >
          Stock&apos;s
        </Typography>
        <Button variant="outlined" color="primary">
          View All
        </Button>
        {/* <Box display="flex" alignItems="flex-start">
          <Box
            color="textSecondary"
            style={{ marginRight: "4px", color: "GrayText" }}
            lineHeight={0}
          >
            <DateRangeIcon color="GrayText" fontSize="small" />
          </Box>
          <Typography
            variant="button"
            color="textSecondary"
            fontWeight="regular"
          >
            10 - 13 March 2023
          </Typography>
        </Box> */}
      </Box>
      <Box>
        <Box style={{ margin: "8px" }}>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ fontWeight: "bold" }}
          //   fontWeight="bold"
          //   textTransform="uppercase"
          >
            NEWEST
          </Typography>
        </Box>
        <Box
          component="ul"
          display="flex"
          flexDirection="column"
          style={{ listStyle: "none", padding: "0", margin: "0" }}
        >
          <Transaction
            color="green"
            icon={<TrendingUpOutlinedIcon color="green" />}
            name="Pentai"
            description="12 March 2023, at 04:30 PM"
            value="+ RM 2,500"
          />
          <Transaction
            color="green"
            icon={<TrendingUpOutlinedIcon color="green" />}
            name="Kindra"
            description="11 March 2023, at 12:30 PM"
            value="+ RM 2,000"
          />
        </Box>
        <Box style={{ margin: "8px" }}>
          <Typography
            variant="body2"
            color="textSecondary"

          //   fontWeight="bold"
          //   textTransform="uppercase"
          >
            YESTERDAY
          </Typography>
        </Box>
        <Box
          component="ul"
          display="flex"
          flexDirection="column"
          //   p={0}
          //   m={0}
          style={{ listStyle: "none", padding: "0", margin: "0" }}
        >
          <Transaction
            color="green"
            icon={<TrendingUpOutlinedIcon color="success" />}
            name="Pentai"
            description="26 March 2020, at 13:45 PM"
            value="+ RM 750"
          />
          <Transaction
            color="red"
            icon={<TrendingDownOutlinedIcon color="success" />}
            name="Ferosel"
            description="26 March 2020, at 12:30 PM"
            value="- RM 1,000"
          />
          {/* <Transaction
            color="green"
            icon={<TrendingUpOutlinedIcon color="success" />}
            name="KPJ"
            description="26 March 2020, at 08:30 AM"
            value="+ RM 2,500"
          /> */}
          <Transaction
            color="orange"
            icon={<PriorityHighIcon color="success" />}
            name="Njord"
            description="26 March 2020, at 05:00 AM"
            value="Pending"
          />
        </Box>
      </Box>
      {/* </CardContent> */}
    </>
  );
}

export default Transactions;
