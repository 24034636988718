import React from "react";
import {
  Grid,
  TextField,
  makeStyles,
  FormControlLabel,
  Switch,
  Typography,
  InputAdornment,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  gridItem: {
    // padding: theme.spacing(2),
  },
  textField: {},
}));
function FileUploadValidation(props) {
  const { validationState, onChange } = props;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        {/* <Grid item xs={12} md={6} className={classes.gridItem}>
          <Typography variant="body1">Minimum File Size </Typography>
          <TextField
            className={classes.textField}
            value={validationState?.min ?? 0}
            onChange={(e) => onChange(e.target.value, "min")}
            id="fileuploadminimumsize"
            variant="outlined"
            size="small"
            type="number"
            // error={val?.error?.labelError ?? false}
            helperText={validationState?.min ? "" : ""}
            InputProps={{
              inputProps: {
                min: 0,
              },
              endAdornment: <InputAdornment position="end">MB</InputAdornment>,
            }}
          />
        </Grid> */}
        <Grid item xs={12} md={4} className={classes.gridItem}>
          <>
            <Typography variant="body1">Maximum File Size</Typography>
            <TextField
              className={classes.textField}
              value={validationState?.max ?? 1}
              onChange={(e) =>
                onChange(e.target.value === 0 ? 1 : e.target.value, "max")
              }
              id="fileuploadmaximumsize"
              variant="outlined"
              size="small"
              type="number"
              // error={val?.error?.labelError ?? false}
              helperText={validationState?.max ? "" : ""}
              InputProps={{
                inputProps: {
                  min: 0,
                },
                endAdornment: (
                  <InputAdornment position="end">MB</InputAdornment>
                ),
              }}
            />
          </>
        </Grid>
        <Grid item xs={12} md={4} className={classes.gridItem}>
          <Typography variant="body1">Limit Number Of Files</Typography>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={validationState?.multiple ?? false}
                onChange={(e) => onChange(e.target.checked, "multiple")}
              />
            }
          />
        </Grid>
        {validationState?.multiple && (
          <Grid item xs={12} md={4} className={classes.gridItem}>
            <Typography variant="body1">Number Of File</Typography>
            <TextField
              value={validationState?.noOfFiles ?? ""}
              onChange={(e) => onChange(e.target.value, "noOfFiles")}
              fullWidth
              id="fileValidationStateNumberOfFilesAllowed"
              variant="outlined"
              size="small"
              type="number"
              // error={val?.error?.labelError ?? false}
              helperText={validationState?.noOfFiles ? "" : ""}
              InputProps={{
                inputProps: {
                  min: 0,
                },
              }}
            />
          </Grid>
        )}
        <Grid item xs={12} md={4} className={classes.gridItem}>
          <Typography variant="body1"> URL </Typography>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={validationState?.typeOfUpload ?? false}
                onChange={(e) => onChange(e.target.checked, "typeOfUpload")}
              />
            }
          />
        </Grid>
        {validationState?.typeOfUpload && (
          <Grid item xs={12} md={4} className={classes.gridItem}>
            <Typography variant="body1">Upload URL </Typography>
            <TextField
              value={validationState?.uploadurl ?? ""}
              onChange={(e) => onChange(e.target.value, "uploadurl")}
              fullWidth
              id="fileValidationStateurl"
              variant="outlined"
              size="small"
              // error={val?.error?.labelError ?? false}
              helperText={validationState?.uploadurl ? "" : ""}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default FileUploadValidation;
