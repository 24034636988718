import React from "react";
import SensibleDrawer from "./sensibleDrawer";
import { TopNavBar } from "../navbars";
import { makeStyles } from "@material-ui/styles";
import { checkWithIdm } from "atp-casbin-js";
import { AxiosArangoCall } from "../../api";
import { useHistory } from "react-router-dom";
import { repolist } from "../../utils";
import { steps } from "../../Steps";
import { Box, CircularProgress, Typography } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100vh",
  },
  content: {
    width: "100%",
    height: "calc(100% - 64px)",
    overflow: "auto",
  },
}));
export const Drawer = (props) => {
  const classes = useStyles();
  const { sidePanellist } = props;
  const [loading, setLoading] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState({});
  const [navlist, setNavlist] = React.useState([]);
  const history = useHistory();

  React.useLayoutEffect(() => {
    getNavListByRole();
    sessionStorage.setItem("setIsopen", JSON.stringify(steps));
    //eslint-disable-next-line
  }, []);

  const getNavListByRole = async () => {
    if (localStorage.getItem("RBAC")) {
      setLoading((prev) => !prev);
      let roleid = atob(localStorage.getItem("RBAC"));

      let params = {
        db_name: process.env.REACT_APP_DB,
        roleid: roleid,
      };

      let res = await AxiosArangoCall(
        "post",
        process.env.REACT_APP_GETPERMISSIONFORROLES,
        params
      );
      if (res?.Result?.length > 0 || res.result.length > 0) {
        let _roledata = res?.Result[0]?.permission[0]?.permissionRepoAccess;
        let repository = repolist.find(
          (l) => l.routePath === window.location.pathname
        );

        setSelectedItem(repository);

        let hasAccessCheck = checkWithIdm(
          _roledata,
          roleid,
          repository?.repo,
          "read"
        );
        if (hasAccessCheck) {
          let NlistCheckRole = sidePanellist.map((l) =>
            checkWithIdm(_roledata, roleid, l.repo, "read") && l.isMenu
              ? { ...l, read: true }
              : { ...l, read: false }
          );
          setNavlist(NlistCheckRole);
          setLoading((prev) => !prev);
        } else {
          history.push("/");
          localStorage.removeItem("aTclk");
          localStorage.removeItem("rTclk");
          localStorage.removeItem("kclkid");
          localStorage.removeItem("RBAC");
        }
      } else {
        history.push("/");
        localStorage.removeItem("aTclk");
        localStorage.removeItem("rTclk");
        localStorage.removeItem("kclkid");
        localStorage.removeItem("RBAC");
      }
    } else {
      history.push("/");
      localStorage.removeItem("aTclk");
      localStorage.removeItem("rTclk");
      localStorage.removeItem("kclkid");
      localStorage.removeItem("RBAC");
    }
  };
  return (
    <>
      <div className={classes.root}>
        {!loading ? (
          <>
            <div>
              <TopNavBar />
            </div>
            <div className={classes.content}>
              <SensibleDrawer
                sidePanellist={navlist}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
              >
                {props.children}
              </SensibleDrawer>
            </div>
          </>
        ) : (
          <>
            <Box style={{ height: "100%" }}>
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {loading && <CircularProgress />}
                <Typography
                  variant="body1"
                  color="textPrimary"
                  style={{ fontWeight: "600" }}
                >
                  {`Loading...`}
                </Typography>
              </div>
            </Box>
          </>
        )}
      </div>
    </>
  );
};
