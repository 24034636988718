import React from "react";
import AppAuth from "./App.auth";
import AppTheme from "./App.theme";
import AppAlert from "./App.alert";
import AppErrorBoundary from "./App.errorBoundry";
import RouterApp from "./router";
import AppDrawer from "./App.drawer";
import { CssBaseline } from "@material-ui/core";
import AppDialog from "./App.dialog";
import AppBackdrop from "./App.backdrop";
import { Provider } from "react-redux";
import { store as ReduxStore } from "./redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PintarContext from "./contexts/consultant";

const App = () => {
  return (
    <Provider store={ReduxStore}>
      <AppErrorBoundary>
        <AppAuth>
          <AppTheme>
            <CssBaseline />
            <AppAlert>
              <AppDialog>
                <AppBackdrop>
                  <PintarContext>
                    <AppDrawer>
                      <RouterApp />
                    </AppDrawer>
                  </PintarContext>
                </AppBackdrop>
              </AppDialog>
            </AppAlert>
          </AppTheme>
        </AppAuth>
      </AppErrorBoundary>
      <ToastContainer theme="colored" />
    </Provider>
  );
};
export default App;
