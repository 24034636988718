import MuiInputBase from "@material-ui/core/InputBase";
import { withStyles } from "@material-ui/core/styles";

export const InputBase = withStyles((theme) => ({
  root: {
    width: "100%",
    flex: 0,
    gap: 10,
    padding: "10px 12px",
    background: theme.palette.background.table,
    borderRadius: "8px",
    border: `1px solid ${theme.palette.border.textField}`,
    "& .App1-MuiInputBase-input": {
      minWidth: 20,
    },
    color:theme.palette.text.primary 
  },
  input: {
    padding: 0,
    "&:focus": {
      backgroundColor: "unset",
    },
  },
}))(MuiInputBase);
