import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { TodoListLoading } from './loading';

export const TodoListLoader = (props) => {

    const {
        count
    } = props;


    return <div style={{width:'100%'}}>
        <Grid container direction='column' spacing={1}>
            {
                Array.apply(null, Array(count)).map((value, index) => <Grid key={index} item>
                    <TodoListLoading />
                </Grid>)
            }
        </Grid>
    </div>
}

TodoListLoader.propTypes = {
    count: PropTypes.number
}

TodoListLoader.defaultProps = {
    count: 1
}