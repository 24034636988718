

import * as React from "react";
import PropTypes from "prop-types";

const HomeDeliveryIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={12} height={13} {...props}>
    <path
      d="M5.984.001a.5.5 0 0 0-.293.107L.953 3.84A2.5 2.5 0 0 0 0 5.801v5.363a1.841 1.841 0 0 0 1.833 1.837h8.333A1.841 1.841 0 0 0 12 11.167V5.801a2.5 2.5 0 0 0-.953-1.964L6.309.108a.5.5 0 0 0-.325-.107ZM6 1.137l4.428 3.489A1.5 1.5 0 0 1 11 5.801v5.363a.826.826 0 0 1-.833.833H1.833a.826.826 0 0 1-.833-.83V5.801a1.5 1.5 0 0 1 .572-1.178Zm1.15 3.53a2.037 2.037 0 0 0-1.41.581l-.029.031c.036-.041-.033.035-.1.1l-.262.264-.768.772-.772.775-.268.27c-.058.059-.084.084-.12.124a2 2 0 0 0 1.418 3.417 2.036 2.036 0 0 0 1.411-.581l.007-.006L8.58 8.083a2 2 0 0 0 0-2.831 2.051 2.051 0 0 0-1.43-.585Zm0 1a1.03 1.03 0 0 1 .726.3.986.986 0 0 1-.005 1.41L6.793 8.459l-1.42-1.42.688-.69.265-.268c.067-.068.083-.081.139-.145l-.029.031a1.029 1.029 0 0 1 .714-.3Z"
      fill={props.color}
    />
  </svg>
);

export default HomeDeliveryIcon;

HomeDeliveryIcon.defaultProps = {
  color: "#fff",
};

HomeDeliveryIcon.propTypes = {
  color: PropTypes.string,
};
