import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import StyledDivider from "../styledDivider";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.75),
  },
  title: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.75),
    background: "white",
    padding: theme.spacing(0.5),
    zIndex: 1,
  },
  divider: ({ negativeMargin }) => ({
    position: "absolute",
    top: "calc(50% - 1px)",
    left: 0,
    right: 0,
    height: 1,
    background: "#DEE5EC",
    zIndex: 0,
    marginInline: negativeMargin,
  }),
}));

function TitleWithDivider(props) {
  // <---------------Hooks------------------>
  const classes = useStyles(props);

  // <---------------Render------------------>
  const { title, icon, onClick } = props;
  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <IconButton
          size="small"
          disableRipple
          onClick={onClick}
          tabIndex={onClick ? 0 : -1}
          //   className={classes.expandCollapseButton}
        >
          {icon}
        </IconButton>
        <Typography variant="body2">{title}</Typography>
      </div>
      <StyledDivider flexItem className={classes.divider} />
    </div>
  );
}

export default TitleWithDivider;

TitleWithDivider.defaultProps = {
  negativeMargin: "-12px",
  title: "Additives for Dextrose Saline 1000 ml",
};

TitleWithDivider.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.element,
  onClick: PropTypes.func,
  negativeMargin: PropTypes.number,
};
