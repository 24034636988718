import React from "react";

const ExposeApi = () => {
  React.useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    let Id = query.get("ticketId");
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      fetch(process.env.REACT_APP_ARANGO_URL_READ, {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          db_name: process.env.REACT_APP_DB,
          entity: process.env.REACT_APP_MEAL_ORDER_ENTITY,
          filter: `${process.env.REACT_APP_MEAL_ORDER_ENTITY}.ticketId=='${Id}'`,
          return_fields: process.env.REACT_APP_MEAL_ORDER_ENTITY,
        }),
        redirect: "follow",
      })
        .then((result) => result.json())
        .then((res) => {
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          fetch(process.env.REACT_APP_ARANGO_URL_UPSERT, {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify([
              {
                db_name: process.env.REACT_APP_DB,
                entity: process.env.REACT_APP_MEAL_ORDER_ENTITY,
                filter: { _id: res.result[0]._id },
                doc: {
                  activestatus: false,
                },
              },
            ]),
            redirect: "follow",
          })
            .then((result) => result.json())
            .then((response) => response)
            .catch((err) => console.error(err));
        })
        .catch((err) => console.error(err));
    } catch (err) {
      console.log(err);
    }
  }, []);
  return <div></div>;
};
export default ExposeApi;
