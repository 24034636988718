import {
  alpha,
  Backdrop,
  Box,
  CircularProgress,
  Dialog,
  Fade,
  Grid,
  IconButton,
  makeStyles,
  Modal,
  Slide,
  Typography,
} from "@material-ui/core";
import React from "react";
import { AxiosCall } from "../../api";
import { FoodCard, TimeLine } from "../../components";
import Config from "../../config";
import { RatingForm } from "./rating";
import UpdateIcon from "@material-ui/icons/Update";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    "& .MuiDialog-scrollPaper": {
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "center",
    },
    "& .MuiDialog-paperFullScreen": {
      width: "100%",
      height: "85%",
      margin: "0px",
      maxWidth: "100%",
      maxHeight: "none",
      borderRadius: "16px 16px 0 0",
    },
  },
  flexBox: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  textResponsive: {
    flex: "1 1 auto",
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem !important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem !important",
    },
  },
  group: {
    marginLeft: "8px !important",
    paddingLeft: 12,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
  closeIcon: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    color: "#fff",
    position: "absolute",
    cursor: "pointer",
    backgroundColor: theme.palette.primary.main,
    top: -20,
    right: -20,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  Header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "38px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    borderRadius: "8px 8px 0 0",
  },
  closeIcon: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    color: "#fff",
    position: "absolute",
    cursor: "pointer",
    backgroundColor: theme.palette.primary.main,
    top: -20,
    right: -20,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  paper: {
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "8px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    height: 400,
    width: 350,
    [theme.breakpoints.down("sm")]: {
      width: 350,
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const OrderHistory = (props) => {
  const classes = useStyles();
  const { orderHistory = [], user, getOrderDetails } = props;
  const [open, setOpen] = React.useState(false);
  const [selectItem, setSelectedItem] = React.useState({});
  const [ratingItems, setRatingItems] = React.useState([]);
  const [ratingMaster, setRatingMaster] = React.useState([]);
  const [openm, setOpenm] = React.useState(false);
  const [timeline, setTimeline] = React.useState([]);
  const [currantStatus, setCurrantStatus] = React.useState();
  const [mloading, setmloading] = React.useState(false);
  React.useEffect(() => {
    getRateValueFromCodingMaster();
  }, []);

  const getRateValueFromCodingMaster = async () => {
    let params = {
      db_name: Config.dbname,
      entity: "CodeableConceptMaster",
      filter:
        "CodeableConceptMaster.Type=='FOODRATING' &&CodeableConceptMaster.status==true && CodeableConceptMaster.activestatus==true",
      sort: "CodeableConceptMaster.text",
      return_fields:
        "MERGE(CodeableConceptMaster,{coding:FIRST(FOR cod IN CodeableConceptMaster.coding RETURN DOCUMENT(cod))})",
    };
    let res = await AxiosCall(
      "post",
      `${Config.api_url}/api/read_documents`,
      params
    );

    let result = res?.result;

    setRatingMaster(result);
  };

  const openModel = (value, isEdit) => {
    setOpen(true);
    if (isEdit) {
      setRatingItems(value?.feedBack[0]?.Feedback);
      setSelectedItem({ ...value, isEdit });
    } else {
      let addontoarr =
        value.Addon.trim().length > 0
          ? value.Addon.split(",").map((l) => {
            return {
              title: l,
              rating: { value: 0, label: "" },
              feedback: "",
              image: "",
            };
          })
          : [];
      let list = [
        {
          title: value?.OrderItemcode?.MealCode?.MealDescription,
          rating: { value: 0, label: "" },
          feedback: "",
          image: "",
        },
        ...addontoarr,
      ];
      setRatingItems(list);
      setSelectedItem({ ...value, isEdit });
    }
  };

  const handleClose = (complete) => {
    setOpen(false);
    if (complete) {
      getOrderDetails();
    }
  };

  const getDateTOString = (value) => {
    const d = `${value}T00:00:00Z`;
    const date = new Date(d);
    return date.toDateString();
  };
  const getImageByType = (t) => {
    let obj = {
      "106 - Medical Supplies and Cosmetics": `${process.env.PUBLIC_URL}/images/aerosel.jfif`,
      "105 - Medications": `${process.env.PUBLIC_URL}/images/tablet.jpg`,
      OINTMENT: `${process.env.PUBLIC_URL}/images/Ointment.jpg`,
      GEL: `${process.env.PUBLIC_URL}/images/gell.jfif`,
      CAPSULE:
        "https://images.unsplash.com/photo-1587854692152-cbe660dbde88?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=869&q=80",
    };

    // console.log("obj", obj);

    let src =
      "https://images.unsplash.com/photo-1587854692152-cbe660dbde88?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=869&q=80";

    return obj[`${t}`] ?? src;
  };
  const handleClosemodal = () => {
    setOpenm(false);
  };

  const getTransactionId = async (ticketId) => {
    try {
      setmloading(true);
      let params = {
        db_name: `${Config.dbname}`,
        entity: "QDMATPtransactionlog",
        filter: `QDMATPtransactionlog.ticketId=='${ticketId}'`,
        return_fields: "QDMATPtransactionlog",
      };
      let res = await AxiosCall(
        "post",
        `${Config.api_url}/api/read_documents`,
        params
      );
      let data = res.result[0];
      setCurrantStatus(data?.payload?.statesid[0]);
      // console.log("currentstatus", data?.payload?.statesid[0]);
      let SecnarioStateList = await getSenarioStateList(
        data?.payload?.scenarioId[0]
      );
      await getAduitLog(data._id, SecnarioStateList);
    } catch (error) { }
  };

  const getSenarioStateList = async (id) => {
    let params = {
      db_name: `${Config.dbname}`,
      entity: "queuescenariostatemapping",
      filter: `queuescenariostatemapping.scenarioid=='${id}' and queuescenariostatemapping.activestatus==true`,
      return_fields: "queuescenariostatemapping.states",
    };
    let res = await AxiosCall(
      "post",
      `${Config.api_url}/api/read_documents`,
      params
    );
    let data = res.result[0];

    return data;
  };

  const getAduitLog = async (_id, scenarioState) => {
    try {
      let params = {
        db_name: `${process.env.REACT_APP_AuditDB}`,
        entity: "QDMATPtransactionlog_audit",
        filter: `QDMATPtransactionlog_audit.refkey=='${_id}'`,
        return_fields: "QDMATPtransactionlog_audit",
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_ARANGO_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };

      await axios(config).then((res) => {
        if (res.data.Code === 201) {
          let data = res.data.result;

          if (data.length > 0) {
            let decodeArr = data.map((l) => {
              let Payload = atob(l.payload);
              let Document = atob(l.document);
              return {
                ...l,
                payload: JSON.parse(Payload),
                document: JSON.parse(Document),
              };
            });
            let isArrHasState = decodeArr.filter(
              (l) =>
                l?.document?.payload?.statesid &&
                l?.document?.payload?.queueMetaData?.userAction?.userAction
            );

            let z = scenarioState.map((l) =>
              Object.assign(l, { statustime: "", userAction: "" })
            );

            for (let j = 0; j < z.length; j++) {
              for (let i = 0; i < isArrHasState.length; i++) {
                if (
                  z[j]._id === isArrHasState[i].document.payload.statesid[0]
                ) {
                  Object.assign(z[j], {
                    statustime: isArrHasState[i].statustime,
                    userAction:
                      isArrHasState[i].document?.payload?.queueMetaData
                        ?.userAction?.userAction,
                  });
                }
              }
            }
            setTimeline(z);
            // console.log({ z });
            setmloading(false);
          } else {
            let z = scenarioState.map((l) =>
              Object.assign(l, { statustime: "", userAction: "" })
            );
            setTimeline(z);
            setmloading(false);
          }
        }
      });
    } catch (error) {
      // updateTimeLine(scenarioState);
    }
  };
  const onTimeLineClicked = (data) => {
    handleOpen();
    getTransactionId(data?.ticketId);
  };
  const handleOpen = () => {
    setOpenm(true);
  };
  return (
    <Grid container spacing={1}>
      {orderHistory?.[0]?.drug?.map((l, index) => (
        <Grid item xs={12} lg={12} md={12} sm={12}>
          <Box className={classes.flexBox}>
            <UpdateIcon fontSize="small" color="primary" />
            <Typography
              variant="body1"
              display="block"
              color="primary"
              className={classes.textResponsive}
            >
              {getDateTOString(l?.OrderDate)}
            </Typography>
          </Box>

          <Grid container spacing={1} className={classes.group}>
            {l?.drugdetails?.map((menu, i) => {
              return (
                <>
                  {menu?.OrderItemCode.map((v) => {
                    return (
                      <FoodCard
                        key={`${i}`}
                        title={v?.itemname ?? "mckei"}
                        image={getImageByType(v?.parntname)}
                        subTitle={v?.parntname ?? "gel"}
                        // description={}
                        quantity={v?.quantity ?? "3"}
                        // content={menu?.Addon ?? ""}
                        status={menu?.OrderStatus ?? "accepted"}
                        // feedBack={menu?.feedBack}
                        mealType={v?.parntname}
                        openModel={openModel}
                        data={menu}
                        setSelectedItem={setSelectedItem}
                        onTimeLineClicked={() => onTimeLineClicked(menu)}
                      />
                    );
                  })}
                </>
              );
            })}
          </Grid>
        </Grid>
      ))}
      {/* {orderHistory?.map((menu, i) => (
        <FoodCard
          key={`${i}`}
          title={menu?.MealCode?.MealDisplayName}
          image={`${process.env.REACT_APP_IMAGE_UPLOAD_PROTOCAL}://${process.env.REACT_APP_IMAGE_UPLOAD_HOST}/${process.env.REACT_APP_DB}/${menu?.MealCode?.MealPic}`}
          subTitle={menu?.OrderDate}
          content={menu?.Addon ?? ""}
          status={menu?.CurrantStatus?.states}
          mealType={menu?.OrderOtherDetails}
          openModel={openModel}
          data={menu}
          setSelectedItem={setSelectedItem}
        />
      ))} */}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        // keepMounted
        fullScreen
        onClose={handleClose}
        className={classes.dialogRoot}
      >
        <RatingForm
          handleClose={handleClose}
          selectItem={selectItem}
          ratingItems={ratingItems}
          user={user}
          ratingMaster={ratingMaster}
          setRatingItems={setRatingItems}
        />
      </Dialog>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openm}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openm}>
          <div className={classes.paper}>
            <Box className={classes.Header}>
              <Typography>{"Order Status"}</Typography>
            </Box>
            {mloading ? (
              <div
                style={{
                  height: "calc(100% - 38px)",
                  overflowY: "auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress color="primary" />
              </div>
            ) : (
              <div style={{ height: "calc(100% - 53px)", overflowY: "auto" }}>
                <TimeLine
                  scenarioState={timeline}
                  currantState={currantStatus}
                />
              </div>
            )}
            <IconButton
              className={classes.closeIcon}
              onClick={() => handleClosemodal()}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </Fade>
      </Modal>
    </Grid>
  );
};
