import { toast } from "react-toastify";

export const ToastMessage = (message, severity, position) => {
  toast(message, {
    position: position ?? "top-right",
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    type: severity,
  });
};
