import React from "react";
import { Step, StepLabel, Stepper, Typography } from "@material-ui/core";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import PaymentIcon from "@material-ui/icons/Payment";
import VerifiedUserOutlinedIcon from "@material-ui/icons/VerifiedUserOutlined";
import AutorenewOutlinedIcon from "@material-ui/icons/AutorenewOutlined";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  Stepper: {
    padding: "16px",
  },
  root: {
    width: "100%",
  }
}));

const steps = [
  {
    iconTrue: <VerifiedUserOutlinedIcon color={ "primary"} />,
    iconFalse: <VerifiedUserOutlinedIcon color={ "disabled"} />,
    label: "Verification",
  },
  {
    iconTrue: <AutorenewOutlinedIcon color={ "primary" } />,
    iconFalse: <AutorenewOutlinedIcon color={ "disabled"} />,
    label: "Processing",
  },
  {
    iconTrue: <DescriptionOutlinedIcon color={ "primary" } />,
    iconFalse: <DescriptionOutlinedIcon color={ "disabled"} />,
    label: "Dispatch",
  },
  {
    iconTrue: <LocalShippingIcon color={ "primary" } />,
    iconFalse: <LocalShippingIcon color={ "disabled"} />,
    label: "Dispatch",
  },
  {
    iconTrue: <PaymentIcon color={ "primary" } />,
    iconFalse: <PaymentIcon color={ "disabled"} />,
    label: "Order Received",
  },
  {
    iconTrue: <CheckCircleOutlinedIcon color={ "primary" } />,
    iconFalse: <CheckCircleOutlinedIcon color={ "disabled"} />,

    label: "Completed",
  },
];
const data = [
  {
    label: "Verification",
    optional: "Verify action held on Mon Nov 20 2023",
  },
  {
    label: "Processing",
    optional: "Process action held on Tue Nov 21 2023",
  },
  // {
  //   label: "Dispatch",
  //   optional: "Invoice action held on Wed Nov 22 2023",
  // },
  // {
  //   4: <LocalShippingIcon color={true ? "primary" : "disabled"} />,
  //   label: "Dispatch",
  //   optional: "Dispatch action held on Fri Nov 25 2023",
  // },
  // {
  //   5: <PaymentIcon color={false ? "primary" : "disabled"} />,
  //   label: "Order Received",
  //   optional: "",
  // },
  // {
  //   6: <CheckCircleOutlinedIcon color={false ? "primary" : "disabled"} />,
  //   label: "Completed",
  //   optional: "",
  // },
];

export default function OrderStepper(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(data.length -1);

  return (
    <div className={classes.root}
    >
      <Stepper className={classes.Stepper} activeStep={activeStep}>
        {steps.map((step, index) => {
          const stepProps = {};
          const labelProps = {};
          let op =data.filter((x) => x.label == step.label);
          // console.log(op,step.optional,data,"op");
          if (op.length>0) {
            labelProps.optional = (
              <Typography
                variant="caption"
                color="textSecondary"
                style={{ fontSize: "9px" }}
              >
                {op[0].optional}
              </Typography>
            );
          }
          return (
            <Step key={index + 1} {...stepProps}>
              <StepLabel icon={ op.length>0 ? step.iconTrue : step.iconFalse} {...labelProps}>
                {step.label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
}
