import React from 'react';
import { makeStyles, Typography, Grid } from "@material-ui/core";
import PropTypes from "prop-types";

import ReactImageMagnify from "react-image-magnify";

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiFormControlLabel-label': {
            textTransform: 'capitalize'
        }
    },
    errorText: {
        color: 'red',
        fontSize: '0.75rem'
    }
}));

const ImageUpload = ({

    heading,
    isrequired,
    errorValidation = {},
    image64 = '',
    id='',
    disabled = false

}) => {

    const classes = useStyles();

    const imageBaseUrl = image64[0]?.file;

    return (
        <div className={classes.root} style={{display : disabled ? "none" : ""}}>
            <Typography variant="body1" style={{ marginBottom: "4px" }}>{heading} {isrequired && <Typography variant="caption" style={{ color: "red" }}>*</Typography>}</Typography>
            <Grid
                container
            >
                <Grid
                    item
                >
                    <div style={{ position: 'relative', zIndex: '1' }} id={"image +"+id}>
                        <ReactImageMagnify
                            {...{
                                smallImage: {
                                    alt: "image",
                                    isFluidWidth: true,
                                    src: `${imageBaseUrl}`,
                                    sizes:
                                        "(min-width: 800px) 33.5vw, (min-width: 415px) 50vw, 100vw"
                                },
                                largeImage: {
                                    alt: "zoomed img",
                                    src: `${imageBaseUrl}`,
                                    width: 1200,
                                    height: 1800,

                                },
                                isHintEnabled: true
                            }}
                        />
                    </div>
                </Grid>
            </Grid>

            {
                errorValidation && errorValidation?.error &&
                <div><Typography variant="caption" className={classes.errorText}>{errorValidation?.errorMessage}</Typography></div>
            }
        </div>
    )
}
ImageUpload.propTypes = {

    heading: PropTypes.string,
    id: PropTypes.string
}

export default ImageUpload;