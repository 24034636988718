import { Dialog, DialogContent } from "@material-ui/core";
import {
  Divider,
  TextField,
  Typography,
  Paper,
  Grid,
  Button,
} from "@material-ui/core";
import { config } from "dotenv";
import React, { useState } from "react";
import { AxiosCall } from "../../../utils";
import { async } from "taskviewersurveillance";
import Config from "../../../config";
import { ToastMessage } from "../../../components";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../router/routes";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useForm } from "react-hook-form"
function Resetcode(props) {
  const history = useHistory();
  const [code, setCode] = useState("");
  const form = useForm({
    defaultValues: {
      code: ""
    },
  });
  const { register, handleSubmit, formState, getValues, control, resetField, setError } = form;
  const { errors } = formState;
 // console.log(code, "errors");

  const handleVerifyMail = async (data) => {
    let params = {
      email: data?.email,
      tenantid: process.env.REACT_APP_IDM_TENANT_ID,
    };
    let customheader = { tenantid: process.env.REACT_APP_IDM_TENANT_ID };
    let getAccess = await AxiosCall(
      "post",
      Config.forgot,
      params,
      customheader
    );
    if (getAccess.Code == 201) {
      {
        history.push(Routes.Completed);
      }
      // ToastMessage(`successfully generated`, "success");
    } else {
      ToastMessage(`user does not exist`, "error");
    }
  };

  const handleClose = () => {
    history.push(Routes.login);
  };
  return (
    <div>
      <Dialog open={true} style={{ borderRadius: "16px" }}>
        <DialogContent
          style={{
            padding: "0px",
            height: "25rem",
            width: "19rem",
          }}
        >
          <Grid style={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton
              style={{ padding: "0.5rem 1rem 0rem 0rem" }}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          {/* <Grid item style={{ padding: "1em 0em 1em 1em" }}>
            
          </Grid> */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: "10.5rem",
            }}
          >
            <img
              src="images/pintar2ulogo.webp"
              alt="icon"
              style={{
                width: "9.2rem",
                height: "9.2rem",
                objectFit: "cover",
                zoom: "1.3"
              }}
            />
          </div>

          <Grid container style={{ display: "flex", justifyContent: "center" }}>
            <Grid container style={{ padding: "1em  1em " }}>
              <Typography>Email</Typography>
              <TextField
                id="email"
                // style={{ borderRadius: "8px", backgroundColor: "#ECF0F8" }}
                // value={code}
                placeholder="Enter your Email"
                variant="outlined"
                size="small"
                fullWidth
                // onChange={(e) => setCode(e.target.value)}
                {...register("email", {
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                })}
                error={
                  errors?.email
                }
                helperText={
                  errors?.email?.message
                }
              />
            </Grid>
            <Grid
              container
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "0.5em",
                padding: "1em 1em 2em ",
              }}
            >
              <Button
                size="small"
                color="primary"
                variant="contained"
                style={{ width: "100%" }}
                onClick={handleSubmit(handleVerifyMail)}
              >
                Verify Email
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Resetcode;
