import * as React from "react";
import PropTypes from "prop-types";

const Clock = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
    <path id="icons8-clock" d="M11,4a7,7,0,1,0,7,7A7.008,7.008,0,0,0,11,4Zm0,1.05A5.95,5.95,0,1,1,5.05,11,5.942,5.942,0,0,1,11,5.05Zm-.183,1.742a.525.525,0,0,0-.517.533v4.2a.525.525,0,0,0,.525.525h2.8a.525.525,0,1,0,0-1.05H11.35V7.325a.525.525,0,0,0-.533-.533Z" transform="translate(-4 -4)"
     fill={props?.color}/>
  </svg>
);

export default Clock;

Clock.defaultProps = {
  color: "black",
};

Clock.propTypes = {
  color: PropTypes.string,
};