import * as React from "react";
import PropTypes from "prop-types";

const DrugPrint = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16">
    <path id="Path_95329" data-name="Path 95329" d="M15.76,5.521h-1.3V2.333A2.3,2.3,0,0,0,12.206,0H5.695A2.285,2.285,0,0,0,3.45,2.324v3.2H2.237A2.276,2.276,0,0,0,0,7.836v7A1.143,1.143,0,0,0,1.123,16H16.889A1.132,1.132,0,0,0,18,14.85V7.839a2.28,2.28,0,0,0-2.24-2.318m-11.185,0v-3.2a1.14,1.14,0,0,1,1.119-1.16h6.512a1.149,1.149,0,0,1,1.13,1.167l.007,3.19v1.02l0,.144.007,3.193-8.776.016V6.172m-3.45,8.193V7.836a1.131,1.131,0,0,1,1.112-1.15H3.45V9.894A1.128,1.128,0,0,0,4.56,11.042h8.792a1.128,1.128,0,0,0,1.109-1.148V6.685h1.3a1.135,1.135,0,0,1,1.115,1.152l.012,6.509a.482.482,0,0,1-.474.49H1.581a.463.463,0,0,1-.456-.472m13.07-1.6a.783.783,0,1,1,.783.81.8.8,0,0,1-.783-.81M7.821,4.381H6.139a.361.361,0,0,1-.353-.364V2.976a.361.361,0,0,1,.353-.366H7.821a.36.36,0,0,1,.353.366V4.017a.36.36,0,0,1-.353.364M11.909,4.1H9.682a.36.36,0,0,1-.352-.366V3.254a.361.361,0,0,1,.352-.366h2.227a.362.362,0,0,1,.353.366v.483a.361.361,0,0,1-.353.366M6.777,8.76H6.139a.361.361,0,0,1-.353-.366V6.146a.362.362,0,0,1,.353-.366h.638a.361.361,0,0,1,.353.366V8.395a.36.36,0,0,1-.353.366m2.2,0H8.339a.36.36,0,0,1-.353-.366V6.146a.361.361,0,0,1,.353-.366h.638a.362.362,0,0,1,.353.366V8.395a.361.361,0,0,1-.353.366M12,8.76H10.522a.36.36,0,0,1-.353-.366V6.146a.361.361,0,0,1,.353-.366H12a.361.361,0,0,1,.353.366V8.395A.36.36,0,0,1,12,8.76"
     fill={props.color}/>
  </svg>
);

export default DrugPrint;

DrugPrint.defaultProps = {
  color: "#2A3C50",
};

DrugPrint.propTypes = {
  color: PropTypes.string,
};
