import {
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  TextField,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Radio,
  Divider,
  Tooltip,
} from "@material-ui/core";
import DateRangeIcon from "@material-ui/icons/DateRange";
import PrintRoundedIcon from "@mui/icons-material/PrintRounded";
import RefreshIcon from '@mui/icons-material/Refresh';
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core";
import React from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import { Cards } from "../card";
import { PageEmpty } from "../pageEmpty";
import axios from "axios";
import TreeView from "../tree";
const useStyles = makeStyles((theme) => ({
  container: {
    width: 500,
    borderRight: "1px solid #e0e0e0",
  },
  Header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 8px",
  },
  Header2: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 16px",
    height: "52px",
  },
  flex: {
    display: "flex",
    justifyContent: "space-around",
  },
}));

export const SidePanel = (props) => {
  const {
    logState = null,
    onCardClicked = () => false,
    isLoading = false,
    queueId,
    scenarioId,
    each,
    stateid,
    getTaskViewerConifg = () => false,
    setAlignByDate = () => false,
    setAlignByPharmacy = () => false,
    setQueue = () => false,
    onFavIconClicked,
    selectedTask,
    PermissionData,
    treedata,
    Alignpharmacychecked,
    Aligndatechecked,
    scenarioState
  } = props;
  const classes = useStyles();
  const [tasklist, setTask] = React.useState();
  const [treeeee, setTree] = React.useState();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  React.useEffect(() => {
    setTask(logState);
    setTree(treedata);
    handleDateChange(new Date());
    setFromDate(null);
    setToDate(null);
  }, [treedata]);
  const [search, setSearch] = React.useState("");
  const [checked, setChecked] = React.useState(false);
  const [dateType, setDateType] = React.useState("single_date");
  const [selectedDate, handleDateChange] = React.useState(new Date());
  const [fromDate, setFromDate] = React.useState(null);
  const [toDate, setToDate] = React.useState(null);
  // const [FilterBy, setFilterBy] = React.useState([
  //   { isChecked: false, label: "Sort By A-Z", title: "title" },
  //   { isChecked: false, label: "Sort By Task-Id", title: "subtitle2" },
  // ]);
  const [ticketchecked, setticketchecked] = React.useState(false);
  const [dateCheck, setDateCheck] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const getDateMonthYear = (date) => {
    let dateVal =
      new Date(date).getDate().toString().length === 1
        ? `0${new Date(date).getDate()}`
        : new Date(date).getDate();
    let monthVal =
      (new Date(date).getMonth() + 1).toString().length === 1
        ? `0${new Date(date).getMonth() + 1}`
        : new Date(date).getMonth() + 1;
    let yearVal = new Date(date).getFullYear();
    return `${yearVal}-${monthVal}-${dateVal}`;
  };
  const FilterRange = () => {
    if (
      fromDate !== null &&
      !isNaN(fromDate) &&
      toDate !== null &&
      !isNaN(toDate)
    ) {
      let Res = [];
      logState.forEach((e) => {
        let startDate = new Date(getDateMonthYear(fromDate));
        let endDate = new Date(getDateMonthYear(toDate));
        let CompDate = new Date(e.field2);
        if (CompDate >= startDate && CompDate <= endDate) {
          Res.push(e);
        }
      });
      setTask(Res.reverse());
    } else {
      setTask(logState);
    }
  };
  const FilterDate = (date) => {
    if (date !== null && !isNaN(date)) {
      let stringDate = getDateMonthYear(date);
      let Res = [];
      logState.forEach((e) => {
        let Convert = e.field2.includes("/")
          ? e.field2.split("/").reverse().join("-")
          : e.field2;

        if (stringDate === getDateMonthYear(Convert)) {
          Res.push(e);
        }
      });
      setTask(Res);
    } else {
      setTask(logState);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onHandleChange = (e) => {
    setSearch(e.target.value.toLowerCase());
    const list = treedata.filter((l) =>
      l?.str_name?.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setTree(list);
  };

  const onSortClick = () => {
    if (!checked) {
      setChecked(true);
      setticketchecked(false);
      setDateCheck(false);
      const sortlist = treedata.sort(function (a, b) {
        const nameA = a.str_name.toUpperCase();
        const nameB = b.str_name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      setTree(sortlist);
      handleClose();
    } else {
      setChecked(false);
      getTaskViewerConifg(queueId, scenarioId, each, stateid);
    }
  };
  const onAlignDateClick = () => {
    if (!Aligndatechecked) {
      setAlignByDate(true);
      handleClose();
    } else {
      setAlignByDate(false);
      handleClose();
    }
  };
  const onAlignPharmacyClick = () => {
    if (!Alignpharmacychecked) {
      setAlignByPharmacy(true);
      getTaskViewerConifg(queueId, scenarioId, each, stateid);
      handleClose();
    } else {
      setAlignByPharmacy(false);
      handleClose();
    }
  };
  const onSortticketClick = (filterBy) => {
    if (!ticketchecked) {
      setticketchecked(true);
      setChecked(false);
      setDateCheck(false);
      const sortlist = tasklist.sort(function (a, b) {
        const nameA = a.field6.toUpperCase();
        const nameB = b.field6.toUpperCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });

      setTask(sortlist);
      handleClose();
    } else if (!checked) {
      setticketchecked(false);
      setChecked(false);
      setDateCheck(true);
      const sortlist = tasklist.sort(function (a, b) {
        const nameA = a.field9.toUpperCase();
        const nameB = b.field9.toUpperCase();

        return nameA - nameB;
      });

      setTask(sortlist);
      handleClose();
    } else {
      setticketchecked(false);
      setDateCheck(false);
      getTaskViewerConifg(queueId, scenarioId, each, stateid);
    }
  };

  const RefreshIconClick = () =>{
    setQueue()
  }
  const clearDateFilter = () => {
    setToDate(null);
    setFromDate(null);
    setTask(logState);
    handleDateChange(null);
    // dateCheck === true && handleClose();
    // setDialogOpen(false);
  };
  const PrintAll = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      ticketId: logState.map((e) => e.ticketId),
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_PRINT_API, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        axios(result.response, {
          method: "GET",
          responseType: "blob", //Force to receive data in a Blob Format
        })
          .then((response) => {
            //Create a Blob from the PDF Stream
            const file = new Blob([response.data], { type: "application/pdf" });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            window.open(fileURL);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => console.log("error", error));
  };
  return (
    <>
      <div className={classes.container}>
        <div className={classes.Header}>
          <Typography variant="body1" noWrap>
            {"Action Item"}
          </Typography>

          {/* <Button
            variant="outlined"
            onClick={() => setDialogOpen((pre) => !pre)}
            endIcon={<DateRangeIcon />}
          >
            {selectedDate !== null &&
            selectedDate.length !== 0 &&
            dialogOpen === false
              ? getDateMonthYear(selectedDate)
              : "Filter By Date"} 
            Filter By Date
          </Button> */}

          <div className={classes.flex}>
            {/* For Filtering Feature */}
            <div style={{ padding: "3px 5px" }}>
              <Tooltip title="refresh" placement="left">
                <IconButton
                  size="small"
                  onClick={() => RefreshIconClick()}
                  style={{ borderRadius: "8px", border: "1px solid #e0e0e0" }}
                >
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
            </div>
            <div style={{ padding: "3px 5px" }}>
              <Tooltip title="Filter" placement="right">
                <IconButton
                  size="small"
                  style={{ borderRadius: "8px", border: "1px solid #e0e0e0" }}
                  onClick={(e) => handleClick(e)}
                >
                  <FilterListIcon />
                </IconButton>
              </Tooltip>
            </div>

            {/* <IconButton size="small">
              <MoreVertIcon />
            </IconButton> */}
          </div>
        </div>

        <Grid style={{ marginLeft: "20px", marginRight: "20px" }} item xs={12}>
          <TextField
            id="outlined-name"
            variant="outlined"
            name="Search"
            value={search}
            onChange={(e) => onHandleChange(e)}
            placeholder="Search"
            fullWidth
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        {treeeee?.length > 0 ? (
          <Grid style={{ height: "calc(100% - 120px)", overflowX: "hidden" }}>
            <TreeView treedata={treeeee} onCardClicked={onCardClicked} scenarioState={scenarioState} stateid={stateid} />
          </Grid>
        ) : (
          <Grid
            container
            style={{
              padding: "8px",
              height: "calc(100% - 120px)",
              display: "grid",
              placeItems: "center",
              width: "100%",
            }}
          >
            {isLoading ? <CircularProgress /> : <PageEmpty />}
          </Grid>
        )}
        <Dialog open={dialogOpen} onClose={() => setDialogOpen((pre) => !pre)}>
          <div>
            <DialogTitle id="alert-dialog-slide-title">
              Filter By Date
            </DialogTitle>
            <Divider />
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item lg={12} sm={12} md={12}>
                  <Typography>Date Type</Typography>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => setDateType("single_date")}
                  >
                    <Radio
                      checked={dateType === "single_date"}
                      onChange={(e) => setDateType(e.target.value)}
                      value="single_date"
                      color="default"
                      name="radio-button-demo"
                      inputProps={{ "aria-label": "E" }}
                      size="small"
                    />
                    <Typography>Single Date</Typography>
                  </span>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => setDateType("range_wise")}
                  >
                    <Radio
                      checked={dateType === "range_wise"}
                      onChange={(e) => setDateType(e.target.value)}
                      value="range_wise"
                      color="default"
                      name="radio-button-demo"
                      inputProps={{ "aria-label": "E" }}
                      size="small"
                    />
                    <Typography>Range Wise</Typography>
                  </span>
                </Grid>
                <Grid
                  item
                  lg={dateType === "range_wise" ? 6 : 12}
                  sm={dateType === "range_wise" ? 6 : 12}
                  md={dateType === "range_wise" ? 6 : 12}
                >
                  <Typography>
                    {dateType === "range_wise" ? "From" : "Custom Date"}
                  </Typography>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      fullWidth
                      inputVariant="outlined"
                      variant="inline"
                      autoOk
                      size="small"
                      placeholder="dd-mm-yyyy"
                      value={
                        dateType === "range_wise" ? fromDate : selectedDate
                      }
                      onChange={(date) =>
                        dateType === "range_wise"
                          ? setFromDate(date)
                          : handleDateChange(date)
                      }
                      format="yyyy/MM/dd"
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                {dateType === "range_wise" && (
                  <Grid item lg={6} sm={6} md={6}>
                    <Typography>To</Typography>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        fullWidth
                        inputVariant="outlined"
                        variant="inline"
                        autoOk
                        size="small"
                        placeholder="dd-mm-yyyy"
                        value={toDate}
                        onChange={(date) => setToDate(date)}
                        format="yyyy/MM/dd"
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                )}
              </Grid>
            </DialogContent>
            <Divider />
            <DialogActions>
              <Button
                onClick={() => (
                  dateType !== "range_wise"
                    ? FilterDate(selectedDate)
                    : FilterRange(),
                  setDialogOpen((pre) => !pre),
                  handleClose(),
                  setDateCheck((pre) => !pre)
                )}
                color="primary"
                variant="outlined"
              >
                Apply
              </Button>
              <Button
                onClick={() => (
                  setDialogOpen(false),
                  dateCheck &&
                    selectedDate === null &&
                    fromDate === null &&
                    toDate == null &&
                    setDateCheck(false),
                  handleClose()
                )}
                color="primary"
                variant="outlined"
              >
                Cancel
              </Button>
              {(selectedDate !== null ||
                fromDate !== null ||
                toDate !== null) && (
                <Button
                  onClick={() => clearDateFilter()}
                  color="primary"
                  variant="outlined"
                >
                  Clear
                </Button>
              )}
            </DialogActions>
          </div>
        </Dialog>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <List dense>
            <ListItem
              button
              dense
              disableGutters
              disablePadding
              onClick={() => onSortClick()}
              style={{ padding: "0 10px" }}
            >
              {/* <ListItemButton role={undefined} > */}
              <ListItemIcon style={{ minWidth: "auto" }}>
                <Checkbox
                  edge="start"
                  checked={checked}
                  disableRipple
                  inputProps={{ "aria-labelledby": "labelId" }}
                />
              </ListItemIcon>
              <ListItemText id={"labelId"} primary="Sort By A-Z" />
            </ListItem>
            {/* <ListItem
              dense
              disableGutters
              disablePadding
              button
              onClick={() => onSortticketClick()}
              style={{ padding: "0 10px" }}
            >
              <ListItemIcon style={{ minWidth: "auto" }}>
                <Checkbox
                  edge="start"
                  checked={ticketchecked}
                  disableRipple
                  inputProps={{ "aria-labelledby": "labelId" }}
                />
              </ListItemIcon>
              <ListItemText id={"labelId"} primary="Sort By Task-ID" />
            </ListItem> */}
            {/* <ListItem
              dense
              disableGutters
              disablePadding
              button
              onClick={() => (
                // dateType !== "range_wise"
                //   ? FilterDate(selectedDate)
                //   : FilterRange(),
                setDialogOpen((pre) => !pre),
                setticketchecked(false),
                setChecked(false)
              )}
              style={{ padding: "0 10px" }}
            >
              <ListItemIcon style={{ minWidth: "auto" }}>
                <Checkbox
                  edge="start"
                  checked={dateCheck}
                  disableRipple
                  inputProps={{ "aria-labelledby": "labelId" }}
                />
              </ListItemIcon>
              <ListItemText id={"labelId"} primary="Sort By Date" />
            </ListItem> */}
            {/* Filter by ticket id and alphabet feature */}
            <ListItem
              dense
              disableGutters
              disablePadding
              button
              onClick={() => onAlignDateClick()}
              style={{ padding: "0 10px" }}
            >
              <ListItemIcon style={{ minWidth: "auto" }}>
                <Checkbox
                  edge="start"
                  checked={Aligndatechecked}
                  disableRipple
                  inputProps={{ "aria-labelledby": "labelId" }}
                />
              </ListItemIcon>
              <ListItemText id={"labelId"} primary="Group By Date" />
            </ListItem>
            <ListItem
              dense
              disableGutters
              disablePadding
              button
              onClick={() => onAlignPharmacyClick()}
              style={{ padding: "0 10px" }}
            >
              <ListItemIcon style={{ minWidth: "auto" }}>
                <Checkbox
                  edge="start"
                  checked={Alignpharmacychecked}
                  disableRipple
                  inputProps={{ "aria-labelledby": "labelId" }}
                />
              </ListItemIcon>
              <ListItemText id={"labelId"} primary="Group By Pharmacy" />
            </ListItem>
          </List>
        </Popover>
      </div>
    </>
  );
};
