import {
  Box,
  CircularProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { actions } from "central_pharma_binder";
import React from "react";
import { useDispatch } from "react-redux";
import { SearchTextField } from "../../components";
import { ListOfCards } from "./listOfCards";
import { AxiosCall } from "../../api";
const useStyles = makeStyles((theme) => ({
  body: {
    height: "calc(100% - 55px)",
    padding: "8px",
    margin: "16px 0",
  },
  boxCenter: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  captionText: {
    fontWeight: "600",
    color: theme.palette.text.disabled,
  },
  searchText: {
    paddingTop: "16px"
  }
}));

export const KitchenOrderingForPatient = (props) => {
  const { updateSelectedPatient } = props;
  const classes = useStyles();
  const [search, setSearch] = React.useState("");
  const [patientList, setPatientList] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const [eptyScrnMesg, setEptyScrnMesg] = React.useState(
    "Please search by Pharmacy Name"
  );

  const onHandleChange = (e) => {
    if (e.target.value.length !== 0) {
      setSearch(e.target.value);
    } else {
      setSearch("");
      setPatientList([]);
    }
  };

  const onEnterClicked = (e) => {
    if (e.key === "Enter") {
      onSearchClicked();
    }
  };

  const onSearchClicked = async () => {
    setLoading(true);
    let searchValue = search;

    if (search.trim().length > 0) {
      let reportingOrg = localStorage.getItem("ReportingOrg") ?? ""
      let adminQuery = reportingOrg === "" ? {
        "Bed": `LIKE(Bed.BedNumber,'%${search}%',true) AND Bed.activestatus==true`,
        "QDMATPtransactionlog": `QDMATPtransactionlog.ticketId==Bed.ticketId and QDMATPtransactionlog.payload.statesid[0]=='${process.env.REACT_APP_CHECK_COMPLETED_STATE}'  `
      } : {
        "Bed": `LIKE(Bed.BedNumber,'%${search}%',true) AND Bed.activestatus==true`,
        "QDMATPtransactionlog": `QDMATPtransactionlog.ticketId==Bed.ticketId and QDMATPtransactionlog.payload.statesid[0]=='${process.env.REACT_APP_CHECK_COMPLETED_STATE}' and QDMATPtransactionlog.payload.inputDoc.mappedData.ReportingOrganization.value=='${reportingOrg}' `
      }
      let params = {
        db_name: process.env.REACT_APP_DB,
        entity: "Bed,QDMATPtransactionlog",
        filter: adminQuery,
        return_fields: "merge(Bed,{State:QDMATPtransactionlog.payload.inputDoc.mappedData.State,Region:QDMATPtransactionlog.payload.inputDoc.mappedData.Region, ReportingOrganization:QDMATPtransactionlog.payload.inputDoc.mappedData.ReportingOrganization})"
      }

      let res = await AxiosCall(
        "post",
        process.env.REACT_APP_API_URL,
        params
      );
      let result = res?.result;
      //setUser(result);
      // console.log("result", result)

      if (result.length > 0) {
        setPatientList(result);
        setLoading(false);
      } else {
        setLoading(false);
        setPatientList([]);

        setEptyScrnMesg("No data available");
      }
    } else {
      setLoading(false);
      setPatientList([]);
      setEptyScrnMesg("No data available");
    }
  };

  return (
    <div className={classes.searchText}>
      <SearchTextField
        value={search}
        onHandleChange={(e) => onHandleChange(e)}
        onKeyPress={(e) => onEnterClicked(e)}
        onSearchClicked={() => onSearchClicked()}
        placeholder="Search by Pharmacy Name"
      />
      <Box className={classes.body}>
        {loading && patientList?.length === 0 && (
          <div className={classes.boxCenter}>
            <CircularProgress />
          </div>
        )}
        {!loading && patientList?.length === 0 && (
          <div className={classes.boxCenter}>
            <Typography variant="body2" className={classes?.captionText}>
              {`" ${eptyScrnMesg} "`}
            </Typography>
          </div>
        )}
        {!loading && patientList?.length > 0 && (
          <ListOfCards
            patientList={patientList}
            updateSelectedPatient={updateSelectedPatient}
          />
        )}
      </Box>
    </div>
  );
};
