import React from "react";
import { withRouter, Prompt } from "react-router-dom";
import { withStyles, Grid, makeStyles } from "@material-ui/core";
//import From from "./formGenerator";
import FormTree from "./formTree";
import { actions } from "central_pharma_binder";
import { connect } from "react-redux";
//import AgreeDailog from "./agreeDialog";
import { SubHeader, CaseDefinition } from "../../components";
import { Message } from "../../utils";
import { withAllContexts, withNavBars } from "../../HOCs";
// import { DrawerContext } from '../../contexts';
import { DrawerProps } from "../../utils";
import Form from "./formGenerator";
import { useDispatch, useSelector } from "react-redux";
import { Routes } from "../../router/routes";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme?.palette?.background?.default,
  },
  gridArea: {
    paddingLeft: theme?.spacing(2),
    paddingRight: theme?.spacing(2),
    paddingBottom: theme?.spacing(2),
  },
}));

export const FormConfigure = (props) => {
  const [state, setState] = React.useState({
    message: "You are in profile page.",
    section_id: null,
    id_edit: false,
    openAgreeDailog: false,
    skipSectionId: null,
    section_detail_id: null,
    save: false,
    showPreview: false,
    set: false,
    render: false,
    formId: null,
    formTitle: "",
    is_sectionUpdate: false,
    formTemplate: null,
    templateFormAddStatus: {},
  });

  const [runUse, setRunUse] = React.useState(true);

  const ID = window.atob(localStorage.getItem("formId"));

  //console.log(props, "FormConfigProps");

  const classes = useStyles();
  const dispatch = useDispatch();

  const onUnload = (e) => {
    e.preventDefault();
    if (state.id_edit) {
      e.returnValue = "";
    }
  };

  React.useEffect(() => {
    getFormDetail();
    getTemplateForms();
    getFormComponents();
    window.addEventListener("beforeunload", onUnload);
  }, []);

  const formsDetails = useSelector((state) => ({
    details: state?.formSlice?.getFormDetailById,
    formUpdate: state?.formconf?.upsert_question ?? { loading: false },
    form_detail: state?.renderSlice?.getRenderJson,
    auth: state?.authSlice?.login,
    getFormTemplate: state?.formSlice?.getFormTemplate,
  }));

  const { data, loading } = formsDetails.details;
  const previewFormDetail = formsDetails.form_detail;

  const formTemplateData = formsDetails.getFormTemplate;

  const [formTreeViewData, setFormTreeViewData] = React.useState(null);

  const onBackBtnClicked = () => {
    props.history.goBack();
    // props.history.push(Routes.notify);
  };

  const setTemplateFormFunction = (value) => {
    if (value !== null) {
      setState({ ...state, formTemplate: value });
    } else {
      setState({ ...state, formTemplate: null });
    }
  };

  const setTemplateFormAddStatus = (val, type) => {
    if (val === null && type === null) {
      setState({
        ...state,
        templateFormAddStatus: {},
        formTemplate: null,
      });
    }
    if (type === "Form" && val !== null) {
      setState({ ...state, templateFormAddStatus: val });
    }
  };

  const getSectionId = (data, parentSection) => {
    let section_detail_id = data?.section?.id;
    if (state.id_edit) {
      setState({
        ...state,
        formId: data.id,
        formTitle: data.section_name,
        section_detail_id,
        skipSectionId: data?.id ?? null,
        openAgreeDailog: true,
        selectedSection: data,
        parentSection: parentSection,
      });
    } else {
      setState({
        ...state,
        formId: data.id,
        formTitle: data.section_name,
        section_detail_id,
        section_id: data?.id ?? null,
        selectedSection: data,
        parentSection: parentSection,
      });
    }
  };

  const skipIsedit = (type) => {
    if (type === "error") {
      setState({
        ...state,
        openAgreeDailog: false,
        save: false,
      });
      return false;
    }
    if (state.skipSectionId) {
      setState({
        ...state,
        openAgreeDailog: false,
        section_id: state.skipSectionId,
        skipSectionId: null,
        save: false,
        id_edit: false,
      });
    } else if (state.set) {
      setState({
        ...state,
        openAgreeDailog: false,
        //   skipSectionId: null,
        showPreview: true,
        save: false,
        id_edit: false,
      });
    } else {
      setState({
        ...state,
        openAgreeDailog: false,
        skipSectionId: null,
        save: false,
        id_edit: false,
      });
      setTimeout(() => {
        props.history.goBack();
      }, 1000);
    }
  };

  const getFormDetail = () => {
    let id = window.atob(localStorage.getItem("formId"));
    //console.log(id, "FormIDSSS");
    let params = {
      form_id: id,
    };
    dispatch(actions.GET_FORM_DETAIL_BY_ID(params));
    dispatch(actions.GET_RENDER_JSON({ formId: id }));
  };

  const getTemplateForms = () => {
    dispatch(actions.GET_FORM_TEMPLATE());
  };

  const getFormComponents = () => {
    dispatch(actions.GET_FORM_COMPONENTS());
  };

  const getSectionComponents = () => {
    let section_detail_id = state?.section_id;
    setState({
      section_id: section_detail_id,
    });
  };

  const checkFormEdit = (val, isSectionUpdate) => {
    setState(
      { ...state, id_edit: val, is_sectionUpdate: isSectionUpdate },
      () => {
        setState({
          is_sectionUpdate: false,
        });
      }
    );
  };

  const goPreview = (val) => {
    if (state.id_edit) {
      setState({
        ...state,
        openAgreeDailog: val,
        set: true,
      });
    } else {
      setState({
        ...state,
        showPreview: val,
      });
    }
  };

  return (
    <div className={classes.root}>
      <SubHeader
        title={loading ? Message.form_detail_loading : data?.title}
        subTitle={!loading ? `(${data?.formType?.type ?? "Form Type"})` : ""}
        onBackBtnClicked={onBackBtnClicked}
        /* onActionIconBtnClicked={onInfoClicked} */
        showInfoButton
      />

      <Grid
        className={classes.gridArea}
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
          {/* {!state.is_sectionUpdate && ( */}
          <FormTree
            selected_section_id={state.section_id}
            getSectionId={getSectionId}
            getTemplateForms={formTemplateData}
            id={ID ?? null}
            formTemplateData={formTemplateData}
            setTemplateFormFunction={setTemplateFormFunction}
            setTemplateFormAddStatus={setTemplateFormAddStatus}
            runUse={runUse}
            setRunUse={setRunUse}
          />
          {/* )} */}
        </Grid>

        <Grid item xs={12} sm={9} md={9} lg={9} xl={9}>
          <Form
            section_id={state?.section_id ?? null}
            checkFormEdit={checkFormEdit}
            parentSection={state.parentSection}
            save={state.save}
            handleClose={skipIsedit}
            section_detail_id={state.section_detail_id}
            id={ID ?? null}
            saveToMove={state.id_edit}
            preview={state.showPreview}
            goPreview={goPreview}
            reRender={state.render}
            previewFormDetail={previewFormDetail}
            formId={state.formId}
            formTitle={state.formTitle}
            selectedSection={state.selectedSection}
            templatedata={state?.formTemplate}
            templateFormAddStatus={state?.templateFormAddStatus}
            setTemplateFormAddStatus={setTemplateFormAddStatus}
            getTemplateFormsFunction={getTemplateForms}
            getSectionComponents={getSectionComponents}
            runUse={runUse}
            setRunUse={setRunUse}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  //console.log(state.formSlice, "Form Slice States...");
  return {
    details: state?.formSlice?.getFormDetailById,
    formUpdate: state?.formconf?.upsert_question ?? { loading: false },
    form_detail: state?.renderSlice?.getRenderJson,
    auth: state?.authSlice?.login,
    getFormTemplate: state?.formSlice?.getFormTemplate,
  };
};

/* const mapStateToProps = (state) => ({
  details: state?.formSlice?.getFormDetailById,
  formUpdate: state?.formconf?.upsert_question ?? { loading: false },
  form_detail: state?.renderSlice?.getRenderJson,
  auth: state?.authSlice?.login,
  getFormTemplate: state?.formSlice?.getFormTemplate,
}); */

export default connect(
  mapStateToProps,
  actions
)(withAllContexts(withRouter(FormConfigure)));
