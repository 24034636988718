import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TreeView from "./treeStuct";
import { alpha, makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { CommonTabList } from "../../components/common";
import { OrgView } from "./orgView";
import FormRenderer from "../OrgFormRenderer";
// import FormRendererParent from "../registrationViewer/TaskViewer/screens/formRenderer";
import { aqlQuery } from "../../utils";
import axios from "axios";
import TreeItem from "@material-ui/lab/TreeItem";
import Typography from "@material-ui/core/Typography";
import { IoMdBed } from "react-icons/io";
import { TbBuildingHospital } from "react-icons/tb";
import BusinessIcon from "@material-ui/icons/Business";
import { MdMeetingRoom } from "react-icons/md";
import { FaClinicMedical } from "react-icons/fa";
import { AlertContext, BackdropContext } from "../../contexts";
import ApartmentIcon from "@material-ui/icons/Apartment";
import { async } from "central_pharma_binder";
import Formreturn from "./Formreturn";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "central_pharma_binder";
import { connect } from "react-redux";
import { withAllContexts } from "../../HOCs";
import { AlertProps } from "../../utils";
import { CircularProgress } from "@material-ui/core";
import { PageEmpty } from "../registrationViewer/TaskViewer/components";
import { AxiosCall } from "../../utils";
import { log } from "qdm-audit-logger";

const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    "&:hover > $content": {
      color: "var(--tree-view-color)",
      backgroundColor: "#fff",
    },
    "&:focus > $content, &$selected > $content": {
      color: "var(--tree-view-color)",
      backgroundColor: `var(--tree-view-bg-color)`,
    },
    "&:focus > $content $label, &:hover > $content $label, &$selected > $content $label":
      {
        color: "var(--tree-view-color)",
      },
    "&$selected > $content $label": {
      color: "var(--tree-view-color)",
      backgroundColor: `var(--tree-view-bg-color)`,
    },
  },
  content: {
    color: theme.palette.text.primary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    "$expanded > &": {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 7,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
  label: {
    fontWeight: "inherit",
    color: "inherit",
  },
  labelRoot: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: "inherit",
    flexGrow: 1,
    textAlign: "left",
    fontSize: 13,
  },
  selected: {
    color: "var(--tree-view-color)",
  },
}));

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    height: "100%",
    backgroundColor: "white",
  },
  backgroundColor: {
    backgroundColor: "transparent",
    padding: 0,
  },
});
const DemoNameChange = {
  Organization: "Enterprise",
  Bed: "Facility",
  Room: "Group",
  Ward: "Country",
  Facility: "Organization",
};

class Organization extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openTree: true,
      form: false,
      key: null,
      treeView: 0,
      entity: null,
      disableAddNew: false,
      formRendering: {},
      showForm: false,
      form_id: null,
      entry_id: null,
      ticketId: null,
      loading: false,
      data: null,
      reload: false,
    };
  }

  // componentDidMount() {

  // }

  getFormFromExecuteAql = async (formId, ticketId) => {
    let params = {
      db_name: `${process.env.REACT_APP_DBNAME}`,
      query: aqlQuery.getFormData(formId, ticketId),
    };
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_READ_DOCUMENT}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: params,
    };

    await axios(config)
      .then((res) => {
        let aqldata = res.data[0];

        let answerArr = aqldata?.bulkAnswers?.answers ?? [];
        let answer = {};
        answerArr?.map((l) => (answer[l.question_id] = l.answer));

        let updateState = {
          formData: aqldata,
          selected_form: aqldata?.form[0],
          answer: answer,
          _id: aqldata?.bulkAnswers?.entry_id,
          secName: aqldata?.bulkAnswers?.secName ?? 1,
        };

        // this.setState({
        //   ...this.state,
        //   formRendering: updateState,
        //   showForm: true,
        // })
        return updateState;
        // setFormRendering(updateState);
        // setShowForm(true);
      })
      .catch((err) => {
        console.log(err);
        return null;
      });
  };

  getEntryId = async (ticketId) => {
    let params2 = {
      db_name: `${process.env.REACT_APP_DBNAME}`,
      filter: {
        ticketId: ticketId,
      },
      queryid: "ee150a83-2326-4b5a-9795-d96676d3c082",
    };

    let config2 = {
      method: "post",
      url: `${process.env.REACT_APP_QDMQUERIES}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: params2,
    };

    let entryId = await axios(config2).then((res) => {
      return {
        entry_id: res?.data[0]?.entry_id,
        form_id: res?.data[0]?.form_id,
      };
      // this.setState({
      //   ...this.state,
      //   entry_id: res.data[0].entry_id,
      // })
    });

    return entryId;
  };

  render() {
    const { router, params, location, routes } = this.props;

    const hospitalsTree = () => {
      this.setState({
        openTree: !this.state.openTree,
      });
    };

    const StyledTreeItem = (props) => {
      console.log("props", props);
      const classes = useTreeItemStyles();
      const {
        labelText,
        labelIcon: LabelIcon,
        labelInfo,
        color,
        bgColor,
        type,
        ticketId,
        key,
        ...other
      } = props;

      const onClick = async () => {
        this.setState({
          ...this.state,
          showForm: false,
          isLoading: true,
        });
        // await this.getEntryId(ticketId);
        await onReloadActionPerformed(ticketId);

        // await this.getFormFromExecuteAql("90bcef04-8c58-420b-92c4-a1df6ad14233", ticketId);
        // this.setState({
        //   ...this.state,
        //   ticketId: ticketId,

        // })
      };
      return (
        <TreeItem
          id={props.id}
          // key={key}
          onClick={onClick}
          label={
            // type !== "Ward" && (
            <div id={props.id} className={classes.labelRoot}>
              <LabelIcon
                id={props.id}
                color="inherit"
                className={classes.labelIcon}
              />
              <Typography
                id={props.id}
                variant="body2"
                className={classes.labelText}
              >
                {labelText}
              </Typography>
              <Typography id={props.id} variant="caption" color="inherit">
                {DemoNameChange[labelInfo]}
              </Typography>
            </div>
            // )
          }
          style={{
            "--tree-view-color": color,
            "--tree-view-bg-color": bgColor,
          }}
          classes={{
            root: classes.root,
            content: classes.content,
            expanded: classes.expanded,
            selected: classes.selected,
            group: classes.group,
            label: classes.label,
          }}
          {...other}
        />
      );
    };

    const getTreeItemsFromData = (treeItems, times, allkeys) => {
      let arr;
      arr = [...treeItems];

      return arr?.map((treeItemData) => {
        let children = undefined;
        if (treeItemData?.children && treeItemData?.children?.length > 0) {
          children = getTreeItemsFromData(treeItemData?.children);
        }
        allkeys?.push(treeItemData?._id);

        return (
          <StyledTreeItem
            // id={`${parent_id}-StyledTreeItem`}
            type={treeItemData?.name}
            children={children}
            nodeId={treeItemData?._id}
            labelText={treeItemData?.name}
            id={treeItemData?._id}
            key={`${treeItemData?._id}`}
            labelIcon={
              treeItemData?.EntityType === "Ward"
                ? BusinessIcon
                : treeItemData?.EntityType === "Bed"
                ? TbBuildingHospital
                : treeItemData?.EntityType === "Facility"
                ? ApartmentIcon
                : treeItemData?.EntityType === "Room"
                ? FaClinicMedical
                : TbBuildingHospital
            }
            labelInfo={treeItemData?.name}
            ticketId={treeItemData?.ticketId}
            color="#1a73e8"
            bgColor="#e8f0fe"
          />
        );
      });
    };

    const onReloadActionPerformed = async (ticketId) => {
      // Setloading(true);

      let updateState = await this.getFormFromExecuteAql(
        process.env.REACT_APP_FORMID,
        ticketId
      );

      let detail = await this.getEntryId(ticketId);
      this.setState({
        ...this.state,
        showForm: true,
        ticketId: ticketId,
        entry_id: detail.entry_id,
        formRendering: updateState,
        form_id: detail.form_id,
        isLoading: false,
      });
    };

    const formClick = (getkey, fromType, isEdit) => {
      this.setState({
        ...this.state,
        form: true,
        key: getkey,
        isexternal: fromType,
        disableAddNew: isEdit ? isEdit : false,
      });
    };
    const setKeyfuntion = (getkey, isEdit) => {
      let entity = getkey.split("/")[0];
      this.setState({
        key: getkey,
        entity: entity,
        form: false,
        disableAddNew: false,
      });
    };

    const updateEntry = async (data) => {
      const ETYType = data?.answer[process.env.REACT_APP_ETY_QUE_ID];
      const ETName = data?.answer[process.env.REACT_APP_ETY_NAME_QUE_ID];
      const EntityType = data?.answer[process.env.REACT_APP_ETY_TYPE_QUE_ID];
      const ParentEty = data?.answer[process.env.REACT_APP_PARENT_GROUP_QUE_ID];
      const EditEtYType = data?.form[process.env.REACT_APP_ETY_QUE_ID];
      const email = data?.answer[process.env.REACT_APP_EMAIL_QUES];
      let error = false;

      if (ETYType?.answer == "Group Parent Entity") {
        //  ;
        if (EditEtYType?.answer == ETYType?.answer) {
          //  ;
          let document = {
            RoomDescription: ETName?.answer,
            RoomNumber: ETName?.answer,
            RoomType: EntityType?.answer,
          };
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          fetch(process.env.REACT_APP_ARANGO_URL_UPSERT, {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify([
              {
                db_name: process.env.REACT_APP_DB,
                entity: "Room",
                filter: { ticketId: this.state?.ticketId, activestatus: true },
                doc: document,
              },
            ]),
            redirect: "follow",
          })
            .then((result) => result.json())
            .then(async (response) => {
              if (response?.Code == 201) {
                let orgid = response?.Result[0]?.properties?.doc?._id;
                var data11 = JSON.stringify({
                  db_name: process.env.REACT_APP_DB,
                  entity: "ContactPointMaster",
                  filter: `ContactPointMaster.value=='${email?.answer}'`,
                  return_fields: "ContactPointMaster._id",
                });

                let datavalue = await AxiosCall(
                  "post",
                  process.env.REACT_APP_ARANGO_URL_READ,
                  data11
                );

                if (datavalue?.Code == 201) {
                  var datasss = JSON.stringify([
                    {
                      db_name: process.env.REACT_APP_DB,
                      entity: "Person",
                      filter: {
                        "telecom[0]": datavalue?.result[0],
                      },
                      is_metadata: true,
                      metadataId: process.env.REACT_APP_METADATAID,
                      metadata_dbname: process.env.REACT_APP_METADATA_DB_NAME,
                      doc: {
                        orgType: orgid ?? "",
                      },
                    },
                  ]);

                  var config = {
                    method: "post",
                    url: process.env.REACT_APP_ARANGO_URL_UPSERT,
                    headers: {
                      "Content-Type": "application/json",
                    },
                    data: datasss,
                  };

                  axios(config).then(function (respo) {
                    if (respo?.data?.Code === 201) {
                      var datass = JSON.stringify([
                        {
                          db_name: process.env.REACT_APP_DB,
                          entity: "HumanNameMaster",
                          filter: {
                            _id: respo?.data?.Result[0]?.properties?.doc
                              ?.name[0],
                          },
                          is_metadata: true,
                          metadataId: process.env.REACT_APP_METADATAID,
                          metadata_dbname:
                            process.env.REACT_APP_METADATA_DB_NAME,
                          doc: {
                            text: EntityType?.answer,
                          },
                        },
                      ]);

                      var config1 = {
                        method: "post",
                        url: process.env.REACT_APP_ARANGO_URL_UPSERT,
                        headers: {
                          "Content-Type": "application/json",
                        },
                        data: datass,
                      };
                      axios(config1)
                        .then(function (respon) {})
                        .catch(function (error) {
                          console.log(error);
                        });
                    }
                  });
                }
              }
            })
            .catch((err) => console.error(err));
        } else if (EditEtYType?.answer == "Individual Entity") {
          //  ;
          let document = {
            EntityType: "Room",
            RoomDescription: ETName?.answer,
            RoomNumber: ETName?.answer,
            RoomType: EntityType?.answer,
            RoomStatus: true,
            WardCode: "",
            ticketId: this.state?.ticketId,
          };
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          fetch(process.env.REACT_APP_ARANGO_URL_UPSERT, {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify([
              {
                db_name: process.env.REACT_APP_DB,
                entity: "Room",
                doc: document,
              },
            ]),
            redirect: "follow",
          })
            .then((result) => result.json())
            .then(async (response) => {
              if (response?.Code == 201) {
                let orgid = response?.Result[0]?.properties?.doc?._id;
                var data11 = JSON.stringify({
                  db_name: process.env.REACT_APP_DB,
                  entity: "ContactPointMaster",
                  filter: `ContactPointMaster.value=='${email?.answer}'`,
                  return_fields: "ContactPointMaster._id",
                });

                let datavalue = await AxiosCall(
                  "post",
                  process.env.REACT_APP_ARANGO_URL_READ,
                  data11
                );

                if (datavalue?.Code == 201) {
                  var datasss = JSON.stringify([
                    {
                      db_name: process.env.REACT_APP_DB,
                      entity: "Person",
                      filter: {
                        "telecom[0]": datavalue?.result[0],
                      },
                      is_metadata: true,
                      metadataId: process.env.REACT_APP_METADATAID,
                      metadata_dbname: process.env.REACT_APP_METADATA_DB_NAME,
                      doc: {
                        orgType: orgid ?? "",
                      },
                    },
                  ]);

                  var config = {
                    method: "post",
                    url: process.env.REACT_APP_ARANGO_URL_UPSERT,
                    headers: {
                      "Content-Type": "application/json",
                    },
                    data: datasss,
                  };

                  axios(config).then(function (respo) {
                    if (respo?.data?.Code === 201) {
                      var datass = JSON.stringify([
                        {
                          db_name: process.env.REACT_APP_DB,
                          entity: "HumanNameMaster",
                          filter: {
                            _id: respo?.data?.Result[0]?.properties?.doc
                              ?.name[0],
                          },
                          is_metadata: true,
                          metadataId: process.env.REACT_APP_METADATAID,
                          metadata_dbname:
                            process.env.REACT_APP_METADATA_DB_NAME,
                          doc: {
                            text: EntityType?.answer,
                          },
                        },
                      ]);

                      var config1 = {
                        method: "post",
                        url: process.env.REACT_APP_ARANGO_URL_UPSERT,
                        headers: {
                          "Content-Type": "application/json",
                        },
                        data: datass,
                      };
                      axios(config1)
                        .then(function (respon) {})
                        .catch(function (error) {
                          console.log(error);
                        });
                    }
                  });
                }
              }
            })
            .catch((err) => console.error(err));

          fetch(process.env.REACT_APP_ARANGO_URL_UPSERT, {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify([
              {
                db_name: process.env.REACT_APP_DB,
                entity: "Bed",
                filter: { ticketId: this.state?.ticketId, activestatus: true },
                doc: {
                  activestatus: false,
                  BedStatus: false,
                },
              },
            ]),
            redirect: "follow",
          })
            .then((result) => result.json())
            .then((response) => response)
            .catch((err) => console.error(err));
        } else if (EditEtYType?.answer == "Individual entity part of group") {
          //  ;
          error = true;
          this.props.alert.setSnack({
            ...this.props.alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Cannot convert Individual entity part of group to Parent Entity  ",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        }
      } else if (ETYType?.answer == "Individual Entity") {
        //  ;
        if (EditEtYType?.answer == ETYType?.answer) {
          //  ;
          let document = {
            BedDescription: ETName?.answer,
            BedNumber: ETName?.answer,
            BedType: EntityType?.answer,
          };

          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          fetch(process.env.REACT_APP_ARANGO_URL_UPSERT, {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify([
              {
                db_name: process.env.REACT_APP_DB,
                entity: "Bed",
                filter: { ticketId: this.state?.ticketId, activestatus: true },
                doc: document,
              },
            ]),
            redirect: "follow",
          })
            .then((result) => result.json())
            .then(async (response) => {
              if (response?.Code == 201) {
                let orgid = response?.Result[0]?.properties?.doc?._id;
                var data11 = JSON.stringify({
                  db_name: process.env.REACT_APP_DB,
                  entity: "ContactPointMaster",
                  filter: `ContactPointMaster.value=='${email?.answer}'`,
                  return_fields: "ContactPointMaster._id",
                });

                let datavalue = await AxiosCall(
                  "post",
                  process.env.REACT_APP_ARANGO_URL_READ,
                  data11
                );

                if (datavalue?.Code == 201) {
                  var datasss = JSON.stringify([
                    {
                      db_name: process.env.REACT_APP_DB,
                      entity: "Person",
                      filter: {
                        "telecom[0]": datavalue?.result[0],
                      },
                      is_metadata: true,
                      metadataId: process.env.REACT_APP_METADATAID,
                      metadata_dbname: process.env.REACT_APP_METADATA_DB_NAME,
                      doc: {
                        orgType: orgid ?? "",
                      },
                    },
                  ]);

                  var config = {
                    method: "post",
                    url: process.env.REACT_APP_ARANGO_URL_UPSERT,
                    headers: {
                      "Content-Type": "application/json",
                    },
                    data: datasss,
                  };

                  axios(config).then(function (respo) {
                    if (respo?.data?.Code === 201) {
                      var datass = JSON.stringify([
                        {
                          db_name: process.env.REACT_APP_DB,
                          entity: "HumanNameMaster",
                          filter: {
                            _id: respo?.data?.Result[0]?.properties?.doc
                              ?.name[0],
                          },
                          is_metadata: true,
                          metadataId: process.env.REACT_APP_METADATAID,
                          metadata_dbname:
                            process.env.REACT_APP_METADATA_DB_NAME,
                          doc: {
                            text: EntityType?.answer,
                          },
                        },
                      ]);

                      var config1 = {
                        method: "post",
                        url: process.env.REACT_APP_ARANGO_URL_UPSERT,
                        headers: {
                          "Content-Type": "application/json",
                        },
                        data: datass,
                      };
                      axios(config1)
                        .then(function (respon) {})
                        .catch(function (error) {
                          console.log(error);
                        });
                    }
                  });
                }
              }
            })
            .catch((err) => console.error(err));
        } else if (EditEtYType?.answer == "Group Parent Entity") {
          //  ;
          let RoomId = "";
          let Response = [];
          let params = {
            db_name: process.env.REACT_APP_DB,
            entity: "Room",
            filter: `Room.ticketId=='${this.state?.ticketId}' && Room.activestatus== true `,
            return_fields: "Room._id",
          };
          let res = await AxiosCall(
            "post",
            `${process.env.REACT_APP_ARANGO_URL_READ}`,
            params
          );
          RoomId = res?.result[0];
          let params1 = {
            db_name: process.env.REACT_APP_DB,
            entity: "Bed",
            filter: `Bed.RoomCode== '${RoomId}' && Bed.activestatus== true `,
            return_fields: "Bed",
          };

          let response = await AxiosCall(
            "post",
            `${process.env.REACT_APP_ARANGO_URL_READ}`,
            params1
          );
          Response = response?.result;
          if (!Response.length > 0) {
            let document = {
              EntityType: "Bed",
              BedDescription: ETName?.answer,
              BedNumber: ETName?.answer,
              BedStatus: true,
              BedType: EntityType?.answer,
              LoginCount: 0,
              ticketId: this.state?.ticketId,
              RoomCode: "",
            };
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            fetch(process.env.REACT_APP_ARANGO_URL_UPSERT, {
              method: "POST",
              headers: myHeaders,
              body: JSON.stringify([
                {
                  db_name: process.env.REACT_APP_DB,
                  entity: "Bed",
                  doc: document,
                },
              ]),
              redirect: "follow",
            })
              .then((result) => result.json())
              .then(async (response) => {
                if (response?.Code == 201) {
                  let orgid = response?.Result[0]?.properties?.doc?._id;
                  var data11 = JSON.stringify({
                    db_name: process.env.REACT_APP_DB,
                    entity: "ContactPointMaster",
                    filter: `ContactPointMaster.value=='${email?.answer}'`,
                    return_fields: "ContactPointMaster._id",
                  });

                  let datavalue = await AxiosCall(
                    "post",
                    process.env.REACT_APP_ARANGO_URL_READ,
                    data11
                  );

                  if (datavalue?.Code == 201) {
                    var datasss = JSON.stringify([
                      {
                        db_name: process.env.REACT_APP_DB,
                        entity: "Person",
                        filter: {
                          "telecom[0]": datavalue?.result[0],
                        },
                        is_metadata: true,
                        metadataId: process.env.REACT_APP_METADATAID,
                        metadata_dbname: process.env.REACT_APP_METADATA_DB_NAME,
                        doc: {
                          orgType: orgid ?? "",
                        },
                      },
                    ]);

                    var config = {
                      method: "post",
                      url: process.env.REACT_APP_ARANGO_URL_UPSERT,
                      headers: {
                        "Content-Type": "application/json",
                      },
                      data: datasss,
                    };

                    axios(config).then(function (respo) {
                      if (respo?.data?.Code === 201) {
                        var datass = JSON.stringify([
                          {
                            db_name: process.env.REACT_APP_DB,
                            entity: "HumanNameMaster",
                            filter: {
                              _id: respo?.data?.Result[0]?.properties?.doc
                                ?.name[0],
                            },
                            is_metadata: true,
                            metadataId: process.env.REACT_APP_METADATAID,
                            metadata_dbname:
                              process.env.REACT_APP_METADATA_DB_NAME,
                            doc: {
                              text: EntityType?.answer,
                            },
                          },
                        ]);

                        var config1 = {
                          method: "post",
                          url: process.env.REACT_APP_ARANGO_URL_UPSERT,
                          headers: {
                            "Content-Type": "application/json",
                          },
                          data: datass,
                        };
                        axios(config1)
                          .then(function (respon) {})
                          .catch(function (error) {
                            console.log(error);
                          });
                      }
                    });
                  }
                }
              })
              .catch((err) => console.error(err));

            fetch(process.env.REACT_APP_ARANGO_URL_UPSERT, {
              method: "POST",
              headers: myHeaders,
              body: JSON.stringify([
                {
                  db_name: process.env.REACT_APP_DB,
                  entity: "Room",
                  filter: {
                    ticketId: this.state?.ticketId,
                    activestatus: true,
                  },
                  doc: {
                    activestatus: false,
                    RoomStatus: false,
                  },
                },
              ]),
              redirect: "follow",
            })
              .then((result) => result.json())
              .then((response) => response)
              .catch((err) => console.error(err));
          } else {
            error = true;
            this.props.alert.setSnack({
              ...this.props.alert,
              open: true,
              severity: AlertProps.severity.error,
              msg: "Group Parent Entity has Individual entity part of group",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          }
        } else if (EditEtYType?.answer == "Individual entity part of group") {
          //  ;
          let document = {
            EntityType: "Bed",
            BedDescription: ETName?.answer,
            BedNumber: ETName?.answer,
            BedStatus: true,
            BedType: EntityType?.answer,
            LoginCount: 0,
            ticketId: this.state?.ticketId,
            RoomCode: "",
          };
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          fetch(process.env.REACT_APP_ARANGO_URL_UPSERT, {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify([
              {
                db_name: process.env.REACT_APP_DB,
                entity: "Bed",
                filter: { ticketId: this.state?.ticketId, activestatus: true },
                doc: document,
              },
            ]),
            redirect: "follow",
          })
            .then((result) => result.json())
            .then(async (response) => {
              if (response?.Code == 201) {
                let orgid = response?.Result[0]?.properties?.doc?._id;
                var data11 = JSON.stringify({
                  db_name: process.env.REACT_APP_DB,
                  entity: "ContactPointMaster",
                  filter: `ContactPointMaster.value=='${email?.answer}'`,
                  return_fields: "ContactPointMaster._id",
                });

                let datavalue = await AxiosCall(
                  "post",
                  process.env.REACT_APP_ARANGO_URL_READ,
                  data11
                );

                if (datavalue?.Code == 201) {
                  var datasss = JSON.stringify([
                    {
                      db_name: process.env.REACT_APP_DB,
                      entity: "Person",
                      filter: {
                        "telecom[0]": datavalue?.result[0],
                      },
                      is_metadata: true,
                      metadataId: process.env.REACT_APP_METADATAID,
                      metadata_dbname: process.env.REACT_APP_METADATA_DB_NAME,
                      doc: {
                        orgType: orgid ?? "",
                      },
                    },
                  ]);

                  var config = {
                    method: "post",
                    url: process.env.REACT_APP_ARANGO_URL_UPSERT,
                    headers: {
                      "Content-Type": "application/json",
                    },
                    data: datasss,
                  };

                  axios(config).then(function (respo) {
                    if (respo?.data?.Code === 201) {
                      var datass = JSON.stringify([
                        {
                          db_name: process.env.REACT_APP_DB,
                          entity: "HumanNameMaster",
                          filter: {
                            _id: respo?.data?.Result[0]?.properties?.doc
                              ?.name[0],
                          },
                          is_metadata: true,
                          metadataId: process.env.REACT_APP_METADATAID,
                          metadata_dbname:
                            process.env.REACT_APP_METADATA_DB_NAME,
                          doc: {
                            text: EntityType?.answer,
                          },
                        },
                      ]);

                      var config1 = {
                        method: "post",
                        url: process.env.REACT_APP_ARANGO_URL_UPSERT,
                        headers: {
                          "Content-Type": "application/json",
                        },
                        data: datass,
                      };
                      axios(config1)
                        .then(function (respon) {})
                        .catch(function (error) {
                          console.log(error);
                        });
                    }
                  });
                }
              }
            })
            .catch((err) => console.error(err));
        }
      } else if (ETYType?.answer == "Individual entity part of group") {
        //  ;
        if (EditEtYType?.answer == ETYType?.answer) {
          //  ;
          let document = {
            BedDescription: ETName?.answer,
            BedNumber: ETName?.answer,
            BedType: EntityType?.answer,
            RoomCode: ParentEty?.answer?.value,
          };
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          fetch(process.env.REACT_APP_ARANGO_URL_UPSERT, {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify([
              {
                db_name: process.env.REACT_APP_DB,
                entity: "Bed",
                filter: { ticketId: this.state?.ticketId, activestatus: true },
                doc: document,
              },
            ]),
            redirect: "follow",
          })
            .then((result) => result.json())
            .then(async (response) => {
              if (response?.Code == 201) {
                let orgid = response?.Result[0]?.properties?.doc?._id;
                var data11 = JSON.stringify({
                  db_name: process.env.REACT_APP_DB,
                  entity: "ContactPointMaster",
                  filter: `ContactPointMaster.value=='${email?.answer}'`,
                  return_fields: "ContactPointMaster._id",
                });
                let datavalue = await AxiosCall(
                  "post",
                  process.env.REACT_APP_ARANGO_URL_READ,
                  data11
                );

                if (datavalue?.Code == 201) {
                  var datasss = JSON.stringify([
                    {
                      db_name: process.env.REACT_APP_DB,
                      entity: "Person",
                      filter: {
                        "telecom[0]": datavalue?.result[0],
                      },
                      is_metadata: true,
                      metadataId: process.env.REACT_APP_METADATAID,
                      metadata_dbname: process.env.REACT_APP_METADATA_DB_NAME,
                      doc: {
                        orgType: orgid ?? "",
                      },
                    },
                  ]);
                  var config = {
                    method: "post",
                    url: process.env.REACT_APP_ARANGO_URL_UPSERT,
                    headers: {
                      "Content-Type": "application/json",
                    },
                    data: datasss,
                  };
                  axios(config).then(function (respo) {
                    if (respo?.data?.Code === 201) {
                      var datass = JSON.stringify([
                        {
                          db_name: process.env.REACT_APP_DB,
                          entity: "HumanNameMaster",
                          filter: {
                            _id: respo?.data?.Result[0]?.properties?.doc
                              ?.name[0],
                          },
                          is_metadata: true,
                          metadataId: process.env.REACT_APP_METADATAID,
                          metadata_dbname:
                            process.env.REACT_APP_METADATA_DB_NAME,
                          doc: {
                            text: EntityType?.answer,
                          },
                        },
                      ]);
                      var config1 = {
                        method: "post",
                        url: process.env.REACT_APP_ARANGO_URL_UPSERT,
                        headers: {
                          "Content-Type": "application/json",
                        },
                        data: datass,
                      };
                      axios(config1)
                        .then(function (respon) {})
                        .catch(function (error) {
                          console.log(error);
                        });
                    }
                  });
                }
              }
            })
            .catch((err) => console.error(err));
        } else if (EditEtYType?.answer == "Individual Entity") {
          //  ;
          let document = {
            BedDescription: ETName?.answer,
            BedNumber: ETName?.answer,
            BedType: EntityType?.answer,
            RoomCode: ParentEty?.answer?.value,
            ticketId: this.state?.ticketId,
          };
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          fetch(process.env.REACT_APP_ARANGO_URL_UPSERT, {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify([
              {
                db_name: process.env.REACT_APP_DB,
                entity: "Bed",
                filter: { ticketId: this.state?.ticketId, activestatus: true },
                doc: document,
              },
            ]),
            redirect: "follow",
          })
            .then((result) => result.json())
            .then(async (response) => {
              if (response?.Code == 201) {
                let orgid = response?.Result[0]?.properties?.doc?._id;
                var data11 = JSON.stringify({
                  db_name: process.env.REACT_APP_DB,
                  entity: "ContactPointMaster",
                  filter: `ContactPointMaster.value=='${email?.answer}'`,
                  return_fields: "ContactPointMaster._id",
                });

                let datavalue = await AxiosCall(
                  "post",
                  process.env.REACT_APP_ARANGO_URL_READ,
                  data11
                );

                if (datavalue?.Code == 201) {
                  var datasss = JSON.stringify([
                    {
                      db_name: process.env.REACT_APP_DB,
                      entity: "Person",
                      filter: {
                        "telecom[0]": datavalue?.result[0],
                      },
                      is_metadata: true,
                      metadataId: process.env.REACT_APP_METADATAID,
                      metadata_dbname: process.env.REACT_APP_METADATA_DB_NAME,
                      doc: {
                        orgType: orgid ?? "",
                      },
                    },
                  ]);
                  var config = {
                    method: "post",
                    url: process.env.REACT_APP_ARANGO_URL_UPSERT,
                    headers: {
                      "Content-Type": "application/json",
                    },
                    data: datasss,
                  };
                  axios(config).then(function (respo) {
                    if (respo?.data?.Code === 201) {
                      var datass = JSON.stringify([
                        {
                          db_name: process.env.REACT_APP_DB,
                          entity: "HumanNameMaster",
                          filter: {
                            _id: respo?.data?.Result[0]?.properties?.doc
                              ?.name[0],
                          },
                          is_metadata: true,
                          metadataId: process.env.REACT_APP_METADATAID,
                          metadata_dbname:
                            process.env.REACT_APP_METADATA_DB_NAME,
                          doc: {
                            text: EntityType?.answer,
                          },
                        },
                      ]);
                      var config1 = {
                        method: "post",
                        url: process.env.REACT_APP_ARANGO_URL_UPSERT,
                        headers: {
                          "Content-Type": "application/json",
                        },
                        data: datass,
                      };
                      axios(config1)
                        .then(function (respon) {})
                        .catch(function (error) {
                          console.log(error);
                        });
                    }
                  });
                }
              }
            })
            .catch((err) => console.error(err));
        } else if (EditEtYType?.answer == "Group Parent Entity") {
          error = true;
          this.props.alert.setSnack({
            ...this.props.alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Cannot convert Group Parent Entity to Individual entity part of group",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        }
      }
      if (!error) {
        //  ;
        let params = {
          form_id: data?.formId,
          data: data?.answer,
          notifier_status: data.isDraft ? 9 : 1,
          notifyType: data?.isDraft,
          entry_id: this.state?.entryId,
          isEdit: true,
          ticketId: this.state?.ticketId,
        };

        Promise.resolve(this.props.UPSERT_ENTRY(params)).then((res) => {
          if (res?.payload?.data?.Result) {
            let ticketId = this.state?.ticketId;
            Promise.resolve(
              this.props.UPDATE_QDM_TRANSACTION_LOG({
                ticketId,
                data: data?.data,
              })
            ).then((response) => {
              if (response?.payload?.data?.Result) {
                this.setState({
                  ...this.state,
                  reload: !this.state.reload,
                });
                this.props.alert.setSnack({
                  ...this.props.alert,
                  open: true,
                  severity: AlertProps.severity.success,
                  msg: "Form Successfully Submitted",
                  vertical: AlertProps.vertical.top,
                  horizontal: AlertProps.horizontal.center,
                });
              } else {
                this.props.alert.setSnack({
                  ...this.props.alert,
                  open: true,
                  severity: AlertProps.severity.error,
                  msg: "Something went wrong!",
                  vertical: AlertProps.vertical.top,
                  horizontal: AlertProps.horizontal.center,
                });
              }
            });
          } else {
            this.props.alert.setSnack({
              ...this.props.alert,
              open: true,
              severity: AlertProps.severity.error,
              msg: "Something went wrong!",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          }
        });
      }
    };

    const completeForm = (isEdit) => {
      this.setState({
        ...this.state,
        reload: !this.state.reload,
        disableAddNew: isEdit ? isEdit : false,
      });
    };

    // console.log("formIdsss2", this.state.form_id);

    const { classes, theme } = this.props;
    return (
      <div id={"organization-parent-div"} className={classes.root}>
        <Grid id={"organization-parent-grid"} container>
          {this.state.openTree ? (
            <Grid
              id={"organization-parent-sub-grid"}
              item
              xs={3}
              style={{ zIndex: 1 }}
            >
              <CommonTabList
                parent_id={"admintabpanel"}
                title={""}
                styles={{
                  backgroundColor: theme.palette.background.table,
                  borderTop: "2px solid #f6f6f6",
                }}
                list={[
                  {
                    name: "Enterprise Details",
                    component: (
                      <TreeView
                        parent_id={"treestuck"}
                        onClickTreebtn={() => hospitalsTree}
                        onClickformClick={formClick}
                        treeis={this.state.openTree}
                        isform={this.state.form}
                        getKeyfuntion={setKeyfuntion}
                        isexternal={false}
                        getTreeItemsFromData={getTreeItemsFromData}
                        disableAddNew={this.state.disableAddNew}
                        reload={this.state.reload}
                      />
                    ),
                  },
                ]}
              />
            </Grid>
          ) : null}

          <Grid
            id={"organization-grid"}
            item
            xs={9}
            style={{ padding: "20px" }}
          >
            <div id={"organization-div"} className={classes.paper}>
              {this.state.showForm ? (
                <FormRenderer
                  onSaveForm={(data) => {
                    updateEntry(data);
                  }}
                  secName={this.state?.formRendering?.secName}
                  title="orgForm"
                  secToShow={[0, 1, null]}
                  form_detail={this.state?.formRendering?.formData}
                  entryId={this?.state?.entry_id}
                  ticketId={this?.state?.ticketId}
                  isReadyOnly={false}
                  isEdit={true}
                  allowEdit={true}
                  userloggedin={true}
                  // UserRole={"notifier"}
                  // isEdit={true}

                  // forms={this.state?.formRendering?.formData?.form}
                  // form_detail={formRendering?.formData}
                  state={[
                    {
                      form_id: this?.state?.form_id,
                      // assessment: {},
                      // is_metadata: false,
                      // client_id: "",
                      // is_active: true,
                      answer: {
                        phoneNumber: location?.number
                          ? { number: location?.number, phoneCode: "+60" }
                          : {},
                      },
                    },
                  ]}
                />
              ) : (
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "grid",
                    placeItems: "center",
                  }}
                >
                  {this.state?.isLoading ? <CircularProgress /> : <PageEmpty />}
                </div>
              )}

              {/* <OrgView
                isform={this.state.form}
                handlekey={this.state.key}
                onClickformClick={formClick}
                entity={this.state.entity}
                completeForm={completeForm}
              /> */}
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  // oraganizationRegisterPatient: state?.OrganizationRegisterPatientApiSlice?.oraganizationRegisterPatient,
  upsertEntry: state?.renderSlice?.upsertEntry,
});

export default connect(
  mapStateToProps,
  actions
)(withStyles(styles, { withTheme: true })(withAllContexts(Organization)));
