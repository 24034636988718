import {
  Badge,
  Box,
  Button,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import ReactHorizontalDatePicker from "../../components/horizontalCalendar/hori";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import { Cart } from "./cart";
import { ListItem } from "./listItem";
import { useSelector } from "react-redux";
import { getTmrwDate } from "../../utils";
import { CartIcon } from "../../assets";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Config from "../../config";
import { AxiosCall } from "../../api";
import { FeedBack } from "./feedback";
// import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import WarningIcon from "@material-ui/icons/Warning";
import UpdateIcon from "@material-ui/icons/Update";
import { SearchTextField } from "../../components";
import axios from "axios";
import DrugOrdering from "../drugOrdering";

const useStyles = makeStyles((theme) => ({
  content: {
    width: "100%",
    height: "100%",
  },
  header: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "8px 16px",
    background: theme.palette.background.table,
    height: "54px",
    borderBottom: " 1px solid #e0e0e0",
    [theme.breakpoints.down("md")]: {
      padding: "4px 8px",
    },
  },
  cartIconContainer: {
    position: "relative",
    display: "flex",
    cursor: "pointer",
    marginLeft: "8px",
    flex: "0 0 auto",
  },
  badge: {
    fontWeight: "600",
    position: "absolute",
    top: "-10px",
    left: "10px",
    // paddingBottom: "3px",
    background: "#007AFF",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    color: "#fff",
    boxSizing: "border-box",
    fontSize: "1rem",
    height: "20px",
    minWidth: "20px",
    transform: "fontSize 225ms cubic-bezier(0.4, 0, 0.2, 1)",
  },
  textResponsive: {
    // fontSize: "1.1rem !important",
    fontSize: "20 px",
    color: theme.palette.primary.main,

    [theme.breakpoints.up("md")]: {
      fontSize: "1rem !important",
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "0.8rem !important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.7rem !important",
    },
  },

  TabsRoot: {
    flexGrow: 1,
    boxShadow: "0px 10px 25px #0000000A",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 8px",
    background: theme.palette.background.table,
    borderBottom: " 1px solid #e0e0e0",
    "& .App1-MuiTabs-root": {
      "& .App1-MuiTab-root": {
        padding: "0px",
      },
      "& .App1-MuiTabs-flexContainer": {
        alignItems: "center",
        justifyContent: "space-between",
        margin: "0px 17px",
        padding: "0px",
      },
      "& .App1-MuiTabs-indicator": {
        display: "block",
        backgroundColor: "#0071F2",
      },
      "& .Mui-selected": {
        backgroundColor: "transparent",
        color: "inherit",
      },
    },
  },
  Tabs: {
    color: "#0b0b0b0",
    zIndex: "30",

    "&.Mui-selected": {
      color: "#ffffff",
    },
    "& .MuiTab-wrapper": {
      display: "-webkit-box",
      WebkitLineClamp: "1",
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      maxWidth: "200px",
    },
    // ".MuiTab-textColorPrimary": {
    //   color: theme.palette.text.primary,
    // },
  },
  TabPanel: {
    padding: "16px",
    height: "calc(100% - 130px)",
    overflow: "auto",
    background: theme.palette.background.table,
  },
  disclaimerBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "4px 16px",
    borderRadius: "8px",
    // background: theme.palette.background.main,
    flex: "1 1 auto",
    [theme.breakpoints.down("md")]: {
      padding: "0px 8px",
      borderRadius: "unset",
    },
  },
}));

function TabPanel(props) {
  const classes = useStyles();

  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      className={classes.TabPanel}
      {...other}
    >
      {value === index && (
        <Box style={{ height: "100%" }}>{props.children}</Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OrderCatalog = (props) => {
  const {
    user,
    getMealSchedule,
    date,
    fromKitchen,
    backToSearch,
    Count,
    countLimit,
    handleTour,
    selectedPatient,
    PatientDetails
  } = props;

  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [tablist, setTablist] = React.useState([]);
  const [hideTour, setHideTour] = React.useState(true);
  const tmrw = React.useRef(null);
  const menuList = useSelector(
    (state) => state?.orderCatalogSlice?.getMealSchedule
  );
  const Theme = useTheme();
  const forPhone = useMediaQuery(Theme.breakpoints.up("sm"));
  const forTab = useMediaQuery(Theme.breakpoints.down("md"));
  const [isloading, setLoading] = React.useState(true);
  const [feedBackDialog, setFeedBackDialog] = React.useState(false);
  const [tabPanelvalue, settabPanelValue] = React.useState([]);

  const handleOpenDialog = () => {
    setFeedBackDialog(true);
  };

  // console.log("menuList", menuList);

  const handleCloseDialog = () => {
    setFeedBackDialog(false);
  };
  React.useEffect(() => {
    let tmrwDate = getTmrwDate();
    tmrw.current = tmrwDate;
    if (date) {
      getMealTypes(date);
    }
    //eslint-disable-next-line
  }, [date]);

  setTimeout(() => {
    setLoading(false);
  }, 2000);

  React.useEffect(() => {
    getAllItem();
  }, []);

  const getAllItem = async () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_EXCHEQUER_API,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        const values = result?.result.filter((li) => li.parntname != null);
        const groupByCategory = values.reduce((group, product) => {
          const { parntname } = product;
          group[parntname] = group[parntname] ?? [];
          group[parntname].push(product);
          return group;
        }, {});

        settabPanelValue(groupByCategory);

        // const keyvalue = Object.keys(groupByCategory)
        // const res = [];
        // for (let i = 0; i < keyvalue.length; i++) {
        //   res.push({
        //     name: keyvalue[i].split('-')[1],
        //     value:keyvalue[i]
        //   });
        // };
        // let filtervalue = res.filter(li => li.name != undefined)
        // setTabvalue(filtervalue)
        // return res;
      })
      .catch((error) => console.log("error", error));
  };

  const getMealTypes = async (dateNew) => {
    let params = {
      db_name: Config.dbname,
      entity: "CodeableConceptMaster",
      filter:
        "CodeableConceptMaster.Type=='DRUG CATEGORY' &&CodeableConceptMaster.status==true && CodeableConceptMaster.activestatus==true",
      sort: "CodeableConceptMaster.text",
      return_fields:
        "MERGE(CodeableConceptMaster,{coding:FIRST(FOR cod IN CodeableConceptMaster.coding RETURN DOCUMENT(cod))})",
    };
    let res = await AxiosCall(
      "post",
      `${Config.api_url}/api/read_documents`,
      params
    );

    let result = res?.result;

    // console.log("mealtype", result);
    setTablist(result);
    /* This below code we disable the tab menu if cusoff time over */
    // if (fromKitchen) {
    //   let updateTab = result?.map((l, index) =>
    //     Object.assign(l, {
    //       disable: false,
    //     })
    //   );
    //   setTablist(updateTab);
    // }
    // else {
    //   let updateTab = result?.map((l, index) =>
    //     Object.assign(l, {
    //       disable: getCutoffFlag(
    //         l?.coding?.display,
    //         dateNew,
    //         l?.coding?.gmconfigvalues?.TIME
    //       ),
    //     })
    //   );

    //   let index = updateTab.findIndex((l) => l?.disable === false);
    //   setValue(index);
    //   setTablist(updateTab);
    //   let updateList=result.map(l)
    // }
  };

  /* Add this function for cutoff feature */
  // const getCutoffFlag = (type, dateNew, cutoffvalue) => {
  //   let epoche = dateNew.getDate();
  //   // let curDate = new Date();
  //   if (type === "Breakfast") {
  //     let hrs = dateNew.getHours();
  //     var todaydate = new Date();
  //     if (epoche === new Date().getDate() && type === "Breakfast") {
  //       let flag = true;
  //       return flag;
  //     } else {
  //       let flag = hrs < parseInt(cutoffvalue) ? false : true;
  //       return flag;
  //     }
  //   } else if (type === "Lunch" && epoche === new Date().getDate()) {
  //     let hrs = dateNew.getHours();

  //     let flag = hrs < parseInt(cutoffvalue) ? false : true;
  //     return flag;
  //   } else if (type === "Dinner" && epoche === new Date().getDate()) {
  //     let hrs = dateNew.getHours();

  //     let flag = hrs < parseInt(cutoffvalue) ? false : true;
  //     return flag;
  //   } else if (
  //     type === "Afternoon Tea Menu" &&
  //     epoche === new Date().getDate()
  //   ) {
  //     let hrs = dateNew.getHours();
  //     let flag = hrs < parseInt(cutoffvalue) ? false : true;
  //     return flag;
  //   } else {
  //     return false;
  //   }
  // };

  const [cartList, setCartlist] = React.useState([]);

  const [selectedAddon, setSelectedAddon] = React.useState([]);
  const [Quantity, setQuantity] = React.useState([]);
  const [search, setSearch] = React.useState("");

  const [Tabvalue, setTabvalue] = React.useState([]);

  const [openDialog, setDialog] = React.useState(false);
  const updateCartState = (value) => {
    //console.log("cartList" , cartList)

    //  ;
    // let getExistMealOnCategory = cartList.find((l) => l?._id === value?._id);
    let updatedList = cartList.filter((l) => value?.serialno !== l?.serialno);
    setCartlist([...updatedList, value]);

    // let updateAddon = Quantity.filter((a) => a?._id !== value?._id);
    // setQuantity(updateAddon);
  };

  const handleOpen = () => {
    setDialog(true);
  };

  const handleClose = () => {
    setDialog(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const removeFromCart = (data) => {
    let updatedList = cartList.filter((l) => l?.serialno !== data?.serialno);
    let updateAddon = Quantity.filter((a) => a?.serialno !== data?.serialno);
    setCartlist(updatedList);
    setQuantity(updateAddon);
  };
  const isAddedToCart = (data) => {
    //  ;
    let isAdded =
      cartList.filter((l) => l?.serialno === data?.serialno).length > 0
        ? true
        : false;
    return isAdded;
  };

  const selectedDay = (val) => {
    getMealSchedule(val);
    // setCartlist([]);
  };

  const updateHideTour = () => {
    setHideTour(false);
  };

  const addMinusQuantity = (isadd, val, l) => {
    let n = Number(val);
    let qty = isadd ? ++n : --n;
    let obj = { ...l, quantity: qty.toString() };

    let hasAlready = Quantity?.filter((d) => d?.serialno === l?.serialno);

    let updatedList =
      hasAlready.length === 0
        ? [...Quantity, obj]
        : Quantity.map((item) => (item.serialno === l?.serialno ? obj : item));
    setQuantity(updatedList);
  };

  const onHandleChnageQuantity = (e, l) => {
    let regex = /^[0-9]*$/;
    let val = e.target.value;
    if (regex.test(val)) {
      let obj = { ...l, quantity: e.target.value };

      let hasAlready = Quantity?.filter((d) => d?.serialno === l?.serialno);
      let updatedList =
        hasAlready.length === 0
          ? [...Quantity, obj]
          : Quantity.map((item) =>
            item.serialno === l?.serialno ? obj : item
          );
      setQuantity(updatedList);
    }
  };
  if (isloading) {
    return (
      <>
        <Box style={{ height: "100%" }}>
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CircularProgress />
            <Typography
              className={classes.textResponsive}
              variant="subtitle2"
              color="textPrimary"
              style={{ fontWeight: "600" }}
            >
              {`Loading...`}
            </Typography>
          </div>
        </Box>
      </>
    );
  }

  //console.log("tabPanelvalue" , Object.keys(tabPanelvalue))
  // console.log("cartOut", selectedPatient);
  return (
    <>
      <Box className={classes.content}>
        {/* {console.log("Tabvalue" , Tabvalue)} */}

        <DrugOrdering
          BedNumber={selectedPatient?.BedNumber}
          BedCode={selectedPatient?.PatientCode}
          backToSearch={backToSearch}
          PatientDetails={PatientDetails}
        />
      </Box>
    </>
  );
};

export default OrderCatalog;
