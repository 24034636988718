import { Box, Tab, Typography } from "@material-ui/core";
import React from "react";
import StarIcon from "@mui/icons-material/Star";
import { StyledButton2, StyledLabel } from "../../../styledcomponent";
import { useTheme } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { ListPanel } from "./listPanel";
import axios from "axios";
import { AlertProps, AxiosCall } from "../../../utils";
import { AlertContext, BackdropContext } from "../../../contexts";
import jwt_decode from "jwt-decode";

export const QueueScenarios = (props) => {
  const theme = useTheme();
  const [value, setValue] = React.useState("1");
  const alertMessage = React.useContext(AlertContext);
  const [scenarioState, setscenarioState] = React.useState([]);
  const [timelineState, setlineState] = React.useState([]);
  const [statecount, setCount] = React.useState([]);
  const [allcount, setAllCount] = React.useState("0");
  const db = sessionStorage.getItem("ProjectDbname");
  const backDrop = React.useContext(BackdropContext);
  const [favlist, setFavlist] = React.useState([]);

  const updateFavState = (val) => {
    setFavlist(val);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // let statesEnd = false;
  // let UserRole = sessionStorage.getItem("UserRole");
  // let Reload = sessionStorage.getItem("reload");
  // console.log(localStorage.getItem("queue_token"), "qt");
  // const personId = jwt_decode(localStorage.getItem("queue_token"));

  // const notifierid = personId?.personid;
  React.useLayoutEffect(() => {
    if (props.queueId && props.currantScenario && props.PermissionData) {
      getScenarioStateData(props.queueId, props.currantScenario);
      getScenarioStateCount(props.queueId, props.currantScenario);
      getAllcount(props.queueId, props.currantScenario);
    }
  }, [props.queueId, props.currantScenario, props.PermissionData]);

  const getScenarioStateData = async (queueid, scenarioid) => {
    try {
      let params = {
        db_name: `${db}`,
        entity: "queuescenariostatemapping",
        filter: `queuescenariostatemapping.queueid=='${queueid}'&&queuescenariostatemapping.scenarioid=='${scenarioid}' && queuescenariostatemapping.activestatus==true`,
        return_fields: "{scenarioState:queuescenariostatemapping.states}",
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_ARANGO_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };

      backDrop.setBackDrop({
        ...backDrop,
        open: true,
        message: "Fetching Scenarios Data",
      });

      await axios(config)
        .then((res) => {
          if (res.data.Code === 201) {
            let data = res.data.result[0];
            setlineState(data?.scenarioState);
            let FinalSenario = data?.scenarioState?.filter(
              (e) =>
                props.PermissionData?.filter(
                  (v) =>
                    v.scenarioChildren.filter(
                      (b) =>
                        b.stateChildren.filter(
                          (n) =>
                            n.repoid.unique_id === e._id &&
                            n.permsnread === true &&
                            b.repoid.unique_id === props.currantScenario
                        ).length !== 0
                    ).length !== 0
                ).length !== 0
            );
            setscenarioState(FinalSenario);
            backDrop.setBackDrop({
              ...backDrop,
              open: false,
            });
          } else {
            alertMessage?.setSnack({
              ...alertMessage,
              open: true,
              severity: AlertProps.severity.error,
              msg: "Something Went Wrong",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {}
  };

  const getScenarioStateCount = async (queueid, scenarioid) => {
    let reportingOrg = localStorage.getItem("ReportingOrg") ?? "";
    let adminFilter =
      reportingOrg === ""
        ? {
            db_name: `${db}`,
            filter: {
              queueId: `${queueid}`,
              scenarioId: `${scenarioid}`,
            },
            queryid: "667e0e60-f777-4e17-a16a-b3c93b70226d",
          }
        : {
            db_name: `${db}`,
            filter: {
              queueId: `${queueid}`,
              scenarioId: `${scenarioid}`,
              reportingorgid: `${reportingOrg}`,
            },
            queryid: "5867c24b-60a1-4303-b55c-e7334ea75f59",
          };
    try {
      let params = (params = adminFilter);
      // if (UserRole == "verifier") {

      // } else if (UserRole == "notifier") {
      //   params = {
      //     db_name: `${db}`,
      //     filter: {
      //       queueId: `${queueid}`,
      //       scenarioId: `${scenarioid}`,
      //       notifier_id: `${notifierid}`,
      //     },
      //     queryid: "b9998625-7cb5-4dce-b852-2fe434cb0309",
      //   };
      // }
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_ARANGO_URL_READ_QDMQUERIES}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };

      await axios(config)
        .then((res) => {
          if (res.data) {
            if (res.data?.collection) {
              let data = res.data.collection[0];

              setCount(data?.grps);
            } else {
              let data = res.data[0];

              setCount(data?.grps);
            }
          } else if (res.error) {
            alertMessage?.setSnack({
              ...alertMessage,
              open: true,
              severity: AlertProps.severity.error,
              msg: `QDM Queries ${res.error}`,
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          } else {
            alertMessage?.setSnack({
              ...alertMessage,
              open: true,
              severity: AlertProps.severity.error,
              msg: "Something Went Wrong",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {}
  };

  const getAllcount = async (queueid, scenarioid) => {
    let reportingOrg = localStorage.getItem("ReportingOrg") ?? "";

    // let adminFilter = reportingOrg === "" ?  "2340cfb7-dda8-4c13-8f47-52916c79a6f3" :  "e79b6e7a-b828-4ef0-8a31-3cf83698b8c5"
    let adminFilter =
      reportingOrg === ""
        ? {
            db_name: `${db}`,
            filter: {
              queueId: `${queueid}`,
              scenarioId: `${scenarioid}`,
            },
            queryid: "2340cfb7-dda8-4c13-8f47-52916c79a6f3",
          }
        : {
            db_name: `${db}`,
            filter: {
              queueId: `${queueid}`,
              scenarioId: `${scenarioid}`,
              reportingorgid: `${reportingOrg}`,
            },
            queryid: "e79b6e7a-b828-4ef0-8a31-3cf83698b8c5",
          };
    try {
      let params = (params = adminFilter);

      let config = {
        method: "post",
        url: `${process.env.REACT_APP_ARANGO_URL_READ_QDMQUERIES}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };

      await axios(config)
        .then((res) => {
          if (res.status === 200) {
            let data = res.data;
            setAllCount(data?.length);
          } else {
            alertMessage?.setSnack({
              ...alertMessage,
              open: true,
              severity: AlertProps.severity.error,
              msg: "Something Went Wrong",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {}
  };

  const getCount = (id) => {
    let co = statecount?.filter((l) => l.state === id);
    if (co && co[0]?.cnt) {
      return co[0]?.cnt;
    } else {
      return "0";
    }
  };

  return (
    <>
      <div style={{ height: "100%" }}>
        <TabContext value={value}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              background: "transparent",
            }}
          >
            <TabList
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="lab API tabs example"
              TabIndicatorProps={{
                style: {
                  height: "3px",
                  borderRadius: "5px 5px 0px 0px",
                  background: theme.palette.info.main,
                },
              }}
              // color="info"
            >
              {/* <Tab
                value={"0"}
                icon={
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <StyledButton2>
                      <StarIcon fontSize="small" color="warning" />
                      <Typography
                        variant="subtitle2"
                        style={{ marginLeft: "8px" }}
                      >
                        {"Favourite"}
                      </Typography>
                      <StyledLabel color={theme.palette.action.hover}>
                        {`${favlist.length}`}
                      </StyledLabel>
                    </StyledButton2>
                  </div>
                }
                style={{ minHeight: "54px" }}
              /> */}

              <Tab
                value={"1"}
                label={`All (${allcount})`}
                style={{ minHeight: "54px" }}
              />
              {scenarioState?.map((l, index) => (
                <Tab
                  value={`${index + 2}`}
                  label={`${l.states} (${getCount(l._id)})`}
                  key={index}
                  style={{ minHeight: "54px" }}
                />
              ))}
            </TabList>
          </Box>
          <TabPanel
            style={{
              height: "calc(100% - 54px)",
              overflowY: "auto",
              padding: "0",
            }}
            value={"0"}
          >
            <ListPanel
              PermissionData={props.PermissionData}
              favtab={true}
              each={false}
              queueId={props.queueId}
              scenarioId={props.currantScenario}
              scenarioState={scenarioState}
              getScenarioStateData={getScenarioStateData}
              getScenarioStateCount={getScenarioStateCount}
              getAllcount={getAllcount}
              updateFavState={updateFavState}
              favlist={favlist}
              setValue={setValue}
              updateScenaorio={props.updateScenaorio}
              timelineState={timelineState}
            />
          </TabPanel>
          <TabPanel
            style={{
              height: "calc(100% - 54px)",
              overflowY: "auto",
              padding: "0",
            }}
            value={"1"}
          >
            <ListPanel
              PermissionData={props.PermissionData}
              favtab={false}
              each={false}
              queueId={props.queueId}
              scenarioId={props.currantScenario}
              scenarioState={scenarioState}
              getScenarioStateData={getScenarioStateData}
              getScenarioStateCount={getScenarioStateCount}
              getAllcount={getAllcount}
              updateFavState={updateFavState}
              favlist={favlist}
              setValue={setValue}
              updateScenaorio={props.updateScenaorio}
              timelineState={timelineState}
            />
          </TabPanel>
          {scenarioState?.map((l, index) => (
            <TabPanel
              value={`${index + 2}`}
              key={index}
              style={{
                height: "calc(100% - 54px)",
                overflowY: "auto",
                padding: "0",
              }}
            >
              <ListPanel
                PermissionData={props.PermissionData}
                favtab={false}
                each={true}
                queueId={props.queueId}
                scenarioId={props.currantScenario}
                stateid={l._id}
                scenarioState={scenarioState}
                setValue={setValue}
                updateFavState={updateFavState}
                favlist={favlist}
                getScenarioStateData={getScenarioStateData}
                getScenarioStateCount={getScenarioStateCount}
                getAllcount={getAllcount}
                updateScenaorio={props.updateScenaorio}
                timelineState={timelineState}
              />
            </TabPanel>
          ))}
        </TabContext>
      </div>
    </>
  );
};
