/**
 * @author Kumaravel Pazhani
 * @email kumaravel@qdmplatforms.com
 * @create 12/11/2020
 * @modify 12/11/2020
 * @desc Exporting all the components from /src/components
 */

import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { TextField, Typography, InputAdornment } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = (theme) => ({
    selectBox: {
        width: "100%",
        height: "35px",
        "& .App1-MuiOutlinedInput-root": {
            borderRadius: "7px",
        },
        "& .App1-MuiFormLabel-root.Mui-focused": {
            transform: "translate(14px, -6px) scale(0.75) !important",
        },
        "& .App1-MuiFormLabel-root.App1-MuiInputLabel-shrink": {
            transform: "translate(14px, -6px) scale(0.75) !important",
        },
        "& .App1-MuiInputLabel-outlined": {
            transform: "translate(14px, 9px) scale(1) !important",
        },
        "& .App1-MuiAutocomplete-inputRoot": {
            height: "35px",
            "& input": {
                padding: "0.5px 4px !important",
            },
        },
        "& .App1-MuiAutocomplete-tag": {
            margin: "3px -4px 3px 8px",
            height: "25px",
            fontSize: "0.8rem"
        }
    },
    MultiselectBox: {
        width: "100%",
        // height: "35px",
        "& .App1-MuiAutocomplete-inputRoot": {
            "& input": {
                padding: "0.5px 4px !important",
            },
        },
        "& .App1-MuiAutocomplete-tag": {
            margin: "3px -4px 3px 8px",
            height: "25px",
            fontSize: "0.8rem"
        }
    }
});

class SelectBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        let {
          serachIcon,
          Optionlabel = "label",
          placeholder,
          classes,
          onchange,
          classname,
          list,
          value,
          title,
          top_title,
          multi,
          disableClearable,
          disabled,
          requireStart,
          parent_id,
          error,
        } = this.props;
        return (
            <React.Fragment>
                {top_title && <Typography
                    id={`${parent_id}_select_${top_title.replace(" ", "_")}_typography`}
                    variant="caption">{top_title}{requireStart && <span
                        id={`${parent_id}_select_${top_title}_star_span`}
                        style={{
                            color: 'red',
                            marginLeft: 5
                        }}>*</span>}</Typography>}
                {serachIcon ? (
                    <Autocomplete
                        id={`${parent_id}_select_autocomplete`}
                        multiple={multi}
                        options={list}
                        disabled={disabled ? disabled : false}
                        className={`${classes.selectBox} ${value ? "Mui-focused" : ""} ${classname ? classname : ""
                            }`}
                        onChange={onchange}
                        getOptionLabel={(option) => option[Optionlabel]}
                        style={{ width: "100%", height: "35px", marginTop: "4px" }}
                        value={value}
                        popupIcon={
                            <InputAdornment
                                id={`${parent_id}_select_InputAdornment`}
                                position="end">
                                <SearchIcon
                                    id={`${parent_id}_select_SearchIcon`}
                                    style={{ color: "#6F6F6F" }} />
                            </InputAdornment>
                        }
                        renderInput={(params) => (
                            <TextField
                                id={`${parent_id}_select_textfield`}
                                style={{ height: "35px" }}
                                placeholder={placeholder}
                                {...params}
                                label={top_title ? "" : title}
                                variant="outlined"
                            />
                        )}
                    />
                ) : (
                    <Autocomplete
                        id={`${parent_id}_select_disableClearable_autocomplete`}
                        multiple={multi}
                        disableClearable={disableClearable}
                        disabled={disabled ? disabled : false}
                        options={list}
                        className={`${multi ? classes.MultiselectBox : classes.selectBox} ${value ? "Mui-focused" : ""} ${classname ? classname : ""
                            }`}
                        onChange={onchange}
                        getOptionLabel={(option) => option[Optionlabel]}
                        style={{ width: "100%", height: multi ? "" : "35px", marginTop: "4px" }}
                        value={value}
                        renderInput={(params) => (
                            <TextField
                                id={`${parent_id}_select_disableClearable_textfield`}
                                style={{ height: multi ? "" : "35px" }}
                                placeholder={placeholder}
                                {...params}
                                error={error}
                                label={top_title ? "" : title}
                                variant="outlined"
                            />
                        )}
                    />
                )}
            </React.Fragment>
        );
    }
}

export default withStyles(useStyles)(SelectBox);
