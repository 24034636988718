import React from "react";
import Avatar from "@material-ui/core/Avatar";
import { useStyles } from "./style";

function StyledAvatar(props) {
  const classes = useStyles();
  return <Avatar variant="rounded" classes={classes} {...props} />;
}

export default StyledAvatar;
