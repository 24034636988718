import React from "react";
import { TopNavBar } from "./topNavbar";

class TopNavbarParent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <TopNavBar {...this.props} />;
  }
}

export default TopNavbarParent;
