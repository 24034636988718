import { Paper } from "@material-ui/core";

import React, { Component } from "react";
import { withRouter } from "react-router";
import { withAllContexts } from "../../HOCs";
// import { TaskViewer } from "./src/index";
import { TaskViewer } from "./TaskViewer";
import md5 from "md5";

class TaskViewers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      PermissionData: [],
    };
  }
  componentDidMount() {
    this.generateCheckSum();
  }

  generateCheckSum = async () => {
    localStorage.setItem("RoleId", "122");
    var rol = {
      facilityid: "",
      roleid: "122",
      locationid: "",
      personid: process.env.REACT_APP_REGISTRATION_VIEWER_ACCESS,
      enterpriseid: "",
      orgid: "",
      apikey: process.env.REACT_APP_APIKEY,
    };
    let CheckSum = md5(JSON.stringify(rol));

    rol.CheckSum = CheckSum;
    fetch(process.env.REACT_APP_IDMJWTTokencreation, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(rol),
    })
      .then((res) => res.json())
      .then((result) => {
        // console.log("first", result.Result);
        var myHeaders = new Headers();
        myHeaders.append("jwttoken", result.Result);

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          redirect: "follow",
        };

        fetch(process.env.REACT_APP_QUEUELIST, requestOptions)
          .then((response) => response.json())
          .then((result) => {
            this.setState({ PermissionData: result.result });
          })
          .catch((error) => console.log("error", error));
      })
      .catch((err) => console.log(err));
  };
  render() {
    return (
      <>
        <Paper
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "#F3F4F7",
          }}
          elevation={3}
        >
          <TaskViewer
            PermissionData={this.state.PermissionData}
            dbName={process.env.REACT_APP_DB}
            performUserAction={process.env.REACT_APP_PERFORMUSERACTION_API}
            auditdb={process.env.REACT_APP_AuditDB}
            theme={require("../../themes/default.json")}
          />
        </Paper>
      </>
    );
  }
}

export default withRouter(withAllContexts(TaskViewers));
