import React from "react";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import MuiInputBase from "@material-ui/core/InputBase";
import { makeStyles, withStyles } from "@material-ui/core/styles";

const InputBase = withStyles((theme) => ({
  root: {
    width: "100%",
    flex: 1,
    gap: 10,
    padding: "10px 12px",
    background: theme.palette.background.table,
    borderRadius: "8px",
    // border: `1px solid ${theme.palette.border.textField}`,
    "& .App1-MuiInputBase-input": {
      minWidth: 20,
    },
    color: theme.palette.text.primary,
  },
  input: {
    padding: 0,
    "&:focus": {
      backgroundColor: "unset",
    },
  },
}))(MuiInputBase);

const useStyles = makeStyles((theme) => ({
  container: {
    border: `1px solid ${theme.palette.border.textField}`,
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
  },
  divider: {
    margin: "0px 8px",
    backgroundColor: theme.palette.border.textField,
  },
  legend: ({ legendWidth }) => ({
    paddingRight: theme.spacing(1),
    maxWidth: legendWidth ? `${legendWidth}` : "25%",
    overflow: "hidden",
    textOverflow: "ellipsis",
  }),
}));

function InputWithLegend({ legend, ...rest }) {
  const classes = useStyles(rest);
  return (
    <div className={classes.container}>
      <InputBase {...rest} />
      <Divider orientation="vertical" flexItem className={classes.divider} />
      <Typography title={legend ?? "legend"} className={classes.legend}>
        {legend ?? "Legend"}
      </Typography>
    </div>
  );
}

export default InputWithLegend;
