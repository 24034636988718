import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Chip, OutlinedInput, Typography } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  addButton: {
    textTransform: "capitalize",
  },
  chip: {
    margin: theme?.spacing(0.5),
  },
  labelStyle: {
    fontSize: 16,
  },
}));

export default function CustionAddText(props) {
  const classes = useStyles();
  const [values, setValues] = React.useState("");

  const handleChange = (event) => {
    setValues(event.target.value);
  };

  const handleClickAdd = () => {
    if (!values) {
      return false;
    }

    let object = {
      label: values,
      value: values,
    };
    props.options.push(object);
    props.addTag(props.options);
    setValues("");
  };

  const handleDelete = (val) => {
    let deleted = props.options.filter((vals) => vals.value !== val.value);
    props.addTag(deleted);
  };

  const handleKeyUp = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      handleClickAdd();
    }
  };

  return (
    <div style={{display : props?.disabled ? "none" : ""}}>
      {
        <Typography
          id={"custom +" + props?.id}
          variant="body1"
          className={classes.labelStyle}
          gutterBottom
        >
          {props.label}{" "}
          {props.isrequired && (
            <Typography variant="caption" style={{ color: "red" }}>
              *
            </Typography>
          )}
        </Typography>
      }
      <FormControl size={"small"} fullWidth variant="outlined">
        <OutlinedInput
          readOnly={props?.isReadonly ?? false}
          fullWidth
          disabled={props?.disabled}
          id="outlined-adornment-password"
          type={"text"}
          value={values}
          inputProps={{
            shrink: false,
          }}
          onKeyUp={handleKeyUp}
          onChange={handleChange}
          endAdornment={
            <InputAdornment position="end">
              <Button
                id={"+ADDBtn +" + props?.id}
                onClick={handleClickAdd}
                className={classes.addButton}
                size={"small"}
                color={"primary"}
                variant={"text"}
              >
                + Add
              </Button>
            </InputAdornment>
          }
          error={
            props?.errorValidation && props?.errorValidation?.error
              ? props?.errorValidation?.error
              : false
          }
          helperText={
            props?.errorValidation && props?.errorValidation?.errorMessage
              ? props?.errorValidation?.errorMessage
              : ""
          }
          // labelWidth={props.label.length * 8}
        />
      </FormControl>
      {props.options.length > 0 &&
        props.options.map((val) => {
          return (
            <Chip
              id={"chip +" + props?.id}
              className={classes.chip}
              label={val.label}
              onDelete={() => handleDelete(val)}
            />
          );
        })}
    </div>
  );
}

CustionAddText.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  options: PropTypes.array, // [{value: "", label: ""}]
  addTag: PropTypes.func,
  error: PropTypes.bool,
  readOnly: PropTypes.bool,
};

CustionAddText.defaultProps = {
  label: "",
  options: [],
  addTag: () => {},
  error: false,
};
