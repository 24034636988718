import React from "react";
import { PageEmpty, TopNavBar } from "../../components";
import { withAllContexts, withSideBars } from "./../../HOCs";

class ActivityLog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <>
        <TopNavBar />
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "grid",
            placeItems: "center",
          }}
        >
          <PageEmpty />
        </div>
      </>
    );
  }
}

export default withSideBars(withAllContexts(ActivityLog));
