import { makeStyles, Grid, Typography } from "@material-ui/core";
import React from "react";
import { Checkbox } from "@material-ui/core";
import { Routes } from "./../../router/routes";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
// import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    float: "right",
    padding: 6,
    marginBottom: 0,
  },
  patientDetailSection: {
    maxHeight: 100,
    overflow: "auto",
  },
}));

export const UserDetailCard = (props) => {
  const {
    patient_id = {},
    patient_name = {},
    gender = {},
    mobile = {},
    email = "",
    dob_age = {},
    country = {},
    onselect = () => false,
    showSelectOptions = false,
    id = "",
  } = props;

  const classes = useStyles();
  const userData = {};
  const current = new Date();
  const date = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`;
  const giveMeFieldToShow = (v, l) => {
    if (Array.isArray(v[l?.field])) {
      //If the value is array then retrun the string.
      return v[l?.field]
        ?.map((_) => {
          //If the array child is the label,value pare then return only label
          if (_?.label) {
            return _?.label;
          }
          return _;
        })
        .toString();
    } else if (v[l?.field]?.label) {
      //If the value is object with value,label pair then return label only
      return v[l?.field]?.label;
    } else if (v[l?.field]?.address) {
      //If the value is a location object then return the formated address
      return `${v[l?.field].address} (${v[l?.field]?.latitude}, ${
        v[l?.field]?.longitude
      })`;
    } else if (v[l?.field]?.tableData) {
      //If the value is the table type,
      return "-";
    } else if (v[l?.field]?.number) {
      return `${v[l?.field]?.phoneCode} ${v[l?.field]?.number}`;
    } else if (v[l?.field]?.country) {
      return `${v[l?.field]?.country?.value}`;
    } else {
      //If nothing mathes the above conditions
      return v[l?.field];
    }
  };
  return (
    <Grid container spacing={1} style={{ padding: "0  20px" }}>
      <Grid item sm={6} md={2} lg={2} xl={2} xs={12}>
        <Typography variant="body1">Facility</Typography>
        <Typography variant="body2">{userData?.facility}</Typography>
      </Grid>
      <Grid item sm={6} md={2} lg={2} xl={2} xs={12}>
        <Typography variant="body1">Cluster</Typography>
        <Typography variant="body2"> {userData?.cluster}</Typography>
      </Grid>
      <Grid item sm={6} md={2} lg={3} xl={2} xs={12}>
        <Typography variant="body1">PCI Location</Typography>
        <Typography variant="body2">{userData?.pcilocation}</Typography>
      </Grid>
      <Grid item sm={6} md={2} lg={3} xl={2} xs={12}>
        <Typography variant="body1">Entered By</Typography>
        <Typography variant="body2">{userData?.first_name}</Typography>
      </Grid>
      <Grid item sm={6} md={2} lg={2} xl={2} xs={12}>
        <Typography variant="body1">Entry Date</Typography>
        <Typography variant="body2">{date}</Typography>
      </Grid>
    </Grid>
  );
};
