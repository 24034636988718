import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, TextField, Typography, Button } from "@material-ui/core";
import { ToggleButtonComponent } from "../../components";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme?.spacing(2),
  },
  gridItem: {
    marginBottom: theme?.spacing(2),
  },
  saveButton: {
    marginTop: theme?.spacing(2),
  },
  cancelBtn: {
    marginLeft: theme?.spacing(2),
  },
}));

export default function SectionProperties(props) {
  const classes = useStyles(props);

  const options = [
    {
      label: "1 Column Layout",
      value: "1 Column Layout",
    },
    {
      label: "2 Column Layout",
      value: "2 Column Layout",
    },
    {
      label: "3 Column Layout",
      value: "3 Column Layout",
    },
  ];

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12} className={classes.gridItem}>
          <TextField
            value={props?.data?.name}
            onChange={(e) => props?.setData(e.target.value, "name")}
            fullWidth
            label="Section Title"
            id="sectionTitle"
            variant="outlined"
            size="small"
            // error={val?.error?.labelError ?? false}
            // helperText={val?.error?.labelError ? "Please give the question (must be more then two character)" : ""}
          />
        </Grid>
        <Grid item xs={12} className={classes.gridItem}>
          <TextField
            value={props?.data?.description}
            onChange={(e) => props?.setData(e.target.value, "description")}
            fullWidth
            label="Section Description"
            id="sectionDescription"
            variant="outlined"
            size="small"
            multiline
            rows={4}
            // error={val?.error?.labelError ?? false}
            // helperText={val?.error?.labelError ? "Please give the question (must be more then two character)" : ""}
          />
        </Grid>
        <Grid item>
          <Typography className={classes.gridItem}>
            {`Form Layout Type`}
          </Typography>
        </Grid>
        <Grid item>
          <ToggleButtonComponent
            toggleButtonData={options}
            isSelected={props?.data?.formLayoutType}
            exclusive={true}
            onSelected={(selected) =>
              props?.setData(selected, "formLayoutType")
            }
          />
        </Grid>
        <Grid item>
          <Button
            color="primary"
            onClick={() => props?.saveAndUpdate()}
            variant="contained"
            className={classes.saveButton}
          >
            Save
          </Button>
        </Grid>
        <Grid item className={classes.cancelBtn}>
          <Button
            color="primary"
            onClick={() => props?.close(false)}
            variant="contained"
            className={classes.saveButton}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
