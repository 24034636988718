import React from "react";
import { makeStyles, Grid, Typography, IconButton } from "@material-ui/core";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { Button, Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { Upload } from "./fileUpload";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import { ToBase64 } from "../../utils";
import { AlertContext } from "../../contexts";
import { AlertProps } from "../../utils";
import GetAppIcon from "@material-ui/icons/GetApp";
// import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
// import { Document, Page, pdfjs } from "react-pdf";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import { ToolbarSlot, TransformToolbarSlot } from "@react-pdf-viewer/toolbar";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
// import { Axios } from "../../function/commonapi";
import { CircularProgress } from "@material-ui/core";
import axios from "axios";
import { PDFJSStatic } from "pdfjs-dist";

// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

var b64toBlob = require("b64-to-blob");
var FileSaver = require("file-saver");
const pdfjsLib = require("pdfjs-dist");

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    position: "relative",
    minHeight: "100%",
    backgroundColor: theme?.palette?.background?.paper,
    margin: 0,
  },
  root: {
    // padding: theme?.spacing(3),
    // paddingTop: theme?.spacing(2),
  },
  footer: {
    position: "absolute",
    bottom: 0,
    width: "100%",
  },
  content: {
    display: "inline-flex",
    width: "100%",
  },
  cardContainer: {
    backgroundColor: theme?.palette?.background?.paper,
    marginTop: theme?.spacing(2),
    boxShadow: "0px 10px 15px #00000014",
    border: "2px solid #E2E2E2",
    borderRadius: "8px",
  },
  Container: {
    // backgroundColor: theme.palette.background.paper,
    marginTop: theme?.spacing(0.5),
    marginBottom: theme?.spacing(0.5),
    // boxShadow: "0px 10px 15px #00000014",
    border: "1px solid #E2E2E2",
    borderRadius: "8px",
    padding: "2px",
  },
  circularLoader: {
    paddingRight: "8px",
    position: "relative",
    "& .MuiCircularProgress-root": {
      position: "relative",
    },
  },
  loadCenter: {
    position: "absolute",
    left: 14,
  },
  uploadText: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  errorText: {
    color: "red",
    fontSize: "0.75rem",
  },
}));

export const UploadReports = (props) => {
  const { validation } = props;
  // console.log(validation);
  const classes = useStyles(props);
  const [previewOpen, setPreviewOpen] = React.useState(false);
  const [fileDetails, setFileDetails] = React.useState({});
  const [fileData, setFileData] = React.useState();
  const [viewDocss, setViewDocss] = React.useState(false);
  const [pdfFile, setPDFFile] = React.useState(null);
  const [viewPdf, setViewPdf] = React.useState(null);
  const [isLoading, setIsloading] = React.useState(false);

  const alert = React.useContext(AlertContext);

  const [uploaddocc, setuploaddocc] = React.useState(
    props?.value ? props?.value : []
  );

  const returnFileSize = (number) => {
    if (number < 1024) {
      return number + "bytes";
    } else if (number >= 1024 && number < 1048576) {
      return (number / 1024).toFixed(1) + "KB";
    } else if (number >= 1048576) {
      return (number / 1048576).toFixed(1) + "MB";
    }
  };

  const FileSizeSetLimit = (lists) => {
    // console.log(lists);
    return !lists.every((list) =>
      list.size.slice(-2) === "KB"
        ? Number(list.size.slice(0, -2))
        : Number(list.size.slice(0, -2)) * 1024 <= validation.max * 1024
    );

    // if (number >= 1048576) {
    //   if ((number / 1048576).toFixed(1) > 15) {
    //     return false;
    //   } else {
    //     return true;
    //   }
    // } else {
    //   return true;
    // }
  };
  const typesAllowed = () => {
    setIsloading(false);
    return [...fileTypes];
  };
  // props.question_options.reduce(
  //   (acc, curr) => (acc += `${curr.split("/")[1].toUpperCase()} `)
  // );

  const ValidateNumberOfFiles = (num) => {
    let valid = false;
    if (num <= Number(validation.noOfFiles)) {
      valid = true;
    }
    return valid;
  };
  const handleFileUploadApi = async (file) => {
    var formdata = new FormData();
    formdata.append("files", file, file.name);
    // console.log("formDss", formdata, file);
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    let { fileid } = await (
      await fetch(validation.uploadurl, requestOptions)
    ).json();
    let dds = await (await fetch(validation.uploadurl, requestOptions)).json();
    // console.log("dds", dds);
    return `${validation.uploadurl.slice(0, -2)}${fileid}`;
  };

  function fileToString(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        // $FlowFixMe
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  }

  const fileType = ["application/pdf"];
  const handleChange = (e) => {
    // console.log("eeee", e.target.result);
    let selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile && fileType.includes(selectedFile.type)) {
        let reader = new FileReader();
        reader.readAsDataURL(selectedFile);
        reader.onload = (e) => {
          // console.log("eeee", e.target.result);
          setPDFFile(e.target.result);
          setViewPdf(e.target.result);
          return e.target.result;
        };
      } else {
        setPDFFile(null);
        setViewPdf(null);
      }
    } else {
      // console.log("plll");
    }
  };

  const handleUpload = async (e) => {
    setIsloading(true);

    e.preventDefault();

    let file = e.target.files[0];
    // console.log("ffgg", file.type);
    let fileType = fileTypes.map((li) => {
      if (file.type.includes(li)) {
        return true;
      } else {
        return false;
      }
    });

    // console.log("fileType", fileType.includes(true));
    if (fileType.includes(true)) {
      handleChange(e);
      let fileList = [];
      if (
        validation.multiple === true &&
        validation.noOfFiles.length !== 0 &&
        !ValidateNumberOfFiles(e.target.files.length)
      ) {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: `Cannot Upload More Than ${validation.noOfFiles} File !`,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      } else {
        for (let i = 0; i < e.target.files.length; i++) {
          let file = e.target.files[i];
          let size = returnFileSize(file.size);
          let files = new FormData();
          files.append("files", file);
          let base = !validation?.typeOfUpload
            ? await ToBase64(file)
            : await handleFileUploadApi(file);

          let bb = await ToBase64(file);

          // console.log("fileInner", file, base, bb);
          const data = {
            path: file?.name,
            name: file?.name,
            load: true,
            file: base,
            size: size,
            type: file?.type,
          };

          // const res = await fileToString(data);
          // setFileData(res);
          fileList.push(data);
          let resultH = handleChange(e);
        }
        if (FileSizeSetLimit(fileList)) {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: `File Size Is Too Large, Maximum File Size is ${validation.max ? validation.max : 1
              }MB !`,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        } else if (!validFileType(fileList)) {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: `Only ${typesAllowed()} File Type Accepted !`,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        } else {
          setuploaddocc(fileList);
          setIsloading(false);
          props.onChange([...uploaddocc, ...fileList]);
        }
      }
    } else {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: `Only ${typesAllowed()} File Type Accepted !`,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
    }

    // if (FileSizeSetLimit(file.size)) {
    //   if (props?.single && uploaddocc.length >= 1) {
    //     alert.setSnack({
    //       ...alert,
    //       open: true,
    //       severity: AlertProps.severity.error,
    //       msg: "Only Single File can be Uploaded !",
    //       vertical: AlertProps.vertical.top,
    //       horizontal: AlertProps.horizontal.center,
    //     });
    //   } else {
    //     if (props?.single && !validFileType(file)) {
    //       alert.setSnack({
    //         ...alert,
    //         open: true,
    //         severity: AlertProps.severity.error,
    //         msg: "Only Image File Type Accepted !",
    //         vertical: AlertProps.vertical.top,
    //         horizontal: AlertProps.horizontal.center,
    //       });
    //     }

    // if (validFileType(fileList)) {
    // setuploaddocc([...uploaddocc, ...[data]]);
    // props.onChange([...uploaddocc, ...[data]]);
    // }

    //     if (!props?.single) {
    //       setuploaddocc([...uploaddocc, ...[data]]);

    //       props.onChange([...uploaddocc, ...[data]]);
    //     }
    //   }
    // }
    // if (!FileSizeSetLimit(file.size)) {
    //   alert.setSnack({
    //     ...alert,
    //     open: true,
    //     severity: AlertProps.severity.error,
    //     msg: "File Size Too Large !",
    //     vertical: AlertProps.vertical.top,
    //     horizontal: AlertProps.horizontal.center,
    //   });
    // }
  };

  const toolbarPluginInstance = toolbarPlugin();
  const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;

  const transform = (slot) => ({
    ...slot,

    Download: () => <></>,
    DownloadMenuItem: () => <></>,
    NumberOfPages: () => <></>,
    OpenMenuItem: () => <></>,
    Open: () => <></>,
    // EnterFullScreen: () => <></>,
    CurrentPageInput: () => <></>,
    CurrentPageLabel: () => <></>,
    CurrentScale: () => <></>,
    GoToFirstPage: () => <></>,
    GoToFirstPageMenuItem: () => <></>,
    GoToLastPage: () => <></>,
    GoToLastPageMenuItem: () => <></>,
    GoToNextPage: () => <></>,
    GoToNextPageMenuItem: () => <></>,
    GoToPreviousPage: () => <></>,
    GoToPreviousPageMenuItem: () => <></>,
    // Print: () => <></>,
    // PrintMenuItem: () => <></>,
    Rotate: () => <></>,
    RotateBackwardMenuItem: () => <></>,
    RotateForwardMenuItem: () => <></>,
    DualPage: () => <></>,
    DualPageMenuItem: () => <></>,
    // ShowProperties: () => <></>,
    // ShowPropertiesMenuItem: () => <></>,
    ShowSearchPopover: () => <></>,
    SwitchScrollMode: () => <></>,
    SwitchScrollModeMenuItem: () => <></>,
    SwitchSelectionMode: () => <></>,
    SwitchSelectionModeMenuItem: () => <></>,
    EnterFullScreenMenuItem: () => <></>,
    SwitchTheme: () => <></>,
    SwitchThemeMenuItem: () => <></>,
  });

  const handleDelete = (e) => {
    var uploaddoccdelete = [];
    uploaddoccdelete = [...uploaddocc];
    uploaddoccdelete.splice(0, 1);
    //delete uploaddocc[0];
    // uploaddocc.splice(i, 1);

    setuploaddocc([...uploaddoccdelete]);
    // setuploaddocc([]);

    props.onChange([...uploaddoccdelete]);

    // uploaddocc.splice(0,1);
    // setuploaddocc([...uploaddocc]);
    // props.onChange([...uploaddocc]);
  };

  const getLabel = (props) => {
    return (
      <Typography variant="body1" style={{ fontSize: 16 }} gutterBottom>
        {props?.label}{" "}
        {props?.isrequired && (
          <Typography
            variant="caption"
            style={{ color: "red", marginLeft: "2px" }}
          >
            *
          </Typography>
        )}
      </Typography>
    );
  };

  const fileTypes = [
    // "image/png",
    // "image/bmp",
    // "image/gif",
    // "image/jpeg",
    // "image/pjpeg",
    // "image/png",
    // "image/svg+xml",
    // "image/tiff",
    // "image/webp",
    // "image/x-icon",

    // "image/jpeg",
    // "image/png",
    // "application/pdf",
    ...props?.question_options,
  ];

  // console.log("question_options", props?.question_options);
  const validFileType = (files) => {
    return files.every((file) => fileTypes.includes(file.type));
  };

  const Download = async (data) => {
    var contentType = data.file.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
    var b64Data = data.file.split(",")[1];

    var blob = await b64toBlob(b64Data, contentType);

    var saveBlob = await new Blob([blob], { type: contentType });
    await FileSaver.saveAs(saveBlob, data.name);
  };

  const returnUploadComponent = (len) => {
    if (!props?.isReadonly && uploaddocc?.length <= 0) {
      return isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Grid item>
          <Upload
            acceptType={props?.question_options}
            onChange={handleUpload}
            single={props?.single}
            id={props?.id}
          />
        </Grid>
      );
    }
  };

  const deleteUpload = () => {
    setuploaddocc([]);
  };

  const newPlugin = defaultLayoutPlugin();

  const convertPdfToBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      // Set the onload callback function
      // reader.onload = () => {
      //   const base64Data = reader.result.split(",")[1]; // Extract the base64 data from the reader result
      //   resolve(base64Data);
      // };

      reader.onload = () => {
        const base64Data = reader.result.split(",")[1]; // Extract the base64 data from the reader result
        resolve(base64Data);
      };
      reader.onerror = (error) => {
        reject(error);
      };

      // reader.onload = (e) => {
      //   console.log("eeee22", e.target.result);
      //   // setPDFFile(e.target.result);
      //   // setViewPdf(e.target.result);
      //   // return e.target.result;
      // };

      // Set the onerror callback function

      // Read the raw data as a data URL
      reader.readAsDataURL(new Blob([file]));
    });
  };

  const [scale, setScale] = React.useState(1);

  const handleZoomIn = () => {
    setScale(scale + 0.1);
  };

  const handleZoomOut = () => {
    // console.log("sacle", scale);
    if (scale > 0.2) {
      setScale(scale - 0.1);
    }
  };

  const updateFile = () => {
    return (
      <Dialog open={previewOpen} maxWidth="md" fullWidth>
        <div
          style={{
            display: "flex",
            justifyContent: " space-between",
            alignItems: "center",
          }}
        >
          <DialogTitle>File Preview</DialogTitle>
          <button
            onClick={closeViewDoc}
            style={{
              marginRight: "20px",
              width: "70px",
              height: "30px",
              background: "#ED3330",
              color: "white",
              border: "none",
              borderRadius: "6px",
              cursor: "pointer",
            }}
          >
            Close
          </button>
        </div>
        <>
          {fileDetails?.type?.includes("pdf") ? (
            <Toolbar>
              {(props) => {
                const {
                  CurrentPageInput,
                  Download,
                  EnterFullScreen,
                  GoToNextPage,
                  GoToPreviousPage,
                  NumberOfPages,
                  Print,
                  ShowSearchPopover,
                  Zoom,
                  ZoomIn,
                  ZoomOut,
                } = props;
                return (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "50px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginLeft: "100px",
                      }}
                    >
                      <ZoomOut />

                      <Zoom />

                      <ZoomIn />
                    </div>

                    <div
                      style={{
                        width: "100px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginRight: "100px",
                      }}
                    >
                      <EnterFullScreen />

                      <Print />
                    </div>
                  </div>
                );
              }}
            </Toolbar>
          ) : (
            <></>
          )}
        </>
        <>
          {fileDetails?.type?.includes("image") ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  userSelect: "none",
                }}
                onClick={handleZoomIn}
              >
                <h5>ZoomIn</h5>
                <ZoomInIcon />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "20px",
                  cursor: "pointer",
                  userSelect: "none",
                }}
                onClick={handleZoomOut}
              >
                <h5>ZoomOut</h5>
                <ZoomOutIcon />
              </div>

              {/* <button style={{
                outline: "none",
                border: "none",

              }} onClick={handleZoomIn}>Zoom In</button> */}
              {/* <button onClick={handleZoomOut}>Zoom Out</button> */}
            </div>
          ) : (
            <></>
          )}
        </>

        <DialogContent>
          {fileDetails?.type?.includes("image") ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={fileDetails.file}
                alt="Preview"
                style={{
                  transform: `scale(${scale})`,
                  marginBottom: "30px",
                  marginTop: "20px",
                }}
              />
            </div>
          ) : (
            <>
              {
                fileDetails?.type?.includes("pdf") ? (
                  <>
                    {/* <Toolbar>{renderDefaultToolbar(transform)}</Toolbar> */}
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                      {viewPdf && (
                        <>
                          <Viewer
                            fileUrl={viewPdf}
                            plugins={[toolbarPluginInstance]}
                          />
                        </>
                      )}
                      {!viewPdf && <>No PDF</>}
                    </Worker>
                  </>
                ) : (
                  <></>
                )

                /*<DocViewer
              pluginRenderers={DocViewerRenderers}
              //documents={[{ uri: fileDetails.file }]}
              documents={uploaddocc.map((files) => ({
                uri: window.URL.createObjectURL(
                  new Blob([files], {
                    type: "application/pdf",
                  })
                ),
                fileName: files.name,
              }))}
            /> */
              }
            </>
          )}
        </DialogContent>
      </Dialog>
    );
  };

  const validateBase64Data = (base64Data) => {
    return new Promise((resolve, reject) => {
      const loadingTask = pdfjsLib.getDocument({ data: base64Data });

      loadingTask.promise
        .then((pdfDocument) => {
          // PDF is valid
          resolve();
        })
        .catch((error) => {
          // Invalid PDF or error occurred
          reject(error);
        });
    });
  };

  const viewDoc = async (files) => {
    // let fileData = await Axios("GET", fileDetails.file);

    let res = await axios({
      url: files.file,
      responseType: "arraybuffer",
      responseEncoding: "binary",
      headers: {
        "Content-Type": "application/pdf",
      },
    }).then((res) => {
      return res.data;
    });
    // console.log("fileData", res);
    // let dataRaw = window.btoa(encodeURIComponent(res));
    // console.log("fileData1cv", dataRaw);

    let bb = await convertPdfToBase64(res)
      .then((base64Data) => {
        // console.log("base64Data", base64Data); // Base64 representation of the PDF data
        return base64Data;
      })
      .catch((error) => {
        console.error(error);
      });
    // // let bb = await ToBase64(res);
    // console.log("bb1vb", `data:application/pdf;base64,${bb}`);

    let basePDF = `data:application/pdf;base64,${bb}`;

    // let unitArrData = convertBase64ToUint8Array(bb);

    // console.log("unitArrData", basePDF);

    setViewPdf(basePDF);
    setPDFFile(basePDF);
    setFileDetails({ ...files });
    setPreviewOpen(!previewOpen);
    setViewDocss(true);
  };

  const closeViewDoc = () => {
    setPreviewOpen(false);
    setViewDocss(false);
  };

  // console.log("propsFile", uploaddocc);

  return (
    <div
      className={classes.rootContainer}
      style={{ display: props?.disabled ? "none" : "" }}
    >
      <div className={classes.root} noValidate>
        {/* {uploaddocc?.length > 0 && props?.isReadonly && ( */}
        <Typography variant="body1" color={"textPrimary"}>
          {getLabel(props)}
        </Typography>
        {/* )} */}
        <Grid>
          {returnUploadComponent()}

          {uploaddocc?.length > 0 && (
            <>
              <div>
                <Typography variant="body1">Uploaded Files</Typography>
              </div>
              <div
                style={{
                  overflowY: "auto",
                  overflowX: "hidden",
                  height: props?.single ? "65px" : "100.5px",
                  border: "1px solid #E8000014",
                }}
              >
                {uploaddocc?.map((item, key) => {
                  {/* console.log("itemfile", item.file); */}
                  return (
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      className={classes.Container}
                      onClick={returnUploadComponent()}
                    >
                      <Grid item xs={2} sm={2} md={1} lg={1}>
                        <DescriptionOutlinedIcon
                          style={{ marginLeft: "3px" }}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={6}
                        md={9}
                        lg={10}
                        className={classes.uploadText}
                      >
                        <Typography variant="subtitle2" color="textPrimary">
                          {item?.name}
                        </Typography>
                      </Grid>

                      <Grid
                        style={{ paddingRight: "60px" }}
                        item
                        xs={2}
                        sm={2}
                        md={1}
                        lg={1}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <IconButton
                            id={`${"downloadBtn" + key}`}
                            onClick={() => window.open(item.file)}
                          >
                            <GetAppIcon style={{ color: "green" }} />
                          </IconButton>
                          {(item.type.includes("image") ||
                            item.type.includes("pdf")) && (
                              <IconButton
                                id={`${"viewBtn" + key}`}
                                onClick={() => viewDoc(item)}
                              >
                                <RemoveRedEyeIcon />
                              </IconButton>
                            )}

                          {/* <IconButton
                            id={`${"downloadBtn" + key}`}
                            onClick={() => handleDelete()}
                          >
                            <DeleteOutlineIcon />
                          </IconButton> */}
                        </div>
                      </Grid>
                    </Grid>
                  );
                })}
              </div>
              {Object.keys(fileDetails).length !== 0 && viewDocss ? (
                <>{updateFile()}</>
              ) : (
                <></>
              )}
            </>
          )}
        </Grid>
        {props?.errorValidation && props?.errorValidation?.error && (
          <div>
            <Typography variant="caption" className={classes.errorText}>
              {props?.errorValidation?.errorMessage}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};
