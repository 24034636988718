import * as React from "react";
import PropTypes from "prop-types";

const Close = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g
      id="Group_95387"
      data-name="Group 95387"
      transform="translate(-914 -123.5)"
    >
      <circle
        id="Ellipse_129930"
        data-name="Ellipse 129930"
        cx="12"
        cy="12"
        r="12"
        transform="translate(914 123.5)"
        fill={props.color}
        opacity="0.1"
      />
      <path
        id="icons8-multiply"
        d="M5.632,4.99a.642.642,0,0,0-.447,1.1l3.4,3.4-3.4,3.4a.642.642,0,1,0,.907.907l3.4-3.4,3.4,3.4a.642.642,0,1,0,.907-.907l-3.4-3.4,3.4-3.4a.642.642,0,1,0-.907-.907l-3.4,3.4-3.4-3.4a.642.642,0,0,0-.46-.194Z"
        transform="translate(916.51 126.01)"
        fill={props.color}
      />
    </g>
  </svg>
);

export default Close;

Close.defaultProps = {
  color: "#ff4d4a",
};

Close.propTypes = {
  color: PropTypes.string,
};
