import React, { useState } from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Grid,
  Button,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import drug from "../../assets/drug.png";
import { QuantityComp } from "../../screens/patientOrdering/quantity";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import Rating from "@mui/material/Rating";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";

export const ProductCard = (props) => {
  const { Quantity, addMinusQuantity, onHandleChnage, data, onClickAddToCart } =
    props;
  const [isFavorite, setIsFavorite] = useState(false);
  const [value, setValue] = useState(0);

  const handleFavoriteToggle = () => {
    setIsFavorite((prevIsFavorite) => !prevIsFavorite);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onAddBtnClicked = () => {
    onClickAddToCart(data.serialno);
  };

  return (
    <div>
      <Card
        style={{
          width: "300px",
          height: "450px",
          padding: "12px",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
          gap: "8px",
          // overflowX: "auto",
        }}
      >
        <CardMedia
          component="img"
          alt="drug"
          height="140"
          src={
            data.drugimage !== ""
              ? data.drugimage
              : "https://images.unsplash.com/photo-1587854692152-cbe660dbde88?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=869&q=80"
          }
          style={{
            borderRadius: "4px",
          }}
        />
        <CardContent
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "8px",
          }}
        >
          <Tooltip title={data.itemname}>
            <Typography
              variant="h6"
              component="div"
              style={{
                color: "#0C5DF9",
                fontFamily: "Poppins",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: 550,
                lineHeight: "normal",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "90%"
              }}
            >
              {data.itemname}
            </Typography>
          </Tooltip>
          <Typography
            variant="body2"
            style={{
              color: "#7D7D7D6",
              fontFamily: "Poppins",
            }}
          >
            {"Mfr By " +
              (data.manufacturer ? data.manufacturer : "--")}
          </Typography>
        </CardContent>
       
        <CardContent
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-evenly",
            gap: "28px",
          }}
        >
          <Typography
            variant="h5"
            color="text.secondary"
            style={{
              color: "#000",
              fontFamily: "Poppins",
              fontSize: "18px",
              fontStyle: "normal",
              fontWeight: 800,
              lineHeight: "normal",
            }}
          >
            {data.currency} {data.calculatedprice}
          </Typography>
          <Typography
            variant="subtitle2"
            color="text.secondary"
            style={{
              color: "#00AC26",
              fontFamily: "Poppins",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 550,
              lineHeight: "normal",
            }}
          >
            {data.discount !== 0 ? "Get " + data.discount + "% off" : ""}
          </Typography>
          <IconButton
            color="inherit"
            onClick={handleFavoriteToggle}
            style={{
              color: isFavorite ? "red" : "inherit",
              alignItems: "flex-end",
              justifyContent: "flex-end",
              marginLeft: "auto",
            }}
          >
            {isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
          </IconButton>
        </CardContent>
        <Grid
          style={{
            justifyContent: "flex-start",
            marginBottom: "14px",
            marginLeft: "10px",
          }}
        >
          <Rating
            name="customized-empty"
            value={value}
            precision={0.5}
            onChange={handleChange}
            emptyIcon={<StarBorderIcon fontSize="inherit" />}
            icon={<StarIcon fontSize="inherit" />}
          />
        </Grid>
        <CardContent
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
            padding: "4px",
          }}
        >
          <QuantityComp
            Quantity={Quantity}
            addMinusQuantity={addMinusQuantity}
            data={data}
            onHandleChnage={onHandleChnage}
          />
          <Button
            style={{
              display: "flex",
              width: "150px",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "4px",
              backgroundColor: "#0C5DF9",
              padding: "8px",
              borderRadius: "4px",
              color: "#fff",
              fontSize: "12px",
            }}
            onClick={onAddBtnClicked}
            endIcon={<ShoppingCartOutlinedIcon />}
          >
            Add to cart
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};
