import * as React from "react";
import PropTypes from "prop-types";
import { Routes } from "../router/routes";
import { useTheme } from "@material-ui/core";

const PrinterIcon = (props) => {
  const theme = useTheme();
  const color =
    window.location.pathname === Routes.printerQueue
      ? theme.sideBar.text.active
      : theme.sideBar.text.nonActive;
  return(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    width="20px"
    height="30px"
    viewBox="0 0 357.308 357.308"
    fill={color}
    // eslint-disable-next-line react/style-prop-object
  >
    <g>
      <g>
        <g>
          <path d="M342.307,99.757h-80.102V45.115c0-2.761-2.238-5-5-5H100.102c-2.762,0-5,2.239-5,5v54.642H15c-8.271,0-15,6.729-15,15     v123.517v27.466c0,8.271,6.728,15,15,15h80.102v31.453c0,2.761,2.238,5,5,5h157.104c2.762,0,5-2.239,5-5V280.74h80.102     c8.271,0,15-6.729,15-15v-27.466V114.757C357.307,106.486,350.578,99.757,342.307,99.757z M105.102,104.757V50.115h147.104     v54.642v67.43H105.102V104.757z M252.206,307.192L252.206,307.192H105.102v-26.453h147.104V307.192z M347.307,265.739     c0,2.757-2.243,5-5,5c-7.539,0-318.203,0-327.307,0c-2.757,0-5-2.243-5-5v-22.466h337.307V265.739z M347.308,233.274H10V114.757     c0-2.757,2.243-5,5-5h80.102v62.43h-5.948c-2.762,0-5,2.239-5,5s2.238,5,5,5c25.286,0,174.709,0,179,0c2.762,0,5-2.239,5-5     s-2.238-5-5-5h-5.948v-62.43h80.102c2.757,0,5,2.243,5,5V233.274z" />
          <path d="M321.327,154.365c-8.811,0-15.979,7.169-15.979,15.979c0,8.811,7.168,15.98,15.979,15.98     c8.812,0,15.98-7.169,15.98-15.98C337.307,161.534,330.139,154.365,321.327,154.365z M321.327,176.324     c-3.297,0-5.979-2.683-5.979-5.98s2.683-5.979,5.979-5.979c3.297,0,5.98,2.683,5.98,5.979     C327.307,173.642,324.624,176.324,321.327,176.324z" />
          <path d="M321.327,119.781c-8.811,0-15.979,7.169-15.979,15.979c0,8.811,7.168,15.98,15.979,15.98     c8.812,0,15.98-7.168,15.98-15.98C337.307,126.949,330.139,119.781,321.327,119.781z M321.327,141.74     c-3.297,0-5.979-2.683-5.979-5.98s2.683-5.979,5.979-5.979c3.297,0,5.98,2.683,5.98,5.979     C327.307,139.057,324.624,141.74,321.327,141.74z" />
        </g>
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>)
};

export default PrinterIcon;

PrinterIcon.defaultProps = {
  color: "black",
  secondaryColor: "#3ab012",
};

PrinterIcon.propTypes = {
  color: PropTypes.string,
  secondaryColor: PropTypes.string,
};
