import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import HomeParent from "./homeBody";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    background: theme.palette.background.paper,
  },
}));

const Home = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <HomeParent />
    </Box>
  );
};

export default Home;
