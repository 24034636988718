import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TableRow from "@material-ui/core/TableRow";

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    backgroundColor: theme.palette.background.table,
    
    "& .App1-MuiTableCell-root": {
      "&:first-child": {
        paddingLeft: 30,
        // backgroundColor: theme.palette.background.table
      },
    },
    "&:last-child": {
      "& .App1-MuiTableCell-root": {
        borderBottom: "0px",
        // backgroundColor: theme.palette.background.table
      },
    },
    
  }),
}));

function StyledTableRow(props) {
  const classes = useStyles(props);
  return <TableRow classes={classes} {...props} />;
}

export default StyledTableRow;
