import React from "react";
import {
  makeStyles,
  Divider,
  Typography,
  Grid,
  // Avatar,
  List,
  // ListItem,
  // ListItemAvatar,
  // ListItemText,
  Button,
} from "@material-ui/core";
import { InfoPanel } from "../infoDrawer";
import { KeyboardArrowLeft } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  header: {
    padding: "10px 20px",
  },
  listParent: {
    padding: theme.spacing(2),
  },
  listItem: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  notFound: {
    textAlign: "center",
  },
}));

export const CaseDefinition = ({ data = [] }) => {
  const classes = useStyles();

  const [state, setState] = React.useState({ selected: null });

  const onDiseaseClicked = (disease) => {
    setState({
      ...state,
      selected: disease,
    });
  };

  // let disease = data[0];

  React.useEffect(() => {
    let disease = data[0];
    onDiseaseClicked(disease);

    // eslint-disable-next-line
  }, [data]);

  const onBackBtnClicked = () => {
    setState({
      ...state,
      selected: null,
    });
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="stretch"
      >
        <Grid item>
          <Typography className={classes.header}>
            {`Case Definition`}
          </Typography>
          <Divider />
        </Grid>

        {!state.selected && (
          <Grid item className={classes.listParent}>
            <List component="nav">
              {/* {data?.map(disease => {
                            if (disease) {
                                return (
                                    <ListItem
                                        className={classes.listItem}
                                        button
                                        onClick={() => onDiseaseClicked(disease)}
                                    >
                                        <ListItemAvatar >
                                            <Avatar
                                                variant="circle"
                                                src={`/images/virus.svg`}
                                                alt={disease?.disease_name}
                                            >
                                                {disease?.disease_name?.[0] + disease?.disease_name?.[1]}
                                            </Avatar>
                                        </ListItemAvatar>

                                        <ListItemText
                                            primary={disease?.disease_name}
                                        />
                                    </ListItem>
                                )
                            }
                            return <></>
                        })} */}
              {data?.length === 0 && (
                <div>
                  <h4 className={classes.notFound}>
                    'Looks like there's no disease mapped to this form'.
                  </h4>
                </div>
              )}
            </List>
          </Grid>
        )}

        {state.selected && (
          <Grid item>
            <Button
              id="backButton"
              startIcon={<KeyboardArrowLeft />}
              onClick={onBackBtnClicked}
            >
              {"Back"}
            </Button>
          </Grid>
        )}

        {state.selected && (
          <Grid item>
            <InfoPanel
              data={state.selected}
              isReadOnly={true}
              hideHeader={true}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
};
