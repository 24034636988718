import React from "react";
import { LinearProgress } from "@material-ui/core";
import { NetworkCall } from "./networkcall";
import md5 from "md5";
import jwt_decode from "jwt-decode";
import {
  LocalStorageKeys,
  NetWorkCallMethods,
  refreshCacheAndReload,
  semverGreaterThan,
} from "./utils";
import { AxiosCall } from "./api";
import { Routes } from "./router/routes";
import Config from "./config";
class AppAuth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  async componentDidMount() {
    this.checkForLatestBuild();
    this.refreshAPI();
    const query = new URLSearchParams(window.location.search);
    const queryData = query.get("token");
    let getToken = queryData;
    if (getToken?.length !== 0 && getToken !== null) {
      try {
        let queryVal = atob(getToken);
        let keyclockId = jwt_decode(
          JSON.parse(queryVal)?.tokenDetails?.access_token
        );
        let id = keyclockId?.sub;
        let aTclk = JSON.parse(queryVal)?.tokenDetails?.access_token;
        let rTclk = JSON.parse(queryVal)?.tokenDetails?.refresh_token;
        localStorage.setItem("aTclk", aTclk);
        localStorage.setItem("rTclk", rTclk);
        localStorage.setItem("kclkid", id);
        await this.getNotificationTiming();
        let getRole = await this.getUserRole(id);
        if (getRole) {
          localStorage.setItem("RBAC", btoa(getRole));
          setTimeout(() => window.open(Routes.home, "_self"), 100);
        } else {
          localStorage.removeItem("aTclk");
          localStorage.removeItem("rTclk");
          localStorage.removeItem("kclkid");
          localStorage.removeItem("RBAC");
        }
      } catch (err) {
        setTimeout(() => window.location.replace("/"), 100);
      }
    }
  }
  generateCheckSum = async (rol) => {
    let CheckSum = md5(JSON.stringify(rol));
    delete rol.apikey;
    rol.CheckSum = CheckSum;
    fetch(process.env.REACT_APP_IDMJWTTokencreation, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(rol),
    })
      .then((res) => res.json())
      .then((result) => {
        localStorage.setItem("queue_Token", result.Result);
        sessionStorage.setItem("queue_Token", result.Result);
      })
      .catch((err) => console.log(err));
  };
  getUserRole = async (id) => {
    let param = {
      db_name: process.env.REACT_APP_DB,
      entity: "Person",
      filter: `Person.keycloackid=='${id}' && Person.activestatus==true`,
      return_fields:
        "{roleDetail:Person.roleid[0],_id:Person._id,facility:Person.orgType,OrgId:Person.OrgID[0].id}",
    };
    let role = await AxiosCall(
      "post",
      `${Config.api_url}/api/read_documents`,
      param
    );
    let chesumData = {
      facilityid: role?.result[0]?.roleDetail?.facility
        ? role?.result[0]?.roleDetail?.facility
        : "",
      roleid: role?.result[0]?.roleDetail?.roleid
        ? role?.result[0]?.roleDetail?.roleid
        : "",
      locationid: "",
      personid: role?.result[0]?._id ? role?.result[0]?._id : "",
      enterpriseid: "",
      orgid: role?.result[0]?.roleDetail?.OrgId
        ? role?.result[0]?.roleDetail?.OrgId
        : "",
      apikey: process.env.REACT_APP_APIKEY,
    };
    this.generateCheckSum(chesumData);

    let _ = role?.result[0]?.roleDetail?.roleid;

    return _;
  };
  refreshAPI = () => {};
  getNotificationTiming = async () => {
    let params = {
      db_name: Config.dbname,
      entity: "CodingMaster",
      filter: `CodingMaster._id=='${process.env.REACT_APP_NOTIFICATION_TRIGGER_TIME}' && CodingMaster.status==true && CodingMaster.activestatus==true`,
      return_fields: "CodingMaster",
    };
    let res = await AxiosCall(
      "post",
      `${Config.api_url}/api/read_documents`,
      params
    );
    let result = res?.result[0];
    let timing = result?.gmconfigvalues?.Timing;

    localStorage.setItem("TriggerTiming", timing);

    // return Number(timing);
  };
  checkForLatestBuild = () => {
    NetworkCall(
      `${window.location.protocol}//${window.location.hostname}${
        window.location.port ? ":" + window.location.port : ""
      }/meta.json`,
      NetWorkCallMethods.get,
      null,
      null,
      false,
      true
    )
      .then((_) => {
        const isVersion = semverGreaterThan(
          _.data.version,
          localStorage.getItem(LocalStorageKeys.version)
        );
        localStorage.setItem(LocalStorageKeys.version, _.data.version);
        if (isVersion) {
          refreshCacheAndReload();
        }
      })
      .catch((err) => {
        console.log("err:", err);
      });
  };

  render() {
    let { loading } = this.state;

    return <>{loading ? <LinearProgress /> : this.props.children}</>;
  }
}

export default AppAuth;
