import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { DrawerProps, LocalStorageKeys } from "../../../utils";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import Badge from "@material-ui/core/Badge";
import { TbLogout } from "react-icons/tb";
import { CgProfile } from "react-icons/cg";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import LocalMallOutlinedIcon from "@material-ui/icons/LocalMallOutlined";
import SettingsIcon from "@material-ui/icons/Settings";

import {
  List,
  ListItemIcon,
  ListItem,
  ListItemText,
  useTheme,
  useMediaQuery,
  Tooltip,
  Divider,
} from "@material-ui/core";
import Popover from "@material-ui/core/Popover";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { Link, useHistory } from "react-router-dom";
import { DrawerContext } from "../../../contexts";
import ListNotification from "./listNotification";
import Config from "../../../config";
import { AxiosCall } from "../../../api";
import { MdLockReset } from "react-icons/md";
import { useCallback } from "react";
import { Routes } from "../../../router/routes";
import { AiFillSetting } from "react-icons/ai";
import { useState } from "react";
import jwt_decode from "jwt-decode";
import axios from "axios";
import withAllContexts from  "../../../HOCs/withAllContexts";
// import { Changepw } from "../../../screens/passwordChange";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
    zIndex: theme.zIndex.drawer + 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: theme.palette.appBar.main,
    // backgroundColor:"#fff",
    // borderBottom: "solid",
    borderBottomStyle: "solid",
    borderBottomWidth: "1px",
    borderBottomColor: "black",
    // borderBottom: "ridge",
    // borderWidth: "14px",
    // borderBottom: "1px solid #e0e0e0",
  },
  title: {
    display: "block",

    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem !important",
    },
  },
  caption: {
    display: "block",
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.6rem !important",
    },
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
  },
  menuIcon: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  userProfilecard: {
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  profilePic: {
    marginLeft: "8px",
  },
  notificationdiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  notifications: {},
}));

 const TopNavBar = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const Theme = useTheme();
  const sm = useMediaQuery(Theme.breakpoints.down("sm"));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [notificationMsg, setNotificationMsg] = React.useState([]);
  const [user, setUser] = React.useState({});
  const [tktid, setTktid] = React.useState("");
  const drawerContext = React.useContext(DrawerContext);
  const id = open ? "simple-popover" : undefined;
  const [headPharmacy, setHeadPharmacy] = React.useState([]);
  const [isPharmacy, setIsPharmacy] = React.useState(false);
  const [pharmacy, setPharmacy] = React.useState({});
  const [isFromMyOrderOnClick, setIsFromMyOrderOnClick] = React.useState(true);
  // React.useEffect(() => {
  //   // let ticketId = localStorage.getItem("tktid")
  //   // console.log("tktlocal",ticketId)
  //   // setTktid(ticketId)
  //   notify();
  //   const interval = setInterval(
  //     () => notify(),
  //     Number(localStorage.getItem("TriggerTiming") ?? "300000")
  //   );
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);
  const handleClose = () => {
    setAnchorEl(null);
  };
  let contactname = localStorage.getItem("contactname");
  // console.log(contactname, "contactname")
  React.useLayoutEffect(() => {
    getPersonLoggedin(
      localStorage.getItem("kclkid"),
      atob(localStorage.getItem("RBAC"))
    );
  }, []);

  const getPersonLoggedin = async (id, role) => {
    let params = {
      db_name: Config.dbname,
      entity: process.env.REACT_APP_ENTITY_PERSON,
      filter: `${process.env.REACT_APP_ENTITY_PERSON}.keycloackid=='${id}' && ${process.env.REACT_APP_ENTITY_PERSON}.activestatus==true`,
      return_fields:
        role === "5"
          ? "{rolename:Person.roleid[0].rolename,person:MERGE(FOR Bed in Bed Filter Bed.BedNumber==document(Person.telecom[0]).value && Bed.activestatus==true RETURN MERGE(FOR PatientCheckIn in PatientCheckIn FILTER PatientCheckIn.BedCode==Bed._id && PatientCheckIn.activestatus==true  RETURN MERGE({text:FIRST(FOR Patient IN Patient FILTER Patient._id==PatientCheckIn.PatientCode && Patient.activestatus==true RETURN Patient.PatientFName)})))}"
          : "{rolename:Person.roleid[0].rolename,person:UNSET(document(Person.name[0]),'_key','_id','_rev','createddate','createdby','activestatus','updatedby','updatedate','family','id','given','prefix','use','person','period'),email:document(Person.telecom)}",
    };

    let res = await AxiosCall(
      "post",
      `${Config.api_url}/api/read_documents`,
      params
    );
    let result = res?.result?.[0];
    setUser(result);
    // console.log(result, "loginresult")
  };

  // console.log("user", user)

  const handleLogout = () => {
    localStorage.removeItem("aTclk");
    localStorage.removeItem("rTclk");
    localStorage.removeItem("kclkid");
    localStorage.removeItem("RBAC");
    sessionStorage.removeItem("user");
    localStorage.removeItem("user");
    localStorage.removeItem("TriggerTiming");
    localStorage.removeItem("access_token");
    // localStorage.removeItem("RoleId")
    localStorage.removeItem("contactname");
    history.push("/");
  };

  const notify = async () => {
    let myHeaders = new Headers();
    myHeaders.append("content-type", "application/json");
    fetch(`${Config.api_url}/api/read_documents`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        db_name: Config.dbname,
        entity: process.env.REACT_APP_notify_ENTITY,
        filter: `${process.env.REACT_APP_notify_ENTITY}.activestatus==true`,
        return_fields:
          "keep(Notification,'id','_id','Notification','Notification_type','Notification_count','Patient_id','Mealorderid','createddate')",
        sort: "Notification.createddate desc",
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        let Res = [...result.result];
        //   result.result.forEach((e)=>{
        //     Res.push({
        //       image :'' ,
        //       message : e.Notification,
        //       // detailPage : "",
        //       receivedTime:'12h ago',
        //       Notification_count:e.Notification_count,
        //       _id:e._id
        //     })
        //     setNotificationMsg(Res)
        // console.log(Res);
        //   })
        // console.log(Res);
        setNotificationMsg(Res);
      })
      .catch((err) => console.log(err));
  };

  const onNotifyclick = (data) => {
    drawerContext.setDrawer({
      ...drawerContext,
      open: true,
      direction: DrawerProps.direction.right,
      variant: DrawerProps.variant.temporary,
      component: <ListNotification data={data} countnotify={notify} />,
      onClose: () => onDrawerClosed(data),
    });
  };
  const onDrawerClosed = async (data) => {
    drawerContext.setDrawer({
      ...drawerContext,
      open: false,
      direction: DrawerProps.direction.right,
    });
  };

  const handleChangepw = () => history.push(Routes.Changepassword);

  const [pop, setPop] = useState(null);

  const handleSetting = (event) => {
    setPop(event.currentTarget);
  };
  const handleSettingclose = () => {
    setPop(null);
  };

  const Popopen = Boolean(pop);
  const ID = open ? "simple-popover" : undefined;

  let hide = localStorage.getItem("length");
  let len = hide == "true" ? true : false;
  // console.log("condition length", len)

  //
  // const handleOrder = () =>
  //   history.push(Routes.myorders);
  const handleOrder = () => {
    history.push({
      pathname: Routes.myorders,
      state: { isFromMyOrderOnClick: isFromMyOrderOnClick },
    });
  };
  const handleCart = () =>
    history.push(Routes.menu);

  const handleProfile = () =>
    history.push({ pathname: Routes.Profile, state: tktid, state1: user });

  React.useEffect(async()=>{
    let cartParams = {
      db_name: process.env.REACT_APP_DB,
      filter: pharmacy,
      queryid: process.env.REACT_APP_GETCARTLIST
    };
    let resCartList = await AxiosCall(
      "post",
      process.env.REACT_APP_ARANGO_URL_READ_QDMQUERIES,
      cartParams
    );
    props.cartCount.setCartCount(resCartList.length);
  }, [pharmacy]);

  React.useEffect(async() => {
    let token = localStorage.getItem("aTclk");
    let Email = jwt_decode(token);

    let params = {
      db_name: process.env.REACT_APP_DB,
      filter: {
        email: `${Email?.email}`,
      },
      queryid: process.env.REACT_APP_BED_QUERYID,
    };
    let res = await AxiosCall(
      "post",
      process.env.REACT_APP_ARANGO_URL_READ_QDMQUERIES,
      params
    );
    res.map((v, index) => {
      if(v?.status === "Active"){
        setIsPharmacy(true);
      }
    });
    let activeRes = res.find(x=> x.activestatus === true);
    setPharmacy({
      pharmacyid: activeRes?._id,
      personid: activeRes?.Person[0]?._id
    });
    var config1 = {
      method: "POST",
      url: process.env.REACT_APP_ARANGO_URL_READ_QDMQUERIES,
      headers: { "Content-Type": "application/json" },
      data: {
        db_name: process.env.REACT_APP_DB,
        filter: {
          email: `${Email?.email}`,
        },
        queryid: process.env.REACT_APP_STATUS_QUERYID,
      },
    };
        axios(config1)
          .then((res1) => {
            
            let nullRemoverForParent =
              res1?.data?.filter((v) => v !== null) ?? [];
            if (res1?.data) {
              setHeadPharmacy(res1?.data);
            }
          });
      
  }, []);
  return (
    <div className={classes.grow}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <div className={classes.titleContainer}>
            {sm ? (
              <img
                alt="icon"
                src="images/pintar2u.webp"
                style={{ width: "45px", height: "30px" }}
              />
            ) : (
              <img
                alt="icon"
                src="images/pintar2u.webp"
                style={{ width: "65px", height: "50px" }}
              />
            )}
            <div>
              <Typography
                className={classes.title}
                style={{ color: "black" }}
                variant="body1"
                noWrap
              >
                {"PINTAR2U"}
              </Typography>
              <Typography
                variant="caption"
                style={{ color: "black" }}
                className={classes.caption}
              >
                {`v ${localStorage.getItem(LocalStorageKeys.version)}`}
              </Typography>
            </div>
          </div>

          <div className={classes.grow} />
          <div className={classes.notificationdiv}>
            {atob(localStorage.getItem("RBAC")) === "4" && (
              <IconButton
                onClick={() => onNotifyclick(notificationMsg)}
                style={{ color: "black" }}
              >
                <Badge
                  badgeContent={
                    notificationMsg.filter((l) => l?.Notification_count === 0)
                      .length
                  }
                  color="secondary"
                >
                  <NotificationsNoneOutlinedIcon />
                </Badge>
              </IconButton>
            )}
          </div>

          <div className={classes.userProfilecard}>
            <div>
              {/* {contactname ?
                <Typography
                  className={classes.title}
                  variant="body1"
                  component={"h6"}
                  noWrap
                  style={{ color: "black" }}
                >
                  {contactname}
                </Typography> : */}
              <Typography
                className={classes.title}
                variant="body1"
                component={"h6"}
                noWrap
                style={{ color: "black" }}
              >
                {user?.person?.text?.trim().length > 0
                  ? user?.person?.text
                  : user?.rolename}
              </Typography>
              {/* } */}
              {user?.person?.text?.trim().length > 0 && (
                <Typography
                  // className={classes.title}
                  variant="caption"
                  noWrap
                  style={{ color: "black" }}
                >
                  {user?.rolename}
                </Typography>
              )}
            </div>
            {/* <Avatar className={classes.profilePic}>
                {atob(sessionStorage.getItem("user")).charAt(0).toUpperCase()}
              </Avatar> */}
          </div>
          <Divider
            flexItem
            orientation="vertical"
            style={{ margin: "16px 4px", width: "2px", background: "black" }}
          />
          {(!isPharmacy) ? null : (
            <div>
              <Tooltip title="My Order" arrow>
                <IconButton style={{ color: "black" }} 
                      // onClick={handleOrder(true)}
                      onClick={() => handleOrder(true)}

                      >
                  <LocalMallOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="My Cart" arrow>
                <IconButton style={{ color: "black" }} 
                      onClick={handleCart}>
                  <Badge badgeContent={props?.cartCount?.cartCount} showZero color="error">
                    <ShoppingCartOutlinedIcon />
                  </Badge>
                </IconButton>
              </Tooltip>
            </div>
          )}

          <IconButton onClick={handleSetting} style={{ color: "black" }}>
            <AiFillSetting />
          </IconButton>
          <Popover
            id={ID}
            open={Popopen}
            anchorEl={pop}
            onClose={handleSettingclose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ cursor: "default" }}
          >
            <List>
              {len == true ? (
                <></>
              ) : (
                <>
                  <ListItem
                    style={{ height: "2.5rem" }}
                    onClick={handleProfile}
                  >
                    <IconButton
                      style={{ color: "black" }}
                      onClick={handleProfile}
                    >
                      <CgProfile />
                    </IconButton>
                    Profile
                  </ListItem>
                  <ListItem
                    onClick={handleChangepw}
                    style={{ height: "2.5rem" }}
                  >
                    <IconButton
                      onClick={handleChangepw}
                      style={{ color: "black" }}
                    >
                      <MdLockReset />
                    </IconButton>
                    Change Password
                  </ListItem>
                </>
              )}
              <ListItem
                onClick={() => handleLogout()}
                style={{ height: "2.5rem" }}
              >
                {sm ? (
                  <IconButton
                    style={{ color: "black" }}
                    onClick={() => handleLogout()}
                  >
                    <TbLogout />
                  </IconButton>
                ) : (
                  <Tooltip title="Logout" arrow>
                    <IconButton
                      style={{ color: "black" }}
                      onClick={() => handleLogout()}
                    >
                      <TbLogout />
                    </IconButton>
                  </Tooltip>
                )}{" "}
                Logout
              </ListItem>
            </List>
          </Popover>

          {/* <Divider
            flexItem
            orientation="vertical"
            style={{ margin: "16px 4px", width: "2px", background: "black" }}
          /> */}
        </Toolbar>
      </AppBar>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <List>
          <ListItem Button onClick={() => handleLogout()}>
            <ListItemIcon style={{ width: "30px" }}>
              <ExitToAppIcon />
            </ListItemIcon>
            {!sm && <ListItemText primary="Logout" />}
          </ListItem>
        </List>
      </Popover>
    </div>
  );
};

export default withAllContexts(TopNavBar);