import React from "react";
import PropTypes from "prop-types";
import { alpha, makeStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import Typography from "@material-ui/core/Typography";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { TbBuildingHospital } from "react-icons/tb";
import { GiHospital } from "react-icons/gi";
import { RiHospitalFill } from "react-icons/ri";
import ApartmentIcon from "@material-ui/icons/Apartment";
import { FaClinicMedical } from "react-icons/fa";
const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme?.palette?.text?.primary,
    "&:hover > $content": {
      color: "var(--tree-view-color)",
      backgroundColor: "#fff",
    },
    "&:focus > $content, &$selected > $content": {
      color: "var(--tree-view-color)",
      backgroundColor: `var(--tree-view-bg-color)`,
    },
    "&:focus > $content $label, &:hover > $content $label, &$selected > $content $label":
    {
      color: "var(--tree-view-color)",
    },
    "&$selected > $content $label": {
      color: "var(--tree-view-color)",
      backgroundColor: `var(--tree-view-bg-color)`,
    },
  },
  content: {
    color: theme?.palette?.text?.primary,
    borderTopRightRadius: theme?.spacing(2),
    borderBottomRightRadius: theme?.spacing(2),
    paddingRight: theme?.spacing(1),
    fontWeight: theme?.typography?.fontWeightMedium,
    "$expanded > &": {
      fontWeight: theme?.typography?.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 7,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme?.palette?.text?.primary, 0.4)}`,
  },
  label: {
    fontWeight: "inherit",
    color: "inherit",
  },
  labelRoot: {
    display: "flex",
    alignItems: "center",
    padding: theme?.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme?.spacing(1),
  },
  labelText: {
    fontWeight: "inherit",
    flexGrow: 1,
    textAlign: "left",
    fontSize: 13,
  },
  selected: {
    color: "var(--tree-view-color)",
  },
}));

const StyledTreeItem = (props) => {
  const classes = useTreeItemStyles();
  const {
    type,
    labelText,
    labelIcon: LabelIcon,
    labelInfo,
    color,
    bgColor,
    ...other
  } = props;
  
  return (
    
    <TreeItem
      id={props.id}
      label={
        <div id={props.id} className={classes.labelRoot}>
          {![null , undefined , ""].includes(type) && 

           <LabelIcon
            id={props.id}
            color="inherit"
            className={classes.labelIcon}
          /> 
        }
          <Typography
            id={props.id}
            variant="body2"
            className={classes.labelText}
          >
            {labelText}
          </Typography>
          <Typography id={props.id} variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </div>
      }
      style={{
        "--tree-view-color": color,
        "--tree-view-bg-color": bgColor,
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
};

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: 264,
    flexGrow: 1,
    maxWidth: 400,
  },
  checkbox: {
    "& .App1-MuiButtonBase-root": {
      "& .App1-MuiSvgIcon-root": {
        width: 15,
        height: 15,
      },
    },
    "& span": {
      fontSize: 14,
    },
  },
  addbtn: {
    fontSize: 13,
  },
  main: {
    position: "relative",
    background: theme?.palette?.background?.table,
    //boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)"
  },
  left_arrow: {
    padding: 6,
    backgroundColor: "white",
    position: "absolute",
    right: "-26px",
    "& svg": {
      fontSize: 16,
      paddingLeft: 4,
    },
    boxShadow: "0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
  },
}));

const TreeViewStuct = (props) => {
  const classes = useStyles();
  const { parent_id, disableAddNew, workflow, treedata } = props;
  const [state, setState] = React.useState(false);
  const [expanded, setExpanded] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  const [allkeys, setallKeys] = React.useState([]);
  const [data, setdata] = React.useState([]);

  const getTreeItemsFromlevelData = (treeItems) => {
    return treeItems?.map((treeItemData) => {
      
      let children = undefined;
      if (treeItemData?.children && treeItemData?.children.length > 0) {
        children = getTreeItemsFromlevelData(treeItemData?.children ?? []);
      }
      allkeys.push(treeItemData?.str_id);
      return (
        <StyledTreeItem
          // id={`${parent_id}-StyledTreeItem`}
          children={children}
          nodeId={treeItemData?.str_id}
          labelText={treeItemData?.str_name}
          id={treeItemData?.str_id}
          key={`${treeItemData?.id}`}
          data={treeItemData}
          type={treeItemData?.type}
          labelIcon={
            treeItemData?.type == "Pharmacy"
              ? ApartmentIcon
              : treeItemData?.type == "Clinic"
              ? TbBuildingHospital
              : treeItemData?.type == "GroupParentEty"
              ? GiHospital
              : treeItemData?.type == "IndividualEty"
              ? RiHospitalFill
              : ApartmentIcon
          }
          labelInfo={treeItemData?.ticketId}
          color="#1a73e8"
          bgColor="#e8f0fe"
        />
      );
    });
  };
  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };
  const handleExpandClick = (event) => {
    setState(event.target.checked);
    event.target.checked ? setExpanded(allkeys) : setExpanded([]);
  };
  const handleSelect = (event, nodeId, data) => {
    setSelected(nodeId);
    props.onCardClicked(nodeId[0]);
    
  };
  return (
    <div
      style={{ marginLeft: "20px", marginTop: "10px" }}
      id={`${parent_id}-expand-div`}
      className={classes.main}
    >
      <Grid
        id={`${parent_id}-expand-grid`}
        container
        spacing={3}
        style={{ paddingBottom: 8 }}
      >
        <Grid
          id={`${parent_id}-expand-sub-grid`}
          item
          xs={6}
          style={{ textAlign: "left" }}
        >
          <FormControlLabel
            id={`${parent_id}-expand-FormControlLabel`}
            style={{ fontSize: 8 }}
            className={classes.checkbox}
            control={
              <Checkbox
                id={`${parent_id}-expand-Checkbox`}
                checked={state}
                onChange={handleExpandClick}
                name="checked"
                color="primary"
                style={{ fontSize: 8 }}
              />
            }
            label="Expand all"
          />
        </Grid>
      </Grid>
      <div
        id={`${parent_id}-TreeView-div`}
        style={{ height: "calc(100vh - 180px)" }}
      >
        <TreeView
          id={`${parent_id}-TreeView`}
          expanded={expanded}
          selected={selected}
          className={classes.root}
          defaultCollapseIcon={<ArrowDropDownIcon />}
          //defaultExpandIcon={<ArrowRightIcon />}
          defaultEndIcon={<div style={{ width: 24 }} />}
          onNodeToggle={handleToggle}
          onNodeSelect={handleSelect}
          multiSelect
        >
          {getTreeItemsFromlevelData(treedata ?? [])}
        </TreeView>
      </div>
      <div id={`${parent_id}-div`} style={{ paddingBottom: "3%" }} />
    </div>
  );
};
export default TreeViewStuct;
