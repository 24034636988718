import React, { useState } from "react";
import {
  makeStyles,
  Paper,
  TablePagination,
  IconButton,
  Typography,
  InputBase,
  Collapse,
  Box,
  CircularProgress,
  Popover,
  ListItem,
  List,
  ListItemText,
} from "@material-ui/core";
import { useContext } from "react";
import { AlertContext, BackdropContext } from "../../contexts";
import Addnewdrugprice from "./addnewdrugprice";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { actions } from "central_pharma_binder";
import { useDispatch } from "react-redux";
import { AxiosCall } from "../../utils";
import { useSelector } from "react-redux";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import SearchIcon from "@material-ui/icons/Search";
import History from "../../assets/History.svg";
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Config from "../../config";
import { useHistory } from "react-router-dom";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from "@material-ui/icons/Delete";
const useStyles = makeStyles((theme) => ({
  paper: {
    // borderRadius: "15px",
    // padding: "0px 30px 20px 30px",
    // position: "relative",
    fontSize: "10px",
    maxHeight: "50dvh",
    borderRadius: "0px",
    padding: "0px 20px",
    boxShadow: "none",
  },
  tablecell: {
    maxWidth: "400px",
  },
  divsearch: {
    borderRadius: "50px",
    position: "absolute",
    zIndex: "10",
    backgroundColor: "#EFF7FF",
    display: "inline-flex",
    alignItems: "center",
    padding: "2px 5px",
    gap: "3px",
    top: "15px",
    width: "300px",
    fontSize: "10px",
  },
  icon: {
    color: theme?.palette?.primary?.main,
  },
  search: {
    fontSize: "10px",
    width: "100%",
  },
  tablepage: {
    fontSize: "10px",
    "& .MuiTypography-body2": {
      fontSize: "10px",
    },
  },
  rightarrow: {
    transform: "rotate(270deg)",
    color: "black",
  },
  downarrow: {
    color: "black",
  },
  toppaper: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    borderRadius: "0px",
    gridRow: "1/2",
  },
  table: {
    // "& .MuiTableCell-stickyHeader": {
    //     zIndex: "0"
    // }
  },
  rowbottom: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  bottompaper: {
    borderRadius: "0px",
  },
  topdiv: {
    display: "grid",
    gridTemplateRows: "0.1fr 0.9fr",
    gridTemplateColumn: "1fr",
    height: "100%",
  },
  popover: {
    pointerEvents: "none",
  },
}));
var bounce;
function debounce(func, actions) {
  clearInterval(bounce);
  bounce = setTimeout(() => {
    return func(actions);
  }, 500);
}

export function calculatedtax(price = 0, discount = 0) {
  let computeddiscount = ((discount * price) / 100).toFixed(2);
  let result = price - computeddiscount;
  if (result === 0) {
    result = "-";
  }
  return result;
}

const getTime = (epoche) => {
  var myDate = new Date(Number(epoche) * 1000);
  return epoche
    ? `${myDate.getDate()}/${myDate.getMonth() + 1}/${myDate.getFullYear()}`
    : "-";
};

function Collapseintable(props) {
  const { row } = props;
  const classes = useStyles();
  const alert = useContext(AlertContext);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const dispatch = useDispatch();
  const [openfor_add_drugprice, setOpenfor_add_drugprice] =
    React.useState(false);
  const [openfor_History, setOpenfor_History] = React.useState(false);

  const handleToggle = async (data) => {
    //  ;
    // console.log("datas", data);
    let last_version_effective_from =
      data?.History.at(data?.History?.length - 1)?.effectivefrom * 1000;
    let last_version_effective_to =
      data?.History.at(data?.History?.length - 1)?.effectiveto * 1000;

    let todaysdate = Date.now();

    // // let first_version_effective_from = data?.History.at((data?.History?.length - 2))?.effectivefrom * 1000;
    // // let first_version_effective_to = data?.History.at((data?.History?.length - 2))?.effectiveto * 1000;

    // // let history_length_onlyOne_above_todays_date = false
    // // if (data?.History?.length === 1 && (last_version_effective_from > todaysdate)) {
    // //   history_length_onlyOne_above_todays_date = true
    // // }

    // // let is_not_inbetween_todays_date = !(((todaysdate > first_version_effective_from)) && (todaysdate < last_version_effective_from))

    // // let above_todays_date_for_both_effective_from = last_version_effective_from < todaysdate && first_version_effective_from < todaysdate

    // // let below_todays_date_for_both_effective_from = last_version_effective_from > todaysdate && first_version_effective_from > todaysdate

    // if (history_length_onlyOne_above_todays_date) {
    //   above_todays_date_for_both_effective_from = true
    //   below_todays_date_for_both_effective_from = true
    // }

    if (todaysdate < last_version_effective_from) {
      let toggledata = {
        price_id: data?.Pricetable?._id,
        drug_id: data?.Pricetable?.drugid,
        version: data?.Pricetable?.version,
      };
      let result = await AxiosCall("post", Config.editdrugprice, toggledata);
      if (result?.response?.Code === 201) {
        alert.setSnack({
          ...alert,
          open: true,
          severity: "success",
          msg: "deleted successfully",
        });
      } else {
        alert.setSnack({
          ...alert,
          open: true,
          severity: "error",
          msg: "something went wrong !",
        });
      }
      let payload = {
        value: props?.serachValue,
        offset: 0,
        count: rowsPerPage * (page + 1),
      };
      await dispatch(actions.GET_SEARCH_DRUG_PRICE(payload));
    } else {
      alert.setSnack({
        ...alert,
        open: true,
        severity: "warning",
        msg: "can't delete the active or past drug price",
      });
    }
  };

  const handleHistory = async (value) => {
    //  ;
    // backdrop.setBackDrop({
    //     ...backdrop,
    //     open: true,
    //     message: "Fetching history",
    // });
    // const data = await dispatch(actions.GET_DRUG_HISTORY(value?._id));
    // if (data?.payload?.data?.length === 0) {
    //     alert.setSnack({
    //         ...alert,
    //         open: true,
    //         severity: "warning",
    //         msg: "There is no history of records",
    //     });
    // }
    // setHistorydata(data?.payload?.data ?? []);
    setOpenfor_History(!openfor_History);
    setOpenfor_add_drugprice(false);

    // backdrop.setBackDrop({ ...backdrop, open: false, message: "" });
  };
  return (
    <React.Fragment key={row?._id}>
      <TableRow key={row?._id}>
        <TableCell scope="row" className={classes.tablecell}>
          {openfor_add_drugprice && (
            <IconButton
              onClick={() => {
                setOpenfor_add_drugprice(false);
              }}
              size="small"
            >
              <RemoveCircleIcon className={classes.icon} />
            </IconButton>
          )}
          {!openfor_add_drugprice && (
            <IconButton
              onClick={() => {
                setOpenfor_add_drugprice(true);
                setOpenfor_History(false);
              }}
              size="small"
            >
              {" "}
              <AddCircleOutlinedIcon className={classes.icon} />
            </IconButton>
          )}
        </TableCell>
        <TableCell scope="row" className={classes.tablecell}>
          <Typography variant="caption"> {row?.itemname}</Typography>
        </TableCell>
        <TableCell align="center">
          {" "}
          <Typography variant="caption">
            {getTime(row?.Pricetable?.effectivefrom)}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="caption">
            {row?.Pricetable?.price ?? "-"}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="caption">
            {row?.Pricetable?.discount
              ? `${row?.Pricetable?.discount} %`
              : "0 %"}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="caption">
            {calculatedtax(row?.Pricetable?.price, row?.Pricetable?.discount)}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <IconButton onClick={() => handleToggle(row)} size="small">
            <DeleteIcon color="error" />
          </IconButton>
        </TableCell>
        <TableCell align="center">
          {row?.History?.length > 0 && (
            <IconButton
              size="small"
              onClick={() => {
                setOpenfor_History(!openfor_History);
                setOpenfor_add_drugprice(false);
              }}
            >
              {openfor_History ? (
                <ExpandMoreIcon
                  fontSize="small"
                  className={classes.downarrow}
                />
              ) : (
                <ExpandMoreIcon
                  fontSize="small"
                  className={classes.rightarrow}
                />
              )}
            </IconButton>
          )}
        </TableCell>
      </TableRow>{" "}
      <TableRow>
        {/* <Collapse in={open} timeout="auto" unmountOnExit> */}
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={openfor_add_drugprice} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography
                variant="caption"
                gutterBottom
                component="div"
                style={{ marginLeft: "20px" }}
              >
                Add Drug Price
              </Typography>
              <Table size="small" aria-label="purchases">
                {/* <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="caption">Effective From</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="caption">Drug Price</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="caption">Discount</Typography>
                                        </TableCell>{" "}
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead> */}
                <TableBody>
                  <TableRow className={classes.rowbottom}>
                    <Addnewdrugprice
                      serachValue={props?.serachValue}
                      data={row}
                      setOpenfor_add_drugprice={setOpenfor_add_drugprice}
                    />
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
          {row?.History?.length > 0 && (
            <Collapse in={openfor_History} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="caption" gutterBottom component="div">
                  Drug Price History
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      {/* <TableCell align="center">
                                            <Typography color="primary" variant="caption">
                                                Drug Name
                                            </Typography>
                                        </TableCell> */}
                      <TableCell align="center">
                        <Typography color="primary" variant="caption">
                          Effective Dates
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography color="primary" variant="caption">
                          Price
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography color="primary" variant="caption">
                          Discount
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography color="primary" variant="caption">
                          Calculated Price
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography color="primary" variant="caption">
                          Version
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row?.History?.map((v, i) => {
                      return (
                        <TableRow
                          key={v?._id ?? `history${i}`}
                          className={classes.rowbottom}
                        >
                          <TableCell></TableCell>
                          <TableCell align="center">
                            <img
                              src={History}
                              alt="history"
                              style={{ width: "20px" }}
                            />
                          </TableCell>
                          <TableCell align="center">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                                justifyContent: "center",
                              }}
                            >
                              {/* <img
                                                                src={History}
                                                                alt="history"
                                                                style={{ width: "20px" }}
                                                            /> */}
                              <Typography variant="caption">
                                {getTime(v?.effectivefrom)} -{" "}
                                {v?.effectiveto === 0
                                  ? "∞"
                                  : getTime(v?.effectiveto)}
                              </Typography>
                            </div>
                          </TableCell>
                          <TableCell align="center">
                            <Typography variant="caption">
                              {v?.price ?? "-"}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography variant="caption">
                              {v?.discount ? `${v?.discount} %` : "0%"}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography variant="caption">
                              {calculatedtax(v?.price, v?.discount)}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography variant="caption">
                              Version-{v?.version}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          )}
        </TableCell>

        {/* </Collapse> */}
      </TableRow>
    </React.Fragment>
  );
}
function PriceHistory() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [serachValue, setSearchValue] = React.useState("");
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const selector = useSelector((state) => {
    return {
      getalldrugprice: state?.DrugPriceslice?.getalldrugprice,
      getsearcheddrug: state?.DrugPriceslice?.getsearcheddrug,
    };
  });
  // console.log({ selector });
  React.useEffect(() => {
    //  dispatch(actions.GET_ALL_DRUG_PRICE());
    let payload = {
      value: serachValue,
      offset: 0,
      count: rowsPerPage * (page + 1),
    };
    dispatch(actions.GET_SEARCH_DRUG_PRICE(payload));
  }, []);
  const handleChangePage = (event, newPage) => {
    let payload = {
      value: serachValue,
      offset: 0,
      count: rowsPerPage * (newPage + 1),
    };
    dispatch(actions.GET_SEARCH_DRUG_PRICE(payload));
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    let row = +event.target.value;
    setRowsPerPage(row);
    setPage(0);
    let payload = {
      value: serachValue,
      offset: 0,
      count: row * (page + 1),
    };
    dispatch(actions.GET_SEARCH_DRUG_PRICE(payload));
  };

  const handlesearch = (e) => {
    let value = e.target.value;
    let payload = { value, offset: 0, count: rowsPerPage * (page + 1) };
    debounce(dispatch, actions.GET_SEARCH_DRUG_PRICE(payload));
    setSearchValue(value);
  };
  // console.log("jo", selector?.getsearcheddrug?.data?.length);
  return (
    <div className={classes.topdiv}>
      <Paper className={classes.toppaper} style={{}}>
        {/* <IconButton
          onClick={() => {
            history.goBack();
          }}
        >
          <ArrowBackIcon color="primary" fontSize="small" />
        </IconButton> */}
        <Typography style={{ marginLeft: "25px" }}>Price Management</Typography>
      </Paper>
      <div
        style={{
          padding: "20px",
          borderRadius: "15px",
          gridRow: "2/3",
        }}
      >
        <Paper
          elevation={0}
          style={{
            borderRadius: "15px",
            height: "100%",
          }}
        >
          <Paper
            elevation={0}
            style={{
              position: "relative",
              padding: "5px 20px",
              borderRadius: "15px 15px 0px 0px",
            }}
          >
            <div className={classes.divsearch}>
              <SearchIcon className={classes.icon} fontSize="small" />
              <InputBase
                value={serachValue}
                onChange={handlesearch}
                variant={"outlined"}
                size="small"
                placeholder="search"
                className={classes?.search}
              />
            </div>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component="div"
              count={selector?.getsearcheddrug?.data?.[0]?.total_number ?? 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              className={classes?.tablepage}
            />
          </Paper>
          <TableContainer component={Paper} className={classes.paper}>
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell align="center">
                    <Typography color="primary" variant="caption">
                      Drug Name
                    </Typography>
                    {/* <IconButton size="small" onClick={handleClick} onMouseEnter={handlePopoverOpen}
                                        onMouseLeave={handlePopoverClose}>
                                        <MoreVertIcon fontSize="small" />
                                    </IconButton> */}
                  </TableCell>
                  <TableCell align="center">
                    {" "}
                    <Typography color="primary" variant="caption">
                      Effective From
                    </Typography>
                    {/* <IconButton size="small" onClick={handleClick}>
                                        <MoreVertIcon fontSize="small" />
                                    </IconButton> */}
                  </TableCell>
                  <TableCell align="center">
                    <Typography color="primary" variant="caption">
                      Price
                    </Typography>
                    {/* <IconButton size="small" onClick={handleClick}>
                                        <MoreVertIcon fontSize="small" />
                                    </IconButton> */}
                  </TableCell>
                  <TableCell align="center">
                    <Typography color="primary" variant="caption">
                      Discount
                    </Typography>
                    {/* <IconButton size="small" onClick={handleClick}>
                                        <MoreVertIcon fontSize="small" />
                                    </IconButton> */}
                  </TableCell>
                  <TableCell align="center">
                    <Typography color="primary" variant="caption">
                      Calculated Price
                    </Typography>
                    {/* <IconButton size="small" onClick={handleClick}>
                                        <MoreVertIcon fontSize="small" />
                                    </IconButton> */}
                  </TableCell>
                  <TableCell align="center">
                    <Typography color="primary" variant="caption">
                      Action
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography color="primary" variant="caption">
                      History
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selector?.getsearcheddrug?.loading === true ? (
                  <TableRow className={classes.rowbottom}>
                    <TableCell colSpan={6} align="center">
                      {" "}
                      <CircularProgress />
                    </TableCell>{" "}
                  </TableRow>
                ) : selector?.getsearcheddrug?.data?.length !== 0 ? (
                  selector?.getsearcheddrug?.data
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((row) => (
                      <Collapseintable
                        key={`${row._id}collapse`}
                        row={row}
                        serachValue={serachValue}
                      />
                    ))
                ) : (
                  <TableRow className={classes.rowbottom}>
                    <TableCell colSpan={6} align="center">
                      <Typography>No data available</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Paper elevation={0} className={classes.bottompaper}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component="div"
              count={selector?.getsearcheddrug?.data?.[0]?.total_number ?? 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              className={classes?.tablepage}
            />
          </Paper>
        </Paper>
      </div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        disableRestoreFocus
        className={classes.popover}
      >
        <List>
          <ListItem>
            <ListItemText>Filter</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>Sort</ListItemText>
          </ListItem>
        </List>
      </Popover>
    </div>
  );
}

export default PriceHistory;
