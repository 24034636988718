import React from "react";

import { useDispatch } from "react-redux";
import { actions } from "central_pharma_binder";
import { WEEKDAYS } from "../../utils";
import OrderCatalog from "./orderCatalog";
import Config from "../../config";
import { AxiosCall } from "../../api";
import { steps } from "../../Steps";
import AppTour from "../../components/appTour";

const PatientOrdering = (props) => {
  const dispatch = useDispatch();
  const { fromKitchen = false, selectedPatient, backToSearch, PatientDetails } = props;
  const [user, setUser] = React.useState({});
  const [openTour, setOpentour] = React.useState(false);
  const handleTour = () => setOpentour(true);

  const [date, setDate] = React.useState(new Date());

  const [Count, setCount] = React.useState();
  const [countLimit, setcountLimit] = React.useState();
  React.useLayoutEffect(() => {
    getMealSchedule();
    //eslint-disable-next-line
  }, []);

  const getMealSchedule = async (selectedDate) => {
    let dateNew = selectedDate ? selectedDate : new Date();
    setDate(dateNew);

    let id = localStorage.getItem("kclkid");
    let user = fromKitchen ? selectedPatient : await getPatientLoggedIn(id);

    setUser({
      PatientCode: user?._id ?? "",
      // PatientBedCode: "Bed/10362",
      PatientBedCode: user?.orgType,
      // PatientCategory: fromKitchen
      //   ? user?.Patient?.PatientCategory
      //   : user?.Patient?.PatientCategory?._id ?? "",
      // PatientCategoryDisplay: user?.Patient?.PatientCategory?.display ?? "",
      // Disclaimer:
      //   user?.Patient?.PatientCategory?.gmconfigvalues?.Disclaimer ?? null,
    });

    // let getDay = dateNew.getDay();

    // let ConceptMaster = "DAY";

    // let day = WEEKDAYS[getDay];

    // let generalList = await dispatch(
    //   actions.IMPO_GENERAL_MASTER(ConceptMaster)
    // );

    // let _MasterDay = generalList?.payload?.data;

    // let findDayCode = _MasterDay.find((l) => l.coding?.display === day?.value);

    // let mealDay = findDayCode?.coding?._id;

    // await dispatch(
    //   actions.GET_PATIENT_MEAL({
    //     key: mealDay,
    //     by: fromKitchen
    //       ? user?.Patient?.PatientCategory
    //       : user?.Patient?.PatientCategory?._id,
    //     date: dateNew,
    //   })
    // );
    getCountLimit();
    if (!fromKitchen && user?.OrgID) {
      increaseLoginCount(user?.BedCode);
    }
  };

  const getCountLimit = async () => {
    let params = {
      db_name: Config.dbname,
      entity: "CodeableConceptMaster",
      sort: "document(CodeableConceptMaster.coding[0]).display",
      filter: "CodeableConceptMaster.Type=='LOGINCOUNT'",
      return_fields:
        "MERGE(CodeableConceptMaster,{coding:(FOR cod IN CodeableConceptMaster.coding RETURN DOCUMENT(cod))})",
    };
    let res = await AxiosCall(
      "post",
      `${Config.api_url}/api/read_documents`,
      params
    );
    let result = res?.result[0];

    setcountLimit(result?.coding[0]?.display);
  };

  const getPatientLoggedIn = async (id) => {
    let params = {
      db_name: Config.dbname,
      entity: "Person",
      filter: `Person.keycloackid=='${id}' && Person.activestatus==true`,
      return_fields:
        "{_id:Person._id,orgID:Person.OrgID[0].id,orgType:Person.orgType,firstname:document(Person.name[0]).text,lastname:document(Person.name[0]).given}",
    };

    let res = await AxiosCall(
      "post",
      `${Config.api_url}/api/read_documents`,
      params
    );
    let result = res?.result[0];

    // if (result?.Patient?.PatientFName) {
    //   sessionStorage.setItem("user", btoa(result?.Patient?.PatientFName));
    // }

    return result;
  };

  const increaseLoginCount = async (id) => {
    let params = {
      db_name: Config.dbname,
      entity: "Bed",
      filter: `Bed._id=='${id}' && Bed.activestatus==true`,
      return_fields: "Bed",
    };

    let res = await AxiosCall(
      "post",
      `${Config.api_url}/api/read_documents`,
      params
    );

    let result = res?.result[0];

    let count = (result?.LoginCount ?? 0) + 1;
    setCount(count);
    let _d = { ...result, LoginCount: count };
    await updateBed(_d);
  };

  const updateBed = async (data) => {
    let config = [
      {
        db_name: Config.dbname,
        entity: "Bed",
        is_metadata: true,
        metadataId: process.env.REACT_APP_METADATAID,
        metadata_dbname: process.env.REACT_APP_MetadataDB_Name,
        filter: { _key: data?._key },
        doc: data,
      },
    ];

    let res = await AxiosCall(
      "post",
      process.env.REACT_APP_ARANGO_URL_UPSERT,
      config
    );

    return res;
  };

  return (
    <>
      {/* <Tour steps={steps}> */}
      <AppTour steps={steps} setOpen={setOpentour} open={openTour} />
      <OrderCatalog
        user={user}
        handleTour={handleTour}
        getMealSchedule={getMealSchedule}
        date={date}
        fromKitchen={fromKitchen}
        backToSearch={backToSearch}
        Count={Count}
        countLimit={countLimit}
        selectedPatient={selectedPatient}
        PatientDetails={PatientDetails}
      />
      {/* </Tour> */}
    </>
  );
};

export default PatientOrdering;
