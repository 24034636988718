import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Typography } from "@material-ui/core";
import StyledDivider from "../styledDivider";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(1.5),
  },
  title: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.75),
    "& > p": {
      fontWeight: 600,
    },
  },
}));

function CardContent(props) {
  // <---------------Hooks------------------>
  const classes = useStyles();

  // <-----------------Render--------------->
  const { children, title, titleIcon, actionIcon, handleActionClick } = props;

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.title}>
          {titleIcon}
          <Typography variant="body2">{title}</Typography>
        </div>
        {actionIcon && (
          <IconButton size="small" onClick={handleActionClick}>
            {actionIcon}
          </IconButton>
        )}
      </div>
      <StyledDivider backgroundColor="#DEE5EC" />
      {children}
    </div>
  );
}

export default CardContent;

CardContent.defaultProps = {
  title: "",
  titleIcon: null,
  actionIcon: null,
  handleActionClick: () => {},
};

CardContent.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  titleIcon: PropTypes.element,
  actionIcon: PropTypes.element,
  handleActionClick: PropTypes.func,
};
