/**
 * @author Kameshwaran Murugan
 * @email kamesh@qdmplatforms.com
 * @create date 2021-01-27
 * @modify date 2021-06-25
 * @desc Form Renderer
 */
import { makeStyles, Grid, Paper } from "@material-ui/core";
import React from "react";
import { SubHeader, CaseDefinition } from "../../components";
import { FormTreeView } from "./formTree";
import { FormBuilder } from "./formBuilder";
// import { useHistory } from "react-router-dom";
import { DrawerContext } from "../../contexts";
import { DrawerProps } from "../../utils";
import { PatientDetail } from "./patientDetail";
import { PatientDetailCard } from "../../components/patientDetailCard";
import { UserDetailCard } from "../../components/userDetailsCard";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Tooltip, Divider } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
    // padding: 16,
    height: "100%",
    [theme.breakpoints.only("xs")]: {
      padding: 10,
    },
  },
  sub: {},
  infocardGrid: {
    margin: "12px 0px -10px 20px",
  },
  infocard: {
    maxWidth: "100%",
    padding: "0px",
    margin: "12px",
  },
  cardContent: {
    //boxShadow: theme.shadows[10],
  },
  cardContent1: {
    float: "Right",
  },
  render: {
    // marginTop: "20px",
    // padding: "0px 32px 32px 32px",
    height: "100%",
  },

  backBtn: {
    [theme.breakpoints.up("sm")]: {
      marginBottom: 12,
    },
    color: "#fff",
    boxShadow: "0px 15px 25px #110f4712",
    backgroundColor: "#fff",
    paddingRight: theme.spacing(2),
    "&:hover": {
      boxShadow: "0px 15px 25px #110f4712",
      backgroundColor: "#fff",
      paddingRight: theme.spacing(2),
    },
  },
  treePaper: {
    padding: theme.spacing(1),
    marginBottom: 10,
    overflow: "auto",
    [theme.breakpoints.up("sm")]: {
      height: "calc(100vh - 150px)",
      marginBottom: 0,
      borderBottomRightRadius: 0,
      borderTopRightRadius: 0,
    },
    borderTopLeftRadius: 12,
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
    borderTopRightRadius: 12,
    border: "1px solid #0000001A",
    boxShadow: "0px 15px 25px #110f4712",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  patientdetailpaper: {
    height: "100px",
  },
  formBuilderPaper: {
    height: "100%",
    overflowY: "auto",
    border: "1px solid #0000001A",
  },
  subbacktext: {
    opacity: 0.6,
    fontSize: 12,
    paddingLeft: 4,
  },
}));

export const FormRenderer = ({
  form_detail,
  // transferDetails,
  tree_view,
  forms = [],
  parent_form_id,
  entry_id,
  isEdit,
  answer,
  isInvestigator = false,
  secToShow = [],
  secName = "",
  isReadyOnly = false,
  toggleButtonStatus = {},
  getPatientDetails = {},
  entryPatientDetails = {},
  onSaveForm = () => false,
  dataSecurityMappedCountryAndSelectedlevel = {},
  mappedLocations = {},
  verifier_registration_status,
  UserRole,
  bulkAnswers,
  onReloadActionPerformed,
}) => {
 
  const classes = useStyles();
  // const history = useHistory();
  const drawerContext = React.useContext(DrawerContext);
  const [view, setView] = React.useState([]);
  const [historyOfViewedForms, setHistoryOfViewedForms] = React.useState([]);
  const [patientDetail, setPatientDetail] = React.useState(entryPatientDetails);
  const [showForm, setShowForm] = React.useState(isEdit);
  const [showuserDetails, setShowuserDetails] = React.useState(true);
  const [state, setState] = React.useState({
    selected_form: forms[0] ?? {},
    form_id: forms[0]?.id ?? "",
    answer: answer,
  });

  const onInfoClicked = (data) => {
    drawerContext.setDrawer({
      ...drawerContext,
      open: true,
      direction: DrawerProps.direction.right,
      variant: DrawerProps.variant.temporary,
      component: <CaseDefinition data={form_detail?.mapped_diseases} />,
      onClose: () => onDrawerClosed(),
    });
  };

  const onPatientInfoBtnClicked = () => {
    drawerContext.setDrawer({
      ...drawerContext,
      open: true,
      direction: DrawerProps.direction.right,
      variant: DrawerProps.variant.temporary,
      component: <PatientDetailCard {...patientDetail} />,
      onClose: () => onDrawerClosed(),
    });
  };

  const onDrawerClosed = () => {
    drawerContext.setDrawer({
      ...drawerContext,
      direction: DrawerProps.direction.right,
      open: false,
    });
  };

  const getSectionId = (data) => {
    setState({
      ...state,
      selected_form: forms?.filter((_) => _.id === data.id)?.[0],
      form_id: data.id,
    });

    drawerContext.setDrawer({
      ...drawerContext,
      direction: DrawerProps.direction.left,
      open: false,
      onClose: () => onViewSectionDrawerClosed(),
    });
  };

  // const onBackBtnClicked = () => {
  //   // history.goBack();
  // };

  const viewSectionDrawer = () => {
    drawerContext.setDrawer({
      ...drawerContext,
      open: true,
      direction: DrawerProps.direction.left,
      variant: DrawerProps.variant.temporary,
      component: (
        <div style={{ padding: 20 }}>
          <FormTreeView
            tree_view={tree_view}
            getSectionId={getSectionId}
            selected_section_id={state.form_id}
            selected_form={state.selected_form}
            isEdit={isEdit}
            view={view}
            historyOfViewedForms={historyOfViewedForms}
            setHistoryOfViewedForms={setHistoryOfViewedForms}
            setView={setView}
            isReadyOnly={isReadyOnly}
            secToShow={secToShow}
          />
        </div>
      ),
      onClose: () => onViewSectionDrawerClosed(),
    });
  };

  const onViewSectionDrawerClosed = () => {
    drawerContext.setDrawer({
      ...drawerContext,
      direction: DrawerProps.direction.left,
      open: false,
    });
  };

  const updateAnswer = (answer) => {
    setState({
      ...state,
      answer,
    });
  };

  const onPatientSelected = (data) => {
    setPatientDetail({
      ...patientDetail,
      ...data,
    });

    setShowForm(true);
  };

  const restForm = (data) => {
    setPatientDetail(null);
    setShowForm(false);
  };

  React.useEffect(() => {
    setHistoryOfViewedForms([...historyOfViewedForms, view]);
    // eslint-disable-next-line
  }, [view]);

  return (
    <div className={classes.root} id="frDiv1">
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="stretch"
        style={{ height: "100%" }}
      >
        {/* Header Section */}
        {!showForm && (
          <Grid item xs={12} lg={12}>
            <SubHeader
              title={form_detail?.name}
              // dataSecurityMappedCountryAndSelectedlevel={
              //   dataSecurityMappedCountryAndSelectedlevel
              // }
              // mappedLocations={mappedLocations}
              entry_id={entry_id}
              form_id={state.form_id}
              parent_form_id={parent_form_id}
              subTitle={`(${
                form_detail?.master_form_type?.form_type ?? "Form Type"
              })`}
              // transferDetails={transferDetails}
              // onBackBtnClicked={onBackBtnClicked}
              onActionIconBtnClicked={onInfoClicked}
              showInfoButton
              secName={secName}
              showToogleButton
              toggleButtonData={
                isInvestigator
                  ? toggleButtonStatus.toggleButtonData?.filter(
                      (l) => l.value === 5 || l.value === 6
                    )
                  : toggleButtonStatus.toggleButtonData
              }
              selected={toggleButtonStatus.isSelected}
              exclusive={toggleButtonStatus.exclusive}
              onSelected={toggleButtonStatus.onSelected}
              statusUpdateData={toggleButtonStatus.statusUpdateData}
              statusPath={toggleButtonStatus.status}
              redirectPath={toggleButtonStatus?.redirectPath}
              showPatientDetails={true}
              getPatientDetails={patientDetail}
              view={view}
              setView={setView}
              isReadyOnly={isReadyOnly}
              secToShow={secToShow}
              onPatientInfoBtnClicked={onPatientInfoBtnClicked}
            />
          </Grid>
        )}
        {!showForm && (
          <Grid item style={{ padding: "16px 32px 0px 32px" }}>
            <Tooltip
              title={`${patientDetail.patient_name?.title?.label} ${
                patientDetail.patient_name?.first_name
              } ${patientDetail.patient_name?.middle_name ?? ""} ${
                patientDetail.patient_name?.last_name ?? ""
              }`}
            >
              <Card>
                <CardContent className={classes.cardContent}>
                  <Grid item container style={{ display: "flex" }}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={5}
                      lg={5}
                      // style={{ borderRight: "1px solid grey" }}
                    >
                      <PatientDetailCard
                        {...patientDetail}
                        showuserDetails={showuserDetails}
                      />
                    </Grid>

                    <Divider orientation="vertical" flexItem />

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <UserDetailCard {...patientDetail} />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Tooltip>
          </Grid>
        )}
        {/* Main Content */}
        {forms?.length > 0 && (
          <Grid
            item
            container
            // direction="row"
            // justify="flex-start"
            // alignItems="flex-start"
            className={classes.render}
          >
            {/* Form View */}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ height: "100%" }}
            >
              <Paper className={classes.formBuilderPaper} elevation={0}>
                {!showForm && (
                  <PatientDetail
                    patientDetail={entryPatientDetails}
                    isEdit={isEdit}
                    onPatientSelected={onPatientSelected}
                  />
                )}

                {showForm && (
                  <FormBuilder
                    verifier_registration_status={verifier_registration_status}
                    getSectionId={getSectionId}
                    form_id={state.form_id}
                    forms={forms}
                    parent_form_id={parent_form_id}
                    isEdit={isEdit}
                    entry_id={entry_id}
                    answer={answer}
                    isInvestigator={isInvestigator}
                    isReadyOnly={isReadyOnly}
                    secToShow={secToShow}
                    secName={secName}
                    onAnswerUpdated={updateAnswer}
                    view={view}
                    onSaveForm={onSaveForm}
                    setView={setView}
                    viewSection={viewSectionDrawer}
                    setHistoryOfViewedForms={setHistoryOfViewedForms}
                    statusUpdateData={toggleButtonStatus.statusUpdateData}
                    patientDetail={patientDetail}
                    restForm={restForm}
                    UserRole={UserRole}
                    bulkAnswers={bulkAnswers}
                    onReloadActionPerformed={onReloadActionPerformed}
                  />
                )}
              </Paper>
            </Grid>
          </Grid>
        )}

        {forms?.length === 0 &&
          {
            /* <NotFoundSection message="No Forms Added Yet!" /> */
          }}
      </Grid>
    </div>
  );
};
