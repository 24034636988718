import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { styled } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { useHistory } from "react-router-dom";
import { Tooltip, Typography, Zoom } from "@material-ui/core";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  ActionButton: {
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    padding: "4px",
    cursor: "pointer",
    top: "40px",
    right: "-14px",
    position: "absolute",
    backgroundColor: "white",
    transition: "width .2s ease-in-out",
    border: `1px solid ${theme.palette.primary.main}`,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
  },
  ListItem: {
    color: theme.palette.text.primary,
    borderRadius: "8px",
    padding: "8px 8px",
    margin: "4px 0px",
    transition: "all .3s ease-in",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.background.common,
    },

    "& .MuiListItemIcon-root": {
      color: theme.palette.text.primary,
    },
    "&:hover .MuiListItemIcon-root": {
      color: theme.palette.background.common,
    },
  },
  selectedItem: {
    borderRadius: "8px",
    padding: "8px 8px",
    margin: "4px 0px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
    transition: "all .3s",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.background.common,
    },
    "& .MuiListItemIcon-root": {
      color: theme.palette.background.common,
    },
  },
  hiddenListLabel: {
    opacity: "0",
    fontSize: "14px",
    fontWeight: "400",
    transition: "opacity .2s ease-in-out",
  },
  selectedListLabel: {
    opacity: "1",
    fontSize: "0.8rem",
    fontWeight: "400",
    color: "#fff",
    padding: "0px 8px",
  },
  listLabel: {
    opacity: "1",
    fontSize: "0.8rem",
    fontWeight: "400",
    padding: "0px 8px",
  },
}));

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: "width .2s ease-in-out",
  overflowY: "clip",
  top: "unset",
  "&:hover": {
    borderRightColor: theme.palette.info.main,
  },
});

const closedMixin = (theme) => ({
  width: "65px",
  overflowY: "clip",
  top: "unset",
  transition: "width .2s ease-in-out",
  "&:hover": {
    borderRightColor: theme.palette.info.main,
  },
});

const StyledDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  color: theme.palette.text.primary,
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    width: "calc(100% - 65px)",
    height: "100%",
    marginLeft: "65px",
    flexGrow: 1,
    transition: "all .2s ease-in-out",
    ...(open && {
      width: "calc(100% - 240px)",
      transition: theme.transitions.create("all", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: "240px",
    }),
  })
);

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.primary.main,
  },
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: "1rem",
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return (
    <Tooltip arrow TransitionComponent={Zoom} classes={classes} {...props} />
  );
}

const SensibleDrawer = (props) => {
  const classes = useStyles();
  const { sidePanellist, selectedItem, setSelectedItem } = props;
  const [showButtonExpand, setButtonExpand] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const history = useHistory();

  const NavigateTo = (l) => {
    setSelectedItem(l);
    history.push(l?.routePath);
  };

  const handleDrawerClose = () => {
    setOpen(!open);
    setButtonExpand(false);
  };

  return (
    <>
      <StyledDrawer
        variant="permanent"
        open={open}
        onMouseEnter={() => setButtonExpand(true)}
        onMouseLeave={() => setButtonExpand(false)}
      >
        {/* List of item to render on drawer component */}
        <List style={{ padding: "8px", color: "#0000" }}>
          {sidePanellist?.map((l, index) => (
            <div key={`${index}`}>
              {l?.read && (
                <BootstrapTooltip
                  title={open ? "" : l?.name}
                  color="primary"
                  placement="right"
                >
                  <ListItem
                    button
                    disableGutters
                    style={open ? { display: "flex" } : {}}
                    className={
                      selectedItem.id === l.id
                        ? classes.selectedItem
                        : classes.ListItem
                    }
                    onClick={() => NavigateTo(l)}
                  >
                    <ListItemIcon
                      style={{
                        minWidth: 0,
                        margin: open ? "0px" : "auto",
                        padding: open ? "0px 8px" : "0px",
                        justifyContent: "center",
                      }}
                    >
                      {l?.icon}
                    </ListItemIcon>
                    {open && (
                      <Typography
                        className={
                          !open
                            ? classes.hiddenListLabel
                            : selectedItem.id === l.id
                            ? classes.selectedListLabel
                            : classes.listLabel
                        }
                      >
                        {l.name}
                      </Typography>
                    )}
                  </ListItem>
                </BootstrapTooltip>
              )}
            </div>
          ))}
        </List>

        {showButtonExpand && (
          <>
            {open ? (
              <ChevronLeftIcon
                className={classes.ActionButton}
                onClick={() => handleDrawerClose()}
              />
            ) : (
              <ChevronRightIcon
                className={classes.ActionButton}
                onClick={() => setOpen(!open)}
              />
            )}
          </>
        )}
      </StyledDrawer>

      {/* Page content */}
      <Main open={open}>{sidePanellist.length > 0 && props.children}</Main>
    </>
  );
};

export default SensibleDrawer;
