import React from "react";
import {
  Grid,
  Paper,
  makeStyles,
  Card,
  Typography,
  IconButton,
  Avatar,
  TextField,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { BsBuilding } from "react-icons/bs";
import { RiBuilding2Line } from "react-icons/ri";
import { GoLocation } from "react-icons/go";
import { FaHandHoldingMedical } from "react-icons/fa";
// import { GoPrimitiveDot } from "react-icons/go";
import { GoDotFill } from "react-icons/go";
import { GrMailOption } from "react-icons/gr";
import { BsTelephone } from "react-icons/bs";
import { GrDocumentVerified } from "react-icons/gr";
import EditProfile from "./editProfile";
import axios from "axios";
import { useLocation } from "react-router";
import { Email } from "@material-ui/icons";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#ffffff",
    margin: "7px",
    padding: "10px",
  },
  containerGrid: {
    display: "flex",
    margin: "10px",
    padding: "10px 0px 10px 0px",
  },
  heading: {
    color: "#007AFF",
    fontWeight: "700",
  },
  Pharma: {
    fontWeight: "700",
  },
  EDbutton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  entity: {
    color: "#007AFF",
    fontWeight: 700,
  },
  button: {
    color: "#007AFF",
  },
}));

function Profile(props) {
  const classes = useStyles();
  const location = useLocation();

  const email = location?.state1?.email?.[0]?.value;

  const [validid,setValidid] = React.useState("")
  const [edit, setEdit] = React.useState(false);
  const [formID, setFormID] = React.useState("");
  const [formdata, setFormdata] = React.useState([]);
  const [profile, setProfile] = React.useState({
    Email: "",
    ClientType: "",
    EntityType: "",
    Association: "",
    ParentGroup: "",
    EntityName: "",
    ContactPersonName: "",
    Address: "",
    City: "",
    PhoneNumber: "",
    State: "",
    Region: "",
  });
  //console.log("profile",profile[0])

  const handleEdit = () => {
    setEdit(!edit);
  };

  function answergetter(Array, questionid) {
    let result = Array?.filter((v) => v?.["question_id"] === questionid);
    //console.log("result",result)
    return result?.[0]?.answer?.answer;
  }


  function valuesetting(apiFormDat = []) {
    let parantgroup = answergetter(
      apiFormDat,
      "18af4817-b3cb-4f92-9449-df2b1c018401"
    );
    setProfile([{
      Email: answergetter(apiFormDat, "c81e3bbb-c394-4f6d-bd31-fc3c99807fc8"),
      ClientType: answergetter(
        apiFormDat,
        "8b880af2-b575-4ff3-8324-2adf8990bfde"
      ),
      // EntityType: answergetter( apiFormDat,"77a9f57f-47f0-478e-bb98-b91e6985caad"),
      EntityType: answergetter(
        apiFormDat,
        "75dce6d8-bd5d-47e5-91b3-922ad83f199c"
      ),
      Association: answergetter(
        apiFormDat,
        "18af4817-b3cb-4f92-9449-df2b1c018401"
      ),
      ParentGroup: answergetter(
        apiFormDat,
        "77a9f57f-47f0-478e-bb98-b91e6985caad"
      ),
      EntityName: answergetter(
        apiFormDat,
        "63a00d95-40e4-4902-ac84-8d5457a6a5d4"
      ),
      ContactPersonName: answergetter(
        apiFormDat,
        "94726c7e-dcf1-4f96-9c09-bb95cfd4e3af"
      ),
      Address: answergetter(apiFormDat, "b16181ca-2dfd-4a27-8348-bcbd3d58a5e4"),
      City: answergetter(apiFormDat, "e677107e-8179-4f93-aac6-e333cfdce18a"),
      PhoneNumber: answergetter(
        apiFormDat,
        "7ba2eb3e-0726-4d5b-92bd-1e400a884d0c"
      ),
      State: answergetter(apiFormDat, "a6cd6452-805f-457d-98e7-860109034bfc"),
      Region: answergetter(apiFormDat, "72950d1d-1bbe-4716-9066-988f815b8597"),
    }]);
  }

  
  const Idgetter = async () => {
    let param = {
      db_name: process.env.REACT_APP_DB,
      entity: "Person",
      filter:`like(document(Person.telecom)[*].value,'%${email}%',true)  && Person.activestatus==true`,
      return_fields: "document(Person.orgType)",
    };
    let ans = await axios
    .post(process.env.REACT_APP_ARANGO_URL_READ,param)
    .then((res)=> res);
    let dashboardID = location?.state?.ticketId?.toString()?.length > 0 ? location?.state?.ticketId : ans?.data?.result?.[0]?.ticketId
    Profiledata(dashboardID);
    setValidid(dashboardID)
    
  };
  

  const Profiledata = async (ticket) => {
    let param = {
      db_name: process.env.REACT_APP_DB,
      entity: "form_entries",
      filter: `form_entries.ticketId == '${ticket}' && form_entries.form_id == '${process.env.REACT_APP_PROFILE_FORMID}'`,
      return_fields:
        "unset(form_entries,'activestatus','_rev','updatedate','updatedby','createddate','createdby')",
    };
    let result = await axios
      .post(process.env.REACT_APP_ARANGO_URL_READ, param)
      .then((res) => res);

    setFormdata(result.data?.result[0]?.answers);
    valuesetting(result.data?.result[0]?.answers);
    setFormID(result);
  };

  React.useEffect(() => {
    Idgetter();
  }, []);

  let id = formID?.data?.result[0]?._id;
  var first_letter = profile[0]?.EntityName;
  let letter = first_letter?.at(0);
  return (
    <>
      {edit ? (
        <>
          <EditProfile
            setEdit={setEdit}
            data={profile}
            ticketid={validid}
            Id={id}
            handleEdit={handleEdit}
            setProfile={setProfile}
            letter={letter}
          />
        </>
      ) : (
        <>
          <Grid container className={classes.root}>
            <Grid container item>
              <Grid
                container
                item
                xs={12}
                justifyContent="space-between"
                className={classes.containerGrid}
              >
                <Grid item>
                  <Typography variant="h5" className={classes.heading}>
                    Entity Information
                  </Typography>
                </Grid>

                <Grid item>
                  <IconButton onClick={() => handleEdit()}>
                    <EditIcon />
                  </IconButton>
                </Grid>
                <Grid container item spacing={7}>
                  <Grid item>
                    <Avatar
                      variant="square"
                      style={{
                        height: "8.2rem",
                        width: "8.2rem",
                        borderRadius: "1em",
                        display:"flex",
                        alignItems:"center"
                      }}
                    >
                      <Typography variant="h1">{letter}</Typography>
                    </Avatar>
                  </Grid>
                  <Grid item>
                    <Grid container item>
                      {profile[0]?.EntityName?.toString()?.length > 0 ? (
                        <Typography variant="h5" style={{ fontWeight: "bold" }}>
                          {profile?.[0]?.EntityName}
                        </Typography>
                      ) : (
                        <Typography> - </Typography>
                      )}
                    </Grid>
                    
                    <Grid item style={{ padding: "15px 0px 25px 0px" }}>
                      <Grid container spacing={2}>
                        <Grid item>
                          <BsBuilding size={20} />
                        </Grid>
                        <Grid item>
                          {profile[0]?.Association?.label?.toString()?.length >
                          0 ? (
                            <Typography>
                              {profile[0]?.Association.label}
                            </Typography>
                          ) : (
                            <Typography> - </Typography>
                          )}
                        </Grid>
                        {profile?.EntityType ==
                        "Individual Entity Part of Group" ? (
                          <>
                            <Grid item>
                              <RiBuilding2Line size={20} />
                            </Grid>
                            <Grid item>
                              {profile[0]?.Association?.toString()?.length ? (
                                <Typography>
                                  {profile[0]?.Association.label}
                                </Typography>
                              ) : (
                                <Typography> - </Typography>
                              )}
                            </Grid>
                          </>
                        ) : (
                          <></>
                        )}
                      </Grid>
                      {/* <Grid container spacing={2}>
                        <Grid item>
                          <GoLocation size={20} />
                        </Grid>
                        <Grid item>
                          {profile?.State?.label?.toString()?.length > 0 ? (
                            <Typography>{profile?.State.label}</Typography>
                          ) : (
                            <Typography> - </Typography>
                          )}
                        </Grid>
                      </Grid> */}
                      <Grid
                        container
                        item
                        spacing={2}
                        style={{ padding: "0.5em 0em 0em 0em" }}
                      >
                        <Grid item>
                          <FaHandHoldingMedical size={20} />
                        </Grid>
                        <Grid item>
                          {profile[0]?.ClientType?.label?.toString()?.length >
                          0 ? (
                            <Typography>{profile[0]?.ClientType.label}</Typography>
                          ) : (
                            <Typography> - </Typography>
                          )}
                        </Grid>
                        <Grid item className={classes.center}>
                          <GoDotFill
                            className={classes.entity}
                            size={15}
                          />
                        </Grid>
                        <Grid item className={classes.center}>
                          {profile[0]?.EntityType?.toString()?.length > 0 ? (
                            <Typography className={classes.entity}>
                              {profile[0]?.EntityType}
                            </Typography>
                          ) : (
                            <Typography> - </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                justifyContent="space-between"
                className={classes.containerGrid}
              >
                <Grid item>
                  <Typography variant="h5" className={classes.heading}>
                    Contact Details
                  </Typography>
                </Grid>
                <Grid container item style={{ padding: "10px 0px 0px 10px" }}>
                  <Grid item className={classes.center}>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                      {profile[0]?.ContactPersonName}
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    style={{ padding: "10px 0px 10px 0px" }}
                  >
                    <Grid item className={classes.center}>
                      <GrMailOption size={20} />
                    </Grid>
                    <Grid item className={classes.center}>
                      {profile[0]?.Email?.toString()?.length > 0 ? (
                        <Typography>{profile[0]?.Email}</Typography>
                      ) : (
                        <Typography> - </Typography>
                      )}
                    </Grid>
                    <Grid item className={classes.center}>
                      <BsTelephone size={20} />
                    </Grid>
                    <Grid item className={classes.center}>
                      {profile[0]?.PhoneNumber?.number?.toString()?.length > 0 ? (
                        <Typography>{profile[0]?.PhoneNumber?.number}</Typography>
                      ) : (
                        <Typography> - </Typography>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item className={classes.center}>
                      <GoLocation size={20} />
                    </Grid>
                    <Grid item className={classes.center}>
                      {profile[0]?.Address?.toString()?.length > 0 ? (
                        <Typography>{profile[0]?.Address}</Typography>
                      ) : (
                        <Typography> - </Typography>
                      )}
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item style={{ margin: "0.5em 0em 1em 3em" }}>
                        {profile[0]?.City?.toString()?.length > 0 ? (
                          <Typography>{profile[0]?.City?.label}</Typography>
                        ) : (
                          <Typography> - </Typography>
                        )}
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item style={{ margin: "0.5em 0em 1em 3em" }}>
                        {profile[0]?.Region?.label?.toString()?.length > 0 ? (
                          <Typography>{profile[0]?.Region?.label}</Typography>
                        ) : (
                          <Typography> - </Typography>
                        )}
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item style={{ margin: "0em 0em 0em 3em" }}>
                        {profile[0]?.State?.label?.toString()?.length > 0 ? (
                          <Typography>{profile[0]?.State?.label}</Typography>
                        ) : (
                          <Typography> - </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                justifyContent="space-between"
                className={classes.containerGrid}
              ></Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}

export default Profile;

//data?.[props.answerno]?.answer?.answer
