import * as React from "react";
import PropTypes from "prop-types";

const AddIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} {...props}>
    <path
      d="M8 0a8 8 0 1 0 8 8 8.009 8.009 0 0 0-8-8Zm0 1.2A6.8 6.8 0 1 1 1.2 8 6.791 6.791 0 0 1 8 1.2Zm-.009 2.791A.6.6 0 0 0 7.4 4.6v2.8H4.6a.6.6 0 1 0 0 1.2h2.8v2.8a.6.6 0 1 0 1.2 0V8.6h2.8a.6.6 0 1 0 0-1.2H8.6V4.6a.6.6 0 0 0-.609-.609Z"
      fill={props.color}
    />
  </svg>
);

export default AddIcon;

AddIcon.defaultProps = {
  color: "#2a60bc",
};

AddIcon.propTypes = {
  color: PropTypes.string,
};
