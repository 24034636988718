import React, { useEffect, useState } from "react";
import axios from "axios";

import { withNavBars } from "../../HOCs";
import { ManageCard } from "atp-crud";
import { actions, idmapis } from "central_pharma_binder";
import { connect } from "react-redux";
import { giveMeRoleDataSecurity, AlertProps } from "../../utils";

import {
  DrawerContext,
  AlertContext,
  DialogContext,
  BackdropContext,
} from "../../contexts";
import { DrawerProps, ConvertToReactSelect, Message } from "../../utils";

import { AddEditForm } from "./addEditForm";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";
import copy from "copy-to-clipboard";
import { withTheme } from "@material-ui/core";
import form3 from "../../assets/form3.svg";

// Store Action's list
const { UPSERT_FORM } = actions;

export const ManageFormsParent = (props) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    sort: "all",
  });

  // console.log(permissiionDatas, "permissiionDatas");

  const [clientData, setClientData] = useState("");
  // console.log(clientData, "test");
  const [tenatIdData, setTenatIdData] = useState("");
  const [selectOrg, setSelectedOrg] = useState([]);
  useEffect(() => {
    (async function () {
      // let roleid = localStorage.getItem("RoleId");
      // let roletype = await idmapis.getuserrole_from_permissionrolemapping(
      //   roleid
      // );
      // setClientData(roletype?.result[0]?.clientid);
      // let clientTenantId = await idmapis.getclientif_from_tenantid(
      //   roletype?.result[0]?.tenantid
      // );
      // setTenatIdData(clientTenantId?.result[0]?._id);
      // let diseaseFormMapping = {
      //   db_name: process.env.REACT_APP_DB,
      //   entity: process.env.REACT_APP_FORMMAP_ENTITY,
      //   filter: `${process.env.REACT_APP_FORMMAP_ENTITY}.client_id=='${clientTenantId?.result[0]?._id}'&& ${process.env.REACT_APP_FORMMAP_ENTITY}.is_active==true && ${process.env.REACT_APP_FORMMAP_ENTITY}.activestatus==true`,
      //   return_fields:
      //     "{formId:AssessmentFormMapping.mappingTable.form._id, selectedOrg:AssessmentFormMapping.mappingTable.selectedOrg.name}",
      // };
      // let config = {
      //   method: "POST",
      //   url: process.env.REACT_APP_ARANGO_URL_READ,
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   data: diseaseFormMapping,
      // };
      // var diseaseFormMappingData = await axios(config).then((res) => {
      //   return res.data.result;
      // });
      // // console.log("diseaseFormMappingData", diseaseFormMappingData);
      // setSelectedOrg([...diseaseFormMappingData]);
      // let vms = {
      //   dbname: process.env.REACT_APP_DB,
      //   entity: "master_assessment",
      //   sortkey: "assessment_name",
      //   isSuperAdmin: true,
      // };
      // await dispatch(actions.GET_ALL_ASSESSMENTS(vms));
      // backdrop.setBackDrop({
      //   ...backdrop,
      //   open: true,
      //   message: "Loading Data",
      // });
    })();
    // clientCheckFunc();
    setTimeout(() => {
      backdrop.setBackDrop({
        ...backdrop,
        open: false,
        message: "",
      });
      setDelay(true);
    }, 2000);
  }, []);
  // const [copyValue, setCopyValue] = React.useState("");
  const [delay, setDelay] = React.useState(false);
  const drawerContext = React.useContext(DrawerContext);
  const alert = React.useContext(AlertContext);
  const dialogContext = React.useContext(DialogContext);
  const backdrop = React.useContext(BackdropContext);

  const history = useHistory();

  const formTypes = useSelector((state) => state?.formSlice?.getAllFormTypes);

  const upsertForm = useSelector((state) => state?.formSlice?.upsertForm);

  const getallselector = useSelector((state) => ({
    getAllAssessment: state?.AssessmentSlice?.getAllAssessments,
    getAllICD10Code: state?.DiseaseSlice?.getAllICD10Code,
    getAllDiseasesClassification:
      state?.DiseaseSlice?.getAllDiseasesClassification,
  }));
  const formsGet = useSelector((state) => ({
    getAllForms: state?.formSlice?.getAllForms,
    userData: state?.authSlice?.login?.data ?? {},
  }));

  // let filterData;

  // if (clientData === "" || clientData === undefined) {
  //   filterData = formsGet?.getAllForms?.data?.filter((v) => v.client_id === "");
  // } else {
  //   filterData = formsGet?.getAllForms?.data?.filter(
  //     (v) => v.client_id === tenatIdData
  //   );

  //   for (let i = 0; i < filterData.length; i++) {
  //     let formWith = `forms/${filterData[i].form_id}`;
  //     for (let j = 0; j < selectOrg.length; j++) {
  //       if (formWith === selectOrg[j].formId) {
  //         filterData[i] = {
  //           ...filterData[i],
  //           selectedOrg: selectOrg[j].selectedOrg,
  //         };
  //       }
  //     }
  //   }
  // }

  // console.log("TenatId", tenatId);

  const sortOptionArray = [
    { value: "all", label: "All Form Types" },
    ...ConvertToReactSelect(formTypes?.data, "form_type_id", "form_type_name"),
  ];

  useEffect(() => {
    getForms();
    getFormTypes();
  }, [state]);

  const handleSortChange = (sort) => {
    setState(
      {
        sort,
      },
      () => {
        getForms();
      }
    );
  };

  const getForms = (searchKey = "", created_at = false) => {
    const roleDataSecurity = giveMeRoleDataSecurity(props.userData);
    const createdBy = props?.userData?.user_profile_id;

    let sort = {
      sortField: "form_name",
      sortDirection: "ASC",
      diseases:
        roleDataSecurity?.disease?.[0] === "all"
          ? []
          : roleDataSecurity?.disease,
      countryIds:
        roleDataSecurity?.country?.[0] === "all"
          ? []
          : roleDataSecurity?.country,
      formIds:
        roleDataSecurity?.form?.[0] === "all" ? [] : roleDataSecurity?.form,
    };

    if (created_at) {
      sort.sortField = "updated_at";
      sort.sortDirection = "DESC";
    }

    let formTypeId = "";
    if (state.sort !== "all") {
      formTypeId = state.sort;
    }

    if (createdBy) {
      sort.created_by = createdBy;
    }

    // let formy = props.GET_ALL_FORMS({
    //   formName: searchKey.trim(),
    //   formTypeId,
    //   ...sort,
    // });

    // console.log(formy, "Forms..");

    dispatch(
      actions.GET_ALL_FORMS({
        formName: searchKey.trim(),
        formTypeId,
        ...sort,
      })
    );
  };

  const getFormTypes = () => {
    dispatch(actions.GET_ALL_FORM_TYPES());
  };

  const addForm = () => {
    drawerContext.setDrawer({
      ...drawerContext,
      open: true,
      direction: DrawerProps.direction.right,
      variant: DrawerProps.variant.temporary,
      component: (
        <AddEditForm
          onActionCompleted={onActionCompleted}
          formTypes={formTypes}
          upsertForm={upsertForm}
          diseasesData={getallselector?.getAllAssessment?.data?.result}
        />
      ),
      onClose: () => onActionCompleted(),
    });
  };

  const onActionCompleted = () => {
    onDrawerClosed();
    getForms(document.getElementById("ippc_search_bar").value, true);
  };

  const onDrawerClosed = () => {
    drawerContext.setDrawer({
      ...drawerContext,
      direction: DrawerProps.direction.right,
      open: false,
    });
  };

  const onSearchChange = (value) => {
    if (value && value?.length % 3 === 0) {
      getForms(value);
    } else if (!value) {
      getForms();
    }
  };

  const onViewFormClicked = (data) => {
    localStorage.setItem(
      "formId",
      window.btoa(data?.currentmapobject?.form_id)
    );
    history.push(Routes.manage_form_detail);
  };

  const onEditIconClicked = (data) => {
    drawerContext.setDrawer({
      ...drawerContext,
      open: true,
      direction: DrawerProps.direction.right,
      variant: DrawerProps.variant.temporary,
      component: (
        <AddEditForm
          isEdit={true}
          editData={data.currentmapobject}
          formTypes={formTypes}
          upsertForm={upsertForm}
          diseasesData={getallselector?.getAllAssessment?.data?.result}
          onActionCompleted={onActionCompleted}
        />
      ),
      onClose: () => onDrawerClosed(),
    });
  };

  const setCopy = (value) => {
    copy(value.currentmapobject?.form_id);

    alert.setSnack({
      ...alert,
      open: true,
      severity: AlertProps.severity.success,
      msg: "Form ID Copied To ClipBoard !",
      vertical: AlertProps.vertical.top,
      horizontal: AlertProps.horizontal.center,
    });
  };

  const onDeleteIconClicked = (data) => {
    dialogContext.setDialog({
      ...dialogContext,
      open: true,
      // title: Message.manage_form_delete_title,
      body: Message.manage_form_delete_title,
      positiveBtn: Message.manage_form_delete_btn,
      negativeBtn: "Cancel",
      onOk: () => deleteCountry(data.currentmapobject),
    });
  };

  const deleteCountry = (data) => {
    dialogContext.setDialog({ ...dialogContext, open: false });
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: Message.manage_form_delete,
    });

    Promise.resolve(
      dispatch(
        UPSERT_FORM({
          formId: data.form_id,
          isActive: false,
        })
      )
    ).then((res) => {
      backdrop.setBackDrop({ ...backdrop, open: false });
      getForms();

      let resStatus = res.payload.error;
      alert.setSnack({
        ...alert,
        open: true,
        severity: resStatus
          ? AlertProps.severity.error
          : AlertProps.severity.success,
        msg: resStatus
          ? "Form deletion Failed !"
          : "Form deleted Successfully !",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
    });
  };
  {
    // console.log("clientData", clientData);
  }
  return (
    <div>
      {delay && (
        <ManageCard
          //Below props for SearchFilter Props
          title="Manage Forms"
          topbuttonName={clientData === "" ? "Add Forms" : ""}
          hideFilter={true}
          showSort={false}
          globalsrc={`${form3}`}
          addfunc={clientData === "" ? addForm : null}
          sortOptions={sortOptionArray}
          sortfunc={handleSortChange}
          searchfunc={onSearchChange}
          inbuiltsearchfunctinality={false}
          //below props for Card List
          basedata={formsGet?.getAllForms?.data}
          keysformap={{
            title_key: "form_name",
            cardname_key: "selectedOrg",
          }}
          hidebtn1={false}
          show_card={clientData === "" ? false : true}
          show_sub_title={clientData === "" ? false : true}
          sub_title="Test"
          hideaddbutton={clientData === "" ? false : true}
          Btn1Name={"Copy Id"}
          onBtn1Clicked={setCopy}
          hidebtn2={false}
          Btn2Name={"View Form"}
          onBtn2Clicked={onViewFormClicked}
          isMoreBtn={clientData === "" ? true : false}
          hideEditBtn={clientData === "" ? false : true}
          hideDeleteBtn={clientData === "" ? false : true}
          editfunc={onEditIconClicked}
          deletefunc={onDeleteIconClicked}
          theme={props?.theme}
          hideImage={true}
        // theme={require("../../themes/default.json")}
        />
      )}
    </div>
  );
};

// const mapStateToProps = (state) => ({
//   getAllForms: state?.formSlice?.getAllForms,
//   userData: state?.authSlice?.login?.data ?? {},
// });

const mapStateToProps = (state) => {
  return {
    getAllForms: state?.formSlice?.getAllForms,
    userData: state?.authSlice?.login?.data ?? {},
    getAllAssessment: state?.AssessmentSlice?.getAllAssessments,
    getAllICD10Code: state?.DiseaseSlice?.getAllICD10Code,
    getAllDiseasesClassification:
      state?.DiseaseSlice?.getAllDiseasesClassification,
  };
};

export default connect(mapStateToProps, actions)(withTheme(ManageFormsParent));

// class ManageFormsParent extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       sort: "all",
//     };
//   }

//   handleSortChange = (sort) => {
//     this.setState(
//       {
//         sort,
//       },
//       () => {
//         this.getForms();
//       }
//     );
//   };

//   componentDidMount() {
//     this.getForms();
//     this.getFormTypes();
//   }

//   getFormTypes = () => {
//     this.props.GET_ALL_FORM_TYPES();
//   };

//   getForms = (searchKey = "", created_at = false) => {
//     const roleDataSecurity = giveMeRoleDataSecurity(this.props.userData);
//     const createdBy = this.props.userData.user_profile_id;

//     let sort = {
//       sortField: "form_name",
//       sortDirection: "ASC",
//       diseases:
//         roleDataSecurity?.disease?.[0] === "all"
//           ? []
//           : roleDataSecurity?.disease,
//       countryIds:
//         roleDataSecurity?.country?.[0] === "all"
//           ? []
//           : roleDataSecurity?.country,
//       formIds:
//         roleDataSecurity?.form?.[0] === "all" ? [] : roleDataSecurity?.form,
//     };

//     if (created_at) {
//       sort.sortField = "updated_at";
//       sort.sortDirection = "DESC";
//     }

//     let formTypeId = "";
//     if (this.state.sort !== "all") {
//       formTypeId = this.state.sort;
//     }

//     if (createdBy) {
//       sort.created_by = createdBy;
//     }

//     let formy = this.props.GET_ALL_FORMS({
//       formName: searchKey.trim(),
//       formTypeId,
//       ...sort,
//     });

//     console.log(formy, "Forms..");

//     this.props.GET_ALL_FORMS({
//       formName: searchKey.trim(),
//       formTypeId,
//       ...sort,
//     });
//   };

//   // addForm(){
//   //   drawerContext.setDrawer({
//   //     ...drawerContext,
//   //     open: true,
//   //     direction: DrawerProps.direction.right,
//   //     variant: DrawerProps.variant.temporary,
//   //     component: (
//   //       <AddEditForm
//   //         projectId={projectId}
//   //         clientId={clientId}
//   //         onActionCompleted={onActionCompleted}
//   //       />
//   //     ),
//   //     onClose: () => onActionCompleted(),
//   //   });
//   // }

//   render() {
//     const { classes, getAllForms } = this.props;
//     console.log("AllForms", getAllForms);
//     console.log(this.state, "statess...");
//     // const getAllForms = useSelector((state) => state?.formSlice?.getAllForms);
//     // const mapStateToProps = (state) => {
//     //   get
//     // }

//     return (
//       <div>
//         {/* <ListForms
//           {...getAllForms}
//           getForms={this.getForms}
//           sort={this.state.sort}
//           handleSortChange={this.handleSortChange}
//         /> */}
//         <ManageCard
//           title="Manage Forms"
//           topbuttonName="Add Forms"
//           hideFilter={true}
//           showSort={false}
//         />

//       </div>
//     );
//   }
// }

// export default withNavBars(ManageForms);
