import React from "react";
import {
  Grid,
  Typography,
  Chip,
  useTheme,
  makeStyles,
  Box,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import styled from "styled-components";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { device } from "../../utils";
import { QuantityComp } from "../../screens/patientOrdering/quantity";
// import {steps1} from '../../Steps'
export const CardList = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 52px;
  padding-left: ${(props) => (props.nopadddingL ? "0" : "16px")};
  padding-right: 16px;
  background-color: ${(props) => props.bgclr};
  color: ${(props) => props.clr};
  padding-top: 32px;
  padding-bottom: 32px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin: 8px;
  @media ${device.mobileL} {
    margin: 8px;
  }
  @media ${device.mobileS} {
    margin: 4px;
  }
  @media ${device.mobileM} {
    margin: 4px;
  }
  @media ${device.mobileXS} {
    margin: 4px;
  }
  /* background: #fff; */
`;
export const Circle = styled.span`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${(props) => (props.isActive ? "#3bb213" : "#e0e0e0")};
  margin: 8px;
  @media ${device.tablet} {
    margin: 4px;
    width: 6px;
    height: 6px;
  }
  @media ${device.mobileL} {
    margin: 8px;
    width: 6px;
    height: 6px;
  }
  @media ${device.mobileS} {
    margin: 4px;
    width: 6px;
    height: 6px;
  }
  @media ${device.mobileM} {
    margin: 4px;
    width: 6px;
    height: 6px;
  }
  @media ${device.mobileXS} {
    margin: 4px;
    width: 6px;
    height: 6px;
  }
`;
export const FlexRow = styled.div`
  display: flex;
  align-items: center;
`;

export const NumberTag = styled.div`
  min-width: 20px;
  width: 6px;
  height: ${(props) => (props.isempty ? "25px" : "")};
  background-color: ${(props) => props.clr};
  color: ${(props) => props.bgcolor};
  padding: 10px 7px;
  border-radius: 0 8px 8px 0;
  box-sizing: border-box;
  margin-right: ${(props) => props.marginRight};
  @media ${device.tablet} {
    margin: 8px;
    font-size: 1rem;
  }
  @media ${device.mobileL} {
    margin: 8px;
    font-size: 1rem;
  }
  @media ${device.mobileS} {
    margin: 4px;
    font-size: 0.6rem;
  }
  @media ${device.mobileM} {
    margin: 4px;
    font-size: 0.6rem;
  }
  @media ${device.mobileXS} {
    margin: 4px;
    padding: 5px 7px;
    font-size: 0.6rem;
  }
`;

export const FlexRowMargin = styled.div`
  display: flex;
  align-items: center;
  font-size: 1rem;
  margin: 16px;
  @media ${device.tablet} {
    margin: 8px;
    font-size: 1rem;
  }
  @media ${device.mobileL} {
    margin: 8px;
    font-size: 0.8rem;
  }
  @media ${device.mobileS} {
    margin: 4px;
    font-size: 0.6rem;
  }
  @media ${device.mobileM} {
    margin: 4px;
    font-size: 0.6rem;
  }
  @media ${device.mobileXS} {
    margin: 4px;
    font-size: 0.6rem;
  }
`;

export const FlexRowMargin2 = styled.div`
  display: flex;
  align-items: center;
  margin: 0 4px;
`;
export const ActiveText = styled.p`
  color: ${(props) => (props.isActive ? "#3bb213" : "#e0e0e0")};
  font-family: TDS_regular;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.57;
`;

const useStyle = makeStyles((theme) => ({
  textResponsive: {
    // fontSize: "0.9rem !important",
    // maxWidth: "250px",
    marginRight: "16px !important",

    [theme.breakpoints.down("md")]: {
      fontSize: "0.8rem !important",
      marginRight: "16px !important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem !important",
      marginRight: "16px !important",
      maxWidth: "250px",
    },
  },
}));
export const Cards = (props) => {
  const {
    title = "",
    price = "",
    index = "",
    // icon = false,
    data,
    date,
    Image,
    // image,
    removeFromCart,
    mealName,
    Quantity,
    addMinusQuantity,
    onHandleChnage,
    // cartTour,
  } = props;
  const classes = useStyle();

  const theme = useTheme();
  
  
  return (
    // <TourProvider steps={steps1}>
    <Grid item lg={12} md={12} xs={12} sm={12} key={index}>
      <CardList nopadddingL={true} key={index} bgclr={"#ebf4ff"}>
        {/* <NumberTag
          clr={theme.palette.primary.main}
          bgcolor={theme.palette.background.common}
          // isempty={isempty ? true : false}
          marginRight={"16px"}
          style={{ flex: "0 0 auto" }}
        >
          <Typography variant="body2" className={classes.textResponsive}>{`${
            index + 1
          }`}</Typography>
        </NumberTag> */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "8px",
            width: "60px",
            height: "60px",
            flex: "0 0 auto",
          }}
        >
          {Image == "" ?

            <img
              src={
                "https://images.unsplash.com/photo-1587854692152-cbe660dbde88?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=869&q=80"
              }
              loading="lazy"
              alt="icon"
              style={{
                width: "100%",
                height: "100%",
                // background: "#e0e0e0",
                borderRadius: "8px",
                objectFit: "cover",
              }}
            />
            :

          <img
              src={Image}
              loading="lazy"
              alt="icon"
              style={{
                width: "100%",
                height: "100%",
                // background: "#e0e0e0",
                borderRadius: "8px",
                objectFit: "cover",
              }}
            />
        }

        </div>

        <Box style={{ flex: "1 1 auto" }}>
          {/* <FlexRowMargin> */}
          <Tooltip title={title} placement="top">
            <Typography
              variant="subtitle2"
              className={classes.textResponsive}
              style={{
                display: "-webkit-box",
                WebkitLineClamp: "1",
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                maxWidth: "500px",
              }}
            >
              {title}
            </Typography>
          </Tooltip>

          <Typography
            variant="subtitle2"
            className={classes.textResponsive}
            style={{
              display: "-webkit-box",
              WebkitLineClamp: "1",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              maxWidth: "200px",
              fontSize: "10px",
            }}
          >
            {date}
          </Typography>
          {/* <div
              style={{
                overflow: "auto",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Circle />
              <Chip color="primary" size="small" label={date} />
              {/* <Circle /> */}
          {/* <Chip color="primary" size="small" label={mealName} /> */}
          {/* </div> */}
          {/* </FlexRowMargin> */}
        </Box>

        <Box style={{ flex: "0 0 auto" }}>
          {/* <Chip color="primary" size="small" label={price} /> */}
          <QuantityComp
            Quantity={Quantity}
            addMinusQuantity={addMinusQuantity}
            data={data}
            onHandleChnage={onHandleChnage}
          />
        </Box>
        <Box style={{ flex: "0 0 auto", marginLeft: "8px" }}>
          <IconButton
            onClick={() => removeFromCart(data)}
            style={{ color: "red", padding: "unset" }}
          >
            <DeleteOutlineIcon />
          </IconButton>
        </Box>
      </CardList>
    </Grid>
  );
};
