import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';


export const DashboardCarousel = ({carouselData}) => {
    return (
        <div >
            <Carousel autoPlay infiniteLoop 
            showThumbs={false} 
            showStatus={false} selectedItem = {carouselData.length -1}
            renderIndicator={(onClickHandler, isSelected, index, label) => {
                const defStyle = { marginLeft: 20, fontSize: 50, color: "silver", cursor: "pointer", fontWeight: "bold" };
                const style = isSelected
                    ? { ...defStyle, color: "blue" }
                    : { ...defStyle };
                return (
                    <span
                    style={style}
                    onClick={onClickHandler}
                    onKeyDown={onClickHandler}
                    value={index}
                    key={index}
                    role="button"
                    tabIndex={0}
                    aria-label={`${label} ${index + 1}`}
                    >
                    {"."}
                    </span>
                );
                }}
            >
                {Array.isArray(carouselData) &&
                carouselData.map((row, index) => {
                    return(
                    <div key={index}>
                        <img src={`${"" + row.imgstr}`} />
                    </div>
                    );
                })}
            </Carousel>
        </div>
    );
};

