import { Dialog, DialogContent, Typography } from "@material-ui/core";
import React from "react";
import Successvector from "../../../assets/Success vector.svg";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../router/routes";
import { Grid } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

function ChangepwSuccess(props) {
  const history = useHistory();
  const handleClose = () => {
    history.push(Routes.login);
  };
  return (
    <div>
      <Dialog open={true} onClick={handleClose}>
        <DialogContent
          style={{
            height: "17rem",
            width: "16rem",
            padding: "0.5rem 0.5rem 1rem 0.5rem",
          }}
        >
          <Grid item style={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton size="small" onClose={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid
              container
              style={{
                display: "flex",
                justifyContent: "center",
                height: "8rem",
              }}
            >
              <img src={Successvector} alt="SuccessIcon" />
            </Grid>
            <Typography style={{ color: "#6dba3d", fontWeight: "500" }}>
              Successfully updated!
            </Typography>
            <Typography
              fontWeight="400"
              align="center"
              style={{ opacity: "60%", fontSize: "0.8em", width: "100%" }}
            >
              password has successfully changed, kindly logout and check your new password.
            </Typography>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ChangepwSuccess;
