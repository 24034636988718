/**
 * @author Prakash p
 */
import React from "react";
import {
  withStyles,
  Tab,
  Tabs,
  Typography,
  Box,
  LinearProgress,
  Divider,
} from "@material-ui/core";
// import { withRouter } from 'react-router-dom';
import PropTypes from "prop-types";

const styles = (theme) => ({
  root: {
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    width: "100%",
    "& .MuiTabs-indicator": {
      background: "#fff !important",
    },
    "& .MuiTab-labelIcon": {
      minHeight: 48,
    },
    "& .::-webkit-scrollbar": {
      height: 2,
    },
  },
  tabs: {
    textTransform: "capitalize",
    fontSize: 13,
    "& .MuiTab-wrapper": {
      display: "contents",
      "& svg": {
        margin: 6,
        fontSize: 17,
      },
    },
  },

  tabHead: {
    "& .MuiTypography-body1": {
      fontSize: 12,
    },
  },
  tabsContainer: {
    "& .MuiTabs-fixed": {
      [theme.breakpoints.down("xs")]: {
        // margin: "6px auto !important"
        overflowX: "auto !important",
      },
    },
    "& .MuiTabs-flexContainer": {
      background: "#fff 0% 0% no-repeat padding-box",
      border: "1px solid #110F471A",
      borderRadius: 10,
      [theme.breakpoints.down("xs")]: {
        background: "none",
        border: 0,
        borderRadius: 0,
      },
    },
    "& .MuiButtonBase-root": {
      flex: 1,
      background: "#fff 0% 0% no-repeat padding-box",
      maxWidth: "100%",
      borderRight: "1px solid #110F471A",
      [theme.breakpoints.down("xs")]: {
        minWidth: 200,
      },
      "&:last-child": {
        borderRight: 0,
      },
      // minHeight:35
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#4448C2 !important",
      height: 3,
    },
  },
  dashboardView: {
    "& .MuiTabs-flexContainer": {
      backgroundColor: "#F2EEF8",
      padding: theme.spacing(1),
      margin: theme.spacing(1),
      borderRadius: 10,
    },
    "& .MuiButtonBase-root": {
      flex: 1,
      borderRadius: 8,
      minHeight: 35,
    },
    "& .MuiTabs-indicator": {
      // backgroundColor:'#F2EEF8 !important',
      display: "none",
    },
  },
});

function TabPanel(props) {
  const { children, value, index, viewPortOverflow, ...other } = props;
  return (
    <div
      role="tabpanel"
      style={{
        height: viewPortOverflow && "calc(100% - 285px)",
        overflowY: viewPortOverflow && "auto",
      }}
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={props.padding ? 3 : ""}>
          <Typography id={"chid" + index}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
class TabComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props?.selected ?? 0,
    };
  }
  handleChange = (e, v) => {
    this.setState(
      {
        value: v,
      },
      () => {
        this.props?.onTabClick(v);
      }
    );
  };

  render() {
    const { classes, tabtitles, padding, viewPortOverflow } = this.props;

    const { value } = this.state;

    return (
      <div className={classes.root}>
        <div>
          {" "}
          <Tabs
            id={"Tabs"}
            value={value}
            onChange={(e, v) => this.handleChange(e, v)}
            className={
              this.props.dashboardView
                ? classes.dashboardView
                : classes.tabsContainer
            }
          >
            {tabtitles?.map((v, i) => {
              return (
                <Tab
                  id={"tab" + i}
                  key={i}
                  label={
                    <>
                      <Typography
                        variant={i === value ? "body1" : "body2"}
                        className={classes.tabHead}
                        style={{
                          color: i === value && "rgba(17, 15, 71, 1)",
                        }}
                      >
                        {v?.name()}
                        {value === i && this.props.loading && (
                          <LinearProgress style={{ width: "100%" }} />
                        )}
                      </Typography>
                    </>
                  }
                  icon={v?.icon ? v?.icon(i === value) : null}
                  // icon={v?.icon}
                  {...a11yProps(i)}
                  //   style={{ background: i === value && '#fff' }}
                  className={classes.tabs}
                />
              );
            })}
          </Tabs>
          {this.props.loadingFullWidth && (
            <LinearProgress className={classes.progressBar} />
          )}
          {this.props.divider && <Divider />}
        </div>
        {tabtitles?.map((v, i) => {
          return (
            <TabPanel
              id={"TabPanel" + i}
              key={i}
              value={value}
              index={i}
              padding={padding}
              viewPortOverflow={viewPortOverflow}
            >
              {this.props[v?.field]}
            </TabPanel>
          );
        })}
      </div>
    );
  }
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  loading: PropTypes.bool,
  onTabClick: PropTypes.func,
  dashboardView: PropTypes.bool,
  divider: PropTypes.bool,
};
export default withStyles(styles)(TabComponent);
