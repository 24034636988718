import {
  CardActionArea,
  Card,
  Typography,
  Grid,
  IconButton,
  Box,
} from "@material-ui/core";
import React, { Suspense } from "react";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import { TbReportAnalytics } from "react-icons/tb";
import { useTheme } from "@material-ui/core";

const reportList = [
  { id: 1, reportname: "Custom Report " },
  { id: 2, reportname: "Weekly Report" },
  { id: 3, reportname: "Today Report" },
  { id: 4, reportname: "Feedback Weekly Report" },
  { id: 5, reportname: "Feedback Date Wise Report" },
  { id: 6, reportname: "Meal Order Weekly Report" },
  { id: 7, reportname: "Meal Order Date Wise Report" },
];
const Report = () => {
  const WebData = React.lazy(() => import("../../components/webdatarocks"));
  const [showReport, setShowReport] = React.useState(false);
  const [reportDetails, setReportDetails] = React.useState({});
  const theme = useTheme();
  return (
    <>
      <div
        style={{
          height: !showReport ? "100%" : "",
          backgroundColor: "#ECF0F7",
        }}
      >
        {!showReport && (
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "8px 16px",
              background: theme.palette.background.table,
              height: "54px",
              borderBottom: " 1px solid #e0e0e0",
            }}
          >
            <Typography variant="body1">{"Reports"}</Typography>
          </Box>
        )}
        <Grid container>
          {!showReport &&
            reportList.map((e) => (
              <Grid item sm={6} xs={12} md={4} lg={4} xl={4}>
                <Card style={{ maxWidth: "98%", margin: "10px" }}>
                  <CardActionArea
                    style={{ padding: "15px" }}
                    onClick={() => {
                      setShowReport(true);
                      setReportDetails(e);
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>{e.reportname}</Typography>
                      <TbReportAnalytics size={24} />
                    </div>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
        </Grid>
        {showReport && (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "5px",
                backgroundColor: "white",
              }}
            >
              <div>
                <IconButton size="small" onClick={() => setShowReport(false)}>
                  <ArrowBackIosRoundedIcon />
                </IconButton>
              </div>
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <Typography>{reportDetails.reportname}</Typography>
              </div>
            </div>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                <Suspense fallback={<div>Loading...</div>}>
                  <WebData reportDetails={reportDetails} />
                </Suspense>
              </Grid>
            </Grid>
          </>
        )}
      </div>
    </>
  );
};

export default Report;
