import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Routes } from "./routes";

import {
  NotFound,
  Login,
  Organization,
  TaskAndDocument,
  PatientOrdering,
  GeneralMasterIPMO,
  PatientRegistration,
  TaskViewers,
  OrderConfig,
  KitchenOrdering,
  ExposeApi,
  Home,
  ManageForms,
  FormConfigurator,
  Registration,
  RegistraionViewer,
  DrugOrdering,
  Forgotpw,
  ItemMaster,
  Profile,
} from "./../screens";
import Repository from "../screens/repository";
import Tutorials from "../screens/Tutorials/index";
import PractitionerMaster from "../screens/practitionerMaster";
import { Drawer } from "../components";
import Reports from "../screens/reportViewer/report";
import { repolist } from "../utils";
import Dashboard from "../screens/dashboard/dashboard";
import PharmacyDashboard from "../screens/PharmacyDashboard";
import PriceHistory from "../screens/pricehistroy";
import {
  Changepw,
  ChangepwSuccess,
  Completed,
  Resetcode,
} from "../screens/passwordChange";
import Accounts from "../screens/accounts.js";
import Payment from "../screens/payment/payment";
import Completion from "../components/stripeForPayment/completion";
import { ExploreProducts } from "../componentsV2/exploreProduct/index.js";
import {  ViewCart } from "../screens/viewCart/index.js";

import MyCart from "../screens/ordersCart/index.js";
import {  MyOrders } from "../screens/myOrders/index.js";

const RouterApp = (props) => {
  return (
    <Router>
      <Switch>
        {/* Login Route */}
        <Route exact path={Routes.home}>
          <Home />
        </Route>
        
        <Route exact path={Routes.login}>
          <Login />
        </Route>

        <Route exact path={Routes.Completed}>
          <Completed />
        </Route>

        <Route exact path={Routes.ChangepwSuccess}>
          <ChangepwSuccess />
        </Route>

        <Route exact path={Routes.Dashboard}>
          <Drawer variant="2" sidePanellist={repolist}>
            <Dashboard />
          </Drawer>
        </Route>

        <Route exact path={Routes.PharmacyDashboard}>
          <Drawer variant="2" sidePanellist={repolist}>
            <PharmacyDashboard />
          </Drawer>
        </Route>

       <Route exact path={Routes.myorders}>
          <Drawer variant="2" sidePanellist={repolist}>
            <MyOrders/>
          </Drawer>
        </Route>
        
        <Route exact path={Routes.mycart}>
          <Drawer variant="2" sidePanellist={repolist}>
            <MyCart/>
          </Drawer>
        </Route>

        <Route exact path={Routes.viewcart}>
          <Drawer variant="2" sidePanellist={repolist}>
            <ViewCart />
          </Drawer>
        </Route>

        {/* Organization Route */}

        <Route exact path={Routes.Organization}>
          <Drawer variant="2" sidePanellist={repolist}>
            <Organization />
          </Drawer>
        </Route>

        {/* IDM management route */}
        <Route exact path={Routes.repositry}>
          <Drawer variant="2" sidePanellist={repolist}>
            <Repository />
          </Drawer>
        </Route>

        {/*  */}
        <Route exact path={Routes.practitioner}>
          <Drawer variant="2" sidePanellist={repolist}>
            <PractitionerMaster />
          </Drawer>
        </Route>

        {/* Task And Document */}
        <Route exact path={Routes.taskdocument}>
          <Drawer variant="2" sidePanellist={repolist}>
            <TaskAndDocument />
          </Drawer>
        </Route>

        {/* Tutorials */}
        <Route exact path={Routes.tutorials}>
          <Drawer variant="2" sidePanellist={repolist}>
            <Tutorials />
          </Drawer>
        </Route>


        {/* Report Viewer */}
        <Route exact path={Routes.reportConfig}>
          <Drawer variant="2" sidePanellist={repolist}>
            <Reports />
          </Drawer>
        </Route>

        {/* Meal Ordering */}
        <Route exact path={Routes.menu}>
          <Drawer variant="2" sidePanellist={repolist}>
            <DrugOrdering />
          </Drawer>
        </Route>

        {/* Genaral Master */}
        <Route exact path={Routes.generalMaster}>
          <Drawer variant="2" sidePanellist={repolist}>
            <GeneralMasterIPMO />
          </Drawer>
        </Route>

        {/* Visit Resgistration */}
        <Route exact path={Routes.patientRegistration}>
          <Drawer variant="2" sidePanellist={repolist}>
            <PatientRegistration />
          </Drawer>
        </Route>

        {/* Change password */}
        <Route exact path={Routes.Changepassword}>
          <Changepw />
        </Route>

        {/* Task Viewer */}
        <Route exact path={Routes.taskViewer}>
          <Drawer variant="2" sidePanellist={repolist}>
            <TaskViewers />
          </Drawer>
        </Route>

        {/* Order Configurator */}
        <Route exact path={Routes.orderConfigurator}>
          <Drawer variant="2" sidePanellist={repolist}>
            <OrderConfig />
          </Drawer>
        </Route>

        {/* Kitchen Meal Ordering */}
        <Route exact path={Routes.kitchenMenu}>
          <Drawer variant="2" sidePanellist={repolist}>
            <KitchenOrdering />
          </Drawer>
        </Route>

        <Route path={Routes.manageForms}>
          <Drawer variant="2" sidePanellist={repolist}>
            <ManageForms />
          </Drawer>
        </Route>
        <Route path={Routes.itemMaster}>
          <Drawer variant="2" sidePanellist={repolist}>
            <ItemMaster />
          </Drawer>
        </Route>

        <Route
          exact
          path={Routes.manage_form_detail}
        // component={FormConfigurator}
        >
          <Drawer variant="2" sidePanellist={repolist}>
            <FormConfigurator />
          </Drawer>
        </Route>
        <Route exact path={Routes.exploreproducts}>
          <ExploreProducts />
        </Route>

        <Route
          exact
          path={Routes.pricehistory}
        // component={FormConfigurator}
        >
          <Drawer variant="2" sidePanellist={repolist}>
            <PriceHistory />
          </Drawer>
        </Route>

        {/* Profile page  */}
        <Route exact path={Routes.Profile}>
          <Drawer variant="2" sidePanellist={repolist}>
            <Profile />
          </Drawer>
        </Route>

        {/* Mail verification */}
        <Route exact path={Routes.Resetcode}>
          <Resetcode />
        </Route>

        <Route
          exact
          path={Routes.registrationviewer}
        // component={FormConfigurator}
        >
          <Drawer variant="2" sidePanellist={repolist}>
            <RegistraionViewer />
          </Drawer>
        </Route>

        {/* For unknow/non-defined path */}
        <Route exact path="/updateMealOrderStatus" component={ExposeApi} />
        <Route exact path={Routes.registration}>
          <Registration logo={true} />
        </Route>
        <Route
          exact
          path={Routes.userregistration}
        // component={FormConfigurator}
        >
          <Drawer variant="2" sidePanellist={repolist}>
            <Registration logo={false} />
          </Drawer>
        </Route>
        <Route
          exact
          path={Routes.accounts}
        // component={FormConfigurator}
        >
          <Drawer variant="2" sidePanellist={repolist}>
            <Accounts logo={false} />
          </Drawer>
        </Route>
        <Route exact path={Routes.payment}>
          <Payment />
        </Route>
        <Route exact path={Routes.paymentSuccess}>
          <Completion />
        </Route>
        <Route exact path="*" component={NotFound} />
      </Switch>
    </Router>
  );
};

export default RouterApp;
