// please dont use this component it anyone watching this other than IPPC Project :
import React from "react";
import { Typography } from "@material-ui/core";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiToggleButton-root": {
      padding: "10px 15px",
      borderRadius: 10,
      minWidth: 70,
      backgroundColor: (props) =>
        props?.borderStyle ? "#fff" : theme?.palette?.primary?.light,
      borderColor: (props) =>
        props?.borderStyle
          ? theme?.palette?.primary?.main
          : theme?.palette?.primary?.light,
      [theme?.breakpoints?.down("xs")]: {
        margin: 10,
      },
      "& .MuiToggleButton-label": {
        color: (props) =>
          props?.borderStyle ? theme?.palette?.primary?.main : "#000",
        textTransform: "capitalize",
      },
      "&:hover": {
        backgroundColor: (props) =>
          props?.borderStyle ? "#fff" : theme?.palette?.primary?.main,
        borderColor: theme?.palette?.primary?.main,
        "& .MuiToggleButton-label": {
          color: (props) =>
            props?.borderStyle
              ? theme?.palette?.primary?.main
              : "#fff !important",
        },
      },
    },
    "& .MuiToggleButtonGroup-groupedHorizontal:not(:first-child)": {
      // borderLeft: '1px solid #F5F5F5'
    },
    "& .Mui-selected": {
      backgroundColor: `${theme?.palette?.primary?.main} !important`,
      borderColor: `${theme?.palette?.primary?.main} !important`,
      color: "#fff",
      fontSize: 14,
      "& .MuiToggleButton-label": {
        color: "#fff !important",
      },
    },
    "& .MuiToggleButton-root.Mui-selected:hover": {
      // backgroundColor:theme.palette.secondary.main
    },
    "& .MuiToggleButton-root.Mui-selected + .MuiToggleButton-root.Mui-selected":
      {
        borderLeft: `1px solid ${theme?.palette?.primary?.main}`,
      },
    "& .MuiToggleButtonGroup-root": {
      // flexWrap: 'wrap',
    },
  },
  btnGroup: {
    flexWrap: "wrap",
    paddingTop: "6px",
  },
  toggleBtn: {
    marginRight: theme?.spacing(1),
    marginBottom: theme?.spacing(1),
  },
}));

export const CustomIppcToggleButtonComponent = (props) => {
  const classes = useStyles(props);

  const [formats, setFormats] = React.useState(() => props?.isSelected ?? []);

  React.useEffect(() => {
    setFormats(props.isSelected);
  }, [props?.isSelected]);

  const handleFormat = (event, newFormats) => {
    // setFormats(newFormats);
    props?.onSelected(newFormats);
  };

  const getCustomLabel = (_) => {
    if (_?.label === "Verified") {
      return "Verified";
    } else if (_?.label === "Reject") {
      return "Rejected";
    } else if (_?.label === "Transfer") {
      return "Transfered";
    } else {
      return _?.label;
    }
  };

  const getCustomName = (_) => {
    if (_?.label === "Verified") {
      return "verify";
    } else if (_?.label === "Registered") {
      return "Register";
    } else {
      return _?.label;
    }
  };

  return (
    <div className={`${classes.root}`} id={props?.id}>
      <ToggleButtonGroup
        id="toogleBtnGrp"
        className={classes.btnGroup}
        value={formats}
        onChange={handleFormat}
        exclusive={props?.exclusive ?? false}
      >
        {props?.toggleButtonData &&
          props?.toggleButtonData.map((_, key) => {
            if (_.label) {
              if (
                _.value === 11 &&
                _.label === "Transfer" &&
                props?.transferStatus === "Pending"
              ) {
                return (
                  <ToggleButton
                    id={`${"tglBtn" + key}`}
                    disabled={true}
                    style={{ height: props?.btnHeight ?? "" }}
                    className={classes.toggleBtn}
                    key={key}
                    value={_.value}
                    aria-label={_.value}
                  >
                    <Typography variant="subtitle2">
                      {_.label && _.value === formats
                        ? getCustomLabel(_)
                        : getCustomName(_)}
                    </Typography>
                  </ToggleButton>
                );
              }
              if (
                (formats === 10 && _.label !== "Pending") ||
                (formats === 11 && _.label !== "Pending") ||
                (formats === 6 && _.label !== "Pending")
              ) {
                return (
                  <ToggleButton
                    id={`${"tglBtn" + key}`}
                    disabled={true}
                    style={{ height: props?.btnHeight ?? "" }}
                    className={classes.toggleBtn}
                    key={key}
                    value={_.value}
                    aria-label={_.value}
                  >
                    <Typography variant="subtitle2">
                      {_.label && _.value === formats
                        ? getCustomLabel(_)
                        : getCustomName(_)}
                    </Typography>
                  </ToggleButton>
                );
              }
              if (_.label === "Pending") {
                return <></>;
              }
              return (
                <ToggleButton
                  id={`${"tglBtn" + key}`}
                  disabled={props.disabled}
                  style={{ height: props?.btnHeight ?? "" }}
                  className={classes.toggleBtn}
                  key={key}
                  value={_.value}
                  aria-label={_.value}
                >
                  <Typography variant="subtitle2">
                    {_.label && _.value === formats
                      ? getCustomLabel(_)
                      : getCustomName(_)}
                  </Typography>
                </ToggleButton>
              );
            }
            return <></>;
          })}
      </ToggleButtonGroup>
    </div>
  );
};

CustomIppcToggleButtonComponent.propTypes = {
  toggleButtonData: PropTypes.array,
  isSelected: PropTypes.array,
  exclusive: PropTypes.bool,
  onSelected: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  btnHeight: PropTypes.string,
  transferStatus: PropTypes.string,
};
