import * as React from "react";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import Typography from "@material-ui/core/Typography";
import "rc-steps/assets/index.css";

import { styled } from "@material-ui/core/styles";

const StyledTimeLineItem = styled(TimelineItem)(({ theme }) => ({
  "&::before": {
    flex: 0,
  },
}));

export const TimeLine = (props) => {
  // console.log("Timeline",Timeline)
  const getTime = (epoche) => {
    let a = new Date(epoche * 1000);
    let timeStamp = `${a.toDateString()} , ${a.toLocaleTimeString()}`;
    return timeStamp;
  };
  return (
    <>
      <Timeline>
        {props.scenarioState?.map((state, index) => (
          <StyledTimeLineItem key={index}>
            <TimelineSeparator>
              {state._id === props.currantState.statesid ? (
                <TimelineDot color="info">
                  <ArticleOutlinedIcon variant="filled" />
                </TimelineDot>
              ) : (
                <TimelineDot variant="outlined">
                  <AccessTimeIcon color="disabled" />
                </TimelineDot>
              )}
              {!(state._id === props.scenarioState.at(-1)._id) && (
                <TimelineConnector />
              )}
            </TimelineSeparator>
            {!state.end ? (
              <TimelineContent style={{ marginTop: "10px" }}>
                <Typography
                  variant="body1"
                  component="span"
                  style={{ display: "block" }}
                >
                  {`Request Sent To "${state.states}"`}
                </Typography>
                {state?.statustime ? (
                  <Typography variant="caption">
                    {`${state?.userAction} action  held on ${getTime(
                      state?.statustime
                    )}`}
                  </Typography>
                ) : (
                  <Typography variant="caption">{`Action not yet performed!`}</Typography>
                )}
              </TimelineContent>
            ) : (
              <TimelineContent style={{ marginTop: "10px" }}>
                <Typography
                  variant="body1"
                  component="span"
                  style={{ display: "block" }}
                >
                  {`Request ${state.states}`}
                </Typography>
              </TimelineContent>
            )}
          </StyledTimeLineItem>
        ))}
      </Timeline>
    </>
  );
};
