import React from "react";
import Divider from "@material-ui/core/Divider";
import { useStyles } from "./style";

function StyledDivider(props) {
  const classes = useStyles();
  return <Divider classes={classes} {...props} />;
}

export default StyledDivider;

StyledDivider.defaultProps = {
  backgroundColor: "#B4BBC3",
};
