import * as React from "react";
import PropTypes from "prop-types";

const SlidingScaleIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13.166"
    height="13.166"
    viewBox="0 0 13.166 13.166"
  >
    <path
      id="icons8-ruler"
      d="M12.62,4.01a1.805,1.805,0,0,0-1.281.527l-6.8,6.8a1.818,1.818,0,0,0,0,2.561l2.748,2.748a1.818,1.818,0,0,0,2.561,0l6.8-6.8a1.819,1.819,0,0,0,0-2.561L13.9,4.537A1.805,1.805,0,0,0,12.62,4.01Zm0,.982a.821.821,0,0,1,.582.244L15.95,7.983a.815.815,0,0,1,0,1.164l-6.8,6.8a.816.816,0,0,1-1.164,0L5.236,13.2a.816.816,0,0,1,0-1.164l.582-.582.639.639a.494.494,0,1,0,.7-.7l-.639-.639.948-.948,1.3,1.3a.494.494,0,1,0,.7-.7l-1.3-1.3.948-.948L9.75,8.8a.494.494,0,1,0,.7-.7l-.639-.639.948-.948,1.3,1.3a.494.494,0,1,0,.7-.7l-1.3-1.3.582-.582A.821.821,0,0,1,12.62,4.991Z"
      transform="translate(-4.01 -4.01)"
      fill={props?.color}
    />
  </svg>
);

export default SlidingScaleIcon;

SlidingScaleIcon.defaultProps = {
  color: "white",
};

SlidingScaleIcon.propTypes = {
  color: PropTypes.string,
};
