import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  tooltip: {
    background: "#485C74",
    color: theme.palette.common.white,
    fontSize: 12,
  },
  arrow: {
    color: "#485C74",
  },
}));
