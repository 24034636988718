import React from 'react';
import {Grid} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root:{
        marginBottom:10
    }
}))

export const CardListLoading = (props) =>{

    const classes = useStyles(props);

    return(
        <Grid container direction="row" alignItems="center" className={classes.root}>
            <Grid item xs={12} sm={12} md={12}>
                <Skeleton variant="text"/>
            </Grid>
        </Grid>
    )
}