/**
 * The below function convert the normal array of object to
 * {label: "",value:1} pair which is suitable for React Select
 * component.
 */
import Axios from "axios";
export let ConvertToReactSelect = (data, valueKey, labelKey) => {
  if (!data || !data?.length) {
    return [];
  }

  return data.map((val) => {
    return {
      ...val,
      value: val[valueKey],
      label: val[labelKey],
    };
  });
};

/**
 * The below function convert the uploaded file to base64 file.
 */
export let ToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

/**
 * The below function capitalize the given string.
 */
export let CapitalizeString = (string) => {
  if (!string) {
    return string;
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * The below function convert the HEX code to RGBA
 */
export let ConvertHexToRGBA = (hex, opacity) => {
  if (hex) {
    let tempHex = hex.replace("#", "");
    let r = parseInt(tempHex.substring(0, 2), 16);
    let g = parseInt(tempHex.substring(2, 4), 16);
    let b = parseInt(tempHex.substring(4, 6), 16);

    return `rgba(${r},${g},${b},${opacity / 100})`;
  }
  return null;
};

/**
 * The below function will open an document node in a full screen.
 */
export let OpenFullScreen = (id) => {
  let elem = document.getElementById(id);
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.mozRequestFullScreen) {
    /* Firefox */
    elem.mozRequestFullScreen();
  } else if (elem.webkitRequestFullscreen) {
    /* Chrome, Safari & Opera */
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) {
    /* IE/Edge */
    elem.msRequestFullscreen();
  }
};

/**
 * The below function will close the full screen of an document
 * node.
 */
export let CloseFullScreen = (id) => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen();
  }
};

/**
 * The below function will scroll the page to the Top.
 */
export let ScrollToTop = () => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
};

// Check the versions
export let semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA ? versionA.split(/\./g) : ["0", "0", "0"];
  const versionsB = versionB ? versionB.split(/\./g) : ["0", "0", "0"];

  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

// Refresh the cache by clearing the cache and reload
export const refreshCacheAndReload = () => {
  if (caches) {
    // Service worker cache should be cleared with caches.delete()
    caches.keys().then((names) => {
      for (const name of names) {
        caches.delete(name);
      }
    });
  }
  // delete browser cache and hard reload
  window.location.reload(true);
};

// To get distance between two lattitude and longitude
export const distance = (lat1, lon1, lat2, lon2, unit) => {
  if (lat1 === lat2 && lon1 === lon2) {
    return 0;
  } else {
    var radlat1 = (Math.PI * lat1) / 180;
    var radlat2 = (Math.PI * lat2) / 180;
    var theta = lon1 - lon2;
    var radtheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit === "K") {
      dist = dist * 1.609344;
    }
    if (unit === "N") {
      dist = dist * 0.8684;
    }
    return dist;
  }
};

export const aqlQuery = {
  getFormData: (id, ticketId) => {
    return `
        for doc in forms
            filter doc.form_id == '${id}' && doc.is_active == true
            let master_form_type = (
                for mfty in master_form_types
                    filter mfty.is_active == true && mfty.form_type_id == doc.form_type_id
                    return {
                        id: mfty.form_type_id,
                        form_type: mfty.form_type_name
                    }
            )
            let form_detail = {
                id: doc.form_id,
                name: doc.form_name,
                is_active: doc.is_active,
                created_at: doc.created_at,
                updated_at: doc.updated_at,
                form_type: doc.form_type_id,
                master_form_type: master_form_type[0],
                mapped_diseases: (
                    for mapForm in rl_disease_form
                        filter mapForm.is_active == true && mapForm.form_id == doc.form_id
                            return FIRST(for mapForm_disease in master_disease
                                filter mapForm_disease.is_active == true && mapForm_disease.disease_id == mapForm.disease_id
                                    return {
                                        disease_name: mapForm_disease.disease_name,
                                        disease_definition: mapForm_disease.disease_definition
                                    }
                            )
                )
            }
            let answers = (
                for entry in form_entries
                    filter entry.form_id=="${id}" && entry.ticketId == "${ticketId}"
                    return {
                        bulkAnswers: entry,
                        answers:    merge(
                            for ans in entry.answers
                                let qnDetail = first(
                                    for qn in questions
                                        filter qn.is_active == true && qn.question_id == ans.question_id
                                        return {
                                            tag: qn.tag,
                                            file: qn.file,
                                            editorState: qn.editorState,
                                            embedFields: qn.embedFields,
                                            ui_component: qn.ui_component
                                        }
                                )

                                return {
                                  [ans.question_id]:merge(ans.answer,qnDetail)
                                }
                        )
                    }
            )

            let tree = (for vertices , edges , paths in OUTBOUND CONCAT("forms/" ,doc.form_id ) edge_form_section
                filter vertices.parent_section_id == null && vertices.is_active == true
                sort vertices.priority ASC
                return {
                    id: vertices.section_id,
                    form_id: vertices.form_id,
                    is_form: vertices.is_form,
                    section_name : vertices.section_name,
                    description: vertices.description,
                    formLayoutType:vertices.formLayoutType,
                    priority: vertices.priority,
                    is_skippable: vertices.is_skippable ? vertices.is_skippable :  false,
                    is_smart_skip: vertices.is_smart_skip ? vertices.is_smart_skip : false,
                    parent_section_id: vertices.parent_section_id,
                    is_investigator_section: vertices.is_investigator_section,
                    section_type: vertices.section_type,
                    level: 1,
                    is_active: vertices.is_active,
                    created_by: vertices.created_at,
                    updated_by: vertices.updated_at,
                    framed_questions: (
                        for qn in questions
                            filter qn.section_id == vertices.section_id && qn.is_active == true
                            sort qn.priority ASC
                            return {
                                id : qn.question_id,
                                priority: IS_NULL(qn.priority) ? 1 : qn.priority,
                                ui_component: qn.ui_component,
                                is_required: qn.is_required,
                                question: qn.question_name,
                                question_options: qn.options,
                                tag: qn.tag,
                                QueryId: qn.QueryId,
                                file: qn.file,
                                editorState: qn.editorState,
                                embedFields: qn.embedFields,
                                table_scheme:qn.table_scheme,
                                formula:qn.formula,
                                validation:qn.validation,
                                has_dependency_qn: qn.has_dependency_qn,
                                dependencies: qn.dependency ? qn.dependency : [],
                                dependent: FIRST(
                                    for dep_qn in dependency_questions
                                        filter dep_qn.is_active == true && dep_qn.target_question_id == qn.question_id
                                        return dep_qn
                                )
                            }
                    ),
                    dependent: FIRST(
                        for dep_qn in dependency_questions
                            filter dep_qn.is_active == true
                            filter dep_qn.target_form_id == vertices.section_id || dep_qn.target_section_id == vertices.section_id
                            return dep_qn
                    ),
                    child_sections: (
                        for childSection1 in section
                        filter childSection1.parent_section_id == vertices.section_id && childSection1.is_active == true
                            sort childSection1.priority ASC
                            return {
                                id: childSection1.section_id,
                                form_id: childSection1.form_id,
                                is_form: childSection1.is_form,
                                section_name : childSection1.section_name,
                                description: childSection1.description,
                                formLayoutType:childSection1.formLayoutType,
                                priority: childSection1.priority,
                                is_skippable: childSection1.is_skippable ? childSection1.is_skippable :  false,
                                is_smart_skip: childSection1.is_smart_skip ? childSection1.is_smart_skip : false,
                                parent_section_id: childSection1.parent_section_id,
                                is_investigator_section: childSection1.is_investigator_section,
                                section_type: childSection1.section_type,
                                level: 2,
                                is_active: childSection1.is_active,
                                created_by: childSection1.created_at,
                                updated_by: childSection1.updated_at,
                                framed_questions: (
                                    for qn1 in questions
                                        filter qn1.section_id == childSection1.section_id && qn1.is_active == true
                                        sort qn1.priority ASC
                                        return {
                                            id : qn1.question_id,
                                            priority: IS_NULL(qn1.priority) ? 1 : qn1.priority,
                                            ui_component: qn1.ui_component,
                                            is_required: qn1.is_required,
                                            question: qn1.question_name,
                                            question_options: qn1.options,
                                            tag: qn1.tag,
                                            QueryId: qn1.QueryId,
                                            file: qn1.file,
                                            editorState: qn1.editorState,
                                            embedFields: qn1.embedFields,
                                            table_scheme:qn1.table_scheme,
                                            formula:qn1.formula,
                                            validation:qn1.validation,
                                            has_dependency_qn: qn1.has_dependency_qn,
                                            dependencies: qn1.dependency ? qn1.dependency : [],
                                            dependent: FIRST(
                                                for dep_qn in dependency_questions
                                                    filter dep_qn.is_active == true && dep_qn.target_question_id == qn1.question_id
                                                    return dep_qn
                                            )
                                        }
                                ),
                                dependent: FIRST(
                                    for dep_qn in dependency_questions
                                        filter dep_qn.is_active == true
                                        filter dep_qn.target_form_id == childSection1.section_id || dep_qn.target_section_id == childSection1.section_id
                                        return dep_qn
                                ),
                                child_sections: (
                                    for childSection2 in section
                                    filter childSection2.parent_section_id == childSection1.section_id && childSection2.is_active == true
                                    sort childSection2.priority ASC
                                        return {
                                            id: childSection2.section_id,
                                            form_id: childSection2.form_id,
                                            is_form: childSection2.is_form,
                                            section_name : childSection2.section_name,
                                            description: childSection2.description,
                                            formLayoutType:childSection2.formLayoutType,
                                            priority: childSection2.priority,
                                            is_skippable: childSection2.is_skippable ? childSection2.is_skippable :  false,
                                            is_smart_skip: childSection2.is_smart_skip ? childSection2.is_smart_skip : false,
                                            parent_section_id: childSection2.parent_section_id,
                                            is_investigator_section: childSection2.is_investigator_section,
                                            section_type: childSection2.section_type,
                                            level: 3,
                                            is_active: childSection2.is_active,
                                            created_by: childSection2.created_at,
                                            updated_by: childSection2.updated_at,
                                            framed_questions: (
                                                for qn2 in questions
                                                    filter qn2.section_id == childSection2.section_id && qn2.is_active == true
                                                    sort qn2.priority ASC
                                                    return {
                                                        id : qn2.question_id,
                                                        priority: IS_NULL(qn2.priority) ? 1 : qn2.priority,
                                                        ui_component: qn2.ui_component,
                                                        is_required: qn2.is_required,
                                                        question: qn2.question_name,
                                                        question_options: qn2.options,
                                                        tag: qn2.tag,
                                                        QueryId: qn2.QueryId,
                                                        file: qn2.file,
                                                        editorState: qn2.editorState,
                                                        embedFields: qn2.embedFields,
                                                        table_scheme:qn2.table_scheme,
                                                        formula:qn2.formula,
                                                        validation:qn2.validation,
                                                        has_dependency_qn: qn2.has_dependency_qn,
                                                        dependencies: qn2.dependency ? qn2.dependency : [],
                                                        dependent: FIRST(
                                                            for dep_qn in dependency_questions
                                                                filter dep_qn.is_active == true && dep_qn.target_question_id == qn2.question_id
                                                                return dep_qn
                                                        )
                                                    }
                                            ),
                                            dependent: FIRST(
                                                for dep_qn in dependency_questions
                                                    filter dep_qn.is_active == true
                                                    filter dep_qn.target_form_id == childSection2.section_id || dep_qn.target_section_id == childSection2.section_id
                                                    return dep_qn
                                            ),
                                            child_sections: (
                                                for childSection3 in section
                                                filter childSection3.parent_section_id == childSection2.section_id && childSection3.is_active == true
                                                sort childSection3.priority ASC
                                                    return  {
                                                        id: childSection3.section_id,
                                                        form_id: childSection3.form_id,
                                                        is_form: childSection3.is_form,
                                                        section_name : childSection3.section_name,
                                                        description: childSection3.description,
                                                        formLayoutType:childSection3.formLayoutType,
                                                        priority: childSection3.priority,
                                                        is_skippable: childSection3.is_skippable ? childSection3.is_skippable :  false,
                                                        is_smart_skip: childSection3.is_smart_skip ? childSection3.is_smart_skip : false,
                                                        parent_section_id: childSection3.parent_section_id,
                                                        is_investigator_section: childSection3.is_investigator_section,
                                                        section_type: childSection3.section_type,
                                                        level: 4,
                                                        is_active: childSection3.is_active,
                                                        created_by: childSection3.created_at,
                                                        updated_by: childSection3.updated_at,
                                                        framed_questions: (
                                                            for qn3 in questions
                                                                filter qn3.section_id == childSection3.section_id && qn3.is_active == true
                                                                sort qn3.priority ASC
                                                                return {
                                                                    id : qn3.question_id,
                                                                    priority: IS_NULL(qn3.priority) ? 1 : qn3.priority,
                                                                    ui_component: qn3.ui_component,
                                                                    is_required: qn3.is_required,
                                                                    question: qn3.question_name,
                                                                    question_options: qn3.options,
                                                                    tag: qn3.tag,
                                                                    QueryId: qn3.QueryId,
                                                                    file: qn3.file,
                                                                    editorState: qn3.editorState,
                                                                    embedFields: qn3.embedFields,
                                                                    table_scheme:qn3.table_scheme,
                                                                    formula:qn3.formula,
                                                                    validation:qn3.validation,
                                                                    has_dependency_qn: qn3.has_dependency_qn,
                                                                    dependencies: qn3.dependency ? qn3.dependency : [],
                                                                    dependent: FIRST(
                                                                        for dep_qn in dependency_questions
                                                                            filter dep_qn.is_active == true && dep_qn.target_question_id == qn3.question_id
                                                                            return dep_qn
                                                                    )
                                                                }
                                                        ),
                                                        dependent: FIRST(
                                                            for dep_qn in dependency_questions
                                                                filter dep_qn.is_active == true
                                                                filter dep_qn.target_form_id == childSection3.section_id || dep_qn.target_section_id == childSection3.section_id
                                                                return dep_qn
                                                        ),
                                                        child_sections:[]
                                                    }
                                            )
                                        }
                                )
                            }
                    )
            })

            let form = (for tr in tree
                return tr.is_form == true ? merge({expanded:[]} ,tr) : (
                    for tr1 in tr.child_sections
                        return tr1.is_form == true ? merge({expanded:[tr.id]}, tr1) : (
                            for tr2 in tr1.child_sections
                                return tr2.is_form == true ? merge({expanded:[tr.id,tr1.id]},tr2): (
                                    for tr3 in tr2.child_sections
                                        return tr3.is_form == true ? merge({expanded:[tr.id,tr1.id,tr2.id]},tr3) : (
                                            for tr4 in tr3.child_sections
                                                return merge({expanded: [tr.id,tr1.id,tr2.id,tr2.id]},tr4)
                            )
                        )
                    )
                )
            )

            let statusArray = (
                for status in master_entry_status
                filter status.is_active == true && status.status_id in []
                sort status.status_id asc
                    return {
                        value : status.status_id,
                        label : status.status_name
                    }
            )

    return {
        form_detail: form_detail,
        tree: tree,
        form: form[****],
        bulkAnswers: answers[0] ? answers[0].bulkAnswers ? answers[0].bulkAnswers : {}  : {},
        answers: answers[0] ? answers[0].answers ? answers[0].answers : {}  : {},
        patientDetail: answers[0] ? answers[0].patientDetail ? answers[0].patientDetail : {}  : {},
        statusArray: statusArray
    }`;
  },
};

export const AxiosCall = async (method, url, params, header) => {
  try {
    let config = {
      method: method,
      url: `${url}`,
      headers: {
        "Content-Type": "application/json",
        ...(header && { ...header }),
      },
      data: params,
    };
    let response = await Axios(config);
    return response.data;
  } catch (error) {
    return error;
  }
};