import {
  Box,
  Button,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import React from "react";
import { TbSquareDot } from "react-icons/tb";
// import EditIcon from "@material-ui/icons/Edit";
import parse from "html-react-parser";
import IconButton from "@material-ui/core/IconButton";
import TimelineIcon from "@material-ui/icons/Timeline";
const useStyles = makeStyles((theme) => ({
  root: {
    // maxWidth: 345,
    boxShadow: "unset",
    border: "1px solid #dbdbdb",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  header: {
    background: "#F3F4F7",
  },
  card: {
    boxShadow: "unset",
    border: "1px solid #dbdbdb",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardHeader: {
    display: "flex",
    alignItems: "center",
    minHeight: "150px",
    flex: "0 0 auto",
    padding: theme.spacing(2),
    background: "#f0f7ff",
    borderBottom: "1px solid #dbdbdb",
    "& .cardHeader-image": { flex: "0 0 auto", marginRight: "16px" },
    "& .cardHeader-content": { flex: "1 1 auto" },
    "& .cardHeader-action": { flex: "0 0 auto" },
  },
  cardContent: {
    padding: theme.spacing(2),
    flex: "1 1 auto",
    // minHeight: "80px",
    "& .cardContent-root": {},
  },
  cardAction: {
    flex: "0 0 auto",
    display: "flex",
    padding: "8px",
    alignItems: "center",
    justifyContent: "flex-end",
    "& .cardAction-content": {},
  },
  cardImage: {
    width: "50px",
    height: "50px",
    display: "flex",
    overflow: "hidden",
    position: "relative",
    fontSize: "1.25rem",
    alignItems: "center",
    flexShrink: "0",
    lineHeight: "1",
    userSelect: "none",
    borderRadius: theme.spacing(1),
    justifyContent: "center",
    marginRight: "16px",
  },
  divStyle: {
    display: "flex",
    alignItems: "center",
  },
  successButton: {
    // background: theme.palette.success.main,

    "&.MuiButton-contained": {
      background: theme.palette.success.main,
      color: theme.palette.common.white,
    },
    "&.MuiButton-contained:hover": {
      background: theme.palette.success.dark,
    },
  },
}));

export const FoodCard = (props) => {
  const {
    index,
    title,
    quantity,
    // subTitle,
    image = "https://images.unsplash.com/photo-1587854692152-cbe660dbde88?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=869&q=80",
    content,
    status,
    openModel,
    mealType,
    data,
    feedBack,
    description,
    onTimeLineClicked,
  } = props;
  const classes = useStyles();
  // const getDateTOString = (value) => {
  //   const d = `${value}T00:00:00Z`;
  //   const date = new Date(d);
  //   return date.toDateString();
  // };

  return (
    <Grid item xs={12} sm={12} md={6} lg={3} key={`${index}`}>
      <Paper className={classes.card}>
        <Box className={classes.cardHeader}>
          {/* <Box className={"cardHeader-image"}> */}
          <Box className={classes.cardImage}>
            <img
              src={image}
              alt="icon"
              style={{
                width: "100%",
                height: "100%",
                // background: "#e0e0e0",
                borderRadius: "8px",
                objectFit: "cover",
              }}
            />
          </Box>
          <Box className={"cardHeader-content"}>
            <Typography variant="body2" display="block">
              {title}
            </Typography>
            <Typography variant="body2" color="textSecondary" display="block">
              {mealType}
            </Typography>
          </Box>
          <Box className={"cardHeader-action"}>
            {status && (
              <Button size="small" disabled>
                {status?.toLowerCase() === "completed" ? "Delivered" : status}
              </Button>
            )}
          </Box>
        </Box>
        <Box className={classes.cardContent}>
          <Box className={"cardContent-root"}>
            <Typography variant="body2" style={{ margin: "0" }}>
              {/* {parse(description)} */}
            </Typography>
            <Typography variant="body2" style={{ margin: "0" }}>
              {`Quantity : ${quantity}`}
            </Typography>
            {/* {content.trim().length > 0 ? (
              content.split(",").map((l) => (
                <Box className={classes.divStyle}>
                  <TbSquareDot fontSize="small" style={{ flex: "0 0 auto" }} />
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    display="block"
                    style={{ flex: "1 1 auto", marginLeft: "4px" }}
                  >
                    {l}
                  </Typography>
                </Box>
              ))
            ) : (
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  // justifyContent: "center",
                  height: "100%",
                }}
              >
                <Typography
                  variant="body2"
                  color="textSecondary"
                  display="block"
                >
                  {"No desserts"}
                </Typography>
              </Box>
            )} */}
          </Box>
        </Box>
        <Box className={classes.cardAction}>
          {/* <Typography variant="body2" color="textSecondary" display="block">
            {getDateTOString(subTitle)}
          </Typography> */}

          {/* {status?.toLowerCase() === "delivered" ? (
            <Box className={"cardAction-content"}>
              {feedBack?.length > 0 ? (
                <Button
                  size="small"
                  variant="contained"
                  color="success"
                  style={{ textTransform: "capitalize" }}
                  disabled
                  // startIcon={<EditIcon size="small" />}
                  onClick={() => openModel(data, true)}
                  className={classes.successButton}
                >
                  {"Thank you for your feedback"}
                </Button>
              ) : (
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={() => openModel(data, false)}
                >
                  {"Rate Your Experience"}
                </Button>
              )}
            </Box>
          ) : (
            <Box className={"cardAction-content"}>
              <Button
                size="small"
                variant="contained"
                color="primary"
                disabled
              >
                {"Rate Your Experience"}
              </Button>
            </Box>
          )} */}
          {/*
          <IconButton onClick={onTimeLineClicked}>
            <TimelineIcon size="small" />
          </IconButton> */}
        </Box>
      </Paper>
    </Grid>
  );
};
