import { Paper } from "@material-ui/core";

import React, { Component } from "react";
// import { translate } from "react-i18next";
import { withRouter } from "react-router";
import { withAllContexts } from "../../HOCs";
import Reports from "./report";

class ReportViewer extends Component {
  render() {
    return (
      <>
        <Paper
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "#F3F4F7",
            padding: "20px",
          }}
          elevation={3}
        >
          <Paper
            style={{
              width: "100%",
              height: "100%",
              padding: "20px",
            }}
            elevation={3}
          >
            <Reports />
          </Paper>
        </Paper>
      </>
    );
  }
}

export default withRouter(withAllContexts(ReportViewer));
