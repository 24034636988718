import React from 'react'
import { makeStyles, SvgIcon, } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    cls1: (props) => ({
        fill: '#110f47'
    })
}))

export function Drag(props) {
    const classes = useStyles(props)
    return (
        <SvgIcon viewBox="0 0 9.62 14.921" style={{fontSize:'1rem'}}>
            <g transform="translate(-290 -341)"><path className={classes.cls1} d="M10.829,3A.829.829,0,0,0,10,3.829V5.487a.829.829,0,0,0,.829.829h1.658a.829.829,0,0,0,.829-.829V3.829A.829.829,0,0,0,12.487,3Zm0,5.8A.829.829,0,0,0,10,9.631v1.658a.829.829,0,0,0,.829.829h1.658a.829.829,0,0,0,.829-.829V9.631a.829.829,0,0,0-.829-.829Zm0,5.8a.829.829,0,0,0-.829.829v1.658a.829.829,0,0,0,.829.829h1.658a.829.829,0,0,0,.829-.829V15.434a.829.829,0,0,0-.829-.829Z" transform="translate(280 338)"/><path class="a" d="M10.829,3A.829.829,0,0,0,10,3.829V5.487a.829.829,0,0,0,.829.829h1.658a.829.829,0,0,0,.829-.829V3.829A.829.829,0,0,0,12.487,3Zm0,5.8A.829.829,0,0,0,10,9.631v1.658a.829.829,0,0,0,.829.829h1.658a.829.829,0,0,0,.829-.829V9.631a.829.829,0,0,0-.829-.829Zm0,5.8a.829.829,0,0,0-.829.829v1.658a.829.829,0,0,0,.829.829h1.658a.829.829,0,0,0,.829-.829V15.434a.829.829,0,0,0-.829-.829Z" transform="translate(286.305 338)"/></g>
        </SvgIcon>
    )
}
