export const steps = [
  {
    selector: '[data_tour="step_1"]',
    // content:<p> You can get each date menu by<br/> selecting dates in this calendar</p>
    content: process.env.REACT_APP_data_tour_step_1,
  },
  {
    selector: '[data_tour="step_2"]',
    // content:<p>Select your meal type </p>
    content: process.env.REACT_APP_data_tour_step_2,
  },
  {
    selector: '[data_tour="step_3"]',
    // content:<p>Select your meal type </p>
    content: process.env.REACT_APP_data_tour_step_3,
  },
  {
    selector: '[data_tour="step_4_cart_1"]',
    // content:<p> It will add item<br/> to the cart</p>
    content: process.env.REACT_APP_data_tour_step_4_cart_1,
  },
  {
    selector: '[data_tour="step_5"]',
    // content:<p> Shows list of meals<br/> that are added to cart</p>
    content: process.env.REACT_APP_data_tour_step_5,
  },
];
// export const steps1 = [
//   {
//     selector:'[data_tour = "step1_1"]',
//     content: process.env.REACT_APP_data_tour_step1_1
//   },
//   {
//     selector:'[data_tour="step1_2"]',
//     content: process.env.REACT_APP_data_tour_step1_2
//   },
//   {
//     selector:'[data_tour="step1_3"]',
//     content: process.env.REACT_APP_data_tour_step1_3
//   },
// ]
