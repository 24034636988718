import { Grid } from "@material-ui/core";
import React from "react";
import { PatientCard } from "../../components";
import StarsIcon from "@material-ui/icons/Stars";
import HotelIcon from "@material-ui/icons/Hotel";
import PersonIcon from "@material-ui/icons/Person";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
export const ListOfCards = (props) => {
  const { patientList, updateSelectedPatient } = props;

  const getFullName = (l) => {
    return `${l.BedNumber} `;
  };

  const onCardClicked = (l) => {
    updateSelectedPatient(l);
  };
  return (
    <>
      <Grid container spacing={3}>
        {patientList?.map((item, index) => (
          <PatientCard
            onCardClicked={onCardClicked}
            data={item}
            variant="1"
            key={`${index}`}
            field1={{ title: getFullName(item) }}
            field2={{ title: item?.ReportingOrganization?.label }}
            field3={{
              title: item?.phonenumber,
              icon: <PersonIcon fontSize="small" />,
            }}
            field4={{
              title: item?.email,
              icon: <EmailOutlinedIcon fontSize="small" />,
            }}
            // field5={{
            //   title: item?.PatientCategory?.display,
            //   icon: <StarsIcon fontSize="small" />,
            // }}
          />
        ))}
      </Grid>
    </>
  );
};
