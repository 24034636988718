import * as React from "react";
import PropTypes from "prop-types";

const DollarOutlineIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="23.229" height="22.007" viewBox="0 0 23.229 22.007">
    <g id="Group_90507" data-name="Group 90507" transform="translate(954.115 23133.504)">
      <path id="icons8-online-payment" d="M8.362,6a3.348,3.348,0,0,0-1.6.406h0A3.378,3.378,0,0,0,5.406,7.76s0,0,0,0a3.341,3.341,0,0,0-.254.6s0,0,0,0c-.033.1-.061.211-.084.32,0,0,0,0,0,0A3.5,3.5,0,0,0,5,9.362V24.644a3.362,3.362,0,0,0,3.362,3.362h16.5a3.362,3.362,0,0,0,3.362-3.362V9.362a3.5,3.5,0,0,0-.068-.677s0,0,0,0c-.022-.109-.051-.215-.084-.32,0,0,0,0,0,0a3.341,3.341,0,0,0-.254-.6s0,0,0,0a3.378,3.378,0,0,0-1.354-1.354h0a3.341,3.341,0,0,0-.6-.254h0c-.1-.033-.211-.061-.32-.084h0a3.361,3.361,0,0,0-.333-.05h0A3.373,3.373,0,0,0,24.867,6Zm0,1.834a.918.918,0,1,1-.185.019A.917.917,0,0,1,8.362,7.834Zm3.056,0a.917.917,0,1,1-.917.917A.917.917,0,0,1,11.419,7.834Zm3.668,0h9.781a.917.917,0,1,1,0,1.834H15.086a.917.917,0,0,1,0-1.834ZM6.834,11.5H26.4V24.644a1.528,1.528,0,0,1-1.528,1.528H8.362a1.528,1.528,0,0,1-1.528-1.528ZM16.6,13.325a.764.764,0,0,0-.752.775V14.6a2.5,2.5,0,0,0,.229,5h1.375a.993.993,0,1,1,0,1.987H15.56a.988.988,0,0,1-.873-.519.765.765,0,1,0-1.342.733,2.527,2.527,0,0,0,2.215,1.315h.29v.43a.764.764,0,1,0,1.528,0v-.43h.076a2.522,2.522,0,1,0,0-5.043H16.08a.993.993,0,1,1,0-1.987h.411a.764.764,0,0,0,.246,0h.321a.986.986,0,0,1,.873.518.764.764,0,1,0,1.342-.731,2.5,2.5,0,0,0-1.894-1.226V14.1a.764.764,0,0,0-.776-.775Z"
       transform="translate(-959.115 -23139.504)"
        fill={props?.color}/>
    </g>
  </svg>
);

export default DollarOutlineIcon;

DollarOutlineIcon.defaultProps = {
  color: "#2a3c50",
};

DollarOutlineIcon.propTypes = {
  color: PropTypes.string,
};
