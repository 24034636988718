import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";

// const headCellStyles = {
//   root: (props) => ({
//     padding: "10px 16px",
//     background: "#ECF0F7",
//     "&:first-child": {
//       paddingLeft: "30px",
//     },
//     "&:last-child": {
//       paddingRight: "30px",
//     },
//   }),
// };

// const rowCellStyles = {
//   root: (props) => ({
//     padding: "10px 16px",
//     borderBottom: `1px solid #DEE5EC`,
//     "&:first-child": {
//       paddingLeft: "30px",
//     },
//     "&:last-child": {
//       paddingRight: "30px",
//     },
//   }),
// };

// isHeadCell ? headCellStyles : rowCellStyles
const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    padding: "10px 16px",
    borderBottom: `1px solid #DEE5EC`,
    "&:first-child": {
      paddingLeft: "30px",
    },
    "&:last-child": {
      paddingRight: "30px",
    },
    ...(props.isHeadCell && {
      background: "#ECF0F7",
      borderBottom: `1px solid #DEE5EC`,
    }),
  }),
}));

function StyledTableCell(props) {
  const theme = useTheme();
  const classes = useStyles(props);
  return <TableCell style={{backgroundColor : props?.isHeadCell ?theme.palette.background.tableHeader : theme.palette.background.table}} classes={classes} {...props} />;
}

export default StyledTableCell;
