import React, { useState } from "react";
import {
  TextField,
  Button,
  Grid,
  makeStyles,
  Typography,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { actions } from "central_pharma_binder";
import { useSelector } from "react-redux";
import { Autocomplete } from "@material-ui/lab";
import { useContext } from "react";
import { AlertContext, BackdropContext } from "../../contexts";
import { calculatedtax } from ".";
import { dateToEpoch, getYesterdayEpoch } from "../../utils";
import { useForm } from "react-hook-form";
// import { DevTool } from "@hookform/devtools";
var bounce;
function debounce(func, date, id) {
  clearInterval(bounce);
  bounce = setTimeout(() => {
    return func(date, id);
  }, 1000);
}
const useStyles = (open) =>
  makeStyles((theme) => ({
    TopGrid: {
      padding: "20px",
    },
    TableCell: {
      // display: open ? "" : "none"
      fontSize: "12px",
    },
    input: {
      "& .MuiOutlinedInput-inputMarginDense": {
        fontSize: "12px",
      },
      "& .MuiFormHelperText-contained": {
        fontSize: "8px",
      },
    },
    btn: {
      fontSize: "12px",
      backgroundColor: theme.palette.primary.main,
      color: "white",
      marginBottom: "12px",
    },
  }));

function Addnewdrugprice(props) {
  const selectors = useSelector((state) => state?.DrugPriceslice?.getalldrugs);
  const dispatch = useDispatch();
  const backdrop = useContext(BackdropContext);
  const alert = useContext(AlertContext);
  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // const [serachValue, setSearchValue] = React.useState("");

  const form = useForm({
    defaultValues: {
      price: "",
      effectivefrom: "",
      discount: "",
    },
    mode: "onSubmit",
  });

  const { register, handleSubmit, formState, control } = form;
  const { errors: error } = formState;
  // console.log(error, formState);
  React.useEffect(() => {
    // dispatch(actions.GET_ALL_DRUGS());
  }, []);
  const classes = useStyles();
  async function validatedate(date, id) {
    if (date) {
      let body = {
        date: dateToEpoch(date),
        drugid: id,
      };
      // console.log({ body });
      const data = await dispatch(actions.Validation(body));

      return data?.payload?.data?.status || data?.payload?.data?.message;
    }
  }

  const handlesubmitbycode = async (data) => {
    // console.log(JSON.stringify(data));
    //  ;
    try {
      backdrop.setBackDrop({
        ...backdrop,
        open: true,
        message: "Inserting the drug price",
      });
      const checkdrug = await dispatch(
        actions.GET_DRUGSPRICE_ID(props?.data?._id)
      );
      // console.log({ checkdrug });
      let doc = {
        price: Number(data?.price ?? 0),
        effectivefrom: dateToEpoch(data?.effectivefrom),
        effectiveto: 0,
        version: 1,
        tax: 0,
        discount: Number(data?.discount ?? 0),
        drugid: props?.data?._id,
        calculatedprice: Number(
          calculatedtax(data?.price, data?.discount ?? 0)
        ),
        is_active: true,
      };
      if (checkdrug?.payload?.data?.result?.length === 0) {
      } else if (checkdrug?.payload?.data?.result.length === 1) {
        let data = checkdrug?.payload?.data?.result?.[0];
        let updateolddrug = {
          ...data,
          activestatus: false,
          effectiveto: getYesterdayEpoch(doc?.effectivefrom),
        };
        doc.version = Number(data?.version) + 1;
        await dispatch(actions.UPDATE_DRUG_PRICE(updateolddrug));
      }

      await dispatch(actions.UPSERT_DRUG_PRICE(doc));
      let payload = {
        value: props?.serachValue,
        offset: 0,
        count: rowsPerPage * (page + 1),
      };
      await dispatch(actions.GET_SEARCH_DRUG_PRICE(payload));

      alert.setSnack({
        ...alert,
        open: true,
        severity: "success",
        msg: "Successfully inserted",
      });

      backdrop.setBackDrop({ ...backdrop, open: false, message: "" });
    } catch (e) {
      // console.log({ e });
      alert.setSnack({
        ...alert,
        open: true,
        severity: "error",
        msg: "something went wrong !",
      });
    }
  };

  const handlecancel = () => {
    props?.setOpenfor_add_drugprice(false);
  };
  return (
    <React.Fragment key={`${props?.key}addnew`}>
      {/* <DevTool control={control} /> */}
      <TableCell className={classes.TableCell}>
        <TextField
          id="effectivefrom"
          variant="outlined"
          error={error?.effectivefrom?.message}
          helperText={error?.effectivefrom?.message}
          size="small"
          label="Effective From"
          type={"date"}
          fullWidth
          className={classes.input}
          InputLabelProps={{ shrink: true }}
          {...register("effectivefrom", {
            required: { value: true, message: "This field is required" },
            validate: (date) => {
              return validatedate(date, props?.data?._id);
            },
          })}
        />
      </TableCell>
      <TableCell className={classes.TableCell}>
        <TextField
          id="price"
          variant="outlined"
          error={error?.price?.message}
          helperText={error?.price?.message}
          size="small"
          label="Drug Price"
          type={"number"}
          fullWidth
          className={classes.input}
          InputProps={{ inputProps: { min: 0 } }}
          {...register("price", {
            required: { value: true, message: "This field is required" },
            validate: {
              min: (data) => {
                return data >= 0 || "The price should not be negative";
              },
            },
          })}
        />
      </TableCell>
      {/* <TableCell>
                <TextField
                    value={drugState?.tax ?? ""}
                    onChange={(e) => handlechange("tax", e.target.value)}
                    variant="outlined"
                    error={error?.taxerror}
                    helperText={helpertext?.taxtxt}
                    size="small"
                    label="Tax"
                    type={"number"}
                    fullWidth
                />
            </TableCell> */}
      <TableCell className={classes.TableCell}>
        <TextField
          id="discount"
          variant="outlined"
          error={error?.discount?.message}
          helperText={error?.discount?.message}
          size="small"
          label="Discount"
          type={"number"}
          fullWidth
          className={classes.input}
          InputProps={{ inputProps: { min: 0 } }}
          {...register("discount", {
            required: { value: true, message: "This field is required" },
            validate: {
              min: (data) => {
                return data >= 0 || "The discount should not be negative";
              },
              maxpercentage: (data) => {
                return (
                  data <= 100 || "The discount should not be greater than 100"
                );
              },
            },
          })}
        />
      </TableCell>
      <TableCell className={classes.TableCell}>
        <Button
          variant="large"
          color="primary"
          onClick={handleSubmit(handlesubmitbycode)}
          className={classes.btn}
        >
          Submit
        </Button>
      </TableCell>
      <TableCell className={classes.TableCell}>
        <Button
          style={{ marginBottom: "12px" }}
          variant="large"
          color="primary"
          onClick={handlecancel}
        >
          cancel
        </Button>
      </TableCell>
    </React.Fragment>
  );
}

export default Addnewdrugprice;
