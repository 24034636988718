import React from "react";
import Tour from "reactour";
import "./style.css";
import { Button, useTheme } from "@material-ui/core";
function AppTour({ open, setOpen, steps }) {
  const theme = useTheme();
  const accentColor = theme.palette.primary.main;
  return (
    <div>
      <Tour
        onRequestClose={() => setOpen(false)}
        steps={steps}
        isOpen={open}
        maskClassName="mask"
        className="helper"
        rounded={5}
        accentColor={accentColor}
        lastStepNextButton={
          <Button variant="contained" color="primary">
            Lets go!
          </Button>
        }
      />
    </div>
  );
}

export default AppTour;
