import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TreeItem from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';


const useTreeItemStyles = makeStyles((theme) => ({
    root: {
        // '&:hover > $content': {
        //     backgroundColor: theme.palette.action.hover,
        // },
        // '&:focus > $content, &$selected > $content': {
        //     backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
        //     color: 'var(--tree-view-color)',
        // },
        // '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
        //     backgroundColor: 'transparent',
        // },
    },
    content: {
        color: theme.palette.text.secondary,
        paddingRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightMedium,
        '$expanded > &': {
            fontWeight: theme.typography.fontWeightRegular,
            color: theme.palette.primary.main,
        },
        '$selected > &': {
            fontWeight: theme.typography.fontWeightRegular,
            color: theme.palette.primary.main,
        }
    },
    group: {
        marginLeft: 7,
        paddingLeft: 18,
        borderLeft: `1px dotted #706f80`,
        // '& $content': {
        //     paddingLeft: theme.spacing(2),
        // },
    },
    expanded: {},
    selected: {
        color: theme.palette.primary.main,
    },
    label: {
        fontWeight: 'inherit',
        color: 'inherit',
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1, 0.5),
    },
    labelIcon: {
        marginRight: theme.spacing(1),
    },
    labelText: {
        fontWeight: 'inherit',
        flexGrow: 1,
    },
}));

export const StyledTreeItem = (props) => {
    const classes = useTreeItemStyles(props);
    const { labelText, labelIcon: LabelIcon, color, bgColor, ...other } = props;

    return (
        <div>
            <TreeItem
                label={
                    <div className={classes.labelRoot}>
                        <LabelIcon color="inherit" className={classes.labelIcon} />
                        <Typography variant="body2" className={classes.labelText}>
                            {labelText}
                        </Typography>
                    </div>
                }
                // style={{
                //     '--tree-view-color': color,
                //     '--tree-view-bg-color': bgColor,
                // }}
                classes={{
                    root: classes.root,
                    content: classes.content,
                    expanded: classes.expanded,
                    selected: classes.selected,
                    group: classes.group,
                    label: classes.label,
                }}
                {...other}
            />
        </div>

    );
}

StyledTreeItem.propTypes = {
    bgColor: PropTypes.string,
    color: PropTypes.string,
    labelIcon: PropTypes.elementType,
    labelText: PropTypes.any.isRequired,
};