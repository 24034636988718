/**
 * @author Hari Ram
 * @email hariram@qdmplatforms.com
 * @create date 2021-01-28
 * @modify date 2021-03-30
 * @desc Input component
 */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  label: {
    // backgroundColor: "#F3F5F9",
    padding: theme?.spacing(1),
    wordBreak: "break-word",
  },
  note: {
    backgroundColor: "#F8F6E9",
    padding: theme?.spacing(1),
    borderRadius: 4,
    fontStyle: "italic",
    wordBreak: "break-word",
  },
}));
const Input = (props) => {
  // console.log("labelProps", props, Array.isArray(props?.question_options));
  const classes = useStyles();
  const getLabel = (label) => {
    return (
      <Typography variant="body1" style={{ fontSize: 16 }} gutterBottom>
        {label}{" "}
        {props.isrequired && (
          <Typography variant="caption" style={{ color: "red" }}>
            *
          </Typography>
        )}
      </Typography>
    );
  };
  return (
    <>
      <div style={{display : props?.disabled ? "none" : ""}}>
      {Array.isArray(props?.question_options) ? (
        <>
          <Typography className={classes.label} variant="body2">
            {getLabel(props.text)}
            <ol>
              {props?.question_options?.map((name, id) => (
                <li id={"txt +" + id} style={{ margin: "1em" }}>
                  {name}
                </li>
              ))}
            </ol>
          </Typography>
        </>
      ) : (
        <>
          {" "}
          <Typography
            id={"txt +" + props?.id}
            className={props.notes ? classes.note : classes.label}
            variant="body2"
          >
            {props.text}
          </Typography>
        </>
      )}
      </div>
     
    </>
  );
};
Input.propTypes = {
  text: PropTypes.string,
  id: PropTypes.string,
  size: PropTypes.string,
  textAlign: PropTypes.string,
  notes: PropTypes.bool,
};
Input.defaultProps = {
  text: "text",
  textAlign: "left",
  notes: false,
};
export default Input;
