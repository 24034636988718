import React from "react";
import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100%",
    display: "flex",
  },
  content: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    backgroundColor: "#ECF0F7",
    // [theme.breakpoints.up("sm")]: {
    //   paddingLeft: 56
    // },
    //[theme.breakpoints.down('md')]: {
    // paddingLeft: 0
    // ,
    //}
  },
  // topNavbar: {
  //},
  // sideNavbar: {
  // [theme.breakpoints.down('md')]: {
  // display: "none"
  //}
  //}
}));

const withNavBars = (Component) => (props) => {
  const classes = useStyles({ props });
  return (
    <React.Fragment>
      {/* Your nav bars here */}
      <div className={classes.root}>
        {/* <SideBar sidebarlist={sidebarlist} /> */}
        {/* Content */}
        <div className={classes.content}>
          <Component {...props}>{props.children}</Component>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withNavBars;
