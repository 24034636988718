import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField, Typography, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";

const top100Films = [
  { label: "Option_1", value: 1 },
  { label: "Option_2", value: 2 },
  { label: "Option_3", value: 3 },
];

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormHelperText-contained": {
      margin: 0,
      marginTop: 4,
      color: "red",
    },
  },
}));

export default function Select(props) {
  const classes = useStyles(props);

  const getLabel = (props) => {
    return (
      <Typography variant="body1" style={{ fontSize: 16 }} gutterBottom>
        {props.label}{" "}
        {props.isrequired && (
          <Typography variant="caption" style={{ color: "red" }}>
            *
          </Typography>
        )}
      </Typography>
    );
  };
  // console.log("optionprops", props);
  return (
    <div className={classes.root} style={{display : props?.disabled ? "none" : ""}} id={"Txt +" + props?.id}>
      {getLabel(props)}
      <Autocomplete
        {...props}
        disabled={(props.isReadonly || props?.disabled) ?? false}
        multiple={props.multiple}
        value={props.multiple ? props.value : props.value?.[0]}
        id={props.id}
        disableClearable={props?.isClearable}
        options={props.option}
        getOptionLabel={(option) => option.label}
        defaultValue={props.defaultValue}
        filterSelectedOptions
        onChange={(e, newValue) => props.handleselect(newValue)}
        size={props.size}
        renderInput={(params) => (
          <TextField
            {...params}
            // error={props.error}
            variant="outlined"
            // label={props.label}
            InputLabelProps={{
              shrink: false,
            }}
            required={props.isrequired}
            disableClearable={props?.isClearable}
            placeholder={props.placeholder}
            error={
              props.error
                ? props.error
                : props?.errorValidation && props?.errorValidation?.error
                ? props?.errorValidation?.error
                : false
            }
            helperText={
              props?.helperText
                ? props?.helperText
                : props?.errorValidation && props?.errorValidation?.errorMessage
                ? props?.errorValidation?.errorMessage
                : ""
            }
          />
        )}
      />
    </div>
  );
}

Select.propTypes = {
  isReadonly: PropTypes.bool,
  multiple: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  id: PropTypes.string,
  defaultValue: PropTypes.array,
  option: PropTypes.array, // EX: [{value: "", label: ""}],
  handleselect: PropTypes.func,
  size: PropTypes.oneOf(["small", "medium"]),
  error: PropTypes.bool,
  isClearable: PropTypes.bool,
  value: PropTypes.array, //{value: "", label: ""}
};

Select.defaultProps = {
  multiple: false,
  label: "",
  placeholder: "",
  defaultValue: [],
  option: top100Films,
  size: "small",
  handleselect: () => {},
  error: false,
  value: [],
};
