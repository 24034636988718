//Normal messagess
export let Message = {
  requiredMessage: "This field is required",
  loading: "Loading...",
  manage_form_delete_title: "Are you sure you want to delete ?",
  manage_form_delete_message:
    "If you delete this forms all the forms that tagged to this form will be deleted.",
  manage_form_delete_btn: "I'm aware delete",
  manage_form_delete: "Deleting form...",
  section_delete: (sec) => `Deleting ${sec}...`,
};
