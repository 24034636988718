import * as React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@material-ui/core";

const PharmacyIcon = (props) => {
  const theme = useTheme();
  const color =
    window.location.pathname === "/pharmacy" ||
    window.location.pathname === "/editnewpharmacy" ||
    window.location.pathname === "/addnewpharmacy"
      ? theme.sideBar.text.active
      : theme.sideBar.text.nonActive
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="19.5"
      viewBox="0 0 18 19.5"
    >
      <path
        id="icons8-pharmacy-shop"
        d="M14.976,4a.75.75,0,0,0-.439.161L7.43,9.759A3.752,3.752,0,0,0,6,12.7V20.75A2.761,2.761,0,0,0,8.75,23.5h12.5A2.761,2.761,0,0,0,24,20.75V12.7a3.752,3.752,0,0,0-1.43-2.945l-7.106-5.6A.75.75,0,0,0,14.976,4ZM15,5.7l6.643,5.233A2.247,2.247,0,0,1,22.5,12.7V20.75A1.239,1.239,0,0,1,21.25,22H8.75A1.239,1.239,0,0,1,7.5,20.75V12.7a2.247,2.247,0,0,1,.857-1.767ZM16.725,11a3.055,3.055,0,0,0-2.115.871q-.023.022-.044.046c.054-.061-.049.052-.148.151l-.394.4-1.152,1.158c-.417.418-.835.837-1.158,1.162l-.4.405c-.088.089-.126.126-.181.187l0,0a3,3,0,0,0,2.128,5.12,3.054,3.054,0,0,0,2.116-.871l.01-.01,3.484-3.5h0a3,3,0,0,0-.006-4.246l0,0A3.076,3.076,0,0,0,16.725,11Zm0,1.5a1.544,1.544,0,0,1,1.089.449,1.479,1.479,0,0,1-.008,2.115h0l-1.617,1.623-2.13-2.13,1.031-1.035.4-.4c.1-.1.124-.121.208-.217l-.044.047A1.544,1.544,0,0,1,16.725,12.5Z"
        transform="translate(-6 -3.999)"
        fill={color}
      />
    </svg>
  );
};

export default PharmacyIcon;

PharmacyIcon.defaultProps = {
  color: "#001C3C",
  secondaryColor: "#001C3C",
};

PharmacyIcon.propTypes = {
  color: PropTypes.string,
  secondaryColor: PropTypes.string,
};
