import * as React from "react";
import PropTypes from "prop-types";

const DoctorIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="16.25"
    viewBox="0 0 15 16.25"
  >
    <path
      id="icons8-medical-doctor_1_"
      data-name="icons8-medical-doctor (1)"
      d="M8.5,0A5.824,5.824,0,0,0,5.9.449,1.8,1.8,0,0,0,5.355.84a1.254,1.254,0,0,0-.2.332.7.7,0,0,0-.039.156L4.789,5.605c0,.027,0,.068,0,.1l-.039.43a.938.938,0,0,0,0,.117,3.75,3.75,0,1,0,7.5,0V6.133l-.371-4.8a.7.7,0,0,0-.039-.156,1.254,1.254,0,0,0-.2-.332A1.805,1.805,0,0,0,11.1.449,5.824,5.824,0,0,0,8.5,0Zm0,10C4.374,10,1,11.941,1,13.691V16.25H16V13.691c0-1.663-3.052-3.494-6.894-3.672C8.9,10,8.7,10,8.5,10Zm0-8.75a4.79,4.79,0,0,1,2.031.312.381.381,0,0,1,.117.078l.254,3.2C10.326,4.878,9.545,5,8.5,5s-1.826-.122-2.4-.156l.254-3.2a.381.381,0,0,1,.117-.078A4.79,4.79,0,0,1,8.5,1.25Zm-.508.625v.742H7.25V3.633h.742v.742H9.008V3.633H9.75V2.617H9.008V1.875ZM6.625,11.406,8.227,15H2.25V13.691C2.25,13.225,3.844,11.863,6.625,11.406Zm3.75,0c2.781.457,4.375,1.819,4.375,2.285V15H8.773Z"
      transform="translate(-1)"
      fill="#6a7888"
    />
  </svg>
);

export default DoctorIcon;

DoctorIcon.defaultProps = {
  color: "#6a7888",
};

DoctorIcon.propTypes = {
  color: PropTypes.string,
};
