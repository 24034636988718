import React from "react";

import { withAllContexts, withQueueModal, withSideBars } from "./../../HOCs";
import { QueueItems } from "./queueItems";
class QueueItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    // this.getProjectDetails();
  }

  render() {
    const { queues, selectedQueue } = this.props;
    return (
      <>
        <QueueItems
          PermissionData={this.props.PermissionData}
          queue={this.props.PermissionData.length === 0 ? [] : queues[0]}
          selectedQueue={selectedQueue}
        />
      </>
    );
  }
}

export default withSideBars(withQueueModal(withAllContexts(QueueItem)));
