import {
  Box,
  Button,
  Grid,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React from "react";
import { KeyboardBackspace } from "@material-ui/icons";
import { SearchTextField } from "../../searchComponent";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: "52px",
    // marginBottom: theme.spacing(1),
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  textResponsive: {
    // fontWeight: "600",
    fontSize: "1.1rem",
    flex: "1 1 auto",
    display: "-webkit-box",
    WebkitLineClamp: "1",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",

    [theme?.breakpoints?.down("md")]: {
      fontSize: "1rem !important",
    },
    [theme?.breakpoints?.down("sm")]: {
      fontSize: "0.8rem !important",
    },
  },
  searchbox2: {
    background: (props) => (props?.colored ? "#F3F4F7" : "#fff"),
    padding: (props) => (props?.variant === "2" ? "2px" : "4px"),
    borderRadius: "8px",
    display: "flex",
    border: (props) => (props?.variant === "2" ? "1px solid #dbdbdb" : "unset"),
  },
  inputBase: {
    width: "300px",
    "& fieldset": {
      border: "none",
    },
  },
}));

export const SubHeader = (props) => {
  const classes = useStyles();
  const {
    title,
    onClickAddNewButton = () => false,
    onHandleChange = () => false,
    onEnterKeyPress = () => false,
    onBackBtnClicked = () => false,
    searchPlaceholder = "",
    searchValue,
    hideSearch,
    hideBackBtn,
    hideHeaderBtn,
    buttonText = "+Add New",
    backToToolip,
  } = props;
  const Theme = useTheme();
  const forPhone = useMediaQuery(Theme.breakpoints.down("sm"));
  return (
    <>
      <Grid item xs={12} lg={12} md={12} sm={12} className={classes.header}>
        <Box className={classes.wrapper}>
          {!hideBackBtn && (
            <Grid item>
              <IconButton
                size="small"
                id="BackBtn"
                onClick={onBackBtnClicked}
                style={{
                  padding: "5px",
                  marginRight: "18px",
                  background: "#FFFFFF 0% 0% no-repeat padding-box",
                  border: "1px solid #E0E0E0",
                  opacity: 1,
                }}
              >
                <Tooltip title={backToToolip} placement="top" arrow>
                  <KeyboardBackspace fontSize="small" />
                </Tooltip>
              </IconButton>
            </Grid>
          )}
          <Typography variant="h6" className={classes.textResponsive}>
            {title}
          </Typography>
        </Box>
        <Box className={classes.wrapper}>
          {!forPhone && !hideSearch && (
            <SearchTextField
              variant="2"
              onHandleChange={onHandleChange}
              placeholder={searchPlaceholder}
              onKeyPress={onEnterKeyPress}
              value={searchValue}
            />
          )}

          {!hideHeaderBtn && (
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: "10px" }}
              onClick={() => onClickAddNewButton()}
            >
              {buttonText}
            </Button>
          )}
        </Box>
      </Grid>
      {forPhone && !hideSearch && (
        <Grid item xs={12} lg={12} md={12} sm={12}>
          <SearchTextField
            variant="2"
            onHandleChange={onHandleChange}
            placeholder={searchPlaceholder}
            onKeyPress={onEnterKeyPress}
            value={searchValue}
            fullWidth
            margin
          />
        </Grid>
      )}
    </>
  );
};
