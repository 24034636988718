import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { useStyles } from "./style";

function StyledTooltip(props) {
  const classes = useStyles();
  return <Tooltip arrow classes={classes} {...props} />;
}

export default StyledTooltip;
