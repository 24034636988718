/**
 * @author Kumaravel Pazhani
 * @email kumaravel@qdmplatforms.com
 * @create 12/11/2020
 * @modify 12/11/2020
 * @desc Exporting all the components from /src/components
 */

import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { TextField, Typography } from "@material-ui/core";

const useStyles = (theme) => ({});

class InputComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let {
      disabled,
      classes,
      onChange,
      // classname,
      error,
      value,
      id,
      top_title,

      requireStart,

      InputProps,
      onInput,
      parent_id,
    } = this.props;
    return (
      <React.Fragment>
        {top_title && (
          <Typography
            id={`${parent_id}-${top_title.replaceAll(" ", "-")}_typography`}
            variant="caption"
            className={classes.drawerSubHead}
          >
            {top_title}
            {requireStart && (
              <span
                style={{
                  color: "red",
                  marginLeft: 5,
                }}
              >
                *
              </span>
            )}
          </Typography>
        )}
        <TextField
          id={id}
          value={value}
          onChange={onChange}
          fullWidth
          variant="outlined"
          size="small"
          InputProps={InputProps}
          disabled={disabled}
          onInput={onInput}
          error={error}
        />
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(InputComp);
