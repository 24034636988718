import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import StyledPaper from "../styledPaper";
import StyledTableCell from "./styledTableCell";
import StyledTableRow from "./styledTableRow";
import StyledSwitch from "../styledSwitch";
import { CreateIcon } from "../../../assets";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    backgroundColor: theme.palette.background.table,
  },
  tableHeader: {
    backgroundColor: theme.palette.background.tableHeader,
  },
}));

function StyledTable({ columns, rows, onEditClick, onStatusChange, ...props }) {
  const classes = useStyles();
  return (
    <TableContainer component={StyledPaper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead className={classes.tableHeader}>
          <StyledTableRow className={classes.tableHeader}>
            {columns.map((column) => (
              <StyledTableCell isHeadCell>{column.label}</StyledTableCell>
            ))}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row, serialCount) => {
            return (
              <StyledTableRow key={row.cartName}>
                {columns.map((column) => {
                  switch (column.type) {
                    case "serial": {
                      return (
                        <StyledTableCell>{serialCount + 1}</StyledTableCell>
                      );
                    }

                    case "switch": {
                      return (
                        <StyledTableCell>
                          <StyledSwitch
                            checked={row?.cartstatus}
                            onChange={() =>
                              onStatusChange(row?._key, row?.cartstatus)
                            }
                          />
                        </StyledTableCell>
                      );
                    }

                    case "action": {
                      return (
                        <StyledTableCell>
                          <IconButton
                            size="small"
                            onClick={() => onEditClick(row?._id, row?._key)}
                          >
                            <CreateIcon color="#6F6F6F" />
                          </IconButton>
                        </StyledTableCell>
                      );
                    }

                    default: {
                      return (
                        <StyledTableCell>{row[column.id]}</StyledTableCell>
                      );
                    }
                  }
                })}
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default StyledTable;
