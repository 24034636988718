import React from "react";
import {
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Delete } from "@material-ui/icons";
import {
  ConvertToReactSelect,
  DependencyType,
  // FormComponents
} from "../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    border: `1px solid ${theme?.palette?.background?.default}`,
  },
}));

export const DependencyTable = ({
  data = [],
  options = [],
  parentSection = {},
  selectedSection = {},
  quetions = [],
  parentIndex = null,
  removeDependency = () => false,
  updateDependency = () => false,
}) => {
  const classes = useStyles();

  const giveMeTargetOptions = (data) => {
    switch (data?.type?.value) {
      case DependencyType[0]?.value:
        return ConvertToReactSelect(
          parentSection?.child_sections?.filter(
            (_) => _.is_form === true && _.priority > selectedSection.priority
          ),
          "id",
          "section_name"
        );

      case DependencyType[1]?.value:
        return ConvertToReactSelect(
          parentSection?.child_sections?.filter(
            (_) => _.is_form === false && _.priority > selectedSection.priority
          ),
          "id",
          "section_name"
        );

      case DependencyType[2]?.value:
        return ConvertToReactSelect(
          quetions?.filter((_) => _.priority > data.parentQuestion.priority),
          "id",
          "label"
        );

      default:
        return [];
    }
  };

  const giveMeOptions = (ui_component) => {
    switch (ui_component) {
      // case FormComponents.multi_select_tag:
      //     return options;

      default:
        return options?.map((_) => {
          return {
            value: _,
            label: _,
          };
        });
    }
  };

  return (
    <div className={classes.root}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width={30}>Sl.No</TableCell>
            <TableCell>Options</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Target</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((dep, index) => (
            <TableRow>
              {/* Ṣl. No */}
              <TableCell>{index + 1}</TableCell>

              {/* Options */}
              <TableCell>
                <Autocomplete
                  onChange={(e, newValue) =>
                    updateDependency("option", newValue, index, parentIndex)
                  }
                  value={dep?.option}
                  options={giveMeOptions(dep?.parentQuestion?.component)}
                  getOptionLabel={(option) => option?.label}
                  id="clear-on-escape"
                  clearOnEscape
                  multiple={true}
                  renderInput={(params) => (
                    <TextField
                      // error={val?.error?.componentError ?? false}
                      {...params}
                      className={classes.selectComponentList}
                      size="small"
                      placeholder={"Select Options"}
                      fullWidth
                      margin={"dense"}
                    />
                  )}
                />
              </TableCell>

              {/* Select Type (Form / Section / Question) */}
              <TableCell>
                <Autocomplete
                  onChange={(e, newValue) =>
                    updateDependency("type", newValue, index, parentIndex)
                  }
                  value={dep?.type}
                  options={DependencyType}
                  getOptionLabel={(option) => option.label}
                  id="dependency_type"
                  clearOnEscape
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={classes.selectComponentList}
                      size="small"
                      placeholder={"Select Type"}
                      fullWidth
                      margin={"dense"}
                    />
                  )}
                />
              </TableCell>

              {/* Select Form / Section / Question (Target)*/}
              <TableCell>
                <Autocomplete
                  onChange={(e, newValue) =>
                    updateDependency("target", newValue, index, parentIndex)
                  }
                  value={dep?.target}
                  options={giveMeTargetOptions(dep)}
                  getOptionLabel={(option) => option.label}
                  id="dependency_target"
                  clearOnEscape
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={classes.selectComponentList}
                      size="small"
                      placeholder={"Select Target"}
                      fullWidth
                      margin={"dense"}
                    />
                  )}
                />
              </TableCell>

              {/* Action */}
              <TableCell>
                <IconButton
                  onClick={() =>
                    removeDependency(dep, parentIndex, false, index)
                  }
                >
                  <Delete />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
