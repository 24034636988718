import React from "react";
import {
    makeStyles,
    Typography,
    Button,
} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
    root: {
    },
    header: {
        fontFamily: "poppinsemibold",
        fontSize: "16px"
    },
    btn: {
        backgroundColor: theme.palette.button.background,
        color: theme.palette.button.text,
        padding: "6px 16px",
        fontFamily: "poppin",
        fontSize: "14px",
        fontWeight: "600",
        textTransform: "none",
        borderRadius: "10px",
        width: "129px",
        "&:hover": {
            backgroundColor: theme.palette.button.hover
        }
    }
}));

const CommonHeader = (props) => {
    const classes = useStyles();
    const { btnFun = () => null, value, parent_id } = props;
    return (
        <div
            id={`${parent_id}-header-div`}>
            <div
                id={`${parent_id}-header-sub-div`}
                style={{ display: "flex", paddingBottom: "10px" }}>
                <Typography
                    id={`${parent_id}-header-title-typography`}
                    className={classes.header} variant="h6">{props.title}</Typography>
                <div style={{ flexGrow: 1 }}></div>
                {props.BtnName &&
                    <Button
                        id={`${parent_id}-header-${props.BtnName.replaceAll(" ", "-")}-button`}
                        className={classes.btn}
                        onClick={() => btnFun(value)} color='primary'
                        variant="contained"
                    >
                        {props.BtnName}
                    </Button>}
            </div>
        </div>
    );
};
export default CommonHeader;
