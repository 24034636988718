import React from "react";
import { makeStyles } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { LocalStorageKeys } from "../../../utils";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  Avatar,
  List,
  ListItemIcon,
  ListItem,
  ListItemText,
  Grid,
  Divider,
  MenuItem,
  Select,
  FormControl,
} from "@material-ui/core";
import Popover from "@material-ui/core/Popover";
import LogoutIcon from "@mui/icons-material/Logout";
import { withStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
    zIndex: theme.zIndex.drawer + 1,
  },
  TaskHeader: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  // title: {
  //   // display: "block",
  // },
  titleContainer: {
    display: "flex",
    alignItems: "center",
  },
  menuIcon: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  userProfilecard: {
    display: "flex",
    alignItems: "center",
  },
  profilePic: {
    marginLeft: "8px",
  },
  paper: {
    borderRadius: 12,
    marginTop: 8,
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,

    "& li": {
      paddingTop: 8,
      paddingBottom: 8,
      fontSize: "1rem",
      fontFamily: "TDS_Regular",
    },
    "& li:hover": {
      color: theme.palette.common.white,
      background: theme.palette.info.main,
    },
    "& li.Mui-selected": {
      color: theme.palette.common.black,
      background: theme.palette.info.light,
    },
    "& li.Mui-selected:hover": {
      // background: "#6EC177",
      color: theme.palette.common.white,
      background: theme.palette.info.main,
    },
  },
}));

const BootstrapInput = withStyles((theme) => ({
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.common.white,
    border: "1px solid #e0e0e0",
    fontSize: "1rem",
    padding: "10px 26px 10px 12px",
  },
}))(InputBase);

export const TopNavBar = ({
  updateScenaorio,
  PermissionData,
  QueueName,
  Scenarios,
}) => {
  const classes = useStyles();
  let ids = Scenarios[Scenarios.length - 1]?._id;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [scenarios, setAge] = React.useState("");

  React.useEffect(() => {
    //  console.count();
    setAge(ids);
    updateScenaorio(ids);
  }, [ids]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem(LocalStorageKeys.authToken);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleChangew = (event) => {
    setAge(event.target.value);
    updateScenaorio(event.target.value);
  };

  const menuProps = {
    classes: {
      list: classes.list,
      paper: classes.paper,
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
    getContentAnchorEl: null,
  };

  return (
    <div className={classes.grow}>
      <AppBar position="static" className={classes.TaskHeader}>
        <Toolbar style={{ padding: "0 16px" }}>
          <div
            //style={{display:'flex', justifyContent:"space-around"}}
            className={classes.titleContainer}
          >
            <Typography variant="body1" noWrap>
              {"Task Viewer"}
            </Typography>
            <Divider
              orientation="vertical"
              flexItem
              style={{ marginLeft: "10px" }}
            />
            {QueueName && (
              <div style={{ marginLeft: "20px", marginRight: "20px" }}>
                <Divider
                  flexItem
                  orientation="vertical"
                //style={{ margin: "8px 12px" }}
                />
                {/* <hr style={{ height: 25, margin: "0px 8px" }} /> */}
                <Typography variant="subtitle1">{QueueName}</Typography>
              </div>
            )}
            <Divider
              orientation="vertical"
              flexItem
              style={{ marginLeft: "10px" }}
            />
            {QueueName && Scenarios.length !== 0 && (
              <div style={{ marginLeft: "20px", marginRight: "20px" }}>
                <Divider
                  flexItem
                  orientation="vertical"
                //style={{ margin: "8px 12px" }}
                />
                {/* <Grid item lg={3}> */}
                <FormControl
                  style={{ margin: "8px", minWidth: "100%" }}
                  size="small"
                >
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    defaultValue={Scenarios[Scenarios.length - 1]?._id}
                    value={scenarios}
                    onChange={handleChangew}
                    input={<BootstrapInput />}
                    MenuProps={menuProps}
                  >
                    {Scenarios?.map((v, index) => {
                      return (
                        <MenuItem key={index} value={v._id}>
                          {v.scenarioname}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                {/* </Grid> */}
              </div>
            )}
          </div>

          {/* <div className={classes.grow} /> */}

          {/* <div className={classes.userProfilecard}> */}
          {/* <Typography className={classes.title} variant="body1" noWrap> */}
          {/* userName */}
          {/* Admin */}
          {/* </Typography> */}
          {/* <Avatar className={classes.profilePic} variant="rounded">
              A
            </Avatar> */}
          {/* </div> */}
          {/* <IconButton onClick={handleClick}>
            <ExpandMoreIcon />
          </IconButton> */}
        </Toolbar>
      </AppBar>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <List>
          <ListItem button disablePadding onClick={() => handleLogout()}>
            <ListItemIcon style={{ width: "30px" }}>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Popover>
    </div>
  );
};
