import {
  makeStyles,
  Grid,
  Typography,
  Tooltip,
  Link,
  Divider,
} from "@material-ui/core";
import React from "react";
import { Checkbox } from "@material-ui/core";
import { Routes } from "./../../router/routes";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: 6,
    marginBottom: 0,
  },
  patientDetailSection: {
    maxHeight: 100,
    overflow: "auto",
  },
}));

export const PatientDetailCard = (props) => {
  const {
    patient_id = {},
    patient_name = {},
    gender = {},
    mobile = {},
    email = "",
    dob_age = {},
    country = {},
    onselect = () => false,
    showSelectOptions = false,
    id = "",
    ID = "",
    addressline = "",
  } = props;
  const classes = useStyles();

  const giveMeFieldToShow = (v, l) => {
    if (Array.isArray(v[l?.field])) {
      //If the value is array then retrun the string.
      return v[l?.field]
        ?.map((_) => {
          //If the array child is the label,value pare then return only label
          if (_?.label) {
            return _?.label;
          }
          return _;
        })
        .toString();
    } else if (v[l?.field]?.label) {
      //If the value is object with value,label pair then return label only
      return v[l?.field]?.label;
    } else if (v[l?.field]?.address) {
      //If the value is a location object then return the formated address
      return `${v[l?.field].address} (${v[l?.field]?.latitude}, ${
        v[l?.field]?.longitude
      })`;
    } else if (v[l?.field]?.tableData) {
      //If the value is the table type,
      return "-";
    } else if (v[l?.field]?.number) {
      return `${v[l?.field]?.phoneCode} ${v[l?.field]?.number}`;
    } else if (v[l?.field]?.country) {
      return `${v[l?.field]?.country?.value}`;
    } else {
      //If nothing mathes the above conditions
      return v[l?.field];
    }
  };

  return (
    <div className={classes.root}>
      <Grid container direction="row">
        {showSelectOptions && (
          <Grid item xs={2}>
            <Checkbox
              id={"checkBox" + id}
              checked={patient_id === props.selectedPatient}
              onChange={(e) => onselect(props?.data, e.target.checked)}
              value={patient_id}
            />
          </Grid>
        )}
        <Grid
          item
          sm={12}
          md={showSelectOptions ? 6 : 4}
          lg={4}
          xl={4}
          xs={12}
          className={showSelectOptions ? classes.patientDetailSection : ""}
        >
          {/* <Tooltip
            title={`${patient_name?.title?.label} ${patient_name?.first_name} ${
              patient_name?.middle_name ?? ""
            } ${patient_name?.last_name ?? ""}`}
          > */}
          <Typography variant="body1">
            {`${patient_name?.title?.label ?? ""} ${
              patient_name?.first_name ?? ""
            } ${patient_name?.last_name?.[0] ?? ""}`}
            <Typography variant="caption" style={{ marginLeft: 8 }}>{`${
              gender?.[0] ?? ""
            }, ${dob_age?.age ?? "-"}y`}</Typography>
          </Typography>
          {/* </Tooltip> */}
          <Typography variant="body2">{`${mobile?.phoneCode ?? ""}-${
            mobile?.number ?? ""
          } / ${email}`}</Typography>
          {country?.patient_details?.components?.map((comp) => {
            return (
              <Typography gutterBottom variant="body2">{`${
                comp.tag
              } : ${giveMeFieldToShow(props, { field: comp.id })}`}</Typography>
            );
          })}
        </Grid>
        <Grid item sm={12} md={showSelectOptions ? 6 : 4} lg={4} xl={4} xs={12}>
          <Typography variant="body1">Patient ID</Typography>
          <Typography variant="body2">{ID}</Typography>
        </Grid>
        <Grid item sm={12} md={showSelectOptions ? 6 : 4} lg={4} xl={4} xs={12}>
          <Typography variant="body1">Address </Typography>
          <Typography variant="body2">{addressline} </Typography>
        </Grid>

        {props?.data?.count_of_entries?.length > 0 && (
          <Grid item xs={12}>
            <Link
              id={"ViewHistory" + id}
              href={`${Routes.manage_patients}?id=${patient_id}`}
              target="_blank"
            >
              <div style={{ marginLeft: 5, display: "flex" }}>
                View History
                <ArrowRightAltIcon />
              </div>
            </Link>
          </Grid>
        )}
      </Grid>
    </div>
  );
};
