import styled from "styled-components";

export const Label = styled.span`
  font-size: ${(props) => (props.count ? "9px" : "12px")};
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius: 50%;
  color: #fff;
  background: ${(props) => props.color};
  margin: 8px;
`;

export const StyledLabel = styled(Label)`
  background: ${(props) => props.color};
  color: #000;
`;

export const StyledLabel2 = styled(Label)`
  margin: 0px;
  color: #000;
`;

export const CardList = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 52px;
  padding-left: 16px;
  padding-right: 16px;
  background: "#FFFFFF 0% 0% no-repeat padding-box";
  border-radius: 8px;
  padding-top: 32px;
  padding-bottom: 32px;
  border: 1px solid #e0e0e0;
`;

export const StyledButton = styled.button`
  outline: none;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  padding: 8px 24px;
  border: 1px solid #bdbdbd;
  border-radius: 20px 20px 0 0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.14);
  cursor: pointer;
`;

export const StyledButton2 = styled(StyledButton)`
  border-radius: 30px;
  padding: 0px 8px;
`;
