import { Routes } from "../router/routes";
import ApartmentIcon from "@material-ui/icons/Apartment";
import AssignmentTurnedInOutlinedIcon from "@material-ui/icons/AssignmentTurnedInOutlined";
import AssessmentOutlinedIcon from "@material-ui/icons/AssessmentOutlined";
import AccountBalanceOutlinedIcon from "@material-ui/icons/AccountBalanceOutlined";
import DynamicFeedIcon from "@material-ui/icons/DynamicFeed";
import FastfoodRoundedIcon from "@material-ui/icons/FastfoodRounded";
import HowToRegRoundedIcon from "@material-ui/icons/HowToRegRounded";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import { MdOutlineFoodBank } from "react-icons/md";
import { FaCartPlus } from "react-icons/fa";
import MedicationIcon from '@mui/icons-material/Medication';
import HomeIcon from "@material-ui/icons/Home";
import GroupRoundedIcon from "@material-ui/icons/GroupRounded";
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import PostAddIcon from '@mui/icons-material/PostAdd';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import { AutoStories } from "@mui/icons-material";



/**
 * Object with role as key and value, which is used for
 * comparison of role in different place.
 */
export const UserRoles = {
  role: "role",
};

/**
 * Object which has the proper name of all the role
 * used in the application.
 */
export let UserRolesName = {
  role: "Role",
};

/**
 * Object which has the different themes used in
 * the application.
 */
export let Themes = {
  default: "default",
  dark: "dark",
};

/**
 * Object which has the different props for the Alert Component (/src/component/alert)
 * which is used via AlertContext (/src/contexts) and provided at /src/App.alert.js.
 */
export let AlertProps = {
  vertical: {
    top: "top",
    bottom: "bottom",
  },
  horizontal: {
    left: "left",
    right: "right",
    center: "center",
  },
  severity: {
    success: "success",
    error: "error",
    warning: "warning",
    info: "info",
  },
};

/**
 * Object which has the different props for the Drawer Component (/src/App.drawer.js)
 * which is used via DrawerContext (/src/contexts) and provided at /src/App.drawer.js.
 */
export const DrawerProps = {
  direction: {
    top: "top",
    bottom: "bottom",
    left: "left",
    right: "right",
  },
  variant: {
    permanent: "permanent",
    persistent: "persistent",
    temporary: "temporary",
  },
};

/**
 * Object has the key and value pair of all the keys which
 * are used to store some values in the local storage.
 */
export let LocalStorageKeys = {
  authToken: "auth_token",
  version: "version",
};

/**
 * Object has the key and value pair of all the HTTP method
 * used for an network call.
 */
export let NetWorkCallMethods = {
  get: "GET",
  post: "POST",
  put: "PUT",
  delete: "DELETE",
  update: "UPDATE",
};

/* This list page with icon (icon can't be stored in session or db) */
export let repolist = [
  {
    id: 0,
    name: "Dashboard",
    repo: "Dashboard",
    icon: <HomeIcon fontSize={"small"} />,
    routePath: Routes.Dashboard,
    isMenu: true
  },
  {
    id: 1,
    name: "Organization",
    repo: "Organization",
    icon: <ApartmentIcon fontSize={"small"} />,
    routePath: Routes.Organization,
    isMenu: true
  },
  {
    id: 2,
    name: "Permission Management",
    repo: "User Management",
    icon: <AccountBalanceOutlinedIcon fontSize={"small"} />,
    routePath: Routes.repositry,
    isMenu: true
  },
  {
    id: 3,
    name: "Task And Document",
    repo: "Task And Document",
    icon: <AssignmentTurnedInOutlinedIcon fontSize={"small"} />,
    routePath: Routes.taskdocument,
    isMenu: true
  },
  {
    id: 4,
    name: "Report Viewer",
    repo: "Report Viewer",
    icon: <AssessmentOutlinedIcon fontSize={"small"} />,
    routePath: Routes.reportConfig,
    isMenu: true
  },
  {
    id: 6,
    name: "Order Catalog",
    repo: "Order Menu",
    icon: <FaCartPlus size={"1.3rem"} />,
    routePath: Routes.menu,
    isMenu: true
  },
  {
    id: 7,
    name: "General Masters",
    repo: "General Master",
    icon: <DynamicFeedIcon fontSize={"small"} />,
    routePath: Routes.generalMaster,
    isMenu: true
  },
  {
    id: 8,
    name: "Visit Registration",
    repo: "Patient Check-in",
    icon: <HowToRegRoundedIcon fontSize={"small"} />,
    routePath: Routes.patientRegistration,
    isMenu: true
  },
  {
    id: 9,
    name: "Task Viewer",
    repo: "Order Viewer",
    icon: <AssignmentTurnedInIcon fontSize={"small"} />,
    routePath: Routes.taskViewer,
    isMenu: true
  },
  {
    id: 10,
    name: "Order Configurator",
    repo: "Order Configurator",
    icon: <MdOutlineFoodBank size={"1.3rem"} />,
    routePath: Routes.orderConfigurator,
    isMenu: true
  },
  {
    id: 11,
    name: "Drug Ordering",
    repo: "Order Catalog",
    icon: <MedicationIcon fontSize={"small"} />,
    routePath: Routes.kitchenMenu,
    isMenu: true
  },
  {
    id: 12,
    name: "Manage Forms",
    repo: "Manage Forms",
    icon: <AssessmentOutlinedIcon fontSize={"small"} />,
    routePath: Routes.manageForms,
    isMenu: true
  },
  {
    id: 13,
    name: "Registration Viewer",
    repo: "Registration Viewer",
    icon: <GroupRoundedIcon fontSize={"small"} />,
    routePath: Routes.registrationviewer,
    isMenu: true
  },
  {
    id: 14,
    name: "Price Management",
    repo: "Price Management",
    icon: <LocalAtmIcon fontSize={"small"} />,
    routePath: Routes.pricehistory,
    isMenu: true
  },
  {
    id: 15,
    name: "Registration",
    repo: "Registration",
    icon: <PermIdentityIcon fontSize={"small"} />,
    routePath: Routes.userregistration,
    isMenu: true
  },
  {
    id: 16,
    name: "Item Master",
    repo: "Item Master",
    icon: <MedicalServicesIcon fontSize={"small"} />,
    routePath: Routes.itemMaster,
    isMenu: true
  },
  {
    id: 17,
    name: "Accounts",
    repo: "Accounts",
    icon: <AccountBalanceWalletIcon fontSize={"small"} />,
    routePath: Routes.accounts,
    isMenu: true
  },
  {
    id: 18,
    name: "Tutorials",
    repo: "Tutorials",
    icon: <AutoStories fontSize={"small"} />,
    routePath: Routes.tutorials,
    isMenu: true
  },
  {
    id: 19,
    name: "Pharmacy Dashboard",
    repo: "Pharmacy Dashboard",
    icon: <HomeIcon fontSize={"small"} />,
    routePath: Routes.PharmacyDashboard,
    isMenu: false
  },
  {
    id: 20,
    name: "My Cart",
    repo: "My Cart",
    icon: <FaCartPlus size={"1.3rem"} />,
    routePath: Routes.mycart,
    isMenu: false
  },
  {
    id: 21,
    name: "My Order",
    repo: "My Order",
    icon: <FaCartPlus size={"1.3rem"} />,
    routePath: Routes.myorders,
    isMenu: false
  },
  {
    id: 22,
    name: "View Cart",
    repo: "View Cart",
    icon: <FaCartPlus size={"1.3rem"} />,
    routePath: Routes.viewcart,
    isMenu: false
  }
];

export const notifyTypeBesedOnEntryStatus = {
  notify: {
    1: "1",
    9: "2",
  },
  verify: {
    2: "6",
    3: "3",
    10: "7",
    11: "8",
  },
  register: {
    7: "9",
    8: "4",
    10: "10",
    11: "11",
  },
  investigate: {
    5: "12",
    6: "5",
  },
};
export const DependencyType = [
  {
    value: "form",
    label: "Form",
  },
  {
    value: "section",
    label: "Sections",
  },
  {
    value: "question",
    label: "Question",
  },
];

export const FormComponents = {
  single_tab_select: "single_tab_select",
  multi_tab_select: "multi_tab_select",
  input_text: "input_text",
  input_textarea: "input_textarea",
  input_date_picker: "input_date_picker",
  multi_select_tag: "multi_select_tag",
  custom_multi_select_tag: "custom_multi_select_tag",
  select_modal_dropdown: "select_modal_dropdown",
  list_text: "list_text",
  input_number: "input_number",
  hours_minutes_range: "hours_minutes_range",
  grade: "grade",
  image_picker: "image_picker",
  switch_tab: "switch_tab",
  range_picker: "range_picker",
  popup_switch: "popup_switch",
  label: "label",
  note: "note",
  check_box: "check_box",
  radio_button: "radio_button",
  location: "location",
  custom_table: "custom_table",
  custom_table_2: "custom_table_2",
  mobile_no: "mobile_no",
  location_level: "location_level",
  date_of_birth: "date_of_birth",
  file_upload: "file_upload",
  Image_upload: "Image_upload",
  Image_only_upload: "Image_only_upload",
  input_time_picker: "input_time_picker",
  input_datetime_picker: "input_datetime_picker",
  select_modal_dropdown_qdm: "select_modal_dropdown_qdm",
};

/* Responsive Resolution sizes of devices */
export const size = {
  mobileXS: "280px",
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
};

/*max and min Resolution sizes of devices */
export const device = {
  mobileXS: `(min-width: ${size.mobileXS})`,
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,
};

export const GetLastSevenDays = () => {
  let getlast7days = Array.from({ length: 7 }, (e, i) => {
    let cur = new Date();
    let temp = new Date(cur);

    let val = new Date(temp.setDate(cur.getDate() - i));
    return {
      label: `${val.getDate()} ${val.toLocaleDateString("en", {
        month: "short",
      })}`,
      value: val.getDate(),
    };
  });
  return getlast7days.reverse();
};

export const Months = () => {
  const months = Array.from({ length: 12 }, (e, i) => {
    return new Date(null, i + 1, null).toLocaleDateString("en", {
      month: "short",
    });
  });
  return months;
};

export const stringToColor = (string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};
export const genderData = [
  {
    value: "Female",
    label: "Female",
  },
  {
    value: "Male",
    label: "Male",
  },
];
