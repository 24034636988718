import React from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { TreeItem, TreeView } from "@material-ui/lab";
import { Typography, makeStyles } from "@material-ui/core";
// import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import { AlertContext } from "../../contexts";
import { AlertProps } from "../../utils";
import { log } from "qdm-audit-logger";
// import { SubHeader } from "../../components";
import { StyledTreeItem } from "../../componentsV2";
import { SubHeader } from "../../components/common/subHeader2";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: "0px 20px",
    height: "100vh",
    overflowY: "auto",
    // maxWidth: 400,
  },
  root1: {
    flexGrow: 1,
    // maxWidth: 400,
  },
  labelRoot: {
    display: "flex",
    alignItems: "center",
    padding: 6,
    // justifyContent: "space-between",
  },
  activeTreeSection: {
    // backgroundColor: 'rgba(63, 81, 181, 0.08)',
    display: "flex",
    alignItems: "center",
    padding: 6,
    // justifyContent: "space-between",
    // color: theme.palette.primary.dark,
    // borderLeft: '3px solid ' + theme.palette.primary.light,
    borderRadius: 4,
  },
  labelText: {
    overflowWrap: "anywhere",
  },
  emptyForm: {
    marginTop: "34vh",
    alignItems: "center",
    // marginLeft: theme.spacing(4)
  },
  list: {
    paddingTop: 4,
    "& .MuiTreeItem-label": {
      paddingLeft: 0,
      // borderRadius: theme.spacing(1)
    },
  },
  blocklist: {
    paddingTop: 4,
    "& .MuiTreeItem-label": {
      paddingLeft: 0,
      // borderRadius: theme.spacing(1),
      // background:"red"
    },
  },
  badge: {
    // marginLeft: theme.spacing(1),
    padding: "0px 8px",
    // background: theme.palette.common.white,
    // color: theme.palette.ternary.main,
    fontFamily: "ippc_extrabold !important",
    // border: `0.2px solid ${theme.palette.ternary.main}`,
    borderRadius: 12,
  },
}));

export const FormTreeView = ({
  tree_view,
  getSectionId,
  selected_section_id,
  selected_form = {},
  answer = {},
  view,
  historyOfViewedForms,
  setHistoryOfViewedForms,
  isReadyOnly,
  isEdit,
  answerobj,
  title,
}) => {
  const classes = useStyles();
  const alert = React.useContext(AlertContext);
  const [temp, setTempData] = React.useState(selected_form);

  const [expanded, setExpanded] = React.useState(selected_form?.expanded ?? []);
  const [selected, setSelected] = React.useState([selected_form]);
  const [allkeys, setallKeys] = React.useState([]);

  React.useEffect(() => {
    // console.log("selected_form", selected_form);
    setExpanded(selected_form?.expanded);
    setSelected([selected_section_id]);
  }, [selected_form, selected_section_id]);

  React.useEffect(() => {
    if (view?.length === 0) {
      setHistoryOfViewedForms([]);
    }
    // eslint-disable-next-line
  }, [view]);

  const fillFormAlert = () => {
    alert.setSnack({
      ...alert,
      open: true,
      severity: AlertProps.severity.warning,
      msg: "Please Fill and Save the Previous Form To continue !",
      vertical: AlertProps.vertical.top,
      horizontal: AlertProps.horizontal.center,
    });
  };

  const canIableToClick = (node) => {
    // console.log(node?.dependent, "node?.dependent");
    if (node?.dependent) {
      let valueArr = node?.dependent?.option?.map((_) => _.value) ?? [];

      if (
        valueArr.indexOf(answer[node?.dependent?.parent_question_id]?.answer) >
        -1
      ) {
        return true;
      }

      return false;
    }
    return true;
  };
  // const isRead = isReadyOnly;

  // let isAlreadyVisted = historyOfViewedForms?.map((item) => {
  //   return item[1]?.id;
  // });

  const filledIcon = (menu, istypeQuestion) => {
    if (istypeQuestion) {
      return (
        <>
          {checkAnswerFilled(menu?.id) ? (
            <CheckCircleOutlineIcon style={{ color: "#4caf50" }} />
          ) : (
            <RadioButtonUncheckedIcon />
          )}
        </>
      );
    } else {
      return null;
    }
  };

  const getAllKeyExpaned = (nodes, istypeQuestion) => {
    if (!istypeQuestion && nodes?.child_sections?.length > 0) {
      allkeys.push(nodes.id);
    }
  };

  const renderTree = (nodes, istypeQuestion) => {
    // let isAllow = isAlreadyVisted?.includes(nodes?.id);
    // console.log("nodes", nodes);
    getAllKeyExpaned(nodes, istypeQuestion);
    return (
      <StyledTreeItem
        key={nodes.id}
        collapseIcon={
          <ExpandMoreIcon id={`${canIableToClick(nodes)}`} color="primary" />
        }
        expandIcon={
          <ChevronRightIcon id={`${canIableToClick(nodes)}`} color="primary" />
        }
        nodeId={nodes.id}
        label={label_type(nodes, istypeQuestion)}
        labelIcon={filledIcon(nodes, istypeQuestion)}
        id={`${canIableToClick(nodes)}`}
        onClick={() => onClickSection(nodes, istypeQuestion)}
        // className={classes.list}
      >
        {Array.isArray(nodes.child_sections) &&
        nodes?.child_sections?.length > 0 ? (
          <Typography component="h6" className={classes.sublist}>
            {nodes.child_sections.map((node) => renderTree(node, false))}
          </Typography>
        ) : nodes?.child_sections?.length === 0 &&
          nodes?.framed_questions?.length > 0 ? (
          <Typography component="h6" className={classes.sublist}>
            {nodes.framed_questions.map((node) => renderTree(node, true))}
          </Typography>
        ) : null}
      </StyledTreeItem>
    );
  };
  // console.log("answerobj", answerobj);
  const checkAnswerFilled = (quesID) => {
    // console.log(quesID, "quesID");
    if (Object.keys(answerobj).length > 0) {
      if (Array.isArray(answerobj[quesID]?.answer)) {
        // console.log(answerobj[quesID], "ans");
        return answerobj[quesID]?.answer.length > 0 ? true : false;
      } else if (typeof answerobj[quesID]?.answer === "object") {
        if (answerobj[quesID]?.answer !== null) {
          let validObjArr = Object.keys(answerobj[quesID]?.answer).map((key) =>
            answerobj[quesID].answer[key].length > 0 ? true : false
          );

          return !validObjArr.includes(false);
        } else {
          return false;
        }
      } else {
        // console.log(answerobj[quesID], "ans");

        let ret = answerobj[quesID]?.answer.length > 0 ? true : false;
        return ret;
      }
    } else {
      return false;
    }
  };

  const getAnsweredCount = (ques) => {
    return ques.map((q) => checkAnswerFilled(q.id)).filter((e) => e === true)
      .length;
    // let newArr = ques.map((l) => answerobj(l.id));
  };

  const label_type = (menu, istypeQuestion) => {
    const type = istypeQuestion
      ? "Question"
      : menu?.is_form
      ? "Form"
      : "Section";

    const is_section_type = menu?.section_type;

    return (
      <div
        id={`${canIableToClick(menu)}`}
        className={
          selected_section_id === menu.id
            ? classes.activeTreeSection
            : classes.labelRoot
        }
      >
        {is_section_type >= 0 && !istypeQuestion && (
          <>
            {type === "Section" && (
              <>
                <Typography
                  id={`${canIableToClick(menu)}`}
                  variant={"body2"}
                  color={
                    canIableToClick(menu) ? "textPrimary" : "textSecondary"
                  }
                  className={classes.labelText}
                >
                  {menu?.section_name}
                  {is_section_type === 0 && " (" + type + ")"}
                  {is_section_type > 0 && (
                    <Typography
                      id={`${canIableToClick(menu)}`}
                      variant="caption"
                      className={classes.badge}
                      component="span"
                    >
                      {is_section_type === 1
                        ? "Verification"
                        : is_section_type === 2
                        ? "Registration"
                        : "Investigation"}
                    </Typography>
                  )}
                </Typography>
              </>
            )}

            {type === "Form" && (
              <Typography
                id={`${canIableToClick(menu)}`}
                variant={"body2"}
                color={canIableToClick(menu) ? "textPrimary" : "textSecondary"}
                className={classes.labelText}
              >
                {`${menu?.section_name} (${type} ${getAnsweredCount(
                  menu?.framed_questions
                )} / ${menu?.framed_questions?.length})`}
              </Typography>
            )}
          </>
        )}

        {istypeQuestion && (
          <Typography
            id={`${canIableToClick(menu)}`}
            variant={"body2"}
            color={canIableToClick(menu) ? "textPrimary" : "textSecondary"}
            className={classes.labelText}
          >
            {`${menu.question} (${type})`}
          </Typography>
        )}
        {/* {istypeQuestion && checkAnswerFilled(menu?.id) && (
          <CheckCircleIcon style={{ color: "#4caf50" }} />
        )} */}
      </div>
    );
  };

  const onClickSection = (data, istypeQuestion) => {
    // //
    if (canIableToClick(temp) && istypeQuestion) {
      getSectionId(temp);
      // answerNodeId(temp);
    } else if (data.is_form && canIableToClick(data)) {
      setTempData(data);
    }
    // console.log("node data", data);
  };

  const handleToggle = (event, nodeIds) => {
    //
    if (event.target.id === "true") {
      setExpanded(nodeIds);
    }
  };

  const handleSelect = (event, nodeIds) => {
    //
    if (event.target.id === "true") {
      setSelected(nodeIds);
    }
  };

  const onExpanedAllClicked = () => {
    // expanded.length > 0 ? setExpanded() : setExpanded(allkeys);
    setExpanded((pre) => (pre.length > 0 ? allkeys : []));
  };

  return (
    <div className={classes.root}>
      <SubHeader
        hideBackBtn={true}
        title={title}
        buttonText={expanded?.length === 0 ? "Expand All" : "Collapse All"}
        hideHeaderBtn={true}
        hideSearch={true}
        onClickAddNewButton={onExpanedAllClicked}
      />
      {tree_view.map((_, key) => (
        <TreeView
          key={key}
          className={classes.root1}
          defaultCollapseIcon={<ExpandMoreIcon color="primary" />}
          defaultExpandIcon={<ChevronRightIcon color="primary" />}
          expanded={expanded}
          selected={selected}
          onNodeToggle={handleToggle}
          onNodeSelect={handleSelect}
          defaultExpanded={selected_form.expanded}
          defaultSelected={selected_form.id}
        >
          {renderTree(_, false)}
        </TreeView>
      ))}

      {tree_view?.length === 0 && (
        <Typography
          variant="subtitle2"
          color="textSecondary"
          className={classes.emptyForm}
        >
          {"Section/Form not added yet."}
        </Typography>
      )}
    </div>
  );
};
