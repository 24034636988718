import {
  Card,
  CardContent,
  Grid,
  IconButton,
  makeStyles,
  Typography,
  Avatar,
} from "@material-ui/core";
import React from "react";
import { AxiosCall } from "../../utils";
import Chart from "./charts";
import { deepOrange, deepPurple } from "@material-ui/core/colors";
import { MdDoneAll } from "react-icons/md";
import { MdOutlineNotificationsActive } from "react-icons/md";
import { IoNewspaperOutline } from "react-icons/io5";
import { IoBusinessSharp } from "react-icons/io5";
import { FaUsersCog } from "react-icons/fa";
import axios from "axios";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";
import Transactions from "./components/transactionCard";
import Paper from "@material-ui/core/Paper";
import StorageIcon from "@material-ui/icons/Storage";
import ReportsLineChart from "./components/charts/lineReportChart";

//success
// background-image: linear-gradient(to right bottom, #388e3c, #4b9c4e, #5daa60, #6fb972, #81c784);
//background-image: linear-gradient(to right bottom, #d65db5, #ea679f, #f3778e, #f48a84, #f09e81);

//info
//background-image: linear-gradient(to right bottom, #080a96, #373db1, #5c67ca, #8291df, #adbcf2);

//warning
//background-image: linear-gradient(to right bottom, #f94c0f, #f96431, #f8794d, #f58c67, #f09e81);
//background-image: linear-gradient(to right bottom, #f88b09, #f9a53e, #fabc66, #fcd28e, #ffe6b8);

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    // color: "#ffffff",
    height: "100%",
    cursor: "pointer",
    borderRadius: "16px",
    // display: "flex",
  },
  avatarColor: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
  cardFacility: {
    // color: "#000",
    // display: "flex",
    // backgroundImage:
    //   "linear-gradient(to right bottom, #2c73d7, #008ce0, #00a1e2, #0fb5df, #56c7dc)",
  },
  cardUser: {
    // backgroundImage:
    // "linear-gradient(to right bottom, #d65db5, #ea679f, #f3778e, #f48a84, #f09e81)",
  },
  cardNotify: {
    // backgroundImage:
    //   "linear-gradient(to right bottom, #004bff, #0062f7, #1e73ea, #4782da, #698fc7)",
  },
  cardVerify: {
    // backgroundImage:
    //   "linear-gradient(to right bottom, #388e3c, #4b9c4e, #5daa60, #6fb972, #81c784)",
  },
  cardPending: {
    // backgroundImage:
    //   "linear-gradient(to right bottom, #d32f2f, #e1682a, #e99632, #ecc24d, #ebeb77)",
  },
  cardReject: {
    // backgroundImage:
    //   "linear-gradient(to right bottom, #d32f2f, #de4542, #e95856, #f26a69, #fb7c7c)",
  },
  iconAlign: {
    // position: "absolute",
    // bottom: "6px",
    // right: "12px",
    // fontSize: "3rem",
    // color: "#ffffff",
  },
}));

const ClientDashBoard = (props) => {
  // console.log({ props });
  // const { clientId } = props;
  const classes = useStyles();
  const [clientId, setClientId] = React.useState();

  const [facilityCount, setFacilityCount] = React.useState(0);

  const [userCount, setUserCount] = React.useState(0);
  const [formCount, setFormCount] = React.useState(0);
  const [drugOrderCount, setDrugOrderCount] = React.useState(0);

  // const [verifyCount, setVerifyCount] = React.useState(0);
  // const [rejectCount, setRejectCount] = React.useState(0);
  const [state, setState] = React.useState([]);

  React.useEffect(() => {
    getPersonLoggedIN();
  }, []);

  const getPersonLoggedIN = async () => {
    let tenentid = process.env.REACT_APP_IDM_TENANT_ID;
    let params = {
      db_name: process.env.REACT_APP_DB,
      entity: "client",
      filter: `client.tenantid=='${tenentid}' && client.activestatus==true`,
      return_fields: "client",
    };
    let res = await AxiosCall(
      "post",
      `${process.env.REACT_APP_BASE_URL_HOST}/api/read_documents`,
      params
    );
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_ARANGO_URL_READ}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: params,
    };
    axios(config)
      .then((response) => {
        let res = response.data;
        if (res.result.length > 0) {
          let result = res?.result[0]?._id;
          getFacilityCount(result);
          getFormCount(result);
          getUserCount(tenentid);
          // getVerifiedCount(result);
          // getPendingCount(result);
          // getRejectCount(result);
          getStateCountList(result);
          setClientId(result);
          // setLoading(false);
        } else {
          setClientId(null);
          // setLoading(false);
        }
      })
      .catch((error) => {
        // console.log(error);
      });
    getDrugOrder(props?.perosnid, props?.orgId);
    getFacilityCount(props?.orgId);
  };

  const getFacilityCount = async (orgid) => {
    var data = {
      db_name: process.env.REACT_APP_DB,
      query: `let a=(for Facility in Facility filter Facility.OrgCode=='${orgid}' && Facility.activestatus==true return Facility) let b=(for Ward in Ward filter a[*]._id any == Ward.FacCode && Ward.activestatus==true return Ward) let c=(for Room in Room filter b[*]._id any == Room.WardCode && Room.activestatus==true return Room) let d=(for Bed in Bed filter c[*]._id any == Bed.RoomCode && Bed.activestatus==true return keep(Bed,'_id')) for DrugOrder in DrugOrder filter document(DrugOrder.PersonId).OrgID[*].id any=='${orgid}' && d[*]._id any ==DrugOrder.PharmacyDetails._id && DrugOrder.activestatus==true collect Bed=d AGGREGATE Count = LENGTH(1) return {Bed,Count}`,
    };

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_ARANGO_API}/api/execute_aql`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        // console.log("log", response.data);
        setFacilityCount(response.data?.[0]?.Count);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getUserCount = async (tenantid) => {
    try {
      let params = {
        db_name: process.env.REACT_APP_DB,
        filter: { tenantid: tenantid },
        queryid: "8e820040-9bd8-4ced-b6fb-9b30add2333a",
      };
      let res = await AxiosCall(
        "post",
        `${process.env.REACT_APP_ARANGO_URL_READ_QDMQUERIES}`,
        params
      );

      if (res?.collection) {
        let result = res?.collection[0];

        setUserCount(result?.usercount);
      } else {
        let result = res[0];

        setUserCount(result?.usercount);
      }
    } catch (error) { }
  };

  const getFormCount = async (clientId) => {
    try {
      let params = {
        db_name: process.env.REACT_APP_DB,
        filter: { clientid: clientId },
        queryid: "92150fcb-a670-44b7-a124-188be9a3cc03",
      };
      let res = await AxiosCall(
        "post",
        `${process.env.REACT_APP_ARANGO_URL_READ_QDMQUERIES}`,
        params
      );

      if (res?.collection) {
        let result = res?.collection[0];
        setFormCount(result?.formcount);
      } else {
        let result = res[0];
        setFormCount(result?.formcount);
      }
    } catch (error) { }
  };

  const getDrugOrder = async (personid, orgid) => {
    var data = {
      db_name: process.env.REACT_APP_DB,
      query: `let a=(for Facility in Facility filter Facility.OrgCode=='${orgid}' && Facility.activestatus==true return Facility) let b=(for Ward in Ward filter a[*]._id any == Ward.FacCode && Ward.activestatus==true return Ward) let c=(for Room in Room filter b[*]._id any == Room.WardCode && Room.activestatus==true return Room) let d=(for Bed in Bed filter c[*]._id any == Bed.RoomCode return Bed) for DrugOrder in DrugOrder filter DrugOrder.PersonId=='${personid}' && document(DrugOrder.PersonId).OrgID[*].id any=='${orgid}' && d[*]._id any ==DrugOrder.PharmacyDetails._id collect with count into length return length`,
    };

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_ARANGO_API}/api/execute_aql`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log("lo", response.data);
        setDrugOrderCount(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  // const getVerifiedCount = async (clientId) => {
  //   try {
  //     let params = {
  //       db_name: process.env.REACT_APP_DB,
  //       filter: { clientid: clientId },
  //       queryid: "12ce6749-5226-41bd-ba83-db40a701edea",
  //     };
  //     let res = await AxiosCall(
  //       "post",
  //       `${process.env.REACT_APP_ARANGO_URL_READ_QDMQUERIES}`,
  //       params
  //     );
  //     let result = res?.collection[0];

  //     setVerifyCount(result?.verifiedcount);
  //   } catch (error) {}
  // };

  // const getRejectCount = async (clientId) => {
  //   try {
  //     let params = {
  //       db_name: process.env.REACT_APP_DB,
  //       filter: { clientid: clientId },
  //       queryid: "208af419-fa34-4c4e-9496-4957daa2f118",
  //     };
  //     let res = await AxiosCall(
  //       "post",
  //       `${process.env.REACT_APP_ARANGO_URL_READ_QDMQUERIES}`,
  //       params
  //     );
  //     let result = res?.collection[0];

  //     setRejectCount(result?.rejectedcount);
  //   } catch (error) {}
  // };

  const getStateCountList = async (clientId) => {
    try {
      let params = {
        db_name: process.env.REACT_APP_DB,
        filter: { clientid: clientId },
        queryid: "3b6d7996-30cf-4075-ab2b-b164fd9f33e4",
      };
      let res = await AxiosCall(
        "post",
        `${process.env.REACT_APP_ARANGO_URL_READ_QDMQUERIES}`,
        params
      );

      if (res?.collection) {
        let result = res?.collection;

        setState(result);
      } else {
        let result = res;

        setState(result);
      }
    } catch (error) { }
  };
  const geoUrl =
    "https://raw.githubusercontent.com/deldersveld/topojson/master/continents/south-america.json";

  const markers = [
    {
      markerOffset: -15,
      name: "Buenos Aires",
      coordinates: [-58.3816, -34.6037],
    },
    { markerOffset: -15, name: "La Paz", coordinates: [-68.1193, -16.4897] },
    { markerOffset: 25, name: "Brasilia", coordinates: [-47.8825, -15.7942] },
    { markerOffset: 25, name: "Santiago", coordinates: [-70.6693, -33.4489] },
    { markerOffset: 25, name: "Bogota", coordinates: [-74.0721, 4.711] },
    { markerOffset: 25, name: "Quito", coordinates: [-78.4678, -0.1807] },
    { markerOffset: -15, name: "Georgetown", coordinates: [-58.1551, 6.8013] },
    { markerOffset: -15, name: "Asuncion", coordinates: [-57.5759, -25.2637] },
    { markerOffset: 25, name: "Paramaribo", coordinates: [-55.2038, 5.852] },
    { markerOffset: 25, name: "Montevideo", coordinates: [-56.1645, -34.9011] },
    { markerOffset: -15, name: "Caracas", coordinates: [-66.9036, 10.4806] },
    { markerOffset: -15, name: "Lima", coordinates: [-77.0428, -12.0464] },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={3} lg={3}>
        <Card component={Paper} elevation={1} className={`${classes.cardRoot}`}>
          <CardContent style={{ display: "flex" }}>
            {/* <Typography variant={"h5"} gutterBottom>
              Facilities
            </Typography>
            <Typography variant={"h4"} style={{fontSize:"1.5rem" ,fontWeight:"700"}}>{facilityCount}</Typography>
            <IoBusinessSharp className={classes.iconAlign} /> */}
            <div style={{ flex: "1 1 auto" }}>
              <Typography variant={"h6"} color={"textSecondary"} gutterBottom>
                Total Pharmacy
              </Typography>
              <Typography
                variant={"h4"}
                style={{ fontSize: "1.5rem", fontWeight: "700" }}
              >
                {facilityCount}
              </Typography>
            </div>
            <div
              style={{
                flex: "0 0 auto",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Avatar className={classes.avatarColor}>
                <IoBusinessSharp />
              </Avatar>
            </div>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={3}>
        <Card className={`${classes.cardRoot} ${classes.cardUser}`}>
          <CardContent style={{ display: "flex" }}>
            {/* <Typography variant={"h5"} gutterBottom>
              Active Users
            </Typography>
            <Typography variant={"h4"} style={{fontSize:"1.5rem" ,fontWeight:"700"}}>{userCount}</Typography>
            <FaUsersCog className={classes.iconAlign} /> */}
            <div style={{ flex: "1 1 auto" }}>
              <Typography variant={"h6"} color={"textSecondary"} gutterBottom>
                Total Users
              </Typography>
              <Typography
                variant={"h4"}
                style={{ fontSize: "1.5rem", fontWeight: "700" }}
              >
                {userCount === "0" ? userCount : "10"}
              </Typography>
            </div>
            <div
              style={{
                flex: "0 0 auto",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Avatar className={classes.avatarColor}>
                <FaUsersCog />
              </Avatar>
            </div>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={3}>
        <Card className={`${classes.cardRoot} ${classes.cardNotify}`}>
          <CardContent style={{ display: "flex" }}>
            {/* <Typography variant={"h5"} gutterBottom>
              Form Notified
            </Typography>
            <Typography variant={"h4"} style={{fontSize:"1.5rem" ,fontWeight:"700"}}>{formCount}</Typography>
            <MdOutlineNotificationsActive className={classes.iconAlign} /> */}
            <div style={{ flex: "1 1 auto" }}>
              <Typography variant={"h6"} color={"textSecondary"} gutterBottom>
                Total Orders
              </Typography>
              <Typography
                variant={"h4"}
                style={{ fontSize: "1.5rem", fontWeight: "700" }}
              >
                {drugOrderCount === "0" ? drugOrderCount : "143"}
              </Typography>
            </div>
            <div
              style={{
                flex: "0 0 auto",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Avatar className={classes.avatarColor}>
                <MdOutlineNotificationsActive />
              </Avatar>
            </div>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} sm={6} md={3} lg={3}>
        <Card className={`${classes.cardRoot} ${classes.cardNotify}`}>
          <CardContent style={{ display: "flex" }}>
            {/* <Typography variant={"h5"} gutterBottom>
              Form Notified
            </Typography>
            <Typography variant={"h4"} style={{fontSize:"1.5rem" ,fontWeight:"700"}}>{formCount}</Typography>
            <MdOutlineNotificationsActive className={classes.iconAlign} /> */}
            <div style={{ flex: "1 1 auto" }}>
              <Typography variant={"h6"} color={"textSecondary"} gutterBottom>
                Today's Orders
              </Typography>
              <Typography
                variant={"h4"}
                style={{ fontSize: "1.5rem", fontWeight: "700" }}
              >
                {drugOrderCount === "0" ? drugOrderCount : "23"}
              </Typography>
            </div>
            <div
              style={{
                flex: "0 0 auto",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Avatar className={classes.avatarColor}>
                <StorageIcon />
              </Avatar>
            </div>
          </CardContent>
        </Card>
      </Grid>

      {state?.map((l) => (
        <Grid item xs={12} sm={6} md lg>
          <Card className={`${classes.cardRoot}`}>
            <CardContent style={{ display: "flex" }}>
              {/* <Typography variant={"h5"} gutterBottom>
              Form Verified
            </Typography>
            <Typography variant={"h4"} style={{fontSize:"1.5rem" ,fontWeight:"700"}}>{verifyCount}</Typography>
            <MdDoneAll className={classes.iconAlign} /> */}
              <div style={{ flex: "1 1 auto" }}>
                <Typography variant={"h5"} gutterBottom>
                  {l?.State}
                </Typography>
                <Typography
                  variant={"h4"}
                  style={{ fontSize: "1.5rem", fontWeight: "700" }}
                >
                  {l.Count}
                </Typography>
              </div>
              <div
                style={{
                  flex: "0 0 auto",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {l?.ICON ? (
                  <Avatar className={classes.avatarColor}>
                    <img
                      style={{ width: "20px", height: "20px" }}
                      src={l?.ICON}
                      alt="icon"
                    />
                  </Avatar>
                ) : (
                  <Avatar className={classes.avatarColor}>
                    <IoNewspaperOutline />
                  </Avatar>
                )}
              </div>
            </CardContent>
          </Card>
        </Grid>
      ))}

      <Grid container item lg={12} md={12} xs={12} justifyContent="flex-start" alignItems="center" >
        {/* <div style={{ width: "50%" }}>
          <ComposableMap>
            <Geographies geography="/topo.json">
              {({ geographies }) =>
                geographies.map((geo) => (
                  <Geography key={geo.rsmKey} geography={geo} />
                ))
              }
            </Geographies>
          </ComposableMap>
        </div> */}
        <Typography variant={"h6"} gutterBottom style={{ marginLeft: "0px" }}>
          Pharmacy Locations
        </Typography>
        <Grid container item lg={12} md={12} xs={12} justifyContent="center" alignItems="center">
          <iframe width="100%" height="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://www.openstreetmap.org/export/embed.html?bbox=102.78602600097658%2C2.293727215013488%2C102.84439086914064%2C2.3378081580445267&amp;layer=mapnik&amp;marker=2.31575473938356%2C102.8152084350586" style={{ border: "none", height: "40vh" }}></iframe><br /><small><a href="https://www.openstreetmap.org/?mlat=2.3158&amp;mlon=102.8152#map=14/2.3158/102.8152&amp;layers=N">View Larger Map</a></small>

        </Grid>

      </Grid>

      <Grid item xs={12} md={6} lg={4}>
        <Card
          className={`${classes.cardRoot} `}
        // style={{
        //   display: "flex",
        //   justifyContent: "center",
        //   alignItems: "center",
        // }}
        >
          <ReportsLineChart
            color="success"
            title="Progress Viewer"
            description="Last Campaign Performance"
            date="just updated"
          // chart={tasks}
          />
        </Card>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={4}>
        <Card
          style={{
            padding: "16px",
            borderRadius: "16px",
          }}
        >
          <Transactions />
        </Card>
      </Grid>
    </Grid>
  );
};

export default ClientDashBoard;
