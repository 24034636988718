import React from 'react'
import { Typography, Grid, makeStyles, Button, IconButton, TextField } from '@material-ui/core'
import { Select } from '../../components';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { useSelector } from "react-redux";

const useStyles = makeStyles(theme => ({
    root: {
        flex: 1
    },
    gridItem: {
        padding: theme.spacing(2),
        '& input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button': {
            margin: 0,
            display: 'none'
        }
    },
}))

export const ContactDetail = ({ changeState = () => false, contactdetails }) => {
    const classes = useStyles();

    const orgContactDetailMode = useSelector(
        state => state?.organizationSlice?.orgContactDetailMode,
    )


    const orgContactDetailUseData = useSelector(
        state => state?.organizationSlice?.orgContactDetailUse
    )

    const priorityData = useSelector(
        state => state?.organizationSlice?.["0"]
    )

    const updateState = (key, value, index) => {
        const newcontactdetails = contactdetails?.map((input, i) =>
            index === i ? Object.assign(input, { [key]: value }) : input
        )
        changeState("contactdetails", newcontactdetails)
    };

    const onDeleteClicked = (index) => {
        let Updatelist = contactdetails.filter((item, i) => i !== index)
        changeState("contactdetails", Updatelist)

    }


    const onAddNewContact = () => {
        changeState("contactdetails", [...contactdetails, {
            contactmode: {},
            email: "",
            use: {},
            priority: {}
        }])
    }

    return (
        <div style={{
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            // border: '1px solid #E0E0E0',
            'border-radius': '8px',
            opacity: 1
        }}>

            <div style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
            }}>
                <Typography variant='body1'>Contact Details</Typography>
                <Button
                    id={"Button1T6"}
                    variant='text'
                    color='primary'
                    onClick={() => onAddNewContact()}
                >
                    +Add New Contact
                </Button>
            </div>
            {
                contactdetails?.map((state, index) => (
                    <Grid key={index} container direction="row" display="flex" alignItems="center">
                        <Grid item xs={12} sm={6} lg={3} className={classes.gridItem}>
                            <Select
                                id="mobileno"
                                className="basic-single"
                                classNamePrefix="select"
                                label={"Contact Mode"}
                                isLoading={orgContactDetailMode?.loading}
                                isClearable
                                // isDisabled={!isAdmin}
                                isSearchable
                                value={state?.contactmode}
                                placeholder="Type"
                                option={orgContactDetailMode?.data}
                                handleselect={(value) => updateState('contactmode', value, index)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
                            <Typography gutterBottom variant="body1" >Email</Typography>
                            {/* <Mobile

                                value={state?.number}
                                label={"Number"}
                                handleChange={(value) => updateState("number", value, index)}
                                type={"number"}
                            // errorValidation={errorValidation ?? {}}
                            // disabled={disabled ?? false}
                            /> */}
                            <TextField
                                // id="LastName"
                                variant="outlined"
                                size="small"
                                placeholder={"Email"}
                                fullWidth
                                margin="none"
                                value={state?.email}
                                onChange={(e) => updateState("email", e.target.value, index)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={2} className={classes.gridItem}>
                            <Select
                                id="use"
                                className="basic-single"
                                classNamePrefix="select"
                                label={"Use"}
                                value={state?.use}
                                placeholder="Work"
                                option={orgContactDetailUseData?.data}
                                handleselect={(value) => updateState('use', value, index)}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} lg={2} className={classes.gridItem}>
                            <Select
                                id="Priority"
                                className="basic-single"
                                classNamePrefix="select"
                                label={"Priority"}
                                value={state?.priority}
                                placeholder="01"
                                option={priorityData?.data}
                                handleselect={(value) => updateState('priority', value, index)}
                            />
                        </Grid>


                        <Grid item xs={12} sm={6} lg={1} gutterBottom className={classes.gridItem}>

                            <IconButton style={{ marginTop: "22px" }}
                                id={"deleteBtn"}
                                onClick={() => onDeleteClicked(index)}>
                                <DeleteOutlineOutlinedIcon color="error" />
                            </IconButton>

                        </Grid>

                    </Grid>)
                )
            }



        </div>
    )
}