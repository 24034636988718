import React, { useContext, useState } from "react";
import {
  Button,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import styled from "styled-components";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import PrintIcon from "@material-ui/icons/Print";
import { TagsIcon } from "../../assets";
import { useTheme } from "@material-ui/core";
import Allergy from "../../assets/allergy.png";
import FoodPrefference from "../../assets/foodprefference.png";
import moment from "moment";
import parse from "html-react-parser";
import axios from "axios";
// import FormRendererParent from "../../screens/formRenderer/index"
import { AlertProps, aqlQuery } from "../../utils";
import FormRendererParent from "../../screens/formRendererfinal/index";
//import { FormRenderer } from "../../screens/formRenderer/formRenderer";
//import FormRendererParent from "../../../../../formRenderer";

import { AlertContext, BackdropContext } from "../../contexts";
import { useDispatch } from "react-redux";
import { actions, async } from "central_pharma_binder";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
// import { FormRenderer } from "../../../../../OrgFormRenderer/formRenderer";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
// import Pdf from "@mikecousins/react-pdf";

const useStyles = makeStyles((theme) => ({
  cardHeading: {
    color: "#6C757D",
    fontSize: "16px",
    margin: "5px",
  },
  cardHeadingAnswer: {
    fontSize: "14px",
    margin: "5px",
  },
  InvoiceCss: {
    fontWeight: "525",
    fontSize: "18px",
    margin: "5px",
  },
  tagcolor: {
    color: theme.palette.primary,
  },
}));
export const CardStyled = styled.div`
  padding: ${(props) => (props.m === "large" ? "12px 12px" : "8px 8px")};
  background: ${(props) => (props.selected ? props.hovercolor : "#ffffff")};
  border-top: 1px solid;
  border-bottom: 1px solid;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100px;
  cursor: pointer;
  border-color: #e0e0e0;
  border-radius: ${(props) => (props.type === "main" ? "8px" : "0px")};
  transition: border-color 0.3s ease-in-out;
  &:hover {
    border: 2px solid;
    border-color: ${(props) => props.color};
  }
`;

export const CardStyle = styled(CardStyled)`
  cursor: inherit;
  border: 1px solid #e0e0e0;
  &:hover {
    border: 1px solid #e0e0e0;
  }
`;

export const FlexRow = styled.div`
  display: flex;
  align-items: center;
  margin: 2px;
`;

export const FlexSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    margin: theme.spacing(0.5),
    // border: "none",
    "&:not(:first-child)": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-child": {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))(ToggleButtonGroup);

var debounce;
function debouncefunc(func, event, value) {
  clearTimeout(debounce);
  debounce = setTimeout(() => {
    func(event, value);
  }, 500);
}

export const Cards = (props) => {
  let {
    type,
    index,
    field1,
    field2,
    field3,
    field4,
    field5,
    field6,
    field7,
    field8,
    field9,
    field10 = undefined,
    field11 = undefined,
    field12 = undefined,
    field13 = undefined,
    ticketId,
    state,
    data,
    favourite,
    performUserAction = () => false,
    onCardClicked = () => false,
    onFavIconClicked = () => false,
    action,
    selectedTask,
    alignment,
    updateState,
    PermissionData,
    scenarioId,
  } = props;
  let actionData = [];
  if (action) {
    action?.forEach((e) =>
      PermissionData?.forEach((v) =>
        v.scenarioChildren?.forEach((b) =>
          b.stateChildren?.forEach((n) =>
            n.actionChildren?.forEach(
              (m) =>
                m.repoid.unique_id === e._id &&
                m.permsnread === true &&
                b.repoid.unique_id === scenarioId &&
                actionData.push(e)
            )
          )
        )
      )
    );
  }
  action = [...new Set(actionData)];
  const regex = /<\w+/g;
  const theme = useTheme();
  const backdrop = useContext(BackdropContext);
  const alert = useContext(AlertContext);
  const classes = useStyles();
  const [formOpen, setFormOpen] = useState(false);
  const [formId, setFormID] = useState("");
  const [loading, setLoading] = useState(false);
  const [alignmen, setAlignmen] = useState();
  const [keyNameForFormUpsert, setKeyNameForFormUpsert] = useState("");
  const [formRendering, setFormRendering] = React.useState({});
  const [pdf, setPdf] = React.useState(false);

  const a = new Date(field9 * 1000);
  const dispatch = useDispatch();
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const moments = moment(a).fromNow();
  const toolbarPluginInstance = toolbarPlugin();

  const handleAlignment = async (event, newAlignment) => {
    if (newAlignment?.chooseform) {
      backdrop?.setBackDrop({
        ...backdrop,
        open: true,
        message: "Loading the form",
      });

      setFormID(newAlignment?.chooseform);
      setAlignmen(newAlignment);

      // ;
      let keyname =
        newAlignment?.action?.toLowerCase()?.split(" ")?.join("") + "Details";
      setKeyNameForFormUpsert(keyname);
      getFormData(newAlignment?.chooseform, ticketId, keyname);
    } else {
      await performUserAction(newAlignment, ticketId);
    }
    // updateState(newAlignment);
  };
  const handleSelect = (data) => {
    onCardClicked(data);
  };

  const onClickFav = (data) => {
    onFavIconClicked(data);
  };

  const printFunc = (ticketid) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      ticketId: [`${ticketid}`],
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_PRINT_API, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        axios(result.response, {
          method: "GET",
          responseType: "blob", //Force to receive data in a Blob Format
        })
          .then((response) => {
            //Create a Blob from the PDF Stream
            const file = new Blob([response.data], { type: "application/pdf" });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            window.open(fileURL);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => console.log("error", error));
  };
  // console.log("ticketId", ticketId);

  const getFormFromExecuteAql = async (formId, ticketId, keyname) => {
    let params_for_read_entry = {
      db_name: `${process.env.REACT_APP_DBNAME}`,
      entity: "QDMATPtransactionlog",
      filter: `QDMATPtransactionlog.ticketId=='${ticketId}' && QDMATPtransactionlog.activestatus==true`,

      return_fields: "QDMATPtransactionlog",
    };

    let config_for_read_entry = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: params_for_read_entry,
    };

    await axios(config_for_read_entry)
      .then(async (res) => {
        //  ;
        let entryid =
          res.data?.result?.[0]?.payload?.inputDoc?.[keyname]?.entry_id;

        let params = {
          db_name: `${process.env.REACT_APP_DBNAME}`,
          query: entryid
            ? aqlQuery.getFormDataWithEntryId(formId, entryid)
            : aqlQuery.getFormData(formId, ticketId),
        };
        let config = {
          method: "post",
          url: `${process.env.REACT_APP_READ_DOCUMENT}`,
          headers: {
            "Content-Type": "application/json",
          },
          data: params,
        };

        await axios(config)
          .then((res) => {
            //  ;
            let aqldata = res.data[0];
            let answerArr = aqldata?.bulkAnswers?.answers ?? [];
            let answer = {};
            // console.log(aqldata, "aqldata");
            answerArr?.map((l) => (answer[l.question_id] = l.answer));
            let updateState = {
              formId: formId,
              // formData: aqldata,
              // selected_form: aqldata?.form[0],
              // answer: answer,
              _id: aqldata?.bulkAnswers?.entry_id,
              secName: aqldata?.bulkAnswers?.secName ?? 1,
              entry_id: entryid,
            };
            setFormRendering(updateState);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getEntryId = async (ticketId, keyname) => {
    //  ;
    let params_for_read_entry = {
      db_name: `${process.env.REACT_APP_DBNAME}`,
      entity: "QDMATPtransactionlog",
      filter: `QDMATPtransactionlog.ticketId=='${ticketId}' && QDMATPtransactionlog.activestatus==true`,

      return_fields: "QDMATPtransactionlog",
    };

    let config_for_read_entry = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: params_for_read_entry,
    };

    await axios(config_for_read_entry)
      .then(async (res) => {
        //  ;
        let entryid =
          res.data?.result?.[0]?.payload?.inputDoc?.[keyname]?.entry_id;

        let params = {
          db_name: `${process.env.REACT_APP_DBNAME}`,
          query: entryid
            ? aqlQuery.getFormDataWithEntryId(formId, entryid)
            : aqlQuery.getFormData(formId, ticketId),
        };
        let config = {
          method: "post",
          url: `${process.env.REACT_APP_READ_DOCUMENT}`,
          headers: {
            "Content-Type": "application/json",
          },
          data: params,
        };

        await axios(config)
          .then((res) => {
            //  ;
            let aqldata = res.data[0];
            let answerArr = aqldata?.bulkAnswers?.answers ?? [];
            let answer = {};
            // console.log(aqldata, "aqldata");
            answerArr?.map((l) => (answer[l.question_id] = l.answer));
            let updateState = {
              formId: formId,
              formData: aqldata,
              selected_form: aqldata?.form[0],
              answer: answer,
              _id: aqldata?.bulkAnswers?.entry_id,
              secName: aqldata?.bulkAnswers?.secName ?? 1,
              entry_id: entryid,
            };
            setFormRendering(updateState);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });

    // let params2 = {
    //   db_name: `${process.env.REACT_APP_DBNAME}`,
    //   entity: "form_entries",
    //   filter: `form_entries.entry_id=='${entryid}'&& form_entries.activestatus==true`,
    //   return_fields: "form_entries",
    // };

    // let config2 = {
    //   method: "post",
    //   url: `${process.env.REACT_APP_API_URL}`,
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   data: params2,
    // };

    // return await axios(config2)
    //   .then((res) => {
    //     console.log("Res", res.data[0]);
    //     return {
    //       entry_id: res?.data[0]?.entry_id,
    //     };
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     alert.setSnack({
    //       ...alert,
    //       open: true,
    //       severity: AlertProps.severity.error,
    //       msg: `Something went wrong and check console for error`,
    //       vertical: AlertProps.vertical.top,
    //       horizontal: AlertProps.horizontal.center,
    //     });
    //   });

    // this.setState({
    //   ...this.state,
    //   entry_id: res.data[0].entry_id,
    // })
  };
  const getFormData = async (formid, ticketId, keyname) => {
    // Setloading(true);
    //  ;
    let updateState = await getFormFromExecuteAql(formid, ticketId, keyname);
    setTimeout(() => {
      setFormOpen(!formOpen);
      backdrop?.setBackDrop({ ...backdrop, open: false, message: "" });
    }, 1000);
  };

  const updateEntry = async (data) => {
    //  ;
    setLoading(true);
    backdrop?.setBackDrop({
      ...backdrop,
      open: true,
      message: "Loading the next step",
    });
    // console.log("InnnerData", data);
    let answerArr = data?.data;
    let answer = {};
    let entryid = data?.entry_id ? data?.entry_id : formRendering?.entry_id;
    Object.keys(answerArr).map(
      (l) =>
        (answer[answerArr?.[l]?.questions] =
          answerArr?.[l]?.answer?.length !== 0 ? answerArr?.[l]?.answer : "")
    );
    let params = {
      form_id: formId,
      data: data,
      notifier_status: data?.isDraft ? 9 : 1,
      notifyType: data?.isDraft,
      entry_id: formRendering?.entry_id,
      isEdit: false,
      ticketId: ticketId,
    };
    // await Promise.resolve(
    //   dispatch(actions.UPSERT_ENTRY_ATP_TASK_VIEWER_TREE(params))
    // ).then((res) => {
    //   console.log("DispatchRes", res);
    //   if (res?.payload?.data?.Result) {
    //      ;
    //     entryid = res?.payload?.data?.Result[0]?.properties?.doc?.entry_id;
    //     alert.setSnack({
    //       ...alert,
    //       open: true,
    //       severity: AlertProps.severity.success,
    //       msg: "Form Successfully Submitted",
    //       vertical: AlertProps.vertical.top,
    //       horizontal: AlertProps.horizontal.center,
    //     });
    //   } else {
    //     alert.setSnack({
    //       ...alert,
    //       open: true,
    //       severity: AlertProps.severity.error,
    //       msg: "Something went wrong!",
    //       vertical: AlertProps.vertical.top,
    //       horizontal: AlertProps.horizontal.center,
    //     });
    //   }
    // });
    //  ;
    await performUserAction(
      alignmen,
      ticketId,
      answer,
      keyNameForFormUpsert,
      entryid,
      setLoading
    );
    setFormOpen(false);
    setLoading(false);
    // backdrop?.setBackDrop({ ...backdrop, open: false, message: "" });
  };
  // console.log({ formRendering });
  return (
    <Grid item lg={12} md={12} xs={12} sm={12} key={index}>
      <CardStyle type={"main"} m={"large"}>
        <div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "5px 0px",
                margin: "0px 0px 5px 0px",
              }}
            >
              <Typography style={{ fontWeight: "bold" }}>
                {field1 !== undefined && field1?.match(regex)
                  ? parse(field1)
                  : field1 ?? ""}
              </Typography>
              {field1 && field2 && (
                <Divider
                  flexItem
                  orientation="vertical"
                  style={{ margin: "4px 4px" }}
                />
              )}
              <Typography
                variant=""
                style={{ fontSize: "14px", fontWeight: "600" }}
              >
                {field2}
              </Typography>
            </div>
            <div
              style={{
                display: "inline-flex",
                gap: "20px",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "inline-flex", alignItems: "center" }}>
                <TagsIcon
                  size="0.8rem"
                  color={theme.palette.primary}
                  className={classes.tagcolor}
                />
                <Typography
                  variant="caption"
                  color="GrayText"
                  style={{
                    marginLeft: "8px",
                    fontWeight: "600",
                    fontSize: "14px",
                  }}
                >
                  {state ?? ""}
                </Typography>
              </div>
              <div style={{ margin: "auto 0" }}>
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  startIcon={<GetAppOutlinedIcon />}
                  // onClick={() => setPdf(true)}
                >
                  <a
                    href={field8}
                    style={{
                      textDecoration: "none",
                      color: theme.palette.primary,
                    }}
                  >
                    Invoice
                  </a>
                </Button>
              </div>
              {/* <div style={{ margin: "auto 0" }}>
               
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  startIcon={<GetAppOutlinedIcon />}
                  // onClick={() => setPdf(true)}
                >
                  <a
                    href={field11}
                    style={{
                      textDecoration: "none",
                      color: theme.palette.primary,
                    }}
                  >
                    Delivery Order
                  </a>
                </Button>
              </div> */}
            </div>
          </div>
          <div style={{ display: "inline-flex", gap: "10px" }}>
            <div>
              <Typography variant="body2" className={classes.cardHeading}>
                Invoice date
              </Typography>
              <Typography className={classes.cardHeadingAnswer}>
                {field3}
              </Typography>
            </div>
            <div>
              <Typography variant="body2" className={classes.cardHeading}>
                Invoiced by
              </Typography>
              <Typography className={classes.cardHeadingAnswer}>
                {field4}
              </Typography>
            </div>
            <div>
              <Typography variant="body2" className={classes.cardHeading}>
                Payment term breach in
              </Typography>
              <Typography className={classes.cardHeadingAnswer}>
                {field5} days
              </Typography>
            </div>
            <div>
              <Typography variant="body2" className={classes.cardHeading}>
                Email reminder sent
              </Typography>
              <Typography className={classes.cardHeadingAnswer}>
                {field6}
              </Typography>
            </div>
            <div>
              <Typography variant="body2" className={classes.cardHeading}>
                Invoice total amount
              </Typography>
              <Typography className={classes.InvoiceCss}>
                {" "}
                {`RM ${field7}`}
              </Typography>
            </div>
            <div>
              <Typography variant="body2" className={classes.cardHeading}>
                Due date
              </Typography>
              <Typography className={classes.cardHeadingAnswer}>
                {field9}
              </Typography>
            </div>
            <div>
              <Typography variant="body2" className={classes.cardHeading}>
                Order to
              </Typography>
              <Typography className={classes.cardHeadingAnswer}>
                {field10}
              </Typography>
            </div>
          </div>
          {action && (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <StyledToggleButtonGroup
                size="small"
                value={alignment}
                exclusive
                onChange={(e, v) => {
                  backdrop?.setBackDrop({
                    ...backdrop,
                    open: true,
                    message: "loading",
                  });
                  debouncefunc(handleAlignment, e, v);
                }}
              >
                {action?.map((action, index) => (
                  <ToggleButton
                    size="small"
                    value={action}
                    key={index}
                    // className={classes.ToggleButton}
                    style={{
                      border: `1px solid ${
                        action?.bgcolor?.trim().length > 0
                          ? action?.textcolor
                          : theme.palette.primary.main
                      }`,
                      borderRadius: "4px",
                      padding: "4px 6px",
                      color:
                        action?.textcolor?.trim().length > 0
                          ? action?.textcolor
                          : theme.palette.primary.main,
                      margin: "0 4px",
                      // backgroundColor:
                      //   action?.textcolor?.trim().length > 0
                      //     ? action?.textcolor
                      //     : theme.palette.primary.main,
                    }}
                  >
                    {action.action}
                  </ToggleButton>
                ))}
              </StyledToggleButtonGroup>
            </div>
          )}
        </div>
      </CardStyle>

      <Dialog
        open={pdf}
        onClose={() => setPdf(!pdf)}
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
      >
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "3px 5px",
            }}
          >
            <IconButton onClick={() => setPdf(false)}>
              <CloseIcon />
            </IconButton>
            <Typography variant="h5">Invoice Preview</Typography>
            <div>
              <Button
                variant="outlined"
                size="small"
                color="primary"
                startIcon={<GetAppOutlinedIcon />}
              >
                <Link href={`${field8}`} style={{ textDecoration: "none" }}>
                  Download
                </Link>
              </Button>
            </div>
          </div>
        </div>
        <div>
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            {field8 && (
              <>
                <Viewer fileUrl={field8} plugins={[toolbarPluginInstance]} />
              </>
            )}
            {!field8 && <>No PDF</>}
          </Worker>
        </div>
      </Dialog>
      <Dialog
        open={formOpen}
        onClose={() => setFormOpen(!formOpen)}
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
      >
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              margin: "20px",
            }}
          >
            <CircularProgress />
            <Typography>Please wait while loading next step</Typography>
          </div>
        ) : (
          <FormRendererParent
            // verifier_registration_status={1}
            onSaveForm={(doc) => {
              updateEntry(doc);
              // setdata(data);
            }}
            // // getSectionId={getSectionId}
            // formid={formRendering?.formId}
            // answer={formRendering?.answer}
            // forms={formRendering?.formData?.form}
            // form_detail={formRendering?.formData}
            // entryId={formRendering?.entry_id}
            // btnName="save"
            // submitBtnBottom="Right"
            // negativeBtn="Cancel"
            // secName={1}
            // secToShow={[0, null]}
            // // cancelPresent={false}
            // // headerPresent={true}
            // // formTitle={true}
            // isReadyOnly={true}
            // // handleCancelBtn={() => handleCancel()}
            // patient_details={formRendering?.patient}
            // UserRole={"notifier"}
            // //onReloadActionPerformed={onReloadActionPerformed}
            // // alertRequired
            // // divider
            // isEdit={formRendering?.entry_id ? true : false}
            // allowEdit={true}
            secName={0}
            title="notifyAdd"
            secToShow={[0, null]}
            state={[
              {
                form_id: formRendering?.formId,
              },
            ]}
            hideBtn={true}
            entryId={formRendering?.entry_id}
            allowEdit={true} // number={number}
            ticketId={ticketId}
            isCancelFunc={() => setFormOpen(!formOpen)}
            isCancel={true}
          />
        )}
      </Dialog>
    </Grid>
  );
};
