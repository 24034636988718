import React from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { CardList, Cards } from "../../components";
import { useDispatch } from "react-redux";
import { getCurrentDate, getDateTime, ToastMessage } from "../../utils";
import { actions } from "central_pharma_binder";
import { BackdropContext } from "../../contexts";
const useStyles = makeStyles((theme) => ({
  body: {
    height: "100%",
  },
  header: {
    height: "54px",
    padding: "0 16px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  header2: {
    height: "54px",
    padding: "0 16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    height: "calc(100% - 55px)",
    background: theme.palette.background.gray,
  },
  sidepanel: {
    padding: theme.spacing(2),

    borderRadius: "8px",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
  mainpanel: {
    padding: theme.spacing(2),
    height: "100%",
    borderRadius: "8px",
    overflowY: "auto",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
  mainBody1: {
    // padding: "8px",
    height: "fit-content",
    background: theme.palette.background.gray,
    borderRadius: "8px",
    overflowY: "auto",
    height: "calc(100% - 52px)",
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
    },
  },
  mainBody2: {
    padding: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(100% - 64px)",
    background: theme.palette.background.gray,
    borderRadius: "8px",
  },
  cardParent: {
    padding: "0 16px 16px 16px",
    background: "#fff",
    borderRadius: "8px",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
}));
export const Cart = (props) => {
  const {
    cartList,
    handleClose,
    Quantity,
    setQuantity,
    removeFromCart,
    user,
    getMealSchedule,
    date,
    setCartlist,
    selectedAddon,
    setSelectedAddon,
    addMinusQuantity,
    onHandleChnage,
  } = props;
  const classes = useStyles();

  const backDrop = React.useContext(BackdropContext);
  const dispacth = useDispatch();

  // const getString = (data) => {
  //   let keysArr = Object.keys(data).filter((l) => l.startsWith("Addon"));
  //   let addon = keysArr.flatMap((l) => data[l]);
  //   return addon.join(",");
  // };
  // const getAddonByMeal = (data) => {
  //   let _addon = selectedAddon
  //     ?.map((l) => l?.MealCode?._key === data?.MealCode?._key && getString(l))
  //     .filter((l) => l !== false);
  //   return _addon[0] ?? "";
  // };
  const onOrderPlacingClicked = async () => {
    backDrop.setBackDrop({
      ...backDrop,
      open: true,
      message: "Placing your order !",
    });
    let UpdateConcatQuantity = cartList?.map((l) => ({
      ...l,
      quantity: getQuantity(l?.serialno),
      newOrder:true,
    }));

    // console.log("UpdateConcatQuantity[0]" , UpdateConcatQuantity)
    // await cartList.map(async (l) => {
    let Params = {
      PatientCode: user?.PatientCode,
      PatientBedNumber: user?.PatientBedCode,
      OrderItemCode: UpdateConcatQuantity,
      OrderTimeCode: getDateTime(),
      OrderDate: getCurrentDate(new Date()),
      // quantity: getQuantity(l?._id),
      // OrderOtherDetails: l?.dosage_form,
      OrderStatus: process.env.REACT_APP_CODING_MASTER_ORDERSTATUS_ID,
      OrderType: process.env.REACT_APP_QUEUE_ORDERTYPE,
    };
    // console.log(UpdateConcatQuantity);
    let res = await dispacth(actions.MEAL_ORDER_INSERT({ _data: Params }));
    // console.log(res, "res");
    if (res?.payload?.data?.error) {
      // let meal = res?.payload?.data?.OrderOtherDetails;
      ToastMessage(`Order not placed, Try again`, "error", "top-center");
    } else {
      ToastMessage(`Order placed successfully`, "success", "top-center");
    }

    handleClose();
    setTimeout(async () => {
      await getMealSchedule(date);
      setCartlist([]);
      setQuantity([]);

      backDrop.setBackDrop({
        ...backDrop,
        open: false,
      });
    }, 3000);
  };

  const getQuantity = (id) => {
    let getValue = Quantity?.find((l) => l?.serialno === id);
    return getValue?.quantity ?? "1";
  };

  return (
    <Box className={classes.body}>
      <Box className={classes.header}>
        <Typography style={{ fontWeight: "800" }} gutterBottom variant="h6">
          {"Cart"}
        </Typography>
        <Button
          variant="contained"
          startIcon={<KeyboardBackspaceIcon />}
          onClick={() => handleClose()}
          color="primary"
        >
          Go Back
        </Button>
      </Box>
      <Divider />
      {/* <Box className={classes.content}> */}
      {/* Card */}
      <Grid className={classes.content} container>
        <Grid item xs={12} sm={12} md={9} lg={9} className={classes.mainpanel}>
          {/*Added Cart Card */}
          <Box className={classes.cardParent}>
            <Box className={classes.header}>
              <Typography
                style={{ fontWeight: "800" }}
                gutterBottom
                variant="body1"
              >
                {"Order Details"}
              </Typography>
            </Box>
            {cartList?.length > 0 ? (
              <Grid className={classes.mainBody1}>
                {cartList?.map((l, index) => (
                  <Cards
                    title={l?.itemname}
                    date={l?.parntname}
                    // price={getQuantityforCard(l?.id)}
                    icon={true}
                    index={index}
                    // image={
                    //   "https://images.unsplash.com/photo-1587854692152-cbe660dbde88?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=869&q=80"
                    // }
                    removeFromCart={removeFromCart}
                    mealName={l?.generic_name}
                    data={l}
                    Quantity={Quantity}
                    addMinusQuantity={addMinusQuantity}
                    onHandleChnage={onHandleChnage}
                  // setQuantity={setQuantity}
                  />
                ))}
              </Grid>
            ) : (
              <div className={classes.mainBody2}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      padding: "8px",
                      height: "50px",
                      width: "50px",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#E9EFF8",
                      margin: "8px",
                    }}
                  >
                    <img
                      style={{
                        height: "30px",
                        width: "30px",
                        objectFit: "contain",
                      }}
                      src={`/images/icons/box.png`}
                      alt={"No Data Imge"}
                    ></img>
                  </div>
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: "600" }}
                    gutterBottom
                    noWrap
                  >
                    {"Your Cart is Empty!"}
                  </Typography>
                  <Typography
                    variant="body2"
                    gutterBottom
                    align="center"
                    style={{ wordWrap: "break-word" }}
                  >
                    {"Look like you haven't added anything to your cart yet!"}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleClose()}
                  >
                    Order Now
                  </Button>
                </div>
              </div>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3} className={classes.sidepanel}>
          {/*  Order Summary */}
          <Box className={classes.cardParent}>
            <Grid container>
              <Grid item xs={12} className={classes.header2}>
                <Typography
                  style={{ fontWeight: "800", textAlign: "center" }}
                  gutterBottom
                  variant="body1"
                >
                  Order Summary
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.header}>
                <Typography>{`Item`}</Typography>
                <Typography>{cartList.length}</Typography>
              </Grid>

              <Grid item xs={12} className={classes.header}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={cartList?.length > 0 ? false : true}
                  onClick={() => onOrderPlacingClicked()}
                >
                  {"Place Your Order"}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      {/* </Box> */}
    </Box>
  );
};
