import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { TimeLine } from "../timeline";

export default function AlertDialog(props) {
  return (
    <div>
      <Dialog
        open={props?.open}
        onClose={props?.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Time Line"}</DialogTitle>
        <DialogContent>
          <TimeLine
            scenarioState={props?.scenarioState}
            currantState={props?.currantState}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={props?.handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
