import * as React from "react";
import PropTypes from "prop-types";

const ReturnPolicyIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14.467}
    height={16.944}
    {...props}
  >
    <defs>
      <clipPath id="a">
        <path
          data-name="Rectangle 50493"
          fill={props.color}
          d="M0 0h14.467v16.944H0z"
        />
      </clipPath>
    </defs>
    <g data-name="Group 95532">
      <g data-name="Group 95531" clipPath="url(#a)">
        <path
          data-name="Path 95365"
          d="M11.987 1.728v5.217a.5.5 0 0 1-1 0V1.728A.729.729 0 0 0 10.259 1H1.728A.729.729 0 0 0 1 1.728v13.488a.728.728 0 0 0 .728.728h3.826a.5.5 0 0 1 0 1H1.728A1.727 1.727 0 0 1 0 15.216V1.728A1.728 1.728 0 0 1 1.728 0h8.565a1.726 1.726 0 0 1 1.694 1.724m-1.728 7.73a3.207 3.207 0 1 0 3.207 3.207 3.211 3.211 0 0 0-3.207-3.207m0-1a4.207 4.207 0 1 1-4.207 4.207 4.207 4.207 0 0 1 4.207-4.207M5.915 3.3a.5.5 0 0 0-.5-.5H2.479a.5.5 0 1 0 0 1h2.936a.5.5 0 0 0 .5-.5M4.736 7.95a.5.5 0 0 0-.5-.5H2.479a.5.5 0 1 0 0 1h1.757a.5.5 0 0 0 .5-.5m2.987-2.2a.5.5 0 0 0-.5-.5H2.479a.5.5 0 1 0 0 1h4.744a.5.5 0 0 0 .5-.5m2.126 8.474 2.362-2.256a.5.5 0 0 0-.691-.723l-2.028 1.942-.507-.45a.5.5 0 1 0-.664.748l.85.756a.5.5 0 0 0 .678-.013"
          fill={props.color}
        />
      </g>
    </g>
  </svg>
);

export default ReturnPolicyIcon;

ReturnPolicyIcon.defaultProps = {
  color: "#6a7888",
};

ReturnPolicyIcon.propTypes = {
  color: PropTypes.string,
};
