import * as React from "react";
import PropTypes from "prop-types";

const InfoIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14} {...props}>
    <path
      d="M7 0a7 7 0 1 0 7 7 7.008 7.008 0 0 0-7-7Zm0 1.05A5.95 5.95 0 1 1 1.05 7 5.942 5.942 0 0 1 7 1.05ZM7 3.5a.7.7 0 1 0 .7.7.7.7 0 0 0-.7-.7Zm-.008 2.442a.525.525 0 0 0-.517.533v3.85a.525.525 0 1 0 1.05 0v-3.85a.525.525 0 0 0-.533-.533Z"
      fill={props.color}
    />
  </svg>
);

export default InfoIcon;

InfoIcon.defaultProps = {
  color: "#6a7888",
};

InfoIcon.propTypes = {
  color: PropTypes.string,
};
