export const Routes = {
  home: "/register-pintar",
  login: "/",
  Organization: "/Organization",
  Dashboard: "/dashboard",
  repositry: "/repositry",
  practitioner: "/practitioner",
  taskdocument: "/taskdocument",
  reportConfig: "/reportConfig",
  menu: "/menu",
  generalMaster: "/generalMaster",
  patientRegistration: "/patientRegistration",
  taskViewer: "/taskViewer",
  orderConfigurator: "/orderConfigurator",
  taskViewerConfig: "/taskViewerConfig",
  manageForms: "/manageForms",
  manage_form_detail: "/viewForm",
  kitchenMenu: "/ordercatalog",
  updateMealOrder: "/updateMealOrderStatus",
  registrationviewer: "/registrationviewer",
  pricehistory: "/pricehistory",
  Changepassword: "/Changepassword",
  Resetcode: "/Resetcode",
  Completed: "/Completed",
  ChangepwSuccess: "/ChangepwSuccess",
  registration: "/registration",
  userregistration: "/userregistration",
  itemMaster: "/itemmaster",
  Profile: "/profile",
  accounts: "/accounts",
  payment: "/payment",
  paymentSuccess: "/paymentSuccess",
  tutorials: "/Tutorials",
  exploreproducts: "/exploreproducts",
  viewcart: "/viewcart",
  PharmacyDashboard: "/PharmacyDashboard",
  mycart:"/mycart",
  myorders:"/myorders"
};
