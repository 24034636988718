import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
// import AddIcon from "@material-ui/icons/Add";
// import RemoveIcon from "@material-ui/icons/Remove";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Box, Typography, IconButton, TextField } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  quantity: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: "0 0 auto",
    gap: "4px",
  },
  buttonCircle: {
    position: "relative",
    boxSizing: "border-box",
    outline: "0",
    margin: "0",
    cursor: "pointer",
    verticalAlign: "middle",
    textDecoration: "none",
    letterSpacing: "0.02857em",
    border: "1px solid",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "1rem",
    fontWeight: "700",
    lineHeight: "1.4",
    textAlign: "center",
    textTransform: "uppercase",
    userSelect: "none",
    transition: "all 150ms ease-in",
    backgroundColor: "transparent",
    background: "transparent",
    color: theme.palette.text.primary,
    borderColor: theme.palette.text.disabled,
    borderRadius: "10rem",
    width: "2.375rem",
    minWidth: "2.375rem",
    height: "2.375rem",
    minHeight: "2.375rem",
    padding: "8px",

    "&:hover": {
      // backgroundColor: "transparent",
      background: theme.palette.primary.main,
      color: theme.palette.background.table,
      borderColor: theme.palette.background.table,
    },
  },
}));
export function QuantityComp(props) {
  const classes = useStyles();
  const {
    Quantity,
    addMinusQuantity,
    data,
    onHandleChnage = () => false,
  } = props;
  const [value, setValue] = React.useState("1");

  React.useEffect(() => {
    setValue(data?.quantity === "" ? "1" : data?.quantity ?? "1");
  }, [data]);

  return (
    <Box className={classes.quantity}>
      {/* <div style={{ flex: "1 1 auto" }}> */}
      <button
        size="small"
        onClick={() => addMinusQuantity(false, value ?? "1", data)}
        variant="outlined"
        disabled={value <= 1}
        color="primary"
        className={classes.buttonCircle}
        disableRipple
      >
        {"-"}

        {/* <RemoveCircleIcon /> */}
      </button>
      {/* </div> */}
      {/* <div style={{ flex: "1 1 auto" }}> */}
      <TextField
        value={value ?? "0"}
        onChange={(e) => onHandleChnage(e, data)}
        size="small"
        inputProps={{
          style: { maxWidth: "30px", textAlign: "center", margin: "0 8px" },
        }}
        //  onChange=
      />
      {/* </div> */}
      {/* <Typography>{value}</Typography> */}
      {/* <div style={{ flex: "1 1 auto" }}> */}
      <button
        size="small"
        variant="outlined"
        color="primary"
        onClick={() => addMinusQuantity(true, value ?? "1", data)}
        disableRipple
        className={classes.buttonCircle}
      >
        {"+"}
        {/* <AddCircleIcon /> */}
      </button>
      {/* </div> */}
    </Box>
  );
}
