import { GeneralMaster } from "atp-general-master";
import React from "react";

const GeneralMasterIPMO = () => {
  return (
    <>
      <div style={{ padding: "16px" }}>
        <GeneralMaster
          URl={process.env.REACT_APP_ARANGO_API}
          db_name={process.env.REACT_APP_DB}
          metadataId={process.env.REACT_APP_METADATAID}
          metadata_dbname={process.env.REACT_APP_MetadataDB_Name}
          theme={require("../../themes/default.json")}
        />
      </div>
    </>
  );
};

export default GeneralMasterIPMO;
