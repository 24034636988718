import React from "react";
import {  withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { useDialogContentStyles, useDialogStyles } from "./style";

const dialogTitleStyles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.text.primary,
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.75),
    // color: "rgba(0, 28, 60, 1)",
  },
  closeButton: {
    position: "absolute",
    right: 14,
    top: 14,
    color: "rgba(1, 32, 92, 1)",
    backgroundColor: "rgba(1, 32, 92, 0.1)",
  },
});

const DialogTitle = withStyles(dialogTitleStyles)((props) => {
  const { children, classes, onClose, titleStyle, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography
        variant="h6"
        className={classes.title}
        style={{ ...titleStyle }}
      >
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          size="small"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

// const DialogContent = withStyles((theme) => ({
//   root: {
//     padding: theme.spacing(2),
//   },
// }))(MuiDialogContent);

function StyledDialog({
  id = "default",
  open = false,
  handleClose = () => {},
  title = "",
  icon,
  children,
  dialogProps,
  contentProps,
  titleStyle,
  ...props
}) {
  const dialogStyles = useDialogStyles();
  const dialogContentStyles = useDialogContentStyles();
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby={`${id}-Dialog`}
      open={open}
      classes={dialogStyles}
      {...dialogProps}
    >
      <DialogTitle
        id={`${id}-Dialog`}
        onClose={handleClose}
        className={dialogStyles.dialogContentStylesTitle}
        titleStyle={titleStyle}
      >
        {icon ? icon : ""}
        {title}
      </DialogTitle>

      <DialogContent dividers classes={dialogContentStyles} {...contentProps}>
        {children}
      </DialogContent>
    </Dialog>
  );
}

export default StyledDialog;
