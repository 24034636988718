import React from "react";
import { withStyles } from "@material-ui/core";
import LocationLevel from "./locationLevel";
// import { actions } from 'binder-engine';
// import { connect } from "react-redux";

const styles = (theme) => ({
  root: {
    flex: 1,
  },
});
class LocationLevelParent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // componentDidMount() {
  //     this.getAllCountries();
  // }

  // getAllCountries = () =>{
  //     this.props.GET_ALL_COUNTRIES({});
  // }

  render() {
    const {
      classes,
      getAllCountries,
      label,
      isReadonly,
      isrequired,
      handleChange,
      value,
      errorValidation,
      disabled,
      id,
      primeLocation,
    } = this.props;

    return (
      <div className={classes.root} style={{display :  disabled ? "none" : ""}}>
        {
          <LocationLevel
            id={id}
            primeLocation={primeLocation}
            countryData={{ data: [], loading: false, error: false }}
            label={label}
            value={value}
            handleChange={handleChange}
            isReadonly={isReadonly}
            isrequired={isrequired}
            errorValidation={errorValidation}
            disabled={disabled}
          />
        }
      </div>
    );
  }
}

// const mapStateToProps = (state) => ({
//     getAllCountries: state?.countrySlice?.getAllCountries,
// });

export default withStyles(styles)(LocationLevelParent);
