import * as React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@material-ui/core";
        
const FrequencyIcon = (props) => {
  const theme = useTheme()
  const color=
  window.location.pathname === "/frequency" ||
  window.location.pathname === "/addnewfrequency_org" ||
  window.location.pathname === "/editnewfrequency_org"
    ? theme.sideBar.text.active
    : theme.sideBar.text.nonActive
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        id="icons8-ecg"
        d="M8.443,6A2.454,2.454,0,0,0,6,8.444V19.556A2.454,2.454,0,0,0,8.443,22h11.1a2.454,2.454,0,0,0,2.443-2.444V17.443a.667.667,0,0,0,0-.216V8.444A2.454,2.454,0,0,0,19.548,6Zm0,1.333h11.1a1.1,1.1,0,0,1,1.11,1.111v8.222h-1.9l-.782-3.909a.666.666,0,0,0-1.307,0l-.782,3.909h-.211L14.655,9.681a.667.667,0,0,0-1.319,0L12.31,16.667h-.255l-.75-2.628a.666.666,0,0,0-1.281,0l-.75,2.628H7.333V8.444A1.1,1.1,0,0,1,8.443,7.333ZM14,14.359l.4,2.691a1.118,1.118,0,0,0,1.1.95h.585a1.118,1.118,0,0,0,1.089-.892s0,0,0,0l.164-.819.163.819A1.118,1.118,0,0,0,18.58,18h2.079v1.556a1.1,1.1,0,0,1-1.11,1.111H8.443a1.1,1.1,0,0,1-1.11-1.111V18H9.441a1.116,1.116,0,0,0,1.068-.806l.155-.546.156.546A1.117,1.117,0,0,0,11.888,18H12.5a1.118,1.118,0,0,0,1.1-.95Z"
        transform="translate(-6 -6)"
        fill={color}
      />
    </svg>
  );
};

export default FrequencyIcon;

FrequencyIcon.defaultProps = {
  color: "black",
  secondaryColor: "#3ab012",
};

FrequencyIcon.propTypes = {
  color: PropTypes.string,
  secondaryColor: PropTypes.string,
};
