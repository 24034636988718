import React from "react";
import { Avatar, Card, CardContent, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import Chart from "./charts";
import { AxiosCall } from "../../utils";
import { AuthContext } from "../../contexts";
import axios from "axios";
import { MdOutlineNotificationsActive } from "react-icons/md";
import { IoBusinessSharp } from "react-icons/io5";
const useStyles = makeStyles((theme) => ({
  cardRoot: {
    // color: "#ffffff",
    height: "100%",
    cursor: "pointer",
    // display: "flex",
  },
  avatarColor: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },

}));
const NotifierDashBoard = (props) => {
  const classes = useStyles()
  const [clientId, setClientId] = React.useState();
  const [drugOrderCount, setDrugOrderCount] = React.useState(0);
  const [facilityCount, setFacilityCount] = React.useState(0);

  React.useEffect(() => {
    getDrugOrder(props?.perosnid)
    getFacilityCount(props?.perosnid, props?.orgId)
  }, []);

  const getDrugOrder = async (personid) => {
    var data = {
      "db_name": process.env.REACT_APP_DB,
      "entity": "DrugOrder",
      "filter": `DrugOrder.PersonId=='${personid}' && CONTAINS(DATE_ISO8601(DATE_NOW()),DrugOrder.OrderDate) collect with count into length`,
      "return_fields": "length"
    };

    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_ARANGO_URL_READ}`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        // console.log("lo", response.data)
        setDrugOrderCount(response.data?.result)
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const getFacilityCount = async (personid, orgid) => {
    var data = {
      "db_name": process.env.REACT_APP_DB,
      "query": `let a=(for Facility in Facility filter Facility.OrgCode=='${orgid}' && Facility.activestatus==true return Facility) let b=(for Ward in Ward filter a[*]._id any == Ward.FacCode && Ward.activestatus==true return Ward) let c=(for Room in Room filter b[*]._id any == Room.WardCode && Room.activestatus==true return Room) let d=(for Bed in Bed filter c[*]._id any == Bed.RoomCode return Bed) for DrugOrder in DrugOrder filter DrugOrder.PersonId=='${personid}' && document(DrugOrder.PersonId).OrgID[*].id any=='${orgid}' && d[*]._id any ==DrugOrder.PharmacyDetails._id collect with count into length return length`
    }

    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_ARANGO_API}/api/execute_aql`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then((response) => {
        // console.log("logs", response.data)
        setFacilityCount(response.data)
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md lg>
        <Card className={`${classes.cardRoot}`}>
          <CardContent style={{ display: "flex" }}>
            {/* <Typography variant={"h5"} gutterBottom>
              Facilities
            </Typography>
            <Typography variant={"h4"}>{facilityCount}</Typography>
            <IoBusinessSharp className={classes.iconAlign} /> */}
            <div style={{ flex: "1 1 auto" }}>
              <Typography variant={"h5"} gutterBottom>
                Facilities
              </Typography>
              <Typography variant={"h4"}>{facilityCount}</Typography>
            </div>
            <div
              style={{
                flex: "0 0 auto",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Avatar className={classes.avatarColor}>
                <IoBusinessSharp />
              </Avatar>
            </div>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md lg>
        <Card className={classes.cardRoot}>
          <CardContent style={{ display: "flex" }}>
            <div style={{ flex: "1 1 auto" }}>
              <Typography variant={"h5"} gutterBottom>
                Drug Ordered
              </Typography>
              <Typography variant={"h4"}>{drugOrderCount}</Typography>
            </div>
            <div
              style={{
                flex: "0 0 auto",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Avatar className={classes.avatarColor}>
                <MdOutlineNotificationsActive />
              </Avatar>
            </div>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default NotifierDashBoard;
