import React, { useContext, useState } from "react";
import {
  Dialog,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import PrintIcon from "@material-ui/icons/Print";
import StarIcon from "@mui/icons-material/Star";
import { TagsIcon } from "../../assets";
import { useTheme } from "@material-ui/core";
import Allergy from "../../assets/allergy.png";
import FoodPrefference from "../../assets/foodprefference.png";
import moment from "moment";
import parse from "html-react-parser";
import axios from "axios";
// import FormRendererParent from "../../screens/formRenderer/index"
import { AlertProps, aqlQuery } from "../../utils";
import FormRendererParent from "../../screens/formRendererfinal/index";
//import { FormRenderer } from "../../screens/formRenderer/formRenderer";
//import FormRendererParent from "../../../../../formRenderer";

import { AlertContext, BackdropContext } from "../../contexts";
import { useDispatch } from "react-redux";
import { actions, async } from "central_pharma_binder";
// import { FormRenderer } from "../../../../../OrgFormRenderer/formRenderer";
import CircularProgress from "@material-ui/core/CircularProgress";
export const CardStyled = styled.div`
  padding: ${(props) => (props.m === "large" ? "12px 12px" : "8px 8px")};
  background: ${(props) => (props.selected ? props.hovercolor : "#ffffff")};
  border-top: 1px solid;
  border-bottom: 1px solid;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100px;
  cursor: pointer;
  border-color: #e0e0e0;
  border-radius: ${(props) => (props.type === "main" ? "8px" : "0px")};
  transition: border-color 0.3s ease-in-out;
  &:hover {
    border: 2px solid;
    border-color: ${(props) => props.color};
  }
`;

export const CardStyle = styled(CardStyled)`
  cursor: inherit;
  border: 1px solid #e0e0e0;
  &:hover {
    border: 1px solid #e0e0e0;
  }
`;

export const FlexRow = styled.div`
  display: flex;
  align-items: center;
  margin: 2px;
`;

export const FlexSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    margin: theme.spacing(0.5),
    // border: "none",
    "&:not(:first-child)": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-child": {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))(ToggleButtonGroup);

var debounce;
function debouncefunc(func, event, value) {
  clearTimeout(debounce);
  debounce = setTimeout(() => {
    func(event, value);
  }, 500);
}

export const Cards = (props) => {
  let {
    type,
    index,
    field1,
    field2,
    field3,
    field4,
    field5,
    field6,
    field7,
    field8,
    field9,
    field10 = undefined,
    field11 = undefined,
    field12 = undefined,
    field13 = undefined,
    ticketId,
    state,
    data,
    favourite,
    performUserAction = () => false,
    onCardClicked = () => false,
    onFavIconClicked = () => false,
    action,
    selectedTask,
    alignment,
    updateState,
    PermissionData,
    scenarioId,
    allTicketsids
  } = props;
  let actionData = [];
  if (action) {
    action?.forEach((e) =>
      PermissionData?.forEach((v) =>
        v.scenarioChildren?.forEach((b) =>
          b.stateChildren?.forEach((n) =>
            n.actionChildren?.forEach(
              (m) =>
                m.repoid.unique_id === e._id &&
                m.permsnread === true &&
                b.repoid.unique_id === scenarioId &&
                actionData.push(e)
            )
          )
        )
      )
    );
  }
  action = [...new Set(actionData)];
  const regex = /<\w+/g;
  const theme = useTheme();
  const backdrop = useContext(BackdropContext);
  const alert = useContext(AlertContext);
  const [formOpen, setFormOpen] = useState(false);
  const [formId, setFormID] = useState("");
  const [loading, setLoading] = useState(false);
  const [alignmen, setAlignmen] = useState();
  const [keyNameForFormUpsert, setKeyNameForFormUpsert] = useState("");
  const [formRendering, setFormRendering] = React.useState({});
  const [showMore, setShowMore] = React.useState(false);
  const a = new Date(field9 * 1000);
  const dispatch = useDispatch();
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const moments = moment(a).fromNow();

  const handleAlignment = async (event, newAlignment) => {
    if (newAlignment?.chooseform) {
      backdrop?.setBackDrop({
        ...backdrop,
        open: true,
        message: "Loading the form",
      });

      setFormID(newAlignment?.chooseform);
      setAlignmen(newAlignment);
      if (process.env.REACT_APP_PAYMENT_STATE_ID === newAlignment?.statesid) {
        if (process.env.REACT_APP_ONHOLD_ACTION_NAME === newAlignment?.action) {
          setKeyNameForFormUpsert("onholdDetails");
          getFormData(newAlignment?.chooseform, ticketId, "onholdDetails");
        } else if (
          process.env.REACT_APP_CANCELLED_ACTION_NAME === newAlignment?.action
        ) {
          setKeyNameForFormUpsert("cancelDetails");
          getFormData(newAlignment?.chooseform, ticketId, "cancelDetails");
        } else {
          setKeyNameForFormUpsert("PaymentDetails");
          getFormData(newAlignment?.chooseform, ticketId, "PaymentDetails");
        }
      } else if (
        process.env.REACT_APP_DISPATCH_STATE_ID === newAlignment?.statesid
      ) {
        if (process.env.REACT_APP_ONHOLD_ACTION_NAME === newAlignment?.action) {
          setKeyNameForFormUpsert("onholdDetails");
          getFormData(newAlignment?.chooseform, ticketId, "onholdDetails");
        } else if (
          process.env.REACT_APP_CANCELLED_ACTION_NAME === newAlignment?.action
        ) {
          setKeyNameForFormUpsert("cancelDetails");
          getFormData(newAlignment?.chooseform, ticketId, "cancelDetails");
        } else {
          setKeyNameForFormUpsert("DispenseDetails");
          getFormData(newAlignment?.chooseform, ticketId, "DispenseDetails");
        }
      }
      else if (process.env.REACT_APP_ONHOLD_ACTION_NAME === newAlignment?.action) {
        setKeyNameForFormUpsert("onholdDetails");
        getFormData(newAlignment?.chooseform, ticketId, "onholdDetails");
      } else if (
        process.env.REACT_APP_CANCELLED_ACTION_NAME === newAlignment?.action
      ) {
        setKeyNameForFormUpsert("cancelDetails");
        getFormData(newAlignment?.chooseform, ticketId, "cancelDetails");
      }
    } else {
      let Arr = []
      if (allTicketsids) {
        Arr = allTicketsids
      }
      else {
        Arr.push(ticketId)
      }
      await performUserAction(newAlignment, Arr);
    }
    // updateState(newAlignment);
  };
  const handleSelect = (data) => {
    onCardClicked(data);
  };

  const onClickFav = (data) => {
    onFavIconClicked(data);
  };

  const printFunc = (ticketid) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      ticketId: [`${ticketid}`],
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_PRINT_API, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        axios(result.response, {
          method: "GET",
          responseType: "blob", //Force to receive data in a Blob Format
        })
          .then((response) => {
            //Create a Blob from the PDF Stream
            const file = new Blob([response.data], { type: "application/pdf" });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            window.open(fileURL);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => console.log("error", error));
  };

  const getFormFromExecuteAql = async (formId, ticketId, keyname) => {
    let params_for_read_entry = {
      db_name: `${process.env.REACT_APP_DBNAME}`,
      entity: "QDMATPtransactionlog",
      filter: `QDMATPtransactionlog.ticketId=='${ticketId}' && QDMATPtransactionlog.activestatus==true`,

      return_fields: "QDMATPtransactionlog",
    };

    let config_for_read_entry = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: params_for_read_entry,
    };

    await axios(config_for_read_entry)
      .then(async (res) => {
        let entryid =
          res.data?.result?.[0]?.payload?.inputDoc?.[keyname]?.entry_id;

        let params = {
          db_name: `${process.env.REACT_APP_DBNAME}`,
          query: entryid
            ? aqlQuery.getFormDataWithEntryId(formId, entryid)
            : aqlQuery.getFormData(formId, ticketId),
        };
        let config = {
          method: "post",
          url: `${process.env.REACT_APP_READ_DOCUMENT}`,
          headers: {
            "Content-Type": "application/json",
          },
          data: params,
        };

        await axios(config)
          .then((res) => {
            let aqldata = res.data[0];
            let answerArr = aqldata?.bulkAnswers?.answers ?? [];
            let answer = {};
            // console.log(aqldata, "aqldata");
            answerArr?.map((l) => (answer[l.question_id] = l.answer));
            let updateState = {
              formId: formId,
              // formData: aqldata,
              // selected_form: aqldata?.form[0],
              // answer: answer,
              _id: aqldata?.bulkAnswers?.entry_id,
              secName: aqldata?.bulkAnswers?.secName ?? 1,
              entry_id: entryid,
            };
            setFormRendering(updateState);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getEntryId = async (ticketId, keyname) => {
    let params_for_read_entry = {
      db_name: `${process.env.REACT_APP_DBNAME}`,
      entity: "QDMATPtransactionlog",
      filter: `QDMATPtransactionlog.ticketId=='${ticketId}' && QDMATPtransactionlog.activestatus==true`,

      return_fields: "QDMATPtransactionlog",
    };

    let config_for_read_entry = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: params_for_read_entry,
    };

    await axios(config_for_read_entry)
      .then(async (res) => {
        let entryid =
          res.data?.result?.[0]?.payload?.inputDoc?.[keyname]?.entry_id;

        let params = {
          db_name: `${process.env.REACT_APP_DBNAME}`,
          query: entryid
            ? aqlQuery.getFormDataWithEntryId(formId, entryid)
            : aqlQuery.getFormData(formId, ticketId),
        };
        let config = {
          method: "post",
          url: `${process.env.REACT_APP_READ_DOCUMENT}`,
          headers: {
            "Content-Type": "application/json",
          },
          data: params,
        };

        await axios(config)
          .then((res) => {
            let aqldata = res.data[0];
            let answerArr = aqldata?.bulkAnswers?.answers ?? [];
            let answer = {};
            // console.log(aqldata, "aqldata");
            answerArr?.map((l) => (answer[l.question_id] = l.answer));
            let updateState = {
              formId: formId,
              formData: aqldata,
              selected_form: aqldata?.form[0],
              answer: answer,
              _id: aqldata?.bulkAnswers?.entry_id,
              secName: aqldata?.bulkAnswers?.secName ?? 1,
              entry_id: entryid,
            };
            setFormRendering(updateState);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });

    // let params2 = {
    //   db_name: `${process.env.REACT_APP_DBNAME}`,
    //   entity: "form_entries",
    //   filter: `form_entries.entry_id=='${entryid}'&& form_entries.activestatus==true`,
    //   return_fields: "form_entries",
    // };

    // let config2 = {
    //   method: "post",
    //   url: `${process.env.REACT_APP_API_URL}`,
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   data: params2,
    // };

    // return await axios(config2)
    //   .then((res) => {
    //     console.log("Res", res.data[0]);
    //     return {
    //       entry_id: res?.data[0]?.entry_id,
    //     };
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     alert.setSnack({
    //       ...alert,
    //       open: true,
    //       severity: AlertProps.severity.error,
    //       msg: `Something went wrong and check console for error`,
    //       vertical: AlertProps.vertical.top,
    //       horizontal: AlertProps.horizontal.center,
    //     });
    //   });

    // this.setState({
    //   ...this.state,
    //   entry_id: res.data[0].entry_id,
    // })
  };
  const getFormData = async (formid, ticketId, keyname) => {
    // Setloading(true);
    let updateState = await getFormFromExecuteAql(formid, ticketId, keyname);
    setTimeout(() => {
      setFormOpen(!formOpen);
      backdrop?.setBackDrop({ ...backdrop, open: false, message: "" });
    }, 1000);
  };

  const updateEntry = async (data) => {
    setLoading(true);
    backdrop?.setBackDrop({
      ...backdrop,
      open: true,
      message: "Loading the next step",
    });
    // console.log("InnnerData", data);
    let answerArr = data?.data;
    let answer = {};
    let entryid = data?.entry_id ? data?.entry_id : formRendering?.entry_id;
    Object.keys(answerArr).map(
      (l) =>
      (answer[answerArr?.[l]?.questions] =
        answerArr?.[l]?.answer?.length !== 0 ? answerArr?.[l]?.answer : "")
    );
    let params = {
      form_id: formId,
      data: data,
      notifier_status: data?.isDraft ? 9 : 1,
      notifyType: data?.isDraft,
      entry_id: formRendering?.entry_id,
      isEdit: false,
      ticketId: ticketId,
    };
    // await Promise.resolve(
    //   dispatch(actions.UPSERT_ENTRY_ATP_TASK_VIEWER_TREE(params))
    // ).then((res) => {
    //   console.log("DispatchRes", res);
    //   if (res?.payload?.data?.Result) {
    //     
    //     entryid = res?.payload?.data?.Result[0]?.properties?.doc?.entry_id;
    //     alert.setSnack({
    //       ...alert,
    //       open: true,
    //       severity: AlertProps.severity.success,
    //       msg: "Form Successfully Submitted",
    //       vertical: AlertProps.vertical.top,
    //       horizontal: AlertProps.horizontal.center,
    //     });
    //   } else {
    //     alert.setSnack({
    //       ...alert,
    //       open: true,
    //       severity: AlertProps.severity.error,
    //       msg: "Something went wrong!",
    //       vertical: AlertProps.vertical.top,
    //       horizontal: AlertProps.horizontal.center,
    //     });
    //   }
    // });
    let Arr = []
    if (allTicketsids) {
      Arr = allTicketsids
    }
    else {
      Arr.push(ticketId)
    }


    await performUserAction(
      alignmen,
      Arr,
      answer,
      keyNameForFormUpsert,
      entryid,
      setLoading
    );
    setFormOpen(false);
    setLoading(false);
    // backdrop?.setBackDrop({ ...backdrop, open: false, message: "" });
  };
  return (
    <Grid item lg={12} md={12} xs={12} sm={12} key={index}>
      {type === 1 && (
        <CardStyled
          onClick={() => handleSelect(data)}
          hovercolor={theme.palette.info.light}
          color={theme.palette.info.main}
          // key={index}
          selected={selectedTask?.key === data?.key ? true : false}
        >
          <FlexSpaceBetween>
            <FlexRow>
              <Typography variant="body1">
                {" "}
                {field1 !== undefined && field1?.match(regex)
                  ? parse(field1)
                  : field1 ?? ""}
              </Typography>
              {field1 && field9 && (
                <Divider
                  flexItem
                  orientation="vertical"
                  style={{ margin: "4px 4px" }}
                />
              )}

              {(field9 || field9 === "undefined") && (
                <>
                  {/* <Divider
                    flexItem
                    orientation="vertical"
                    style={{ margin: "4px 4px" }}
                  /> */}
                  <Typography
                    variant="caption"
                    style={{
                      fontSize: "9px",
                    }}
                    color="GrayText"
                  >
                    {`${moments}`}
                    {/* ${a.toGMTString()} */}
                  </Typography>
                </>
              )}
            </FlexRow>
            <Typography
              variant="subtitle2"
              style={{ fontWeight: "600", fontSize: "10px", width: "200px", "word-wrap": "break-word" }}
            >
              {ticketId ?? ""}
            </Typography>
          </FlexSpaceBetween>

          <FlexRow>
            {(field4 || field4 === "undefined") && (
              <>
                <Typography
                  variant="caption"
                  style={{ fontSize: "10px" }}
                  color="GrayText"
                >
                  {field4 !== undefined && field4?.match(regex)
                    ? parse(field4)
                    : field4 ?? ""}
                </Typography>
              </>
            )}

            {field4 && field2 && (
              <Divider
                flexItem
                orientation="vertical"
                style={{ margin: "4px 4px" }}
              />
            )}

            {(field2 || field2 === "undefined") && (
              <>
                <Typography
                  variant="caption"
                  style={{ fontSize: "10px" }}
                  color="GrayText"
                >
                  {field2 !== undefined && field2?.match(regex)
                    ? parse(field2)
                    : field2 ?? ""}
                </Typography>
              </>
            )}
            {field8 && field2 && (
              <Divider
                flexItem
                orientation="vertical"
                style={{ margin: "4px 4px" }}
              />
            )}

            {(field8 || field8 === "undefined") && (
              <>
                <Typography
                  variant="caption"
                  style={{ fontSize: "10px" }}
                  color="GrayText"
                >
                  {field8 !== undefined && field8?.match(regex)
                    ? parse(field8)
                    : field8}
                </Typography>
              </>
            )}
          </FlexRow>
          {(field5 || field5 === "undefined") && (
            <FlexRow>
              <Typography
                variant="caption"
                style={{
                  fontSize: "10px",
                }}
                color="GrayText"
              >
                {field5 !== undefined && field5?.match(regex)
                  ? parse(field5)
                  : field5 ?? ""}
              </Typography>
            </FlexRow>
          )}

          <FlexSpaceBetween>
            <FlexRow>
              <TagsIcon size="0.8rem" color={theme.palette.text.disabled} />
              <Typography
                variant="caption"
                color="GrayText"
                style={{
                  marginLeft: "8px",
                  fontWeight: "600",
                  fontSize: "14px",
                }}
              >
                {state ?? ""}
              </Typography>
            </FlexRow>
            <FlexRow>
              <IconButton onClick={() => onClickFav(data)}>
                <StarIcon
                  color={favourite ? "warning" : "disabled"}
                  style={{ fontSize: "1rem" }}
                />
              </IconButton>
              <IconButton onClick={() => printFunc(ticketId)}>
                <PrintIcon
                  color={favourite ? "warning" : "disabled"}
                  style={{ fontSize: "1rem" }}
                />
              </IconButton>
            </FlexRow>
          </FlexSpaceBetween>
        </CardStyled>
      )}
      {type === 2 && (
        <CardStyle type={"main"} m={"large"}>
          <FlexSpaceBetween>
            <FlexRow>
              <Typography>
                {" "}
                {field1 !== undefined && field1?.match(regex)
                  ? parse(field1)
                  : field1 ?? ""}
              </Typography>

              {field1 && field9 && (
                <Divider
                  flexItem
                  orientation="vertical"
                  style={{ margin: "4px 4px" }}
                />
              )}

              {field9 && (
                <>
                  <Typography
                    variant="caption"
                    style={{
                      fontSize: "9px",
                    }}
                    color="GrayText"
                  >
                    {` ${a.toLocaleDateString(undefined, options)}`}
                    {/* ${a.toGMTString()} */}
                  </Typography>
                </>
              )}
            </FlexRow>
            {!showMore ?
              <Typography
                variant="subtitle2"
                style={{
                  display: "-webkit-box",
                  WebkitLineClamp: "1",
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  maxWidth: "9em",
                  fontSize: "12px",
                }}

              >
                {ticketId ?? ""}
              </Typography>
              :
              <Typography
                variant="subtitle2"
                style={{ fontWeight: "500", fontSize: "12px", width: "20em", "word-wrap": "break-word" }}
              >
                {ticketId ?? ""}

              </Typography>
            }
          </FlexSpaceBetween>
          {allTicketsids?.length > 3 &&
            <FlexRow style={{ flexDirection: "row-reverse" }}>
              <p style={{ fontSize: "12px", color: "#2196f3", textDecoration: "underline", cursor: "pointer" }} onClick={() => setShowMore(!showMore)}>{showMore ? "show less" : "show more"}</p>
              {/* <ToggleButton
                size="small"
                value={action}
                key={index}
                onClick={() => setShowMore(!showMore)}
                style={{
                  border: `1px solid ${action?.bgcolor?.trim().length > 0
                    ? action?.textcolor
                    : theme.palette.primary.main
                    }`,
                  borderRadius: "4px",
                  padding: "4px 6px",
                  color:
                    action?.textcolor?.trim().length > 0
                      ? action?.textcolor
                      : theme.palette.primary.main,
                }}
              >
                {showMore ? "show less" : "show more"}

              </ToggleButton> */}
            </FlexRow>
          }
          <FlexRow>
            {(field4 || field4 === "undefined") && (
              <>
                <Typography
                  variant="caption"
                  color="GrayText"
                // style={{ fontWeight: "600" }}
                >
                  {field4 !== undefined && field4?.match(regex)
                    ? parse(field4)
                    : field4 ?? ""}
                </Typography>
                {/* <hr style={{ height: 10, margin: "0px 8px" }} /> */}
              </>
            )}
            {field4 && field2 && (
              <Divider
                flexItem
                orientation="vertical"
                style={{ margin: "4px 4px" }}
              />
            )}
            {field2 && (
              <>
                {/* <Divider
                  flexItem
                  orientation="vertical"
                  style={{ margin: "4px 4px" }}
                /> */}
                <Typography
                  variant="caption"
                  // style={{ fontWeight: "600" }}
                  color="GrayText"
                >
                  {`${field2 !== undefined && field2?.match(regex)
                    ? parse(field2)
                    : field2 ?? ""
                    }`}
                </Typography>
              </>
            )}
            {field5 && field2 && (
              <Divider
                flexItem
                orientation="vertical"
                style={{ margin: "4px 4px" }}
              />
            )}
            {(field5 || field5 === "undefined") && (
              <>
                {/* <Divider
                  flexItem
                  orientation="vertical"
                  style={{ margin: "4px 4px" }}
                /> */}
                <Typography
                  variant="caption"
                  // style={{ fontWeight: "600" }}
                  color="GrayText"
                >
                  {field5 !== undefined && field5?.match(regex)
                    ? parse(field5)
                    : field5 ?? ""}
                </Typography>
              </>
            )}
            {/* {field6 && field5 && (
              <Divider
                flexItem
                orientation="vertical"
                style={{ margin: "4px 4px" }}
              />
            )} */}
            {(field6 || field6 === "undefined") && (
              <>
                <Divider
                  flexItem
                  orientation="vertical"
                  style={{ margin: "4px 4px" }}
                />
                <Typography
                  variant="caption"
                  // style={{ fontWeight: "600" }}
                  color="GrayText"
                >
                  {field6 !== undefined && field6?.match(regex)
                    ? parse(field6)
                    : field6 ?? ""}
                </Typography>
              </>
            )}
            {field6 && field8 && (
              <Divider
                flexItem
                orientation="vertical"
                style={{ margin: "4px 4px" }}
              />
            )}

            {(field8 || field8 === "undefined") && (
              <>
                <Divider
                  flexItem
                  orientation="vertical"
                  style={{ margin: "4px 4px" }}
                />
                <Typography
                  variant="caption"
                  style={{ fontWeight: "600", fontSize: "10px" }}
                  color="GrayText"
                >
                  {field8 !== undefined && field8?.match(regex)
                    ? parse(field8)
                    : field8}
                </Typography>
              </>
            )}
          </FlexRow>
          <FlexRow>
            {field10 && (
              <>
                <img
                  src={Allergy}
                  alt="skin icons"
                  style={{
                    width: "30px",
                    padding: "1px",
                    margin: "0 10px 0 0",
                  }}
                />
                <Tooltip title="Allergy" placement="top">
                  <Typography
                    variant="caption"
                    style={{ fontWeight: "600", fontSize: "12px" }}
                    color="GrayText"
                  >
                    {field10 !== undefined && field10?.match(regex)
                      ? parse(field10)
                      : field10}
                  </Typography>
                </Tooltip>
              </>
            )}
            {field10 && field11 && (
              <Divider
                flexItem
                orientation="vertical"
                style={{ margin: "4px 4px" }}
              />
            )}
            {field11 && (
              <>
                {/* <Divider
                  flexItem
                  orientation="vertical"
                  style={{ margin: "4px 4px" }}
                /> */}
                <img
                  src={FoodPrefference}
                  alt="skin icons"
                  style={{
                    width: "30px",
                    padding: "1px",
                    margin: "0 10px 0 0",
                  }}
                />
                <Tooltip title="Food Prefference" placement="top">
                  <Typography
                    variant="caption"
                    style={{ fontWeight: "600", fontSize: "12px" }}
                    color="GrayText"
                  >
                    {field11 !== undefined && field11?.match(regex)
                      ? parse(field11)
                      : field11}
                  </Typography>
                </Tooltip>
              </>
            )}
          </FlexRow>
          <FlexRow>
            {field12 && (
              <Typography
                variant="caption"
                style={{ fontWeight: "600" }}
                color="GrayText"
              >
                {typeof field12 !== "object"
                  ? field12 !== undefined && field12?.match(regex)
                    ? parse(field12)
                    : field12
                  : field12?.display !== undefined &&
                    field12?.display?.match(regex)
                    ? parse(field12?.display)
                    : field12?.display}

                {/* {field12 !== undefined && field12?.match(regex)
                  ? parse(field12)
                  : field12} */}
              </Typography>
            )}
            {/* {field13 && (
              <>
                <Divider
                  flexItem
                  orientation="vertical"
                  style={{ margin: "4px 4px" }}
                />
                <Typography
                  variant="caption"
                  style={{ fontWeight: "600" }}
                  color="GrayText"
                >
                  {field13 !== undefined && field13?.match(regex)
                    ? parse(field12)
                    : field13}
                </Typography>
              </>
            )} */}
            {field12 && field7 && (
              <Divider
                flexItem
                orientation="vertical"
                style={{ margin: "4px 4px" }}
              />
            )}
            {(field7 || field7 === "undefined") && (
              <>
                {/* <Divider
                  flexItem
                  orientation="vertical"
                  style={{ margin: "4px 4px" }}
                /> */}
                <Typography
                  variant="caption"
                  style={{ fontWeight: "600" }}
                  color="GrayText"
                >
                  {field7 !== undefined && field7?.match(regex)
                    ? parse(field7)
                    : field7}
                </Typography>
              </>
            )}
          </FlexRow>
          <FlexSpaceBetween>
            <FlexRow>
              <TagsIcon size="1rem" color={theme.palette.info.main} />
              <Typography
                variant="body1"
                color="GrayText"
                style={{ marginLeft: "8px", fontWeight: "400" }}
              >
                {state ?? ""}
              </Typography>
            </FlexRow>
            {action && (
              <FlexRow>
                <StyledToggleButtonGroup
                  size="small"
                  value={alignment}
                  exclusive
                  onChange={(e, v) => {
                    backdrop?.setBackDrop({
                      ...backdrop,
                      open: true,
                      message: "loading",
                    });
                    debouncefunc(handleAlignment, e, v);
                  }}
                >
                  {action?.map((action, index) => (
                    <ToggleButton
                      size="small"
                      value={action}
                      key={index}
                      // className={classes.ToggleButton}
                      style={{
                        border: `1px solid ${action?.bgcolor?.trim().length > 0
                          ? action?.textcolor
                          : theme.palette.primary.main
                          }`,
                        borderRadius: "4px",
                        padding: "4px 6px",
                        color:
                          action?.textcolor?.trim().length > 0
                            ? action?.textcolor
                            : theme.palette.primary.main,
                        margin: "0 4px",
                        // backgroundColor:
                        //   action?.textcolor?.trim().length > 0
                        //     ? action?.textcolor
                        //     : theme.palette.primary.main,
                      }}
                    >
                      {action.action}
                    </ToggleButton>
                  ))}
                </StyledToggleButtonGroup>
              </FlexRow>
            )}
          </FlexSpaceBetween>
        </CardStyle>
      )}
      <Dialog
        open={formOpen}
        onClose={() => setFormOpen(!formOpen)}
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
      >
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              margin: "20px",
            }}
          >
            <CircularProgress />
            <Typography>Please wait while loading next step</Typography>
          </div>
        ) : (
          <FormRendererParent
            // verifier_registration_status={1}
            onSaveForm={(doc) => {
              updateEntry(doc);
              // setdata(data);
            }}
            // // getSectionId={getSectionId}
            // formid={formRendering?.formId}
            // answer={formRendering?.answer}
            // forms={formRendering?.formData?.form}
            // form_detail={formRendering?.formData}
            // entryId={formRendering?.entry_id}
            // btnName="save"
            // submitBtnBottom="Right"
            // negativeBtn="Cancel"
            // secName={1}
            // secToShow={[0, null]}
            // // cancelPresent={false}
            // // headerPresent={true}
            // // formTitle={true}
            // isReadyOnly={true}
            // // handleCancelBtn={() => handleCancel()}
            // patient_details={formRendering?.patient}
            // UserRole={"notifier"}
            // //onReloadActionPerformed={onReloadActionPerformed}
            // // alertRequired
            // // divider
            // isEdit={formRendering?.entry_id ? true : false}
            // allowEdit={true}
            secName={0}
            title="notifyAdd"
            secToShow={[0, null]}
            state={[
              {
                form_id: formRendering?.formId,
              },
            ]}
            hideBtn={true}
            entryId={formRendering?.entry_id}
            allowEdit={true} // number={number}
            ticketId={ticketId}
            isCancelFunc={() => setFormOpen(!formOpen)}
            isCancel={true}
          />
        )}
      </Dialog>
    </Grid>
  );
};
