import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { AlertContext, BackdropContext } from "../../contexts";
import { AlertProps, validateSpecialCharacter, AxiosCall } from "../../utils";

import axios from "axios";

export const TableComponet = (props) => {
  const {
    TableHeader,
    TableContent,
    ticketId,
    updateselectedList = () => false,
    getCreditData = () => false,
    checkCreditDEtails = () => false,
  } = props;
  const alertMessage = React.useContext(AlertContext);
  const [data, setdata] = useState([]);
  const [queId, setQueId] = useState("");
  const [headerData, setHeaderData] = useState([]);
  // const [disble, setDisable] = useState(false);

  useEffect(() => {
    if(TableContent?.length > 1)
    {
      setdata([...TableContent.sort((a, b) => (a.ticketId > b.ticketId) ? -1 : 1)]);
    }
    else{
      setdata(TableContent);
    }
  }, [TableContent]);

  let getId = async () => {
    let param2 = {
      db_name: process.env.REACT_APP_DB,
      entity: "CodeableConceptMaster",
      filter: `CodeableConceptMaster.activestatus==true && CodeableConceptMaster.Type=='${process.env.REACT_APP_ALLOW_EDIT_DISPENSE}'`,
      return_fields:
        "MERGE({coding:(FOR cod IN CodeableConceptMaster.coding RETURN DOCUMENT(cod).display)})",
    };

    let con = {
      method: "post",
      url: process.env.REACT_APP_ARANGO_URL_READ,
      headers: { "Content-Type": "application/json" },
      data: param2,
    };
    let data1 = await axios(con).then((res) => {
      return res.data.result;
    });
    let queId1 = data1[0].coding[0];

    let queId2 = data1[1].coding[0];
    if (props?.stateID === queId1 || props?.stateID === queId2) {
      const newArray = [...TableHeader];
      newArray[3].editable = true;
      setHeaderData([...newArray]);
    } else {
      const newArray = [...TableHeader];
      newArray[3].editable = false;
      setHeaderData([...newArray]);
    }

    return data1;
  };

  useEffect(() => {
    // ;
    getId();
  }, [props.state]);

  const RowEDit = (datass) => {
    let selectedDatavalue = data.filter(li => li.ticketId == datass?.id)
    const re = /^[0-9\b]+$/;
    if (
      ![null, "", undefined, "0"].includes(datass?.value) &&
      selectedDatavalue[0].quantity >= datass?.value &&
      re.test(parseInt(datass?.value))
    ) {
      let Arr = data.filter(li => li.ticketId != datass?.id);
    
      let Total =
        parseFloat(selectedDatavalue[0]?.calculatedprice) * parseFloat(datass?.value);

      let obj = {
        UOM: selectedDatavalue[0].UOM,
        activestatus: selectedDatavalue[0].activestatus,
        calculatedprice: selectedDatavalue[0].calculatedprice,
        createdby: selectedDatavalue[0].createdby,
        createddate: selectedDatavalue[0].createddate,
        discount: selectedDatavalue[0].discount,
        dispensequantity: parseInt(datass?.value),
        drug_id: selectedDatavalue[0].drug_id,
        drugcategory: selectedDatavalue[0].drugcategory,
        drugclass: selectedDatavalue[0].drugclass,
        drugcode: selectedDatavalue[0].drugcode,
        drugimage: selectedDatavalue[0].drugimage,
        itemname: selectedDatavalue[0].itemname,
        price: selectedDatavalue[0].price,
        quantity: selectedDatavalue[0].quantity,
        status: selectedDatavalue[0].status,
        totalprice: Total,
        updatedate: selectedDatavalue[0].updatedate,
        updatedby: selectedDatavalue[0].updatedby,
        _id: selectedDatavalue[0]._id,
        _key: selectedDatavalue[0]._key,
        _rev: selectedDatavalue[0]._rev,
      };

      updateselectedList(datass?.id, obj);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify([
        {
          db_name: process.env.REACT_APP_DBNAME,
          entity: "QDMATPtransactionlog",
          is_metadata: true,
          metadataId: process.env.REACT_APP_METADATAID,
          metadata_dbname: process.env.REACT_APP_REPORT_METADATA_DB_NAME,
          filter: {
            ticketId: datass?.id,
          },
          doc: {
            payload: {
              inputDoc: {
                OrderItemCode: obj,
              },
            },
          },
        },
      ]);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/upsert_document`,
        requestOptions
      )
        .then((response) => response.text())

        .catch((error) => console.log("error", error));

      var raw1 = JSON.stringify([
        {
          db_name: process.env.REACT_APP_DBNAME,
          entity: "OrderTransaction",
          is_metadata: false,
          metadataId: process.env.REACT_APP_METADATAID,
          metadata_dbname: process.env.REACT_APP_REPORT_METADATA_DB_NAME,
          filter: {
            ticketId: datass?.id,
          },
          doc: {
            OrderItemCode: obj,
          },
        },
      ]);

      var requestOptions1 = {
        method: "POST",
        headers: myHeaders,
        body: raw1,
        redirect: "follow",
      };

      fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/upsert_document`,
        requestOptions1
      )
        .then((response) => response.json())
        .then((result) => {
          if (result?.Code == 201) {
            //getCreditData();
            checkCreditDEtails(datass?.id , Total);
          }
        })
        .catch((error) => console.log("error", error));
        obj["ticketId"] = datass?.id
        Arr.push(obj);

      setdata(Arr.sort((a, b) => (a.ticketId > b.ticketId) ? -1 : 1));

      alertMessage?.setSnack({
        ...alertMessage,
        open: true,
        severity: AlertProps.severity.success,
        msg: `dispense quantity Updated Succesfully`,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
    } else {
     
      let Arr = data.filter(li => li.ticketId != datass?.id);
      let Total = selectedDatavalue[0]?.price * datass?.value;
      let obj = {
        UOM: selectedDatavalue[0].UOM,
        activestatus: selectedDatavalue[0].activestatus,
        calculatedprice: selectedDatavalue[0].calculatedprice,
        createdby: selectedDatavalue[0].createdby,
        createddate: selectedDatavalue[0].createddate,
        discount: selectedDatavalue[0].discount,
        dispensequantity: selectedDatavalue[0].dispensequantity,
        drug_id: selectedDatavalue[0].drug_id,
        drugcategory: selectedDatavalue[0].drugcategory,
        drugclass: selectedDatavalue[0].drugclass,
        drugcode: selectedDatavalue[0].drugcode,
        drugimage: selectedDatavalue[0].drugimage,
        itemname: selectedDatavalue[0].itemname,
        price: selectedDatavalue[0].price,
        quantity: selectedDatavalue[0].quantity,
        status: selectedDatavalue[0].status,
        totalprice: selectedDatavalue[0].totalprice,
        updatedate: selectedDatavalue[0].updatedate,
        updatedby: selectedDatavalue[0].updatedby,
        _id: selectedDatavalue[0]._id,
        _key: selectedDatavalue[0]._key,
        _rev: selectedDatavalue[0]._rev,
      };
      obj["ticketId"] = datass?.id
      Arr.push(obj);
      setdata(Arr.sort((a, b) => (a.ticketId > b.ticketId) ? 1 : -1));
      alertMessage?.setSnack({
        ...alertMessage,
        open: true,
        severity: AlertProps.severity.error,
        msg: `dispense quantity cannot be 0 or greater than actual Quantity`,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
    }
  };
  return (
    <div style={{ height: 300, width: "100%" }}>
      <DataGrid
        rows={data}
        columns={headerData}
        getRowId={(TableContent) => TableContent?.ticketId}
        onCellEditCommit={RowEDit}
      />
    </div>
  );
};
