import { Paper } from "@material-ui/core";

import React, { Component } from "react";
import { withRouter } from "react-router";
import { withAllContexts } from "../../HOCs";
import { TaskViewer } from "./src/index";
// import { TaskViewer } from "atp-task-viewer-with-tree";

class TaskViewers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      PermissionData: [],
      refreshComponent:false,
    };
  }
  componentDidMount() {
    this.getData()
  }
  getData(){
    var myHeaders = new Headers();
    myHeaders.append("jwttoken", sessionStorage.getItem("queue_Token"));

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_QUEUELIST, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        this.setState({ PermissionData: result.result });
      })
      .catch((error) => console.log("error", error));
  }
  render() {
    return (
      <>
        <Paper
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "#F3F4F7",
          }}
          elevation={3}
        >
          <TaskViewer
            PermissionData={this.state.PermissionData}
            dbName={process.env.REACT_APP_DB}
            performUserAction={process.env.REACT_APP_PERFORMUSERACTION_API}
            auditdb={process.env.REACT_APP_AuditDB}
            theme={require("../../themes/default.json")}
            getdata ={this.getData}
          />
        </Paper>
      </>
    );
  }
}

export default withRouter(withAllContexts(TaskViewers));
