import { Button, Link, Typography } from "@material-ui/core";
import { loadStripe } from "@stripe/stripe-js";
import { useContext } from "react";
import { useEffect, useState } from "react";
import { BackdropContext } from "../../contexts";
import { AxiosCall } from "../../utils";
import { stripePromise } from "../../stripe-config";

function Completion(props) {
  const [messageBody, setMessageBody] = useState("");
  const loading = useContext(BackdropContext);

  async function updateStatus(clientSecret, paymentDetails) {
    let data = { clientSecret, paymentDetails };
    //method, url, params, header
    let result = AxiosCall(
      "POST",
      process.env.REACT_APP_UPDATE_PAYMENT_STATUS,
      data
    );
  }

  useEffect(() => {
    if (!stripePromise) return;

    stripePromise.then(async (stripe) => {
      loading.setBackDrop({
        open: true,
        message: "checking your payment status",
      });
      const url = new URL(window.location);
      const clientSecret = url.searchParams.get("payment_intent_client_secret");
      const paymentDetails = await stripe.retrievePaymentIntent(clientSecret);
      const { error, paymentIntent, ...rest } = paymentDetails;
      updateStatus(clientSecret, paymentDetails);
      console.log("success", error, paymentIntent, rest);
      loading.setBackDrop({
        open: false,
        message: "",
      });
      setMessageBody(
        error ? (
          `> ${error.message}`
        ) : (
          <>
            &gt; Payment {paymentIntent.status}:{" "}
            {/* <a
              href={`https://dashboard.stripe.com/test/payments/${paymentIntent.id}`}
              target="_blank"
              rel="noreferrer"
            >
              {paymentIntent.id}
            </a> */}
          </>
        )
      );
    });
  }, []);

  return (
    <div style={{ margin: "20px", padding: "10px" }}>
      <Typography variant="h3">Thank you!</Typography>
      <Button variant="contained" color="primary" style={{ marginTop: "10px" }}>
        <Link href="/" color="inherit" underline="none">
          Home
        </Link>
      </Button>
      <div
        id="messages"
        role="alert"
        style={
          messageBody
            ? { display: "block", fontWeight: "500", marginTop: "10px" }
            : {}
        }
      >
        {messageBody}
      </div>
    </div>
  );
}

export default Completion;
