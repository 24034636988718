import * as React from "react";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import Typography from "@material-ui/core/Typography";
import "rc-steps/assets/index.css";

import { styled } from "@material-ui/core/styles";

const StyledTimeLineItem = styled(TimelineItem)(({ theme }) => ({
  "&::before": {
    flex: 0,
  },
}));

export const TimeLine = (props) => {
  const getTime = (epoche) => {
    let a = new Date(epoche * 1000);
    let timeStamp = `${a.toDateString()} , ${a.toLocaleTimeString()}`;
    return timeStamp;
  };
  return (
    <>
      {props?.scenarioState?.length !== 0 ? (
        <Timeline>
          {props.scenarioState?.map((state, index) => (
            <StyledTimeLineItem key={index}>
              <TimelineSeparator>
                {index === props.scenarioState?.length - 1 ? (
                  <TimelineDot color="info">
                    <ArticleOutlinedIcon variant="filled" />
                  </TimelineDot>
                ) : (
                  <TimelineDot variant="outlined">
                    <AccessTimeIcon color="disabled" />
                  </TimelineDot>
                )}

                <TimelineConnector />
              </TimelineSeparator>
              {
                <TimelineContent style={{ marginTop: "10px" }}>
                  <Typography
                    variant="body1"
                    component="span"
                    style={{ display: "block" }}
                  >
                    {state?.states}
                  </Typography>

                  <Typography variant="caption">
                    {state?.userAction ? (`${state?.userAction} action  held on ${getTime(
                      state?.statustime
                    )}`) : state?.message}
                  </Typography>
                </TimelineContent>
              }
            </StyledTimeLineItem>
          ))}
        </Timeline>
      ) : (
        <Typography
          variant="body1"
          component="span"
          style={{ display: "block" }}
        >
          No data found
        </Typography>
      )}
    </>
  );
};
