import React from "react";
import { ProductCard } from "./productCard";

const products = [
  {
    productid: 1,
    productname: " Para Z- Par Formalin kit",
    manufacturer: " Manufactured by MEDICAL CHEMICAL CORP",
    price: 18,
    offer: 20,
  },
  {
    productid: 2,
    productname: "R29 Theridon Drops 22 ml",
    manufacturer: " Manufactured by MEDICAL CHEMICAL CORP",
    price: 20,
    offer: 20,
  },
  {
    productid: 3,
    productname: "R29 Theridon Drops 22 ml",
    manufacturer: " Manufactured by MEDICAL CHEMICAL CORP",
    price: 20,
    offer: 20,
  },
  {
    productid: 4,
    productname: "R29 Theridon Drops 22 ml",
    manufacturer: " Manufactured by MEDICAL CHEMICAL CORP",
    price: 20,
    offer: 20,
  },
  {
    productid: 5,
    productname: "R29 Theridon Drops 22 ml",
    manufacturer: " Manufactured by MEDICAL CHEMICAL CORP",
    price: 20,
    offer: 20,
  },
  {
    productid: 6,
    productname: "R29 Theridon Drops 22 ml",
    manufacturer: " Manufactured by MEDICAL CHEMICAL CORP",
    price: 20,
    offer: 20,
  },
];

export const ExploreProducts = () => {
  return (
    <div 
    style={{ display: 'inline-flex', alignItems: 'flex-start', gap: '40px', padding: '20px',
    margin: '0 auto', flexWrap: 'wrap', }}
    >
      {products.map((product) => (
        <div key={product.productid}>
          <ProductCard productname={product.productname} />
        </div>
      ))}
    </div>
  );
};
