import {
  Grid,
  Typography,
  Paper,
  Button,
  Box,
  TextField,
  Divider,
  IconButton,
  useTheme,
  makeStyles,
  Switch,
  // Input,
} from "@material-ui/core";
import EditorToolbar, { modules, formats } from "../react-quill/index";
import "react-quill/dist/quill.snow.css";
import DeleteIcon from "@material-ui/icons/Delete";
import { Autocomplete } from "@material-ui/lab";
import { AlertProps } from "../../utils";
import { AlertContext } from "../../contexts";
import { actions } from "central_pharma_binder";
import React from "react";
// import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { useSelector, useDispatch } from "react-redux";
// import FoodImage from "../../assets/Food_Image.webp";
import { RequiredField } from "../common";
import "./style.css";
import { useContext } from "react";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
const useStyles = makeStyles((theme) => ({
  inputText: {
    "& .MuiInputBase-input": {
      [theme.breakpoints.up("md")]: {
        fontSize: "1rem !important",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "0.8rem !important",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.465rem !important",
      },
    },
  },
  textResponsive: {
    [theme.breakpoints.up("md")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.65rem",
    },
  },
  autoCompleteText: {
    "& .MuiInputBase-root": {
      [theme.breakpoints.up("md")]: {
        fontSize: "1rem",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.465rem",
      },
    },
  },
  buttonText: {
    "& .MuiButton-textSizeSmall": {
      [theme.breakpoints.up("md")]: {
        fontSize: "1rem !important",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "0.8rem !important",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.465rem !important",
      },
    },
  },
}));

const OrderForm = (props) => {
  const theme = useTheme();
  const alert = useContext(AlertContext);
  const classes = useStyles(theme);
  const { editData, edit } = props;
  const [inputFields, setInputFields] = React.useState(
    edit && editData?.[0]?.Addon !== 0 && editData?.[0]?.Addon
      ? editData?.[0]?.Addon
      : [{ title: "", dropdowntext: "", multiselect: false }]
  );
  const dispatch = useDispatch();
  const patientcategory = useSelector(
    (state) => state.personSlice.patientcategory?.data
  );
  const mealtypes = useSelector((state) => state.personSlice.mealtype?.data);
  const daylist = useSelector((state) => state.personSlice.day?.data);
  // console.log("aaaaedit", editData);
  const [value, setValue] = React.useState(
    edit ? editData?.[0]?.MealDescription : ""
  );
  const [meals, setMeals] = React.useState(
    edit
      ? [
          {
            // id: 0,
            dish: editData?.[0]?.MealDisplayName,
            err_dish: "",
            // err_price: "",
            mealday: editData.MealDay,
            mealstype: editData[0].MealCategoryCode,
            patientscat: editData.PatientCategoryCode,
            // err_id: "",
          },
        ]
      : [
          {
            // id: "",
            dish: "",
            err_dish: "",
            mealcode: "",
            mealstype: "",
            patientscat: "",
            // err_id: "",
          },
        ]
  );
  // const [mealCode, setMealCode] = React.useState("");
  const [imageFile, setImageFile] = React.useState({
    file: "",
  });
  // console.log(mealType);
  React.useLayoutEffect(() => {
    dispatch(actions.PATIENTCATEGORY());
    dispatch(actions.DAYLIST());
    dispatch(actions.MEALTYPE());
  }, [dispatch]);
  // const imageHandle = (event) => {
  //   setImageFile({ file: URL.createObjectURL(event.target.files[0]) });
  // };
  const handleNoOfAdd = (ee) => {
    let newfield = { title: "", dropdowntext: "", multiselect: false };
    setInputFields([...inputFields, newfield]);
  };

  const handleDataChange = (e, index, name) => {
    let temp = [...meals];
    temp[index][name] = e;
    temp[index]["err_dish"] = "";
    // temp[index]["err_price"] = "";
    temp[index]["err_description"] = "";
    setMeals(temp);
  };

  const handleChange = (key, value) => {
    setMeals([{ ...meals[0], [key]: value?._id }]);
  };
  // console.log("mealType", meals);
  const handleSave = () => {
    if (
      meals[0].patientscat.length !== 0 &&
      meals[0].mealstype.length !== 0 &&
      value.length !== 0 &&
      meals[0].dish.length !== 0
    ) {
      // console.log("inside save", meals);
      let filter = {};
      if (edit) {
        filter = { _id: editData[0]._id };
      }
      // console.log({ filter });
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      let data = [
        {
          db_name: process.env.REACT_APP_DB,
          entity: process.env.REACT_APP_ENTITY_MEALMASTER,
          // is_metadata: true,
          filter,
          // metadataId: process.env.REACT_APP_METADATAID,
          // metadata_dbname: process.env.REACT_APP_MetadataDB_Name,
          doc: {
            MealCategoryCode: meals[0].mealstype,
            MealDisplayName: meals[0].dish,
            MealDescription: value,
            MealPic: imageFile.fileid,
            MealStatus: true,
            MealCreatedBy: "",
            Addon: inputFields,
          },
        },
      ];
      // console.log(data);
      const payload = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(data),
        redirect: "follow",
      };
      fetch(`${process.env.REACT_APP_ARANGO_API}/api/upsert_document`, payload)
        .then((result) => result.json())
        .then((response) => {
          let filter = {};
          if (edit) {
            filter = {
              _id: editData._id,
            };
          }
          let data = [
            {
              db_name: process.env.REACT_APP_DB,
              entity: process.env.REACT_APP_ENITIY_MEALSCHEDULE,
              filter,
              is_metadata: true,
              metadataId: process.env.REACT_APP_METADATAID,
              metadata_dbname: process.env.REACT_APP_MetadataDB_Name,
              doc: {
                MealCode: response.Result[0].properties?.doc?._id,
                PatientCategoryCode: meals[0].patientscat,
                MealDate: "",
                MealTimeCode: "",
                MealScheduleCreatedBy: "",
                MealStatus: true,
                MealDay: meals[0].mealday,
              },
            },
          ];
          const payload = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify(data),
            redirect: "follow",
          };
          fetch(
            `${process.env.REACT_APP_ARANGO_API}/api/upsert_document`,
            payload
          )
            .then((result) => result.json())
            .then((response) => {
              if (response.Code === 201) {
                alert.setSnack({
                  open: true,
                  severity: AlertProps.severity.success,
                  msg: edit ? "Successfully Updated " : "Successfully Created",
                  vertical: AlertProps.vertical.top,
                  horizontal: AlertProps.horizontal.right,
                });
                props.handleDrawerClose();
                setMeals([]);
              }
            })
            .catch((err) => console.error(err));
        })
        .catch((err) => console.error(err));
    } else {
      alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Required Fields Are Empty",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      // setMeals([...errmsg]);
    }
  };
  // console.log("final", meals);

  const uploadFile = (fileInput) => {
    var formdata = new FormData();
    formdata.append("files", fileInput.target.files[0]);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_IMAGE_UPLOAD_PROTOCAL}://${process.env.REACT_APP_IMAGE_UPLOAD_HOST}/${process.env.REACT_APP_DB}/${process.env.REACT_APP_CONFIG_NO}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => setImageFile(result))
      .catch((error) => console.log("error", error));
  };
  const handleAddOnChange = (e, i, value) => {
    let data = [...inputFields];
    data[i][value] = e;
    setInputFields(data);
  };
  const removeFields = (index) => {
    let data = [...inputFields];
    data.splice(index, 1);
    setInputFields(data);
  };
  // console.log("listOfAddOn", inputFields);

  return (
    <div style={{ margin: "0 15px", padding: "10px" }}>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "10px 0",
        }}
      >
        <Typography className={classes.textResponsive}>ADD MEAL</Typography>
      </Box>
      <Grid container spacing={1}>
        {/* <Grid container item xs={12} sm={12} md={12} lg={12} spacing={2}>
          <Grid item xs={4} sm={4} md={4} lg={4}>
            <div style={{ marginTop: "10px" }}>
              <Typography
                style={{ margin: "5px 0" }}
                className={classes.textResponsive}
              >
                Day
                <RequiredField color={"red"} />
              </Typography>
              <Autocomplete
                PaperComponent={({ children }) => (
                  <Paper className={classes.textResponsive}>{children}</Paper>
                )}
                className={classes.autoCompleteText}
                size="small"
                value={daylist?.find((l) => l._id === meals[0]?.mealday) || ""}
                options={daylist ?? []}
                getOptionLabel={(option) => option.display ?? []}
                onChange={(event, newValue) =>
                  handleChange("mealday", newValue)
                }
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
              />
            </div>
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4}>
            <div style={{ marginTop: "10px" }}>
              <Typography
                style={{ margin: "5px 0" }}
                className={classes.textResponsive}
              >
                Food Type
                <RequiredField color={"red"} />
              </Typography>

              <Autocomplete
                size="small"
                PaperComponent={({ children }) => (
                  <Paper className={classes.textResponsive}>{children}</Paper>
                )}
                className={classes.autoCompleteText}
                value={
                  mealtypes?.find((l) => l?._id === meals[0]?.mealstype) || ""
                }
                options={mealtypes ?? []}
                getOptionLabel={(option) => option.display ?? []}
                onChange={(event, newValue) =>
                  handleChange("mealstype", newValue)
                }
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
              />
            </div>
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4}>
            <div style={{ marginTop: "10px" }}>
              <Typography
                style={{ margin: "5px 0" }}
                className={classes.textResponsive}
              >
                Patient Category
                <RequiredField color={"red"} />
              </Typography>
              <Autocomplete
                className={classes.autoCompleteText}
                size="small"
                value={patientcategory.find(
                  (l) => l._id === meals[0]?.patientscat ?? ""
                )}
                PaperComponent={({ children }) => (
                  <Paper className={classes.textResponsive}>{children}</Paper>
                )}
                options={patientcategory ?? []}
                getOptionLabel={(option) => option.display ?? []}
                onChange={(event, newValue) =>
                  handleChange("patientscat", newValue)
                }
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
              />
            </div>
          </Grid>
        </Grid> */}

        <Grid container item xs={12} sm={12} md={12} lg={12}>
          {meals.map(
            (e, i) =>
              Array.isArray(e) !== true && (
                <Grid
                  container
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  spacing={2}
                >
                  <Grid
                    container
                    item
                    xl={6}
                    lg={6}
                    sm={6}
                    md={6}
                    justifyContent="center"
                    alignContent="flex-start"
                  >
                    <Grid
                      container
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      spacing={2}
                    >
                      <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Typography
                          style={{ margin: "5px 0" }}
                          className={classes.textResponsive}
                        >
                          Day <RequiredField color={"red"} />
                        </Typography>
                        <Autocomplete
                          PaperComponent={({ children }) => (
                            <Paper className={classes.textResponsive}>
                              {children}
                            </Paper>
                          )}
                          className={classes.autoCompleteText}
                          size="small"
                          value={
                            daylist?.find((l) => l._id === meals[0]?.mealday) ||
                            ""
                          }
                          options={daylist ?? []}
                          getOptionLabel={(option) => option.display ?? []}
                          onChange={(event, newValue) =>
                            handleChange("mealday", newValue)
                          }
                          renderInput={(params) => (
                            <TextField {...params} variant="outlined" />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Typography
                          style={{ margin: "5px 0" }}
                          className={classes.textResponsive}
                        >
                          Food Type <RequiredField color={"red"} />
                        </Typography>

                        <Autocomplete
                          size="small"
                          PaperComponent={({ children }) => (
                            <Paper className={classes.textResponsive}>
                              {children}
                            </Paper>
                          )}
                          className={classes.autoCompleteText}
                          value={
                            mealtypes?.find(
                              (l) => l?._id === meals[0]?.mealstype
                            ) || ""
                          }
                          options={mealtypes ?? []}
                          getOptionLabel={(option) => option.display ?? []}
                          onChange={(event, newValue) =>
                            handleChange("mealstype", newValue)
                          }
                          renderInput={(params) => (
                            <TextField {...params} variant="outlined" />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Typography
                          style={{ margin: "5px 0" }}
                          className={classes.textResponsive}
                        >
                          Diet Type <RequiredField color={"red"} />
                        </Typography>
                        <Autocomplete
                          className={classes.autoCompleteText}
                          size="small"
                          value={patientcategory.find(
                            (l) => l._id === meals[0]?.patientscat ?? ""
                          )}
                          PaperComponent={({ children }) => (
                            <Paper className={classes.textResponsive}>
                              {children}
                            </Paper>
                          )}
                          options={patientcategory ?? []}
                          getOptionLabel={(option) => option.display ?? []}
                          onChange={(event, newValue) =>
                            handleChange("patientscat", newValue)
                          }
                          renderInput={(params) => (
                            <TextField {...params} variant="outlined" />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Typography
                          style={{ margin: "5px 0" }}
                          className={classes.textResponsive}
                          fullWidth
                        >
                          Title
                        </Typography>
                        <TextField
                          className={classes.inputText}
                          value={e?.dish}
                          fullWidth
                          size="small"
                          // helperText={e?.err_dish}
                          variant="outlined"
                          // error={e?.dish?.length !== 0 ? true : false}
                          onChange={(evt) =>
                            handleDataChange(evt.target.value, i, "dish")
                          }
                        ></TextField>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography
                          className={classes.textResponsive}
                          fullWidth
                        >
                          Image
                        </Typography>
                        <div className="inputfile">
                          <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => uploadFile(e)}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container item xl={6} lg={6} sm={6} md={6}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Typography
                        style={{ margin: "5px 0" }}
                        className={classes.textResponsive}
                      >
                        Main Course
                      </Typography>
                      {/* <TextField
                      className={classes.inputText}
                      value={e?.description}
                      multiline
                      fullWidth
                      size="small"
                      variant="outlined"
                      // error={e?.err_description?.length !== 0 ? true : false}
                      // helperText={e?.err_description}
                      onChange={(evt) =>
                        handleDataChange(evt.target.value, i, "description")
                      }
                    ></TextField> */}
                      <EditorToolbar />
                      <ReactQuill
                        style={{ height: "70%" }}
                        theme="snow"
                        value={value}
                        modules={modules}
                        formats={formats}
                        onChange={setValue}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    style={{
                      marginTop: "20px",
                      padding: "15px 0",
                    }}
                  >
                    <Divider style={{ margin: "0 0 10px 0" }} />

                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        size="small"
                        onClick={() => handleNoOfAdd()}
                        color="primary"
                        variant="contained"
                      >
                        + NEW ADDON
                      </Button>
                    </div>
                  </Grid>

                  {inputFields?.map((ee, i) => (
                    <React.Fragment>
                      <Typography>Add-On {i + 1} </Typography>
                      <Grid
                        container
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        alignItems="flex-end"
                        style={{
                          margin: "5px 0",
                          border: "1px solid #dddddd ",
                          borderRadius: "4px",
                        }}
                        spacing={2}
                      >
                        <Grid item xs={4} sm={4} lg={4} xl={4} md={4}>
                          <Typography>Title</Typography>
                          <TextField
                            fullWidth
                            value={ee?.title}
                            size="small"
                            variant="outlined"
                            onChange={(e) =>
                              handleAddOnChange(e.target.value, i, "title")
                            }
                          ></TextField>
                        </Grid>
                        <Grid item xs={5} sm={5} lg={5} xl={5} md={5}>
                          <Typography>Add-On </Typography>
                          <TextField
                            placeholder="Eg: Tea,Coffee,Chocolate"
                            fullWidth
                            value={ee?.dropdowntext}
                            size="small"
                            variant="outlined"
                            onChange={(e) =>
                              handleAddOnChange(
                                e.target.value,
                                i,
                                "dropdowntext"
                              )
                            }
                          ></TextField>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          sm={2}
                          lg={2}
                          xl={2}
                          md={2}
                          style={{
                            display: "inline-flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <Typography>MultiSelect</Typography>
                          <Switch
                            checked={ee?.multiselect}
                            inputProps={{
                              "aria-label": "secondary checkbox",
                            }}
                            color="primary"
                            name="checkedB"
                            onChange={(e) =>
                              handleAddOnChange(
                                e.target.checked,
                                i,
                                "multiselect"
                              )
                            }
                          />
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          sm={1}
                          lg={1}
                          xl={1}
                          md={1}
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <IconButton onClick={() => removeFields(i)}>
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  ))}
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {meals.length !== 0 && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          variant="contained"
                          className={classes.buttonText}
                          size="small"
                          style={{
                            backgroundColor: "#4CAF50",
                            color: "white",
                            margin: "0 10px 0 0",
                          }}
                          onClick={() => handleSave()}
                        >
                          Save
                        </Button>
                        <Button
                          variant="contained"
                          className={classes.buttonText}
                          size="small"
                          // style={{
                          //    backgroundColor: "#F44336",
                          //   color: "white",
                          // }}
                          onClick={() => props.handleDrawerClose()}
                        >
                          Cancel
                        </Button>
                      </div>
                    )}
                  </Grid>
                </Grid>
              )
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default OrderForm;
