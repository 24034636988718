import * as React from "react";
import PropTypes from "prop-types";

const SupplementDoseIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={9.27}
    height={16.222}
    {...props}
  >
    <path
      d="M3.283 0a1.361 1.361 0 0 0-1.352 1.352v1.792A3.677 3.677 0 0 0 0 6.373V14.1a2.133 2.133 0 0 0 2.124 2.124h5.021A2.133 2.133 0 0 0 9.27 14.1V6.373a3.677 3.677 0 0 0-1.931-3.229V1.352A1.361 1.361 0 0 0 5.987 0Zm0 1.159h2.7a.184.184 0 0 1 .193.193v1.4A3.648 3.648 0 0 0 5.6 2.7H3.669a3.648 3.648 0 0 0-.579.051v-1.4a.184.184 0 0 1 .193-.192Zm.386 2.7H5.6a2.5 2.5 0 0 1 2.51 2.51V14.1a.957.957 0 0 1-.966.966h-5.02a.957.957 0 0 1-.966-.966V6.373a2.5 2.5 0 0 1 2.511-2.511Zm.966 1.545a2.326 2.326 0 0 0-2.317 2.317V11.2a2.317 2.317 0 0 0 4.635 0V7.725a2.326 2.326 0 0 0-2.318-2.318Zm0 1.159a1.15 1.15 0 0 1 1.159 1.159v1.159H3.476V7.725a1.15 1.15 0 0 1 1.159-1.159Zm-1.159 3.476h2.317V11.2a1.159 1.159 0 0 1-2.317 0Z"
      fill={props.color}
    />
  </svg>
);

export default SupplementDoseIcon;

SupplementDoseIcon.defaultProps = {
  color: "#2a3c50",
};

SupplementDoseIcon.propTypes = {
  color: PropTypes.string,
};
