import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import Select from "react-select";
// store
// import { useDispatch, useSelector } from "react-redux";
// import { actions } from 'binder-engine';

// const { GET_COUNTRY_LEVELS } = actions;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  levelName: {
    marginBottom: 5,
  },
  errorText: {
    color: "red",
    fontSize: "0.75rem",
  },
  hash: {
    color: theme.palette.hash.primary,
  },
}));

const LocationLevel = (props) => {
  const initialState = {
    country: props?.value?.country ?? null,
    level1: props?.value?.level1 ?? null,
    level2: props?.value?.level2 ?? null,
    level3: props?.value?.level3 ?? null,
    level4: props?.value?.level4 ?? null,
    level5: props?.value?.level5 ?? null,
    level6: props?.value?.level6 ?? null,
    level7: props?.value?.level7 ?? null,
    level8: props?.value?.level8 ?? null,
    level9: props?.value?.level9 ?? null,
    level10: props?.value?.level10 ?? null,
    level11: props?.value?.level11 ?? null,
    level12: props?.value?.level12 ?? null,
  };

  const classes = useStyles(props);
  //   const dispatch = useDispatch();

  const [state, setState] = React.useState(initialState);

  React.useEffect(() => {
    if (props.value) {
      getManageLocation(props?.value?.country);
    }
    // eslint-disable-next-line
  }, [props.value]);

  //   const countryLevelData = useSelector(
  //     (state) => state?.manageLocationSlice?.countrylevels
  //   );

  const changeState = (key, value, startLevel = 0) => {
    let updatedState = state;
    updatedState[key] = value;
    onStateChangeCallback(key, value, updatedState, startLevel);
  };

  React.useEffect(() => {
    changeState("country", props?.primeLocation);
    // eslint-disable-next-line
  }, []);

  const onStateChangeCallback = (key, value, updatedState, startLevel = 0) => {
    if (key === "country") {
      getManageLocation(value);
      updatedState = clearLevel(startLevel, updatedState);
    } else if (
      [
        "level1",
        "level2",
        "level3",
        "level4",
        "level5",
        "level6",
        "level7",
        "level8",
        "level9",
        "level10",
        "level11",
        "level12",
      ].indexOf(key) > -1
    ) {
      updatedState = clearLevel(startLevel, updatedState);
    }

    let newState = {
      ...state,
      ...updatedState,
    };

    setState(newState);

    props?.handleChange(newState);
  };

  const clearLevel = (startLevel, updatedState) => {
    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((i) => {
      if (i > startLevel) {
        updatedState[`level${i}`] = null;
      }
      return i;
    });
    return updatedState;
  };

  const getManageLocation = (country) => {
    let countryId = country?.value;
    // dispatch(
    //   GET_COUNTRY_LEVELS({
    //     countryId,
    //   })
    // );
  };

  const giveMeLevelChilds = (level, child) => {
    if (level === 1) {
      return child;
    } else {
      const levelKey = `level${level - 1}`;
      const previousLevel = state?.[levelKey]?.child_level_id ?? "";
      let newChild = child.filter(
        (ch) => previousLevel === ch.parent_child_level_id
      );
      return newChild;
    }
  };

  return (
    <div className={classes.root}>
      {/* Location Levels */}
      <div>
        <Grid container direction="row" alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              style={{ fontSize: 16 }}
              className={classes.hash}
            >
              {props?.label ?? "Location Level"}{" "}
              {props.isrequired && (
                <Typography variant="caption" style={{ color: "red" }}>
                  *
                </Typography>
              )}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={3} className={classes.selectField}>
            <Typography variant="body2" className={classes.levelName}>
              Country
            </Typography>
            <Select
              id={props?.id ? props?.id + "selectCountry" : "selectCountry"}
              className="basic-single"
              classNamePrefix="select"
              isLoading={props?.countryData?.loading}
              isClearable
              isSearchable
              menuPosition={"fixed"}
              value={
                props?.primeLocation ? props?.primeLocation : state.country
              }
              placeholder="Select Country"
              options={
                [{ value: "all", label: "All" }, ...props?.countryData?.data] ??
                []
              }
              onChange={(value) => changeState("country", value)}
              isDisabled={(props?.disabled || props?.isReadonly) ?? false}
              styles={{
                control: (provided, state) =>
                  props?.errorValidation?.error
                    ? {
                        ...provided,
                        boxShadow: "0 0 0 0px red !important",
                        borderColor: "red !important",
                      }
                    : provided,
              }}
            />
            {props?.errorValidation?.error && (
              <div>
                <Typography variant="caption" className={classes.errorText}>
                  {props?.errorValidation?.errorMessage}
                </Typography>
              </div>
            )}
          </Grid>

          {/* {state?.country?.value &&
            Object.values(countryLevelData?.data ?? {}).map((level, key) => {
              let currentLevel = key + 1;
              let stateKey = `level${currentLevel}`;

              if (level.length === 0) {
                return <div key={key + 10}></div>;
              }

              return (
                <Grid key={level?.level_id} item xs={12} sm={12} md={3}>
                  <Typography variant="body2" className={classes.levelName}>
                    {level?.level_name}
                  </Typography>
                  <Select
                    id={props?.id ? props?.id + "select" + key : "select"}
                    className="basic-single"
                    classNamePrefix="select"
                    isLoading={countryLevelData?.loading}
                    isClearable
                    isSearchable
                    menuPosition={"fixed"}
                    value={state?.[stateKey]}
                    onChange={(value) =>
                      changeState(stateKey, value, currentLevel)
                    }
                    placeholder={"Select " + level?.level_name}
                    options={[
                      { value: "all", label: "All" },
                      ...giveMeLevelChilds(currentLevel, level?.child),
                    ]}
                  />
                </Grid>
              );
            })} */}
        </Grid>
      </div>
    </div>
  );
};

export default LocationLevel;
