import {
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Radio,
  Divider,
  Tooltip,
  Button,
  Avatar,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core";
import React from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import { Cards } from "../card";
import { PageEmpty } from "../pageEmpty";
// import Button from '@mui/material/Button';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import FormControlLabel from "@mui/material/FormControlLabel";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  AlertContext,
  BackdropContext,
  ProgressBarContext,
} from "../../contexts";
import { AlertProps, AxiosCall } from "../../utils";

const useStyles = makeStyles((theme) => ({
  container: {
    // width: 500,
    height: "100%",
    overflowY: "auto",
    borderRight: "1px solid #e0e0e0",
  },
  Header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 8px",
  },
  Header2: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 16px",
    height: "52px",
  },
  flex: {
    display: "flex",
  },
  menuitemstyle: {
    color: "#000 !important",
    fontSize: "14px !important",
    "&:hover": {
      backgroundColor: "#f0f8ff !important",
    },
  },
  root: {
    "&.MuiFormControlLabel-label": {
      fontSize: "14px !important",
      color: "#007aff !important",
    },
    "&.MuiMenuItem-root ": {
      fontSize: "14px",
      color: "#007aff",
      "&:hover": {
        backgroundColor: "#f0f8ff !important",
      },
    },
    "&.MuiAvatar-fallback": {
      color: "#757575 !important",
    },
  },
  large: {
    //width: "50px",
    //height: "50px",
    borderRadius: "50%",
    //position: "fixed",
    // right: "410px",
    cursor: "pointer",
    //top: 10,
    "& img": {
      objectFit: "scale-down",
      //backgroundColor: "#fff",
    },
    [theme?.breakpoints?.down("xs")]: {
      right: "313px",
    },
  },
}));

export const SidePanel = (props) => {
  const {
    ClearMainPanel = () => false,
    logState = null,
    onCardClicked = () => false,
    isLoading = false,
    queueId,
    scenarioId,
    each,
    stateid,
    getTaskViewerConifg = () => false,
    onFavIconClicked,
    selectedTask,
    onReloadActionPerformed,
  } = props;
  const classes = useStyles();
  const [tasklist, setTask] = React.useState();

  const [dateType, setDateType] = React.useState("single_date");
  const [selectedDate, handleDateChange] = React.useState(null);
  const [fromDate, setFromDate] = React.useState(null);
  const [toDate, setToDate] = React.useState(null);
  const [search, setSearch] = React.useState("");
  const [checked, setChecked] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [updatedialogOpen, setupdateDialogOpen] = React.useState(false);
  const [dateCheck, setDateCheck] = React.useState(false);
  const [UpdateCheck, setUpdateCheck] = React.useState(false);
  const [isCheckAll, setIsCheckAll] = React.useState(false);
  const [isCheck, setIsCheck] = React.useState([]);
  const [isUpdate, setIsUpdate] = React.useState(true);
  const [buttonList, setButtonList] = React.useState([]);
  const backDrop = React.useContext(BackdropContext);
  const progressBar = React.useContext(ProgressBarContext);
  // const [FilterBy, setFilterBy] = React.useState([
  //   { isChecked: false, label: "Sort By A-Z", title: "title" },
  //   { isChecked: false, label: "Sort By Task-Id", title: "subtitle2" },
  // ]);
  React.useEffect(() => {
    setTask(logState);
    handleDateChange(null);
    setFromDate(null);
    setToDate(null);
  }, [logState]);

  React.useEffect(() => {
    GetBtnName();
  }, []);
  const [ticketchecked, setticketchecked] = React.useState(false);
  const alert = React.useContext(AlertContext);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorE2, setAnchorE2] = React.useState(null);

  const open2 = Boolean(anchorE2);
  const id1 = open2 ? "simple-popover" : undefined;

  const handleClick2 = (event) => {
    setAnchorE2(event.currentTarget);
  };

  const handleClose2 = () => {
    setIsUpdate(!isUpdate);
    setAnchorE2(null);
    setIsCheckAll(false);
    setIsCheck([]);
  };

  const onHandleChange = (e) => {
    const list = logState.filter((l) => {
      return (
        l?.field1?.toLowerCase().includes(e.target.value.toLowerCase()) ||
        l?.field2?.toLowerCase().includes(e.target.value.toLowerCase()) ||
        l?.field3?.toLowerCase().includes(e.target.value.toLowerCase()) ||
        l?.field4?.toLowerCase().includes(e.target.value.toLowerCase()) ||
        l?.field5?.toLowerCase().includes(e.target.value.toLowerCase()) ||
        l?.field6?.toLowerCase().includes(e.target.value.toLowerCase()) ||
        l?.field8?.toLowerCase().includes(e.target.value.toLowerCase()) ||
        l?.ticketId.toLowerCase().includes(e.target.value.toLowerCase())
      );
    });
    setTask(list);
    setSearch(e.target.value);
  };
  const clearDateFilter = () => {
    setToDate(null);
    setFromDate(null);
    setTask(logState);
    handleDateChange(null);
    dateCheck && setDateCheck(false);
    // dateCheck === true && handleClose();
    // setDialogOpen(false);
    ClearMainPanel(true);
  };
  const getDateMonthYear = (date) => {
    let dateVal =
      new Date(date).getDate().toString().length === 1
        ? `0${new Date(date).getDate()}`
        : new Date(date).getDate();
    let monthVal =
      (new Date(date).getMonth() + 1).toString().length === 1
        ? `0${new Date(date).getMonth() + 1}`
        : new Date(date).getMonth() + 1;
    let yearVal = new Date(date).getFullYear();
    return `${yearVal}-${monthVal}-${dateVal}`;
  };
  const FilterRange = () => {
    if (
      fromDate !== null &&
      !isNaN(fromDate) &&
      toDate !== null &&
      !isNaN(toDate)
    ) {
      let Res = [];

      logState.forEach((e) => {
        let startDate = new Date(getDateMonthYear(fromDate));
        let endDate = new Date(getDateMonthYear(toDate));
        let CompDate = new Date(
          getDateMonthYear(new Date(e.createdDate * 1000))
        );
        if (CompDate >= startDate && CompDate <= endDate) {
          Res.push(e);
        }
      });
      setTask(Res.reverse());
      if (Res.length === 0) {
        ClearMainPanel(false);
      } else {
        ClearMainPanel(true);
      }
    } else {
      setTask(logState);
    }
  };
  const FilterDate = (date) => {
    if (date !== null && !isNaN(date)) {
      let stringDate = getDateMonthYear(date);
      let Res = [];
      logState.forEach((e) => {
        // let Convert = e.field2.includes("/")
        //   ? e.field2.split("/").reverse().join("-")
        //   : e.field2;

        if (stringDate === getDateMonthYear(new Date(e.createdDate * 1000))) {
          Res.push(e);
        }
      });
      setTask(Res);
      if (Res.length === 0) {
        ClearMainPanel(false);
      } else {
        ClearMainPanel(true);
      }
    } else {
      setTask(logState);
    }
  };
  const onSortClick = () => {
    if (!checked) {
      setChecked(true);
      setticketchecked(false);
      setDateCheck(false);
      const sortlist = tasklist.sort(function (a, b) {
        const nameA = a.field1.toUpperCase();
        const nameB = b.field1.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      setTask(sortlist);
      handleClose();
    } else {
      setChecked(false);
      getTaskViewerConifg(queueId, scenarioId, each, stateid);
    }
  };

  const onSortticketClick = (val) => {
    if (!ticketchecked && val === "id") {
      setticketchecked(true);
      setChecked(false);
      setDateCheck(false);
      const sortlist = tasklist.sort(function (a, b) {
        const nameA = a.ticketId.slice(1);
        const nameB = b.ticketId.slice(1);
        return nameB - nameA;
        // if (nameA < nameB) {
        //   return -1;
        // }
        // if (nameA > nameB) {
        //   return 1;
        // }
        // return 0;
      });
      setTask(sortlist);
      handleClose();
    } else if (!checked && val === "date") {
      setticketchecked(false);
      setChecked(false);
      setUpdateCheck(false);
      setDateCheck(true);
      const sortlist = tasklist.sort(function (a, b) {
        const nameA = a.field9;
        const nameB = b.field9;

        return nameA - nameB;
      });

      setTask(sortlist);
      handleClose();
    } else if (!checked && val === "update") {
      setticketchecked(false);
      setChecked(false);
      setUpdateCheck(true);
      setDateCheck(false);
      handleClose();
    } else {
      setticketchecked(false);
      setChecked(false);
      setDateCheck(false);
      setUpdateCheck(false);
      getTaskViewerConifg(queueId, scenarioId, each, stateid);
    }
  };

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(tasklist?.map((li) => li?.ticketId));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleClick1 = (e) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  const GetBtnName = async () => {
    const myHeader = new Headers();
    myHeader.append("content-type", "application/json");
    const data = await fetch(process.env.REACT_APP_ARANGO_URL_READ_QDMQUERIES, {
      method: "post",
      headers: myHeader,
      body: JSON.stringify({
        db_name: process.env.REACT_APP_DBNAME,
        filter: {
          queueid: queueId,
          scenarioid: scenarioId,
          stateid: stateid,
        },
        queryid: process.env.REACT_APP_BUTTON_QUERY,
      }),
    });

    const result = await data.json();
    if (result.length > 0) {
      if (result[0].length > 0) {
        setButtonList(result[0]);
      }
    }
  };

  const onUpdateButtonClick = async (e) => {
    setIsUpdate(!isUpdate);
  };

  const bulUpdate = async (e) => {
    if (isCheck?.length > 0) {
      let ticketCount = isCheck.length;
      let processcount = 0;
      let errorCount = 0;
      let error = false;
      let errorMessage = "";
      //let status = "";
      progressBar.setBackDrop({
        ...progressBar,
        open: true,
        completed: 0,
        bgcolor: "#01205c",
        message: `${processcount} of ${ticketCount}`,
      });
      setIsUpdate(!isUpdate);

      const myHeader = new Headers();
      myHeader.append("content-type", "application/json");

      var raw = JSON.stringify({
        userAction: e,
        ticketId: [...isCheck],
        verifier_id: localStorage.getItem("person_id"),
      });

      var requestOptions = {
        method: "POST",
        headers: myHeader,
        body: raw,
        redirect: "follow",
      };

      await fetch(
        `${process.env.REACT_APP_NIFI_API}/api/v1/surveillance/bulkupdate`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          let params = {
            db_name: process.env.REACT_APP_DB,
            entity: "BulkTransactionLog",
            filter: `BulkTransactionLog.activestatus==true && BulkTransactionLog._id == '${result?.logid}'`,
            return_fields:
              "Keep(BulkTransactionLog,'ticketcount','processcount','status','errorcount')",
          };

          let intervalID = setInterval(async () => {
            let res = await AxiosCall(
              "post",
              `${process.env.REACT_APP_BASE_URL}/api/read_documents`,
              params
            );
            ticketCount = res?.result[0]?.ticketcount;
            processcount = res?.result[0]?.processcount;
            errorCount = res?.result[0]?.errorcount;
            const result = (processcount * 100) / ticketCount;
            progressBar.setBackDrop({
              ...progressBar,
              open: true,
              completed: result,
              bgcolor: "#01205c",
              message: `${res?.result[0]?.processcount} of ${res?.result[0]?.ticketcount}`,
            });

            if (ticketCount == processcount + errorCount) {
              if (errorCount > 0) {
                clearInterval(intervalID);
                getTaskViewerConifg(queueId, scenarioId, each, stateid);

                setAnchorE2(null);
                setIsCheckAll(false);
                setIsCheck([]);
                onReloadActionPerformed();
                progressBar.setBackDrop({
                  ...progressBar,
                  open: false,
                });
                alert.setSnack({
                  ...alert,
                  open: true,
                  severity: AlertProps.severity.warning,
                  msg: `${errorCount} Tickets Cannot Updated`,
                  vertical: AlertProps.vertical.top,
                  horizontal: AlertProps.horizontal.center,
                });
              } else {
                clearInterval(intervalID);
                getTaskViewerConifg(queueId, scenarioId, each, stateid);
                //setIsUpdate(!isUpdate)
                setAnchorE2(null);
                setIsCheckAll(false);
                setIsCheck([]);
                onReloadActionPerformed();
                progressBar.setBackDrop({
                  ...progressBar,
                  open: false,
                });
                alert.setSnack({
                  ...alert,
                  open: true,
                  severity: AlertProps.severity.success,
                  msg: "Updated Succesfully",
                  vertical: AlertProps.vertical.top,
                  horizontal: AlertProps.horizontal.center,
                });
              }
            }
          }, 5000);
        })
        .catch((error) => console.log("error", error));
    } else {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.warning,
        msg: `Please Select any thing before ${e}`,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
    }
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.Header}>
          <Typography variant="body1" noWrap>
            {"Action Item"}
          </Typography>
          <div className={classes.flex}>
            {isUpdate
              ? buttonList?.length > 0 && (
                  <Tooltip title="Update All">
                    {/* <Avatar
                  variant="square"
                  src="/TaskViewer/assets/update.svg"
                  className={classes.large}
                  style={{ backgroundColor: "#fff", border: "1px solid #757575" , borderRadius:"10px" }}
                  onClick={(e) => onUpdateButtonClick(e)}
               >

               </Avatar> */}
                    <IconButton
                      size="small"
                      style={{
                        borderRadius: "8px",
                        border: "1px solid #e0e0e0",
                        marginRight: "20px",
                      }}
                      onClick={(e) => onUpdateButtonClick(e)}
                    >
                      <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABmJLR0QA/wD/AP+gvaeTAAABdUlEQVQ4ja3Uv0tVYRzH8VfX4qohDjnozSsNjUJUY+Daojjp0BgoQYhDVEsS/gtNBSFiNThUIO4qDuIgURE4OdwaxElw8NovG57nyOHmuedc9AOH85zv8/2+n+/zPOf75Zx1IWe+ilvowT6+YbtZQFuGfQSvcRd/UMdV3MNTlPAJx3kZd+At5lHJ8OnGLFbQl5ftEibyVo0awiauZDk8wfOCsESjeBfH42l4N7ZwqUUgfMAdfEb5YjSOxZV+tQBaxgbe4w0WcZRMLuBGi5l14H6E/sa19OQqLrcITKs3GZTiu02Bf6qJdhuBO7h+BuCJkktZF6riS4GYTjxCf4P9J+aSjy6hlMoFgDPC8Zz21JItHwg3/awAsDEz+IE1VEsp4wsM4kEBKPxNJTOO9tOcyjHTBQxkgF4J25vAS6EzfYy246x+OCwcfF1oALtCnX4XymwSh3goFMR0EpjXYCu4HWF7+IrHmMrwP8qwN9VN1Px/w3XM/gNYJUxjPlf+vAAAAABJRU5ErkJggg==" />
                    </IconButton>
                  </Tooltip>
                )
              : buttonList?.length > 0 && (
                  <>
                    <Button
                      id="basic-button"
                      aria-controls={open2 ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      style={{ backgroundColor: "#007AFF", color: "#fff" }}
                      aria-expanded={open2 ? "true" : undefined}
                      onClick={handleClick2}
                      endIcon={<KeyboardArrowDownIcon />}
                    >
                      {buttonList[0]?.action}
                    </Button>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorE2}
                      open={open2}
                      onClose={handleClose2}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      {buttonList.map((datasss) => {
                        return (
                          <MenuItem
                            className={classes.menuitemstyle}
                            name={datasss?.action}
                            onClick={(e) => bulUpdate(datasss?.action)}
                          >
                            {datasss?.action}
                          </MenuItem>
                        );
                      })}
                    </Menu>
                  </>
                )}
            <IconButton
              size="small"
              style={{
                borderRadius: "8px",
                border: "1px solid #e0e0e0",
                marginLeft: "20px",
              }}
              onClick={(e) => handleClick(e)}
            >
              <FilterListIcon />
            </IconButton>
          </div>
        </div>
        <Grid item xs={12} style={{ padding: "8px" }}>
          <TextField
            id="outlined-name"
            variant="outlined"
            name="Search"
            value={search}
            onChange={(e) => onHandleChange(e)}
            placeholder="Search"
            fullWidth
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        {!isUpdate ? (
          <Grid
            item
            xs={12}
            style={{ padding: "8px", backgroundColor: "aliceblue" }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  icon={<CircleUnchecked />}
                  checkedIcon={<CircleCheckedFilled />}
                  id="selectAll"
                  color="primary"
                  size="small"
                  label="selectAll"
                  onChange={handleSelectAll}
                  checked={isCheckAll}
                />
              }
              label="select All"
            />
            <p style={{ float: "right", padding: "10px", color: "#007aff" }}>
              {isCheck?.length} Selected
            </p>
          </Grid>
        ) : (
          ""
        )}
        {tasklist?.length !== 0 ? (
          <Grid style={{ height: "calc(100% - 120px)", overflowX: "auto" }}>
            {tasklist?.map((log, i) => (
              <>
                <Cards
                  type={1}
                  index={i}
                  data={log}
                  verifier_registration_status={
                    log?.verifier_registration_status
                      ? log?.verifier_registration_status
                      : 0
                  }
                  field1={log?.field1}
                  field2={log?.field2}
                  field3={log?.field3}
                  field4={log?.field4}
                  field5={log?.field5}
                  field6={log?.field6}
                  field7={log?.field7}
                  field8={log?.field8}
                  field9={log?.field9}
                  ticketId={log?.ticketId}
                  state={log?.state?.states}
                  favourite={log?.favourite}
                  onCardClicked={onCardClicked}
                  onFavIconClicked={onFavIconClicked}
                  selectedTask={selectedTask}
                  isUpdate={isUpdate}
                  handleClick1={handleClick1}
                  isCheck={isCheck}
                />
              </>
            ))}
          </Grid>
        ) : (
          <Grid
            container
            style={{
              padding: "8px",
              height: "calc(100% - 120px)",
              display: "grid",
              placeItems: "center",
              width: "100%",
            }}
          >
            {isLoading ? <CircularProgress /> : <PageEmpty />}
          </Grid>
        )}
        <Dialog open={dialogOpen} onClose={() => setDialogOpen((pre) => !pre)}>
          <div>
            <DialogTitle id="alert-dialog-slide-title">
              Filter By Date
            </DialogTitle>
            <Divider />
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item lg={12} sm={12} md={12}>
                  <Typography>Date Type</Typography>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => setDateType("single_date")}
                  >
                    <Radio
                      checked={dateType === "single_date"}
                      onChange={(e) => setDateType(e.target.value)}
                      value="single_date"
                      color="default"
                      name="radio-button-demo"
                      inputProps={{ "aria-label": "E" }}
                      size="small"
                    />
                    <Typography>Single Date</Typography>
                  </span>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => setDateType("range_wise")}
                  >
                    <Radio
                      checked={dateType === "range_wise"}
                      onChange={(e) => setDateType(e.target.value)}
                      value="range_wise"
                      color="default"
                      name="radio-button-demo"
                      inputProps={{ "aria-label": "E" }}
                      size="small"
                    />
                    <Typography>Range Wise</Typography>
                  </span>
                </Grid>
                <Grid
                  item
                  lg={dateType === "range_wise" ? 6 : 12}
                  sm={dateType === "range_wise" ? 6 : 12}
                  md={dateType === "range_wise" ? 6 : 12}
                >
                  <Typography>
                    {dateType === "range_wise" ? "From" : "Custom Date"}
                  </Typography>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      fullWidth
                      inputVariant="outlined"
                      variant="inline"
                      autoOk
                      size="small"
                      placeholder="dd-mm-yyyy"
                      value={
                        dateType === "range_wise" ? fromDate : selectedDate
                      }
                      onChange={(date) =>
                        dateType === "range_wise"
                          ? setFromDate(date)
                          : handleDateChange(date)
                      }
                      format="yyyy/MM/dd"
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                {dateType === "range_wise" && (
                  <Grid item lg={6} sm={6} md={6}>
                    <Typography>To</Typography>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        fullWidth
                        inputVariant="outlined"
                        variant="inline"
                        autoOk
                        size="small"
                        placeholder="dd-mm-yyyy"
                        value={toDate}
                        onChange={(date) => setToDate(date)}
                        format="yyyy/MM/dd"
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                )}
              </Grid>
            </DialogContent>
            <Divider />
            <DialogActions>
              <Button
                onClick={() => (
                  dateType !== "range_wise"
                    ? FilterDate(selectedDate)
                    : FilterRange(),
                  setDialogOpen((pre) => !pre),
                  handleClose(),
                  setDateCheck(true)
                )}
                color="primary"
                variant="outlined"
              >
                Apply
              </Button>
              <Button
                onClick={() => (
                  setDialogOpen(false),
                  dateCheck &&
                    selectedDate === null &&
                    fromDate === null &&
                    toDate == null &&
                    setDateCheck(false),
                  handleClose()
                )}
                color="primary"
                variant="outlined"
              >
                Cancel
              </Button>
              {(selectedDate !== null ||
                fromDate !== null ||
                toDate !== null) && (
                <Button
                  onClick={() => clearDateFilter()}
                  color="primary"
                  variant="outlined"
                >
                  Clear
                </Button>
              )}
            </DialogActions>
          </div>
        </Dialog>

        {/* <Dialog open={updatedialogOpen} onClose={() => setupdateDialogOpen((pre) => !pre)}>
          <div>
            <DialogTitle id="alert-dialog-slide-title">
              Update All
            </DialogTitle>
            <Divider />
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item lg={12} sm={12} md={12}>
                  <Typography>Update All</Typography>
                  <Grid item lg={12} sm={12} md={12}>


                    <input
                      id="selectAll"
                      name="selectAll"
                      type="checkbox"
                      onChange={handleSelectAll}
                      checked={isCheckAll}
                    />

                    Select All
                  </Grid>
                  <Grid item lg={12} sm={12} md={12}>
                    {tasklist?.length !== 0 ? (
                      <Grid style={{ height: "calc(100% - 120px)", overflowX: "auto" }}>
                        {tasklist?.map((log, i) => (
                          <Grid item xs={12} md={12}>

                            <input
                              id={log?.ticketId}
                              name={log?.ticketId}
                              type="checkbox"
                              onChange={handleClick1}
                              checked={isCheck.includes(log?.ticketId)}
                            />
                            {log?.ticketId}
                          </Grid>
                        ))}
                      </Grid>
                    ) : (
                      <Grid
                        container>

                      </Grid>
                    )}
                  </Grid>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => setDateType("single_date")}
                  >
                  </span>
                </Grid>
              </Grid>
            </DialogContent>
            <Divider />
            <DialogActions>
              <Button
                onClick={() => (
                  dateType !== "range_wise"
                    ? FilterDate(selectedDate)
                    : FilterRange(),
                  setDialogOpen((pre) => !pre),
                  handleClose(),
                  setDateCheck(true)
                )}
                color="primary"
                variant="outlined"
              >
                Apply
              </Button>
              <Button
                onClick={() => (
                  setDialogOpen(false),
                  dateCheck &&
                  selectedDate === null &&
                  fromDate === null &&
                  toDate == null &&
                  setDateCheck(false),
                  handleClose()
                )}
                color="primary"
                variant="outlined"
              >
                Cancel
              </Button>
              {(selectedDate !== null ||
                fromDate !== null ||
                toDate !== null) && (
                  <Button
                    onClick={() => clearDateFilter()}
                    color="primary"
                    variant="outlined"
                  >
                    Clear
                  </Button>
                )}
            </DialogActions>
          </div>
        </Dialog> */}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <List dense>
            <ListItem
              button
              dense
              disableGutters
              disablePadding
              style={{ padding: "0 10px" }}
              onClick={() => onSortClick()}
            >
              {/* <ListItemButton role={undefined} > */}
              <ListItemIcon style={{ minWidth: "auto" }}>
                <Checkbox
                  edge="start"
                  checked={checked}
                  disableRipple
                  inputProps={{ "aria-labelledby": "labelId" }}
                />
              </ListItemIcon>
              <ListItemText id={"labelId"} primary="Sort By A-Z" />
            </ListItem>
            <ListItem
              dense
              disableGutters
              disablePadding
              button
              style={{ padding: "0 10px" }}
              onClick={() => onSortticketClick("id")}
            >
              <ListItemIcon style={{ minWidth: "auto" }}>
                <Checkbox
                  edge="start"
                  checked={ticketchecked}
                  disableRipple
                  inputProps={{ "aria-labelledby": "labelId" }}
                />
              </ListItemIcon>
              <ListItemText id={"labelId"} primary="Sort By Task-ID" />
            </ListItem>
            {/* <ListItem
              dense
              disableGutters
              disablePadding
              button
              onClick={() => (
                // dateType !== "range_wise"
                //   ? FilterDate(selectedDate)
                //   : FilterRange(),
                setDialogOpen((pre) => !pre), onSortticketClick("date")
              )}
              style={{ padding: "0 10px" }}
            >
              <ListItemIcon style={{ minWidth: "auto" }}>
                <Checkbox
                  edge="start"
                  checked={dateCheck}
                  disableRipple
                  inputProps={{ "aria-labelledby": "labelId" }}
                />
              </ListItemIcon>
              <ListItemText id={"labelId"} primary="Sort By Date" />
            </ListItem> */}
            {/* Filter by ticket id and alphabet feature */}
            {/* {FilterBy.map((l, index) => (
              <ListItem key={index} dense>
                <ListItemButton
                  role={undefined}
                  onClick={() => onFilterBy(l.isChecked, l.title, index)}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={l.isChecked}
                      disableRipple
                      inputProps={{ "aria-labelledby": "labelId" }}
                    />
                  </ListItemIcon>
                  <ListItemText id={"labelId"} primary={l.label} />
                </ListItemButton>
              </ListItem>
            ))} */}
          </List>
        </Popover>
      </div>
    </>
  );
};
