import React from "react";
import {
  Grid,
  Checkbox,
  FormControlLabel,
  makeStyles,
  TextField,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  gridItem: {
    padding: theme.spacing(2),
  },
}));

export const MultitagOwn = (props) => {
  const { validationState, onChange } = props;

  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid md={12} sm={12} xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={validationState?.isEmailValidation ?? null}
                onChange={(e) => {
                  onChange(e.target.checked, "isEmailValidation");
                }}
                name="emailValidation"
                color="primary"
              />
            }
            label="Add Email Validation on Click Add"
          />
        </Grid>
        <Grid
          md={6}
          sm={12}
          xs={12}
          style={{ paddingRight: 10, paddingTop: 15 }}
        >
          <TextField
            fullWidth
            variant="outlined"
            label="Min"
            type="number"
            value={validationState?.min ?? ""}
            size="small"
            onChange={(e) => onChange(e.target.value, "min")}
          />
        </Grid>
        <Grid md={6} sm={12} xs={12} style={{ paddingTop: 15 }}>
          <TextField
            fullWidth
            variant="outlined"
            label="Max"
            type="number"
            value={validationState?.max ?? ""}
            size="small"
            onChange={(e) => onChange(e.target.value, "max")}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default MultitagOwn;
