import React, { useEffect } from "react";
import {
  TextField,
  Grid,
  Paper,
  makeStyles,
  Card,
  Typography,
  IconButton,
  Avatar,
  Button,
  Select,
  MenuItem,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { ToastMessage } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "central_pharma_binder";
import { SelectBox } from "../../components";
import { async } from "q";
import { PhonenumberValidation } from "./validation";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#ffffff",
    margin: "7px",
    padding: "10px",
  },
  containerGrid: {
    display: "flex",
    margin: "10px",
    padding: "10px 0px 10px 0px",
  },
  heading: {
    color: "#007AFF",
    fontWeight: "700",
    padding: "0em 0em 1em 0em",
  },
  Pharma: {
    fontWeight: "700",
  },
  EDbutton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  entity: {
    color: "#007AFF",
  },
  container: {
    padding: "0.5em 0em 0em 0em",
  },
  button: {
    backgroundColor: "#007AFF",
    color: "#ffffff",
  },
  item: {
    padding: "0.5em 0em 0em 1em",
  },
  numerr: {
    color: "red",
  },
}));
function EditProfile(props) {
 // console.log("props",props?.data[0]?.Address)
  const histroy = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [statesdata, setStatesdata] = React.useState("");
  const [regionsdata, setRegionsdata] = React.useState("");
  const phcode = [{ label: "code", value: "+60(MY)" }];

  const [numerr, setNumerr] = React.useState("");
  const [strerr, setStrerr] = React.useState("");
  const [adrserr, setAdrserr] = React.useState("");
  const [sterr, setSterr] = React.useState("");
  const [cterr, setCterr] = React.useState("");
  const [regerr, setRegerr] = React.useState("");

  const [label3, setLabel3] = React.useState({
    answer: {
      id: "8b880af2-b575-4ff3-8324-2adf8990bfde",
      answer: {
        value: props?.data[0]?.ClientType?.label,
        label: props?.data[0]?.ClientType?.label,
      },
      questions: "Client Type",
      tag: "ClientType",
    },
    question_id: "8b880af2-b575-4ff3-8324-2adf8990bfde",
  });
  const [label1, setLabel1] = React.useState({
    answer: {
      id: "75dce6d8-bd5d-47e5-91b3-922ad83f199c",
      answer: props?.data[0]?.EntityType,
      questions: "Entity Type",
      tag: "EntityType",
    },
    question_id: "75dce6d8-bd5d-47e5-91b3-922ad83f199c",
  });
  const [label, setLabel] = React.useState({
    answer: {
      id: "18af4817-b3cb-4f92-9449-df2b1c018401",
      answer: {
        value: props?.data[0]?.Association?.label,
        label: props?.data[0]?.Association?.label,
      },
      questions: "Association",
      tag: "Association",
    },
    question_id: "18af4817-b3cb-4f92-9449-df2b1c018401",
  });
  const [pname, setPname] = React.useState({
    answer: {
      id: "63a00d95-40e4-4902-ac84-8d5457a6a5d4",
      answer: props?.data[0]?.EntityName,
      questions: "Entity Name",
      tag: "EntityName",
    },
    question_id: "63a00d95-40e4-4902-ac84-8d5457a6a5d4",
  });
  const [name, setName] = React.useState({
    answer: {
      id: "94726c7e-dcf1-4f96-9c09-bb95cfd4e3af",
      answer: props?.data[0]?.ContactPersonName,
      questions: "Contact Person Name",
      tag: "ContactPersonName",
    },
    question_id: "94726c7e-dcf1-4f96-9c09-bb95cfd4e3af",
  });
  const [number, setNumber] = React.useState({
    answer: {
      id: "7ba2eb3e-0726-4d5b-92bd-1e400a884d0c",
      answer: {
        number: props?.data[0]?.PhoneNumber?.number,
        phoneCode: "+60",
      },
      questions: "Phone Number",
      tag: "PhoneNumber",
    },
    question_id: "7ba2eb3e-0726-4d5b-92bd-1e400a884d0c",
  });
  const [address, setAddress] = React.useState({
    answer: {
      id: "b16181ca-2dfd-4a27-8348-bcbd3d58a5e4",
      answer: props?.data[0]?.Address,
      questions: "Address",
      tag: "Address",
    },
    question_id: "b16181ca-2dfd-4a27-8348-bcbd3d58a5e4",
  });
  const [label2, setLabel2] = React.useState({
    answer: {
      id: "e677107e-8179-4f93-aac6-e333cfdce18a",
      answer: props?.data[0]?.City?.label,
      questions: "City",
      tag: "City",
    },
    question_id: "e677107e-8179-4f93-aac6-e333cfdce18a",
  });
  const [state, setState] = React.useState({
    answer: {
      id: "a6cd6452-805f-457d-98e7-860109034bfc",
      answer: {
        value: props?.data[0]?.State?.label,
        label: props?.data[0]?.State?.label,
      },
      questions: "State",
      tag: "State",
    },
    question_id: "a6cd6452-805f-457d-98e7-860109034bfc",
  });
  const [region, setRegion] = React.useState({
    answer: {
      id: "72950d1d-1bbe-4716-9066-988f815b8597",
      answer: {
        value: props?.data[0]?.Region?.label,
        label: props?.data[0]?.Region?.label,
      },
      questions: "Region",
      tag: "Region",
    },
    question_id: "72950d1d-1bbe-4716-9066-988f815b8597",
  });
  const Email = props?.data[0]?.Email;

  let status = localStorage.getItem("status");
  let disabled = status == "true" ? true : false;

  const editables = async () => {
    let profiledatas = [
      name,
      number,
      address,
      label2,
      state,
      region,
    ];

    let mappedData = {}
    profiledatas.map(li => {
      let answer = li?.answer
      mappedData[answer?.tag] = answer?.answer
    })
    let pharmaname = pname?.answer?.answer;
    let contactname = name?.answer?.answer;
    let Entityname = label1?.answer.answer;
    let ticketId = props?.ticketid;
    if (PhonenumberValidation(number)) {
      if (name?.answer?.answer?.toString()?.length > 0) {
        if (address?.answer?.answer?.toString()?.length > 0) {
          if (state?.answer?.answer?.label?.toString()?.length > 0) {
            if (region?.answer?.answer?.label?.toString()?.length > 0) {
              if (label2?.answer?.answer?.toString()?.length > 0) {
                let payload = {
                  ticketId: ticketId,
                  answers: profiledatas,
                  id: props?.Id,
                  pharmaname: pharmaname,
                  contactname: contactname,
                  Entityname: Entityname,
                  mappedData:mappedData
                };

                let config = {
                  method: "POST",
                  url: process.env.REACT_APP_EDITPROFILE_API,
                  //url: "http://localhost:3009/editprofile",
                  headers: { "Content-Type": "application/json" },
                  data: payload,
                };

                let result = await axios(config).then((res) => {
                  return res;
                });
                if (result.data.data.length > 0) {
                  ToastMessage("Successfully updated", "success", "top-center");
                  histroy.goBack();
                }
              } else {
                setCterr("Please fill the City");
              }
            } else {
              setRegerr("Please fill the Region");
            }
          } else {
            setSterr("Please fill the state");
          }
        } else {
          setAdrserr("Please fill the Address");
        }
      } else {
        setStrerr("Please fill the Contact person name");
      }
    } else {
      setNumerr(
        "Please fill atleast 9 No (Re-check the Number you have Entered)"
      );
    }
  };

  const handleCancel = () => {
    props.setEdit(false);
  };

  const states = async () => {
    let param = {
      db_name: process.env.REACT_APP_DB,
      entity: "CodingMaster",
      filter:
        "CodingMaster.Type=='STATE' && CodingMaster.activestatus==true && CodingMaster.status==true",
      return_fields: "{label:CodingMaster.display,value:CodingMaster._id}",
    };
    let result = await axios
      .post(process.env.REACT_APP_ARANGO_URL_READ, param)
      .then((res) => res);
    setStatesdata(result?.data?.result);
  };

  const regions = async () => {
    let param = {
      db_name: process.env.REACT_APP_DB,
      entity: "CodingMaster",
      filter:
        "CodingMaster.Type=='REGION' && CodingMaster.activestatus==true && CodingMaster.status==true",
      return_fields: "{label:CodingMaster.display,value:CodingMaster._id}",
    };
    let result = await axios
      .post(process.env.REACT_APP_ARANGO_URL_READ, param)
      .then((res) => res);
    setRegionsdata(result?.data?.result);
  };

  React.useEffect(() => {
    states();
    regions();
  }, []);

  return (
    <>
      <Grid container className={classes.root}>
        <Grid container>
          <Grid
            container
            xs={12}
            justifyContent="space-between"
            className={classes.containerGrid}
          >
            <Grid item>
              <Typography variant="h5" className={classes.heading}>
                Entity Information
              </Typography>
            </Grid>

            <Grid container item spacing={2}>
              <Grid item style={{ display: "flex", alignItems: "center" }}>
                <Avatar
                  variant="square"
                  style={{
                    height: "8.2rem",
                    width: "8.2rem",
                    borderRadius: "1em",
                  }}
                >
                  <Typography variant="h1">{props.letter}</Typography>
                </Avatar>
              </Grid>
              <Grid item>
                <Grid container spacing={3} className={classes.item}>
                  <Grid item>
                    <Typography>Entity Name</Typography>
                    <Grid container item>
                      <TextField
                        size="small"
                        variant="outlined"
                        disabled={true}
                        value={pname.answer.answer}
                        onChange={(e) =>
                          setPname({
                            ...pname,
                            answer: { answer: e.target.value },
                          })
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid item>
                    {/* <Grid item>
                      <BsBuilding size={20} />
                    </Grid> */}
                    <Grid item>
                      <Typography>Association</Typography>
                      <TextField
                        size="small"
                        variant="outlined"
                        value={label?.answer.answer.value}
                        disabled={true}
                        onChange={(e) =>
                          setLabel({
                            ...label,
                            answer: {
                              answer: {
                                value: e.target.value,
                                label: e.target.value,
                              },
                            },
                          })
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid item>
                    {/* <Typography className={classes.entity}>
                        {label1?.answer.answer}
                      </Typography> */}
                    <Typography>Entity Type</Typography>
                    <TextField
                      size="small"
                      variant="outlined"
                      className={classes.entity}
                      disabled={true}
                      value={label1?.answer?.answer}
                      onChange={(e) =>
                        setLabel1({
                          ...label1,
                          answer: { answer: e.target.value },
                        })
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} className={classes.item}>
                  <Grid item>
                    {/* <Typography className={classes.entity}>
                        {label1?.answer.answer}
                      </Typography> */}
                    <Typography>Client type</Typography>
                    <TextField
                      size="small"
                      variant="outlined"
                      className={classes.entity}
                      disabled={true}
                      value={label3?.answer?.answer?.label}
                      onChange={(e) =>
                        setLabel3({
                          ...label3,
                          answer: {
                            answer: {
                              value: e.target.value,
                              label: e.target.value,
                            },
                          },
                        })
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          justifyContent="space-between"
          className={classes.containerGrid}
        >
          <Grid item>
            <Typography variant="h5" className={classes.heading}>
              Contact Details
            </Typography>
          </Grid>

          <Grid container spacing={2}>
            <Grid item>
              <Grid container>
                <Typography>Contact Person Name</Typography>
                <Typography variant="h6" style={{ color: "red" }}>
                  *
                </Typography>
              </Grid>
              <Grid container>
                <TextField
                  size="small"
                  variant="outlined"
                  value={name?.answer?.answer}
                  onChange={(e) =>
                    setName({
                      ...name,
                      answer: { ...name.answer, answer: e.target.value },
                    })
                  }
                />
              </Grid>
              <label className={classes.numerr}>{strerr}</label>
            </Grid>

            <Grid item>
              <Grid container>
                <Typography>Email</Typography>
                <Typography variant="h6" style={{ color: "red" }}>
                  *
                </Typography>
              </Grid>
              {props?.data[0]?.Email?.toString().length > 0 ? (
                <TextField
                  size="small"
                  variant="outlined"
                  disabled
                  value={props?.data[0]?.Email}
                />
              ) : (
                <Typography> - </Typography>
              )}
            </Grid>

            <Grid item>
              <Grid container>
                <Typography>Phone Number</Typography>
                <Typography variant="h6" style={{ color: "red" }}>
                  *
                </Typography>
              </Grid>
              <Grid container>
                <Autocomplete
                  options={phcode}
                  getOptionLabel={(option) => option.value}
                  size="small"
                  style={{ width: "10rem" }}
                  defaultValue={phcode?.[0]}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" />
                  )}
                />

                <TextField
                  onChange={(e) =>
                    setNumber({
                      ...number,
                      answer: {
                        ...number.answer,
                        answer: {
                          number: e.target.value,
                          phoneCode: "+60",
                        },
                      },
                    })
                  }
                  size="small"
                  variant="outlined"
                  value={number?.answer?.answer?.number}
                />
              </Grid>
              <label className={classes.numerr}>{numerr}</label>
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.container}>
            <Grid item>
              <Grid container>
                <Typography>Address</Typography>
                <Typography variant="h6" style={{ color: "red" }}>
                  *
                </Typography>
              </Grid>
              <Grid container>
                <TextField
                  onChange={(e) =>
                    setAddress({
                      ...address,
                      answer: { ...address.answer, answer: e.target.value },
                    })
                  }
                  size="small"
                  variant="outlined"
                  value={address?.answer.answer}
                />
              </Grid>
              <label className={classes.numerr}>{adrserr}</label>
            </Grid>
            <Grid item style={{ width: "15.6rem" }}>
              <Grid container>
                <Typography>State</Typography>
                <Typography variant="h6" style={{ color: "red" }}>
                  *
                </Typography>
              </Grid>
              <Grid container>
                <Autocomplete
                  options={statesdata}
                  defaultValue={state?.answer?.answer}
                  getOptionLabel={(option) => option.label}
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" />
                  )}
                  fullWidth
                  onChange={(e, value) => {
                    setState({
                      ...state,
                      answer: {
                        ...state.answer,
                        answer: {
                          value: value?.label,
                          label: value?.label,
                        },
                      },
                    });
                  }}
                />
              </Grid>
              <label className={classes.numerr}>{sterr}</label>
            </Grid>
            <Grid item style={{ width: "25.1rem" }}>
              <Grid container>
                <Typography>Region</Typography>
                <Typography variant="h6" style={{ color: "red" }}>
                  *
                </Typography>
              </Grid>
              <Grid item>
                <Autocomplete
                  options={regionsdata}
                  defaultValue={region?.answer?.answer}
                  getOptionLabel={(option) => option.label}
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" />
                  )}
                  fullWidth
                  onChange={(e, value) => {
                    setRegion({
                      ...region,
                      answer: {
                        ...region.answer,
                        answer: {
                          value: value?.label,
                          label: value?.label,
                        },
                      },
                    });
                  }}
                />
              </Grid>
              <label className={classes.numerr}>{regerr}</label>
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.container}>
            <Grid item>
              <Grid container>
                <Typography>City</Typography>
                <Typography variant="h6" style={{ color: "red" }}>
                  *
                </Typography>
              </Grid>
              <Grid item>
                <TextField
                  onChange={(e) =>
                    setLabel2({
                      ...label2,
                      answer: { ...label2.answer, answer: e.target.value },
                    })
                  }
                  size="small"
                  variant="outlined"
                  value={label2?.answer.answer}
                />
              </Grid>
              <label className={classes.numerr}>{cterr}</label>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            justifyContent="space-between"
            className={classes.containerGrid}
          >
            <Grid container>
              <Grid
                item
                container
                spacing={2}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Grid item>
                  <Button variant="outlined" onClick={() => handleCancel()}>
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    className={classes.button}
                    onClick={() => editables()}
                  >
                    submit
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default EditProfile;
