import axios from "axios";
import React from "react";
import { MainPanel, SidePanel } from "../../../components";
import { AlertContext } from "../../../contexts";
import { AlertProps, AxiosCall } from "../../../utils";
import jwt_decode from "jwt-decode";
import { Grid, useMediaQuery, useTheme } from "@material-ui/core";

export const ListPanel = (props) => {
  const {
    queueId,
    scenarioId,
    each,
    stateid,
    scenarioState,
    getScenarioStateCount = () => false,
    getScenarioStateData,
    getAllcount,
    updateScenaorio,
    setValue,
    updateFavState,
    favtab,
    PermissionData,
    timelineState,
  } = props;
  const alertMessage = React.useContext(AlertContext);

  const [logState, setState] = React.useState([]);
  const [selectedTask, setSelectedTask] = React.useState({});
  const [isLoading, Setloading] = React.useState(false);
  const [config, setconfig] = React.useState([]);
  const [timeline, setTimeline] = React.useState([]);
  const db = sessionStorage.getItem("ProjectDbname");
  const [alignment, setAlignment] = React.useState("");
  //const [UserRole , setUserRole] = React.useState("");

  const [clearMainPanelData, setClearMainPanelData] = React.useState(true);

  // let UserRole = sessionStorage.getItem("UserRole");
  const personId = jwt_decode(localStorage.getItem("queue_token"));
  // const notifierid = personId?.personid;
  // let statesEnd = false;
  const theme = useTheme();

  const forTab = useMediaQuery(theme.breakpoints.down("sm"));
  const [hidePanel, setHidePanel] = React.useState(false);

  const updateTimeLine = (value) => {
    // console.log({ value })
    setTimeline(value);
  };

  React.useEffect(() => {
    if (queueId && scenarioId) {
      getTaskViewerConifg(queueId, scenarioId, each, stateid, favtab);
    }

    setSelectedTask({});

    //eslint-disable-next-line
  }, [queueId, scenarioId, favtab]);

  // React.useEffect(() => {
  //   GetRoleBased()
  // }, [UserRole])

  // const GetRoleBased = async () =>{
  //   let RoleId = localStorage.getItem("RoleId");
  //   let params = {
  //     db_name: process.env.REACT_APP_DB,
  //     entity: "IDM_PermissionRoleMapping",
  //     filter: `IDM_PermissionRoleMapping.roleid=='${RoleId}' && IDM_PermissionRoleMapping.activestatus==true`,
  //     return_fields: "IDM_PermissionRoleMapping",
  //   };
  //   let res = await AxiosCall(
  //     "post",
  //     `${process.env.REACT_APP_BASE_URL}/api/read_documents`,
  //     params
  //   );

  //   let RoleName = res?.result[0]?.rolename;

  //   if(RoleName?.toString()?.toLowerCase()?.includes("notifi"))
  //   {

  //     sessionStorage.setItem("userRole", "notifier")
  //     setUserRole("notifier")
  //   }

  //   else if(RoleName?.toString()?.toLowerCase()?.includes("verif"))
  //   {
  //     sessionStorage.setItem("userRole", "verifier")
  //     setUserRole("verifier")
  //   }

  // }

  const getFavouriteList = async (
    queueId,
    scenarioId,
    field1,
    field2,
    field3,
    field4,
    field5,
    field6,
    field7,
    field8,
    field9,
    favtab
  ) => {
    try {
      let params = {
        db_name: `${db}`,
        entity: "QDMATPtransactionlog",
        filter: `QDMATPtransactionlog.queueIds=='${queueId}' and QDMATPtransactionlog.payload.scenarioId[0]=='${scenarioId}' and QDMATPtransactionlog.favourite==true `,
        sort: `QDMATPtransactionlog._key DESC`,
        return_fields: `{field1:QDMATPtransactionlog.payload.inputDoc.${field1},field2:QDMATPtransactionlog.payload.inputDoc.${field2},field9:QDMATPtransactionlog.payload.inputDoc.${field9},field3:QDMATPtransactionlog.payload.inputDoc.${field3},field4:QDMATPtransactionlog.payload.inputDoc.${field4},field5:QDMATPtransactionlog.payload.inputDoc.${field5},field6:QDMATPtransactionlog.payload.inputDoc.${field6},field7:QDMATPtransactionlog.payload.inputDoc.${field7},field8:QDMATPtransactionlog.payload.inputDoc.${field8},ticketId:QDMATPtransactionlog.payload.ticketId,state:first( for queuestates in queuestates filter queuestates._id==QDMATPtransactionlog.payload.statesid[0] return {states:queuestates.states,statesid:queuestates._id}),favourite:QDMATPtransactionlog.favourite,key:QDMATPtransactionlog._key,id:QDMATPtransactionlog._id,verifier_registration_status:QDMATPtransactionlog.payload.inputDoc.verifier_registration_status}`,
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_ARANGO_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };

      await axios(config).then((res) => {
        if (res.data.Code === 201) {
          let data = res.data.result;
          if (favtab) {
            setState(data);
            setSelectedTask(data[0]);
            updateFavState(data);
          } else {
            updateFavState(data);
          }

          Setloading(false);
        } else {
          alertMessage?.setSnack({
            ...alertMessage,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Something Went Wrong",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        }
      });
    } catch (error) { }
  };

  const getTaskViewerConifg = async (
    queueId,
    scenarioId,
    each,
    stateid,
    favtab
  ) => {
    try {
      let params = {
        db_name: `${db}`,
        entity: "Taskviewer_config",
        filter: `Taskviewer_config.queueid=='${queueId}' `,
        return_fields: `Taskviewer_config`,
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_ARANGO_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      Setloading(true);

      await axios(config)
        .then((res) => {
          if (res.data.Code === 201) {
            let data = res.data.result[0];
            let field1 = data?.field1 === "" ? "undefined" : data?.field1;
            let field2 = data?.field2 === "" ? "undefined" : data?.field2;
            let field3 = data?.field3 === "" ? "undefined" : data?.field3;
            let field4 = data?.field4 === "" ? "undefined" : data?.field4;
            let field5 = data?.field5 === "" ? "undefined" : data?.field5;
            let field6 = data?.field6 === "" ? "undefined" : data?.field6;
            let field7 = data?.field7 === "" ? "undefined" : data?.field7;
            let field8 = data?.field8 === "" ? "undefined" : data?.field8;
            let field9 = data?.field9 === "" ? "undefined" : data?.field9;
            let field11 = data?.field11 === "" ? "undefined" : data?.field11;

            setconfig({
              field1: data?.field1 === "" ? "undefined" : data?.field1,
              field2: data?.field2 === "" ? "undefined" : data?.field2,
              field3: data?.field3 === "" ? "undefined" : data?.field3,
              field4: data?.field4 === "" ? "undefined" : data?.field4,
              field5: data?.field5 === "" ? "undefined" : data?.field5,
              field6: data?.field6 === "" ? "undefined" : data?.field6,
              field7: data?.field7 === "" ? "undefined" : data?.field7,
              field8: data?.field8 === "" ? "undefined" : data?.field8,
              field9: data?.field9 === "" ? "undefined" : data?.field9,
              field11: data?.field11 === "" ? "undefined" : data?.field11,
            });

            if (favtab) {
              getFavouriteList(
                queueId,
                scenarioId,
                field1,
                field2,
                field3,
                field4,
                field5,
                field6,
                field7,
                field8,
                field9,
                favtab
              );
            } else {
              getAlltransactionlog(
                queueId,
                scenarioId,
                field1,
                field2,
                field3,
                field4,
                field5,
                field6,
                field7,
                field8,
                field9,
                field11,
                each,
                stateid
              );
            }
          } else if (res.data.error) {
            alertMessage?.setSnack({
              ...alertMessage,
              open: true,
              severity: AlertProps.severity.error,
              msg: `taskviewer_config-${res.data.error}`,
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          } else {
            alertMessage?.setSnack({
              ...alertMessage,
              open: true,
              severity: AlertProps.severity.error,
              msg: "Something Went Wrong",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) { }
  };

  const getAlltransactionlog = async (
    queueId,
    scenarioId,
    field1,
    field2,
    field3,
    field4,
    field5,
    field6,
    field7,
    field8,
    field9,
    field11,
    each,
    stateid
  ) => {
    const db = sessionStorage.getItem("ProjectDbname");
    let reportingOrg = localStorage.getItem("ReportingOrg") ?? ""

    let adminQueryWithState = reportingOrg === "" ? {
      "QDMATPtransactionlog":`QDMATPtransactionlog.queueIds=='${queueId}' and QDMATPtransactionlog.payload.scenarioId[0]=='${scenarioId}'and QDMATPtransactionlog.payload.statesid[0]=='${stateid}' `,
      "form_entries": "form_entries.ticketId==QDMATPtransactionlog.ticketId"
    } : {
      "QDMATPtransactionlog":`QDMATPtransactionlog.queueIds=='${queueId}' and QDMATPtransactionlog.payload.scenarioId[0]=='${scenarioId}'and QDMATPtransactionlog.payload.statesid[0]=='${stateid}' and QDMATPtransactionlog.payload.inputDoc.mappedData.ReportingOrganization.value == '${reportingOrg}'`,
      "form_entries": "form_entries.ticketId==QDMATPtransactionlog.ticketId"
    }

    let adminQueryWithoutState = reportingOrg === "" ? {
      "QDMATPtransactionlog": `QDMATPtransactionlog.queueIds=='${queueId}' and QDMATPtransactionlog.payload.scenarioId[0]=='${scenarioId}'`,
      "form_entries": "form_entries.ticketId==QDMATPtransactionlog.ticketId"
    } : {
      "QDMATPtransactionlog": `QDMATPtransactionlog.queueIds=='${queueId}' and QDMATPtransactionlog.payload.scenarioId[0]=='${scenarioId}' and QDMATPtransactionlog.payload.inputDoc.mappedData.ReportingOrganization.value == '${reportingOrg}'`,
      "form_entries": "form_entries.ticketId==QDMATPtransactionlog.ticketId"
    }

    try {
      let params = each
        ? {
          db_name: `${db}`,
          entity: "QDMATPtransactionlog,form_entries",
          filter: adminQueryWithState,
          sort: `QDMATPtransactionlog._key DESC`,
          return_fields: `distinct {field1:QDMATPtransactionlog.payload.inputDoc.${field1},field2:QDMATPtransactionlog.payload.inputDoc.${field2},field9:QDMATPtransactionlog.payload.inputDoc.${field9},field3:QDMATPtransactionlog.payload.inputDoc.${field3},field4:QDMATPtransactionlog.payload.inputDoc.${field4},field5:QDMATPtransactionlog.payload.inputDoc.${field5},field6:QDMATPtransactionlog.payload.inputDoc.${field6},field7:QDMATPtransactionlog.payload.inputDoc.${field7},field8:QDMATPtransactionlog.payload.inputDoc.${field8},field11:QDMATPtransactionlog.payload.inputDoc.${field11},ticketId:QDMATPtransactionlog.payload.ticketId,state:first( for queuestates in queuestates filter queuestates._id==QDMATPtransactionlog.payload.statesid[0] return {states:queuestates.states,statesid:queuestates._id}),favourite:QDMATPtransactionlog.favourite,key:QDMATPtransactionlog._key,id:QDMATPtransactionlog._id,createdDate:QDMATPtransactionlog.createddate,verifier_registration_status:QDMATPtransactionlog.payload.inputDoc.verifier_registration_status}`,
        }
        : {
          db_name: `${db}`,
          entity: "QDMATPtransactionlog,form_entries",
          filter: adminQueryWithoutState,
          sort: `QDMATPtransactionlog._key DESC`,
          return_fields: `distinct {field1:QDMATPtransactionlog.payload.inputDoc.${field1},field2:QDMATPtransactionlog.payload.inputDoc.${field2},field9:QDMATPtransactionlog.payload.inputDoc.${field9},field3:QDMATPtransactionlog.payload.inputDoc.${field3},field4:QDMATPtransactionlog.payload.inputDoc.${field4},field5:QDMATPtransactionlog.payload.inputDoc.${field5},field6:QDMATPtransactionlog.payload.inputDoc.${field6},field7:QDMATPtransactionlog.payload.inputDoc.${field7},field8:QDMATPtransactionlog.payload.inputDoc.${field8},field11:QDMATPtransactionlog.payload.inputDoc.${field11},ticketId:QDMATPtransactionlog.payload.ticketId,state:first( for queuestates in queuestates filter queuestates._id==QDMATPtransactionlog.payload.statesid[0] return {states:queuestates.states,statesid:queuestates._id}),favourite:QDMATPtransactionlog.favourite,key:QDMATPtransactionlog._key,id:QDMATPtransactionlog._id,createdDate:QDMATPtransactionlog.createddate,verifier_registration_status:QDMATPtransactionlog.payload.inputDoc.verifier_registration_status}`,
        };

      let config = {
        method: "post",
        url: `${process.env.REACT_APP_ARANGO_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };

      await axios(config)
        .then((res) => {
          if (res.data.Code === 201) {
            let data = res.data.result;
            // console.log("res.data.result", res.data.result);
            setState(data);
            setSelectedTask(data[0]);
            getFavouriteList(
              queueId,
              scenarioId,
              field1,
              field2,
              field3,
              field4,
              field5,
              field6,
              field7,
              field8,
              field9,
              false
            );
            setTimeout(() => {
              Setloading(false);
            }, 1000);
          } else {
            alertMessage?.setSnack({
              ...alertMessage,
              open: true,
              severity: AlertProps.severity.error,
              msg: "Something Went Wrong",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) { }
  };

  const getActionPerformedState = async (ticketId) => {
    const db = sessionStorage.getItem("ProjectDbname");
    try {
      let params = {
        db_name: `${db}`,
        entity: "QDMATPtransactionlog",
        filter: `QDMATPtransactionlog.ticketId == '${ticketId}'`,
        return_fields: `QDMATPtransactionlog.payload.statesid`,
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_ARANGO_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      Setloading(true);

      await axios(config)
        .then((res) => {
          if (res.data.Code === 201) {
            const data = res.data.result[0];
            const TabValue =
              scenarioState.findIndex((l) => data[0] === l._id) + 2;
            setValue(`${TabValue}`);
          } else {
            alertMessage?.setSnack({
              ...alertMessage,
              open: true,
              severity: AlertProps.severity.error,
              msg: "Something Went Wrong",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) { }
  };
  const onCardClicked = (cardData) => {
    let arr = cardData;
    setSelectedTask(arr);
    setAlignment("");
    if (forTab) {
      setHidePanel(true);
    }
  };

  const onFavIconClicked = (data) => {
    addThisFavourite(data.key, data.favourite);
  };

  const addThisFavourite = async (key, favourite) => {
    const db = sessionStorage.getItem("ProjectDbname");
    try {
      let params = [
        {
          db_name: `${db}`,
          entity: "QDMATPtransactionlog",
          filter: {
            _key: `${key}`,
          },
          is_metadata: true,
          metadataId: `${process.env.REACT_APP_METADATAID}`,
          metadata_dbname: `${process.env.REACT_APP_METADATA_DB_NAME}`,
          doc: {
            favourite: !favourite,
          },
        },
      ];
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_ARANGO_URL_UPSERT}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };

      await axios(config).then((res) => {
        if (res.data.Code === 201) {
          let data = res.data.Result[0];
          let isFav = data.properties.doc.favourite;
          if (!favtab) {
            getTaskViewerConifg(queueId, scenarioId, each, stateid, false);
            getFavouriteList(
              queueId,
              scenarioId,
              config.field1,
              config.field2,
              config.field3,
              config.field4,
              config.field5,
              config.field6,
              config.field7,
              config.field8,
              config.field9,
              false
            );
            getScenarioStateCount(queueId, scenarioId);
          } else {
            getTaskViewerConifg(queueId, scenarioId, each, stateid, true);
            getScenarioStateCount(queueId, scenarioId);
          }

          alertMessage.setSnack({
            ...alertMessage,
            open: true,
            severity: AlertProps.severity.success,
            msg: isFav
              ? "Added to favourite list!"
              : "Removed from favourite list",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        } else {
          alertMessage.setSnack({
            ...alertMessage,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Something Went Wrong!",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        }
      });
    } catch (error) { }
  };

  const onUserActionPerformed = (ticketid) => {
    setSelectedTask({});
    setState([]);
    Setloading(true);
    setTimeout(async () => {
      await getActionPerformedState(ticketid);
      await getScenarioStateData(queueId, scenarioId);
      await getTaskViewerConifg(queueId, scenarioId, each, stateid, false);
      getScenarioStateCount(queueId, scenarioId);
      updateScenaorio(scenarioId);
      Setloading(false);
    }, 2000);
  };

  const onReloadActionPerformed = () => {
    Setloading(true);
    setTimeout(async () => {
      await getTaskViewerConifg(queueId, scenarioId, each, stateid, false);
      await getScenarioStateData(queueId, scenarioId);
      getScenarioStateCount(queueId, scenarioId);
      getAllcount(queueId, scenarioId);
      updateScenaorio(scenarioId);
      Setloading(false);
    }, 2000);
  };

  const updateState = (value) => {
    setAlignment(value);
  };
  const ClearMainPanel = (val) => {
    if (val) {
      setSelectedTask({});
    }
    setClearMainPanelData(val);
  };
  return (
    <React.Fragment>
      <div
        style={{
          height: "100%",
          display: "flex",
          background: "#ffffff",
        }}
      >
        {!hidePanel && (
          <Grid item xs={12} md={4} lg={3} sm={12}>
            <SidePanel
              ClearMainPanel={ClearMainPanel}
              PermissionData={PermissionData}
              isLoading={isLoading}
              config={config}
              logState={logState}
              getTaskViewerConifg={getTaskViewerConifg}
              each={each}
              queueId={queueId}
              scenarioId={scenarioId}
              selectedTask={selectedTask}
              stateid={stateid}
              onCardClicked={onCardClicked}
              onFavIconClicked={onFavIconClicked}
              onReloadActionPerformed={onReloadActionPerformed}
            />
          </Grid>
        )}

        {(!forTab || hidePanel) && clearMainPanelData && (
          <Grid item xs={12} md={8} lg={9} sm={12}>
            <MainPanel
              scenarioId={scenarioId}
              PermissionData={PermissionData}
              isLoading={isLoading}
              alignment={alignment}
              updateState={updateState}
              logState={logState}
              selectedTask={selectedTask}
              scenarioState={scenarioState}
              onUserActionPerformed={onUserActionPerformed}
              onReloadActionPerformed={onReloadActionPerformed}
              updateTimeLine={updateTimeLine}
              timeline={timeline}
              queueId={queueId}
              each={each}
              stateid={stateid}
              favtab={favtab}
              getTaskViewerConifg={getTaskViewerConifg}
              timelineState={timelineState}
              setHidePanel={setHidePanel}
            />
          </Grid>
        )}
      </div>
    </React.Fragment>
  );
};
