import React from "react";
import FormRenderer from "../formRenderer";
import {
  useMediaQuery,
  useTheme,
  Typography,
  Toolbar,
  AppBar,
  makeStyles,
  Button,
} from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { AxiosCall } from "../../api";

import Config from "../../config";
import { LoadingScreen } from "../../components";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import { aqlQuery } from "../../utils";
import { actions } from "central_pharma_binder";
import { AlertContext } from "../../contexts";
import { AlertProps } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import FormRendererParent from "../OrgFormRenderer";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: theme.palette.appBar.main,
    borderBottomStyle: "solid",
    borderBottomWidth: "1px",
    borderBottomColor: "black",
  },
}));
const RegistrationForm = (props) => {
  const Theme = useTheme();
  const sm = useMediaQuery(Theme.breakpoints.down("sm"));
  const classes = useStyles();
  const location = useLocation();

  const [loading, setLoading] = React.useState(true);
  const [thanks, setThanks] = React.useState(false);
  const [entryId, setEntryId] = React.useState("");
  const [entryId2, setEntryId2] = React.useState("");
  const dispatch = useDispatch();
  const [email, setEMail] = React.useState("");
  const alert = React.useContext(AlertContext);
  const [formRendering, setFormRendering] = React.useState();
  const [showForm, setShowForm] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [ticketId, setTicketId] = React.useState(location?.state?.id);
  const [editss, setEditss] = React.useState(false);
  const history = useHistory();
  const [userparams, setUserParams] = React.useState(false);

  React.useEffect(() => {
    checkUserExist();

    onReloadActionPerformed(location?.state?.id);
    setEditss(location?.state?.edit ? location?.state?.edit : false);
  }, []);

  React.useEffect(() => {
    geturl();
  }, [email]);

  // console.log("edits", location?.state?.id);

  const geturl = () => {
    if (history.location.pathname === "/userregistration" && email === "") {
      setUserParams(true);
    } else if (email !== "") {
      setUserParams(false);
    }
  };
  async function checkUserExist() {
    //  ;
    let ticketId = location?.state?.id;
    let email = location?.state?.Email;

    if (ticketId) {
      let params = {
        db_name: Config.dbname,
        entity: "form_entries",
        filter: `form_entries.ticketId=='${ticketId}' and form_entries.activestatus==true and form_entries.form_id == '${process.env.REACT_APP_PROFILE_FORMID}'`,
        return_fields: "form_entries",
      };
      let data = await AxiosCall(
        "post",
        `${Config.api_url}/api/read_documents`,
        params
      );

      if (data?.result.length > 0) {
        let formDetials = data?.result[0];
        setEntryId(formDetials?.entry_id);
      }

      setLoading(false);
    } else {
      setLoading(false);
    }

    if (email) {
      setEMail(email);
    }
  }

  const getFormFromExecuteAql = async (formId, ticketId) => {
    let params = {
      db_name: `${process.env.REACT_APP_DBNAME}`,
      query: aqlQuery.getFormData(formId, ticketId),
    };
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_READ_DOCUMENT}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: params,
    };

    let updateState;

    await axios(config)
      .then((res) => {
        let aqldata = res.data[0];

        let answerArr = aqldata?.bulkAnswers?.answers ?? [];
        let answer = {};
        answerArr?.map((l) => (answer[l.question_id] = l.answer));

        updateState = {
          formData: aqldata,
          selected_form: aqldata?.form[0],
          answer: answer,
          _id: aqldata?.bulkAnswers?.entry_id,
          secName: aqldata?.bulkAnswers?.secName ?? 1,
        };

        // console.log(updateState, "updateState");

        // this.setState({
        //   ...this.state,
        //   formRendering: updateState,
        //   showForm: true,
        // })
        // return updateState;
        // setFormRendering(updateState);
        // setShowForm(true);
      })
      .catch((err) => {
        // console.log(err);
        return null;
      });

    return updateState;
  };

  const getEntryId = async (ticketId) => {
    let params2 = {
      db_name: `${process.env.REACT_APP_DBNAME}`,
      filter: {
        ticketId: ticketId,
      },
      queryid: "ee150a83-2326-4b5a-9795-d96676d3c082",
    };

    let config2 = {
      method: "post",
      url: `${process.env.REACT_APP_QDMQUERIES}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: params2,
    };

    let entryId = await axios(config2).then((res) => {
      return {
        entry_id: res?.data[0]?.entry_id,
        form_id: res?.data[0]?.form_id,
      };
      // this.setState({
      //   ...this.state,
      //   entry_id: res.data[0].entry_id,
      // })
    });

    return entryId;
  };

  const onReloadActionPerformed = async (ticketId) => {
    // Setloading(true);

    let updateState = await getFormFromExecuteAql(
      process.env.REACT_APP_FORMID,
      ticketId
    );

    // let detail = await getEntryId(ticketId);
    // console.log("updateStateInner", updateState?._id);
    setFormRendering(updateState);
    setShowForm(true);
    setEntryId(updateState?._id);
    setEntryId2(updateState?._id);
    setTicketId(ticketId);
    setIsLoading(false);
    setLoading(false);

    // this.setState({
    //   ...this.state,
    //   showForm: true,
    //   ticketId: ticketId,
    //   entry_id: detail.entry_id,
    //   formRendering: updateState,
    //   form_id: detail.form_id,
    //   isLoading: false,
    // });
  };
  const removeSpacesInObjectKeys = (obj) => {
    const newObj = {};

    Object.keys(obj).forEach((key) => {
      const newKey = key.replace(/\s/g, ""); // Remove spaces from the key using a regular expression
      newObj[newKey] = obj[key];
    });

    return newObj;
  };

  const updateEntry = async (data) => {
    const ETYType = data?.answer[process.env.REACT_APP_ETY_QUE_ID];
    const ETName = data?.answer[process.env.REACT_APP_ETY_NAME_QUE_ID];
    let status = location.state.status;

    // console.log("ETYType", entryId2, data);
    let datas = {
      data: data,
      ticketId: location?.state?.id,
      personId: location?.state?.personId,
      status: status,
    };

    let params = {
      form_id: data?.formId,
      data: data?.answer,
      notifier_status: data?.isDraft ? 9 : 1,
      notifyType: data?.isDraft,
      entry_id: entryId2,
      isEdit: true,
      ticketId: location?.state?.id,
    };

    let QDMData = data?.data;

    // console.log("mappedDataUpdatedBfore", data?.data);

    // let removedSpaceInKeys = removeSpacesInObjectKeys(QDMData?.mappedData);

    // QDMData.mappedData = removedSpaceInKeys;

    // console.log("mappedDataUpdated", QDMData);

    Promise.resolve(dispatch(actions.UPSERT_ENTRY(params))).then((res) => {
      if (res?.payload?.data?.Result) {
        let ticketId = location?.state?.id;
        Promise.resolve(
          dispatch(
            actions.UPDATE_QDM_TRANSACTION_LOG({
              ticketId,
              data: data?.data,
            })
          )
        ).then((response) => {
          if (response?.payload?.data?.Result) {
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.success,
              msg: "Form Successfully Submitted",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          } else {
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.error,
              msg: "Something went wrong!",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          }
        });
      } else {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Something went wrong!",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      }
    });

    let creditData = await AxiosCall(
      "post",
      process.env.REACT_APP_REDOSUBMIT_API,
      // "http://localhost:3009/redosubmit",
      // "http://localhost:3009/creditlimit",
      datas
    );

    if (creditData.response === 201) {
      setThanks(true);
      setUserParams(true);
    }

    // history.goBack();

    // console.log("creditsss", creditData);
  };

  const goBack = () => {
    // history.push("/register-pintar");
    setThanks(true);
    setUserParams(true);
    // console.log("goBak");
  };

  return (
    <>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar variant="dense">
          <div>
            {props?.logo ? (
              <img
                alt="icon"
                src="images/pintar2u.webp"
                style={{ width: "100px", height: "50px", aspectRatio: "1" }}
              />
            ) : (
              <></>
            )}
            <div>
              <Typography className={classes.title} noWrap></Typography>
            </div>
          </div>
          <Typography variant="h5" color="primary">
            Registration
          </Typography>
        </Toolbar>
      </AppBar>
      {!thanks ? (
        <div style={{ height: "calc(100% - 63px)" }}>
          {!loading ? (
            <>
              {location?.state?.edit ? (
                <>
                  {entryId2 && (
                    <FormRendererParent
                      onSaveForm={(data) => {
                        // console.log("jkli");
                        updateEntry(data);
                      }}
                      secName={formRendering?.secName}
                      title="redoedit"
                      secToShow={[0, null]}
                      form_detail={formRendering?.formData}
                      entryId={entryId2}
                      ticketId={location?.state?.id}
                      isReadyOnly={false}
                      isEdit={true}
                      allowEdit={true}
                      userloggedin={false}
                      // UserRole={"notifier"}
                      // isEdit={true}

                      // forms={this.state?.formRendering?.formData?.form}
                      // form_detail={formRendering?.formData}
                      state={[
                        {
                          form_id: process.env.REACT_APP_FORMID,
                          // assessment: {},
                          // is_metadata: false,
                          // client_id: "",
                          // is_active: true,
                          answer: {
                            phoneNumber: location?.number
                              ? { number: location?.number, phoneCode: "+60" }
                              : {},
                          },
                        },
                      ]}
                    />
                  )}
                </>
              ) : (
                <FormRenderer
                  goBack={goBack}
                  secName={0}
                  title="notifyAdd"
                  secToShow={[0, null]}
                  status={location?.state?.status}
                  state={[
                    {
                      form_id: process.env.REACT_APP_FORMID,
                      assessment: {},
                      is_metadata: false,
                      client_id: "",
                      is_active: true,
                      answer: {
                        Email: email !== "" ? email : "",
                      },
                    },
                  ]}
                  hideBtn={true}
                  entryId={entryId}
                  allowEdit={true}
                  // number={number}
                  ticketId={location?.state?.id}
                  userloggedin={userparams}
                />
              )}
            </>
          ) : (
            <div style={{ height: "100%" }}>
              <LoadingScreen message={"Loading...."} />
            </div>
          )}
        </div>
      ) : (
        <div style={{ height: "calc(100% - 63px)" }}>
          {!userparams ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "calc(100% - 63px)",
                background: "#fff",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography variant="h4" align="center">
                  Thank You!
                </Typography>
                <Typography variant="h6" align="center" gutterBottom>
                  {`Your registration has been ${
                    entryId ? "updated" : "submitted"
                  } Successfully!`}
                </Typography>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      history.push("/register-pintar");
                    }}
                  >
                    Go Back
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "calc(100% - 63px)",
                background: "#fff",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography variant="h4" align="center">
                  Thank You!
                </Typography>
                <Typography variant="h6" align="center" gutterBottom>
                  {`Your registration has been ${
                    entryId ? "updated" : "submitted"
                  } Successfully!`}
                </Typography>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      history.push("/dashboard");
                    }}
                  >
                    Go Back
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};
export default RegistrationForm;
