import { Rating } from "@material-ui/lab";
import React from "react";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@material-ui/icons/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@material-ui/icons/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@material-ui/icons/SentimentSatisfiedAltOutlined";
import SentimentVerySatisfiedIcon from "@material-ui/icons/SentimentVerySatisfied";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  styleRating: {
    color: (props) =>
      theme.palette[customIcons[props.value]?.color ?? "primary"].main,
    "&:hover": {
      color: (props) =>
        theme.palette[customIcons[props.hoverValue]?.color ?? "primary"].main,
    },
  },
}));
const customIcons = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon fontSize="large" />,
    label: "Very Dissatisfied",
    color: "error",
  },
  2: {
    icon: <SentimentDissatisfiedIcon fontSize="large" />,
    label: "Dissatisfied",
    color: "error",
  },
  3: {
    icon: <SentimentSatisfiedIcon fontSize="large" />,
    label: "Neutral",
    color: "warning",
  },
  4: {
    icon: <SentimentSatisfiedAltIcon fontSize="large" />,
    label: "Satisfied",
    color: "success",
  },
  5: {
    icon: <SentimentVerySatisfiedIcon fontSize="large" />,
    label: "Very Satisfied",
    color: "success",
  },
};

// const StyledRating = withStyles((theme) => ({
//   iconFilled: {
//     color: theme.palette["primary"].main,
//   },
//   iconHover: {
//     color: theme.palette["primary"].main,
//   },
// }))(Rating);

function IconContainer(props) {
  const { value, ...other } = props;
  //   const classes = useStyles();

  return (
    <span {...other} style={{ margin: "4px 2px" }}>
      {customIcons[value].icon}
    </span>
  );
}

export const RateUsComponent = (props) => {
  const { id, value, max, getSelectedRating, getHoverRating, hoverValue } =
    props;
  const classes = useStyles({ value, hoverValue });
  return (
    <>
      <Rating
        name={`customized-color-${id}`}
        value={value}
        max={max}
        onChange={(event, newValue) => getSelectedRating(newValue, id)}
        onChangeActive={(event, newHover) => getHoverRating(newHover, id)}
        IconContainerComponent={IconContainer}
        className={classes.styleRating}
      />
    </>
  );
};
