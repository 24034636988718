import React from "react";
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
  createGenerateClassName,
  StylesProvider,
} from "@material-ui/core/styles";

import { ThemeContext } from "./contexts";
import { Themes } from "./utils";

const AppTheme = (props) => {
  let [theme, setTheme] = React.useState({
    name: props.appTheme,
  });

  const giveMeTheme = () => {
    let currentThemeJson;

    //Geting the theme json for  the selected theme
    switch (theme.name) {
      case Themes.default:
        currentThemeJson = {
          direction: "ltr",
          typography: {
            fontFamily: "TDS_regular",
            body1: {
              fontFamily: "TDS_medium",
            },
            button: {
              fontFamily: "TDS_medium",
              textTransform: "capitalize",
            },
            label: {
              fontFamily: "TDS_regular",
              fontSize: ".85rem",
            },
          },
          overrides: {
            MuiCssBaseline: {
              "@global": {
                html: {
                  scrollBehavior: "smooth",
                },
                body: {
                  backgroundColor: "#F2F3F6",
                },
                ".MuiButton-root": {
                  fontFamily: "TDS_medium",
                  textTransform: "capitalize",
                },
              },
            },
          },
          palette: {
            mode: "light",
            primary: {
              light: "#01205C",
              main: "#01205C",
              dark: "#01205C",
              contrastText: "#fff",
            },
            secondary: {
              light: "#EC6A49",
              main: "#EC6A49",
              dark: "#EC6A49",
              contrastText: "#fff",
            },
            error: {
              light: "#e57373",
              main: "#f44336",
              dark: "#d32f2f",
              contrastText: "#fff",
            },
            hash: {
              primary: "#83859B",
              secondary: "#F0F0F0",
              disabled: "rgba(112, 111, 128, 0.86)",
              hint: "rgba(112, 111, 128, 0.86)",
            },
            info: {
              light: "#e0f2ff",
              main: "#2A60BC",
              dark: "#2A60BC",
              contrastText: "#fff",
            },
            success: {
              light: "#a4c5fc",
              main: "#3BB213",
              dark: "#3BB213",
              contrastText: "#fff",
            },
            default: {
              main: "#FFFFFF",
            },
          },
        };
        break;

      case Themes.dark:
        currentThemeJson = {
          direction: "ltr",
          typography: {
            fontFamily: "TDS_regular",
          },
          palette: {
            mode: "dark",
            primary: {
              light: "#ff33b9",
              main: "#ff00a8",
              dark: "#b20075",
              contrastText: "#fff",
            },
            secondary: {
              light: "#766dde",
              main: "#5449d6",
              dark: "#3a3395",
              contrastText: "#fff",
            },
            error: {
              light: "#e57373",
              main: "#f44336",
              dark: "#d32f2f",
              contrastText: "#fff",
            },
          },
        };
        break;

      default:
        currentThemeJson = {
          direction: "ltr",
          typography: {
            fontFamily: "TDS_regular",
            body1: {
              fontFamily: "TDS_medium",
            },
            button: {
              fontFamily: "TDS_medium",
              textTransform: "capitalize",
            },
            label: {
              fontFamily: "TDS_regular",
              fontSize: ".85rem",
            },
          },
          overrides: {
            MuiCssBaseline: {
              "@global": {
                html: {
                  scrollBehavior: "smooth",
                },
                body: {
                  backgroundColor: "#F2F3F6",
                },
                ".MuiButton-root": {
                  fontFamily: "TDS_medium",
                  textTransform: "capitalize",
                },
              },
            },
          },
          palette: {
            mode: "light",
            primary: {
              light: "#01205C",
              main: "#01205C",
              dark: "#01205C",
              contrastText: "#fff",
            },
            secondary: {
              light: "#EC6A49",
              main: "#EC6A49",
              dark: "#EC6A49",
              contrastText: "#fff",
            },
            error: {
              light: "#e57373",
              main: "#f44336",
              dark: "#d32f2f",
              contrastText: "#fff",
            },
            hash: {
              primary: "#83859B",
              secondary: "#F0F0F0",
              disabled: "rgba(112, 111, 128, 0.86)",
              hint: "rgba(112, 111, 128, 0.86)",
            },
            info: {
              light: "#e0f2ff",
              main: "#2A60BC",
              dark: "#2A60BC",
              contrastText: "#fff",
            },
            success: {
              light: "#a4c5fc",
              main: "#3BB213",
              dark: "#3BB213",
              contrastText: "#fff",
            },
            default: {
              main: "#FFFFFF",
            },
          },
        };
        break;
    }

    //Creating a Theme with json
    let currentTheme = createTheme(props.appTheme);

    //Making theme work with responsive font size
    currentTheme = responsiveFontSizes(currentTheme);

    return currentTheme;
  };

  const generateClassName = createGenerateClassName({
    productionPrefix: "Task-comp",
    seed: "Task-comp",
  });

  return (
    <ThemeContext.Provider value={{ ...theme, setTheme }}>
      <StylesProvider generateClassName={generateClassName} injectFirst>
        <ThemeProvider theme={giveMeTheme()}>{props.children}</ThemeProvider>
      </StylesProvider>
    </ThemeContext.Provider>
  );
};

export default AppTheme;
