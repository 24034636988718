import * as React from "react";
import PropTypes from "prop-types";

const PillIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={13.974} height={14} {...props}>
    <path
      d="M9.884 0a4.105 4.105 0 0 0-2.912 1.2l-5.77 5.773a4.119 4.119 0 0 0 5.825 5.825l3.006-3.006a.547.547 0 0 0 .122-.122l2.642-2.642a4.2 4.2 0 0 0 .352-5.424 3.173 3.173 0 0 0-.352-.4A4.107 4.107 0 0 0 9.884 0Zm0 1.086a3.029 3.029 0 0 1 2.139 5.168L9.707 8.57 5.429 4.293l2.313-2.317a3.016 3.016 0 0 1 2.142-.89ZM4.656 5.065l4.279 4.278-2.681 2.68a3.017 3.017 0 0 1-4.279 0 3.017 3.017 0 0 1 0-4.279Z"
      fill={props.color}
    />
  </svg>
);

export default PillIcon;

PillIcon.defaultProps = {
  color: "#2a3c50",
};

PillIcon.propTypes = {
  color: PropTypes.string,
};
