import React from "react";
import { makeStyles, Grid, Typography, IconButton } from "@material-ui/core";

import { Upload } from "./fileUpload";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import { ToBase64 } from "../../utils";

import { AlertContext } from "../../contexts";

import { AlertProps } from "../../utils";
import GetAppIcon from "@material-ui/icons/GetApp";

var b64toBlob = require("b64-to-blob");
var FileSaver = require("file-saver");

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    position: "relative",
    minHeight: "100%",
    backgroundColor: theme?.palette?.background?.paper,
    margin: 0,
  },
  root: {
    // padding: theme?.spacing(3),
    // paddingTop: theme?.spacing(2),
  },
  footer: {
    position: "absolute",
    bottom: 0,
    width: "100%",
  },
  content: {
    display: "inline-flex",
    width: "100%",
  },
  cardContainer: {
    backgroundColor: theme?.palette?.background?.paper,
    marginTop: theme?.spacing(2),
    boxShadow: "0px 10px 15px #00000014",
    border: "2px solid #E2E2E2",
    borderRadius: "8px",
  },
  Container: {
    // backgroundColor: theme.palette.background.paper,
    marginTop: theme?.spacing(0.5),
    marginBottom: theme?.spacing(0.5),
    // boxShadow: "0px 10px 15px #00000014",
    border: "1px solid #E2E2E2",
    borderRadius: "8px",
    padding: "2px",
  },
  circularLoader: {
    paddingRight: "8px",
    position: "relative",
    "& .MuiCircularProgress-root": {
      position: "relative",
    },
  },
  loadCenter: {
    position: "absolute",
    left: 14,
  },
  uploadText: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  errorText: {
    color: "red",
    fontSize: "0.75rem",
  },
}));

export const UploadReports = (props) => {
  const { validation } = props;
  // console.log(validation);
  const classes = useStyles(props);

  const alert = React.useContext(AlertContext);

  const [uploaddocc, setuploaddocc] = React.useState(
    props?.value ? props?.value : []
  );

  const returnFileSize = (number) => {
    if (number < 1024) {
      return number + "bytes";
    } else if (number >= 1024 && number < 1048576) {
      return (number / 1024).toFixed(1) + "KB";
    } else if (number >= 1048576) {
      return (number / 1048576).toFixed(1) + "MB";
    }
  };

  const FileSizeSetLimit = (lists) => {
    // console.log(lists);
    return !lists.every((list) =>
      list.size.slice(-2) === "KB"
        ? Number(list.size.slice(0, -2))
        : Number(list.size.slice(0, -2)) * 1024 <= validation.max * 1024
    );

    // if (number >= 1048576) {
    //   if ((number / 1048576).toFixed(1) > 15) {
    //     return false;
    //   } else {
    //     return true;
    //   }
    // } else {
    //   return true;
    // }
  };
  const typesAllowed = () =>
    props.question_options.reduce(
      (acc, curr) => (acc += `${curr.split("/")[1].toUpperCase()} `)
    );

  const ValidateNumberOfFiles = (num) => {
    let valid = false;
    if (num <= Number(validation.noOfFiles)) {
      valid = true;
    }
    return valid;
  };
  const handleFileUploadApi = async (file) => {
    var formdata = new FormData();
    formdata.append("files", file, file.name);
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    let { fileid } = await (
      await fetch(validation.uploadurl, requestOptions)
    ).json();
    return `${validation.uploadurl.slice(0, -2)}${fileid}`;
  };
  const handleUpload = async (e) => {
    e.preventDefault();
    let fileList = [];
    if (
      validation.multiple === true &&
      validation.noOfFiles.length !== 0 &&
      !ValidateNumberOfFiles(e.target.files.length)
    ) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: `Cannot Upload More Than ${validation.noOfFiles} File !`,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
    } else {
      for (let i = 0; i < e.target.files.length; i++) {
        let file = e.target.files[i];
        let size = returnFileSize(file.size);
        let files = new FormData();
        files.append("files", file);
        let base = !validation?.typeOfUpload
          ? await ToBase64(file)
          : await handleFileUploadApi(file);

        const data = {
          name: file?.name,
          load: true,
          file: base,
          size: size,
          type: file?.type,
        };

        fileList.push(data);
      }
      if (FileSizeSetLimit(fileList)) {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: `File Size Is Too Large, Maximum File Size is ${validation.max ? validation.max : 1
            }MB !`,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      } else if (!validFileType(fileList)) {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: `Only ${typesAllowed()} File Type Accepted !`,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      } else {
        setuploaddocc(fileList);
        props.onChange([...uploaddocc, ...fileList]);
      }
      // if (FileSizeSetLimit(file.size)) {
      //   if (props?.single && uploaddocc.length >= 1) {
      //     alert.setSnack({
      //       ...alert,
      //       open: true,
      //       severity: AlertProps.severity.error,
      //       msg: "Only Single File can be Uploaded !",
      //       vertical: AlertProps.vertical.top,
      //       horizontal: AlertProps.horizontal.center,
      //     });
      //   } else {
      //     if (props?.single && !validFileType(file)) {
      //       alert.setSnack({
      //         ...alert,
      //         open: true,
      //         severity: AlertProps.severity.error,
      //         msg: "Only Image File Type Accepted !",
      //         vertical: AlertProps.vertical.top,
      //         horizontal: AlertProps.horizontal.center,
      //       });
      //     }

      // if (validFileType(fileList)) {
      // setuploaddocc([...uploaddocc, ...[data]]);
      // props.onChange([...uploaddocc, ...[data]]);
      // }

      //     if (!props?.single) {
      //       setuploaddocc([...uploaddocc, ...[data]]);

      //       props.onChange([...uploaddocc, ...[data]]);
      //     }
      //   }
      // }
      // if (!FileSizeSetLimit(file.size)) {
      //   alert.setSnack({
      //     ...alert,
      //     open: true,
      //     severity: AlertProps.severity.error,
      //     msg: "File Size Too Large !",
      //     vertical: AlertProps.vertical.top,
      //     horizontal: AlertProps.horizontal.center,
      //   });
      // }
    }
  };
  const handleDelete = (e) => {
    var uploaddoccdelete = [];
    uploaddoccdelete = [...uploaddocc];
    uploaddoccdelete.splice(0, 1);
    //delete uploaddocc[0];
    // uploaddocc.splice(i, 1);

    setuploaddocc([...uploaddoccdelete]);

    props.onChange([...uploaddoccdelete]);

    // uploaddocc.splice(0,1);
    // setuploaddocc([...uploaddocc]);
    // props.onChange([...uploaddocc]);
  };

  const getLabel = (props) => {
    return (
      <Typography variant="body1" style={{ fontSize: 16 }} gutterBottom>
        {props?.label}{" "}
        {props?.isrequired && (
          <Typography
            variant="caption"
            style={{ color: "red", marginLeft: "2px" }}
          >
            *
          </Typography>
        )}
      </Typography>
    );
  };

  const fileTypes = [
    // "image/png",
    // "image/bmp",
    // "image/gif",
    // "image/jpeg",
    // "image/pjpeg",
    // "image/png",
    // "image/svg+xml",
    // "image/tiff",
    // "image/webp",
    // "image/x-icon",
    ...props?.question_options,
  ];

  const validFileType = (files) => {
    return files.every((file) => fileTypes.includes(file.type));
  };

  const Download = async (data) => {
    var contentType = data.file.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
    var b64Data = data.file.split(",")[1];

    var blob = await b64toBlob(b64Data, contentType);

    var saveBlob = await new Blob([blob], { type: contentType });
    await FileSaver.saveAs(saveBlob, data.name);
  };

  return (
    <div
      className={classes.rootContainer}
      style={{ display: props?.disabled ? "none" : "" }}
    >
      <div className={classes.root} noValidate>
        {uploaddocc?.length > 0 && props?.isReadonly && (
          <Typography variant="body1" color={"textPrimary"}>
            {getLabel(props)}
          </Typography>
        )}
        <Grid>
          {!props?.isReadonly && (
            <Grid item>
              <Upload
                acceptType={props?.question_options}
                onChange={handleUpload}
                single={props?.single}
                id={props?.id}
              />
            </Grid>
          )}

          {uploaddocc?.length > 0 && props?.isReadonly && (
            <>
              <div>
                <Typography variant="body1">Uploaded Files</Typography>
              </div>
              <div
                style={{
                  overflowY: "auto",
                  overflowX: "hidden",
                  height: props?.single ? "125px" : "187.5px",
                  border: "1px solid #00000014",
                }}
              >
                {uploaddocc?.map((item, key) => {
                  return (
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      className={classes.Container}
                    >
                      <Grid item xs={2} sm={2} md={1} lg={1}>
                        <DescriptionOutlinedIcon
                          style={{ marginLeft: "3px" }}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={6}
                        md={9}
                        lg={10}
                        className={classes.uploadText}
                      >
                        <Typography variant="subtitle2" color="textPrimary">
                          {item?.name}
                        </Typography>
                      </Grid>

                      <Grid item xs={2} sm={2} md={1} lg={1}>
                        <IconButton
                          id={`${"downloadBtn" + key}`}
                          onClick={() => window.open(item.file)}
                        >
                          <GetAppIcon style={{ color: "green" }} />
                        </IconButton>
                      </Grid>
                    </Grid>
                  );
                })}
              </div>
            </>
          )}
        </Grid>
        {props?.errorValidation && props?.errorValidation?.error && (
          <div>
            <Typography variant="caption" className={classes.errorText}>
              {props?.errorValidation?.errorMessage}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};
