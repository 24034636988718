/**
 * @author Gayathri Murugesan
 * @email gayathri.murugesan@ainqa.com
 * @create date 2022-04-01
 * @modify date 2022-04-01
 * @desc The first component of the application, all the required context and routes are used here
 * to setup the application.
 */

import React from "react";
import AppAuth from "./App.auth";
import AppTheme from "./App.theme";
import AppAlert from "./App.alert";
import AppErrorBoundary from "./App.errorBoundry";
import RouterApp from "./router";
// import ApolloClient from "./App.gqlclient";
import AppDrawer from "./App.drawer";
import { CssBaseline } from "@material-ui/core";
import AppDialog from "./App.dialog";
import AppBackdrop from "./App.backdrop";
import AppProgress from "./App.progress";
import QueueItem from "./screens/queueItems/index";
// import AppFirebase from "./App.firebase"; //For Push Notification thing
//PERFORMUSERACTION_API REACT_APP_AuditDB
const TaskViewer = (props) => {
  sessionStorage.setItem("ProjectDbname", props.dbName);
  sessionStorage.setItem("PerformUserAction", props.performUserAction);
  sessionStorage.setItem("AuditDB", props.auditdb);
  return (
    <>
      <AppErrorBoundary>
        {/* <AppAuth> */}
        {/* <AppTheme appTheme={props.theme}> */}
        <CssBaseline />
        <AppAlert>
          <AppDialog>
            <AppDrawer>
              <AppBackdrop>
              <AppProgress>
                {/* <AppFirebase> */}
                {/* <RouterApp /> */}
                {/* </AppFirebase> */}
                <QueueItem PermissionData={props.PermissionData} />
                </AppProgress>
              </AppBackdrop>
            </AppDrawer>
          </AppDialog>
        </AppAlert>
        {/* </AppTheme> */}
        {/* </AppAuth> */}
      </AppErrorBoundary>
    </>
  );
};
export default TaskViewer;
