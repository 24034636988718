import * as React from "react";
import PropTypes from "prop-types";

const CheckIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14} {...props}>
    <g data-name="Group 95277">
      <path
        d="M7 14a7 7 0 1 1 7-7 7.008 7.008 0 0 1-7 7Zm0-1.05A5.95 5.95 0 1 0 1.05 7 5.942 5.942 0 0 0 7 12.95Z"
        fill={props.color}
      />
      <path
        d="m10.557 5.09-4.5 4.5a.483.483 0 0 1-.682 0L3.443 7.662a.482.482 0 1 1 .682-.682l1.588 1.588 4.161-4.161a.483.483 0 0 1 .683.682Z"
        fill={props.color}
      />
    </g>
  </svg>
);

export default CheckIcon;

CheckIcon.defaultProps = {
  color: "#3bb213",
  secondaryColor: "#3ab012",
};

CheckIcon.propTypes = {
  color: PropTypes.string,
  secondaryColor: PropTypes.string,
};
