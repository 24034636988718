import React from 'react'
import { Typography } from '@material-ui/core'
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiToggleButton-root': {
      padding: '10px 15px',
      borderRadius: 10,
      minWidth:70,
      backgroundColor:(props)=>props?.borderStyle ? '#fff' : theme.palette.primary.light,
      borderColor:(props)=>props?.borderStyle ?  theme.palette.primary.main : theme.palette.primary.light,
      [theme.breakpoints.down('xs')]: {
        margin: 10
      },
      '& .MuiToggleButton-label': {
        color:(props)=>props?.borderStyle ?  theme.palette.primary.main : '#000',
        textTransform: 'capitalize'
      },
      '&:hover': {
        backgroundColor:(props)=>props?.borderStyle ? '#fff' : theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        '& .MuiToggleButton-label': {
          color:(props)=>props?.borderStyle ? theme.palette.primary.main :  '#fff !important',
        }
      }

    },
    '& .MuiToggleButtonGroup-groupedHorizontal:not(:first-child)': {
      // borderLeft: '1px solid #F5F5F5'
    },
    '& .Mui-selected': {
      backgroundColor: `${theme.palette.primary.main} !important`,
      borderColor: `${theme.palette.primary.main} !important`,
      color: '#fff',
      fontSize: 14,
      '& .MuiToggleButton-label': {
        color: '#fff !important'
      }
    },
    '& .MuiToggleButton-root.Mui-selected:hover': {
      // backgroundColor:theme.palette.secondary.main
    },
    '& .MuiToggleButton-root.Mui-selected + .MuiToggleButton-root.Mui-selected': {
      borderLeft: `1px solid ${theme.palette.primary.main}`
    },
    '& .MuiToggleButtonGroup-root': {
      // flexWrap: 'wrap',
    }
  },
  btnGroup: {
    flexWrap: "wrap",
    paddingTop:"6px"
  },
  toggleBtn: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    
  }
}))




export const ToggleButtonComponent = props => {

  const classes = useStyles(props);

  const [formats, setFormats] = React.useState(() => props?.isSelected ?? []);

  React.useEffect(() => {
    setFormats(props.isSelected)
  }, [props?.isSelected])

  const handleFormat = (event, newFormats) => {
    // setFormats(newFormats);
    props?.onSelected(newFormats);
  };


  return (
    <div className={`${classes.root}`} id={props?.id}>
      <ToggleButtonGroup id="toogleBtnGrp" className={classes.btnGroup} value={formats} onChange={handleFormat} exclusive={props?.exclusive ?? false}>

        {
          props?.toggleButtonData && props?.toggleButtonData.map((_, key) => {
            if (_.label) { 
              return (
                <ToggleButton id={`${"tglBtn"+key}`} disabled={props.disabled} style={{ height: props?.btnHeight ?? ""}} className={classes.toggleBtn} key={key} value={_.value} aria-label={_.value}>
                  <Typography variant="subtitle2">{_.label}</Typography>
                </ToggleButton>
              )
            }
            return <></>
          })
        }

      </ToggleButtonGroup>
    </div>
  )
}

ToggleButtonComponent.propTypes = {
  toggleButtonData: PropTypes.array,
  isSelected: PropTypes.array,
  exclusive: PropTypes.bool,
  onSelected: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  btnHeight: PropTypes.string,
  transferStatus: PropTypes.string,
}
