import React from "react";
import PropTypes from "prop-types";
import { alpha, makeStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import Typography from "@material-ui/core/Typography";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { TbBuildingHospital } from "react-icons/tb";
import { GiHospital } from "react-icons/gi";
import { RiHospitalFill } from "react-icons/ri";
import ApartmentIcon from "@material-ui/icons/Apartment";
import { FaClinicMedical } from "react-icons/fa";
import { ContentPasteSearchOutlined } from "@mui/icons-material";
import Tooltip from "@material-ui/core/Tooltip"
import { AxiosCall } from "../../utils";
import Config from "../../../../../../config";
const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme?.palette?.text?.primary,
    "&:hover > $content": {
      color: "var(--tree-view-color)",
      backgroundColor: "#fff",
    },
    "&:focus > $content, &$selected > $content": {
      color: "var(--tree-view-color)",
      backgroundColor: `var(--tree-view-bg-color)`,
    },
    "&:focus > $content $label, &:hover > $content $label, &$selected > $content $label":
    {
      color: "var(--tree-view-color)",
    },
    "&$selected > $content $label": {
      color: "var(--tree-view-color)",
      backgroundColor: `var(--tree-view-bg-color)`,
    },
  },
  content: {
    color: theme?.palette?.text?.primary,
    borderTopRightRadius: theme?.spacing(2),
    borderBottomRightRadius: theme?.spacing(2),
    paddingRight: theme?.spacing(1),
    fontWeight: theme?.typography?.fontWeightMedium,
    "$expanded > &": {
      fontWeight: theme?.typography?.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 7,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme?.palette?.text?.primary, 0.4)}`,
  },
  label: {
    fontWeight: "inherit",
    color: "inherit",
  },
  labelRoot: {
    display: "flex",
    alignItems: "center",
    padding: theme?.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme?.spacing(1),
  },
  labelText: {
    fontWeight: "inherit",
    flexGrow: 1,
    textAlign: "left",
    fontSize: 13,
  },
  selected: {
    color: "var(--tree-view-color)",
  },
}));

const StyledTreeItem = (props) => {
  const classes = useTreeItemStyles();

  const {
    type,
    labelText,
    labelIcon: LabelIcon,
    labelInfo,
    color,
    bgColor,
    multiSelect,
    expandKeys,
    selectednode,
    selecteddata,
    stateid,
    table,
    level,
    newOrder,
    ...other
  } = props;

  return (
    <TreeItem
      id={props.id}
      label={
        <div id={props.id} className={classes.labelRoot}>
          {![null, undefined, ""].includes(type) &&
            <LabelIcon
              id={props.id}
              color="inherit"
              className={classes.labelIcon}
            />
          }
          <Tooltip title={labelText} placement="top">
            <Typography
              id={props.id}
              variant="body2"
              className={classes.labelText}
              style={{
                display: "-webkit-box",
                WebkitLineClamp: "1",
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                //maxWidth: "180px",
              }}
            >
              {labelText}
            </Typography>
          </Tooltip>
          {selectednode == props?.nodeId &&
            selecteddata?.length > 0 && (
              <Typography id={props.id} variant="caption" color="inherit">
                {selecteddata?.length} Ticket Selected
              </Typography>
            )
          }
        {    newOrder &&
          <Typography id={props.id} variant="caption" color="secondary">
          New Order 
        </Typography> 
          }
          

          <Typography id={props.id} variant="caption" color="inherit">
            {labelInfo}
          </Typography>
          {![null, undefined, ""].includes(multiSelect) ?
            stateid && (
              <Checkbox
                checked={expandKeys[props?.nodeId] ?? false}
                name={props?.nodeId}
                tabIndex={-1}
                disableRipple
                color="primary"
                style={{ fontSize: 8 }}
                onClick={(e) => props?.handleNodeSelect(e, props?.nodeId)}
              />
            )
            :
            stateid && (
              <Checkbox
                checked={expandKeys[props?.nodeId] ?? false}
                name={props?.nodeId}
                tabIndex={-1}
                disableRipple
                color="primary"
                style={{ fontSize: 8 }}
                onClick={(e) => props?.handleNodeSelect(e, props?.nodeId)}
              />
            )
            
          }
          {/* <Checkbox
            checked={expandKeys[props?.nodeId] ?? false}
            name={props?.nodeId}
            tabIndex={-1}
            disableRipple
            color="primary"
            style={{ fontSize: 8 }}
            onClick={(e) => props?.handleNodeSelect(e, props?.nodeId)}
          /> */}

        </div>
      }
      style={{
        "--tree-view-color": color,
        "--tree-view-bg-color": bgColor,
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
};

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: 264,
    flexGrow: 1,
    maxWidth: 400,
  },
  checkbox: {
    "& .App1-MuiButtonBase-root": {
      "& .App1-MuiSvgIcon-root": {
        width: 15,
        height: 15,
      },
    },
    "& span": {
      fontSize: 14,
    },
  },
  addbtn: {
    fontSize: 13,
  },
  main: {
    position: "relative",
    background: theme?.palette?.background?.table,
    //boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)"
  },
  left_arrow: {
    padding: 6,
    backgroundColor: "white",
    position: "absolute",
    right: "-26px",
    "& svg": {
      fontSize: 16,
      paddingLeft: 4,
    },
    boxShadow: "0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
  },
}));

const TreeViewStuct = (props) => {
  const classes = useStyles();
  const { parent_id, disableAddNew, workflow, treedata, stateid } = props;
  const [state, setState] = React.useState(false);
  const [expanded, setExpanded] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  const [allkeys, setallKeys] = React.useState([]);
  const [selecteddata, setselecteddata] = React.useState([]);
  const [expandKeys, setExpandedKeys] = React.useState({

  });
  const [parent, setParent] = React.useState({})
  let datavalue = false
  const [selectednode, setSelectedNode] = React.useState(null)
  const getTreeItemsFromlevelData = (treeItems) => {
    return treeItems?.map((treeItemData) => {

      let children = undefined;
      if (treeItemData?.children && treeItemData?.children.length > 0) {
        children = getTreeItemsFromlevelData(treeItemData?.children ?? []);
      }
      allkeys.push(treeItemData?.str_id);
      return (
        <StyledTreeItem
          // id={`${parent_id}-StyledTreeItem`}
          children={children}
          nodeId={treeItemData?.str_id}
          labelText={treeItemData?.str_name}
          id={treeItemData?.str_id}
          key={`${treeItemData?.id}`}
          data={treeItemData}
          type={treeItemData?.type}
          table={treeItemData?.table}
          newOrder={treeItemData?.newOrder}
          labelIcon={
            treeItemData?.type == "Pharmacy"
              ? ApartmentIcon
              : treeItemData?.type == "Clinic"
                ? TbBuildingHospital
                : treeItemData?.type == "GroupParentEty"
                  ? GiHospital
                  : treeItemData?.type == "IndividualEty"
                    ? RiHospitalFill
                    : ApartmentIcon
          }
          labelInfo={treeItemData?.ticketId}
          handleNodeSelect={(e) => handleNodeSelect(e, treeItemData?.str_id)}
          selected={selected}
          multiSelect={treeItemData?.multiSelect}
          selecteddata={selecteddata}
          expandKeys={expandKeys}
          selectednode={selectednode}
          stateid={stateid}
          level={treeItemData?.level}
          color="#1a73e8"
          bgColor="#e8f0fe"

        />
      );
    });
  };
  const handleToggle = (event, nodeIds) => {

    setExpanded(nodeIds);

  };
  const handleExpandClick = (event) => {
    setExpandedKeys({})
    setselecteddata([])
    setSelectedNode(null)
    setState(event.target.checked);
    event.target.checked ? setExpanded(allkeys) : setExpanded([]);
  };
  const handleSelect = (event, nodeId, data) => {

    if (!datavalue) {
      let childLoop = getAllChildLoop(nodeId, treedata)
    let childValue = []
    if(childLoop[0]?.level == "1")
    {
      if(childLoop[0]?.children[0]?.level == "3")
      {
        childLoop[0]?.children.map(li => {
          childValue.push(...li?.children)
        })
      }
      else{
        childLoop[0]?.children[0]?.children.map(li => {
          childValue.push(...li?.children)
        })
       
      }
    }
    else if(childLoop[0]?.level == "3")
    {
      childValue = childLoop[0]?.children
    }
    else if(childLoop[0]?.level == "4")
    {
      childValue = childLoop
    }
    const Value = updateValue(childValue)
      setSelected(nodeId);
      props.onCardClicked(nodeId[0], selecteddata);
    }
  };
  const bfsSearch = (graph, targetId) => {
    const queue = [...graph];

    while (queue.length > 0) {
      const currNode = queue.shift();
      if (currNode.str_id === targetId) {
        return currNode;
      }
      if (currNode.children) {
        queue.push(...currNode.children);
      }
    }
    return []; // Target node not found
  };

  const getAllChild = (str_id) => {
    return getAllIds(bfsSearch(treedata, str_id));
  };

  // Get all father IDs from specific node
  const getAllFathers = (str_id, list = []) => {
    const node = bfsSearch(treedata, str_id);
    if (node.parent) {
      list.push(node.parent);
      return getAllFathers(node.parent, list);
    }
    return list;
  };
  function getAllIds(node, idList = []) {
    idList.push(node.str_id);
    if (node.children) {
      node.children.forEach((child) => getAllIds(child, idList));
    }
    return idList;
  }

  const getAllTicketId = (str_id) => {
    let childData = bfsSearch(treedata, str_id);
    let data = getTicketId([childData])
    setselecteddata(data)
    return data
  }

  const getTicketId = (val) => {
    let finalComponent = [];
    const childLoop = (val) => {
      val.forEach((e) => {
        if (e?.children && e?.children?.length !== 0) {
          childLoop(e?.children);
        }
        if (e?.multiSelect == undefined) {
          finalComponent.push(e)
        }
      });
    };
    childLoop(val);
    return finalComponent;
  }


  function isAllChildrenChecked(node, list) {

    const allChild = getAllChild(node.str_id);
    const nodeIdIndex = allChild.indexOf(node.str_id);
    allChild.splice(nodeIdIndex, 1);

    return allChild.every((nodeId) =>
      selected.concat(list).includes(nodeId)
    );
  }

  const checkParent = (nodeId, val) => {
    let value = []
    const childLoop = (val) => {
      val.forEach((e) => {

        if (e?.str_id == nodeId) {
          value.push(e?.level)
        }
        else {
          if (e?.children && e?.children?.length !== 0) {
            childLoop(e?.children);
          }
        }
      });
    };
    childLoop(val);
    return value
  }

  const getAllChildLoop = (nodeId , val) => {

    let value = []
    const childLoop = (val) => {
      val.forEach((e) => {

        if (e?.str_id == nodeId) {
          value.push(e)
        }
        else {
          if (e?.children && e?.children?.length !== 0) {
            childLoop(e?.children);
          }
        }
      });
    };
    childLoop(val);
    return value
  }
  const getParentNode = (str_id, tree) => {
    let parentNode;
    for (let i = 0; i < tree.length; i++) {
      const node = tree[i];
      if (node.children) {
        if (node.children.some(item => item.str_id === str_id)) {
          parentNode = node;
        } else if (getParentNode(str_id, node.children)) {
          parentNode = getParentNode(str_id, node.children);
        }
      }
    }
    return parentNode;
  }

  const updateValue = (childValue) => {
  let val = childValue.filter(li => li?.table?.newOrder == true)
  if(val?.length > 0)
  {
    val?.map((li) => {    
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      let data = [
        {
          db_name: process.env.REACT_APP_DB,
          entity: "QDMATPtransactionlog",
          filter: { ticketId: li?.ticketId },
          doc: {
            payload:{
              inputDoc:{
                OrderItemCode:{
                  newOrder:false
                }
              }
            }
          },
        },
      ];
      const payload = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(data),
        redirect: "follow",
      };
      fetch(`${process.env.REACT_APP_ARANGO_API}/api/upsert_document`, payload)
        .then((result) => result.json())
        .then((response) => {
        })
    })
  }
  } 


  const handleNodeSelect = (event, nodeId) => {
    let obj = {}
    let childLoop = getAllChildLoop(nodeId, treedata)
    let childValue = []
    if(childLoop[0]?.level == "1")
    {
      if(childLoop[0]?.children[0]?.level == "3")
      {
        childLoop[0]?.children.map(li => {
          childValue.push(...li?.children)
        })
      }
      else{
        childLoop[0]?.children[0]?.children.map(li => {
          childValue.push(...li?.children)
        })
       
      }
    }
    else if(childLoop[0]?.level == "3")
    {
      childValue = childLoop[0]?.children
    }
    else if(childLoop[0]?.level == "4")
    {
      childValue = childLoop
    }
    const Value = updateValue(childValue)
    if (event.target.checked) {
      const Parentlevel = checkParent(nodeId, treedata)
      if (Parentlevel[0] == "1" || Parentlevel[0] == "2") {
        let data = {
          str_id: nodeId
        }
        setParent(data)
        datavalue = true
        setSelectedNode(nodeId)
        let value = getAllTicketId(nodeId)
        const allChild = getAllChild(nodeId);
        allChild.map(li => {
          obj[li] = true
        })
        setExpandedKeys(obj)
        props.onCardClicked(nodeId, value);
      }
      else if (Parentlevel[0] == "3") {
        let par = getParentNode(nodeId, treedata)
        setParent(par)
        if (parent?.str_id == par?.str_id) {
          if (selecteddata?.length > 0) {
            setParent(par)
            setSelectedNode(par?.str_id)
            datavalue = true
            let data1 = selecteddata
            const allChild = getAllChild(nodeId);
            let value = getAllTicketId(nodeId)
            let data = expandKeys
            data[parent?.str_id] = true
            allChild.map(li => {
              data[li] = true
              data1 = data1.filter(lis => lis.str_id != li)
            })
            data1.push(...value)
            setExpandedKeys(data)
            setselecteddata(data1)
            props.onCardClicked(nodeId, data1);
          }
          else {
            datavalue = true
            setSelectedNode(nodeId)
            let value = getAllTicketId(nodeId)
            const allChild = getAllChild(nodeId);
            allChild.map(li => {
              obj[li] = true
            })
            setExpandedKeys(obj)
            props.onCardClicked(nodeId, value);
          }
        }
        else {
          datavalue = true
          setSelectedNode(nodeId)
          let value = getAllTicketId(nodeId)
          const allChild = getAllChild(nodeId);
          allChild.map(li => {
            obj[li] = true
          })
          setExpandedKeys(obj)
          props.onCardClicked(nodeId, value);
        }
      }
      else if (Parentlevel[0] == "4") {
        
        let par = getParentNode(nodeId, treedata)
        if (par?.level == "3") {
          par = getParentNode(par?.str_id, treedata)
        }
        setParent(par)
        if (parent?.str_id == par?.str_id) {
          if (selecteddata?.length > 0) {
            setSelectedNode(par?.str_id)
            setParent(par)
            datavalue = true
            let data1 = selecteddata
            const allChild = getAllChild(nodeId);
            let value = getAllTicketId(nodeId)
            let data = expandKeys
            // data[parent?.str_id] = true
            allChild.map(li => {
              data[li] = true
              data1 = data1.filter(lis => lis.str_id != li)
            })
            data1.push(...value)
            setExpandedKeys(data)
            setselecteddata(data1)
            props.onCardClicked(nodeId, data1);
          }
          else {
            datavalue = true
            setSelectedNode(nodeId)
            let value = getAllTicketId(nodeId)
            const allChild = getAllChild(nodeId);
            allChild.map(li => {
              obj[li] = true
            })
            setExpandedKeys(obj)
            props.onCardClicked(nodeId, value);
          }
        }
        else {
          datavalue = true
          setSelectedNode(nodeId)
          let value = getAllTicketId(nodeId)
          const allChild = getAllChild(nodeId);
          allChild.map(li => {
            obj[li] = true
          })
          setExpandedKeys(obj)
          props.onCardClicked(nodeId, value);
        }
      }
    }
    else {
      const allChild = getAllChild(nodeId);
      const Parentlevel = checkParent(nodeId, treedata)
      if (Parentlevel[0] == "1" || Parentlevel[0] == "2") {
        datavalue = true
        allChild.map(li => {
          obj[li] = false
        })
        setExpandedKeys(obj)
        setselecteddata([])
        setSelectedNode(null)
        let data1 = []
        props.onCardClicked(nodeId, data1);
      }
      else if (Parentlevel[0] == "3") {
        if (selecteddata?.length > 0) {
          datavalue = true
          const allChild = getAllChild(nodeId);
          let parent = getParentNode(nodeId, treedata)
          let data = expandKeys
          let data1 = selecteddata
          data[parent?.str_id] = false
          allChild.map(li => {
            data[li] = false
            data1 = data1.filter(lis => lis.str_id != li)
          })
          setExpandedKeys(data)
          setselecteddata(data1)
          props.onCardClicked(nodeId, data1);
        }
        else {
          let data1 = []
          props.onCardClicked(nodeId, data1);
          datavalue = true
          setExpandedKeys({})
          setselecteddata([])
          setSelectedNode(null)
        }
      }
      else {
        if (selecteddata?.length > 0) {
          datavalue = true
          let datas = expandKeys
          let parent = getParentNode(nodeId, treedata)
          datas[nodeId] = false
          datas[parent?.str_id] = false
          let value = allChild
          value = value.filter(li => li != nodeId)
          setExpandedKeys(datas)
          let data = selecteddata.filter(li => li.str_id != nodeId)
          setselecteddata(data)
          props.onCardClicked(nodeId, data);
        }
        else {
          let data = []
          props.onCardClicked(nodeId, data);
          datavalue = true
          setExpandedKeys({})
          setselecteddata([])
          setSelectedNode(null)
        }
      }
    }
  };

  return (
    <div
      style={{ marginLeft: "20px", marginTop: "10px" }}
      id={`${parent_id}-expand-div`}
      className={classes.main}
    >
      <Grid
        id={`${parent_id}-expand-grid`}
        container
        spacing={3}
        style={{ paddingBottom: 8 }}
      >
        <Grid
          id={`${parent_id}-expand-sub-grid`}
          item
          xs={6}
          style={{ textAlign: "left" }}
        >
          <FormControlLabel
            id={`${parent_id}-expand-FormControlLabel`}
            style={{ fontSize: 8 }}
            className={classes.checkbox}
            control={
              <Checkbox
                id={`${parent_id}-expand-Checkbox`}
                checked={state}
                onChange={handleExpandClick}
                name="checked"
                color="primary"
                style={{ fontSize: 8 }}
              />
            }
            label="Expand all"
          />
        </Grid>
      </Grid>
      <div
        id={`${parent_id}-TreeView-div`}
        style={{ height: "calc(100vh - 180px)" }}
      >
        <TreeView
          id={`${parent_id}-TreeView`}
          expanded={expanded}
          selected={selected}
          className={classes.root}
          defaultCollapseIcon={<ArrowDropDownIcon />}
          //defaultExpandIcon={<ArrowRightIcon />}
          defaultEndIcon={<div style={{ width: 24 }} />}
          onNodeToggle={handleToggle}
          onNodeSelect={handleSelect}
          multiSelect
        >
          {getTreeItemsFromlevelData(treedata ?? [])}
        </TreeView>
      </div>
      <div id={`${parent_id}-div`} style={{ paddingBottom: "3%" }} />
    </div>
  );
};
export default TreeViewStuct;
