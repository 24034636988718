import { Box, Tab, Typography } from "@material-ui/core";
import React from "react";
import StarIcon from "@mui/icons-material/Star";
import { StyledButton2, StyledLabel } from "../../../styledcomponent";
import { useTheme } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { ListPanel } from "./listPanel";
import axios from "axios";
import { AlertProps } from "../../../utils";
import { AlertContext, BackdropContext } from "../../../contexts";

export const QueueScenarios = (props) => {
  const theme = useTheme();
  const [value, setValue] = React.useState("1");
  const alertMessage = React.useContext(AlertContext);
  const [scenarioState, setscenarioState] = React.useState([]);
  const [statecount, setCount] = React.useState([]);
  const [allcount, setAllCount] = React.useState("0");
  const db = sessionStorage.getItem("ProjectDbname");
  const backDrop = React.useContext(BackdropContext);
  const [favlist, setFavlist] = React.useState([]);

  const updateFavState = (val) => {
    setFavlist(val);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    if (props.queueId && props.currantScenario && props.PermissionData) {
      getScenarioStateData(props.queueId, props.currantScenario);
      getScenarioStateCount(props.queueId, props.currantScenario);
      getAllcount(props.queueId, props.currantScenario);
    }
    //eslint-disable-next-line
  }, [props.queueId, props.currantScenario, props.PermissionData , props?.refresh]);

  const getScenarioStateData = async (queueid, scenarioid) => {
    try {
      let params = {
        db_name: `${db}`,
        entity: "queuescenariostatemapping",
        filter: `queuescenariostatemapping.queueid=='${queueid}'&&queuescenariostatemapping.scenarioid=='${scenarioid}' && queuescenariostatemapping.activestatus==true`,
        return_fields:
          "{scenarioState:(For doc in queuescenariostatemapping.states return MERGE(document(doc._id),{sequenceno:doc.sequenceno}) )}",
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_ARANGO_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };

      backDrop.setBackDrop({
        ...backDrop,
        open: true,
        message: "Fetching Scenarios Data",
      });
      await axios(config)
        .then((res) => {
          if (res.data.Code === 201) {
            let data = res.data.result[0];
            let FinalSenario = data?.scenarioState?.filter(
              (e) =>
                props.PermissionData?.filter(
                  (v) =>
                    v.scenarioChildren.filter(
                      (b) =>
                        b.stateChildren.filter(
                          (n) =>
                            n.repoid.unique_id === e._id &&
                            n.permsnread === true &&
                            b.repoid.unique_id === props.currantScenario
                        ).length !== 0
                    ).length !== 0
                ).length !== 0
            );
            setscenarioState(FinalSenario);
            backDrop.setBackDrop({
              ...backDrop,
              open: false,
            });
          } else {
            alertMessage?.setSnack({
              ...alertMessage,
              open: true,
              severity: AlertProps.severity.error,
              msg: "Something Went Wrong",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) { }
  };

  const getScenarioStateCount = async (queueid, scenarioid) => {
    let reportingOrg = localStorage.getItem("ReportingOrg") ?? ""
    let query = reportingOrg === "" ? {
      db_name: `${db}`,
      filter: {
        queueId: `${queueid}`,
        scenarioId: `${scenarioid}`,
      },
      queryid: "fa7f256d-0eb7-47df-8677-302ea0927ded",
    } : {
      db_name: `${db}`,
      filter: {
        queueId: `${queueid}`,
        scenarioId: `${scenarioid}`,
        "reportingOrgid": `${reportingOrg}`

      },
      queryid: "211088a5-4a91-402f-a3ce-0ac5e63225c3",
    }
    try {
      let params = query;
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_ARANGO_URL_READ_QDMQUERIES}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };

      await axios(config)
        .then((res) => {
          if (res.data) {
            if (res.data?.collection) {
              let data = res.data.collection[0];

              setCount(data?.grps);
            } else {
              let data = res.data[0];

              setCount(data?.grps);
            }
          } else if (res.error) {
            alertMessage?.setSnack({
              ...alertMessage,
              open: true,
              severity: AlertProps.severity.error,
              msg: `QDM Queries ${res.error}`,
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          } else {
            alertMessage?.setSnack({
              ...alertMessage,
              open: true,
              severity: AlertProps.severity.error,
              msg: "Something Went Wrong",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) { }
  };

  const getAllcount = async (queueid, scenarioid) => {
    let reportingOrg = localStorage.getItem("ReportingOrg") ?? ""
    let query = reportingOrg === "" ? {
      db_name: `${db}`,
      entity: "QDMATPtransactionlog",
      filter: `QDMATPtransactionlog.queueIds=='${queueid}' and QDMATPtransactionlog.payload.scenarioId[0]=='${scenarioid}'`,
      return_fields: "QDMATPtransactionlog",
    } : {
      db_name: `${db}`,
      entity: "QDMATPtransactionlog",
      filter: `QDMATPtransactionlog.queueIds=='${queueid}' and QDMATPtransactionlog.payload.scenarioId[0]=='${scenarioid}' and QDMATPtransactionlog.payload.inputDoc.RerportingOrganization.value == '${reportingOrg}' `,
      return_fields: "QDMATPtransactionlog",
    } 

    try {
      let params = query;
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_ARANGO_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };

      await axios(config)
        .then((res) => {
          if (res.data.Code === 201) {
            let data = res.data.result;
            setAllCount(data?.length);
          } else {
            alertMessage?.setSnack({
              ...alertMessage,
              open: true,
              severity: AlertProps.severity.error,
              msg: "Something Went Wrong",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) { }
  };

  const getCount = (id) => {
    let co = statecount?.filter((l) => l.state === id);
    if (co && co[0]?.cnt) {
      return co[0]?.cnt;
    } else {
      return "0";
    }
  };

  return (
    <>
      <div style={{ height: "100%" }}>
        <TabContext value={value}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              background: "transparent",
            }}
          >
            <TabList
              onChange={handleChange}
              // scrollButtons
              aria-label="lab API tabs example"
              variant="scrollable"
              scrollButtons="auto"
              TabIndicatorProps={{
                style: {
                  height: "3px",
                  borderRadius: "5px 5px 0px 0px",
                  background:
                    scenarioState[Number(value) - 2]?.textcolor ??
                    theme.palette.info.main,
                },
              }}
              color="info"
            >
              {/* <Tab
                value={"0"}
                icon={
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <StyledButton2>
                      <StarIcon fontSize="small" color="warning" />
                      <Typography
                        variant="subtitle2"
                        style={{ marginLeft: "8px" }}
                      >
                        {"Favourite"}
                      </Typography>
                      <StyledLabel color={theme.palette.action.hover}>
                        {`${favlist.length}`}
                      </StyledLabel>
                    </StyledButton2>
                  </div>
                }
                style={{ minHeight: "54px" }}
              /> */}

              <Tab
                value={"1"}
                label={`All (${allcount})`}
                style={{ minHeight: "54px" }}
              // variant="scrollable"
              // scrollButtons="auto"
              />
              {scenarioState?.map((l, index) => (
                <Tab
                  value={`${index + 2}`}
                  label={`${l.states} (${getCount(l._id)})`}
                  key={index}
                  // variant="scrollable"
                  // scrollButtons="auto"
                  style={{
                    minHeight: "54px",
                    color: l?.textcolor ?? "unset",
                    opacity: "1",
                  }}
                />
              ))}
            </TabList>
          </Box>
          <TabPanel
            style={{
              height: "calc(100% - 64px)",
              overflowY: "auto",
              padding: "0",
            }}
            value={"0"}
          >
            <ListPanel
              PermissionData={props.PermissionData}
              favtab={true}
              each={false}
              queueId={props.queueId}
              scenarioId={props.currantScenario}
              scenarioState={scenarioState}
              getScenarioStateData={getScenarioStateData}
              getScenarioStateCount={getScenarioStateCount}
              updateFavState={updateFavState}
              favlist={favlist}
              setValue={setValue}
              updateScenaorio={props.updateScenaorio}
              setQueue={props.setQueue}
             refresh={props?.refresh}
            />
          </TabPanel>
          <TabPanel
            style={{
              height: "calc(100% - 64px)",
              overflowY: "auto",
              padding: "0",
            }}
            value={"1"}
          >
            <ListPanel
              PermissionData={props.PermissionData}
              favtab={false}
              each={false}
              queueId={props.queueId}
              scenarioId={props.currantScenario}
              scenarioState={scenarioState}
              getScenarioStateData={getScenarioStateData}
              getScenarioStateCount={getScenarioStateCount}
              updateFavState={updateFavState}
              favlist={favlist}
              setValue={setValue}
              updateScenaorio={props.updateScenaorio}
              setQueue={props.setQueue}
              refresh={props?.refresh}
            />
          </TabPanel>
          {scenarioState?.map((l, index) => (
            <TabPanel
              value={`${index + 2}`}
              key={index}
              style={{
                height: "calc(100% - 64px)",
                overflowY: "auto",
                padding: "0",
              }}
            >
              <ListPanel
                PermissionData={props.PermissionData}
                favtab={false}
                each={true}
                queueId={props.queueId}
                scenarioId={props.currantScenario}
                stateid={l._id}
                scenarioState={scenarioState}
                setValue={setValue}
                updateFavState={updateFavState}
                favlist={favlist}
                getScenarioStateData={getScenarioStateData}
                getScenarioStateCount={getScenarioStateCount}
                updateScenaorio={props.updateScenaorio}
                setQueue={props.setQueue}
                refresh={props?.refresh}
                
              />
            </TabPanel>
          ))}
        </TabContext>
      </div>
    </>
  );
};
