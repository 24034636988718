import React, { useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  Divider,
  Grid,
  CircularProgress,
  IconButton,
  makeStyles,
  Slide,
  TablePagination,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  withStyles,
} from "@material-ui/core";
import axios from "axios";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { CardList, Cards, RequiredField } from "../../components";
import { useDispatch } from "react-redux";
import {
  AlertProps,
  getCurrentDate,
  getDateTime,
  ToastMessage,
} from "../../utils";
import { actions } from "central_pharma_binder";
import { AlertContext, BackdropContext } from "../../contexts";
import { Autocomplete } from "@material-ui/lab";
import TablePaginationActions from "@material-ui/core/TablePagination/TablePaginationActions";
import UpdateIcon from "@material-ui/icons/Update";
import jwt_decode from "jwt-decode";

import { FeedBack } from "./feedback";
import Config from "../../config";
import { AxiosCall } from "../../api";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { async } from "taskviewersurveillance";
import { Routes } from "../../router/routes";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles((theme) => ({
  cartBody: {
    height: "100%",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 16px",
    background: theme.palette.background.table,
    height: "54px",
    borderBottom: " 1px solid #e0e0e0",
  },
  header2: {
    height: "54px",
    padding: "0 16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  header3: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 16px",
    background: theme.palette.background.table,
    height: "54px",
    borderBottom: " 1px solid #e0e0e0",
    [theme.breakpoints.down("sm")]: {
      height: "fit-content",
    },
  },
  subHeading: {
    height: "48px",
    padding: "0 16px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    //position:"fixed"
  },
  subHeadingXs: {
    height: "48px",
    padding: "0 16px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      height: "fit-content",
    },
  },
  flexBetween: {
    padding: "16px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  plusbutton: {
    paddingLeft: "287px",
    display: "flex",
    // justifyContent: "space-between",
  },
  content: {
    height: "calc(100% - 108px)",
    background: theme.palette.background.gray,
  },
  sidepanel: {
    padding: theme.spacing(2),

    borderRadius: "8px",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
  mainpanel: {
    padding: theme.spacing(2),
    height: "100%",
    borderRadius: "8px",
    overflowY: "auto",

    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
  mainBody1: {
    padding: "8px",
    borderRadius: "8px",
    overflowY: "auto",
    height: "calc(100% - 48px)",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100% - 120px)",
    },
  },
  mainBody2: {
    padding: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(100% - 48px)",
    borderRadius: "8px",
  },
  cardParent: {
    background: "#fff",
    borderRadius: "8px",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
  disclaimerBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 16px",
    borderRadius: "8px",
    margin: "8px 16px",

    background: `${theme.palette.success.light}96`,
    flex: "1 1 auto",
    color: theme.palette.success.main,
  },
  creditBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 16px",
    borderRadius: "8px",
    margin: "8px 16px",

    // background: `${theme.palette.success.light}96`,
    background: "#B38BEA",
    flex: "1 1 auto",
    color: "black",
  },
  pendingBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 16px",
    borderRadius: "8px",
    margin: "8px 16px",

    // background: `${theme.palette.success.light}96`,
    background: "#EB7B65",
    flex: "1 1 auto",
    color: "black",
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 16px",
    background: theme.palette.background.table,
    height: "54px",
    borderBottom: " 1px solid #e0e0e0",
    "@media (max-width:400px)": {
      width: "100%",
      flexDirection: "column",
      // alignItems: "flex-start",
      height: "fit-content",
      borderBottom: "none",
    },
  },
  TablePagination: {
    "& .MuiTablePagination-menuItem": {
      textAlign: "start",
      paddingRight: "8px",
    },
  },
  textResponsive: {
    // fontSize: "0.9rem !important",
    maxWidth: "130px",
    marginRight: "16px !important",

    [theme.breakpoints.down("md")]: {
      fontSize: "0.8rem !important",
      marginRight: "16px !important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem !important",
      marginRight: "16px !important",
      maxWidth: "100px",
    },
  },
  discount: {
    color: "#f44336",

    maxWidth: "130px",
    marginRight: "16px !important",

    [theme.breakpoints.down("md")]: {
      fontSize: "0.8rem !important",
      marginRight: "16px !important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem !important",
      marginRight: "16px !important",
      maxWidth: "100px",
    },
  },
  maincontent: {
    height: "calc(100vh - 200px)",
    overflow: "auto",
  },
  textResponsive1: {
    // fontSize: "1.1rem !important",
    fontSize: "20 px",
    // color: theme.palette.primary.main,

    [theme.breakpoints.up("md")]: {
      fontSize: "1rem !important",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "0.8rem !important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.7rem !important",
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.action.hover,
    // color: theme.palette.common.white,
    opacity: "0.7",
    // background: "transparent",
    boxShadow: "none",
    fontSize: "0.75rem",
    fontWeight: "700",
    textTransform: "uppercase",
  },
  // body: {
  //   fontSize: "0.75rem",
  // },
}))(TableCell);
export const Cart = (props) => {
  const { drugList, user, BedNumber, BedCode, backToSearch, PatientDetails, ReorderData } =
    props;
  const classes = useStyles();
  const history = useHistory();
  const [state, setState] = React.useState({});
  const [total, setTotal] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [cartList, setCartlist] = React.useState([]);
  const [cartDrugList, setCartDrugList] = React.useState();
  const [pharmacy, setPharmacy] = React.useState();
  const [creditDetails, setCreditDetails] = React.useState({});
  const backDrop = React.useContext(BackdropContext);
  const dispacth = useDispatch();
  const alertMessage = React.useContext(AlertContext);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [open, setOpen] = React.useState(false);
  const [saleChannel, setSaleChannel] = React.useState([]);
  const [saleChannelValue, setsaleChannelValue] = React.useState("");
  const [neworder, setNeworder] = React.useState([]);
  const theme = useTheme();
  const forTab = useMediaQuery(theme.breakpoints.up("md"));
  const forTabdown = useMediaQuery(theme.breakpoints.down("md"));
  const forPhone = useMediaQuery(theme.breakpoints.down("sm"));
  const [totalDelivery, setTotalDelivery] = React.useState([]);
  const [deliverydata, setDeliverydata] = React.useState([]);
  const [totalPriceAll, setTotalpriceAll] = React.useState([]);
  const [binderState, setBinderState] = React.useState([]);

  useEffect(() => {
    if (BedNumber != undefined) {
      getSaleChannel();
    }
    deliveryapi();
    getCreditLimit();
    if(ReorderData?.data != undefined){
      onReorderClicked(ReorderData.list,ReorderData.data);
    }
    else{
      getAllCartItem();
    }
  }, [BedCode]);


  const updateAddToCartStatus = async () => {
    if(pharmacy?.pharmacyid){
      let cartList =[];
      cartDrugList.map((drug)=> {
        cartList.push(drug._id);
      });
      let params = {
        db_name: process.env.REACT_APP_DB,
        filter: {
          cartid: cartList
        },
        queryid: "20131351-7f50-4ade-9d85-4e4bc7fa2e55"
      };
      let res = await AxiosCall(
        "post",
        `${process.env.REACT_APP_ARANGO_URL_READ_QDMQUERIES}`,
        params
      );
      return res;
    }
    return [];
  };
  
  const getAllCartItem = async () => {
    let token = localStorage.getItem("aTclk");
    let Email = jwt_decode(token);
    var config = {
      db_name: process.env.REACT_APP_DB,
      filter: {
        email: `${Email?.email}`,
      },
      queryid: process.env.REACT_APP_BED_QUERYID,
    };
    let data = await AxiosCall(
      "post",
      `${process.env.REACT_APP_ARANGO_URL_READ_QDMQUERIES}`,
      config
    );
    let activeRes = data.find(x=> x.activestatus === true);
    setPharmacy({
      pharmacyid: activeRes?._id,
      personid: activeRes?.Person[0]?._id
    })
    getAllCartDetails();
  };
  const getAllCartDetails = async () => {
    if(pharmacy?.personid){
      let params = {
        db_name: process.env.REACT_APP_DB,
        filter: pharmacy,
        queryid: process.env.REACT_APP_GETCARTLIST
      };
      
      let res = await AxiosCall(
        "post",
        `${process.env.REACT_APP_ARANGO_URL_READ_QDMQUERIES}`,
        params
      );
      let cList = [];
      for (let i = 0; i < res.length; i++) {
        const item = res[i];
        let exlprice = await AxiosCall(
          "post",
          process.env.REACT_APP_EXCEL_PRICE_API,
          {
            DrugCode: item.drugid.serialno,
            PharmacyID: pharmacy.pharmacyid
          }
        );

        let calcPrice = item.drugid?.calculatedprice;
        if(exlprice != ""){
          if(item.drugid?.discount === 0){
            calcPrice = exlprice; 
          }
          else{
            calcPrice = (exlprice - (exlprice * item.drugid?.discount/100)).toFixed(2)
          }
        }
        cList.push({
          _id : item.drugid._id,
          _key: item.drugid._key,
          calculatedprice: calcPrice,
          discount: item.drugid.discount,
          drugcategory: item.drugid.drugcategory,
          drugclass: item.drugid.drugclass,
          drugid: item.drugid._id,
          effectivefrom: item.drugid.effectivefrom,
          effectiveto: item.drugid.effectiveto,
          is_active: item.drugid.is_active,
          itemname: item.drugid.itemname,
          moq: item.drugid.moq,
          price: exlprice ?? item.drugid.price,
          serialno: item.drugid.serialno,
          status: item.drugid.status,
          stock: item.drugid.stock,
          tax: item.drugid.tax,
          uom: item.drugid.uom,
          version: item.drugid.version,
          drugimage: item.drugid.drugimage,
          quantity: item.drugid.quantity,
          totalprice: (exlprice == "" ? item.drugid.totalprice : (calcPrice*item.drugid.quantity)) + ''
        });
      }
      // let cList = res.map(async (item)=>
      // {

      //   }
      // );
      setCartlist(cList);
      setCartDrugList(res);
      props.setCartCount(cList.length);
    }
  };

  const getCreditLimit = async () => {
    let creditPayload = {
      // date: Date.now(),
      //date: Math.floor(new Date() / 1000),
      Bedcode: BedCode,
      queueId: "queue/10025",
    };

    // console.log("Statess -", state);

    let creditData = await AxiosCall(
      "post",
      process.env.REACT_APP_CREDIT_LIMIT_CHECK_API,
      // "http://localhost:3009/creditlimit",
      creditPayload
    );

    if (
      creditData.creditLimit === NaN ||
      creditData.creditLimit === undefined ||
      creditData.creditLimit === null
    ) {
      creditData.creditLimit = 0;
    }
    if (
      creditData.OldOutstanding === NaN ||
      creditData.OldOutstanding === undefined ||
      creditData.OldOutstanding === null
    ) {
      creditData.OldOutstanding = 0;
    }
    if (
      creditData.difference === NaN ||
      creditData.difference === undefined ||
      creditData.difference === null
    ) {
      creditData.difference = 0;
    }
    if (
      creditData.orderInProcess === NaN ||
      creditData.orderInProcess === undefined ||
      creditData.orderInProcess === null
    ) {
      creditData.orderInProcess = 0;
    }
    if (
      creditData.pendingPaymentAmount === NaN ||
      creditData.pendingPaymentAmount === undefined ||
      creditData.pendingPaymentAmount === null
    ) {
      creditData.pendingPaymentAmount = 0;
    }
    setCreditDetails({ ...creditData });
    // let params = {
    //   db_name: Config.dbname,
    //   entity: "Bed",
    //   filter: `Bed._id=='${BedCode}' && Bed.activestatus==true `,
    //   //sort: "CodeableConceptMaster.text",
    //   return_fields: "Bed",
    // };

    // let res = await AxiosCall(
    //   "post",
    //   `${Config.api_url}/api/read_documents`,
    //   params
    // );

    // let tic = res?.result[0]?.ticketId;

    // console.log("CreditLimit", tic);

    // let params2 = {
    //   db_name: Config.dbname,
    //   entity: "form_entries",
    //   filter: `form_entries.ticketId=='${tic}' && form_entries.activestatus==true `,
    //   //sort: "CodeableConceptMaster.text",
    //   return_fields: "form_entries",
    // };

    // let res2 = await AxiosCall(
    //   "post",
    //   `${Config.api_url}/api/read_documents`,
    //   params2
    // );

    // let creditAns = res2?.result[0]?.answers;

    // console.log("CreditForm", res2?.result[0]?.answers);

    // let quesName = "Approved Loan Amount";

    // let credit = "";

    // let asname = creditAns.map((l) => {
    //   if (l.answer?.questions === quesName) {
    //     credit = l?.answer?.answer;
    //   }
    //   // console.log(l.answer, "TrueDa");
    // });

    // console.log("as", credit);

    //setCredit(creditData.creditLimit);
  };

  if (props.BedNumber === undefined) {
    var deliveryapi = async () => {
      let param1 = {
        db_name: Config.dbname,
        entity: "QDMATPtransactionlog",
        filter: `QDMATPtransactionlog.ticketId==document('${props.BedCode}').ticketId && QDMATPtransactionlog.activestatus==true`,
        return_fields:
          "{Region:QDMATPtransactionlog.payload.inputDoc.mappedData.Region,State:QDMATPtransactionlog.payload.inputDoc.mappedData.State, ReportingOrganization:QDMATPtransactionlog.payload.inputDoc.mappedData.ReportingOrganization}",
      };
      let res1 = await AxiosCall("post", process.env.REACT_APP_API_URL, param1);

      setBinderState(res1.result[0]);

      // console.log("res1", res1.result[0]?.Region?.value, res1.result[0]?.State?.value)
      let params = {
        db_name: Config.dbname,
        entity: "CodingMaster",
        filter: `CodingMaster.Type=='DELIVERYCHARGE' && CodingMaster.gmconfigvalues.State=='${res1.result[0]?.State?.value}' && CodingMaster.gmconfigvalues.Region=='${res1.result[0]?.Region?.value}' && CodingMaster.activestatus==true && CodingMaster.status==true`,
        return_fields:
          "merge(CodingMaster.gmconfigvalues,{_id:CodingMaster._id})",
      };
      let res = await AxiosCall("post", process.env.REACT_APP_API_URL, params);
      // console.log("deliveryapibed", res.result);
      setDeliverydata([...res.result]);
    };
  } else {
    var deliveryapi = async () => {
      let params = {
        db_name: Config.dbname,
        entity: "CodingMaster",
        filter: `CodingMaster.Type=='DELIVERYCHARGE' && CodingMaster.gmconfigvalues.State=='${props?.PatientDetails?.State?.value}' && CodingMaster.gmconfigvalues.Region=='${props?.PatientDetails?.Region?.value}' && CodingMaster.activestatus==true && CodingMaster.status==true`,
        return_fields:
          "merge(CodingMaster.gmconfigvalues,{_id:CodingMaster._id})",
      };
      let res = await AxiosCall("post", process.env.REACT_APP_API_URL, params);
      // console.log("deliveryapi", res.result);
      setDeliverydata([...res.result]);
    };
  }

  function removeLettersFromString(str) {
    if (str.includes("to")) {
      const stringArray = str.split("to");
      const numbersOnly = str.replace(/\D/g, "");
      let firstnum = parseFloat(stringArray[0]);
      let secontnum = parseFloat(stringArray[1]);
      // const inputString = 'Hello, World!';
      // const stringArray = inputString.split(' ');
      // console.log("numbersOnly", stringArray);
      return [firstnum, secontnum];
    } else {
      const stringArray = str.replace("Above", "");
      let singlevalue = parseFloat(stringArray);
      // const numbersOnly = str.replace(/\D/g, '');
      // let firstnum = parseFloat(stringArray[0])
      // let secontnum = parseFloat(stringArray[1])
      // const inputString = 'Hello, World!';
      // const stringArray = inputString.split(' ');
      // console.log("numbersOnly", stringArray);
      return [singlevalue];
    }
  }

  const getSaleChannel = async () => {
    let params = {
      db_name: Config.dbname,
      entity: "CodeableConceptMaster",
      filter:
        "CodeableConceptMaster.Type=='SALE CHANNEL' && CodeableConceptMaster.activestatus==true ",
      //sort: "CodeableConceptMaster.text",
      return_fields:
        "MERGE(FIRST(FOR cod IN CodeableConceptMaster.coding RETURN DOCUMENT(cod)))",
    };
    let res = await AxiosCall(
      "post",
      `${Config.api_url}/api/read_documents`,
      params
    );

    let result = res?.result;
    let Arr = [];

    result.map((datsss) => {
      const obj = {
        label: datsss.display,
        value: datsss._id,
      };
      Arr.push(obj);
    });
    setSaleChannel(Arr);
  };
  const onHandleChange = (value) => {
    // //
    setsaleChannelValue(value?.label);
    setNeworder(value?.label);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, cartList.length - page * rowsPerPage);
  const handleOpenDialog = () => {
    setOpen(true);
  };
  const handleOrder = () =>
  // history.push(Routes.myorders);
  history.push({
    pathname: Routes.myorders,
    state: { BedCode: BedCode,
      iscatalog: BedNumber? true: false
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  const onOrderPlacingClicked = async () => {
    setLoading(true);
    const grandtotal = totalcalfunc();
    let Totals = grandtotal;
    //  ;
    // let instore = process.env.REACT_APP_INSTOR_ORDER
    // console.log(instore, "instore", process.env.REACT_APP_INSTOR_ORDER === saleChannelValue, "", saleChannelValue)
    let quanity = Number(cartList.quantity);
    if (
      BedNumber != undefined &&
      saleChannelValue?.length >= 3 &&
      saleChannelValue !== process.env.REACT_APP_INSTOR_ORDER
    ) {
      backDrop.setBackDrop({
        ...backDrop,
        open: true,
        message: "Placing your order !",
      });

      let quanity = parseFloat(Number(cartList.quantity));
      let cp = parseFloat(Number(cartList.calculatedprice));
      let price = Number(cartList.price);
      let total = parseFloat(
        Number(cartList?.quantity ? cartList?.quantity : 1) *
          Number(
            cartList?.calculatedprice
              ? cartList?.calculatedprice
              : cartList?.price
          )
      ).toFixed(2);
      let result1 = parseFloat(cp) * parseFloat(quanity);
      cartList["totalprice"] = total;

      if (![undefined, NaN, null, ""].includes(total)) {
        let Params = {
          PatientCode: user?.PatientCode,
          PatientBedNumber: BedCode,
          OrderItemCode: cartList,
          OrderTimeCode: getDateTime(),
          OrderDate: getCurrentDate(new Date()),
          OrderStatus: process.env.REACT_APP_CODING_MASTER_ORDERSTATUS_ID,
          OrderType: process.env.REACT_APP_QUEUE_ORDERTYPE,
          saleChannel: saleChannelValue,
          reportingOrg: PatientDetails?.ReportingOrganization,
        };

        // console.log(Params, "params");
        // let res = await dispacth(actions.MEAL_ORDER_INSERT({ _data: Params }));
        let res = await dispacth(
          actions.MEAL_ORDER_INSERT({
            _data: Params,
            PatientDetails: PatientDetails,
            props: props,
            binderState: binderState,
            totalPriceAll: getTotalPrice(),
            totalDelivery: deliveryCharge(),
          })
        );
        // console.log(res, "resplaceorder");

        if (res?.payload?.data?.error) {
          ToastMessage(`Order not placed, Try again`, "error", "top-center");
          backDrop.setBackDrop({
            ...backDrop,
            open: false,
          });
          setLoading(false);
        } else {
          let Totals = 0;
          cartList.map((li) => {
            let value =
              Number(li?.quantity ? li?.quantity : 1) *
              Number(li?.calculatedprice ? li?.calculatedprice : li?.price);
            Totals = Totals + value;
          });
          let newDif = creditDetails.difference - Totals;
          // console.log("newDif", newDif, Totals, creditDetails.difference);
          setCreditDetails({
            ...creditDetails,
            difference: newDif,
          });
          ToastMessage(`Order placed successfully`, "success", "top-center");
          setsaleChannelValue("");
          setNeworder("");
          setCartlist([]);
          props.setCartCount(0);
          updateAddToCartStatus();
          backDrop.setBackDrop({
            ...backDrop,
            open: false,
          });
          setLoading(false);
        }
      }
    } else if (BedNumber == undefined) {
      backDrop.setBackDrop({
        ...backDrop,
        open: true,
        message: "Placing your order !",
      });

      let quanity = parseFloat(Number(cartList.quantity));
      let cp = parseFloat(Number(cartList.calculatedprice));
      let price = Number(cartList.price);
      let total = parseFloat(
        Number(cartList?.quantity ? cartList?.quantity : 1) *
          Number(
            cartList?.calculatedprice
              ? cartList?.calculatedprice
              : cartList?.price
          )
      ).toFixed(2);

      let result1 = parseFloat(cp) * parseFloat(quanity);
      cartList["totalprice"] = total;
      if (![undefined, NaN, null, ""].includes(total)) {
        let Params = {
          PatientCode: user?.PatientCode,
          PatientBedNumber: user?.PatientBedCode,
          OrderItemCode: cartList,
          OrderTimeCode: getDateTime(),
          OrderDate: getCurrentDate(new Date()),
          OrderStatus: process.env.REACT_APP_CODING_MASTER_ORDERSTATUS_ID,
          OrderType: process.env.REACT_APP_QUEUE_ORDERTYPE,
          saleChannel: process.env.REACT_APP_INSTOR_ORDER,
          reportingOrg: user?.ReportingOrganization,
        };
        // console.log(Params, "params");
        // let res = await dispacth(actions.MEAL_ORDER_INSERT({ _data: Params }));
        let res = await dispacth(
          actions.MEAL_ORDER_INSERT({
            _data: Params,
            PatientDetails: PatientDetails,
            props: props,
            binderState: binderState,
            totalPriceAll: getTotalPrice(),
            totalDelivery: deliveryCharge(),
          })
        );
        if (res?.payload?.data?.error) {
          ToastMessage(`Order not placed, Try again`, "error", "top-center");
          backDrop.setBackDrop({
            ...backDrop,
            open: false,
          });
          setLoading(false);
        } else {
          let Totals = 0;
          cartList.map((li) => {
            let value =
              Number(li?.quantity ? li?.quantity : 1) *
              Number(li?.calculatedprice ? li?.calculatedprice : li?.price);
            Totals = Totals + value;
          });
          let newDif = creditDetails.difference - Totals;
          // console.log("newDif", newDif, Totals, creditDetails.difference);
          setCreditDetails({
            ...creditDetails,
            difference: newDif,
          });
          ToastMessage(`Order placed successfully`, "success", "top-center");
          setsaleChannelValue("");
          setNeworder("");
          setCartlist([]);
          props.setCartCount(0);
          updateAddToCartStatus();
          backDrop.setBackDrop({
            ...backDrop,
            open: false,
          });
          setLoading(false);
        }
      }
    } else {
      ToastMessage(`Sales Channel Is Required`, "error", "top-center");
      setLoading(false);
    }
  };
  //console.log(cartList, "outsideconsole")

  const onReorderClicked = async(data, list) => {
    // ;
    //console.log(data, list, "datalist")
    if(pharmacy?.personid){
      let config = {
        db_name: process.env.REACT_APP_DB,
        filter: pharmacy,
        queryid: process.env.REACT_APP_GETCARTLIST
      };
      let cartData = await AxiosCall(
        "post",
        `${process.env.REACT_APP_ARANGO_URL_READ_QDMQUERIES}`,
        config
      );
      let addtocartData = [];
      console.log(data,cartData,"cartData");
      data.forEach(d => {
        let existCart = cartData.filter(c => c.drugid._id == d._id)
        if(existCart.length === 0 ){
          
          addtocartData.push({
            pharmacyid: pharmacy.pharmacyid,
            personid: pharmacy.personid,
            drugid: d._id,
            quantity: Number(d.quantity ?? (d.moq ?? 1)),
            cartstatus: "CodingMaster/11634"
          });
        }
      });
      if(addtocartData.length>0){
        let input = [{
          db_name: process.env.REACT_APP_DB,
          entity: "addtocart",
          is_metadata: true,
          metadataId: process.env.REACT_APP_METADATAID,
          metadata_dbname: process.env.REACT_APP_REPORT_METADATA_DB_NAME,
          doc: addtocartData
        }];

        let res = await AxiosCall(
          "post",
          `${process.env.REACT_APP_ARANGO_URL_UPSERT}`,
          input
        );
      }
      getAllCartDetails();
    }
    // else{
    //   setCartlist([...cartList, ...data]);
    // }

    if (cartList?.length == 0) {
      // setCartlist([...cartList, ...data]);
      setNeworder(
        saleChannel.find((l) => l?.label === list[0]?.drugdetails?.SaleChannel)
      );
      setsaleChannelValue(list[0]?.drugdetails?.SaleChannel);
    } else if (cartList?.length > 0) {
      let Arr = [];
      data.map((li) => {
        if (!cartList.some((l) => l?.serialno == li?.serialno)) {
          Arr.push(li);
        }
      });
      setNeworder(
        saleChannel.find((l) => l?.label === list[0]?.drugdetails?.SaleChannel)
      );
      // setCartlist([...cartList, ...Arr]);
      setsaleChannelValue(list[0]?.drugdetails?.SaleChannel);
    }
    // console.log(list, "saleChannel");
  };

  const removeFromCart = async(data) => {
    // let updatedList = cartList.filter((l) => l?.serialno != data?.serialno);
    // setCartlist(updatedList);

    setLoading(true);
    let drug = cartDrugList?.find((d) => d?.drugid?.serialno == data?.serialno);
    if(drug?._key){
      let config = {
        db_name: process.env.REACT_APP_DB,
        entity: "addtocart",
        filter: `addtocart._key=='${drug._key}'`
      }
      let res = await AxiosCall(
        "post",
        `${process.env.REACT_APP_ARANGO_URL_SOFTDELETE}`,
        config
      );
      if(res.Code == 201){
        let updatedDrugList = cartDrugList.filter((d) => d?.drugid?.serialno != data?.serialno);
        let updatedList = cartList.filter((l) => l?.serialno != data?.serialno);
        setCartlist(updatedList);
        setCartDrugList(updatedDrugList);
        props.setCartCount(updatedDrugList.length);
      }
      else{
        let updatedList = cartList.filter((l) => l?.serialno != data?.serialno);
        setCartlist(updatedList);
        alertMessage.setSnack({
          ...alertMessage,
          open: true,
          severity: AlertProps.severity.error,
          msg: res[0]?.error,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      }
    }
    else{
      let updatedList = cartList.filter((l) => l?.serialno != data?.serialno);
      setCartlist(updatedList);
    }
    setLoading(false);
  };

  const addMinusQuantity = async(isadd, val, l) => {
    // console.log("l", l);
    let n = +val;
    let qty = isadd ? ++n : --n;
    let minimum = l?.moq ? parseInt(l?.moq) : 1;

    if (qty < 1000) {
      if (minimum <= qty) {
        setLoading(true);
        let obj = { ...l, quantity: qty.toString() };

        let hasAlready = cartList?.filter((d) => d?.serialno == l?.serialno);

        let updatedList =
          hasAlready.length === 0
            ? [...cartList, obj]
            : cartList.map((item) =>
                item.serialno == l?.serialno ? obj : item
              );

              
        if(pharmacy?.personid){
          let drug = cartDrugList?.filter((d) => d?.drugid.serialno == l?.serialno);
          if(drug.length>0){
            drug[0].drugid.quanity = qty.toString()
          }
          let updatedCartDrugList =
            drug.length === 0
              ? cartDrugList
              : cartDrugList.map((item) =>
                  item.serialno == l?.serialno ? drug : item
                );
          let input = {
            db_name: process.env.REACT_APP_DB,
            filter: {
              cartid: [
                drug[0]._id
              ],
              quantity: qty
            },
            queryid: process.env.REACT_APP_UPDATE_CART_QTY
          }
          let cartData = await AxiosCall(
            "post",
            `${process.env.REACT_APP_ARANGO_URL_READ_QDMQUERIES}`,
            input
          );
          setCartDrugList(updatedCartDrugList);
        }

        setCartlist(updatedList);
        setLoading(false);
      } else {
        alertMessage.setSnack({
          ...alertMessage,
          open: true,
          severity: AlertProps.severity.error,
          msg: `Minimum ${minimum} quantity is Required`,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
        return;
      }
    } else {
      alertMessage.setSnack({
        ...alertMessage,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Maximum Quantity Should Below 1000 Limit Exceed! ",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
      return;
    }
  };

  //   const onHandleChange = (e) => {
  //     setSearch(e.target.value);
  //   };

  const onHandleChnageQuantity = async(e, l) => {
    let regex = /^[0-9]*$/;
    let val = e.target.value;
    let Minimum = l?.moq ? parseInt(l?.moq) : 1;
    if (regex.test(val)) {
      if (val < 1000) {
        if (Minimum <= val) {
          setLoading(true);
          let obj = { ...l, quantity: e.target.value };

          let hasAlready = cartList?.filter((d) => d?.serialno == l?.serialno);
          let updatedList =
            hasAlready.length === 0
              ? [...cartList, obj]
              : cartList.map((item) =>
                  item.serialno == l?.serialno ? obj : item
                );
                  
          
          if(pharmacy?.personid){
            let drug = cartDrugList?.filter((d) => d?.drugid.serialno == l?.serialno);
            if(drug.length>0){
              drug[0].drugid.quanity = e.target.value
            }
            let updatedCartDrugList =
              drug.length === 0
                ? cartDrugList
                : cartDrugList.map((item) =>
                    item.serialno == l?.serialno ? drug : item
                  );
            let input = {
              db_name: process.env.REACT_APP_DB,
              filter: {
                cartid: [
                  drug[0]._id
                ],
                quantity: Number(e.target.value)
              },
              queryid: process.env.REACT_APP_UPDATE_CART_QTY
            }
            let cartData = await AxiosCall(
              "post",
              `${process.env.REACT_APP_ARANGO_URL_READ_QDMQUERIES}`,
              input
            );
            setCartDrugList(updatedCartDrugList);
          }
          setCartlist(updatedList);
          setLoading(false);
        } else {
          alertMessage.setSnack({
            ...alertMessage,
            open: true,
            severity: AlertProps.severity.error,
            msg: `Minimum ${Minimum} quantity is Required`,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
          return;
        }
      } else {
        alertMessage.setSnack({
          ...alertMessage,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Maximum Quantity Should Below 1000 Limit Exceed! ",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
        return;
      }
    }
  };

  const handleChange = (key, value) => {
    //console.log("change", value)
    if (key === "drug") {
      if (value) {
        let obj = { ...value };
        if (obj?.moq) {
          obj["quantity"] = parseInt(obj?.moq);
          setState({ ...state, ...obj });
          return;
        } else {
          obj["quantity"] = 1;
          setState({ ...state, ...obj });
          return;
        }
      }
    } else {
      let regex = /^[0-9]*$/;
      if (value != "0") {
        if (regex.test(value)) setState({ ...state, quantity: value });
        return;
      }
    }
  };

  const onAddBtnClicked = async () => {
    setLoading(true);
    //console.log(state, "onaddstate")
    let data = state?.quantity ?? 1;
    let Minimum = state?.moq ? parseInt(state?.moq) : 1;
    if (data < 1000) {
      if (Minimum <= data) {
        if(pharmacy?.personid){
          let config = {
            db_name: process.env.REACT_APP_DB,
            filter: pharmacy,
            queryid: process.env.REACT_APP_GETCARTLIST
          };

          
          let cartData = await AxiosCall(
            "post",
            `${process.env.REACT_APP_ARANGO_URL_READ_QDMQUERIES}`,
            config
          );

          if (cartData.some((l) => l?.drugid.serialno == state?.serialno)) {
            alertMessage.setSnack({
              ...alertMessage,
              open: true,
              severity: AlertProps.severity.error,
              msg: "Drug already exist is the cart !",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
            props.setCartCount(cartData.length);
                  
            let cList = cartData.map((item)=>
            {return{
              _id : item.drugid._id,
              _key: item.drugid._key,
              calculatedprice: item.drugid.calculatedprice,
              discount: item.drugid.discount,
              drugcategory: item.drugid.drugcategory,
              drugclass: item.drugid.drugclass,
              drugid: item.drugid._id,
              effectivefrom: item.drugid.effectivefrom,
              effectiveto: item.drugid.effectiveto,
              is_active: item.drugid.is_active,
              itemname: item.drugid.itemname,
              moq: item.drugid.moq,
              price: item.drugid.price,
              serialno: item.drugid.serialno,
              status: item.drugid.status,
              stock: item.drugid.stock,
              tax: item.drugid.tax,
              uom: item.drugid.uom,
              version: item.drugid.version,
              drugimage: item.drugid.drugimage,
              quantity: item.drugid.quantity,
              totalprice: item.drugid.totalprice + ''
            }}
            );
            setCartlist(cList);
            setCartDrugList(cartData);
            setLoading(false);
            return;
          }
        }
        const getCurrentDate = () => {
          const t = new Date();
          const date = ("0" + t.getDate()).slice(-2);
          const month = ("0" + (t.getMonth() + 1)).slice(-2);
          const year = t.getFullYear();
          return `${year}-${month}-${date}`;
        };

        let discount = {
          // date: Date.now(),
          //date: Math.floor(new Date() / 1000),
          date: getCurrentDate(),
          drugid: state._id,
        };

        // console.log("Statess -", state);

        let dprice = await AxiosCall(
          "post",
          process.env.REACT_APP_FETCHPRICE,
          discount
        );

        let exlprice = await AxiosCall(
          "post",
          process.env.REACT_APP_EXCEL_PRICE_API,
          {
            DrugCode: state.serialno,
            PharmacyID: pharmacy.pharmacyid ?? BedCode
          }
        );
        let calcPrice = dprice[0]?.calculatedprice;
        if(exlprice != ""){
          if(dprice[0]?.discount === 0){
            calcPrice = exlprice; 
          }
          else{
            calcPrice = (exlprice - (exlprice * dprice[0]?.discount/100)).toFixed(2)
          }
        }
        let updatedState = state;
        updatedState["price"] = exlprice == "" ? dprice[0]?.price : exlprice;
        updatedState["discount"] = dprice[0]?.discount;
        updatedState["calculatedprice"] = calcPrice;
        updatedState["totalprice"] = parseFloat(
          Number(dprice[0]?.quantity ? dprice[0]?.quantity : 1) *
            Number(
              calcPrice
                ? calcPrice
                : dprice[0]?.price
            )
        ).toFixed(2);

        if (cartList.some((l) => l?.serialno == state?.serialno) && pharmacy?.pharmacyid == undefined) {
          alertMessage.setSnack({
            ...alertMessage,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Drug already exist is the cart !",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
          setLoading(false);
          return;
        } else if ([undefined, "", null, NaN].includes(updatedState?.price)) {
          alertMessage.setSnack({
            ...alertMessage,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Price Not Avaliable",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
          setLoading(false);
          return;
        }

          let obj = updatedState;
        if (obj?.quantity == undefined) {
          obj["quantity"] = "1";
        }
        if(pharmacy?.personid){
          let input = [{
            db_name: process.env.REACT_APP_DB,
            entity: "addtocart",
            is_metadata: true,
            metadataId: process.env.REACT_APP_METADATAID,
            metadata_dbname: process.env.REACT_APP_REPORT_METADATA_DB_NAME,
            doc: [{
              pharmacyid: pharmacy.pharmacyid,
              personid: pharmacy.personid,
              drugid: obj._id,
              quantity: Number(obj.quantity),
              cartstatus: "CodingMaster/11634"
            }]
          }];

          let res = await AxiosCall(
            "post",
            `${process.env.REACT_APP_ARANGO_URL_UPSERT}`,
            input
          );
          getAllCartDetails();
        }
        else{
          setCartlist([...cartList, obj]);
        }
        setLoading(false);
      } else {
        alertMessage.setSnack({
          ...alertMessage,
          open: true,
          severity: AlertProps.severity.error,
          msg: `Minimum ${Minimum} quantity is Required`,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
        return;
      }
    } else {
      alertMessage.setSnack({
        ...alertMessage,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Maximum Quantity Should Below 1000 Limit Exceed! ",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
      return;
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function totalcalfunc() {
    let Total = 0;
    cartList.map((li) => {
      let value =
        Number(li?.quantity ? li?.quantity : 1) *
        Number(li?.calculatedprice ? li?.calculatedprice : li?.price);
      Total = Total + value;
    });
    return Total;
  }

  function creditLimitMessagefunc() {
    let Total = grandtotal;
    let cr = creditDetails.difference - Total;
    if (cr < 0) {
      return "Credit limit exceeded";
    }
    return "";
  }

  const grandtotal = totalcalfunc();
  const getTotalPrice2 = () => {
    let Total = grandtotal;

    let cr = creditDetails.difference - Total;

    if (deliverydata?.length > 0) {
      for (let i = 0; i <= deliverydata.length - 1; i++) {
        let deliveryData2 = stripWhitespaceFromKeys(deliverydata[i]);
        let lowerAmount = parseFloat(deliveryData2.LowerAmount);
        let higherAmount = parseFloat(deliveryData2.HigherAmount);
        let deliveryCharge = parseFloat(deliveryData2.DeliveryCharge);
        if (Total >= lowerAmount && Total <= higherAmount) {
          if (deliveryData2.ChargeType === "Amount") {
            return `+ MYR ${parseFloat(
              Total ? Total + deliveryCharge : 0
            ).toFixed(2)}`;
          } else if (deliveryData2.ChargeType === "Percentage") {
            const deliveryValue = (Total * deliveryCharge) / 100;
            return `+ MYR ${parseFloat(
              Total ? Total + deliveryValue : 0
            ).toFixed(2)}`;
          }
        }
      }
      return `+ MYR ${parseFloat(Total ? Total : 0).toFixed(2)}`;
    } else {
      return `+ MYR ${parseFloat(Total ? Total : 0).toFixed(2)} `;
    }
    // if (deliverydata?.length > 0) {  // {deliverydata ? : }

    //   for (let i = 0; i <= deliverydata.length - 1; i++) {
    //     let between = deliverydata[i].MOV
    //     let deliveryvalue = parseFloat(deliverydata[i].DeliveryCharge)

    //     let arrBetween = removeLettersFromString(between)

    //     console.log(arrBetween, "arrBetween", deliveryvalue)

    //     if (arrBetween.length === 2) {
    //       if (Total >= arrBetween[0] && Total <= arrBetween[1]) {
    //         return `MYR ${ parseFloat(Total ? Total + deliveryvalue : Total).toFixed(2) } `;
    //       }
    //     } else if (Total >= arrBetween[0]) {
    //       return `MYR ${ parseFloat(Total ? Total + deliveryvalue : Total).toFixed(2) } `;

    //     }
    //   }
    // }
  };

  const getTotalPrice = () => {
    let Total = grandtotal;
    // setTotalpriceAll(Total)
    return `MYR ${parseFloat(Total).toFixed(2)} `;
  };
  let totot = getTotalPrice();
  // console.log(totot, "totot")
  // setTotalpriceAll(totot)
  // console.log(totalPriceAll, "todaytask");

  function stripWhitespaceFromKeys(obj) {
    const newObj = {};

    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        const newKey = key.replace(/\s/g, ""); // Remove whitespace using regular expression
        newObj[newKey] = obj[key];
      }
    }

    return newObj;
  }

  const deliveryCharge = () => {
    let Total = grandtotal;

    // console.log(deliveryData2, "deliveryData2")

    for (let i = 0; i <= deliverydata.length - 1; i++) {
      let deliveryData2 = stripWhitespaceFromKeys(deliverydata[i]);
      let lowerAmount = parseFloat(deliveryData2.LowerAmount);
      let higherAmount = parseFloat(deliveryData2.HigherAmount);
      let deliveryCharge = parseFloat(deliveryData2.DeliveryCharge);
      if (Total >= lowerAmount && Total <= higherAmount) {
        if (deliveryData2.ChargeType === "Amount") {
          // setTotalDelivery(deliveryCharge)
          return `+ MYR ${parseFloat(Total ? deliveryCharge : 0).toFixed(2)} `;
        } else if (deliveryData2.ChargeType === "Percentage") {
          // setTotalDelivery(deliveryCharge)
          const deliveryValue = (Total * deliveryCharge) / 100;
          return `+ MYR ${parseFloat(Total ? deliveryValue : 0).toFixed(2)} `;
        }
      }
    }
    // setTotalDelivery(0)
    return `+ MYR ${parseFloat(0).toFixed(2)} `;

    // for (let i = 0; i <= deliverydata.length - 1; i++) {
    //   let between = deliverydata[i].MOV
    //   let deliveryvalue = parseFloat(deliverydata[i].DeliveryCharge)

    //   let arrBetween = removeLettersFromString(between)

    //   if (arrBetween.length === 2) {
    //     if (Total >= arrBetween[0] && Total <= arrBetween[1]) {
    //       return `+ MYR ${ parseFloat(Total ? deliveryvalue : Total).toFixed(2) } `;
    //     }
    //   } else if (Total >= arrBetween[0]) {
    //     return `+ MYR ${ parseFloat(Total ? deliveryvalue : Total).toFixed(2) } `;

    //   }
    // }
  };

  // function getCurrencySymbol(locale, currency) {
  //   return (0)
  //     .toLocaleString(locale, {
  //       style: "currency",
  //       currency: currency,
  //       minimumFractionDigits: 0,
  //       maximumFractionDigits: 0,
  //     })
  //     .replace(/\d/g, "")
  //     .trim();
  // }
  // console.log("propscard", state);
  return (
    <Box className={classes.cartBody}>
      <Box className={classes.header}>
        <Typography>
          {BedNumber && (
            <Tooltip title="Back" placement="top">
              <IconButton>
                <ArrowBackIcon
                  onClick={() => backToSearch()}
                  color="primary"
                  fontSize="small"
                />
              </IconButton>
            </Tooltip>
          )}{" "}
          {"Drug Ordering"}
        </Typography>
        <Box>
          {/* <IconButton>
                <WarningIcon color="warning" />
              </IconButton> */}
          {BedNumber && (
            <Typography
              //color="primary"
              variant="body2"
              className={classes?.textResponsive1}
            >
              {"Pharmacy : "} {BedNumber}
            </Typography>
          )}
          {user?.Pharmacy && (
            <Typography
              //color="primary"
              variant="body2"
              className={classes?.textResponsive1}
            >
              {"Pharmacy : "} {user?.Pharmacy}
            </Typography>
          )}
        </Box>
        <Box>
          {/* <IconButton>
                <WarningIcon color="warning" />
              </IconButton> */}
          {PatientDetails?.ReportingOrganization?.label && (
            <Typography
              //color="primary"
              variant="body2"
              className={classes?.textResponsive1}
            >
              {"Order to : "} {PatientDetails?.ReportingOrganization?.label}
            </Typography>
          )}
          {user?.ReportingOrganization?.label && (
            <Typography
              //color="primary"
              variant="body2"
              className={classes?.textResponsive1}
            >
              {"Order to : "} {user?.ReportingOrganization?.label}
            </Typography>
          )}
        </Box>

        {forPhone ? (
          <Tooltip title="Order History" arrow>
            <IconButton color="primary" onClick={() => handleOpenDialog()}>
              <UpdateIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <div style={{ flex: "0 0 auto" }}>
            <Button
              variant="text"
              color="primary"
              startIcon={<UpdateIcon />}
              onClick={() => handleOrder()}
            >
              {"Order Histroy"}
            </Button>
          </div>
        )}
      </Box>
      {/* {BedNumber != undefined &&
      <Box className={classes.header}>
          <Grid container spacing={2} alignItems="center">
          <Grid
            container
            spacing={1}
            alignItems="center"
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
          >
          <Grid item xs={2} sm={2} md={3} lg={3}>
                <Typography variant="body2" gutterBottom>
                  {"Sale Channel"}
                  <RequiredField color={"red"} />
                </Typography>
              </Grid>
            <Grid item xs={12} sm={4} md={6} lg={6}>
              
              <Autocomplete
                options={saleChannel}
                getOptionLabel={(option) => option.label || ""}
                size={"small"}
                value={saleChannel.find((l) => l?.label === saleChannelValue)}
                onChange={(e, value) => onHandleChange(value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder={"Select Sale Channel"}
                    autoComplete="off"
                    size="small"
                  />
                )}
              />
            </Grid>
          </Grid>
          </Grid>
      </Box>
       } */}

      <Box className={classes.header3}>
        <Grid container spacing={2} alignItems="center">
          {BedNumber && (
            <Grid
              container
              spacing={1}
              alignItems="center"
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
            >
              <Grid item xs={2} sm={2} md={4} lg={4}>
                <Typography variant="body2" gutterBottom>
                  {"Sale Channel"}
                  <RequiredField color={"red"} />
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                sm={!forTab ? 6 : 8}
                md={!forTab ? 6 : 8}
                lg={8}
              >
                <Autocomplete
                  options={saleChannel}
                  getOptionLabel={(option) => option.label || ""}
                  size={"small"}
                  value={
                    saleChannel.find((l) => l?.label === saleChannelValue) ??
                    neworder
                  }
                  onChange={(e, value) => onHandleChange(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder={"Select Sale Channel"}
                      autoComplete="off"
                      size="small"
                    />
                  )}
                />
              </Grid>
            </Grid>
          )}
          <Grid
            container
            spacing={1}
            alignItems="center"
            item
            xs={12}
            sm={12}
            md={5}
            lg={5}
          >
            {forTab && (
              <Grid item xs={2} sm={2} md={3} lg={3}>
                <Typography variant="body2" gutterBottom>
                  {"Medicine Name"}
                  <RequiredField color={"red"} />
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} sm={!forTab ? 6 : 9} md={!forTab ? 6 : 9} lg={9}>
              <Autocomplete
                // id={`${ parent_id } -Entity - Type - autocomplete`}
                options={drugList ?? []}
                getOptionLabel={(option) => option?.itemname || ""}
                getOptionSelected={(option, value) =>
                  option?.serialno === value?.serialno
                }
                size={"small"}
                //   disabled={isTransfer}
                value={state || {}}
                onChange={(e, value) => handleChange("drug", value)}
                renderInput={(params) => (
                  <TextField
                    // id={`${ parent_id } -Entity - Type - textField`}
                    {...params}
                    variant="outlined"
                    // error={props.data?.error?.EntityType}
                    placeholder="Search for medicine"
                    autoComplete="off"
                    size="small"
                  />
                )}
              />
            </Grid>
            {!forTab && (
              <Grid
                item
                xs={9}
                sm={forPhone ? 4 : 9}
                md={forTabdown ? 4 : 6}
                style={{ maxWidth: forPhone ? "100%" : "100px" }}
              >
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  size="small"
                  style={{ maxWidth: forPhone ? "100%" : "100px" }}
                  //   disabled={isTransfer}
                  placeholder={"Quantity"}
                  value={state?.quantity ?? ""}
                  inputProps={{ maxLength: 10 }}
                  onChange={(e) => handleChange("quantity", e.target.value)}
                />
              </Grid>
            )}
            {!forTab && (
              <Grid item sm={forPhone ? 2 : 9} md={forTabdown ? 2 : 6}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!state?.serialno}
                  onClick={() => onAddBtnClicked()}
                >
                  {"+ Add"}
                </Button>
              </Grid>
            )}
            <Grid></Grid>
          </Grid>

          {forTab && (
            <Grid
              container
              alignItems="center"
              spacing={2}
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
            >
              {forTab && (
                <Grid item>
                  <Typography variant="body2" gutterBottom>
                    {"Quantity"}
                    {/* <RequiredField color={"red"} /> */}
                  </Typography>
                </Grid>
              )}

              <Grid item>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  size="small"
                  style={{ maxWidth: "100px" }}
                  //   disabled={isTransfer}
                  placeholder={"Quantity"}
                  value={state?.quantity ?? ""}
                  inputProps={{ maxLength: 10 }}
                  onChange={(e) => handleChange("quantity", e.target.value)}
                />
              </Grid>
              {forTab && (
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={!state?.serialno}
                    onClick={() => onAddBtnClicked()}
                  >
                    {"+ Add"}
                  </Button>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      </Box>

      <Grid className={classes.content} container>
        <Grid item xs={12} sm={12} md={7} lg={8} className={classes.mainpanel}>
          <Box className={classes.cardParent}>
            <Box className={classes.subHeadingXs}>
              <Typography gutterBottom>Order Items</Typography>
              {cartList?.length > 0 && (
                <TablePagination
                  className={classes.TablePagination}
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  colSpan={3}
                  count={cartList.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                  }}
                  style={{ border: "none" }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              )}
            </Box>
            <Divider />
            {cartList?.length > 0 ? (
              <Grid className={classes.mainBody1}>
                {(rowsPerPage > 0
                  ? cartList.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : cartList
                )?.map((l, index) => (
                  <Cards
                    title={l?.itemname}
                    date={l?.parntname}
                    Image={l?.drugimage}
                    icon={true}
                    index={index}
                    removeFromCart={removeFromCart}
                    mealName={l?.generic_name}
                    data={l}
                    Quantity={cartList}
                    addMinusQuantity={addMinusQuantity}
                    onHandleChnage={onHandleChnageQuantity}
                  />
                ))}
              </Grid>
            ) : (
              <div className={classes.mainBody2}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      padding: "8px",
                      height: "50px",
                      width: "50px",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#E9EFF8",
                      margin: "8px",
                    }}
                  >
                    <img
                      style={{
                        height: "30px",
                        width: "30px",
                        objectFit: "contain",
                      }}
                      src={`/images/icons/box.png`}
                      alt={"No Data Imge"}
                    ></img>
                  </div>
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: "600" }}
                    gutterBottom
                    noWrap
                  >
                    {"Your Cart is Empty!"}
                  </Typography>
                  <Typography
                    variant="body2"
                    gutterBottom
                    align="center"
                    style={{ wordWrap: "break-word" }}
                  >
                    {"Look like you haven't added anything to your cart yet!"}
                  </Typography>
                  {/* <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleClose()}
                  >
                    Order Now
                  </Button> */}
                </div>
              </div>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={4} className={classes.sidepanel}>
          {/*  Order Summary */}
          <Box className={classes.cardParent}>
            <Grid container className={classes.maincontent}>
              <Grid item xs={12} className={classes.subHeading}>
                <Typography
                  // style={{ fontWeight: "400", textAlign: "center" }}
                  gutterBottom
                  variant="body1"
                >
                  Order Summary
                </Typography>
              </Grid>

              <Grid item xs={12} className={classes.subHeading}>
                <Box className={classes.creditBox}>
                  <div>
                    <Typography variant="body2">{`Credit available`}</Typography>
                    {creditLimitMessagefunc()?.length !== 0 && (
                      <Typography
                        variant="caption"
                        color="error"
                        style={{ fontSize: "10px", fontWeight: "bold" }}
                      >
                        {creditLimitMessagefunc()}
                      </Typography>
                    )}
                  </div>
                  <Typography variant="body2">{`${creditDetails.difference} MYR`}</Typography>
                </Box>
                {/* <Typography
                  // style={{ fontWeight: "400", textAlign: "center" }}
                  gutterBottom
                  variant="body1"
                >
                  Credit Limit -
                </Typography> */}
                {/* <Typography
                  // style={{ fontWeight: "400", textAlign: "center" }}
                  gutterBottom
                  variant="body1"
                  style={{ color: "Green" }}
                >
                  {`${ creditLimit } `}
                </Typography> */}
              </Grid>
              {creditDetails.pendingPaymentAmount > 0 && (
                <Grid item xs={12} className={classes.subHeading}>
                  <Box className={classes.pendingBox}>
                    <div>
                      <Typography variant="body2">{`Payment Pending`}</Typography>
                    </div>
                    <Typography variant="body2">{`${creditDetails.pendingPaymentAmount} MYR`}</Typography>
                  </Box>
                </Grid>
              )}
              <Grid style={{ marginTop: "10px" }} item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} className={classes.flexBetween}>
                <Typography
                  gutterBottom
                  variant="body2"
                >{`Total Item`}</Typography>
                <Typography
                  variant="body2"
                  color="primary"
                >{`${cartList.length} items`}</Typography>
              </Grid>

              {cartList.length > 0 && (
                <>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <TableContainer
                    className={classes.tableContainer}
                    component={Paper}
                  >
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="left">
                            Item name
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Quantity
                          </StyledTableCell>
                          <StyledTableCell align="left">Price</StyledTableCell>
                          <StyledTableCell align="left">
                            Total Price
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {cartList.map((li, index) => {
                          {
                            /* console.log(li, "CartLi"); */
                          }
                          return (
                            <TableRow key={`${index}${li?.serialno} `}>
                              <StyledTableCell
                                align="left"
                                style={{ width: "40%" }}
                              >
                                <Typography
                                  className={classes.textResponsive}
                                  variant="body4"
                                >
                                  {li?.itemname}
                                </Typography>
                                <br />
                                {/* {li?.discount && (
                                  <Typography
                                    className={classes.discount}
                                    variant="body4"
                                  >
                                    {`${ li?.discount } `}
                                    {"  "}
                                    <img
                                      style={{ width: "13px", height: "13px" }}
                                      src="images/discount.png"
                                      alt=""
                                    />
                                  </Typography>
                                )} */}
                                {li?.discount ? (
                                  <Typography
                                    className={classes.discount}
                                    variant="body4"
                                  >
                                    {`${li?.discount} `}
                                    {"  "}
                                    <img
                                      style={{ width: "13px", height: "13px" }}
                                      src="images/discount.png"
                                      alt=""
                                    />
                                  </Typography>
                                ) : (
                                  <Typography
                                    className={classes.discount}
                                    variant="body4"
                                  >
                                    {/* {`${ li?.discount } `}
                                    {"  "}
                                    <img
                                      style={{ width: "13px", height: "13px" }}
                                      src="images/discount.png"
                                      alt=""
                                    /> */}
                                  </Typography>
                                )}
                              </StyledTableCell>

                              <StyledTableCell
                                align="left"
                                style={{ width: "25%" }}
                              >
                                <Typography variant="body4" color="primary">
                                  {li?.quantity ? li?.quantity : 1}
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell
                                align="left"
                                style={{ width: "20%" }}
                              >
                                {li?.discount ? (
                                  <>
                                    <Typography
                                      style={{
                                        textDecoration: "line-through",
                                        color: "grey",
                                      }}
                                      variant="body4"
                                      color="primary"
                                    >
                                      {li?.price}
                                    </Typography>
                                    <br />
                                    <Typography
                                      style={{
                                        color: "green",
                                      }}
                                      variant="body4"
                                      color="primary"
                                    >
                                      {li?.calculatedprice}
                                    </Typography>
                                  </>
                                ) : (
                                  <Typography variant="body4" color="primary">
                                    {li?.calculatedprice}
                                  </Typography>
                                )}
                              </StyledTableCell>
                              <StyledTableCell
                                align="left"
                                style={{ width: "10%" }}
                              >
                                <Typography variant="body4" color="primary">
                                  {parseFloat(
                                    Number(li?.quantity ? li?.quantity : 1) *
                                      Number(
                                        li?.calculatedprice
                                          ? li?.calculatedprice
                                          : li?.price
                                      )
                                  ).toFixed(2)}
                                </Typography>
                              </StyledTableCell>
                              {/* <TableCell align="right">{row.protein}</TableCell> */}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}

              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={12} className={classes.flexBetween}>
                <Typography variant="body2">{`Total Price`}</Typography>
                <Typography variant="body2" color="primary">
                  {getTotalPrice()}
                </Typography>
              </Grid>
              {grandtotal > 0 ? (
                <Grid item xs={12} className={classes.flexBetween}>
                  {deliverydata.length > 0 ? (
                    <>
                      <Typography variant="body2">{`Delivery Charge`}</Typography>
                      <Typography variant="body2" color="primary">
                        {deliveryCharge()}
                      </Typography>
                    </>
                  ) : null}
                </Grid>
              ) : (
                <></>
              )}
              <Grid
                item
                xs={12}
                style={{
                  margin: "16px",
                }}
              >
                <Box className={classes.disclaimerBox}>
                  <div>
                    <Typography variant="body2">{`Total Payable`}</Typography>
                    {creditLimitMessagefunc()?.length !== 0 && (
                      <Typography
                        variant="caption"
                        color="error"
                        style={{ fontSize: "10px", fontWeight: "bold" }}
                      >
                        {creditLimitMessagefunc()}
                      </Typography>
                    )}
                  </div>
                  <Typography variant="body2">{getTotalPrice2()}</Typography>
                </Box>
                <Typography
                  // style={{
                  //   marginLeft: "16px",
                  // }}
                  variant="body2"
                  color="textSecondary"
                  align="center"
                >
                  {"Inclusive of all taxes and shipping"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} lg={12} className={classes.footer}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <div>
                    <Typography
                      style={{
                        // marginLeft: "16px",
                        fontWeight: "600",
                      }}
                      variant="body2"
                      align="center"
                    >
                      {"Grand Total"}
                    </Typography>
                    <Typography variant="body2" align="center">
                      {getTotalPrice2()}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={cartList?.length > 0 ? false : true}
                    onClick={() => onOrderPlacingClicked()}
                  >
                    {"Place Your Order"}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      
      {
        loading && (
          <div
            style={{
              position: "fixed",
              display: "block",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              opacity: "0.7",
              backgroundColor: "#fff",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            >
              <CircularProgress />
            </div>
          </div>
        )
      }
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <FeedBack
          handleClose={handleClose}
          onReorderClicked={onReorderClicked}
          user={user}
          BedNumber={BedNumber}
          BedCode={BedCode}
          Difference={creditDetails.difference}
        />
      </Dialog>
      {/* </Box> */}
    </Box>
  );
};
