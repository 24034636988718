import React from "react";
import { makeStyles } from "@material-ui/core";
import { AlertProps } from "../utils";
import { AlertContext, ProjectInfoContext } from "../contexts";
import axios from "axios";
import { QueueModal } from "../components";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100%",
    position: "relative",
  },
  content: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    backgroundColor: "#ECF0F7",
  },
}));

const withQueueModal = (Component) => (props) => {
  // console.log("withQueueModal", props, Component)
  const classes = useStyles({ props });
  const [queues, setQueues] = React.useState([]);
  const alertMessage = React.useContext(AlertContext);
  const projectContext = React.useContext(ProjectInfoContext);

  const [selectedQueue, setSelectedQueue] = React.useState({});

  React.useEffect(() => {
    getCreatedQueue(sessionStorage.getItem("ProjectDbname"));
  }, [projectContext.call, props.PermissionData]);

  const getCreatedQueue = async (db) => {
    //
    try {
      let params = {
        db_name: `${db}`,
        entity: "queue",
        filter: `queue.activestatus==true`,
        sort: "queue.queuename ASC",
        return_fields: "queue",
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_ARANGO_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      await axios(config).then((res) => {
        //
        if (res.data.Code === 201) {
          let invoicequeue = res.data.result?.filter(
            (v) => v?._id === process.env.REACT_APP_INVOICE_QUEUE_ID
          );
          // console.log("wqm", invoicequeue)
          setQueues(invoicequeue);
        } else {
          alertMessage?.setSnack({
            ...alertMessage,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Something Went Wrong",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getSelectedQueue = (value) => {
    setSelectedQueue(value);
  };
  return (
    <React.Fragment>
      {/* Your nav bars here */}
      <div className={classes.root}>
        {/* Content */}
        <div className={classes.content}>
          <Component
            queues={queues}
            selectedQueue={selectedQueue}
            getSelectedQueue={getSelectedQueue}
            {...props}
          >
            {props.children}
          </Component>
          {/* <QueueModal
            PermissionData={props.PermissionData}
            queues={queues}
            getSelectedQueue={getSelectedQueue}
          /> */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default withQueueModal;
