import React from "react";
import { ProgressBarContext } from "./contexts";
import { Grid, Typography, CircularProgress, Backdrop } from "@material-ui/core";

import { withStyles } from "@material-ui/core";



const styles = (theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
});

class AppProgress extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            message: "",
            bgcolor: "",
            completed: "",
            setBackDrop: () => null,
        };
    }


    close = () => {
        this.setState({
            open: false,
            message: "",
        });
    };


    set = (props) => {
        this.setState({ ...props });
    };

    render() {
        const { classes } = this.props;

        const { open, message, bgcolor, completed } = this.state;

        return (
            <ProgressBarContext.Provider
                value={{
                    ...this.state,
                    setBackDrop: this.set,
                }}
            >
                {this.props.children}
                <Backdrop className={classes.backdrop} open={open} onClick={this.close}>
                    <Grid
                        container
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <div style={{ height: 20, width: '90%', backgroundColor: "#e0e0de", borderRadius: 50, margin: 50 }}>
                            <div style={{ height: '100%', width: `${completed}%`, backgroundColor: bgcolor, borderRadius: 'inherit', textAlign: 'right' }}>
                                <span style={{ padding: 5, color: 'white', fontWeight: 'bold' }}>{`${Math.round(completed)}%`}</span>
                            </div>
                        </div>
                        <Typography variant="h6" color="inherit">
                            {message}
                        </Typography>
                    </Grid>
                </Backdrop>
            </ProgressBarContext.Provider>
        );
    }
}

export default withStyles(styles)(AppProgress);
