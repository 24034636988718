import { Routes } from "../router/routes";
import WidgetsRoundedIcon from "@mui/icons-material/WidgetsRounded";
import NoteAltRoundedIcon from "@mui/icons-material/NoteAltRounded";
/**
 * Object with role as key and value, which is used for
 * comparison of role in different place.
 */
export const UserRoles = {
  role: "role",
};

/**
 * Object which has the proper name of all the role
 * used in the application.
 */
export let UserRolesName = {
  role: "Role",
};

/**
 * Object which has the different themes used in
 * the application.
 */
export let Themes = {
  default: "default",
  dark: "dark",
};

/**
 * Object which has the different props for the Alert Component (/src/component/alert)
 * which is used via AlertContext (/src/contexts) and provided at /src/App.alert.js.
 */
export let AlertProps = {
  vertical: {
    top: "top",
    bottom: "bottom",
  },
  horizontal: {
    left: "left",
    right: "right",
    center: "center",
  },
  severity: {
    success: "success",
    error: "error",
    warning: "warning",
    info: "info",
  },
};

export let sidebarlist = [
  {
    name: "Queue Items",
    id: 0,
    path: Routes.queueItem,
    icon: <WidgetsRoundedIcon />,
  },
  {
    name: "Activity Log",
    id: 1,
    path: Routes.activityLog,
    icon: <NoteAltRoundedIcon />,
  },
];
/**
 * Object which has the different props for the Drawer Component (/src/App.drawer.js)
 * which is used via DrawerContext (/src/contexts) and provided at /src/App.drawer.js.
 */
export const DrawerProps = {
  direction: {
    top: "top",
    bottom: "bottom",
    left: "left",
    right: "right",
  },
  variant: {
    permanent: "permanent",
    persistent: "persistent",
    temporary: "temporary",
  },
};

/**
 * Object has the key and value pair of all the keys which
 * are used to store some values in the local storage.
 */
export let LocalStorageKeys = {
  //   projectName: "",

  authToken: "authtoken",

  version: "version",
};

/**
 * Object has the key and value pair of all the HTTP method
 * used for an network call.
 */
export let NetWorkCallMethods = {
  get: "GET",
  post: "POST",
  put: "PUT",
  delete: "DELETE",
  update: "UPDATE",
};

export const FormComponents = {
  single_tab_select: "single_tab_select",
  multi_tab_select: "multi_tab_select",
  input_text: "input_text",
  input_textarea: "input_textarea",
  input_date_picker: "input_date_picker",
  multi_select_tag: "multi_select_tag",
  custom_multi_select_tag: "custom_multi_select_tag",
  select_modal_dropdown: "select_modal_dropdown",
  list_text: "list_text",
  input_number: "input_number",
  hours_minutes_range: "hours_minutes_range",
  grade: "grade",
  image_picker: "image_picker",
  switch_tab: "switch_tab",
  range_picker: "range_picker",
  popup_switch: "popup_switch",
  label: "label",
  note: "note",
  check_box: "check_box",
  radio_button: "radio_button",
  location: "location",
  custom_table: "custom_table",
  custom_table_2: "custom_table_2",
  mobile_no: "mobile_no",
  location_level: "location_level",
  date_of_birth: "date_of_birth",
  file_upload: "file_upload",
  Image_upload: "Image_upload",
  Image_only_upload: "Image_only_upload",
  input_time_picker: "input_time_picker",
  input_datetime_picker: "input_datetime_picker",
  select_modal_dropdown_qdm:"select_modal_dropdown_qdm"
};

export const personalTitles = [
  {
    value: "Mr.",
    label: "Mr.",
  },
  {
    value: "Mrs.",
    label: "Mrs.",
  },
  {
    value: "Miss.",
    label: "Miss.",
  },
  {
    value: "Ms.",
    label: "Ms.",
  },
];

export const genderData = [
  {
    value: "Female",
    label: "Female",
  },
  {
    value: "Male",
    label: "Male",
  },
  {
    value: "Others",
    label: "Others",
  },
];
