import React, { useEffect, useState, useRef } from "react";

import {
  Button,
  makeStyles,
  Divider,
  Typography,
  Grid,
} from "@material-ui/core";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import { BackdropContext, AlertContext } from "../../contexts";
import { actions } from "central_pharma_binder";
import { Message, AlertProps } from "../../utils";
import { useSelector, useDispatch } from "react-redux";
import Config from "../../config";
import { AxiosCall } from "../../utils";
import { Autocomplete } from "@material-ui/lab";
import AddIcon from "@material-ui/icons/Add";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { UploadFun } from "../../utils";

// Store Action's list
const { ADD_DRUG_MASTER } = actions;

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    padding: "16px"
  },
  header: {
    padding: "10px 20px",
  },
  formNameParent: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  formTypeParent: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  btnParent: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  selectedTab: {
    backgroundColor: theme.palette.secondary.main + "!important",
    color: "#fff !important",
  },
  selecteTab: {
    margin: "10px 16px 0px 0px",
    borderRadius: "6px !important",
    border: "1px solid rgba(0, 0, 0, 0.12) !important",
  },
  lable: {
    margin: "auto 0",
  },
  gridContainer: {
    marginTop: theme?.spacing(2),
    marginBottom: theme?.spacing(2),
  },
  root1: {
    width: "100%",
    height: "50%",
    "& input": {
      position: "absolute",
      width: "100%",
      height: "100%",
      opacity: 0,
      left: 0,
      cursor: "pointer",
      zIndex: 100,
    },
    "& .MuiButton-root": {
      top: "1px",
    },
    "& .MuiTypography-colorPrimary": {
      marginTop: theme?.spacing(1),
    },
  },
  drap: {
    position: "relative",
    height: 80,
    borderRadius: 6,
    textAlign: "center",
    background: theme?.palette?.background?.paper,
    border: "2px dashed #48AABF",
  },
  drap1: {
    position: "relative",
    height: "auto",
    borderRadius: 6,
    textAlign: "center",
    background: theme?.palette?.background?.paper,
    border: "2px dashed #48AABF",
  },
  error: {
    color: "#ff0000"
  }
}));

export const AddEditForm = (props) => {


  const classes = useStyles();
  const allowedFileTypes = `image/png, image/jpeg, image/x-png`;
  const [image, setImage] = useState(props?.editData?.drugimage ? props?.editData?.drugimage : null);
  const [cropData, setCropData] = useState(null);
  const [files, setfiles] = useState(null);
  const cropperRef = useRef(null);

  //console.log("addedit", props)
  const [state, setState] = React.useState({
    Drug_Description: props?.editData?.itemname ?? "",
    _id: props?.editData?._id ?? "",
    Drug_Class: props?.editData?.drugclass ?? "",
    Drug_Image: props?.editData?.drugimage ?? "",
    Drug_Category: props?.editData?.drugcategory ?? "",
    UOM: props?.editData?.uom ?? "",
    Status: props?.editData?.status ?? process.env.REACT_APP_STATUS,
    Stock: props?.editData?.stock ?? "",
    serialno: props?.editData?.serialno ?? "",
    Drug_Form: props?.editData?.drugform ?? "",
    Drug_Generic: props?.editData?.druggeneric ?? "",
    MOQ: props?.editData?.moq ?? "",
  });
  const [error, setError] = useState({
    Drug_Class: false,
    Drug_Description: false,
    MOQ: false,
    Drug_Image: false,
    Status: false,
    Stock: false
  })
  const [helpertxt, setHelpertxt] = useState({
    Drug_Class: "",
    Drug_Description: "",
    MOQ: "",
    Drug_Image: "",
    Status: "",
    Stock: ""

  })
  const onChange = (data) => {
    setState({
      ...state,
      assessment: data,
    });
  };
  const [prevFormName, setPrevFormName] = React.useState("");
  const [options, setOptions] = React.useState();
  // React.useEffect(() => {
  //   setOptions(
  //     props?.diseasesData?.map((disData) => {
  //       return { label: disData.assessment_name, value: disData._id };
  //     })
  //   );
  //   setPrevFormName(state.name);
  // }, []);

  // Declaring Store dispatch
  const dispatch = useDispatch();

  const backdrop = React.useContext(BackdropContext);

  const alert = React.useContext(AlertContext);

  const onChangeInput = (name, value) => {

    if (name == "Drug_Description") {
      setState({ ...state, [name]: value });
    }
    else if (name == "MOQ") {

      let Max = 999
      let Min = 1

      if (value > Max || value < Min) {
        setError({
          MOQ: true
        })
        setHelpertxt({
          MOQ: "MOQ should be minimum 1 to Maximum 999"
        })
        setState({ ...state, [name]: "" });
      }

      else {
        setError({
          MOQ: false
        })
        setHelpertxt({
          MOQ: ""
        })
        setState({ ...state, [name]: value });
      }
    } else if (name == "Stock") {

      let Max = 999
      let Min = 1

     // if (value > Max || value < Min) {
      if ( value < Min) {
        setError({
          Stock: true
        })
        setHelpertxt({
        //  Stock: "Stock should be in Number and Minimum 1 to Maximum 999"
           Stock: "Stock should be in Number and Minimum 1 "
        })
        setState({ ...state, [name]: "" });
      }

      else {
        setError({
          Stock: false
        })
        setHelpertxt({
          Stock: ""
        })
        setState({ ...state, [name]: value });
      }
    }

    else {
      setState({ ...state, [name]: value?._id ?? "" });
    }
  };


  const validation = () => {

    let filtervalue = props?.diseasesData.filter(li => li.itemname.toLowerCase() == state?.Drug_Description.toLowerCase())

    let errorDrug_Description = "";
    let errorDrug_Class = "";
    let errorMOQ = "";
    let errorImage = "";
    let errorStatus = "";
    let errorStock = "";
    if (state?.Drug_Description == "") {

      errorDrug_Description = Message.requiredMessage
    }
    else if (props?.isEdit == false) {
      if (filtervalue?.length > 0) {
        errorDrug_Description = "Drug Name Already Exist"
      }
    }
    else if (props?.isEdit == true) {
      if (filtervalue?.length > 0) {
        if (state?.serialno != filtervalue[0].serialno) {
          errorDrug_Description = "Drug Name Already Exist"
        }
      }
    }

    if (state?.Drug_Class == "") {
      errorDrug_Class = Message.requiredMessage
    }
    if (state?.MOQ == "") {
      errorMOQ = Message.requiredMessage
    }

    if (state?.Status == "") {
      errorStatus = Message.requiredMessage
    } if (state?.Stock == "") {
      errorStock = Message.requiredMessage
    }


    if (errorDrug_Description || errorDrug_Class || errorMOQ || errorImage || errorStatus || errorStock) {
      setError({
        Drug_Description: errorDrug_Description != "" ? true : false,
        Drug_Class: errorDrug_Class != "" ? true : false,
        MOQ: errorMOQ != "" ? true : false,
        Drug_Image: errorImage != "" ? true : false,
        Status: errorStatus != "" ? true : false,
        Stock: errorStock != "" ? true : false
      })
      setHelpertxt({
        Drug_Description: errorDrug_Description,
        Drug_Class: errorDrug_Class,
        MOQ: errorMOQ,
        Drug_Image: errorImage,
        Status: errorStatus,
        Stock: errorStock
      })
      //return false;
    }
    else {
      return true;
    }


    // else {
    //   return true;
    // }

    // let terror = false;
    // let errordata = error
    // let errortxt = helpertxt
    // let array = ["Drug_Description", "MOQ", "Drug_Class"]
    // array?.map((v) => {

    //   if (state?.[v]?.length === 0) {
    //     errordata[v] = true
    //     errortxt[v] = Message.requiredMessage
    //     terror = true
    //   } else {
    //     errordata[v] = false
    //     errortxt[v] = ""
    //   }
    // })
    // setError({ ...error, ...errordata })
    // setHelpertxt({ ...helpertxt, ...errortxt })
    // return !terror
  };

  const onSubmitForm = async () => {
    const isValidation = validation();

    if (!isValidation) {

      return false;
    }

    let __params = {}

    if (files) {
      const data = await UploadFun(files)
      __params = {
        itemname: state?.Drug_Description,
        drugclass: state?.Drug_Class,
        drugcategory: state?.Drug_Category,
        uom: state?.UOM,
        status: state?.Status,
        stock: parseFloat(state?.Stock),
        drugimage: `${Config.downloadUrl}${data?.fileid}`,
        druggeneric: state?.Drug_Generic,
        drugform: state?.Drug_Form,
        moq: state?.MOQ,
        _id: state?._id
      };
    }

    else {

      __params = {
        itemname: state?.Drug_Description,
        drugclass: state?.Drug_Class,
        drugcategory: state?.Drug_Category,
        uom: state?.UOM,
        status: state?.Status,
        stock: parseFloat(state?.Stock),
        drugimage: state?.Drug_Image,
        druggeneric: state?.Drug_Generic,
        drugform: state?.Drug_Form,
        _id: state?._id,
        moq: state?.MOQ,
      };
    }

    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: props.isEdit
        ? Message.manage_form_update
        : Message.manage_form_add,
    });

    Promise.resolve(dispatch(ADD_DRUG_MASTER(__params))).then((res) => {
      backdrop.setBackDrop({ ...backdrop, open: false });
      let resStatus = res.payload.error;
      let statusMessage = props.isEdit
        ? "Drug Updated Successfully !"
        : "Drug Added Successfully !";
      let statusMessage2 = props.isEdit
        ? "Drug Updated Failed!"
        : "Drug Adding Failed !";
      if (res?.payload?.message === "Drug Already Exist !") {
        alert.setSnack({
          ...alert,
          open: true,
          severity: resStatus
            ? AlertProps.severity.error
            : AlertProps.severity.success,
          msg: res?.payload?.message,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      } else {
        props.onActionCompleted(props.isEdit);
        alert.setSnack({
          ...alert,
          open: true,
          severity: resStatus
            ? AlertProps.severity.error
            : AlertProps.severity.success,
          msg: resStatus ? statusMessage2 : statusMessage,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      }
    });
  };

  const onImageChange = (e) => {
    e.preventDefault();
    const imageFile = e.target.files[0];
    if (imageFile.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      let files;
      if (e.dataTransfer) {
        files = e.dataTransfer.files;
      } else if (e.target) {
        files = e.target.files;
      }
      setfiles(e.target.files[0])
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(files[0]);
      setHelpertxt({
        Drug_Image: false
      })
      setError({
        ...error,
        Drug_Image: ""
      })
    }

    else {
      setfiles(null)
      setHelpertxt({
        ...error,
        Drug_Image: true
      })
      setError({
        ...error,
        Drug_Image: "Please select valid image."
      })
    }
  };

  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      setCropData(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
    }
    var num = Math.floor(Math.random() * 90000) + 10000;
    var file = dataURLtoFile(cropperRef.current?.cropper.getCroppedCanvas().toDataURL(), `${state?.Drug_Description}-${num}.png`);
    setfiles(file)
  };

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const RemoveImage = () => {
    setState({
      ...state,
      Drug_Image: "",
    });
    setfiles(null)
    setImage(null)
    setCropData(null)
  }

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="stretch"
      >

        {state?.serialno &&
          <Grid item className={classes.formNameParent}>
            <TextField

              value={state?.serialno ?? ""}
              fullWidth
              label="Drug Code"
              id="outlined-size-small"
              variant="outlined"
              size="small"
              disabled={true}
            />
          </Grid>
        }
        <Grid item className={classes.formNameParent}>
          <TextField
            onChange={(e) => onChangeInput("Drug_Description", e.target.value)}
            value={state?.Drug_Description ?? ""}
            fullWidth
            label="Item Description"
            id="outlined-size-small"
            variant="outlined"
            size="small"
            error={error.Drug_Description}
            helperText={
              helpertxt.Drug_Description
            }
            disabled={props?.viewopen}
            required

          />
        </Grid>
        <Grid container>


          <Grid item md={6} className={classes.formTypeParent}>
            <Autocomplete
              options={props?.DrugClass}
              getOptionLabel={(option) => option.display || ""}
              size={"small"}
              value={props?.DrugClass.find((l) => l?._id === state?.Drug_Class)}
              onChange={(e, value) => onChangeInput("Drug_Class", value)}
              disabled={props?.viewopen}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Class"
                  variant="outlined"
                  //placeholder={"Select Drug Class"}
                  autoComplete="off"
                  size="small"
                  helperText={helpertxt?.Drug_Class}
                  error={error?.Drug_Class}
                  required
                />
              )}
            />

          </Grid>

          <Grid md={6} item className={classes.formTypeParent}>
            <Autocomplete
              options={props?.Drugcategory}
              getOptionLabel={(option) => option.display || ""}
              size={"small"}
              value={props?.Drugcategory.find((l) => l?._id === state?.Drug_Category)}
              onChange={(e, value) => onChangeInput("Drug_Category", value)}
              disabled={props?.viewopen}
              renderInput={(params) => (
                <TextField
                  label="Category"
                  {...params}
                  variant="outlined"
                  //placeholder={"Select Drug Category"}
                  autoComplete="off"
                  size="small"
                // helperText={helpertxt?.Drug_Category}
                // error={error?.Drug_Category}
                //  required
                />
              )}
            />

          </Grid>
          <Grid md={6} item className={classes.formTypeParent}>
            <Autocomplete
              options={props?.UOM}
              getOptionLabel={(option) => option.display || ""}
              size={"small"}
              value={props?.UOM.find((l) => l?._id === state?.UOM)}
              onChange={(e, value) => onChangeInput("UOM", value)}
              disabled={props?.viewopen}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="UOM"
                  autoComplete="off"
                  size="small"
                // helperText={helpertxt?.UOM}
                // error={error?.UOM}
                />
              )}
            />

          </Grid>
          <Grid md={6} item className={classes.formTypeParent}>
            <Autocomplete
              options={props?.druggeneric}
              getOptionLabel={(option) => option.display || ""}
              size={"small"}
              value={props?.druggeneric.find((l) => l?._id === state?.Drug_Generic)}
              onChange={(e, value) => onChangeInput("Drug_Generic", value)}
              disabled={props?.viewopen}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Generic"
                  autoComplete="off"
                  size="small"

                />
              )}
            />

          </Grid>
          <Grid md={6} item className={classes.formTypeParent}>
            <Autocomplete
              options={props?.drugform}
              getOptionLabel={(option) => option.display || ""}
              size={"small"}
              value={props?.drugform.find((l) => l?._id === state?.Drug_Form)}
              onChange={(e, value) => onChangeInput("Drug_Form", value)}
              disabled={props?.viewopen}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Form"
                  autoComplete="off"
                  size="small"
                />
              )}
            />

          </Grid>
          <Grid md={6} item className={classes.formTypeParent}>
            <Autocomplete
              options={props?.Status}
              getOptionLabel={(option) => option.display || ""}
              size={"small"}
              value={props?.Status.find((l) => l?._id === state?.Status)}
              onChange={(e, value) => onChangeInput("Status", value)}
              disabled={props?.viewopen}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Status"
                  autoComplete="off"
                  size="small"
                  required
                  helperText={helpertxt?.Status}
                  error={error?.Status}
                />
              )}
            />

          </Grid>

          <Grid md={6} item className={classes.formNameParent}>
            <TextField
              onChange={(e) => onChangeInput("MOQ", e.target.value)}
              type={"number"}
              value={state?.MOQ ?? ""}
              fullWidth
              label="Minimum Order Quantity"
              id="outlined-size-small"
              variant="outlined"
              size="small"
              disabled={props?.viewopen}
              error={error?.MOQ}
              helperText={helpertxt?.MOQ}
              required
              InputProps={{ inputProps: { min: 1, max: 999 } }}
            />
          </Grid>
          <Grid md={6} item className={classes.formNameParent}>
            <TextField
              onChange={(e) => onChangeInput("Stock", e.target.value)}
              type={"number"}
              value={state?.Stock ?? ""}
              fullWidth
              label="Stock"
              id="outlined-size-small"
              variant="outlined"
              size="small"
              disabled={props?.viewopen}
              error={error?.Stock}
              helperText={helpertxt?.Stock}
              required
              InputProps={{ inputProps: { min: 1, max: 999 } }}
            />
          </Grid>
        </Grid>
        {!props?.viewopen &&
          !image &&
          <Grid item className={classes.formTypeParent}>
            <div className={classes.drap}>
              <div className={classes.root1}>
                <input
                  id={"input2" + props?.id}
                  type="file"
                  multiple={false}
                  accept={allowedFileTypes}
                  disabled={props?.disabled}
                  onChange={onImageChange}
                />
                <Button id={"add" + props?.id}>
                  <AddIcon style={{ color: "#48AABF" }} />
                </Button>
                <Typography variant="subtitle2" color="primary">
                  Click to browse and upload Item Image
                </Typography>
              </div>
            </div>
            {helpertxt?.Drug_Image && <p className={classes.error}>{error?.Drug_Image}</p>}
          </Grid>

        }

        {!props?.viewopen &&
          image &&
          <Grid item className={classes.formTypeParent}>
            <div className={classes.drap1}>
              <Cropper
                style={{ height: "auto", width: "100%" }}
                initialAspectRatio={1}
                preview=".img-preview"
                src={image}
                ref={cropperRef}
                viewMode={1}
                guides={true}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                background={false}
                responsive={true}
                checkOrientation={false}
              />
            </div>
          </Grid>
        }
        {!props?.viewopen &&
          image &&
          <Grid item className={classes.btnParent}>
            <Button variant="outlined" size="small"
              color="primary" onClick={getCropData}>
              Crop Image
            </Button>
            <Button variant="outlined" size="small" style={{ marginLeft: "10px" }}
              color="primary" onClick={RemoveImage}>
              Remove Image
            </Button>
          </Grid>
        }
        {cropData &&
          <Grid item className={classes.formTypeParent}>
            <div className={classes.drap1}>
              <img style={{ width: "auto", height: "auto" }} src={cropData} alt="cropped" />
            </div>
          </Grid>
        }
        {props?.viewopen &&
          <Grid item className={classes.formTypeParent}>
            <div className={classes.drap1}>
              <img style={{ width: "auto", height: "auto" }} src={state?.Drug_Image} alt="Drug Image Not Avaliable " />
            </div>
          </Grid>
        }
        {!props?.viewopen &&
          <Grid item className={classes.btnParent}>
            <Button
              variant="contained"
              color="primary"
              disabled={props?.upsertForm?.loading}
              onClick={() => onSubmitForm()}
              style={{ float: "right" }}
            >
              {props.isEdit
                ? props?.upsertForm?.loading
                  ? "Updating..."
                  : "Update Item"
                : props?.upsertForm?.loading
                  ? "Adding..."
                  : "Add Item"}
            </Button>
          </Grid>
        }
      </Grid>
    </div>
  );
};
