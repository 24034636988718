import * as React from "react";
import PropTypes from "prop-types";
import { Routes } from "../router/routes";
import { useTheme } from "@material-ui/core";

const PractitionerIcon = (props) => {
  const theme = useTheme();
  const color =
    window.location.pathname === Routes.practitionerMaster
      ? theme.sideBar.text.active
      : theme.sideBar.text.nonActive;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.925"
      height="16.81"
      viewBox="0 0 15.925 16.81"
    >
      <path
        id="icons8-classroom"
        d="M5.654,2A1.769,1.769,0,1,0,7.424,3.769,1.769,1.769,0,0,0,5.654,2Zm3.539.885V4.654h7.963v7.078H10.078V13.5H17.6a1.329,1.329,0,0,0,1.327-1.327V4.212A1.329,1.329,0,0,0,17.6,2.885ZM4.769,6.424A1.769,1.769,0,0,0,3,8.193V13.5H8.308V8.193h5.308V6.424H4.769Zm0,1.769H6.539v3.539H4.769Zm.885,7.078A1.769,1.769,0,1,0,7.424,17.04,1.769,1.769,0,0,0,5.654,15.271Zm5.308,0a1.769,1.769,0,1,0,1.769,1.769A1.769,1.769,0,0,0,10.963,15.271Zm5.308,0A1.769,1.769,0,1,0,18.04,17.04,1.769,1.769,0,0,0,16.271,15.271Z"
        transform="translate(-3 -2)"
        fill={color}
      />
    </svg>
  );
};

export default PractitionerIcon;

PractitionerIcon.defaultProps = {
  color: "black",
};

PractitionerIcon.propTypes = {
  color: PropTypes.string,
};
