import * as React from "react";
import PropTypes from "prop-types";

const GridIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} {...props}>
    <path
      d="M.6 0a.6.6 0 0 0-.6.6V5a.6.6 0 0 0 .6.6H5a.6.6 0 0 0 .6-.6V.6A.6.6 0 0 0 5 0ZM7 0a.6.6 0 0 0-.6.6V5a.6.6 0 0 0 .6.6h4.4A.6.6 0 0 0 12 5V.6a.6.6 0 0 0-.6-.6ZM.6 6.4A.6.6 0 0 0 0 7v4.4a.6.6 0 0 0 .6.6H5a.6.6 0 0 0 .6-.6V7a.6.6 0 0 0-.6-.6Zm6.4 0a.6.6 0 0 0-.6.6v4.4a.6.6 0 0 0 .6.6h4.4a.6.6 0 0 0 .6-.6V7a.6.6 0 0 0-.6-.6Z"
      fill={props.color}
    />
  </svg>
);

export default GridIcon;

GridIcon.defaultProps = {
  color: "#2a60bc",
};

GridIcon.propTypes = {
  color: PropTypes.string,
};
