import {
  Grid,
  Typography,
  IconButton,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Button,
  Paper,
  Divider,
} from "@material-ui/core";
import PhoneIcon from "@material-ui/icons/Phone";
import HotelIcon from "@material-ui/icons/Hotel";
import PersonIcon from "@material-ui/icons/Person";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import EditIcon from "@material-ui/icons/Edit";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { actions } from "central_pharma_binder";
import { useDispatch } from "react-redux";
import React from "react";
import { AxiosCall } from "../../api";
import { ToastMessage } from "../../utils";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const PatientList = (props) => {
  const dispatch = useDispatch();

  const { patientList, editForm, transferForm } = props;
  const patientCheckin = React.useRef(null);
  const [bed, setBed] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [ticketId, setTicketId] = React.useState([]);
  const [checkOutPatientDetails, setCheckOutPatientDetails] = React.useState(
    []
  );
  // console.log("checkin value", patientList);
  const handleClickOpen = (val) => {
    // console.log(val);
    setOpen(true);
  };

  const getTicketId = async (val) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        db_name: process.env.REACT_APP_DB,
        entity: process.env.REACT_APP_MEAL_ORDER_ENTITY,
        filter: `${process.env.REACT_APP_MEAL_ORDER_ENTITY}.PatientCode == '${val?._id}' && ${process.env.REACT_APP_MEAL_ORDER_ENTITY}.PatientBedNumber == '${val?.PatientMapping?.BedCode}' && ${process.env.REACT_APP_MEAL_ORDER_ENTITY}.OrderStatus !='${process.env.REACT_APP_DELIVERED_ORDER_STATUS}' && ${process.env.REACT_APP_MEAL_ORDER_ENTITY}.activestatus==true `,
        return_fields: `${process.env.REACT_APP_MEAL_ORDER_ENTITY}.ticketId`,
      }),
      redirect: "follow",
    };
    fetch(
      `${process.env.REACT_APP_ARANGO_API}/api/read_documents`,
      requestOptions
    )
      .then((result) => result.json())
      .then((response) => {
        if (response.result.length !== 0) {
          handleClickOpen(val);
          setCheckOutPatientDetails(val);
        } else {
          handleCheckout(val);
          resetBedCount(val.PatientMapping?.BedCode);
        }
        setTicketId(response.result);
      })
      .catch((err) => console.error(err));
  };

  const requestAll = async () => {
    return await Promise.all(
      ticketId.map(async (id) => {
        try {
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          return await fetch(process.env.REACT_APP_PERFORMUSERACTION_API, {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify({
              ticketId: id,
              userAction: process.env.REACT_APP_TASK_VIEWER_USER_ACTION,
            }),
            redirect: "follow",
          });
        } catch (err) {
          console.log(err);
        }
      })
    ).then((e) => {
      // console.log(e);
      if (e.error !== true) {
        MealOrderActiveStatus();
      }
    });
  };
  // console.log(patientList, checkOutPatientDetails);
  const MealOrderActiveStatus = async () => {
    return await Promise.all(
      ticketId.map(async (id) => {
        try {
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          fetch(process.env.REACT_APP_ARANGO_URL_READ, {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify({
              db_name: "ipmo",
              entity: "MealOrder",
              filter: `MealOrder.ticketId=='${id}'`,
              return_fields: "MealOrder",
            }),
            redirect: "follow",
          })
            .then((result) => result.json())
            .then((res) => {
              var myHeaders = new Headers();
              myHeaders.append("Content-Type", "application/json");
              fetch(process.env.REACT_APP_ARANGO_URL_UPSERT, {
                method: "POST",
                headers: myHeaders,
                body: JSON.stringify([
                  {
                    db_name: process.env.REACT_APP_DB,
                    entity: process.env.REACT_APP_MEAL_ORDER_ENTITY,
                    filter: { _id: res.result[0]._id },
                    doc: {
                      activestatus: false,
                    },
                  },
                ]),
                redirect: "follow",
              })
                .then((result) => result.json())
                .then((response) => response)
                .catch((err) => console.error(err));
            })
            .catch((err) => console.error(err));
        } catch (err) {
          console.log(err);
        }
      })
    ).then((e) => {
      if (e.error !== true) {
        handleCheckout(checkOutPatientDetails);
        resetBedCount(checkOutPatientDetails?.PatientMapping?.BedCode);
        handleClose();
      }
    });
  };
  const resetBedCount = (bed) => {
    try {
      //;
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      fetch(process.env.REACT_APP_ARANGO_URL_UPSERT, {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify([
          {
            db_name: process.env.REACT_APP_DB,
            entity: process.env.REACT_APP_ENTITY_BED,
            filter: { _id: bed },
            doc: {
              LoginCount: 0,
            },
          },
        ]),
        redirect: "follow",
      })
        .then((result) => result.json())
        .then((res) => {
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          fetch(process.env.REACT_APP_ARANGO_URL_UPSERT, {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify([
              {
                db_name: process.env.REACT_APP_DB,
                entity: process.env.REACT_APP_MEAL_ORDER_ENTITY,
                filter: { _id: res.result[0]._id },
                doc: {
                  activestatus: false,
                },
              },
            ]),
            redirect: "follow",
          })
            .then((result) => result.json())
            .then((response) => response)
            .catch((err) => console.error(err));
        })
        .catch((err) => console.error(err));
    } catch (err) {
      console.log(err);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const onCheckinClicked = (data) => {
    // console.log("editform", data);
    editForm(data);
  };
  React.useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        db_name: process.env.REACT_APP_DB,
        entity: process.env.REACT_APP_ENTITY_BED,
        filter: `${process.env.REACT_APP_ENTITY_BED}.activestatus==true`,
        return_fields: process.env.REACT_APP_ENTITY_BED,
      }),
      redirect: "follow",
    };
    fetch(process.env.REACT_APP_ARANGO_URL_READ, requestOptions)
      .then((result) => result.json())
      .then((response) => {
        setBed(response.result);
      })
      .catch((err) => console.error(err));
  }, [patientList]);

  const resetPasswordToDefault = async (bedid) => {
    let selectedBed = bed.find((l) => l._id === bedid);
    let params = {
      email: selectedBed?.BedNumber,
      password: "adminpassword",
    };
    let header = { tenantid: process.env.REACT_APP_IDM_TENANT_ID };

    let res = await AxiosCall(
      "post",
      process.env.REACT_APP_KCLK_RESET,
      params,
      header
    );
    return res;
  };
  const patientCheckInCall = async (response) => {
    // console.log("patientCheckInCall", response);
    patientCheckin.current = dispatch(
      actions.SEARCH_PATIENTCHECKIN(response.PatientCode)
    );
  };
  const handleCheckout = async (patientdetails) => {
    let patientDetails = patientdetails?.PatientMapping;
    // console.log("patientDetails", patientDetails);
    let _res = await resetPasswordToDefault(patientDetails?.BedCode);
    // console.log(_res);
    if (_res?.Code === "201" && _res !== undefined) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify([
          {
            db_name: process.env.REACT_APP_DB,
            entity: process.env.REACT_APP_ENTITY_PATIENTCHECKIN,
            filter: { _id: patientDetails?._id },
            doc: {
              BedCode: patientDetails?.BedCode,
              CheckInDateTime: patientDetails.CheckInDateTime,
              PatientCode: patientDetails.PatientCode,
              Status: process.env.REACT_APP_PATIENTCHECKIN_STATUS_CHECKOUT, //
              CheckOutDateTime: Date.parse(new Date()) / 1000,
              roomCode: patientDetails.roomCode,
              wardCode: patientDetails.wardCode,
              facCode: patientDetails.facCode,
              orgCode: patientDetails.orgCode,
              activestatus: false,
            },
          },
        ]),
        redirect: "follow",
      };
      await fetch(process.env.REACT_APP_ARANGO_URL_UPSERT, requestOptions)
        .then((result) => result.json())
        .then((response) => {
          // console.log("checkout", response);
          ToastMessage("Check-Out successful", "success");
          patientCheckInCall(patientDetails);
          props.onSearchClicked();
        })
        .catch((err) => console.error(err));
    } else {
      ToastMessage("Unable to Check-Out", "error");
    }
  };
  const bedNumberFun = (val) => {
    let bedvalue = bed.find((l) => l._id === val?.PatientMapping?.BedCode);

    return bedvalue?.BedNumber;
  };
  const handleTicket = async (l) => {
    await getTicketId(l);
  };
  return (
    <React.Fragment>
      {/* <Grid container spacing={1} style={{ padding: "8px" }}>
        {patientList.map((l, index) => (
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3} key={index}>
            <Card style={{ minHeight: "150px" }}>
              <CardContent>
                <Grid container>
                  <Grid item xs={5} sm={5} md={5} lg={5}>
                    <Typography variant="body1">{"Patient Name"}</Typography>
                    <Typography variant="body2">
                      {l?.PatientFName} {l?.PatientMName} {l?.PatientLName}
                    </Typography>

                    <React.Fragment>
                      <Typography variant="body1">MRN Number</Typography>
                      <Typography variant="body2">
                        {l?.PatientMRN?.length !== 0 ? l?.PatientMRN : "-"}
                      </Typography>
                    </React.Fragment>
                  </Grid>
                  <Grid item xs={5} sm={5} md={5} lg={5}>
                    <Typography variant="body1">{"Phone Number"}</Typography>
                    <Typography variant="body2">
                      {l?.PatientMobNumber.length !== 0
                        ? l?.PatientMobNumber
                        : "-"}
                    </Typography>
                    <Typography variant="body1">{"Bed Number"}</Typography>
                    <Typography variant="body2">
                      {l.hasOwnProperty("PatientMapping") &&
                      l?.PatientMapping?.BedCode?.length !== 0
                        ? bedNumberFun(l)
                        : "Bed not yet mapped"}
                    </Typography>
                  </Grid>

                  <Grid item xs={2} sm={2} md={2} lg={2}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                        justifyContent: "space-between",
                        alignItems: "flex-end",
                      }}
                    >
                      {patientCheckin.current?.BedCode ||
                        (l.PatientMapping?.BedCode && (
                          <Tooltip title="Edit" placement="right">
                            <IconButton
                              variant="contained"
                              // size="small"
                              color={"primary"}
                              onClick={() => editForm(l)}
                            >
                              <EditIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        ))}

                      <Tooltip
                        title={
                          patientCheckin.current?.BedCode ||
                          l?.PatientMapping?.BedCode
                            ? "Check-Out"
                            : "Check-In"
                        }
                      >
                        <IconButton
                          variant="contained"
                          // size="small"
                          color={"primary"}
                          onClick={() => {
                            patientCheckin.current?.BedCode ||
                            l.PatientMapping?.BedCode
                              ? handleTicket(l)
                              : onCheckinClicked(l);
                            // ticketId.current.length !== 0
                            //   ? handleClickOpen(l)
                            //   : patientCheckin.current?.BedCode ||
                            //     l.PatientMapping?.BedCode
                            //   ? handleCheckout(l)
                            //   : onCheckinClicked(l);
                          }}
                        >

                          {patientCheckin.current?.BedCode ||
                          l.PatientMapping?.BedCode ? (
                            //<img
                            //style={{ width: "20px", height: "20px" }}
                            //src={CheckOut}
                            //alt="Check-Out"
                            // />

                            <ExitToAppIcon fontSize="small" />
                          ) : (
                            <CheckCircleOutlineIcon fontSize="small" />
                          )}

                        </IconButton>
                      </Tooltip>
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid> */}
      <Grid container spacing={1} style={{ padding: "8px" }}>
        {patientList.map((l, index) => (
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3} key={index}>
            <Paper style={{ padding: "8px", minHeight: "120px" }}>
              <Grid container>
                <Grid
                  item
                  xs={9}
                  sm={9}
                  md={9}
                  lg={9}
                  xl={9}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Typography component="h6" style={{ fontWeight: "600" }}>
                    {l?.PatientFName} {l?.PatientMName} {l?.PatientLName}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={3}
                  sm={3}
                  md={3}
                  lg={3}
                  xl={3}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  {patientCheckin.current?.BedCode ||
                    (l.PatientMapping?.BedCode && (
                      <Tooltip title="Transfer Bed" placement="top">
                        <IconButton
                          variant="contained"
                          // size="small"
                          color={"primary"}
                          onClick={() => transferForm(l)}
                          style={{ cursor: "pointer" }}
                        >
                          <SwapHorizIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    ))}
                  {patientCheckin.current?.BedCode ||
                    (l.PatientMapping?.BedCode && (
                      <Tooltip title="Edit" placement="top">
                        <IconButton
                          variant="contained"
                          // size="small"
                          color={"primary"}
                          onClick={() => editForm(l)}
                          style={{ cursor: "pointer" }}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    ))}
                  <Tooltip
                    placement="right"
                    title={
                      patientCheckin.current?.BedCode ||
                      l?.PatientMapping?.BedCode
                        ? "Check-Out"
                        : "Check-In"
                    }
                  >
                    <IconButton
                      variant="contained"
                      // size="small"
                      color={"primary"}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        patientCheckin.current?.BedCode ||
                        l.PatientMapping?.BedCode
                          ? handleTicket(l)
                          : onCheckinClicked(l);
                        // ticketId.current.length !== 0
                        //   ? handleClickOpen(l)
                        //   : patientCheckin.current?.BedCode ||
                        //     l.PatientMapping?.BedCode
                        //   ? handleCheckout(l)
                        //   : onCheckinClicked(l);
                      }}
                    >
                      {patientCheckin.current?.BedCode ||
                      l.PatientMapping?.BedCode ? (
                        <ExitToAppIcon fontSize="small" />
                      ) : (
                        <CheckCircleOutlineIcon fontSize="small" />
                      )}
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
              <Divider orientation="horizontal" />
              <Grid container style={{ margin: "10px 0" }}>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                  <div
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <PersonIcon fontSize="small" />
                    <Typography variant="caption" style={{ margin: "0 5px" }}>
                      {l?.PatientMRN?.length !== 0 ? l?.PatientMRN : "-"}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <HotelIcon fontSize="small" />
                    <Typography variant="caption" style={{ margin: "0 5px" }}>
                      {l.hasOwnProperty("PatientMapping") &&
                      l?.PatientMapping?.BedCode?.length !== 0
                        ? bedNumberFun(l)
                        : "Bed not mapped"}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <PhoneIcon fontSize="small" />
                    <Typography variant="caption" style={{ margin: "0 5px" }}>
                      {l?.PatientMobNumber.length !== 0
                        ? l?.PatientMobNumber
                        : "-"}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        ))}
      </Grid>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        // onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"Patient Check-Out"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            style={{ color: "black" }}
          >
            There are outstanding order for{" "}
            {checkOutPatientDetails?.PatientFName}{" "}
            {checkOutPatientDetails?.PatientMName}{" "}
            {checkOutPatientDetails?.PatientLName}
            <br />
            Do you wish to proceed with Check-out
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={() => requestAll()} color="primary">
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
