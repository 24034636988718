import React from "react";

import {
  CartListCountContect
} from "./";
const PintarContext = (props) => {
  
  let [cartCount, setCartCount] = React.useState(0);
  return (
    <CartListCountContect.Provider value={{ cartCount, setCartCount }}>
        {props.children}
    </CartListCountContect.Provider>
  );
};

export default PintarContext;