import React, { useEffect, Fragment, useState } from "react";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { makeStyles, Typography } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  datePic: {
    position: "relative",
    "& .MuiTextField-root": {
      width: "100%",
      zIndex: 2,
      "& .MuiOutlinedInput-input": {
        paddingTop: 10.5,
        paddingBottom: 10.5,
      },
    },
    "& .MuiSvgIcon-root": {
      position: "absolute",
      right: 14,
      top: "50%",
      transform: "translateY(-50%)",
      width: 20,
      height: 20,
    },
  },
  errorText: {
    color: "red",
    fontSize: "0.75rem",
  },
}));
const DatePickers = (props) => {
  const classes = useStyles(props);

  const [selectedDate, setSelectedDate] = useState(
    props?.value ? props?.value : null
  );

  useEffect(() => {
    setSelectedDate(props?.value ? props?.value : null);
  }, [props?.value]);

  const getLabel = (props) => {
    return (
      <Typography variant="body1" style={{ fontSize: 16 }} gutterBottom>
        {props.label}{" "}
        {props.isrequired && (
          <Typography variant="caption" style={{ color: "red" }}>
            *
          </Typography>
        )}
      </Typography>
    );
  };

  const minimunDate = () => {
    if (props?.validation) {
      if (props?.fromDate) {
        return props?.fromDate;
      } else if (props?.toggleSelectedData === "Hide Past Dates") {
        return moment(new Date()).format("YYYY-MM-DD");
      } else {
        return "1000-01-01";
      }
    } else {
      return "1000-01-01";
    }
  };

  const maximumDate = () => {
    // Hide Future Dates
    if (props?.validation) {
      if (props?.toDate) {
        return props?.toDate;
      } else if (props?.toggleSelectedData === "Hide Future Dates") {
        return moment(new Date()).format("YYYY-MM-DD");
      } else {
        return "9999-12-31";
      }
    } else {
      return "9999-12-31";
    }
  };

  const handleDateChange = (val) => {
    setSelectedDate(moment(val).format("YYYY-MM-DD"));
    props?.onChange && props.onChange(moment(val).format("YYYY-MM-DD"));
  };

  useEffect(() => {
    if (props?.validation) {
      if (props?.currentDate?.length > 0) {
        props.onChange(props?.currentDate);
      }
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.root}>
      {getLabel(props)}
      <div className={classes.datePic}>
        <Fragment>
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <DatePicker
              id={props?.id ?? ""}
              inputVariant="outlined"
              value={selectedDate}
              onChange={(val) => handleDateChange(val)}
              autoOk={true}
              showTodayButton={true}
              format={props?.dateFormat}
              animateYearScrolling
              readOnly={props?.isReadonly}
              disabled={props?.disabled}
              minDate={minimunDate()}
              maxDate={maximumDate()}
              invalidDateMessage=""
            />
          </MuiPickersUtilsProvider>
        </Fragment>
        <svg
          class="MuiSvgIcon-root"
          viewBox="0 0 24 24"
          aria-hidden="true"
          role="presentation"
        >
          <path d="M17 12h-5v5h5v-5zM16 1v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-1V1h-2zm3 18H5V8h14v11z"></path>
          <path fill="none" d="M0 0h24v24H0z"></path>
        </svg>
      </div>
      {props?.errorValidation && props?.errorValidation?.error && (
        <div>
          <Typography variant="caption" className={classes.errorText}>
            {props?.errorValidation?.errorMessage}
          </Typography>
        </div>
      )}
    </div>
  );
};

DatePickers.propTypes = {
  // defaultValue: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  dateFormat: PropTypes.string,
  onChange: PropTypes.func,
  isReadonly: PropTypes.bool,
  disabled: PropTypes.bool,
};

DatePickers.defaultProps = {
  dateFormat: "DD-MM-YY",
};

export default DatePickers;
