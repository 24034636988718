import * as React from "react";
import PropTypes from "prop-types";

const SeniorCitizenIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14.547}
    height={22.022}
    {...props}
  >
    <defs>
      <clipPath id="a">
        <path
          data-name="Rectangle 51807"
          fill="#8895a5"
          d="M0 0h14.547v22.022H0z"
        />
      </clipPath>
    </defs>
    <g data-name="Group 95699">
      <g data-name="Group 95698" clipPath="url(#a)" fill="#8895a5">
        <path
          data-name="Path 95402"
          d="M7.798 8.117c-.522.933-1.072 1.831-1.525 2.776a1.912 1.912 0 0 0 .023 1.041 1.027 1.027 0 0 0 .324.47c2.4 2.389 1.981 5.471 2.064 8.424a1.057 1.057 0 0 1-1.039 1.164 1.108 1.108 0 0 1-1.27-1.086c-.2-1.381-.429-2.76-.548-4.148a2.088 2.088 0 0 0-.928-1.679c-.9-.642-1.763-1.342-2.624-2.04a2.35 2.35 0 0 1-.764-3.3A99.517 99.517 0 0 1 4.25 4.821a2.323 2.323 0 0 1 4.136-.24c1.532 1.981 3.1 3.943 4.47 6.036a7.266 7.266 0 0 1 .7 2.873q.494 3.428.856 6.875c.059.552.455 1.456-.441 1.567-.927.114-.64-.9-.792-1.425a16.6 16.6 0 0 1-.225-1.86c-.327-1.964-.294-4.111-1.13-5.834S9.263 9.761 7.798 8.117"
        />
        <path
          data-name="Path 95403"
          d="M13.327 2.245a2.317 2.317 0 1 1-4.631.141 2.317 2.317 0 0 1 4.631-.141"
        />
        <path
          data-name="Path 95404"
          d="M1.728 14.198c2.994 2.082 2.681 1.723 1.519 4.641-.3.757-.6 1.515-.913 2.268-.3.719-.83 1.114-1.59.813a1.182 1.182 0 0 1-.621-1.684c.225-.632.487-1.25.712-1.882a17.535 17.535 0 0 0 .665-2.048 18.68 18.68 0 0 0 .223-2.108"
        />
      </g>
    </g>
  </svg>
);

export default SeniorCitizenIcon;

SeniorCitizenIcon.defaultProps = {
  color: "#2a3c50",
};

SeniorCitizenIcon.propTypes = {
  color: PropTypes.string,
};
