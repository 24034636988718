export const PhonenumberValidation = (phonenumber) => {
  //
  const phoneNumberRegex = /^\d{3}\d{3}\d{3}$/;
  // const phoneNumberRegex =/^[0-9]{9}$/g;
  const drill = phonenumber?.answer?.answer?.number;
  const no = parseInt(drill);

  let phno = phoneNumberRegex.test(no);
  // console.log("phno",phno)
  // console.log("validation part",typeof no)
  return phno;
};
