import React from "react";
import Config from "../../config";
import { AxiosCall } from "../../api";
import OrderCarts from "./orderCarts";

const PharmaCart = (props) => {
  
  const { fromKitchen = false, selectedPatient, backToSearch, PatientDetails } = props;
  const [user, setUser] = React.useState({});
  // const [openTour, setOpentour] = React.useState(false);

  const [date, setDate] = React.useState(new Date());

  const [Count, setCount] = React.useState();
  const [countLimit, setcountLimit] = React.useState();

  React.useEffect(() => {
    getMealSchedule();
  }, []);

  const getMealSchedule = async (selectedDate) => {
    let dateNew = selectedDate ? selectedDate : new Date();
    setDate(dateNew);

    let id = localStorage.getItem("kclkid");
    let user = fromKitchen ? selectedPatient : await getPatientLoggedIn(id);
    let reporting_Org = await getReportingOrg(user?.orgType);
    setUser({
      PatientCode: user?._id ?? "",
      PatientBedCode: user?.orgType,
      ReportingOrganization:reporting_Org?.ReportingOrganization,
      Pharmacy:reporting_Org?.EntityName,
    });

    getCountLimit();
    if (!fromKitchen && user?.OrgID) {
      increaseLoginCount(user?.BedCode);
    }
  };

  const getCountLimit = async () => {
    let params = {
      db_name: Config.dbname,
      entity: "CodeableConceptMaster",
      sort: "document(CodeableConceptMaster.coding[0]).display",
      filter: "CodeableConceptMaster.Type=='LOGINCOUNT'",
      return_fields:
        "MERGE(CodeableConceptMaster,{coding:(FOR cod IN CodeableConceptMaster.coding RETURN DOCUMENT(cod))})",
    };
    let res = await AxiosCall(
      "post",
      `${Config.api_url}/api/read_documents`,
      params
    );
    let result = res?.result[0];

    setcountLimit(result?.coding[0]?.display);
  };

  const getPatientLoggedIn = async (id) => {
    let params = {
      db_name: Config.dbname,
      entity: "Person",
      filter: `Person.keycloackid=='${id}' && Person.activestatus==true`,
      return_fields:
        "{_id:Person._id,orgID:Person.OrgID[0].id,orgType:Person.orgType,firstname:document(Person.name[0]).text,lastname:document(Person.name[0]).given}",
    };

    let res = await AxiosCall(
      "post",
      `${Config.api_url}/api/read_documents`,
      params
    );
    let result = res?.result[0];

    // if (result?.Patient?.PatientFName) {
    //   sessionStorage.setItem("user", btoa(result?.Patient?.PatientFName));
    // }

    return result;
  };

  const getReportingOrg = async (id) => {
    let params = {
      "db_name": Config.dbname,
        "entity": "QDMATPtransactionlog",
        "filter": `QDMATPtransactionlog.ticketId==document('${id}').ticketId && QDMATPtransactionlog.activestatus==true`,
        "return_fields": "{ ReportingOrganization:QDMATPtransactionlog.payload.inputDoc.mappedData.ReportingOrganization, EntityName:QDMATPtransactionlog.payload.inputDoc.mappedData.EntityName}"
    };

    let res = await AxiosCall(
      "post",
      `${Config.api_url}/api/read_documents`,
      params
    );
    let result = res?.result[0];

    // if (result?.Patient?.PatientFName) {
    //   sessionStorage.setItem("user", btoa(result?.Patient?.PatientFName));
    // }

    return result;
  };

  const increaseLoginCount = async (id) => {
    let params = {
      db_name: Config.dbname,
      entity: "Bed",
      filter: `Bed._id=='${id}' && Bed.activestatus==true`,
      return_fields: "Bed",
    };

    let res = await AxiosCall(
      "post",
      `${Config.api_url}/api/read_documents`,
      params
    );

    let result = res?.result[0];

    let count = (result?.LoginCount ?? 0) + 1;
    setCount(count);
    let _d = { ...result, LoginCount: count };
    await updateBed(_d);
  };

  const updateBed = async (data) => {
    let config = [
      {
        db_name: Config.dbname,
        entity: "Bed",
        is_metadata: true,
        metadataId: process.env.REACT_APP_METADATAID,
        metadata_dbname: process.env.REACT_APP_MetadataDB_Name,
        filter: { _key: data?._key },
        doc: data,
      },
    ];

    let res = await AxiosCall(
      "post",
      process.env.REACT_APP_ARANGO_URL_UPSERT,
      config
    );

    return res;
  };
  // console.log("CartProps", props);


  return (
    <>
      <OrderCarts
        user={user}
        BedNumber={props.BedNumber ?? user?.BedNumber}
        BedCode={props.BedCode ?? user?.PatientBedCode}
        backToSearch={backToSearch}
        PatientDetails={PatientDetails}
      />
    </>
  );
};

export default PharmaCart ;
