import React from "react";
import { Cart } from "./cart";
import Config from "../../config";
import { useLocation } from "react-router";
import { AxiosCall } from "../../api";
import withAllContexts from "../../HOCs/withAllContexts";

const DrugOrdering = (props) => {
  const location = useLocation();
  
  const [drugList, setDrugList] = React.useState();
  const { fromKitchen = false, selectedPatient, backToSearch, PatientDetails } = props;
  const [user, setUser] = React.useState({});
  // const [openTour, setOpentour] = React.useState(false);

  const [date, setDate] = React.useState(new Date());

  const [Count, setCount] = React.useState();
  const [countLimit, setcountLimit] = React.useState();
  const [reorderData, setReorderData] = React.useState();

  React.useEffect(() => {
    getAllItem();
    getMealSchedule();
    if(location.state){
      setReorderData(location.state)
    }
  }, []);

  const getMealSchedule = async (selectedDate) => {
    let dateNew = selectedDate ? selectedDate : new Date();
    setDate(dateNew);

    let id = localStorage.getItem("kclkid");
    let user = fromKitchen ? selectedPatient : await getPatientLoggedIn(id);
    let reporting_Org = await getReportingOrg(user?.orgType);
    setUser({
      PatientCode: user?._id ?? "",
      // PatientBedCode: "Bed/10362",
      PatientBedCode: user?.orgType,
      ReportingOrganization:reporting_Org?.ReportingOrganization,
      Pharmacy:reporting_Org?.EntityName,
      // PatientCategory: fromKitchen
      //   ? user?.Patient?.PatientCategory
      //   : user?.Patient?.PatientCategory?._id ?? "",
      // PatientCategoryDisplay: user?.Patient?.PatientCategory?.display ?? "",
      // Disclaimer:
      //   user?.Patient?.PatientCategory?.gmconfigvalues?.Disclaimer ?? null,
    });

    // let getDay = dateNew.getDay();

    // let ConceptMaster = "DAY";

    // let day = WEEKDAYS[getDay];

    // let generalList = await dispatch(
    //   actions.IMPO_GENERAL_MASTER(ConceptMaster)
    // );

    // let _MasterDay = generalList?.payload?.data;

    // let findDayCode = _MasterDay.find((l) => l.coding?.display === day?.value);

    // let mealDay = findDayCode?.coding?._id;

    // await dispatch(
    //   actions.GET_PATIENT_MEAL({
    //     key: mealDay,
    //     by: fromKitchen
    //       ? user?.Patient?.PatientCategory
    //       : user?.Patient?.PatientCategory?._id,
    //     date: dateNew,
    //   })
    // );
    getCountLimit();
    if (!fromKitchen && user?.OrgID) {
      increaseLoginCount(user?.BedCode);
    }
  };

  const getCountLimit = async () => {
    let params = {
      db_name: Config.dbname,
      entity: "CodeableConceptMaster",
      sort: "document(CodeableConceptMaster.coding[0]).display",
      filter: "CodeableConceptMaster.Type=='LOGINCOUNT'",
      return_fields:
        "MERGE(CodeableConceptMaster,{coding:(FOR cod IN CodeableConceptMaster.coding RETURN DOCUMENT(cod))})",
    };
    let res = await AxiosCall(
      "post",
      `${Config.api_url}/api/read_documents`,
      params
    );
    let result = res?.result[0];

    setcountLimit(result?.coding[0]?.display);
  };

  const getPatientLoggedIn = async (id) => {
    let params = {
      db_name: Config.dbname,
      entity: "Person",
      filter: `Person.keycloackid=='${id}' && Person.activestatus==true`,
      return_fields:
        "{_id:Person._id,orgID:Person.OrgID[0].id,orgType:Person.orgType,firstname:document(Person.name[0]).text,lastname:document(Person.name[0]).given}",
    };

    let res = await AxiosCall(
      "post",
      `${Config.api_url}/api/read_documents`,
      params
    );
    let result = res?.result[0];

    // if (result?.Patient?.PatientFName) {
    //   sessionStorage.setItem("user", btoa(result?.Patient?.PatientFName));
    // }

    return result;
  };

  const getReportingOrg = async (id) => {
    let params = {
      "db_name": Config.dbname,
        "entity": "QDMATPtransactionlog",
        "filter": `QDMATPtransactionlog.ticketId==document('${id}').ticketId && QDMATPtransactionlog.activestatus==true`,
        "return_fields": "{ ReportingOrganization:QDMATPtransactionlog.payload.inputDoc.mappedData.ReportingOrganization, EntityName:QDMATPtransactionlog.payload.inputDoc.mappedData.EntityName}"
    };

    let res = await AxiosCall(
      "post",
      `${Config.api_url}/api/read_documents`,
      params
    );
    let result = res?.result[0];

    // if (result?.Patient?.PatientFName) {
    //   sessionStorage.setItem("user", btoa(result?.Patient?.PatientFName));
    // }

    return result;
  };

  const increaseLoginCount = async (id) => {
    let params = {
      db_name: Config.dbname,
      entity: "Bed",
      filter: `Bed._id=='${id}' && Bed.activestatus==true`,
      return_fields: "Bed",
    };

    let res = await AxiosCall(
      "post",
      `${Config.api_url}/api/read_documents`,
      params
    );

    let result = res?.result[0];

    let count = (result?.LoginCount ?? 0) + 1;
    setCount(count);
    let _d = { ...result, LoginCount: count };
    await updateBed(_d);
  };

  const updateBed = async (data) => {
    let config = [
      {
        db_name: Config.dbname,
        entity: "Bed",
        is_metadata: true,
        metadataId: process.env.REACT_APP_METADATAID,
        metadata_dbname: process.env.REACT_APP_MetadataDB_Name,
        filter: { _key: data?._key },
        doc: data,
      },
    ];

    let res = await AxiosCall(
      "post",
      process.env.REACT_APP_ARANGO_URL_UPSERT,
      config
    );

    return res;
  };
  // console.log("CartProps", props);

  const getAllItem = async () => {
    let params = {
      db_name: Config.dbname,
      queryid: "f37d2a0f-1409-4e3a-a8cf-5527c5b92b0e",
    };

    let res = await AxiosCall(
      "post",
      `${process.env.REACT_APP_ARANGO_URL_READ_QDMQUERIES}`,
      params
    );

    let result = res;
    setDrugList(result);
  };
  
  const setCartCount = (count) => {
    props?.cartCount?.setCartCount(count);
  };

  return (
    <>
      <Cart
        drugList={drugList}
        user={user}
        BedNumber={props.BedNumber ?? user?.BedNumber}
        BedCode={props.BedCode ?? user?.PatientBedCode}
        backToSearch={backToSearch}
        PatientDetails={PatientDetails}
        ReorderData = {reorderData}
        setCartCount = {setCartCount}
      />
    </>
  );
};

export default withAllContexts(DrugOrdering);
