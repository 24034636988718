import React from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Grid, 
  Chip,
  CardHeader, IconButton} from "@material-ui/core";
import OrderStepper from "./stepper";
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    cardcontent1: {
     padding:"1px",
    },
  }));
  
export const OrderCard = (props) => {
    const classes = useStyles();
  const steps = ["Order Placed", "Processing", "Shipped", "Delivered"];
  return (
    <div>
      <Card
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          margin: "20px",
          flexWrap: "wrap",
        }}
      >
        <CardContent className={classes.cardcontent1}
          style={{
            display: "flex",
            gap: "516px",
          }}
        >
          <Grid
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "flex-start",
              gap: "28px",
            }}
          >
            <CardHeader
              variant="body1"
              title="Order Date"
              subheader= {props.orderdate}
              titleTypographyProps={{
                variant: "h6",
                style: { fontSize: "16px" },
              }}
              subheaderTypographyProps={{
                variant: "subtitle1",
                style: { fontSize: "14px" },
              }}
            />
            <CardHeader
              variant="body1"
              title="Order Number"
              subheader={props.orderid}
              titleTypographyProps={{
                variant: "h6",
                style: { fontSize: "16px" },
              }}
              subheaderTypographyProps={{
                variant: "subtitle1",
                style: { fontSize: "14px" },
              }}
            />
            <CardHeader
              variant="body1"
              title= "Invoice Number"
              subheader={props.invoicenumber}
              titleTypographyProps={{
                variant: "h6",
                style: { fontSize: "16px" },
              }}
              subheaderTypographyProps={{
                variant: "subtitle1",
                style: { fontSize: "14px" },
              }}
            />
          </Grid>
          <Grid
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "flex-start",
              gap: "28px",
            }}
          >
            <CardHeader
              variant="body1"
              title= "Payment Status"
              subheader={props.paymentstatus || "Pending"}
              titleTypographyProps={{
                variant: "h6",
                style: { fontSize: "16px" },
              }}
              subheaderTypographyProps={{
                variant: "subtitle1",
                style: { fontSize: "14px", color: "#F24E1E" },
              }}
            />
            <CardHeader
              variant="body1"
              title= "Due Date"
              subheader={props.duedate}
              titleTypographyProps={{
                variant: "h6",
                style: { fontSize: "16px" },
              }}
              subheaderTypographyProps={{
                variant: "subtitle1",
                style: { fontSize: "14px" },
              }}
            />
          </Grid>
        </CardContent>
        <CardContent  className={classes.cardcontent1}
          style={{
            display: "flex",
            alignItems: "flex-start",
            gap: "516px",

          }}
        >
          <Grid style={{ display: 'flex', alignItems: 'center', gap: '25px' }}>
          <CardMedia
            component="img"
            alt="drug"
            height="140"
            src={props.productimage ? props.productimage : "https://images.unsplash.com/photo-1587854692152-cbe660dbde88?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=869&q=80"}
            style={{ width: "120px", height: "120px" , marginLeft: '10px'}}
          />
          
         
          <div  
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              gap: "8px",
              width: "340px"
            }}
          >
            <Typography
              variant="h6"
              style={{
                color: "#0C5DF9",
                fontFamily: "Poppins",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
              }}
            >
              {props.itemname}
            </Typography>
            <Typography
              variant="subtitle2"
              style={{
                color: "#7D7D7D",
                fontFamily: "Poppins",
              }}
            >
             {props.manufacturer}
            </Typography>
            <Typography
              variant="subtitle2"
              style={{
                fontFamily: "Poppins",
                color: "#353434",
              }}
            >
             Dispense Quantity: {props.dispensequantity}
            </Typography>
            <Typography
              variant="subtitle2"
              style={{
                fontFamily: "Poppins",
                color: "#353434",
                fontSize: "16px",
                fontWeight: 500
              }}
            >
              Price: {props.currency} {props.price}
            </Typography>
          </div>
          </Grid>
         
          <Chip
            label={props.chip}
            variant="default"
            size="large"
            style={{
              borderRadius: "4px",
              background: "#CDDEFF",
              fontSize: "18px",
              color: "#3354F4",
              width: "280px",
              height: "50px",
            }}
          />
         
          
        </CardContent>
        {/* <OrderStepper data={true} /> */}
      </Card>
    </div>
  );
};
