import {
  Button,
  CardContent,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import FilterListIcon from "@material-ui/icons/FilterList";
import SortByAlphaIcon from "@material-ui/icons/SortByAlpha";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import BusinessIcon from "@material-ui/icons/Business";

export const PharmaFilter = (props) => {
  const [isFavorite, setIsFavorite] = useState(false);
  const [filterDetail, setFilterDetail] = useState([]);
  const [data, setData] = useState([]);
  const [filterName, setFilterName] = useState([]);

  const [selectedValue, setSelectedValue] = useState(null);

  const handleChange = (event) => {
    const value = event.target.value;
    if (selectedValue !== value) {
      setSelectedValue(value);
    } else {
      setSelectedValue(null); // Uncheck both if same is clicked again
    }
  };

  const getFilterDetail = () => {
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    };
    const payload = { groupcode: "PRODUCTFILTER" };
    fetch(
      process.env.REACT_APP_BACKEND_API_URL +
        process.env.REACT_APP_PRODUCTFILTERDETAILS,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(payload),
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json(); // Assuming the response is JSON
        } else {
          throw new Error("Network response was not ok");
        }
      })
      .then((data) => {
        setFilterDetail(data?.response);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };
  React.useEffect(() => {
    getFilterDetail();
  }, []);
  // const handleFavoriteToggle = () => {
  //   setIsFavorite((prevIsFavorite) => !prevIsFavorite);
  // };

  const handleclick = (e, id, name) => {
    let f = data;
    let fn = filterName;

    if (e.target.checked) {
      f.push({ id: id, name: name });
    } else {
      let i = f.findIndex((i) => i.id === id);
      f.splice(i, 1);
    }

    if (f.filter((x) => x.name === name).length > 0) {
      if (fn.filter((x) => x === name).length === 0) {
        fn.push(name);
      }
    } else if (fn.filter((x) => x === name).length > 0) {
      let i = fn.findIndex((i) => i === name);
      fn.splice(i, 1);
    }
    setFilterName(fn);
    setData(f);
    var result = data
      .filter((x) => x.name === filterName[0])
      .map(function (item) {
        return item.id;
      });
    console.log("data", data, "filterName", filterName, "result", result);
  };
  const applyFilter = () => {
    const filterArray = [];
    filterName.map(function (name) {
      let v = data
        .filter((x) => x.name === name)
        .map(function (item) {
          return item.id;
        });
      filterArray.push({
        filtercolumnname: name,
        filtervalue: v,
      });
    });
    let sortingvalue = "";
    if (selectedValue === "AtoZ") {
      sortingvalue = "ASC";
    } else if (selectedValue === "ZtoA") {
      sortingvalue = "DESC";
    } else {
      sortingvalue = "";
    }
    props.getItem( {
      filterarray: filterArray,
      sortingvalue: sortingvalue,
    });
  };

  return (
    <Paper
      style={{
        margin: "15px",
        borderRadius: "15px",
        backgroundColor: "white",
        boxShadow: "rgba(151, 151, 151, 0.25) 2px 2px 8px 0px",
      }}
    >
      <CardContent
        style={{
          display: "inline-flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "5px",
          fontSize: "15px",
          width:"100%"
        }}
      >
        <Grid
          style={{
            color: "#0866B9",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            width:"100%"
          }}
        >
          <Grid 
          style={{
            color: "#0866B9",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            gap: "12px",
            width: "50%"
          }}
          >
            {" "}
            <FilterListIcon style={{ marginTop: "7px" }} />
            <Typography
              variant="body1"
              style={{ width: "100px", marginTop: "7px" }}
            >
              Sort By
            </Typography>
          </Grid>
          
          <Grid 
          style={{
            width: "50%", display: 'flex', justifyContent: 'right'
          }}
          >
            <Button
              variant="outlined"
              color="primary"
              style={{ marginRight: "5px" }}
              onClick={applyFilter}
            >
              Apply
            </Button>
          </Grid>
        </Grid>
      </CardContent>
      <CardContent style={{ overflowY: "auto" }}>
        <Grid
          style={{
            display: "flex",
            alignItems: "flex-start",
            gap: "10px",
            flexDirection: "row",
          }}
        >
          <SortByAlphaIcon />
          <Typography
            variant="subtitle2"
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            Alphabetically
          </Typography>
        </Grid>
        <Grid container direction="column" style={{ marginLeft: "8px" }}>
          <FormControlLabel
            value="AtoZ"
            control={
              <Checkbox
                color="primary"
                onChange={handleChange}
                checked={selectedValue === "AtoZ"}
              />
            }
            label="A - Z"
            labelPlacement="end"
          />
          <FormControlLabel
            value="ZtoA"
            control={
              <Checkbox
                color="primary"
                onChange={handleChange}
                checked={selectedValue === "ZtoA"}
              />
            }
            label="Z - A"
            labelPlacement="end"
          />
        </Grid>

        <Grid style={{ height: "10px" }}></Grid>

        <Grid style={{ height: "5px" }}></Grid>
        <Grid>
          {filterDetail &&
            filterDetail?.map((l, index) => {
              return (
                <>
                  <Grid
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      gap: "10px",
                      flexDirection: "row",
                    }}
                  >
                    {l.icon == "" ? (
                      <BusinessIcon />
                    ) : (
                      <img
                        src="https://toppng.com/uploads/preview/google-forms-icon-free-and-vector-form-icon-11553444694xhldfmtngo.png"
                        style={{ height: "20px", width: "20px" }}
                      />
                    )}
                    {/* <LocalOfferRoundedIcon style={{ color: "#FE801D" }} /> */}
                    <Typography
                      variant="subtitle2"
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        marginBottom: "10px",
                      }}
                    >
                      {l.caption}
                    </Typography>
                  </Grid>
                  {l.items.map((itm, i) => {
                    return (
                      <Grid
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginBottom: "10px",
                        }}
                      >
                        <FormControlLabel
                          style={{ margin: "2px" }}
                          control={
                            <Checkbox
                              value={itm._id}
                              onClick={(e) =>
                                handleclick(e, itm._id, l.filtercolname)
                              }
                              color="primary"
                              style={{
                                padding: "1px",
                              }}
                            />
                          }
                        />
                        <Typography
                          variant="subtitle1"
                          style={{ color: "#787878" }}
                        >
                          {itm.display}
                        </Typography>
                      </Grid>
                    );
                  })}
                  <Grid style={{ height: "5px" }}></Grid>
                </>
              );
            })}
        </Grid>
        <Grid style={{ height: "100px" }}></Grid>
        {/* <Grid style={{ height: "5px" }}></Grid>
        <Grid
          style={{
            display: "flex",
            alignItems: "flex-start",
            gap: "10px",
            flexDirection: "row",
          }}
        >
          <RateReviewOutlinedIcon />
          <Typography
            variant="subtitle2"
            style={{ fontSize: "16px", fontWeight: "bold" }}
          >
            Customer Review
          </Typography>
        </Grid>
        <Grid style={{ display: "flex", flexDirection: "row" }}>
          <FormControlLabel
            style={{ margin: "2px" }}
            control={
              <Checkbox
                color="primary"
                style={{
                  padding: "1px",
                }}
              />
            }
          />
          <StarIcon style={{ color: " #F3C418" }} />
          <StarIcon style={{ color: " #F3C418" }} />
          <StarIcon style={{ color: " #F3C418" }} />
          <StarIcon style={{ color: " #F3C418" }} />
          <StarIcon style={{ color: " #F3C418" }} />
        </Grid>
        <Grid style={{ display: "flex", flexDirection: "row" }}>
          <FormControlLabel
            style={{ margin: "2px" }}
            control={
              <Checkbox
                color="primary"
                style={{
                  padding: "1px",
                }}
              />
            }
          />
          <StarIcon style={{ color: " #F3C418" }} />
          <StarIcon style={{ color: " #F3C418" }} />
          <StarIcon style={{ color: " #F3C418" }} />
          <StarIcon style={{ color: " #F3C418" }} />
          <StarBorderOutlinedIcon style={{ color: " #F3C418" }} />
        </Grid>
        <Grid style={{ display: "flex", flexDirection: "row" }}>
          <FormControlLabel
            style={{ margin: "2px" }}
            control={
              <Checkbox
                color="primary"
                style={{
                  padding: "1px",
                }}
              />
            }
          />
          <StarIcon style={{ color: " #F3C418" }} />
          <StarIcon style={{ color: " #F3C418" }} />
          <StarIcon style={{ color: " #F3C418" }} />
          <StarBorderOutlinedIcon style={{ color: " #F3C418" }} />
          <StarBorderOutlinedIcon style={{ color: " #F3C418" }} />
        </Grid>
        <Grid style={{ display: "flex", flexDirection: "row" }}>
          <FormControlLabel
            style={{ margin: "2px" }}
            control={
              <Checkbox
                color="primary"
                style={{
                  padding: "1px",
                }}
              />
            }
          />
          <StarIcon style={{ color: " #F3C418" }} />
          <StarIcon style={{ color: " #F3C418" }} />
          <StarBorderOutlinedIcon style={{ color: " #F3C418" }} />
          <StarBorderOutlinedIcon style={{ color: " #F3C418" }} />
          <StarBorderOutlinedIcon style={{ color: " #F3C418" }} />
        </Grid>
        <Grid style={{ display: "flex", flexDirection: "row" }}>
          <FormControlLabel
            style={{ margin: "2px" }}
            control={
              <Checkbox
                color="primary"
                style={{
                  padding: "1px",
                }}
              />
            }
          />
          <StarIcon style={{ color: " #F3C418" }} />
          <StarBorderOutlinedIcon style={{ color: " #F3C418" }} />
          <StarBorderOutlinedIcon style={{ color: " #F3C418" }} />
          <StarBorderOutlinedIcon style={{ color: " #F3C418" }} />
          <StarBorderOutlinedIcon style={{ color: " #F3C418" }} />
        </Grid> */}
      </CardContent>
    </Paper>
  );
};
export default PharmaFilter;
