import axios from "axios";
import { ToastMessage } from "../components";

//using axios call
export const AxiosCall = async (method, url, params, header) => {
  if (!navigator.onLine) {
    return Promise.reject(
      ToastMessage("Unable to connect with internet", "warning", "top-center")
    );
  }

  try {
    let config = {
      method: method,
      url: `${url}`,
      headers: {
        "Content-Type": "application/json",
        ...(header && { ...header }),
      },
      data: params,
    };
    let response = await axios(config);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const AxiosArangoCall = async (method, url, params) => {
  if (!navigator.onLine) {
    return Promise.reject(
      ToastMessage("Unable to connect with internet", "warning", "top-center")
    );
  }

  try {
    let config = {
      method: method,
      url: `${url}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: params,
    };
    let response = await axios(config);
    let arangoRes = ArangoErroHandler(response.data);
    return arangoRes;
  } catch (error) {
    return error;
  }
};

export const ArangoErroHandler = (res) => {
  if (res?.Code === "201" || res?.code === "201") {
    return res;
  } else if (res?.Code === "1111" || res?.code === "1111") {
    let type = "error";
    let errMesg = res?.validation_error
      ? res?.validation_error[0]?.Errormsg
      : res?.error;

    ToastMessage(errMesg, type, "top-center");
    return { ...res, result: [] };
  } else {
    let errMesg = res?.error;
    ToastMessage(errMesg, "error", "top-center");
    return { ...res, result: [] };
  }
};
