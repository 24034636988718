import React from "react";
import {
  makeStyles,
  Grid,
  Typography,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import GetAppIcon from "@material-ui/icons/GetApp";
import { PageEmpty } from "../pageEmpty";
const useStyles = makeStyles((theme) => ({
  rootContainer: {
    position: "relative",
    minHeight: "100%",
    backgroundColor: theme?.palette?.background?.paper,
    margin: 0,
  },
  root: {
    padding: theme?.spacing(3),
    paddingTop: theme?.spacing(2),
  },
  footer: {
    position: "absolute",
    bottom: 0,
    width: "100%",
  },
  content: {
    display: "inline-flex",
    width: "100%",
  },
  cardContainer: {
    backgroundColor: theme?.palette?.background?.paper,
    marginTop: theme?.spacing(2),
    boxShadow: "0px 10px 15px #00000014",
    border: "2px solid #E2E2E2",
    borderRadius: "8px",
  },
  Container: {
    // backgroundColor: theme.palette.background.paper,
    marginTop: theme?.spacing(0.5),
    marginBottom: theme?.spacing(0.5),
    // boxShadow: "0px 10px 15px #00000014",
    border: "1px solid #E2E2E2",
    borderRadius: "8px",
    padding: "2px",
  },
  circularLoader: {
    paddingRight: "8px",
    position: "relative",
    "& .MuiCircularProgress-root": {
      position: "relative",
    },
  },
  loadCenter: {
    position: "absolute",
    left: 14,
  },
  uploadText: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  errorText: {
    color: "red",
    fontSize: "0.75rem",
  },
}));
const AttachementFileList = (props) => {
  const classes = useStyles();
  return (
    <>
      {props.list ? (
        props.list.map((item, key) => (
          <Grid
            container
            direction="row"
            alignItems="center"
            className={classes.Container}
          >
            <Grid item xs={2}>
              <DescriptionOutlinedIcon style={{ marginLeft: "3px" }} />
            </Grid>

            <Grid
              item
              xs={props?.isReadonly ? 10 : 8}
              className={classes.uploadText}
            >
              <Tooltip title={item?.name}>
                <Typography variant="subtitle2" color="textPrimary">
                  {item?.name}
                </Typography>
              </Tooltip>
            </Grid>

            <Grid item xs={2}>
              <IconButton
                id={`${"downloadBtn" + key}`}
                onClick={() => window.open(item.file)}
              >
                <GetAppIcon style={{ color: "green" }} />
              </IconButton>
            </Grid>
          </Grid>
        ))
      ) : (
        <div style={{ margin: "2em 7em" }}>
          <PageEmpty />
        </div>
      )}
    </>
  );
};

export default AttachementFileList;
