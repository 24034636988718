import React from "react";
import FormRendererParent from "../formRenderer";

function Formreturn(props) {
  // console.log("FormProps", props);
  const [ticket_Id, setTicketId] = React.useState("");
  const [entry_id, setEntryId] = React.useState("");

  React.useEffect(() => {
    setTicketId(props.ticketId);
    setEntryId(props.entryId);
  }, []);

  return (
    <FormRendererParent
      onSaveForm={props.onSaveForm}
      secName={props.secName}
      title={props.title}
      secToShow={props.secToShow}
      form_detail={props.form_detail}
      entryId={entry_id}
      ticketId={ticket_Id}
      isReadyOnly={props.isReadyOnly}
      isEdit={props.isEdit}
      // isEdit={true}

      // forms={this.state?.formRendering?.formData?.form}
      // form_detail={formRendering?.formData}
      state={props.state}
    />
  );
}

export default Formreturn;
