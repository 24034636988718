import React from "react";
// import { TaskViewConfig } from "task_viewer_config";

const TaskViewerConfiguration = () => {
  return (
    // <TaskViewConfig
    //   dbname={process.env.REACT_APP_DB}
    //   metadatadbname={process.env.REACT_APP_MetadataDB_Name}
    // />
    <></>
  );
};

export default TaskViewerConfiguration;
