import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Grid,
  FormControlLabel,
  Checkbox,
  TextField,
  // Button,
  Typography,
  Chip,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import { DrawerComponent } from "../../componentsV2";
import { ComponentType } from "../../utils";
import ComponentUiPreview from "./componentUIPreview";
import QuestionProperties from "./questionProperties";

import {
  // DependencyTable,
  CustomTable,
  CustomTableFixedRow,
} from "../../components";

import Autocomplete from "@material-ui/lab/Autocomplete";
import VisibilityRoundedIcon from "@material-ui/icons/VisibilityRounded";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import FlipToFrontIcon from "@material-ui/icons/FlipToFront";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";

// for drag and drop
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import copy from "fast-copy";

//store
import { useDispatch, useSelector } from "react-redux";
const fileTypes = [
  {
    label: "JPEG",
    type: "image/jpeg",
  },
  { label: "GIF", type: "image/gif" },
  {
    label: "PNG",
    type: "image/png",
  },
  {
    label: "PDF",
    type: "application/pdf",
  },
  {
    label: "IMAGE",
    type: "image/*",
  },
  {
    label: "VIDEO",
    type: "video/*",
  },
  {
    label: "AUDIO",
    type: "audio/*",
  },
  {
    label: "HTML",
    type: "text/html",
  },
  {
    label: "CSV",
    type: ".csv",
  },
];
const useStyles = makeStyles((theme) => ({
  formFields: {
    paddingBottom: "20px",
    border: "1px solid rgb(224, 224, 224)",
    background: "rgb(245, 245, 245)",
  },
  inputFiled: {
    marginBottom: "16px",
  },
  selectComponentList: {
    marginTop: 0,
    color: "red",
  },
  addFloatingButton: {
    position: "absolute",
    right: "26px",
    bottom: "30px",
  },
  embtyMesg: {
    textAlign: "center",
    padding: "20px 10px",
  },
  optionNote: {
    textAlign: "end",
    fontSize: "12px",
    paddingRight: "6px",
  },
  previewIcon: {
    marginLeft: 4,
    marginTop: 4,
    cursor: "pointer",
  },
  root: {
    padding: 12,
  },
  paperRoot: {
    padding: "8px 16px 16px 16px",
    border: "1px solid #E0E0E0",
  },
  header: {
    display: "flex",
  },
  slNo: {
    marginRight: 8,
  },
  componentList: {
    width: 300,
  },
  headerRoot: {
    marginBottom: 16,
  },
  addDependency: {
    marginTop: 12,
  },
}));

const componentsNote = {
  range_picker: "Provide Range (Eg. 0, 10)",
};

export default function FormLayer(props) {
  const classes = useStyles();

  // ComponentUIPreview
  const initalState = {
    anchorEl: null,
    data: null,
  };

  const [formComponentList, setFormComponentList] = useState(null);
  const [state, setState] = useState(initalState);
  const [drawerOpen, setDrawerOpen] = useState({
    open: false,
    index: null,
  });
  const open = Boolean(state.anchorEl);

  // Declaring Store dispatch
  const dispatch = useDispatch();
  const allComponents = useSelector(
    (state) =>
      state?.formSlice?.getFormComponents ?? {
        loading: false,
        data: [],
        error: false,
      }
  );

  React.useLayoutEffect(() => {
    const initialDataFetch = async () => {
      let skipElement = [
        "bmi_input",
        "bp_input",
        // "file_upload",
        "Image_upload",
        "Image_only_upload",
        "popup_switch",
        "location_level",
        "custom_table_2",
        "location",
        "custom_table",
        "temperature_input",
      ];
      const data = allComponents?.data?.filter(
        (val) => skipElement.indexOf(val.value) === -1
      );
      setFormComponentList(data);
    };
    initialDataFetch();
  }, [dispatch, props.id, allComponents]);

  const handlePopoverOpen = (event, data) => {
    setState({ ...state, anchorEl: event.currentTarget, data: data });
  };

  const handlePopoverClose = () => {
    setState(initalState);
  };
  const toggleDrawer = async (val, index) => {
    try {
      // if (val) {
      //   await props.getSectionComponents(val);
      // }
      setDrawerOpen({
        open: val,
        index: index ?? null,
      });
    } catch (err) {
      // console.log("err:", err);
    }
  };

  const giveMeQuestionProps = (data) => {
    const newData = copy(data);
    return newData;
  };
  const fileListData = (val) => {
    // console.log(val);
    return fileTypes
      .filter(
        (type) => val?.options.filter((e) => type.type === e).length !== 0
      )
      .reduce((acc, curr) => (acc = [...acc, curr.label]), []);
  };

  return (
    <>
      <DragDropContext
        onDragEnd={(params) => {
          const srcI = params?.source?.index;
          const descI = params?.destination?.index;
          props?.fromData?.splice(
            descI,
            0,
            props?.fromData?.splice(srcI, 1)[0]
          );
        }}
      >
        <Droppable droppableId="droppable-1">
          {(provided, _) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {props?.fromData?.length > 0 ? (
                props?.fromData?.map((val, index) => {
                  if (val.component === "popup_switch") {
                    return false;
                  }
                  return (
                    <Draggable
                      key={val.priority}
                      draggableId={"draggable-" + val.priority}
                      index={index}
                    >
                      {(provided, _) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          key={val.priority}
                        >
                          <Grid
                            key={index}
                            style={{ padding: "20px 20px 0px 20px" }}
                          >
                            <Paper
                              style={{
                                padding: "8px 20px 3px 20px",
                                border: "1px solid #E0E0E0",
                              }}
                              elevation={0}
                            >
                              <Grid
                                container
                                direction="row"
                                justify="space-between"
                                alignItems="center"
                                style={{ marginBottom: "20px" }}
                              >
                                <Grid item style={{ display: "flex" }}>
                                  <Grid style={{ minWidth: "26px" }}>
                                    <Typography
                                      component={"span"}
                                      color={"textPrimary"}
                                    >
                                      {index + 1 - props.indexOfno}
                                      {"."}
                                    </Typography>
                                  </Grid>

                                  <Autocomplete
                                    className={classes.componentList}
                                    onChange={(e, newValue) =>
                                      props.onChangeComponents(
                                        newValue?.value,
                                        index,
                                        "component"
                                      )
                                    }
                                    value={
                                      formComponentList?.filter(
                                        (getvalue) =>
                                          getvalue.value === val?.component
                                      )?.[0] ?? null
                                    }
                                    options={formComponentList}
                                    getOptionLabel={(option) => option.label}
                                    id="clear-on-escape"
                                    clearOnEscape
                                    renderInput={(params) => (
                                      <TextField
                                        error={
                                          val?.error?.componentError ?? false
                                        }
                                        {...params}
                                        className={classes.selectComponentList}
                                        size="small"
                                        placeholder={"Select Question type"}
                                        fullWidth
                                        margin={"dense"}
                                      />
                                    )}
                                  />

                                  <VisibilityRoundedIcon
                                    className={classes.previewIcon}
                                    color={"action"}
                                    fontSize={"small"}
                                    aria-owns={
                                      open ? "mouse-over-popover" : undefined
                                    }
                                    aria-haspopup="true"
                                    onMouseEnter={(e) =>
                                      handlePopoverOpen(e, val)
                                    } //handlePopoverOpen(e, val)
                                    onMouseLeave={handlePopoverClose} //handlePopoverClose
                                  />
                                </Grid>

                                <Grid
                                  item
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={val?.required ?? false}
                                        onChange={(e) =>
                                          props.onChangeComponents(
                                            !val?.required ?? false,
                                            index,
                                            "required"
                                          )
                                        }
                                        name="checkedA"
                                      />
                                    }
                                    label="Required"
                                  />
                                  <Tooltip
                                    title="Delete Question"
                                    placement="top"
                                  >
                                    <IconButton
                                      onClick={() =>
                                        props.delectQuestion(index)
                                      }
                                    >
                                      <DeleteOutlineOutlinedIcon color="error" />
                                    </IconButton>
                                  </Tooltip>
                                  <Grid
                                    {...provided.dragHandleProps}
                                    style={{
                                      display: "flex",
                                      padding: 12,
                                      alignItems: "center",
                                    }}
                                  >
                                    <Tooltip title="Drag Here" placement="top">
                                      <FlipToFrontIcon />
                                    </Tooltip>
                                  </Grid>
                                  <IconButton
                                    onClick={() => toggleDrawer(true, index)}
                                  >
                                    {/* <IconButton onClick={()=>openDrawer(index)}> */}
                                    <SettingsOutlinedIcon />
                                  </IconButton>
                                </Grid>
                              </Grid>
                              <Grid item container direction="row" spacing={2}>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={6}
                                  lg={6}
                                  xl={6}
                                  className={classes.inputFiled}
                                >
                                  <TextField
                                    value={val?.label ?? ""}
                                    onChange={(e) =>
                                      props.onChangeComponents(
                                        e.target.value,
                                        index,
                                        "label"
                                      )
                                    }
                                    fullWidth
                                    label="Question / Title"
                                    id="question"
                                    variant="outlined"
                                    size="small"
                                    error={val?.error?.labelError ?? false}
                                    helperText={
                                      val?.error?.labelError
                                        ? "Please give the question (must be more then two character)"
                                        : ""
                                    }
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={6}
                                  lg={6}
                                  xl={6}
                                  className={classes.inputFiled}
                                >
                                  <TextField
                                    value={val?.tag ?? ""}
                                    onChange={(e) =>
                                      props.onChangeComponents(
                                        e.target.value,
                                        index,
                                        "tag"
                                      )
                                    }
                                    fullWidth
                                    label="#Tag"
                                    id="tag"
                                    variant="outlined"
                                    size="small"
                                    error={val?.error?.tagError ?? false}
                                    helperText={
                                      val?.error?.tagError
                                        ? "Please give the tag (must be more then two character)"
                                        : ""
                                    }
                                  />
                                </Grid>
                              </Grid>
                              {drawerOpen.open &&
                                index === drawerOpen.index && (
                                  <DrawerComponent
                                    open={
                                      drawerOpen.open &&
                                      index === drawerOpen.index
                                    }
                                    isLarge
                                    forQuestion={true}
                                    onClose={() => toggleDrawer(false)}
                                    onSave={props?.getSectionComponents}
                                  >
                                    <div>
                                      <QuestionProperties
                                        dependency={val?.dependency}
                                        data={giveMeQuestionProps(val)}
                                        options={val?.options}
                                        parentSection={props.parentSection}
                                        selectedSection={props.selectedSection}
                                        fromData={props?.fromData ?? []}
                                        addDeleteComponents={
                                          props?.addDeleteComponents
                                        }
                                        updateDependency={
                                          props?.updateDependency
                                        }
                                        index={index}
                                        onChangeComponents={
                                          props?.onChangeComponents
                                        }
                                      />
                                    </div>
                                  </DrawerComponent>
                                )}
                              {ComponentType(val?.component ?? "") && (
                                <Grid item className={classes.inputFiled}>
                                  <Typography
                                    color={"textSecondary"}
                                    className={classes.optionNote}
                                  >
                                    {componentsNote[val?.component] ??
                                      "UI - Separated by a enter / use multiple tags"}
                                  </Typography>

                                  <Autocomplete
                                    multiple
                                    id="tags-outlined"
                                    size={"small"}
                                    options={[]}
                                    value={val?.options}
                                    freeSolo
                                    onChange={(e, newValue) =>
                                      props?.onChangeComponents(
                                        newValue,
                                        index,
                                        "options"
                                      )
                                    }
                                    renderTags={(value, getTagProps) =>
                                      value?.map((option, index) => (
                                        <Chip
                                          variant="outlined"
                                          label={option}
                                          {...getTagProps({ index })}
                                        />
                                      ))
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Options"
                                        placeholder="Options"
                                        error={val?.error?.optionError ?? false}
                                        helperText={
                                          val?.error?.optionError
                                            ? "Please add minimum two options required"
                                            : ""
                                        }
                                      />
                                    )}
                                  />
                                </Grid>
                              )}
                              {val?.component === "multi_select_tag" && (
                                <Grid container>
                                  <Grid
                                    md={6}
                                    xs={6}
                                    sm={6}
                                    style={{
                                      paddingBottom: 15,
                                      paddingTop: 10,
                                    }}
                                  >
                                    <TextField
                                      fullWidth
                                      type={"number"}
                                      label="Max Selection"
                                      variant="outlined"
                                      size="small"
                                      value={val?.validation?.max_option}
                                      onChange={(e) => {
                                        let validation = val.validation
                                          ? val.validation
                                          : {};
                                        props?.onChangeComponents(
                                          {
                                            ...validation,
                                            max_option: e.target.value,
                                          },
                                          index,
                                          "validation"
                                        );
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              )}
                              {val?.component === "file_upload" && (
                                <Grid container>
                                  <Grid
                                    md={6}
                                    xs={6}
                                    sm={6}
                                    style={{
                                      paddingBottom: 15,
                                      paddingTop: 10,
                                    }}
                                  >
                                    {/* {console.log("valss", val)} */}
                                    {/* <Autocomplete
                                      className={classes.componentList}
                                      onChange={(e, newValue) =>
                                        props.onChangeComponents(
                                          newValue?.type,
                                          index,
                                          "options"
                                        )
                                      }
                                      value={
                                        fileTypes.find(
                                          (file) => val?.options === file?.type
                                        ) ?? ""
                                        // val?.options
                                      }
                                      options={fileTypes}
                                      getOptionLabel={(option) => option.label}
                                      id="clear-on-escape"
                                      clearOnEscape
                                      renderInput={(params) => (
                                        <TextField
                                          error={
                                            val?.error?.componentError ?? false
                                          }
                                          {...params}
                                          className={
                                            classes.selectComponentList
                                          }
                                          size="small"
                                          placeholder={"Type of file"}
                                          fullWidth
                                          margin={"dense"}
                                        />
                                      )}
                                    /> */}
                                    <Autocomplete
                                      multiple
                                      id="tags-standard"
                                      onChange={(e, newValue) => {
                                        props.onChangeComponents(
                                          newValue.reduce((acc, curr) => {
                                            return (acc = [
                                              ...acc,
                                              curr.type
                                                ? curr.type
                                                : fileTypes.find(
                                                  (file) =>
                                                    file.label === curr
                                                ).type,
                                            ]);
                                          }, []),
                                          index,
                                          "options"
                                        );
                                      }}
                                      value={fileListData(val)}
                                      options={fileTypes}
                                      getOptionLabel={(option) => option?.label}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          variant="standard"
                                          label="Multiple values"
                                          placeholder="Favorites"
                                        />
                                      )}
                                      renderTags={(value, getTagProps) =>
                                        value?.map((option, index) => (
                                          <Chip
                                            variant="outlined"
                                            label={option}
                                            {...getTagProps({ index })}
                                          />
                                        ))
                                      }
                                    />
                                  </Grid>
                                </Grid>
                              )}
                              {val?.component === "label" && (
                                <Grid container>
                                  <Grid
                                    md={6}
                                    xs={6}
                                    sm={6}
                                    style={{
                                      paddingBottom: 15,
                                      paddingTop: 10,
                                    }}
                                  >
                                    <Autocomplete
                                      multiple
                                      id="tags-standard"
                                      options={[]}
                                      value={val?.options}
                                      freeSolo
                                      onChange={(e, newValue) =>
                                        props?.onChangeComponents(
                                          newValue,
                                          index,
                                          "options"
                                        )
                                      }
                                      getOptionLabel={(option) => option?.label}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          variant="standard"
                                          label="Multiple values"
                                          placeholder="Favorites"
                                        />
                                      )}
                                      renderTags={(value, getTagProps) =>
                                        value?.map((option, index) => (
                                          <Chip
                                            variant="outlined"
                                            label={option}
                                            {...getTagProps({ index })}
                                          />
                                        ))
                                      }
                                    />
                                  </Grid>
                                </Grid>
                              )}
                              {val?.component === "custom_table" && (
                                <CustomTable
                                  data={val}
                                  onChangeComponents={(value) =>
                                    props?.onChangeComponents(
                                      value,
                                      index,
                                      "tableScheme"
                                    )
                                  }
                                />
                              )}
                              {val?.component === "custom_table_2" && (
                                <CustomTableFixedRow
                                  data={val}
                                  onChangeComponents={(value) =>
                                    props?.onChangeComponents(
                                      value,
                                      index,
                                      "tableScheme"
                                    )
                                  }
                                />
                              )}
                              {val?.component ===
                                "select_modal_dropdown_qdm" && (
                                <Grid container>
                                  <Grid
                                    md={6}
                                    xs={6}
                                    sm={6}
                                    style={{
                                      paddingBottom: 15,
                                      paddingTop: 10,
                                    }}
                                  >
                                    {/* {console.log("val", val)} */}
                                    <TextField
                                      fullWidth
                                      type={"text"}
                                      label="Query Id"
                                      variant="outlined"
                                      size="small"
                                      value={val?.QueryId}
                                      onChange={(e) =>
                                        props.onChangeComponents(
                                          e.target.value,
                                          index,
                                          "QueryId"
                                        )
                                      }
                                      error={val?.error?.queryError ?? false}
                                      helperText={
                                        val?.error?.queryError
                                          ? "Query Id Is Required"
                                          : ""
                                      }
                                    />
                                  </Grid>
                                </Grid>
                              )}
                            </Paper>
                          </Grid>
                        </div>
                      )}
                    </Draggable>
                  );
                })
              ) : (
                <Typography
                  className={classes.embtyMesg}
                  color={"textSecondary"}
                >
                  You are yet to add a Question to this form
                </Typography>
              )}
              <ComponentUiPreview
                open={open}
                data={state?.data}
                anchorEl={state?.anchorEl}
                handlePopoverClose={handlePopoverClose}
              />
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
}
