import * as React from "react";
import PropTypes from "prop-types";

const Tick = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g
      id="Group_95386"
      data-name="Group 95386"
      transform="translate(-942 -123.5)"
    >
      <circle
        id="Ellipse_129929"
        data-name="Ellipse 129929"
        cx="12"
        cy="12"
        r="12"
        transform="translate(942 123.5)"
        fill="#2a60bc"
      />
      <path
        id="icons8-double-tick"
        d="M11.835,10.985a.416.416,0,0,0-.292.147L6.68,16.606l-.592.667-.525.59L2.731,14.814a.407.407,0,0,0-.606,0,.487.487,0,0,0,0,.652l3.142,3.383a.407.407,0,0,0,.612-.007l.814-.917.592-.667,4.876-5.489a.488.488,0,0,0,.086-.513A.426.426,0,0,0,11.835,10.985Z"
        transform="translate(947 121.016)"
        fill="#fff"
        stroke="#fff"
        stroke-width="1"
      />
    </g>
  </svg>
);

export default Tick;

Tick.defaultProps = {
  color: "#2a3c50",
};

Tick.propTypes = {
  color: PropTypes.string,
};
