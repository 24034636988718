import { Box, useTheme, Typography } from "@material-ui/core";
import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart, registerables, ArcElement, Tooltip, Legend } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

Chart.register(...registerables, ArcElement, Tooltip, Legend, ChartDataLabels);

const BarChart = (props) => {
  const { dataSet = {} } = props;
  const theme = useTheme();
  const [state, setState] = React.useState(dataSet);

  React.useEffect(() => {
    if (dataSet) {
      // console.log(dataSet);
      setState(dataSet);
    }
  }, [dataSet]);

  const options = {
    animation: false,
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: false,
    responsive: true,
    xAxes: [
      {
        ticks: {
          fontColor: theme.palette.text.secondary,
        },
        gridLines: {
          display: false,
          drawBorder: false,
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          fontColor: theme.palette.text.secondary,
          beginAtZero: true,
          min: 0,
        },
        gridLines: {
          borderDash: [2],
          borderDashOffset: [2],
          color: theme.palette.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: theme.palette.divider,
        },
      },
    ],
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: "index",
      titleFontColor: theme.palette.text.primary,
    },
    plugins: {
      datalabels: {
        display: true,
        color: "#000000",
      },
      labels: {
        title: {
          font: {
            weight: "bold",
          },
        },
        value: {
          color: "green",
        },
      },
    },
  };
  return (
    <Box
      sx={{
        height: "350px",
        position: "relative",
      }}
    >
      {/* {Object.keys(dataSet).length > 0 ? (
        <Bar data={state} options={options} />
      ) : (
        <div>
          <Typography>"No Record Found"</Typography>
        </div>
      )} */}
      <Bar data={state} options={options} />
    </Box>
  );
};

export default BarChart;
