import * as React from "react";
import PropTypes from "prop-types";

const GroceryShelfIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={14} height={13.3} {...props}>
    <path
      d="M2.8 0a.7.7 0 0 0-.7.7v1.265l-1.4 3.5V10.5H0v1.4h1.4v1.4h1.4v-1.4h8.4v1.4h1.4v-1.4H14v-1.4h-.7V4.9H9.1v2.8h-.7v2.8H7V5.465l-1.4-3.5V.7a.7.7 0 0 0-.7-.7Zm.474 2.8h1.153L5.6 5.735V10.5H2.1V5.735ZM10.5 6.3h1.4v1.4h-1.4Zm-.7 2.8h2.1v1.4H9.8Z"
      fill={props.color}
    />
  </svg>
);

export default GroceryShelfIcon;

GroceryShelfIcon.defaultProps = {
  color: "#2a3c50",
};

GroceryShelfIcon.propTypes = {
  color: PropTypes.string,
};
