import React from 'react'
import { makeStyles, SvgIcon } from '@material-ui/core'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
    cls1: (props) => ({
        // fill: props?.color ?? theme.palette.text.primary,
        fill: props?.isFilterApplied ? theme.palette.secondary.main : theme.palette.text.primary
    })
}))

export function FilterIcon(props) {
    const classes = useStyles(props)
    return (
        <SvgIcon>
            <path className={classes.cls1} d="M3,3V4.556h.778l3.889,6.222V17h4.667V10.778l3.889-6.222H17V3H3ZM5.613,4.556h8.776l-3.611,5.776v5.113H9.222V10.331Z" transform="translate(2 2)" />
        </SvgIcon>
    )
}

FilterIcon.propTypes = {
    color: PropTypes.string
}