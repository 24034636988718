import React from 'react'
import { makeStyles, SvgIcon } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    cls1: (props) => ({
        fill: '#9a9a9a'
    })
}))

export function DragDropIcon(props) {
    const classes = useStyles(props)
    return (
        <SvgIcon viewBox="0 0 58.2 58.2" fontSize="large">
            <path className={classes.cls1} d="M14.424,5A8.459,8.459,0,0,0,6,13.424a2.3,2.3,0,1,0,4.595,0,3.8,3.8,0,0,1,3.829-3.829,2.3,2.3,0,1,0,0-4.595Zm9.189,0a2.3,2.3,0,1,0,2.3,2.3A2.3,2.3,0,0,0,23.613,5ZM32.8,5a2.3,2.3,0,1,0,2.3,2.3A2.3,2.3,0,0,0,32.8,5Zm9.189,0a2.3,2.3,0,1,0,0,4.595,3.8,3.8,0,0,1,3.829,3.829,2.3,2.3,0,1,0,4.595,0A8.459,8.459,0,0,0,41.992,5ZM28.208,18.784a8.459,8.459,0,0,0-8.424,8.424V54.776A8.459,8.459,0,0,0,28.208,63.2H55.776A8.459,8.459,0,0,0,64.2,54.776V27.208a8.459,8.459,0,0,0-8.424-8.424ZM8.3,20.316a2.3,2.3,0,1,0,2.3,2.3A2.3,2.3,0,0,0,8.3,20.316Zm19.911,3.063H55.776a3.8,3.8,0,0,1,3.829,3.829V54.776a3.8,3.8,0,0,1-3.829,3.829H28.208a3.8,3.8,0,0,1-3.829-3.829V27.208A3.8,3.8,0,0,1,28.208,23.379ZM8.3,29.505a2.3,2.3,0,1,0,2.3,2.3A2.3,2.3,0,0,0,8.3,29.505Zm29.1,3.072a.657.657,0,0,0-.769.646V46.356a.652.652,0,0,0,.371.589.659.659,0,0,0,.7-.078l2.674-2.142L42.9,50.55a.653.653,0,0,0,.862.341l2.01-.873a.656.656,0,0,0,.338-.865l-1.331-3.069-1.2-2.755,3.464-.4a.655.655,0,0,0,.374-1.128l-9.689-9.055A.656.656,0,0,0,37.4,32.578ZM8.262,38.662A2.3,2.3,0,0,0,6,40.992a8.459,8.459,0,0,0,8.424,8.424,2.3,2.3,0,1,0,0-4.595,3.8,3.8,0,0,1-3.829-3.829,2.3,2.3,0,0,0-2.333-2.33Z" transform="translate(-6 -5)"/>
        </SvgIcon>
    )
}
