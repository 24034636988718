import React from "react";
import { withNavBars } from "../../HOCs";
import {
  Box,
  Typography,
  makeStyles,
  Grid,
  CircularProgress,
  TablePagination,
  TextField,
  InputAdornment,
  IconButton,
  Paper,
  Popover,
  Divider,
  Tooltip,
} from "@material-ui/core";
import { ToastMessage, AlertProps } from "../../utils";
import { DashboardCarousel, SearchTextField } from "../../components";
import TablePaginationActions from "@material-ui/core/TablePagination/TablePaginationActions";
import Config from "../../config";
import axios from "axios";
import { ProductCard } from "./productCard";
import { PharmaFilter } from "./pharmaFilter";
import SearchIcon from "@material-ui/icons/Search";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { GoLocation } from "react-icons/go";
import { useHistory } from "react-router-dom";
import { AlertContext } from "../../contexts";
import { Routes } from "../../router/routes";
import jwt_decode from "jwt-decode";
import EditIcon from "@material-ui/icons/Edit";
import InfoIcon from "@material-ui/icons/Info";
import { FaClinicMedical } from "react-icons/fa";
import { TbBuildingHospital } from "react-icons/tb";
import withAllContexts from "../../HOCs/withAllContexts";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    padding: theme.spacing(2),
    width: "100%",
    height: "100%",
    background: "#EFF1F4",
    overflowY: "auto",
  },
  itemSpacing: {
    padding: 10,
    position: "relative",
    border: "1px solid #FFFFFF",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 2px 4px #0000000A",
    borderRadius: "8px",
    opacity: 1,
    marginBottom: 10,
    [theme?.breakpoints.down("xs")]: {
      padding: "12px 6px",
    },
  },
  rowCenter: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    //justifyContent: "space-around",
    flexWrap: "wrap",
  },
  mobViewColumn: {
    [theme?.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  mainpanel: {
    padding: theme.spacing(2),
    height: "100%",
    borderRadius: "8px",
    overflowY: "auto",

    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
  mainBody1: {
    padding: "8px",
    borderRadius: "8px",
    overflowY: "auto",
    height: "calc(100% - 48px)",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100% - 120px)",
    },
    display: "inline-flex",
    flexWrap: "wrap",
    gap: "10px",
  },
  mainBody2: {
    padding: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(100% - 48px)",
    borderRadius: "8px",
  },
  cardParent: {
    background: "#fff",
    borderRadius: "8px",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
  TablePagination: {
    "& .MuiTablePagination-menuItem": {
      textAlign: "start",
      paddingRight: "8px",
    },
  },
  subHeadingXs: {
    height: "48px",
    padding: "0 16px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      height: "fit-content",
    },
  },
}));

const PharmacyDashboard = (props) => {
  const classes = useStyles();
  const [drugs, setDrugs] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [personDetails, setPersonDetails] = React.useState({});
  const [pharmacy, setPharmacy] = React.useState([]);
  const [email, setEmail] = React.useState("");
  const alertMessage = React.useContext(AlertContext);
  const [headPharmacy, setHeadPharmacy] = React.useState([]);
  const [filterData, setFilterData] = React.useState([]);
  const [SearchItem, setSearchItem] = React.useState([]);
  const [search, setSearch] = React.useState("");
  const [reason, setReason] = React.useState();
  const [pharmacyuser, setPahrmacyuser] = React.useState(false);
  const [tktid, setTktid] = React.useState("");
  const [creditDetails, setCreditDetails] = React.useState({});
  const [carousel, setCarousel] = React.useState([]);
  const [address, setAddress] = React.useState("");
  const [distributor, setDistributor] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const histroy = useHistory();
  React.useEffect(() => {
    const data = getUserRole(localStorage.getItem("kclkid"));
  }, []);

  const checkCreditDEtails = async (_id, selectedTask) => {
    let creditPayload = {
      Bedcode: _id,
      queueId: "queue/10025",
    };

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_CREDIT_API}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: creditPayload,
    };

    let result = await axios(config).then((res) => {
      return res.data;
    });

    setCreditDetails({ ...result });

    let datasss = { ...result };

    let cr = datasss?.orderInProcess - selectedTask;
    // if(!result?.difference){
    //   ToastMessage(`Credit limit not loaded`, "error", "top-right");
    // }
    if (result?.difference < 0) {
      ToastMessage(`Credit limit exceeded`, "error", "top-right");
    }
  };
  const getUserRole = async (id) => {
    var data = {
      db_name: process.env.REACT_APP_DB,
      entity: "Person",
      filter: `Person.keycloackid=='${id}' && Person.activestatus==true`,
      return_fields:
        "{roleDetail:Person.roleid[0],_id:Person._id,facility:Person.orgType,OrgId:Person.OrgID[0].id}",
    };

    var config = {
      method: "post",
      url: `${Config.api_url}/api/read_documents`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        let details = response.data?.result?.[0];
        setPersonDetails(details);
        if (details?.facility !== "") {
          setPahrmacyuser(true);
        }
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  React.useEffect(() => {
    let warehouseid = "";
    let token = localStorage.getItem("aTclk");
    let Email = jwt_decode(token);
    setEmail(Email?.email);
    var config = {
      method: "POST",
      url: process.env.REACT_APP_ARANGO_URL_READ_QDMQUERIES,
      headers: { "Content-Type": "application/json" },
      data: {
        db_name: process.env.REACT_APP_DB,
        filter: {
          email: `${Email?.email}`,
          //"g3.btech@gmail.com",
        },
        queryid: process.env.REACT_APP_BED_QUERYID,
      },
    };
    var config1 = {
      method: "POST",
      url: process.env.REACT_APP_ARANGO_URL_READ_QDMQUERIES,
      headers: { "Content-Type": "application/json" },
      data: {
        db_name: process.env.REACT_APP_DB,
        filter: {
          email: `${Email?.email}`,
          //"g3.btech@gmail.com",
        },
        queryid: process.env.REACT_APP_STATUS_QUERYID,
      },
    };
    axios(config)
      .then((res) => {
        let activeRes = res?.data.find(x=> x.activestatus === true);
        if (activeRes?.RoomCode) {
          warehouseid = activeRes?.RoomCode;
          var adsConfig = {
            method: "POST",
            url:
              process.env.REACT_APP_BACKEND_API_URL +
              process.env.REACT_APP_ADS_IMG_FOR_CAROUSEL,
            headers: { "Content-Type": "application/json" },
            data: {
              warehouseid: [warehouseid],
            },
          };
          axios(adsConfig)
            .then((re) => {
              setCarousel(re?.data?.response);
            })
            .catch((err) => {
              console.log({ err });
            });
        }
        if (activeRes?._id) {
          var addrConfig = {
            method: "POST",
            url:
              process.env.REACT_APP_BACKEND_API_URL +
              process.env.REACT_APP_PHARMACY_ADDRESS,
            headers: { "Content-Type": "application/json" },
            data: {
              pharmacyid: activeRes?._id,
            },
          };
          axios(addrConfig)
            .then((adr) => {
              const distributorValue = adr?.data?.response[0]?.distributor;
              const statusValue = adr?.data?.response[0]?.status;
              const addressValue = adr?.data?.response[0]?.address;
              setAddress(addressValue);
              setDistributor(distributorValue);
              setStatus(statusValue);
            })
            .catch((err) => {
              console.log({ err });
            });
          checkCreditDEtails(activeRes?._id, 0);
        }
        axios(config1)
          .then((res1) => {
            setTktid(activeRes?.ticketId);
            localStorage.setItem(
              "length",
              res1?.data?.length == 0 ? true : false
            );
            localStorage.setItem("tktid", activeRes?.ticketId);
            localStorage.setItem(
              "status",
              activeRes?.RegistrationStatus == "CodingMaster/11457"
                ? true
                : false
            );

            let nullRemoverForParent =
              res?.data?.filter((v) => v !== null) ?? [];

            if (res?.data) {
              setPharmacy(res?.data.filter(l => l.status === "Active") ?? []);
              setFilterData(res?.data.filter(l => l.status === "Active"));
            }
            setHeadPharmacy(res1?.data);
            let reasonArray = res?.data;
            let reasonsDatas = [];

            for (let i = 0; i < reasonArray.length; i++) {
              let key = reasonArray[i]?._key;
              let reasonData = reasonArray[i]?.Reason[0]?.filter((item) => {
                if (
                  item?.id ===
                    process.env.REACT_APP_REASON_REDO_ANSWER_QUE_ID ||
                  item?.id ===
                    process.env.REACT_APP_REASON_REDO_COMMENTS_QUE_ID ||
                  item?.id ===
                    process.env.REACT_APP_REASON_REJECT_ANSWER_QUE_ID ||
                  item?.id ===
                    process.env.REACT_APP_REASON_REJECT_COMMENTS_QUE_ID
                ) {
                  return item;
                }
              });

              reasonsDatas.push({ id: `${key}`, data: reasonData });
            }

            setReason(reasonsDatas ?? []);
          })
          .catch((err) => {
            console.log({ err });
          });
      })
      .catch((err) => {
        console.log({ err });
      });

    getItem({
      filterarray: [],
      sortingvalue: "",
    });
  }, []);

  const getItem = async (input) => {
    setSearch("");
    var itmConfig = {
      method: "POST",
      url:
        process.env.REACT_APP_BACKEND_API_URL +
        process.env.REACT_APP_PRODUCT_DETAILS,
      headers: { "Content-Type": "application/json" },
      data: input,
    };
    axios(itmConfig)
      .then((re) => {
        if (re?.data?.response.length > 0) {
          var data = re?.data?.response;
          data.forEach((node) => (node.quantity = node.moq));
          setDrugs(re?.data?.response);
          setSearchItem(re?.data?.response);
        } else {
          setDrugs([]);
          setSearchItem([]);
        }
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  const addMinusQuantity = (isadd, val, l) => {
    let n = +val;
    let qty = isadd ? ++n : --n;
    let minimum = l?.moq ? parseInt(l?.moq) : 1;

    if (qty < 1000) {
      if (minimum <= qty) {
        let obj = { ...l, quantity: qty.toString() };

        let hasAlready = SearchItem?.filter((d) => d?.serialno == l?.serialno);
        let hasAlreadyInDrugs = drugs?.filter(
          (d) => d?.serialno == l?.serialno
        );

        let updatedList =
          hasAlready.length === 0
            ? [...SearchItem, obj]
            : SearchItem.map((item) =>
                item.serialno == l?.serialno ? obj : item
              );
        let updatedDrugList =
          hasAlreadyInDrugs.length === 0
            ? [...drugs, obj]
            : drugs.map((item) => (item.serialno == l?.serialno ? obj : item));

        setSearchItem(updatedList);
        setDrugs(updatedDrugList);
      } else {
        alertMessage.setSnack({
          ...alertMessage,
          open: true,
          severity: AlertProps.severity.error,
          msg: `Minimum ${minimum} quantity is Required`,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
        return;
      }
    } else {
      alertMessage.setSnack({
        ...alertMessage,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Maximum Quantity Should Below 1000 Limit Exceed! ",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
      return;
    }
  };

  const onHandleChnageQuantity = (e, l) => {
    let regex = /^[0-9]*$/;
    let val = e.target.value;
    let Minimum = l?.moq ? parseInt(l?.moq) : 1;
    if (regex.test(val)) {
      if (val < 1000) {
        if (Minimum <= val) {
          let obj = { ...l, quantity: e.target.value };

          let hasAlready = SearchItem?.filter(
            (d) => d?.serialno == l?.serialno
          );
          let hasAlreadyInDrugs = drugs?.filter(
            (d) => d?.serialno == l?.serialno
          );
          let updatedList =
            hasAlready.length === 0
              ? [...SearchItem, obj]
              : SearchItem.map((item) =>
                  item.serialno == l?.serialno ? obj : item
                );
          let updatedDrugList =
            hasAlreadyInDrugs.length === 0
              ? [...drugs, obj]
              : drugs.map((item) =>
                  item.serialno == l?.serialno ? obj : item
                );
          setSearchItem(updatedList);
          setDrugs(updatedDrugList);
        } else {
          alertMessage.setSnack({
            ...alertMessage,
            open: true,
            severity: AlertProps.severity.error,
            msg: `Minimum ${Minimum} quantity is Required`,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
          return;
        }
      } else {
        alertMessage.setSnack({
          ...alertMessage,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Maximum Quantity Should Below 1000 Limit Exceed! ",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
        return;
      }
    }
  };

  const onHandleChange = (e) => {
    let val = "";
    if (e.target.value.length !== 0) {
      val = e.target.value;
      setSearch(e.target.value);
    } else {
      setSearch("");
      // setPatientList([]);
    }
    onSearchClicked(val);
  };

  const onEnterClicked = (e) => {
    if (e.key === "Enter") {
      onSearchClicked(search);
    }
  };

  const onSearchClicked = async (value) => {
    //let value = search;

    if (value) {
      const results = SearchItem.filter((li) =>
        li?.itemname?.toLowerCase().includes(value.toLowerCase())
      );
      setDrugs(results);
    } else if (!value) {
      setDrugs(SearchItem);
    }
  };

  const onClickAddToCart = (slno) => {
    setLoading(true);
    let selectedVal = SearchItem?.filter((d) => d?.serialno == slno);
    let v = selectedVal?.[0];
    let data = v?.quantity ?? 1;
    let Minimum = v?.moq ? parseInt(v?.moq) : 1;
    if (data < 1000) {
      if (Minimum <= data) {
        let config = {
          method: "post",
          url: `${process.env.REACT_APP_ARANGO_URL_READ_QDMQUERIES}`,
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            db_name: process.env.REACT_APP_DB,
            filter: {
              pharmacyid: pharmacy[0]?._id,
              personid: pharmacy[0]?.Person[0]?._id,
            },
            queryid: process.env.REACT_APP_GETCARTLIST,
          },
        };
        axios(config)
          .then((re) => {
            let cartData = re?.data;
            if (cartData.some((l) => l?.drugid.serialno == v?.serialno)) {
              alertMessage.setSnack({
                ...alertMessage,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Drug already exist is the cart !",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
              });
              props.cartCount.setCartCount(cartData.length);
              setLoading(false);
              return;
            }
            let input = [
              {
                db_name: process.env.REACT_APP_DB,
                entity: "addtocart",
                is_metadata: true,
                metadataId: process.env.REACT_APP_METADATAID,
                metadata_dbname: process.env.REACT_APP_REPORT_METADATA_DB_NAME,
                doc: [
                  {
                    pharmacyid: pharmacy[0]?._id,
                    personid: pharmacy[0]?.Person[0]?._id,
                    drugid: v._id,
                    quantity: Number(data),
                    cartstatus: "CodingMaster/11634",
                  },
                ],
              },
            ];
            var acConfig = {
              method: "POST",
              url: process.env.REACT_APP_ARANGO_URL_UPSERT,
              headers: { "Content-Type": "application/json" },
              data: input,
            };
            axios(acConfig)
              .then((re) => {

                let cartCount = props.cartCount;
                cartCount.setCartCount(cartData.length + 1);
                setLoading(false);
              })
              .catch((err) => {
                props.cartCount.setCartCount(cartData.length);
                setLoading(false);
                console.log({ err });
              });
          })
          .catch((err) => {
            setLoading(false);
            console.log({ err });
          });
      } else {
        setLoading(false);
        alertMessage.setSnack({
          ...alertMessage,
          open: true,
          severity: AlertProps.severity.error,
          msg: `Minimum ${Minimum} quantity is Required`,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
        return;
      }
    } else {
      setLoading(false);
      alertMessage.setSnack({
        ...alertMessage,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Maximum Quantity Should Below 1000 Limit Exceed! ",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
      return;
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <div className={classes.root}>
        <>
          <Grid container spacing={2}>
            <Grid container item spacing={2}>
              {filterData?.map((v, index) => {
                return (
                  <Grid item xs={12}>
                    <Paper style={{ width: "100%", padding: "10px" }}>
                      <Grid container item xs={12} alignItems="center">
                        <Grid
                          xs={6}
                          style={{
                            // display: "flex",
                            // marginLeft: "40px",
                          }}
                        >
                          <Tooltip title="Entity Name">
                            <Typography
                              style={{
                                // display: "inline",
                                backgroundColor: "#f1f3f5",
                                padding: "10px",
                                marginRight: "10px",
                                marginTop: "5px",
                                borderRadius: "10px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  // alignItems: "center",
                                  // justifyContent: "center",
                                  gap: "10px",
                                  // marginRight: "80px",
                                }}
                              >
                                <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                                >
                                <GoLocation
                                  size={30}
                                  style={{ color: "#6c83f4" }}
                                />
                                </div>
                                <div>
                                <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  // alignItems: "center",
                                  // justifyContent: "center",
                                  gap:"10px"
                                }}
                                >
                                <Typography
                                  style={{
                                    fontWeight: "bold",
                                    color: "#6c83f4",
                                  }}
                                >
                                  {v?.BedDescription}
                                </Typography>
                                <Typography style={{ fontSize: "10px", fontWeight:"bold", marginBottom:"0px", marginTop:"auto" }}>
                                  Status: {status}
                                </Typography>
                                {/* <Divider
                                  orientation="vertical"
                                  //  flexItem
                                  style={{
                                    height: "15px",
                                    borderLeft: "2px solid black",
                                    backgroundColor: "black",
                                  }}
                                /> */}
                                <Typography
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    marginBottom:"0px",
                                    marginTop:"auto"
                                  }}
                                >
                                   <Typography
                                      style={{
                                        fontSize: "15px",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        fontWeight: "bold"
                                      }}
                                    >DISTRIBUTOR: </Typography> 
                                    <Typography
                                      style={{
                                        fontSize: "15px",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        // color: "#f86033",
                                      }}
                                    > {distributor}</Typography>
                                </Typography>
                                </div>
                                <div>
                                <Typography>{address}</Typography>
                              </div>
                              </div>
                             </div>
                            </Typography>
                          </Tooltip>
                        </Grid>
                        <Grid xs={6}>
                          <Tooltip title="Contact Person">
                            <Typography style={{ display: "inline" }}>
                              <div
                                style={{
                                  display: "flex",
                                  height: "66px",
                                  justifyContent: "space-around",
                                  alignItems: "center",
                                  backgroundColor: "#f1f3f5",
                                  borderRadius: "10px",
                                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                  padding: "12px",
                                  marginTop: "5px",
                                  border: "0.2px solid #D3D3D3",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <AccountBalanceIcon
                                    style={{ color: "#0c5df9" }}
                                  />
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    <Typography
                                      style={{ fontSize: "15px" }}
                                      variant="body2"
                                    >
                                      Credit Limit
                                    </Typography>
                                    <Typography
                                      variant="body3"
                                      style={{
                                        color: "#71c989",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {parseFloat(
                                        creditDetails?.creditLimit ? creditDetails?.creditLimit : 0
                                      ).toFixed(2)}{" "}
                                      MYR
                                    </Typography>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <LocalMallIcon style={{ color: "#0c5df9" }} />
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    <Typography
                                      style={{ fontSize: "15px" }}
                                      variant="body2"
                                    >
                                      Old Outstanding
                                    </Typography>
                                    <Typography
                                      variant="body3"
                                      style={{
                                        color: "#71c989",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {parseFloat(
                                        creditDetails?.OldOutstanding ? creditDetails?.OldOutstanding : 0
                                      ).toFixed(2)}{" "}
                                      MYR
                                    </Typography>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <ShoppingCartCheckoutIcon
                                    style={{ color: "#0c5df9" }}
                                  />
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    <Typography
                                      style={{ fontSize: "15px" }}
                                      variant="body2"
                                    >
                                      Order In Process
                                    </Typography>
                                    <Typography
                                      variant="body3"
                                      style={{
                                        color: "#f86033",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {parseFloat(
                                        creditDetails?.orderInProcess ? creditDetails?.orderInProcess : 0
                                      ).toFixed(2)}{" "}
                                      MYR
                                    </Typography>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <AccountBalanceWalletOutlinedIcon
                                    style={{ color: "#0c5df9" }}
                                  />
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    <Typography
                                      style={{ fontSize: "15px" }}
                                      variant="body2"
                                    >
                                      Available Credit
                                    </Typography>
                                    <Typography
                                      variant="body3"
                                      style={{
                                        color: "#71c989",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {parseFloat(
                                        creditDetails?.difference ? creditDetails?.difference : 0
                                      ).toFixed(2)}{" "}
                                      MYR
                                    </Typography>
                                  </div>
                                </div>
                              </div>
                            </Typography>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Paper>
                    <div style={{ marginTop: "10px" }}>
                      <Paper
                        style={{ marginLeft: "auto", marginRight: "auto" }}
                      >
                        <DashboardCarousel carouselData={carousel} />
                      </Paper>
                    </div>
                    <Grid container xs={12} sm={12} md={12} lg={12}>
                      <Grid xs={3} sm={3} md={3} lg={3}>
                        <PharmaFilter getItem={getItem} />
                      </Grid>
                      <Grid
                        item
                        xs={9}
                        sm={9}
                        md={9}
                        lg={9}
                        className={classes.mainpanel}
                      >
                        <div style={{ width: "100%", paddingBottom: "10px" }}>
                          <SearchTextField
                            value={search}
                            onHandleChange={(e) => onHandleChange(e)}
                            onKeyPress={(e) => onEnterClicked(e)}
                            onSearchClicked={() => onSearchClicked(search)}
                            placeholder="Search by Product Name"
                          />
                        </div>
                        <Box className={classes.cardParent}>
                          <Box className={classes.subHeadingXs}>
                            {drugs?.length > 0 && (
                              <TablePagination
                                className={classes.TablePagination}
                                rowsPerPageOptions={[
                                  50,
                                  100,
                                  250,
                                  { label: "All", value: -1 },
                                ]}
                                colSpan={3}
                                count={drugs.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                  inputProps: { "aria-label": "rows per page" },
                                  native: true,
                                }}
                                style={{ border: "none" }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                              />
                            )}
                          </Box>
                          <Divider />
                          {drugs?.length > 0 ? (
                            <Grid className={classes.mainBody1}>
                              {(rowsPerPage > 0
                                ? drugs.slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                  )
                                : drugs
                              )?.map((l, index) => (
                                <div key={l.productid}>
                                  <ProductCard
                                    data={l}
                                    Quantity={SearchItem}
                                    addMinusQuantity={addMinusQuantity}
                                    onHandleChnage={onHandleChnageQuantity}
                                    onClickAddToCart={onClickAddToCart}
                                  />
                                </div>
                                // <Cards
                                //   title={l?.itemname}
                                //   date={l?.parntname}
                                //   Image={l?.drugimage}
                                //   icon={true}
                                //   index={index}
                                //   removeFromCart={removeFromCart}
                                //   mealName={l?.generic_name}
                                //   data={l}
                                //   Quantity={cartList}
                                //   addMinusQuantity={addMinusQuantity}
                                //   onHandleChnage={onHandleChnageQuantity}
                                // />
                              ))}
                            </Grid>
                          ) : (
                            <div className={classes.mainBody2}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    padding: "8px",
                                    height: "50px",
                                    width: "50px",
                                    borderRadius: "50%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    backgroundColor: "#E9EFF8",
                                    margin: "8px",
                                  }}
                                >
                                  <img
                                    style={{
                                      height: "30px",
                                      width: "30px",
                                      objectFit: "contain",
                                    }}
                                    src={`/images/icons/box.png`}
                                    alt={"No Data Imge"}
                                  ></img>
                                </div>
                                <Typography
                                  variant="subtitle1"
                                  style={{ fontWeight: "600" }}
                                  gutterBottom
                                  noWrap
                                >
                                  {"No Item to List!"}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  gutterBottom
                                  align="center"
                                  style={{ wordWrap: "break-word" }}
                                >
                                  {
                                    "Look like you have no item to add in your cart!"
                                  }
                                </Typography>
                              </div>
                            </div>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          {loading && (
            <div
              style={{
                position: "fixed",
                display: "block",
                width: "100%",
                height: "100%",
                top: 0,
                left: 0,
                opacity: "0.7",
                backgroundColor: "#fff",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </div>
            </div>
          )}
        </>
      </div>
    </>
  );
};

export default withAllContexts(PharmacyDashboard);
