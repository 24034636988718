import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import AttachementFileList from "../attachmentcomponent";
export default function AttachmentModal(props) {
  const getList = (list, key) => {
    return Object.values(list).find((e) => e.questions === key);
  };

  return (
    <div>
      <Dialog
        open={props?.open}
        onClose={props?.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Attachment</DialogTitle>
        <DialogContent>
          {props?.open && getList(props.answer, "Attachment")?.length !== 0 && (
            <AttachementFileList
              list={getList(props.answer, "Attachment")?.answer}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={props?.handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
