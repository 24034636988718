import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
  useTheme,
  makeStyles,
  TextField,
  IconButton,
  Modal,
  Backdrop,
  Fade,
  Tooltip,
} from "@material-ui/core";
import React from "react";
import { FlexRow, StyledTooltip, TimeLine } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "central_pharma_binder";
import { getCurrentDate } from "../../utils";
import { Autocomplete } from "@material-ui/lab";
import CloseIcon from "@mui/icons-material/Close";
import { MdLocalDining } from "react-icons/md";
import { AxiosCall } from "../../api";
import Config from "../../config";
import axios from "axios";
import ShowMoreText from "react-show-more-text";
import { QuantityComp } from "./quantity";
// import aersol from "../../../public/images/aerosel.jfif";
// import tablet from "../../../public/images/tablet.jpg";
// import Ointment from "../../../public/images/Ointment.jpg";
// import gel from "../../../public/images/gell.jfif";
// import { FilterList } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    // justifyContent: "center",
    alignItems: "center",
    flex: "1 1 auto",
    padding: "8px",
    // flexWrap: "wrap",
    boxShadow:
      "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  cardContent: {
    padding: "8px",
    flex: "1 1 auto",
  },
  media: {
    height: 100,
  },
  textResponsive: {
    [theme.breakpoints.up("lg")]: {
      fontSize: "1rem !important",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: ".9rem !important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem !important",
    },
  },
  captiontextResponsive: {
    minHeight: "35px",
    fontSize: "0.8rem !important",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.7rem !important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.7rem !important",
    },
  },
  buttonText: {
    "& .MuiButton-textSizeSmall": {
      [theme.breakpoints.up("md")]: {
        fontSize: "1rem !important",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "0.8rem !important",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.465rem !important",
      },
    },
  },
  banner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #e0e0e0",
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    borderRadius: "8px",
    background: theme.palette.warning.light,
    color: theme.palette.common.white,
  },
  paper: {
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "8px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    height: 400,
    width: 350,
    [theme.breakpoints.down("sm")]: {
      width: 350,
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  Header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "38px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    borderRadius: "8px 8px 0 0",
  },
  closeIcon: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    color: "#fff",
    position: "absolute",

    cursor: "pointer",
    backgroundColor: theme.palette.primary.main,
    top: -20,
    right: -20,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  quantity: {
    display: "flex",
    alignItems: "center",
    flex: "0 0 auto",
    gap: "4px",
  },
}));

export const ListItem = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const {
    type,
    menuList,
    updateCartState,
    removeFromCart,
    isAddedToCart,
    user,
    date,
    selectedAddon,
    setSelectedAddon,
    emptyMesg,
    fromKitchen,
    Count,
    value,
    tabNum,
    countLimit,
    cutOff,
    hideTour,
    updateHideTour,
    codingMasterId,
    handleTour,
    Quantity,
    setQuantity,
    addMinusQuantity,
    search,
    setSearch,
    onHandleChnage,
    tabPanelvalue
  } = props;
  const dispatch = useDispatch();

  // console.log("type" , type)
  // console.log("tabPanelvalue" , tabPanelvalue)

  const isMealorder = useSelector(
    (state) => state?.orderCatalogSlice.getMealOrderByPatient.data
  );
  // console.log(isMealorder);
  const [foodItem, setFoodItem] = React.useState([]);
  const [isloading, setLoading] = React.useState(true);
  const [cutoff, setCutoff] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [timeline, setTimeline] = React.useState([]);
  const [currantStatus, setCurrantStatus] = React.useState();
  const [mloading, setmloading] = React.useState(false);
  const [filterList, setFilter] = React.useState([]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useLayoutEffect(() => {
    // if (menuList.length > 0 && type) {
    if (foodItem.length === 0) {
      //getMealOrderbyType(date, menuList, countLimit);
      setSearch("");
    } else {
      // filterList;
      let filteList = filterList?.filter(
        (l) =>
          l?.itemname?.toLowerCase().includes(search?.toLowerCase()) ||
          l?.parntname?.toLowerCase().includes(search?.toLowerCase())
      );
      // console.log("filteList", filteList);
      if (filteList.length > 0) setFoodItem(filteList);
      // setFoodItem(filteList);
    }
    // } else {
    //   setLoading(false);
    // }
    //eslint-disable-next-line
  }, [type, menuList, date, Count, countLimit, search]);

  React.useEffect(() => {

    setFilter(tabPanelvalue[type]);
    setFoodItem(tabPanelvalue[type]);

    setTimeout(() => {
      setLoading(false);
    }, 2000);

  }, [type])

  const getMealOrderbyType = async (date, menuList, countLimit) => {
    setLoading(true);
    let meal = menuList?.find((l) => l.find((a) => a.MealSchedule === type));

    // let isTymUp = getCutoffFlag(type, date, cutOff, fromKitchen);

    // setCutoff(isTymUp);
    let today = getCurrentDate(date);

    // // let forKitchen = date.getDate() === new Date().getDate() ? isTymUp : false;

    // let updateMealList = meal?.map((l) => {
    //   return {
    //     ...l,
    //     disable: fromKitchen ? false : isTymUp,
    //     MealDate: getCurrentDate(date),
    //   };
    // });

    // console.log("updateMealList", updateMealList);

    let res = await dispatch(
      actions.GET_MEAL_ORDERED_BY_PATIENT({
        // PatientCode: user?.PatientCode,
        // PatientBedCode: user?.PatientBedCode,
        // currantDay: today,
        mealType: type,
      })
    );
    let result = res?.payload?.data;

    // console.log("result" , result)

    // let updateOrderMeal = result.map(
    //   (item) =>
    //     item && {
    //       ...item,
    //       orderstatus: false,
    //       orderDate: getCurrentDate(new Date()),
    //     }
    // );

    // console.log(updateOrderMeal, "res")

    // let updateOrderMeal = meal?.map((v) => {
    //   if (result?.length > 0) {
    //   let val = result?.find((a) => a?.OrderItemCode === v?._id);
    //   if (val && Object.keys(val)?.length > 0) {
    //     return {
    //       ...v,
    //       orderstatus: true,
    //       ticketId: val?.ticketId,
    //       orderDate: getCurrentDate(date),
    //     };
    //   } else {
    //     return {
    //       ...v,
    //       orderstatus: false,
    //       disable: true,
    //       orderDate: getCurrentDate(date),
    //     };
    //   }
    // }
    // return { ...v, orderstatus: false, orderDate: getCurrentDate(date) };
    // });
    setFilter(result);
    setFoodItem(result);
    // console.log(result, "ee");
    openUserGuide(
      res?.payload?.data,
      meal,
      value,
      tabNum,
      Count,
      countLimit,
      fromKitchen
      // isTymUp
    );

    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  // React.useEffect(() => {
  //   if (filterList.length > 0) {
  //     // setTimeout(() => {
  //     let filteList = filterList?.filter(
  //       (l) =>
  //         l?.brand_name?.toLowerCase().includes(search?.toLowerCase()) ||
  //         l?.dosage_form?.toLowerCase().includes(search?.toLowerCase())
  //     );
  //     console.log("filteList", filteList);
  //     // if (filteList.length>0) setFoodItem(filteList)
  //     // setFoodItem(filteList);
  //     // }, 500);
  //   }

  //   // return () => {
  //   //   second
  //   // }
  // }, [search]);

  const openUserGuide = (
    res,
    meal,
    value,
    tabNum,
    Count,
    countLimit,
    fromKitchen
    // isTymUp
  ) => {
    if (
      res?.length === 0 &&
      meal?.length > 0 &&
      Count <= parseInt(countLimit) &&
      // !isTymUp &&
      fromKitchen === false &&
      hideTour
    ) {
      handleTour();
      updateHideTour();
    }
  };

  // const getOptions = (value) => {
  //   let list =
  //     value?.dropdowntext?.trim()?.length > 0
  //       ? value?.dropdowntext?.split(",")
  //       : [];
  //   return list;
  // };

  // const handleState = (key, value, data) => {
  //   let hasAlready = selectedAddon?.filter(
  //     (d) => d?.MealCode?._key === data?.MealCode?._key
  //   );
  //   let temp =
  //     hasAlready?.length === 0 ? [...selectedAddon, data] : [...selectedAddon];

  //   let selectedAddonList = temp?.map((l) =>
  //     l?.MealCode?._key === data?.MealCode?._key ? { ...l, [key]: value } : l
  //   );
  //   setSelectedAddon(selectedAddonList);
  // };

  // const getSelectedAddOn = (data, index, multiselect) => {
  //   let findSelectValue = selectedAddon?.find(
  //     (val) => val?.MealCode?._key === data?.MealCode?._key
  //   );
  //   if (findSelectValue && Object.keys(findSelectValue).length > 0) {
  //     let addon = !multiselect
  //       ? findSelectValue[`Addon${index}`]
  //       : Array.isArray(findSelectValue[`Addon${index}`])
  //       ? findSelectValue[`Addon${index}`]
  //       : [];
  //     return addon;
  //   } else {
  //     let value = multiselect ? [] : "";
  //     return value;
  //   }
  // };

  // const getCutoffFlag = (type, dateNew, cutoffvalue, fromKitchen) => {
  //   let epoche = dateNew.getDate();
  //   // let value = fromKitchen ? parseInt(cutoffvalue) + 2 : parseInt(cutoffvalue);
  //   if (codingMasterId === process.env.REACT_APP_CODING_MASTER_BREAKFAST_ID) {
  //     let hrs = dateNew.getHours();
  //     if (epoche === new Date().getDate()) {
  //       let flag = true;
  //       return flag;
  //     } else {
  //       let flag = hrs < parseInt(cutoffvalue) ? false : true;
  //       return flag;
  //     }
  //   }
  // else if (type === "Lunch" && epoche === new Date().getDate()) {
  //   let hrs = dateNew.getHours();

  //   let flag = hrs < parseInt(cutoffvalue) ? false : true;
  //   return flag;
  // } else if (type === "Dinner" && epoche === new Date().getDate()) {
  //   let hrs = dateNew.getHours();

  //   let flag = hrs < parseInt(cutoffvalue) ? false : true;
  //   return flag;
  // }
  //   else if (epoche === new Date().getDate()) {
  //     let hrs = dateNew.getHours();
  //     let flag = hrs < parseInt(cutoffvalue) ? false : true;
  //     return flag;
  //   } else {
  //     return false;
  //   }
  // };

  // const getAddOn = (l) => {
  //   let whetherMealOrder = isMealorder?.data.find(
  //     (a) => a?.OrderItemCode === l?.Mealid
  //   );
  //   let addonText = whetherMealOrder?.Addon;

  //   return addonText;
  // };

  const onTimeLineClicked = (data) => {
    handleOpen();
    getTransactionId(data?.ticketId);
  };

  const getTransactionId = async (ticketId) => {
    try {
      setmloading(true);
      let params = {
        db_name: `${Config.dbname}`,
        entity: "QDMATPtransactionlog",
        filter: `QDMATPtransactionlog.ticketId=='${ticketId}'`,
        return_fields: "QDMATPtransactionlog",
      };
      let res = await AxiosCall(
        "post",
        `${Config.api_url}/api/read_documents`,
        params
      );
      let data = res.result[0];
      setCurrantStatus(data?.payload?.statesid[0]);
      let SecnarioStateList = await getSenarioStateList(
        data?.payload?.scenarioId[0]
      );
      await getAduitLog(data._id, SecnarioStateList);
    } catch (error) { }
  };

  const getSenarioStateList = async (id) => {
    let params = {
      db_name: `${Config.dbname}`,
      entity: "queuescenariostatemapping",
      filter: `queuescenariostatemapping.scenarioid=='${id}' and queuescenariostatemapping.activestatus==true`,
      return_fields: "queuescenariostatemapping.states",
    };
    let res = await AxiosCall(
      "post",
      `${Config.api_url}/api/read_documents`,
      params
    );
    let data = res.result[0];

    return data;
  };

  const getAduitLog = async (_id, scenarioState) => {
    try {
      let params = {
        db_name: `${process.env.REACT_APP_AuditDB}`,
        entity: "QDMATPtransactionlog_audit",
        filter: `QDMATPtransactionlog_audit.refkey=='${_id}'`,
        return_fields: "QDMATPtransactionlog_audit",
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_ARANGO_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };

      await axios(config).then((res) => {
        if (res.data.Code === 201) {
          let data = res.data.result;

          if (data.length > 0) {
            let decodeArr = data.map((l) => {
              let Payload = atob(l.payload);
              let Document = atob(l.document);
              return {
                ...l,
                payload: JSON.parse(Payload),
                document: JSON.parse(Document),
              };
            });
            let isArrHasState = decodeArr.filter(
              (l) =>
                l?.document?.payload?.statesid &&
                l?.document?.payload?.queueMetaData?.userAction?.userAction
            );

            let z = scenarioState.map((l) =>
              Object.assign(l, { statustime: "", userAction: "" })
            );

            for (let j = 0; j < z.length; j++) {
              for (let i = 0; i < isArrHasState.length; i++) {
                if (
                  z[j]._id === isArrHasState[i].document.payload.statesid[0]
                ) {
                  Object.assign(z[j], {
                    statustime: isArrHasState[i].statustime,
                    userAction:
                      isArrHasState[i].document?.payload?.queueMetaData
                        ?.userAction?.userAction,
                  });
                }
              }
            }
            setTimeline(z);
            setmloading(false);
          } else {
            let z = scenarioState.map((l) =>
              Object.assign(l, { statustime: "", userAction: "" })
            );
            setTimeline(z);
            setmloading(false);
          }
        }
      });
    } catch (error) {
      // updateTimeLine(scenarioState);
    }
  };

  if (isloading) {
    return (
      <>
        <Box style={{ height: "100%" }}>
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CircularProgress />
            <Typography
              className={classes.textResponsive}
              variant="subtitle2"
              color="textPrimary"
              style={{ fontWeight: "600" }}
            >
              {`Loading...`}
            </Typography>
          </div>
        </Box>
      </>
    );
  }

  const getImageByType = (t) => {
    let obj = {
      AEROSOL: `${process.env.PUBLIC_URL}/images/aerosel.jfif`,
      TABLET: `${process.env.PUBLIC_URL}/images/tablet.jpg`,
      OINTMENT: `${process.env.PUBLIC_URL}/images/Ointment.jpg`,
      GEL: `${process.env.PUBLIC_URL}/images/gell.jfif`,
      CAPSULE:
        "https://images.unsplash.com/photo-1587854692152-cbe660dbde88?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=869&q=80",
    };

    // console.log("obj", obj);

    let src =
      "https://images.unsplash.com/photo-1587854692152-cbe660dbde88?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=869&q=80";

    return obj[`${t}`] ?? src;
  };

  return (
    <>
      <Box style={{ height: "100%" }}>
        <>
          {/* {!fromKitchen &&
            cutoff &&
            isMealorder?.data.length === 0 &&
            foodItem?.length > 0 && (
              <>
                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className={classes.banner}
                >
                  <Typography>{`Sorry, we are not accepting any more ${type.toLowerCase()} orders`}</Typography>
                </Grid>
              </>
            )} */}
          {foodItem?.length > 0 && !isloading ? (
            <Grid container spacing={3}>
              {foodItem?.map((l, i) => (
                <Grid item xs={12} sm={12} md={12} lg={12} key={`${i}drugCard`}>
                  <Card className={classes.root}>
                    <Grid container alignItems="center">
                      <Grid item xs={3} sm={2} md={1} lg={1}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "8px",
                            width: "60px",
                            height: "60px",
                            flex: "0 0 auto",
                          }}
                        >
                          <img
                            src={getImageByType(type)}
                            // src={
                            //   l?.MealCode?.MealPic
                            //     ? `${process.env.REACT_APP_IMAGE_UPLOAD_PROTOCAL}://${process.env.REACT_APP_IMAGE_UPLOAD_HOST}/${process.env.REACT_APP_DB}/${l.MealCode.MealPic}`
                            //     : "images/icons/FoodImage.webp"
                            // }
                            // src={`https://fileupload.dev.ainqaplatform.in/ipmo/${l.MealCode.MealPic}`}
                            alt="icon"
                            style={{
                              width: "100%",
                              height: "100%",
                              // background: "#e0e0e0",
                              borderRadius: "8px",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={9}
                        sm={7}
                        md={8}
                        lg={8}
                        className={classes.card}
                      >
                        <CardContent className={classes.cardContent}>
                          <FlexRow>
                            <Typography
                              // variant="h6"
                              component="body1"
                              gutterBottom
                              // style={{ fontWeight: "600" }}
                              style={{
                                display: "-webkit-box",
                                WebkitLineClamp: "2",
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                              }}
                              className={classes.textResponsive}
                            >
                              {l?.itemname}
                            </Typography>
                          </FlexRow>
                          <ShowMoreText>
                            <StyledTooltip
                              title={l?.MealCode?.MealDescription}
                              arrow
                            >
                              <Typography
                                style={{
                                  display: "-webkit-box",
                                  WebkitLineClamp: "2",
                                  WebkitBoxOrient: "vertical",
                                  overflow: "hidden",
                                }}
                                className={classes.captiontextResponsive}
                              >
                                {l?.parntname}
                              </Typography>
                            </StyledTooltip>
                          </ShowMoreText>

                          {/* {l?.orderstatus && getAddOn(l) && (
                            <>
                              <Typography
                                variant="body2"
                                style={{ fontWeight: "600" }}
                              >
                                {"Desserts"}
                              </Typography>
                              <Typography variant="body2">
                                {getAddOn(l)}
                              </Typography>
                            </>
                          )} */}
                        </CardContent>

                        {/* <Quantity/> */}
                        {/* <TextField
                          style={{ padding: "10px" }}
                          fullWidth
                          size="small"
                          type="number"
                          variant="outlined"
                        /> */}
                        {/* {isMealorder?.data?.length === 0 && (
                          <Grid container data_tour="step_3">
                            {l?.MealCode?.Addon?.length > 0 && (
                              <>
                                {l?.MealCode?.Addon?.map((addons, index) => (
                                  <Grid
                                    // container
                                    item
                                    xs={12}
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    style={{ padding: "0 8px" }}
                                  >
                                    <Typography
                                      id={`Add On Label`}
                                      variant="body2"
                                    >
                                      {addons?.title ?? "Add-on"}
                                    </Typography>
                                    <Autocomplete
                                      id={`${l?.MealCode?._id}_${index}`}
                                      multiple={addons?.multiselect}
                                      fullWidth
                                      size="small"
                                      options={getOptions(addons)}
                                      getOptionLabel={(option) => option}
                                      disabled={l?.disable}
                                      value={getSelectedAddOn(
                                        l,
                                        index,
                                        addons?.multiselect
                                      )}
                                      onChange={(e, v) =>
                                        handleState(
                                          `Addon${index}`,
                                          v,
                                          l,
                                          addons?.multiselect
                                        )
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label={false}
                                          variant="outlined"
                                          placeholder="Select"
                                          size="small"
                                        />
                                      )}
                                    />
                                  </Grid>
                                ))}
                              </>
                            )}
                          </Grid>
                        )} */}
                      </Grid>
                      <Grid item xs={12} sm={3} md={3} lg={3}>
                        <QuantityComp
                          Quantity={Quantity}
                          addMinusQuantity={addMinusQuantity}
                          data={l}
                          onHandleChnage={onHandleChnage}
                        />
                        <div
                          style={{
                            // padding: "8px",
                            marginLeft: "8px",
                            display: "flex",
                            alignItems: "center",
                            flex: "0 0 auto",
                          }}
                        >
                          {l?.orderstatus ? (
                            <>
                              <Button
                                className={classes.buttonText}
                                size="small"
                                fullWidth
                                variant="contained"
                                style={{
                                  backgroundColor: "#1AA251",
                                  color: "white",
                                }}
                              >
                                {"Ordered"}
                              </Button>
                              <IconButton
                                color="primary"
                                onClick={() => onTimeLineClicked(l)}
                              >
                                <MdLocalDining size="1.3rem" />
                              </IconButton>
                            </>
                          ) : isAddedToCart(l) ? (
                            <Button
                              fullWidth
                              className={classes.buttonText}
                              size="small"
                              variant="contained"
                              onClick={() => removeFromCart(l)}
                              style={{
                                backgroundColor: "red",
                                color: "white",
                              }}
                            >
                              {"Remove From Cart"}
                            </Button>
                          ) : (
                            <Button
                              className={classes.buttonText}
                              size="small"
                              fullWidth
                              color="primary"
                              variant="contained"
                              // disabled={l?.disable}
                              onClick={() => updateCartState(l)}
                              data_tour={`step_4_cart_${i + 1}`}
                            >
                              {/* {l?.disable ? "Order Closed" : "+ Add to Cart"} */}
                              + Add to Cart
                            </Button>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              ))}
            </Grid>
          ) : (
            <>
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  background: "aliceblue",
                }}
              >
                <img
                  style={{ heigth: "100px", width: "100px" }}
                  alt="Empty Icon"
                  src="/images/EMPTY_FOOD_CART.svg"
                />
                <Typography
                  variant="subtitle2"
                  color="textPrimary"
                  style={{ fontWeight: "600" }}
                  className={classes.textResponsive}
                >
                  {emptyMesg ?? "Menu item is empty !"}
                </Typography>
              </div>
            </>
          )}
        </>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Box className={classes.Header}>
              <Typography>{"Order Status"}</Typography>
            </Box>
            {mloading ? (
              <div
                style={{
                  height: "calc(100% - 38px)",
                  overflowY: "auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress color="primary" />
              </div>
            ) : (
              <div style={{ height: "calc(100% - 53px)", overflowY: "auto" }}>
                <TimeLine
                  scenarioState={timeline}
                  currantState={currantStatus}
                />
              </div>
            )}

            <IconButton
              className={classes.closeIcon}
              onClick={() => handleClose()}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </Fade>
      </Modal>
    </>
  );
};
