import React, { useEffect, useState } from "react";
import axios from "axios";
import CheckoutForm from "../../components/stripeForPayment";
// stripe-config.js
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { CircularProgress, Typography } from "@material-ui/core";
import { stripePromise } from "../../stripe-config";

function Payment() {
  const [clientSecret, setClientSecret] = useState();
  //"pi_3NathqSDKgZ8WrJP08CU1oEa_secret_NeSGsvk4huHqy8euCaA3CDUA6"
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    getCLientSecrent();
  }, []);
  function getCLientSecrent() {
    const url = new URL(window.location.href);
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
    try {
      setClientSecret(atob(url.searchParams.get("pi.cs")));
    } catch (err) {
      setClientSecret("");
      console.log(err);
    }
  }
  const options = {
    // passing the client secret obtained from the server
    clientSecret: clientSecret,
    // clientSecret:
    //     "pi_3NY55kSDKgZ8WrJP1cqn7o0Y_secret_r5SDqvdUOI6jE1G1CYHLS272W",
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      {isLoading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <img
            src="/images/pintar2ulogo.webp"
            alt="icon"
            style={{ width: "60px", height: "60px", objectFit: "cover" }}
          />
          <CircularProgress />
          <Typography>Payment page is loading</Typography>
        </div>
      ) : (
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm clientSecret={clientSecret} />
        </Elements>
      )}
    </div>
  );
}

export default Payment;
