import React from 'react';
import {
    IconButton, TextField, Grid, Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { FilterIcon } from '../../assets';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    textInput: {
        height: 40,
        background: '#110F470A 0% 0% no-repeat padding-box',
        borderRadius: 10,
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#110F470D'
        }
    },
    filterIcon: {
        background: '#110F470A 0% 0% no-repeat padding-box',
        border: (props) => `1px solid ${props?.isFilterApplied ? theme.palette.secondary.light : '#110F471A'}`,
        borderRadius: 10,
        cursor: 'pointer',
    },
    filterIconBtn: {
        padding: theme.spacing(1)
    },
    fullWidth: {
        width: '100%'
    },
    textInputGrid: {
        marginTop: theme.spacing(2)
    },
    title:{
        color: theme.palette.primary.main
    }
}));

export const SearchFilter = (props) => {

    const classes = useStyles(props);

    const [state, setState] = React.useState({
        search: ""
    });

    const changeState = (key, value) => {

        setState({
            ...state,
            [key]: value
        })

        if (key === 'search') {
            props.onSearchChange(value);
        }
    }

    const {
        title = "Forms",
        placeholder = "Search",
        hideFilter = false,
        hideSearchBar = false,
        onFilterClicked = () => false,
        isFilterApplied = false,
        id=''
    } = props;

    return (
        <div>
            <Grid
                container
                direction="row"
                alignItems="center"
                justify="space-between"
            >
                <Grid item>
                    <Typography variant="body1" className={classes.title}>{title}</Typography>
                </Grid>
                {
                    !hideFilter &&
                    <Grid item className={classes.filterIcon}>
                        <IconButton className={classes.filterIconBtn} onClick={onFilterClicked} id={`${id+"filterButton" ?? "filterButton"}`}>
                            <FilterIcon isFilterApplied={isFilterApplied} />
                        </IconButton>
                    </Grid>
                }
            </Grid>
            {
                !hideSearchBar &&
                <Grid container className={classes.textInputGrid}>
                    <Grid item className={classes.fullWidth}>
                        <TextField
                            autoComplete="off"
                            id="ippc_search_bar"
                            placeholder={placeholder}
                            fullWidth
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                className: classes.textInput,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            value={state.search}
                            onChange={(e) => changeState('search', e.target.value)}
                        />
                    </Grid>
                </Grid>
            }
        </div>
    )
}

SearchFilter.propTypes = {
    title: PropTypes.string,
    placeholder: PropTypes.string,
    hideSearchBar: PropTypes.bool,
    hideFilter: PropTypes.bool,
    onSearchChange: PropTypes.func,
    onFilterClicked: PropTypes.func,
    isFilterApplied: PropTypes.bool
}