import React from "react";
import { Modal, Typography, Box, useMediaQuery } from "@material-ui/core";
import { Label, StyledButton } from "../../styledcomponent";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useTheme } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { ModalQueueList } from "./modalQueueList";

const MyBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: "0",
  left: "50%",
  maxWidth: "250px",
  zIndex: "3",
  transform: "translate(-50%)",
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: 500,
  backgroundColor: "#FFFFFF",
  borderRadius: "8px",
  boxShadow: 24,
};

const stylePhone = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  padding: "8px",
  height: 500,
  backgroundColor: "#FFFFFF",
  borderRadius: "8px",
  boxShadow: 24,
};

export const QueueModal = (props) => {
  const theme = useTheme();
  const forTab = useMediaQuery(theme.breakpoints.down("sm"));

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <MyBox>
        <StyledButton onClick={() => handleOpen()}>
          <Typography variant="body1">{"Queue"}</Typography>
          <Label
            count={
              `${
                props?.queues?.filter(
                  (e) =>
                    props.PermissionData?.filter(
                      (v) => v.repoid.unique_id === e._id && v.permsnread
                    ).length !== 0
                ).length
              }`.length > 2
                ? true
                : false
            }
            color={theme.palette.info.main}
          >{`${
            props?.queues?.filter(
              (e) =>
                props.PermissionData?.filter(
                  (v) => v.repoid.unique_id === e._id && v.permsnread
                ).length !== 0
            ).length
          }`}</Label>
          <ExpandLessIcon fontSize="small" />
        </StyledButton>
      </MyBox>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={forTab ? stylePhone : style}>
          <ModalQueueList
            PermissionData={props.PermissionData}
            queues={props.queues}
            handleClose={handleClose}
            getSelectedQueue={props.getSelectedQueue}
            {...props}
          />
        </Box>
      </Modal>
    </>
  );
};
