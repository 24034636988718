import React, { useContext } from "react";
import {
  Divider,
  Grid,
  IconButton,
  Typography,
  Checkbox,
  Tooltip,
  Dialog,
} from "@material-ui/core";
import AttachmentIcon from "@material-ui/icons/Attachment";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import StarIcon from "@mui/icons-material/Star";
import { TagsIcon } from "../../assets";
import { useTheme } from "@material-ui/core";
import moment from "moment";
import parse from "html-react-parser";
import axios from "axios";
import CircleChecked from "@material-ui/icons/CheckCircleOutline";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import FormRendererParent from "../../../../taskviewer/src/TaskViewer/screens/formRendererfinal/index";
import { useState } from "react";
import { BackdropContext } from "../../../../taskviewer/src/TaskViewer/contexts";
import { aqlQuery } from "../../../../taskviewer/src/TaskViewer/utils";
export const CardStyled = styled.div`
  padding: ${(props) => (props.m === "large" ? "12px 12px" : "8px 8px")};
  background: ${(props) =>
    props.selected && props.hovercolor ? props.hovercolor : "#ffffff"};
  border-top: 1px solid;
  border-bottom: 1px solid;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100px;
  cursor: pointer;
  border-color: #e0e0e0;
  border-radius: ${(props) => (props.type === "main" ? "8px" : "0px")};
  transition: border-color 0.3s ease-in-out;
  &:hover {
    border: 2px solid;
    border-color: ${(props) => props.color};
  }
`;

export const CardStyle = styled(CardStyled)`
  cursor: inherit;
  border: 1px solid #e0e0e0;
  &:hover {
    border: 1px solid #e0e0e0;
  }
`;

export const FlexRow = styled.div`
  display: flex;
  align-items: center;
  margin: 2px;
`;

export const FlexSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    margin: theme.spacing(0.5),
    border: "none",
    "&:not(:first-child)": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-child": {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))(ToggleButtonGroup);

var debounce;
function debouncefunc(func, event, value) {
  //
  clearTimeout(debounce);
  debounce = setTimeout(() => {
    func(event, value);
  }, 500);
}
export const Cards = (props) => {
  let {
    type,
    index,
    field1,
    field2,
    field3,
    field4,
    field5,
    field6,
    field7,
    field8,
    field9,
    ticketId,
    state,
    data,
    favourite,
    performUserAction = () => false,
    onCardClicked = () => false,
    onFavIconClicked = () => false,
    action = [],
    selectedTask,
    alignment,
    updateState,
    PermissionData,
    scenarioId,
    verifier_registration_status = 0,
    isUpdate,
    handleClick1,
    isCheck,
    handleClickOpen,
    handleClickOpenAttachment,
  } = props;
  let actionData = [];
  if (action) {
    action?.forEach((e) =>
      PermissionData?.forEach((v) =>
        v.scenarioChildren?.forEach((b) =>
          b.stateChildren?.forEach((n) =>
            n.actionChildren?.forEach(
              (m) =>
                m.repoid.unique_id === e._id &&
                m.permsnread === true &&
                b.repoid.unique_id === scenarioId &&
                actionData.push(e)
            )
          )
        )
      )
    );
    action = [...new Set(actionData)];
  }
  const regex = /<\w+/g;
  const theme = useTheme();
  const [formOpen, setFormOpen] = useState(false);
  const [formRendering, setFormRendering] = React.useState({});
  const [keyNameForFormUpsert, setKeyNameForFormUpsert] = useState("");
  const [formId, setFormID] = useState("");
  const backdrop = useContext(BackdropContext);
  const [loading, setLoading] = useState(false);
  const [alignmen, setAlignmen] = useState();
  // console.log({ formRendering })
  const a = new Date(field9 * 1000);
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const moments = moment(a).fromNow();

  const handleAlignment = async (event, newAlignment) => {
    //
    setFormID(newAlignment?.chooseform);
    setAlignmen(newAlignment);
    // console.log(process.env.REACT_APP_REDO_ACTION_NAME === newAlignment?.action, process.env.REACT_APP_REDO_ACTION_NAME, newAlignment?.action)
    // console.log(process.env.REACT_APP_REJECT_ACTION_NAME === newAlignment?.action, process.env.REACT_APP_REJECT_ACTION_NAME, newAlignment?.action)

    if (process.env.REACT_APP_REDO_ACTION_NAME === newAlignment?.action) {
      backdrop?.setBackDrop({ ...backdrop, open: true, message: "loading" });
      setKeyNameForFormUpsert("RedoDetails");
      getFormData(newAlignment?.chooseform, ticketId, "RedoDetails");
    } else if (
      process.env.REACT_APP_REJECT_ACTION_NAME === newAlignment?.action
    ) {
      backdrop?.setBackDrop({ ...backdrop, open: true, message: "loading" });
      setKeyNameForFormUpsert("RejectDetails");
      getFormData(newAlignment?.chooseform, ticketId, "RejectDetails");
    } else {
      await performUserAction(newAlignment, ticketId);
    }
    updateState(newAlignment);
    // performUserAction(newAlignment, ticketId);
  };
  const handleSelect = (data) => {
    onCardClicked(data);
  };

  const onClickFav = (data) => {
    onFavIconClicked(data);
  };

  const printFunc = (ticketid) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      ticketId: ticketid,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_PRINT_API, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        axios(result.response, {
          method: "GET",
          responseType: "blob", //Force to receive data in a Blob Format
        })
          .then((response) => {
            //Create a Blob from the PDF Stream
            const file = new Blob([response.data], { type: "application/pdf" });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            window.open(fileURL);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => console.log("error", error));
  };

  const getFormFromExecuteAql = async (formId, ticketId, keyname) => {
    let entryid;
    let params_for_read_entry = {
      db_name: `${process.env.REACT_APP_DBNAME}`,
      entity: "QDMATPtransactionlog",
      filter: `QDMATPtransactionlog.ticketId=='${ticketId}' && QDMATPtransactionlog.activestatus==true`,

      return_fields: "QDMATPtransactionlog",
    };

    let config_for_read_entry = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: params_for_read_entry,
    };

    await axios(config_for_read_entry)
      .then(async (res) => {
        //  ;
        entryid = res.data?.result?.[0]?.payload?.inputDoc?.[keyname]?.entry_id;

        let params = {
          db_name: `${process.env.REACT_APP_DBNAME}`,
          query: entryid
            ? aqlQuery.getFormDataWithEntryId(formId, entryid)
            : aqlQuery.getFormData(formId, ticketId),
        };
        let config = {
          method: "post",
          url: `${process.env.REACT_APP_READ_DOCUMENT}`,
          headers: {
            "Content-Type": "application/json",
          },
          data: params,
        };

        await axios(config)
          .then((res) => {
            //  ;
            let aqldata = res.data[0];
            let answerArr = aqldata?.bulkAnswers?.answers ?? [];
            let answer = {};
            // console.log(aqldata, "aqldata");
            answerArr?.map((l) => (answer[l.question_id] = l.answer));
            let updateState = {
              formId: formId,
              // formData: aqldata,
              // selected_form: aqldata?.form[0],
              // answer: answer,
              _id: aqldata?.bulkAnswers?.entry_id,
              secName: aqldata?.bulkAnswers?.secName ?? 1,
              entry_id: entryid,
            };
            setFormRendering(updateState);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getFormData = async (formid, ticketId, keyname) => {
    // Setloading(true);
    //  ;
    await getFormFromExecuteAql(formid, ticketId, keyname);
    setTimeout(() => {
      setFormOpen(!formOpen);
      backdrop?.setBackDrop({ ...backdrop, open: false, message: "" });
    }, 1000);
  };

  const updateEntry = async (data) => {
    //  ;
    setLoading(true);
    backdrop?.setBackDrop({
      ...backdrop,
      open: true,
      message: "Loading the next step",
    });
    // console.log("InnnerData", data);
    let answerArr = data?.data;
    let answer = {};
    let entryid = data?.entry_id ? data?.entry_id : formRendering?.entry_id;
    Object.keys(answerArr).map(
      (l) =>
        (answer[answerArr?.[l]?.questions] =
          answerArr?.[l]?.answer?.length !== 0 ? answerArr?.[l]?.answer : "")
    );
    let params = {
      form_id: formId,
      data: data,
      notifier_status: data?.isDraft ? 9 : 1,
      notifyType: data?.isDraft,
      entry_id: formRendering?.entry_id,
      isEdit: false,
      ticketId: ticketId,
    };
    // await Promise.resolve(
    //   dispatch(actions.UPSERT_ENTRY_ATP_TASK_VIEWER_TREE(params))
    // ).then((res) => {
    //   console.log("DispatchRes", res);
    //   if (res?.payload?.data?.Result) {
    //     //  ;
    //     entryid = res?.payload?.data?.Result[0]?.properties?.doc?.entry_id;
    //     alert.setSnack({
    //       ...alert,
    //       open: true,
    //       severity: AlertProps.severity.success,
    //       msg: "Form Successfully Submitted",
    //       vertical: AlertProps.vertical.top,
    //       horizontal: AlertProps.horizontal.center,
    //     });
    //   } else {
    //     alert.setSnack({
    //       ...alert,
    //       open: true,
    //       severity: AlertProps.severity.error,
    //       msg: "Something went wrong!",
    //       vertical: AlertProps.vertical.top,
    //       horizontal: AlertProps.horizontal.center,
    //     });
    //   }
    // });
    //  ;
    await performUserAction(
      alignmen,
      ticketId,
      answer,
      keyNameForFormUpsert,
      entryid,
      setLoading
    );
    setFormOpen(false);
    setLoading(false);
    // backdrop?.setBackDrop({ ...backdrop, open: false, message: "" });
  };
  return (
    <Grid item lg={12} md={12} xs={12} sm={12} key={index}>
      {type === 1 && (
        <CardStyled
          onClick={() => handleSelect(data)}
          hovercolor={theme.palette.info.light}
          color={theme.palette.info.main}
          // key={index}
          selected={selectedTask?.key === data?.key ? true : false}
        >
          <FlexSpaceBetween>
            <FlexRow>
              <>
                {!isUpdate ? (
                  <Checkbox
                    icon={<CircleUnchecked />}
                    checkedIcon={<CircleCheckedFilled />}
                    id={ticketId}
                    name={ticketId}
                    size="small"
                    type="checkbox"
                    color="primary"
                    //iconStyle={{fill: '#007AFF'}}
                    onChange={handleClick1}
                    checked={isCheck.includes(ticketId)}
                  />
                ) : (
                  ""
                )}
              </>
              <Typography variant="body1">
                {field1 !== undefined ? `${field1}` : ""}
              </Typography>

              {(field9 || field9 === "undefined") && (
                <>
                  <Divider
                    flexItem
                    orientation="vertical"
                    style={{ margin: "4px 4px" }}
                  />
                  <Typography
                    variant="caption"
                    style={{
                      fontSize: "9px",
                    }}
                    color="GrayText"
                  >
                    {`${moments}`}
                  </Typography>
                </>
              )}
            </FlexRow>
            <Typography
              variant="subtitle2"
              style={{ fontWeight: "600", fontSize: "10px" }}
            >
              {ticketId ?? ""}
            </Typography>
          </FlexSpaceBetween>

          <FlexRow>
            {(field4 || field4 === "undefined") && (
              <>
                <Typography
                  variant="caption"
                  style={{ fontSize: "10px" }}
                  color="GrayText"
                >
                  {field4 !== undefined ? field4 : ""}
                </Typography>
              </>
            )}

            {(field2 || field2 === "undefined") && (
              <>
                <Divider
                  flexItem
                  orientation="vertical"
                  style={{ margin: "4px 4px" }}
                />
                <Typography
                  variant="caption"
                  style={{ fontSize: "10px" }}
                  color="GrayText"
                >
                  {field2 !== undefined ? field2 : ""}
                </Typography>
              </>
            )}

            {(field8 || field8 === "undefined") && (
              <>
                <Divider
                  flexItem
                  orientation="vertical"
                  style={{ margin: "4px 4px" }}
                />
                <Typography
                  variant="caption"
                  style={{ fontSize: "10px" }}
                  color="GrayText"
                >
                  {field8 !== undefined ? field8 : ""}
                </Typography>
              </>
            )}
          </FlexRow>
          {(field5 || field5 === "undefined") && (
            <FlexRow>
              <Typography
                variant="caption"
                style={{
                  fontSize: "10px",
                }}
                color="GrayText"
              >
                {field5 !== undefined ? `${field5}` : ""}
              </Typography>
            </FlexRow>
          )}

          <FlexSpaceBetween>
            <FlexRow>
              <TagsIcon size="0.8rem" color={theme.palette.text.disabled} />
              <Typography
                variant="caption"
                color="GrayText"
                style={{
                  marginLeft: "8px",
                  fontWeight: "600",
                  fontSize: "14px",
                }}
              >
                {state ?? ""}
              </Typography>
            </FlexRow>

            <FlexRow>
              {/* <IconButton onClick={() => onClickFav(data)}>
                <StarIcon
                  color={favourite ? "warning" : "disabled"}
                  style={{ fontSize: "1rem" }}
                />
              </IconButton> */}
              {/* <IconButton onClick={() => printFunc(ticketId)}>
                <PrintIcon
                  color={favourite ? "warning" : "disabled"}
                  style={{ fontSize: "1rem" }}
                />
              </IconButton> */}
            </FlexRow>
          </FlexSpaceBetween>
        </CardStyled>
      )}
      {type === 2 && (
        <CardStyle type={"main"} m={"large"}>
          <FlexSpaceBetween>
            <FlexRow>
              <Typography> {field1 !== undefined ? field1 : ""}</Typography>

              {field9 && (
                <>
                  <Divider
                    flexItem
                    orientation="vertical"
                    style={{ margin: "4px 4px" }}
                  />
                  <Typography
                    variant="caption"
                    style={{
                      fontSize: "9px",
                    }}
                    color="GrayText"
                  >
                    {` ${a.toLocaleDateString(undefined, options)}`}
                    {/* ${a.toGMTString()} */}
                  </Typography>
                </>
              )}
            </FlexRow>

            <Typography
              variant="subtitle2"
              style={{ fontWeight: "600", fontSize: "12px" }}
            >
              {ticketId ?? ""}
            </Typography>
          </FlexSpaceBetween>
          <FlexSpaceBetween>
            <FlexRow>
              {(field4 || field4 === "undefined") && (
                <>
                  <Typography
                    variant="caption"
                    color="GrayText"
                    // style={{ fontWeight: "600" }}
                  >
                    {field4 !== undefined ? field4 : ""}
                  </Typography>
                  {/* <hr style={{ height: 10, margin: "0px 8px" }} /> */}
                </>
              )}
              {field2 && (
                <>
                  <Divider
                    flexItem
                    orientation="vertical"
                    style={{ margin: "4px 4px" }}
                  />
                  <Typography
                    variant="caption"
                    // style={{ fontWeight: "600" }}
                    color="GrayText"
                  >
                    {`${field2 !== undefined ? field2 : ""}`}
                  </Typography>
                </>
              )}
              {(field5 || field5 === "undefined") && (
                <>
                  <Divider
                    flexItem
                    orientation="vertical"
                    style={{ margin: "4px 4px" }}
                  />
                  <Typography
                    variant="caption"
                    // style={{ fontWeight: "600" }}
                    color="GrayText"
                  >
                    {field5 !== undefined ? field5 : ""}
                  </Typography>
                </>
              )}
              {(field6 || field6 === "undefined") && (
                <>
                  <Divider
                    flexItem
                    orientation="vertical"
                    style={{ margin: "4px 4px" }}
                  />
                  <Typography
                    variant="caption"
                    // style={{ fontWeight: "600" }}
                    color="GrayText"
                  >
                    {field6 !== undefined ? field6 : ""}
                  </Typography>
                </>
              )}

              {(field8 || field8 === "undefined") && (
                <>
                  <Divider
                    flexItem
                    orientation="vertical"
                    style={{ margin: "4px 4px" }}
                  />
                  <Typography
                    variant="caption"
                    // style={{ fontWeight: "600", fontSize: "10px" }}
                    color="GrayText"
                  >
                    {field8 !== undefined ? field8 : ""}
                  </Typography>
                </>
              )}
            </FlexRow>
            <FlexRow>
              {(field7 || field7 === "undefined") && (
                <>
                  <Typography
                    variant="caption"
                    style={{ fontWeight: "600" }}
                    color="GrayText"
                  >
                    {field7 !== undefined ? field7 : ""}
                  </Typography>
                </>
              )}
            </FlexRow>
            <FlexRow style={{ margin: "1em 0" }}>
              <div style={{ display: "flex", gap: "1em" }}>
                {
                  //<Tooltip title="Attachment">
                  //<AttachmentIcon
                  //  style={{ cursor: "pointer" }}
                  //  size="1rem"
                  //  color={theme.palette.info.main}
                  //  onClick={handleClickOpenAttachment}
                  // />
                  //</Tooltip>
                }
                <Tooltip title="Timeline">
                  <AccessTimeIcon
                    style={{ cursor: "pointer" }}
                    size="1rem"
                    color={theme.palette.info.main}
                    onClick={handleClickOpen}
                  />
                </Tooltip>
              </div>
            </FlexRow>
          </FlexSpaceBetween>
          <FlexSpaceBetween>
            <FlexRow>
              <TagsIcon size="1rem" color={theme.palette.info.main} />
              <Typography
                variant="body1"
                color="GrayText"
                style={{ marginLeft: "8px", fontWeight: "400" }}
              >
                {state ?? ""}
              </Typography>
            </FlexRow>
            {action !== undefined && (
              <FlexRow>
                <StyledToggleButtonGroup
                  size="small"
                  value={alignment}
                  exclusive
                  onChange={(e, v) => {
                    backdrop?.setBackDrop({
                      ...backdrop,
                      open: true,
                      message: "loading",
                    });
                    debouncefunc(handleAlignment, e, v);
                  }}
                >
                  {action?.map((action, index) => (
                    <ToggleButton
                      disabled={
                        verifier_registration_status === 1 ? false : true
                      }
                      size="small"
                      value={action}
                      key={index}
                      // className={classes.ToggleButton}
                      style={{
                        border: "1px solid #01205C",
                        borderRadius: "8px",
                        padding: "4px 6px",
                        color: "#01205C",
                        margin: "0 4px",
                      }}
                    >
                      {action.action}
                    </ToggleButton>
                  ))}
                </StyledToggleButtonGroup>
              </FlexRow>
            )}
          </FlexSpaceBetween>
        </CardStyle>
      )}

      <Dialog
        open={formOpen}
        fullWidth={true}
        onClose={() => setFormOpen(!formOpen)}
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* {
          loading ?
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", margin: "20px" }}>
              <CircularProgress />
              <Typography>Please wait while loading next step</Typography>
            </div> : */}
        <FormRendererParent
          // verifier_registration_status={1}
          onSaveForm={(doc) => {
            updateEntry(doc);
            //   setdata(data);
          }}
          secName={0}
          title="notifyAdd"
          secToShow={[0, null]}
          state={[
            {
              form_id: formId,
            },
          ]}
          hideBtn={true}
          entryId={formRendering?.entry_id}
          allowEdit={true}
          ticketId={ticketId}
          isCancel={true}
          isCancelFunc={() => setFormOpen(!formOpen)}
        />
      </Dialog>
    </Grid>
  );
};
