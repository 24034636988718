import { useMemo } from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
import { Line } from "react-chartjs-2";
import { Box, Typography, Avatar, useTheme } from "@material-ui/core";
import DateRangeIcon from "@material-ui/icons/DateRange";
import Button from "@material-ui/core/Button/Button";
import { withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { MdInventory2 } from "react-icons/md";
import { BsSpeedometer } from "react-icons/bs";
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#60B664",
  },
}))(LinearProgress);

function ReportsLineChart({ color, title, description, date }) {
  const chart = {
    labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: {
      label: "Processed",
      data: [50, 40, 300, 320, 500, 350, 200, 230, 500],
    },
  };
  const { data, options } = configs(chart.labels || [], chart.datasets || {});
  const theme = useTheme();
  return (
    // <Card sx={{ height: "100%" }}>
    <Box padding="1rem">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        style={{
          listStyle: "none",
          padding: "8px",
          margin: "0",
          marginBottom: "16px",
        }}
      >
        <Typography
          variant="body1"
          fontWeight="medium"
          textTransform="capitalize"
        >
          Inventory Tracker
        </Typography>
        <Box display="flex" alignItems="flex-start">
          <Button
            variant="outlined"
            // color="textSecondary"
            disabled
            startIcon={<DateRangeIcon color="GrayText" />}
          >
            Monthly
          </Button>
          {/* <Box
            color="textSecondary"
            style={{ marginRight: "4px", color: "GrayText" }}
            lineHeight={0}
          >
            <DateRangeIcon color="GrayText" fontSize="small" />
          </Box>
          <Typography
            variant="button"
            color="textSecondary"
            fontWeight="regular"
          >
            Monthly
          </Typography> */}
        </Box>
      </Box>
      {useMemo(
        () => (
          <Box
            variant="gradient"
            bgColor={color}
            borderRadius="lg"
            coloredShadow={color}
            py={2}
            pr={0.5}
            //   mt={-5}
            style={{
              padding: "16px 8px",
              background: "#60B664",
              borderRadius: "16px",
            }}
            height="12.5rem"
          >
            <Line data={data} options={options} />
          </Box>
        ),
        [chart, color]
      )}
      <div
        style={{
          listStyle: "none",
          padding: "8px",
          margin: "0",
        }}
      >
        <div style={{ flex: "1 1 auto", marginBottom: "8px" }}>
          <Typography
            variant="body1"
            fontWeight="medium"
            textTransform="capitalize"
            gutterBottom
          >
            Inventory Stock
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Avatar
              style={{
                color: theme.palette.primary.contrastText,
                background: theme.palette.primary.main,
              }}
            >
              <MdInventory2 />
            </Avatar>
            <div style={{ marginLeft: "16px" }}>
              <Typography
                variant={"h4"}
                style={{ fontSize: "1.5rem", fontWeight: "700" }}
              >
                {"42"}
              </Typography>
              <Typography variant={"caption"} color={"textSecondary"}>
                {"Since the last check"}
              </Typography>
            </div>
          </div>
        </div>

        <div style={{ flex: "1 1 auto", marginBottom: "8px" }}>
          <Typography
            variant="body1"
            fontWeight="medium"
            textTransform="capitalize"
            gutterBottom
          >
            Inventory Accuracy
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Avatar
              style={{
                color: theme.palette.primary.contrastText,
                background: theme.palette.primary.main,
              }}
            >
              <BsSpeedometer />
            </Avatar>
            <div style={{ marginLeft: "16px", flex: "1 1 auto" }}>
              <Typography
                variant={"h4"}
                style={{ fontSize: "1.5rem", fontWeight: "700" }}
              >
                {"81%"}
              </Typography>
              <BorderLinearProgress variant="determinate" value={81} />
            </div>
          </div>
        </div>
      </div>
      {/* <Box pt={3} pb={1} px={1}>
          <Typography variant="h6" textTransform="capitalize">
            {title}
          </Typography>
          <Typography
            component="div"
            variant="button"
            color="text"
            fontWeight="light"
          >
            {description}
          </Typography>
          <Divider />
          <Box display="flex" alignItems="center">
            <Typography
              variant="button"
              color="text"
              lineHeight={1}
              sx={{ mt: 0.15, mr: 0.5 }}
            >
              {/* <Icon>schedule</Icon> */}
      {/* </Typography>
            <Typography variant="button" color="text" fontWeight="light">
              {date}
            </Typography>
          </Box> */}
      {/* </Box> */}
    </Box>
    // </Card>
  );
}

// Setting default values for the props of ReportsLineChart
ReportsLineChart.defaultProps = {
  color: "dark",
  description: "",
};

// Typechecking props for the ReportsLineChart
ReportsLineChart.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  date: PropTypes.string.isRequired,
  chart: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.array, PropTypes.object])
  ).isRequired,
};

export default ReportsLineChart;

function configs(labels, datasets) {
  return {
    data: {
      labels,
      datasets: [
        {
          //   label: datasets.label,
          tension: 0,
          pointRadius: 5,
          pointBorderColor: "transparent",
          pointBackgroundColor: "rgba(255, 255, 255, 1)",
          borderColor: "rgba(255, 255, 255, 1)",
          borderWidth: 4,
          backgroundColor: "transparent",
          fill: true,
          data: datasets.data,
          maxBarThickness: 6,
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
      interaction: {
        intersect: false,
        mode: "index",
      },
      scales: {
        y: {
          grid: {
            drawBorder: false,
            display: true,
            drawOnChartArea: true,
            drawTicks: false,
            borderDash: [5, 5],
            color: "rgba(255, 255, 255, .5)",
          },
          ticks: {
            display: true,
            color: "#fff",
            padding: 10,
            font: {
              size: 14,
              weight: 300,
              family: "Roboto",
              style: "normal",
              lineHeight: 2,
            },
          },
        },
        x: {
          grid: {
            drawBorder: false,
            display: false,
            drawOnChartArea: false,
            drawTicks: false,
            borderDash: [5, 5],
          },
          ticks: {
            display: true,
            color: "#fff",
            padding: 10,
            font: {
              size: 14,
              weight: 300,
              family: "Roboto",
              style: "normal",
              lineHeight: 2,
            },
          },
        },
      },
    },
  };
}
